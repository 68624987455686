import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useFetch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Customer } from 'types/customer.types';
import { normalizeCustomer } from 'atoms/customers';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

export function ChannelHeader({ customerId }: { customerId: number }) {
  const { data: customer } = useFetch<Customer>(`/v1/clients/${customerId}`, {
    normalizeData: normalizeCustomer,
  });
  const { t } = useTranslation();
  // hidden only master company 5450
  const [hiddenH265MessageFlag] = useDecision(FEATURE_FLAGS_KEYS.H265_MESSAGE);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            noWrap={true}
            sx={{
              fontSize: '1.375rem',
              marginRight: 2,
            }}
          >
            {customer?.tradeName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap={true}>
            {customer?.companyName}
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="textSecondary">
          ID {customer?.idMask}
        </Typography>
      </Box>
      {hiddenH265MessageFlag.enabled && (
        <Alert
          variant="filled"
          severity="info"
          sx={(theme) => ({
            color: 'black',
            bgcolor: theme.palette.warning.main,
            marginLeft: '24px',
            minHeight: '32px',
          })}
        >
          <Typography
            variant="vms-body2"
            sx={{
              color: 'black',
            }}
          >
            {t('_common:we_currently_do_not_support_h265')}
          </Typography>
        </Alert>
      )}
    </Box>
  );
}
