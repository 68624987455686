import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type ChannelDetail = {
  name: string;
  url: string;
  id: number;
};
export function BatchRTMPCopyModal({
  open,
  setOpen,
  channels,
  onRedirect,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  channels: ChannelDetail[];
  onRedirect: () => void;
}) {
  const { t } = useTranslation();
  const [helperText, setHelperText] = React.useState('');

  const handleClose = () => {
    setOpen(false);
    onRedirect();
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setHelperText('');
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="batch-rtmp-copy-modal"
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
        },
      }}
    >
      <DialogTitle id="batch-rtmp-copy-modal">{t('channels:links_was_created')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('channels:batch_rtmp_modal_description')}</DialogContentText>
      </DialogContent>

      <DialogContent>
        <Box
          sx={{
            maxHeight: '300px',
            overflow: 'auto',
            pr: 2,
          }}
        >
          {channels.map((channel) => (
            <TextField
              key={channel.id}
              fullWidth
              id="url"
              name="url"
              label={channel.name}
              disabled
              value={channel.url}
              sx={{
                mt: 1,
                mb: 1.2,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => {
                      navigator.clipboard.writeText(channel.url);
                      setHelperText(t('channels:copied'));
                    }}
                    position="end"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <ContentCopyIcon />
                  </InputAdornment>
                ),
              }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ml: 3,
              mb: 1,
            }}
          >
            <DialogContentText align="left">{helperText}</DialogContentText>
          </Box>
          <Box>
            <Button
              onClick={() => {
                setOpen(false);
                onRedirect();
              }}
              color="error"
              aria-label="close"
            >
              {t('_common:close')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
