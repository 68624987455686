import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'components/core';
import { Typography, Box, Divider } from '@mui/material';
import { useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { Formik } from 'formik';
import { Channel, ChannelInitialValues, ProtocolType, RecordingType } from 'types/channels.types';
import routes from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import { ChannelForm } from '../../../Channel';
import { RTMPCopyModal } from './RTMPCopyModal';

interface Props {
  initialValues: ChannelInitialValues | Channel;
  doSubmit: (channel: Channel) => Promise<Channel | void>;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  onClose?: () => void;
  customerId?: number;
}

export function RTMPChannelForm({
  initialValues,
  doSubmit,
  showDeleteButton,
  doDelete,
  onClose,
  customerId,
}: Props) {
  const yup = useYupObject();
  const { t } = useTranslation();
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const history = useHistory();
  const [channelCreated, setChannelCreated] = React.useState<Channel | null>();
  const [modalOpen, setModalOpen] = React.useState(false);

  const validationSchema = yup.object({
    status: yup.bool(),
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.bool(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup
      .object({
        id: yup.number(),
        name: yup.string(),
      })
      .nullable(),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (
        { channelGroup, recordingType, simpleScheduledRecording, ...rest },
        { setSubmitting }
      ) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule = isScheduleLinked && recordingType !== RecordingType.SCHEDULE;

        setSubmitting(true);
        try {
          // @ts-ignore
          const transformedValues: Channel = {
            ...rest,
            channelGroup: channelGroup
              ? {
                  id: channelGroup.id,
                  name: channelGroup.label,
                }
              : undefined,
            connectionTypeEnum: ProtocolType.RTMP,
            recordingType,
            simpleScheduledRecording: hasUnlinkedSchedule ? null : simpleScheduledRecording,
          };
          const channel = await doSubmit(transformedValues);
          setChannelCreated(channel as Channel);
          channel && setModalOpen(true);

          setSubmitting(false);
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <form
          aria-label="channel"
          style={{
            overflow: 'hidden',
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            maxHeight: '100%',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              py: 1.5,
              pr: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>

            <ChannelForm.StatusInput />

            <ChannelForm.NameInput />
            {initialValues.id && <ChannelForm.RTMPUrlInput />}
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mb: 4,
              }}
            >
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              textAlign: 'end',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'channels:not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('channels:want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    onClose && onClose();
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
            <ChannelForm.SaveButton
              isSubmitting={formik.isSubmitting}
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              onClick={formik.handleSubmit}
            />
          </Box>
          {channelCreated && (
            <RTMPCopyModal
              open={modalOpen}
              setOpen={setModalOpen}
              url={channelCreated.url}
              onRedirect={() => {
                history.push(routes.customer.root(Number(customerId)));
              }}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
