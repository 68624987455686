import styled from 'styled-components';
import { Box, Card, CardActionArea as MuiCardActionArea, Theme } from '@mui/material';

export const Container = styled(Box)`
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
`;

export const MosaicSlotContainer = styled(Card)<{ selected?: boolean; theme: Theme }>`
  flex: 1 1 50%;
  display: flex;
  ${({ selected, theme }) => selected && `border-color: ${theme.palette.primary.main};`}
  position: relative;
`;

export const CardActionArea = styled(MuiCardActionArea)`
  height: 100%;
  text-align: center;
`;
