import React, { useEffect } from 'react';
import { useFetch } from 'hooks';
import { useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import NotFound from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import { MosaicInPlaylist, Playlist, PlaylistItem } from 'types/playlist';
import { Mosaic } from 'types/mosaics.types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useInterval } from 'react-use';
import { addMosaicToPlaylistModalOpenedAtom, itemAtualAtom } from 'atoms/playlist';
import AddMosaicToPlaylistModal from 'pages/PlaylistPage/AddMosaicToPlaylistModal';
import { Box } from '@mui/material';
import useCallStartLiveBefore from './useCallStartLiveBefore';
import { PlaylistMosaicViewPage } from './PlaylistPlayFullscreenView';

function PlaylistViewPage() {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { data: playlist } = useFetch<Playlist>(`/v1/playlists/${Number(playlistId)}`, {
    normalizeData: (_playlist) => {
      const newArr = [..._playlist.items];

      return {
        ..._playlist,
        items: newArr.sort((a, b) => a.order - b.order),
      };
    },
  });
  const { t } = useTranslation('playlists');

  if (!playlist) {
    return (
      <NotFound
        title={t('playlists:non_exixstent_playlist')}
        reason={t('playlists:playlist_does_not_exist_or_may_have_been_deleted')}
        shouldDo={t('playlists:register_a_new_playlist_or_select_another_playlist_to_be_displayed')}
      />
    );
  }

  if (playlist.items) return <PlaylistView {...{ playlist }} />;
}

function PlaylistView({ playlist }: { playlist: Playlist }) {
  const [index, setIndex] = React.useState(0);
  const itemAtual = React.useMemo<PlaylistItem>(() => playlist.items[index], [playlist, index]);
  const [open, setOpen] = useRecoilState(addMosaicToPlaylistModalOpenedAtom);
  const setItemAtual = useSetRecoilState(itemAtualAtom);

  const nextIndex = React.useMemo(() => {
    const newIndex = index + 1;
    return playlist.items.length - 1 >= newIndex ? newIndex : 0;
  }, [index, playlist]);

  useCallStartLiveBefore(itemAtual.rotationTime, playlist.items[nextIndex].mosaic.id);

  useInterval(() => {
    setIndex(nextIndex);
  }, itemAtual.rotationTime * 1000);

  useEffect(() => {
    if (itemAtual) {
      setItemAtual(itemAtual);
    }
  }, [itemAtual, setItemAtual]);

  return (
    <>
      <PlaylistMosaicView mosaicInPlaylist={itemAtual.mosaic} noRemoveChannelOption />
      <AddMosaicToPlaylistModal
        {...{ open, setOpen }}
        items={playlist.items}
        playlistId={playlist.id}
      />
    </>
  );
}

function PlaylistMosaicView({
  mosaicInPlaylist,
  noRemoveChannelOption,
}: {
  mosaicInPlaylist: MosaicInPlaylist;
  noRemoveChannelOption: boolean;
}) {
  const { data: mosaic } = useFetch<Mosaic>(`/v1/mosaics/${mosaicInPlaylist.id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  if (!mosaic) {
    return null;
  }

  return (
    <Box display="flex" flex="1 1 auto" overflow="hidden">
      <PlaylistMosaicViewPage mosaicId={mosaic.id} />
    </Box>
  );
}

export default asyncComponent(PlaylistViewPage);
