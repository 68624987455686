import { VideoLiveSource } from 'types/channels.types';
import { useTranslation } from 'react-i18next';
import useFetch from '../useFetch';

function useMosaicStartLive({ mosaicId }: { mosaicId: number }): VideoLiveSource[] {
  const { t } = useTranslation('api_errors_messages');
  const { data } = useFetch<VideoLiveSource[]>(`/v1/mosaics/${mosaicId}/startLive/optimized`, {
    revalidateOnFocus: false,
    errorRetryInterval: 1000,
  });

  if (!data) throw new Error(t('api_errors_messages:unable_to_get_live_video'));

  return data;
}

export default useMosaicStartLive;
