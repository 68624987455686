import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Customers(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3ZM8.81055 14.3926C5.99355 15.0166 3 16.385 3 18.5V21H21V18.5C21 16.385 18.0065 15.0166 15.1895 14.3926C14.4595 15.3636 13.308 16 12 16C10.692 16 9.54055 15.3636 8.81055 14.3926Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Customers;
