import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link, Typography, Fab, Box, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { customerSearchTextAtom } from 'atoms/customers';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import DebounceInput from 'components/core/DebounceInput';
import { useHaveScroll } from 'hooks';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { Customer } from 'types/customer.types';
import { ListContainer, Header, BodyItems } from './CustomerList.styles';
import CustomerListItem from './CustomerListItem';

function Layout({ children, loading }: { loading?: boolean; children: React.ReactNode }) {
  const { t } = useTranslation(['customers', 'crud_actions']);
  const listaRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(customerSearchTextAtom);
  const [searchText, setSearchText] = useState(searchTextDebounced);
  const { haveScroll } = useHaveScroll(listaRef);
  const inputRef = useRef<HTMLInputElement>();

  const showSearchInput = useMemo(() => !loading && (haveScroll || searchTextDebounced), [
    haveScroll,
    loading,
    searchTextDebounced,
  ]);

  const handleDebounceText = () => {
    setSearchTextDebounced(searchText);
    if (searchText !== searchTextDebounced) {
      history.push(routes.customer.root());
    }
  };
  /* O foco no campo do search deve continuar mesmo após o loading aparecer na tela */
  useEffect(() => {
    inputRef.current?.focus();
  }, [children]);

  return (
    <ListContainer>
      <Header>
        <Typography variant="h5">{t('customers')}</Typography>
        <Permissioned role={PermissionRole.INSERT_CLIENT}>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            component={RouterLink}
            to={routes.customer.create()}
          >
            <AddIcon />
          </Fab>
        </Permissioned>
      </Header>
      {showSearchInput && (
        <>
          <Divider />
          <Box margin="8px">
            <DebounceInput
              {...{ inputRef }}
              placeholder={t('crud_actions:search')}
              fullWidth
              autoFocus
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              setValue={setSearchText}
              onDebounce={handleDebounceText}
            />
          </Box>
        </>
      )}
      <BodyItems ref={listaRef}>{children}</BodyItems>
    </ListContainer>
  );
}

function CustomersList({ customers }: { customers?: Customer[] }) {
  const { t } = useTranslation('crud_actions');

  return (
    <>
      <Layout>
        {customers && customers.length > 0 ? (
          customers.map((customer) => (
            <Link
              key={customer.id}
              to={routes.customer.root(customer.id)}
              component={RouterLink}
              underline="none"
            >
              <CustomerListItem key={customer?.id} customer={customer} />
            </Link>
          ))
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography color="textSecondary">{t('no_results')}</Typography>
          </Box>
        )}
      </Layout>
    </>
  );
}

export function CustomerListLoading() {
  return (
    <Layout loading>
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CustomerListItem key={i} loading />
      ))}
    </Layout>
  );
}

export default CustomersList;
