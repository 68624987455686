import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Block from './Block';

function ContactUsModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <Block open={open}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('consumption:contact_us')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('consumption:contact_commercial_representant_or_send_email')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            {t('_common:back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Block>
  );
}
export default ContactUsModal;
