import React from 'react';

function useVMSDownloaded() {
  const [downloaded, setDownloaded] = React.useState(false);

  React.useEffect(() => {
    const isWebView = typeof window !== 'undefined' && window.isWebView;
    const isWebViewProxy = typeof window !== 'undefined' && window.isWebViewProxy;

    if (isWebView || isWebViewProxy) {
      setDownloaded(true);
    }
  }, []);

  return downloaded;
}

export { useVMSDownloaded };
