import React, { useState } from 'react';
import { SegwareLogo } from 'components/core/Icons';
import { Typography, Fab, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTranslation } from 'react-i18next';
import { textDebouncedAtom } from 'atoms/selectChannelPanel';
import DebounceInput from 'components/core/DebounceInput';
import ApiBoundary from 'components/ApiBoundary';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { mosaicAtom } from 'atoms/mosaics';
import { mosaicViewContext } from 'helpers/routes';
import { asyncComponent } from 'HOCs';
import { useRouteMatch } from 'react-router-dom';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { Container, InputContainer, Header } from '../LeftPanel.styles';
import ResultsContainer, { Loading } from './ResultsContainer';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function SelectChannelPanel() {
  const match = useRouteMatch<{ mosaicId?: string }>(mosaicViewContext);
  const { closeChannelSelection } = useSelectChannelPanel();

  if (!match) {
    closeChannelSelection();
    return null;
  }

  return <Panel mosaicId={Number(match.params.mosaicId)} />;
}

function Panel({ mosaicId }: { mosaicId: number }) {
  const { t } = useTranslation('select_channel');
  const [inputText, setInputText] = useState('');
  const setTextDebounced = useSetRecoilState(textDebouncedAtom);
  const { closeChannelSelection } = useSelectChannelPanel();
  const mosaic = useRecoilValue(mosaicAtom(mosaicId));
  const classes = useStyles();
  if (!mosaic) {
    closeChannelSelection();
    return null;
  }

  return (
    <Container role="region" aria-labelledby="select-channel-panel-title">
      <Header id="select-channel-panel-title">
        <SegwareLogo className={classes.segwareLogo} />
        <Box>
          <Typography variant="h6">{t('select_channel')}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t('you_need_select_or_drag_channel_add_to_the_mosaic')}
          </Typography>
        </Box>
      </Header>
      <InputContainer>
        <Fab size="small" onClick={closeChannelSelection} aria-label="close-search">
          <NavigateBeforeIcon />
        </Fab>
        <DebounceInput
          placeholder={t('search_channels')}
          autoFocus
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          setValue={setInputText}
          onDebounce={() => setTextDebounced(inputText)}
        />
      </InputContainer>
      <ApiBoundary fallbackLoading={<Loading />}>
        <ResultsContainer mosaicId={mosaic.id} />
      </ApiBoundary>
    </Container>
  );
}

export default asyncComponent(SelectChannelPanel);
