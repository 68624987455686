import React from 'react';
import { FormControl, FormControlLabel, Checkbox, FormHelperText, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormStyles } from 'components/core';

type CheckBoxstorageLimitPROPS = {
  touched: boolean | undefined;
  error: string | undefined;
  checked: boolean;
  change: (e: React.ChangeEvent) => void;
  disabled?: boolean;
};
export function ActiveStorageLimit(props: CheckBoxstorageLimitPROPS) {
  const { change, checked, error, touched, disabled } = props;
  const classesForm = useFormStyles({});
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <FormControl className={classesForm.formInput} error={touched && Boolean(error)}>
          <FormControlLabel
            control={
              <Checkbox
                id="activeStorageLimit"
                name="activeStorageLimit"
                checked={checked}
                disabled={disabled}
                onChange={change}
              />
            }
            label={t('channels:limit_of_use_of_storage')}
          />
          <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
      </Box>
    </>
  );
}
