import { atomFamily } from 'recoil';

export const textTimeTooltipFullscreenAtom = atomFamily<string | null, string>({
  key: 'components.CronologicBarFullscreen.textTimeTooltipAtom',
  default: null,
});

export const leftTimeTooltipFullscreenAtom = atomFamily<number, string>({
  key: 'components.CronologicBarFullscreen.leftTimeTooltipAtom',
  default: 0,
});

export const periodStartDateFullscreenAtom = atomFamily<Date, string>({
  key: 'components.CronologicBarFullscreen.periodStartDateAtom',
  default: new Date(),
});

export const periodEndDateFullscreenAtom = atomFamily<Date, string>({
  key: 'components.CronologicBarFullscreen.periodEndDateAtom',
  default: new Date(),
});
