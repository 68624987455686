import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { tokenAtom } from 'atoms/auth';
import {
  getTokenFromUrlIfValid,
  URL_LOGIN,
  getTokenFromStorageIfValid,
  SESSION_STORAGE,
} from 'services/auth';
import routes from 'helpers/routes';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRoute } from 'services/routes';

function PrivateRoute({
  permission,
  ...routeProps
}: RouteProps & { permission?: PermissionRoute }) {
  const setToken = useSetRecoilState(tokenAtom);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const tokenFromUrl = getTokenFromUrlIfValid();
    const tokenFromStorage = getTokenFromStorageIfValid();

    if (!tokenFromUrl && !tokenFromStorage) {
      window.location.assign(URL_LOGIN);
    } else {
      setIsAuthenticated(true);
      if (tokenFromUrl) sessionStorage.setItem(SESSION_STORAGE.TOKEN, tokenFromUrl);
      setToken(tokenFromUrl || tokenFromStorage);
    }
  }, [setToken]);

  if (!isAuthenticated) {
    return null;
  }

  if (!routeProps.component) {
    return <RedirectInitialPage />;
  }

  return permission ? (
    <PermissionedRoute {...{ permission, ...routeProps }} />
  ) : (
    <Route {...routeProps} />
  );
}

function PermissionedRoute({
  permission,
  ...routeProps
}: RouteProps & { permission: PermissionRoute }) {
  const havePermissionFn = useHavePermission();

  const canRender = Array.isArray(permission)
    ? permission.some((permissionRole) => havePermissionFn(permissionRole))
    : havePermissionFn(permission);

  return canRender ? <Route {...routeProps} /> : <RedirectInitialPage />;
}

function RedirectInitialPage() {
  const channelPermission = useHavePermission(PermissionRole.VIEW_CHANNEL);

  if (channelPermission) {
    // primeiro login joga para welcome
    return <Redirect to={routes.welcome.root} />;
  }

  // nas próximas atualizações da página, não envia para welcome e pega onde o usuário está.
  return null;
}

export default PrivateRoute;
