import React from 'react';
import { IconButton, List, ListItem, Paper, Stack, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import SelectCustomer from 'components/SelectCustomer';
import SelectCustomerChannels from 'components/SelectCustomerChannels';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Customer } from 'types/customer.types';
import { useParams } from 'react-router-dom';
import { IRecordingSchedulerForm } from 'types/recordingSchedule.types';

function RecordingScheduleCustomerChannels() {
  const formik = useFormikContext<IRecordingSchedulerForm>();
  const { t } = useTranslation(['recording_schedule', '_common']);

  const { id: scheduleId } = useParams<{ id: string }>();

  const getCustomerIdError = (index: number) => {
    if (!formik.touched.customers) {
      return undefined;
    }
    const customerError = formik.errors.customers?.[index];
    return typeof customerError !== 'string' ? customerError?.customerId : '';
  };
  const getChannelsError = (index: number) => {
    const customerError = formik.errors.customers?.[index];
    if (typeof customerError === 'string') {
      return '';
    }

    return Array.isArray(customerError?.channelIds)
      ? customerError?.channelIds.join(', ')
      : customerError?.channelIds;
  };

  const handleDelete = (id: string) => {
    formik.setFieldValue(
      'customers',
      formik.values.customers.filter((customer) => customer.id !== id) || []
    );
  };

  const filterCustomers = React.useCallback(
    (customerId: number) => {
      const customerIds = formik.values.customers.map((customer) => customer.customerId);
      return (customer: Customer) =>
        !customerIds.includes(customer.id) || customer.id === customerId;
    },
    [formik.values.customers]
  );

  if (formik.values.customers?.length === 0) {
    return null;
  }

  return (
    <List>
      {formik.values.customers.map((customerItem, index) => (
        <ListItem
          key={customerItem.id}
          component={Paper}
          elevation={0}
          sx={{
            bgcolor: 'background.default',
            p: 3,
            // mb: 1,
            // '&:last-chiDld': { mb: 2 },
            '&:not(:last-child)': { mb: 1 },
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems="flex-start"
            flex="1 1 auto"
            gap={{ xs: 1, sm: 2, md: 4 }}
            maxWidth="100%"
          >
            <React.Suspense fallback={<SelectCustomer.Loading />}>
              <SelectCustomer
                value={customerItem.customerId}
                filterFn={filterCustomers(customerItem.customerId)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={`customers[${index}].customerId`}
                helperText={
                  getCustomerIdError(index) || t('select_a_customer_to_filter_its_channels')
                }
                error={formik.touched.customers && Boolean(getCustomerIdError(index))}
                disabled={formik.isSubmitting}
              />
            </React.Suspense>
            <React.Suspense fallback={<SelectCustomerChannels.Loading />}>
              <SelectCustomerChannels
                key={customerItem.customerId}
                values={customerItem.channelIds || []}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={`customers[${index}].channelIds`}
                helperText={
                  formik.touched.customers ? (
                    getChannelsError(index)
                  ) : (
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <Stack spacing={2}>
                          <span>
                            {t(
                              'recording_schedule:only_channels_configured_with_the_scheduled_recording_type_will_appear_here'
                            )}
                          </span>
                          <span>
                            {t(
                              'recording_schedule:each_channel_can_only_be_linked_to_a_single_recording_schedule'
                            )}
                          </span>
                        </Stack>
                      }
                    >
                      <span>
                        {t(
                          'recording_schedule:only_channels_configured_with_the_scheduled_recording_type_will_appear_here'
                        )}
                        ..
                      </span>
                    </Tooltip>
                  )
                }
                error={formik.touched.customers && Boolean(getChannelsError(index))}
                customerId={customerItem.customerId}
                disabled={formik.isSubmitting}
                scheduleId={scheduleId}
              />
            </React.Suspense>
            <IconButton
              size="large"
              onClick={() => {
                handleDelete(customerItem.id);

                if (formik.values.customers.length === 0) {
                  formik.setFieldTouched('customers', false);
                }
              }}
              disabled={formik.isSubmitting}
            >
              <Delete />
            </IconButton>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
}

export default RecordingScheduleCustomerChannels;
