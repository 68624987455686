import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMessages, useYupObject } from 'hooks';
import { Form } from 'components/core';
import { timeFormat, timeMinute, timeSecond } from 'd3';
import { DateSchema } from 'yup';
import Axios from 'axios';
import { selectedDateAtom, liveSelector } from 'atoms/playback';
import { useRecoilValue } from 'recoil';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { ChannelSimple } from 'types/mosaics.types';
import { useFormStyles } from '../core/Form';

function ExportVideoForm({
  channel,
  mosaicId = null,
  nowLiveDate,
  open,
  setOpen,
}: {
  channel: ChannelSimple;
  mosaicId?: number | null;
  nowLiveDate: Date;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOpen: any;
}) {
  const MAX_MINUTES = 15;
  const selectedDate = useRecoilValue(selectedDateAtom({ mosaicId, channelId: channel.id }));
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: channel.id }));
  const { enqueueError } = useMessages();
  const useStyles = makeStyles((theme) => ({
    startDateField: {
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation(['video_export', 'forms', '_common']);
  const yup = useYupObject();
  const classesForm = useFormStyles({ dialog: true });
  const initialValues = {
    startDate: timeMinute.offset(
      selectedDate || nowLiveDate,
      live ? -MAX_MINUTES : -(MAX_MINUTES / 2)
    ),
    endDate: timeMinute.offset(selectedDate || nowLiveDate, live ? 0 : MAX_MINUTES / 2),
  };
  const validationSchema = yup.object({
    startDate: yup.date().required(),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), t('forms:date_the_end_date_cannot_be_less_than_the_start_date'))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .when('startDate', (startDate: Date, schema: DateSchema<Date | undefined>) =>
        schema.max(
          timeMinute.offset(startDate, MAX_MINUTES),
          t('you_must_select_a_period_of_up_to_15_minutes')
        )
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await Axios({
          url: `/v1/channels/${channel.id}/mux`,
          method: 'GET',
          responseType: 'blob',
          params: values,
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${channel.name} ${timeFormat('%d-%m-%Y %H-%M-%S')(
              values.startDate
            )} ${timeSecond.count(values.startDate, values.endDate)}.mp4`
          );
          document.body.appendChild(link);
          link.click();
        });
        setOpen(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (Axios.isAxiosError(error))
          enqueueError(
            `${t('error_while_exporting')}\n${t(
              `api_errors_messages:${error?.response?.data?.message || ''}`
            )}`
          );
        setSubmitting(false);
      }
    },
  });
  return (
    <form aria-label="ExportVideoModal" className={classesForm.form} onSubmit={formik.handleSubmit}>
      <Box className={classesForm.formInputsContainer}>
        <>
          <Box marginBottom={2}>
            <Typography>{t('enter_the_period')}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    className={classes.startDateField}
                    label={t('_common:start_date')}
                    name="startDate"
                    fullWidth
                    helperText={formik.touched.startDate && formik.errors.startDate}
                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                    {...props}
                  />
                )}
                value={formik.values.startDate}
                autoFocus
                inputFormat="DD/MM/YYYY HH:mm:ss"
                onError={(error) => {
                  if (error && error !== formik.errors.startDate) {
                    formik.setFieldError('startDate', String(error));
                  }
                }}
                onChange={(date: Dayjs | null) => {
                  formik.setFieldValue(
                    'startDate',
                    date?.toDate(),
                    date?.toString() !== 'Invalid Date'
                  );
                }}
                OpenPickerButtonProps={{
                  'aria-label': 'select start date',
                }}
              />
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    label={t('_common:final_date')}
                    name="endDate"
                    fullWidth
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                    {...props}
                  />
                )}
                value={formik.values.endDate}
                inputFormat="DD/MM/YYYY HH:mm:ss"
                onError={(error) => {
                  if (error && error !== formik.errors.endDate) {
                    formik.setFieldError('endDate', String(error));
                  }
                }}
                onChange={(date: Dayjs | null) => {
                  formik.setFieldValue(
                    'endDate',
                    date?.toDate(),
                    date?.toString() !== 'Invalid Date'
                  );
                }}
                OpenPickerButtonProps={{
                  'aria-label': 'select end date',
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      </Box>
      <Box className={classesForm.formFooter}>
        <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={() => setOpen(false)} />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          values={formik.values}
          label={t('export')}
          dialog
        />
      </Box>
    </form>
  );
}

export default ExportVideoForm;
