/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Typography,
  IconButton,
  Box,
  Tooltip,
  SvgIcon,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Delete, OpenInNew, Refresh } from '@mui/icons-material';
import { clearInterval, setInterval } from 'timers';
import routes from 'helpers/routes';
import { Link } from 'react-router-dom';
import ErrorBoundary, {
  ErrorBoundaryFallbackProps,
  Props as ErrorBoundaryProps,
} from 'components/ErrorBoundary';
import { VideoLiveSource } from 'types/channels.types';
import axios from 'axios';
import { useMeasure } from 'react-use';
import { useRecoilState, atomFamily } from 'recoil';
import { BIG, EXTRA_BIG, EXTRA_SMALL, EXTRA_SMALL2, MEDIUM, SMALL } from './responsive.service';

const attemptsErrorBoundaryAtom = atomFamily<number, number>({
  key: 'attemptsErrorBoundaryAtom',
  default: 0,
});

interface Channel {
  clientTradeName: string;
  clientCompanyName: string;
  clientId: number;
  id: number;
  name: string;
}

interface ErrorSlotProps {
  channel: Channel;
  order: number;
  mosaicId: number;
  removeChannelClick(): void;
  errorRetryInterval?: number;
}

function ErrorSlot({
  isApiError,
  error,
  handlerTryAgainClick,
  channel,
  order,
  mosaicId,
  resetState,
  removeChannelClick,
  errorRetryInterval,
}: ErrorBoundaryFallbackProps & ErrorSlotProps) {
  const retryIntervalRef = React.useRef<NodeJS.Timeout | 0>(0);
  const { t } = useTranslation('error_boundary');
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const [attempts, setAttempts] = useRecoilState(attemptsErrorBoundaryAtom(channel.id));
  const attemptsLimit = 100;

  const handleTryAgainClick = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      event.stopPropagation();
      retryIntervalRef.current && clearInterval(retryIntervalRef.current);
      handlerTryAgainClick();
    },
    [handlerTryAgainClick]
  );

  const tryAgainWithAttempts = React.useCallback(() => {
    if (attempts < attemptsLimit) {
      setAttempts(attempts + 1);
      handlerTryAgainClick();
    } else {
      retryIntervalRef.current && clearInterval(retryIntervalRef.current);
    }
  }, [attempts, handlerTryAgainClick, setAttempts]);

  React.useEffect(
    function initInterval() {
      if (error && errorRetryInterval) {
        retryIntervalRef.current && clearInterval(retryIntervalRef.current);
        retryIntervalRef.current = setInterval(() => {
          tryAgainWithAttempts();
        }, errorRetryInterval);
      }

      return () => {
        retryIntervalRef.current && clearInterval(retryIntervalRef.current);
      };
    },
    [error, errorRetryInterval, tryAgainWithAttempts]
  );

  const handlerRemoveChannel = () => {
    resetState?.();
    removeChannelClick();
  };

  return (
    <Box ref={measureRef} sx={{ width: '100%', height: '100%', display: 'flex', flex: '1 1 auto' }}>
      <Box
        sx={{
          alignItems: 'center',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          p: BIG.lower(measures) ? (MEDIUM.lower(measures) ? 0.5 : 1) : 2,
        }}
      >
        <Box display="flex" width="100%" alignItems="center" flex="0 0 auto">
          <Link
            aria-label="OpenInNew"
            to={routes.customer.channel.view(channel.clientId, channel.id)}
          >
            <OpenInNew
              sx={{ mr: 1, color: 'text.primary' }}
              fontSize={MEDIUM.lower(measures) ? 'small' : 'medium'}
            />
          </Link>
          <Tooltip title={`${channel.clientTradeName} (${channel.clientCompanyName})`}>
            <Typography
              variant={
                BIG.lower(measures)
                  ? MEDIUM.lower(measures)
                    ? 'caption'
                    : 'subtitle2'
                  : 'subtitle1'
              }
              sx={EXTRA_SMALL.lower(measures) ? { fontSize: '10px' } : {}}
            >
              {channel.name}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex="1 1 auto"
        >
          <Stack
            alignItems="center"
            direction={BIG.lower(measures) ? 'row' : 'column'}
            spacing={BIG.lower(measures) ? 1 : 0}
            display={MEDIUM.lower(measures) ? 'none' : 'flex'}
          >
            <SvgIcon
              color="error"
              fontSize={
                EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'small' : 'medium') : 'large'
              }
            >
              <path d="M 17 10.5 V 7 c 0 -0.55 -0.45 -1 -1 -1 H 4 c -0.55 0 -1 0.45 -1 1 v 10 c 0 0.55 0.45 1 1 1 h 12 c 0.55 0 1 -0.45 1 -1 v -3.5 l 4 4 v -11 l -4 4 z M 9 8 L 11 8 L 11 13 L 9 13 Z M 9 14 L 11 14 L 11 16 L 9 16 Z" />
            </SvgIcon>
            <Typography
              align="center"
              variant={
                EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
              }
            >
              Oops!
            </Typography>
          </Stack>
          <Typography
            variant={
              EXTRA_BIG.lower(measures)
                ? BIG.lower(measures)
                  ? MEDIUM.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle2'
                : 'subtitle1'
            }
            display={EXTRA_SMALL.lower(measures) ? 'none' : 'block'}
            align="center"
          >
            {t('something_went_wrong')}
          </Typography>
          <Typography
            align="center"
            variant={
              EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'caption' : 'body2') : 'subtitle1'
            }
            color="textSecondary"
            display={EXTRA_SMALL2.lower(measures) ? 'none' : 'block'}
            sx={{ mt: EXTRA_BIG.lower(measures) ? (SMALL.lower(measures) ? 0 : 0.5) : 1 }}
          >
            {isApiError
              ? t(`api_errors_messages:${error?.response?.data?.message}`)
              : error?.message}
          </Typography>
        </Box>
        <Box display="flex" flex="0 0 auto" pb={2} width="100%" position="relative">
          <Box flex="1 1 auto" textAlign="center">
            <IconButton onClick={handleTryAgainClick} title={t('try_again')} size="small">
              <Refresh fontSize="small" />
            </IconButton>
            <IconButton
              aria-label={`remove channel of slot ${order}`}
              onClick={handlerRemoveChannel}
              size="small"
              sx={{
                ml: 1,
              }}
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function ErrorBoundaryVideo({
  children,
  channel,
  order,
  mosaicId,
  removeChannelClick,
  setVideoLiveSource,
  errorRetryInterval,
}: {
  children: React.ReactNode;
  setVideoLiveSource: (value: VideoLiveSource) => void;
} & ErrorSlotProps &
  ErrorBoundaryProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  const tryAgainClick = async ({ channelId }: { channelId: number }) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get<VideoLiveSource>(`/v1/channels/${channelId}/url`);
      setVideoLiveSource({ ...data });
      setIsLoading(false);
    } catch (err) {
      setVideoLiveSource({ status: 500, url: '', token: '', channelId });
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary
      reloadChildrenOnTryAgain
      tryAgainClick={async () => {
        await tryAgainClick({ channelId: channel.id });
        // mutate(`/v1/channels/${channel.id}/url`, undefined, true);
      }}
      fallback={(props) =>
        !isLoading ? (
          <ErrorSlot
            {...props}
            // errorRetryInterval={1500}
            {...{ order, mosaicId, channel, removeChannelClick, errorRetryInterval }}
          />
        ) : (
          <Box
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#000000',
            })}
          >
            <CircularProgress />
          </Box>
        )
      }
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryVideo;
