import React from 'react';
import { useRouteMatch, Redirect, useHistory, Switch, Route, RouteProps } from 'react-router-dom';
import routes from 'helpers/routes';
import { Tabs, Tab, AppBar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomerGroup from 'components/CustomerGroup';
import ChannelGroup from 'components/ChannelGroup';
import User, { UserCreate, UserEdit } from 'components/User';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import OperationLogs from 'components/OperationLogs';
import OperationLogDetails from 'components/OperationLogDetails';
import RecordingSchedule, {
  RecordingScheduleCreate,
  RecordingScheduleEdit,
} from 'components/RecordingSchedule';
import ChannelLimitPage from 'components/ChannelLimitPage/ChannelLimitPage';
import { CompanyPage } from 'pages/CompanyPage';
import CompanyEditPage from 'pages/CompanyEditPage/CompanyEditPage';
import { useIsWindows } from 'hooks/useIsWindows';
import CompanyNewPage from 'pages/CompanyNewPage/CompanyNewPage';
import HikvisionSettingsPage from 'pages/HikvisionSettingsPage';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { useClientPlataform } from 'hooks/useClientPlataform';
import { Container } from './SettingsPage.styles';
import SpeedTestTab from './tabs/SpeedTest/SpeedTestTab';
import { ClientPlataformEnum } from './tabs/SpeedTest/types/speedTest.types';

function SettingsPage() {
  const match = useRouteMatch(routes.settings.root);
  const isWindows = useIsWindows();

  const matchChildren = useRouteMatch([
    routes.settings.user.root,
    routes.settings.usageSummary.root,
    routes.settings.channelGroup,
    routes.settings.customerGroup,
    routes.settings.companies.root(),
    routes.settings.operationLogs.root(),
    routes.settings.channels,
    routes.settings.recordingSchedule.root,
    routes.settings.hikvision,
    routes.settings.speedTest.root,
  ]);
  const history = useHistory();
  const { t } = useTranslation(['settings', '_common']);
  const havePermissionFn = useHavePermission();
  const viewUserPermission = havePermissionFn(PermissionRole.VIEW_USER);
  const viewChannelGroupPermission = havePermissionFn(PermissionRole.VIEW_CHANNEL_GROUP);
  const viewClientGroupPermission = havePermissionFn(PermissionRole.VIEW_CLIENT_GROUP);
  const adminPermission = havePermissionFn(PermissionRole.ADMIN);
  const editUserPermission = havePermissionFn(PermissionRole.EDIT_USER);
  const insertUserPermission = havePermissionFn(PermissionRole.INSERT_USER);
  const viewChannel = havePermissionFn(PermissionRole.VIEW_CHANNEL);
  const viewCompanies = havePermissionFn(PermissionRole.VIEW_COMPANY);
  const insertCompanies = havePermissionFn(PermissionRole.INSERT_COMPANY);
  const editCompanies = havePermissionFn(PermissionRole.EDIT_COMPANY);
  const viewHikvisionSettings = havePermissionFn(PermissionRole.VIEW_HIKVISION_SETTINGS);

  const [speedTestFlag] = useDecision(FEATURE_FLAGS_KEYS.DIAGNOSTICO_DE_CONEXAO);
  const [speedTestOnlyWindowsFlag] = useDecision(
    FEATURE_FLAGS_KEYS.DIAGNOSTICO_DE_CONEXAO_ONLY_WINDOWS
  );

  const isWindowsFlag = speedTestOnlyWindowsFlag.enabled ? isWindows : true; // permite habilitar remotamente a visibilidade do campo para ambiente linux
  const clientPlataform = useClientPlataform();
  const isBrowserPlataform = clientPlataform === ClientPlataformEnum.WEB_BROWSER;

  if (!match) return null;

  if (match.isExact) {
    return <RedirectSettingsInitial />;
  }

  return (
    <Container>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={matchChildren?.path}
          indicatorColor="primary"
          textColor="inherit"
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
        >
          {viewUserPermission && (
            <Tab
              label={t('settings:users')}
              value={routes.settings.user.root}
              onClick={() => history.push(routes.settings.user.root)}
            />
          )}
          {viewChannelGroupPermission && (
            <Tab
              label={t('settings:channel_group')}
              value={routes.settings.channelGroup}
              onClick={() => history.push(routes.settings.channelGroup)}
            />
          )}
          {viewClientGroupPermission && (
            <Tab
              label={t('_common:customer_group')}
              value={routes.settings.customerGroup}
              onClick={() => history.push(routes.settings.customerGroup)}
            />
          )}

          {viewCompanies && (
            <Tab
              label={t('settings:companies')}
              value={routes.settings.companies.root()}
              onClick={() => history.push(routes.settings.companies.root())}
            />
          )}

          {adminPermission && (
            <Tab
              label={t('settings:operation_logs')}
              value={routes.settings.operationLogs.root()}
              onClick={() => history.push(routes.settings.operationLogs.root())}
            />
          )}

          {adminPermission && (
            <Tab
              label={t('_common:channels')}
              value={routes.settings.channels}
              onClick={() => history.push(routes.settings.channels)}
            />
          )}
          {viewHikvisionSettings && (
            <Tab
              label={t('HIKVISION')}
              value={routes.settings.hikvision}
              onClick={() => history.push(routes.settings.hikvision)}
            />
          )}
          {adminPermission && (
            <Tab
              label={t('_common:recording_schedule')}
              value={routes.settings.recordingSchedule.root}
              onClick={() => history.push(routes.settings.recordingSchedule.root)}
            />
          )}
          {adminPermission && isWindowsFlag && speedTestFlag.enabled && isBrowserPlataform && (
            <Tab
              label={t('_common:speed_test')}
              value={routes.settings.speedTest.root}
              onClick={() => history.push(routes.settings.speedTest.root)}
            />
          )}
        </Tabs>
      </AppBar>
      <Switch>
        <PermissionedRoute
          permission={viewClientGroupPermission}
          path={routes.settings.customerGroup}
          exact
          component={CustomerGroup}
        />
        <PermissionedRoute
          permission={viewChannelGroupPermission}
          path={routes.settings.channelGroup}
          exact
          component={ChannelGroup}
        />
        <PermissionedRoute
          permission={viewUserPermission}
          path={routes.settings.user.root}
          exact
          component={User}
        />
        <PermissionedRoute
          permission={editUserPermission}
          path={routes.settings.user.edit(':userId')}
          exact
          component={UserEdit}
        />
        <PermissionedRoute
          permission={insertUserPermission}
          path={routes.settings.user.create}
          exact
          component={UserCreate}
        />
        <PermissionedRoute
          permission={viewCompanies}
          path={routes.settings.companies.root()}
          exact
          component={CompanyPage}
        />
        <PermissionedRoute
          permission={insertCompanies}
          path={routes.settings.companies.create}
          exact
          component={CompanyNewPage}
        />
        <PermissionedRoute
          permission={editCompanies}
          path={routes.settings.companies.edit(':id')}
          exact
          component={CompanyEditPage}
        />
        <PermissionedRoute
          permission={adminPermission}
          path={routes.settings.operationLogs.root()}
          exact
          component={OperationLogs}
        />
        <PermissionedRoute
          permission={adminPermission}
          path={routes.settings.operationLogs.details(':operationLogId')}
          exact
          component={OperationLogDetails}
        />
        <PermissionedRoute
          permission={viewChannel}
          path={routes.settings.channels}
          exact
          component={ChannelLimitPage}
        />
        <PermissionedRoute
          permission={viewChannel}
          path={routes.settings.hikvision}
          exact
          component={HikvisionSettingsPage}
        />
        {speedTestFlag.enabled && isWindowsFlag && (
          <PermissionedRoute
            permission={adminPermission}
            path={routes.settings.speedTest.root}
            exact
            component={SpeedTestTab}
          />
        )}
        <Route path={routes.settings.recordingSchedule.root} exact component={RecordingSchedule} />
        <Route
          path={routes.settings.recordingSchedule.create}
          exact
          component={RecordingScheduleCreate}
        />
        <Route
          path={routes.settings.recordingSchedule.edit(':id')}
          exact
          component={RecordingScheduleEdit}
        />
      </Switch>
    </Container>
  );
}

function PermissionedRoute({ permission, ...routeProps }: RouteProps & { permission: boolean }) {
  if (!permission) {
    return <RedirectSettingsInitial />;
  }

  return <Route {...routeProps} />;
}

function RedirectSettingsInitial() {
  const havePermissionFn = useHavePermission();
  const viewUserPermission = havePermissionFn(PermissionRole.VIEW_USER);
  const viewChannelGroupPermission = havePermissionFn(PermissionRole.VIEW_CHANNEL_GROUP);
  const viewClientGroupPermission = havePermissionFn(PermissionRole.VIEW_CLIENT_GROUP);
  const adminPermission = havePermissionFn(PermissionRole.ADMIN);

  if (viewUserPermission) return <Redirect to={routes.settings.user.root} />;
  if (adminPermission) return <Redirect to={routes.settings.operationLogs.root()} />;
  if (viewChannelGroupPermission) return <Redirect to={routes.settings.channelGroup} />;
  if (viewClientGroupPermission) return <Redirect to={routes.settings.customerGroup} />;
  return <Redirect to="/" />;
}

export default SettingsPage;
