import React from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ApiBoundary from 'components/ApiBoundary';
import { useQuery } from 'hooks';
import { DateRangeValue } from 'components/DateRange';
import routes from 'helpers/routes';

import { useRecoilValue } from 'recoil';
import DetailStorageCostsChart from './DetailStorageCostsChart';
import { INIT_RANGE } from './metrics';
import HeaderTab from './HeaderTab';
import CurrentPlan from '../../components/CurrentPlan/index';
import { storageSize } from './storage';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 1),
  flexDirection: 'column',
  flex: '1 1 50%',
  height: '100%',
  overflow: 'auto',
}));

const ChartBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  flex: '1 1 auto',
  borderRadius: '4px',
}));

function StorageTab() {
  const { t } = useTranslation(['metrics', 'channels', 'usage_summary']);
  const query = useQuery();
  const consumption = useRecoilValue(storageSize);
  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue>(
    startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : INIT_RANGE
  );

  return (
    <>
      <Container>
        <HeaderTab
          title={t('usage_summary:used_space')}
          subtitle={t('by_recording_types')}
          routeFn={routes.metrics.storage}
          {...{
            range,
            setRange,
          }}
        />
        <ChartBox>
          <ApiBoundary>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <DetailStorageCostsChart startDate={range[0]} endDate={range[1]} />
              <CurrentPlan consumptionSize={consumption} />
            </Box>
          </ApiBoundary>
        </ChartBox>
      </Container>
    </>
  );
}

export default StorageTab;
