import { SelectCompanyTypeValue } from './companies.types';
import { CustomerGroup } from './customerGroup.types';

export enum ClientType {
  COMMERCIAL = 'COMMERCIAL',
  ACCESS_CONTROL = 'ACCESS_CONTROL',
  RESIDENTIAL = 'RESIDENTIAL',
  PUBLIC = 'PUBLIC',
}

export interface CustomerInitialValues {
  status: boolean;
  clientGroup?: CustomerGroup;
  address: string;
  addressNumber?: number;
  addressComplement?: string;
  addressReference?: string;
  companyName: string;
  tradeName: string;
  district: string;
  city: string;
  zipcode: string;
  phone: string;
  phone2?: string;
  clientType?: ClientType;
  state: string;
  email?: string;
  idMask: string;
  id?: number;
  company?: SelectCompanyTypeValue;
  enableFailTolerance: boolean;
  failToleranceTime: number;
}

export interface Customer {
  status: boolean;
  clientGroup?: CustomerGroup;
  address: string;
  addressNumber: number;
  addressComplement?: string;
  addressReference?: string;
  companyName: string;
  tradeName: string;
  district: string;
  city: string;
  zipcode: string;
  phone: string;
  phone2?: string;
  clientType: ClientType;
  state: string;
  email?: string;
  idMask: string;
  id: number;
  company?: SelectCompanyTypeValue;
  enableFailTolerance: boolean;
  failToleranceTime: number;
}

export const DEFAULT_CUSTOMER: CustomerInitialValues = {
  status: true,
  address: '',
  addressNumber: undefined,
  companyName: '',
  tradeName: '',
  addressComplement: '',
  district: '',
  city: '',
  zipcode: '',
  phone: '',
  clientType: undefined,
  state: '',
  email: '',
  idMask: '',
  clientGroup: undefined,
  enableFailTolerance: false,
  failToleranceTime: 0, // to seconds
};
