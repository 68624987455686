import { ScaleTime, select, axisBottom, Selection, timeFormat, timeHour } from 'd3';
import React, { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Mode, themeSelectedAtom } from 'atoms/config';
import {
  periodEndDateFullscreenAtom,
  periodStartDateFullscreenAtom,
} from 'atoms/cronologicBar-fullscreen';

function XAxis({
  left,
  onSelectDate,
  height,
  xScale,
  id,
}: {
  height?: number;
  xScale: ScaleTime<number, number, never>;
  id: string;
  left: number;
  onSelectDate: (dateClicked: Date) => void;
}) {
  const internalStartDate = useRecoilValue(periodStartDateFullscreenAtom(id));
  const internalEndDate = useRecoilValue(periodEndDateFullscreenAtom(id));
  const themeSelected = useRecoilValue(themeSelectedAtom);

  const dateFormat = useCallback(
    (date: Date) => {
      const showDays = timeHour.count(internalStartDate, internalEndDate) >= 12;
      return timeFormat(showDays ? '%d/%m %H:%M' : '%H:%M')(date);
    },
    [internalEndDate, internalStartDate]
  );
  useEffect(
    function mountXAxis() {
      const group = select(`#x-axis-${id}-fullscreen`) as Selection<
        SVGGElement,
        unknown,
        HTMLElement,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any
      >;
      if (height) {
        const tickSize = 0.2 * height;
        group.attr('transform', `translate(0, ${height - (tickSize + 14 + 4)})`);
        const xAxis = axisBottom(xScale)
          .tickSize(tickSize)
          .tickFormat((d) => dateFormat(d as Date));
        group
          .attr('color', themeSelected === Mode.DARK ? 'white' : '#22222290')
          .style('font-size', '0.75rem')
          .style('font-family', 'Roboto, Helvetica, Arial, sans-serif')
          .style('font-style', 'normal')
          .style('font-weight', '500')
          .style('line-height', '1.333')
          .style('letter-spacing', '0.5px')
          .on('click', function changePointer(e: MouseEvent) {
            const dateClicked = xScale.invert(e.clientX - (left || 0));
            onSelectDate(dateClicked);
          })
          .call(xAxis);
      }
      return () => {
        group.selectChildren().remove();
      };
    },
    [dateFormat, height, xScale, id, left, onSelectDate, themeSelected]
  );
  return <g id={`x-axis-${id}-fullscreen`} />;
}

export default XAxis;
