import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CancelButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Button variant="text" color="primary" onClick={onClick} disabled={disabled}>
      {t('_common:cancel')}
    </Button>
  );
}
