import EmptyState from 'components/EmptyState';
import routes from 'helpers/routes';
import { useFetch, useQuery } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CompanySimplifyListItem } from 'types/companies.types';
import { PermissionRole } from 'types/user.types';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Box } from '@mui/material';
import { asyncComponent } from 'HOCs';
import { ConfirmModal } from 'components/core';
import { deleteConfirmOpenedAtom, companiesSearchTextAtom } from 'atoms/companies';
import { useCompaniesApi } from 'hooks/companies/useCompaniesApi';
import CompanyList, { CompanyListLoading } from './CompanyList';
import CompanySquare from './CompanySquare';

function CompaniesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const text = useRecoilValue(companiesSearchTextAtom);
  const { data } = useFetch<CompanySimplifyListItem[]>('/v1/companies', {
    params: { text },
  });
  const [loading, setLoading] = React.useState(false);
  const [deleteConfirmOpened, setDeleteConfirmOpened] = useRecoilState(deleteConfirmOpenedAtom);
  const { deleteCompany } = useCompaniesApi();

  const handleDeleteCompany = async (id: number) => {
    try {
      setDeleteConfirmOpened(false);
      setLoading(true);
      await deleteCompany(id);
      setLoading(false);
      history.push('/settings/companies');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteConfirmOpened(false);
    }
  };

  return (
    <Box
      sx={{
        padding: '16px 5px',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        width: '100%',
      }}
    >
      {!text && !data?.length ? (
        <EmptyState
          title={t('_common:have_not_company')}
          description={t('_common:description_of_multicompanies_empty_state')}
          captionButton={t('_common:register_first_company')}
          onAddClick={() => history.push(routes.settings.companies.create)}
          roleAdd={PermissionRole.ADMIN}
        />
      ) : (
        <CompanyList {...{ data }} />
      )}

      <CompanySquare {...{ data }} />

      <ConfirmModal
        open={deleteConfirmOpened}
        setOpen={setDeleteConfirmOpened}
        doConfirm={() => handleDeleteCompany(Number(query.get('selectedCompany')))}
        disabled={loading}
        loading={loading}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        title={t('company:want_delete_company', {
          name: data?.find((company) => company.id === Number(query.get('selectedCompany')))
            ?.companyName,
        })}
        description={t('company:company_will_no_longer_displayed')}
      />
    </Box>
  );
}

export default asyncComponent(
  CompaniesPage,
  <Box
    sx={{
      padding: '2.7rem 2.12rem',
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      width: '100%',
    }}
  >
    <CompanyListLoading />
  </Box>
);
