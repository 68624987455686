import { Box, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useYupObject } from 'hooks';
import { Form } from 'components/core';

function GetFileNameModal({
  open,
  onClose,
  setFileName,
}: {
  open: boolean;
  onClose: () => void;
  setFileName: (name: string) => void;
}) {
  const { t } = useTranslation();
  const yup = useYupObject();

  const validationSchema = yup.object({
    name: yup.string().trim().required(),
  });
  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setFileName(values.name);
      setSubmitting(false);
      onClose();
    },
  });

  return (
    <Dialog
      {...{ open, onClose }}
      fullWidth
      maxWidth="md"
      aria-labelledby="get-file-name-modal-title"
    >
      <Box padding="16px 24px" marginBottom="16px" bgcolor="background.default">
        <Typography id="get-file-name-modal-title">
          {t('calculator:download_cost_simulation')}
        </Typography>
        <Typography variant="body2">
          {t('calculator:choose_a_name_to_describe_this_cost_simulation')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            id="name"
            name="name"
            label={t('_common:name')}
            autoFocus
            fullWidth
            helperText={formik.touched.name && formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
        </DialogContent>
        <DialogActions>
          <Form.CancelButton onClick={onClose} isSubmitting={formik.isSubmitting} />
          <Form.SaveButton
            label={t('_common:download')}
            initialValues={formik.initialValues}
            values={formik.values}
            isSubmitting={formik.isSubmitting}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default GetFileNameModal;
