import { PermissionGroup, PermissionRole } from 'types/user.types';

const roles = [
  {
    label: 'remove_channel',
    role: PermissionRole.REMOVE_CHANNEL,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'insert_playlist',
    role: PermissionRole.INSERT_PLAYLIST,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'remove_client_group',
    role: PermissionRole.REMOVE_CLIENT_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  { label: 'edit_user', role: PermissionRole.EDIT_USER, groups: [PermissionGroup.ADMIN] },
  { label: 'remove_user', role: PermissionRole.REMOVE_USER, groups: [PermissionGroup.ADMIN] },
  { label: 'view_user', role: PermissionRole.VIEW_USER, groups: [PermissionGroup.ADMIN] },
  {
    label: 'view_client',
    role: PermissionRole.VIEW_CLIENT,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.COMMERCIAL,
      PermissionGroup.OPERATOR,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  {
    label: 'view_channel_group',
    role: PermissionRole.VIEW_CHANNEL_GROUP,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.OPERATOR,
      PermissionGroup.COMMERCIAL,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  {
    label: 'edit_channel_group',
    role: PermissionRole.EDIT_CHANNEL_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'remove_client',
    role: PermissionRole.REMOVE_CLIENT,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'insert_client_group',
    role: PermissionRole.INSERT_CLIENT_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'remove_channel_group',
    role: PermissionRole.REMOVE_CHANNEL_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'edit_mosaic',
    role: PermissionRole.EDIT_MOSAIC,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'insert_channel',
    role: PermissionRole.INSERT_CHANNEL,
    groups: [PermissionGroup.ADMIN, PermissionGroup.COMMERCIAL, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'insert_mosaic',
    role: PermissionRole.INSERT_MOSAIC,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'edit_client',
    role: PermissionRole.EDIT_CLIENT,
    groups: [PermissionGroup.ADMIN, PermissionGroup.COMMERCIAL],
  },
  {
    label: 'insert_client',
    role: PermissionRole.INSERT_CLIENT,
    groups: [PermissionGroup.ADMIN, PermissionGroup.COMMERCIAL],
  },
  {
    label: 'view_mosaic',
    role: PermissionRole.VIEW_MOSAIC,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.OPERATOR,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  {
    label: 'remove_mosaic',
    role: PermissionRole.REMOVE_MOSAIC,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'view_playlist',
    role: PermissionRole.VIEW_PLAYLIST,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.OPERATOR,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  {
    label: 'edit_playlist',
    role: PermissionRole.EDIT_PLAYLIST,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'view_client_group',
    role: PermissionRole.VIEW_CLIENT_GROUP,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.OPERATOR,
      PermissionGroup.COMMERCIAL,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  {
    label: 'edit_channel',
    role: PermissionRole.EDIT_CHANNEL,
    groups: [PermissionGroup.ADMIN, PermissionGroup.COMMERCIAL, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'insert_channel_group',
    role: PermissionRole.INSERT_CHANNEL_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'view_channel',
    role: PermissionRole.VIEW_CHANNEL,
    groups: [
      PermissionGroup.ADMIN,
      PermissionGroup.CLIENT,
      PermissionGroup.COMMERCIAL,
      PermissionGroup.OPERATOR,
      PermissionGroup.OPERATOR_LOW,
      PermissionGroup.SUPERVISOR,
    ],
  },
  { label: 'insert_user', role: PermissionRole.INSERT_USER, groups: [PermissionGroup.ADMIN] },
  {
    label: 'edit_client_group',
    role: PermissionRole.EDIT_CLIENT_GROUP,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'remove_playlist',
    role: PermissionRole.REMOVE_PLAYLIST,
    groups: [PermissionGroup.ADMIN, PermissionGroup.OPERATOR, PermissionGroup.SUPERVISOR],
  },
  {
    label: 'insert_company',
    role: PermissionRole.INSERT_COMPANY,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'remove_company',
    role: PermissionRole.REMOVE_COMPANY,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'edit_company',
    role: PermissionRole.EDIT_COMPANY,
    groups: [PermissionGroup.ADMIN],
  },
  {
    label: 'view_company',
    role: PermissionRole.VIEW_COMPANY,
    groups: [PermissionGroup.ADMIN],
  },
];

export default roles;
