import EmptyState from 'components/EmptyState';
import { asyncComponent } from 'HOCs';
import { useFetch, useQuery } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperationLog, OperationLogNormalized } from 'types/operationLogs.types';
import OperationLogsFilters from './OperationLogsFilters';
import OperationLogsTable from './OperationLogsTable';

function OperationLogs({
  logs,
  haveQueryCriteria,
}: {
  logs: OperationLogNormalized[];
  haveQueryCriteria: boolean;
}) {
  const { t } = useTranslation(['operation_logs', '_common', 'customers', 'settings']);

  if (logs.length === 0 && !haveQueryCriteria) {
    return (
      <EmptyState
        title={t('your_account_does_not_have_operation_logs_yet')}
        description={t(
          'operation_logs_are_information_about_the_changes_that_have_been_made_to_the_account'
        )}
      />
    );
  }

  return (
    <>
      <OperationLogsFilters />
      <OperationLogsTable {...{ logs }} />
    </>
  );
}

function OperationLogsFetch() {
  const query = useQuery();

  const { data: logs } = useFetch<OperationLog[], OperationLogNormalized[]>('/v1/systemLogs', {
    params: {
      startDate: query.get('startDate'),
      endDate: query.get('endDate'),
      clientId: query.get('customer'),
      userId: query.get('user'),
      operation: query.get('operation'),
      item: query.get('item'),
    },
    normalizeData: (_logs: OperationLog[]) =>
      _logs.map((log) => ({
        ...log,
        ...log.client,
        companyName: log?.objectData?.company_name,
        date: new Date(log.date),
      })),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const haveQueryCriteria = React.useMemo(() => !!query.toString(), [query.toString()]);

  return logs ? <OperationLogs {...{ logs, haveQueryCriteria }} /> : null;
}

export default asyncComponent(OperationLogsFetch);
