import React from 'react';
import { Box } from '@mui/material';
import { NetworkStatus } from 'topbar/MosaicTopBar/NetworkStatus';
import { UnstableConnectionStatus } from './UnstableConnectionStatus';
import { UnstableChannelsStatus } from './UnstableChannelsStatus';

function ConnectionStatus() {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UnstableChannelsStatus />
      <UnstableConnectionStatus />
      <NetworkStatus />
    </Box>
  );
}

export default ConnectionStatus;
