import React from 'react';
import { User } from 'types/user.types';
import InfoItem from 'components/InfoItem';
import { useTranslation } from 'react-i18next';
import { Typography, IconButton, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import routes from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import BadgeActiveContent from 'components/BadgeActiveContent';
import { ContainerDetails, HeaderDetails } from './User.styles';
import UserRoles from './UserRoles';

function UserDetails({ user }: { user: User }) {
  const history = useHistory();
  const { t } = useTranslation(['users', 'roles']);

  const handleEdit = () => {
    history.push(routes.settings.user.edit(user.id));
  };
  return (
    <>
      <ContainerDetails role="region" aria-label={t('general_data')}>
        <HeaderDetails>
          <Typography>{t('general_data').toUpperCase()}</Typography>

          <IconButton onClick={() => handleEdit()} aria-label="edit" size="large">
            <Edit />
          </IconButton>
        </HeaderDetails>
        <InfoItem label={t('user_name')}>
          <Box width="8px" display="inline-block" marginRight="4px">
            <BadgeActiveContent active={!!user.status} />
          </Box>
          <Box display="inline-block" marginRight="4px">
            <Typography variant="body1">{user.username}</Typography>
          </Box>
          <Typography variant="body2" color="textSecondary" display="inline">
            {user.status ? `(${t('active')})` : `(${t('inactive')})`}
          </Typography>
        </InfoItem>
        <InfoItem label={t('email')}>{user.email}</InfoItem>
        <br />
        {user.enablePermissionCompany && (
          <>
            <InfoItem label={t('company:companies')}>
              {user?.companies?.map((company) => company.companyName).join(', ')}
            </InfoItem>
          </>
        )}
        <Typography>{t('permissions').toUpperCase()}</Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          {t(`user_permission_${user.permission}`)}
        </Typography>
        <UserRoles groupPermission={user.permission} />
      </ContainerDetails>
    </>
  );
}

export default UserDetails;
