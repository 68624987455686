import { atom } from 'recoil';
import { PlaylistItem } from 'types/playlist';

export const savingPlaylistItemAtom = atom<boolean>({
  key: 'savingPlaylistItemAtom',
  default: false,
});

export const savingPlaylistItemErrorAtom = atom<boolean>({
  key: 'savingPlaylistItemErrorAtom',
  default: false,
});

export const addMosaicToPlaylistModalOpenedAtom = atom<boolean>({
  key: 'addMosaicToPlaylistModalOpenedAtom',
  default: false,
});

export const removeMosaicFromPlaylistModalOpenedAtom = atom<boolean>({
  key: 'removeMosaicFromPlaylistModalOpenedAtom',
  default: false,
});

export const itemAtualAtom = atom<PlaylistItem | null>({
  key: 'playlist.itemAtualAtom',
  default: null,
});