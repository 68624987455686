import React from 'react';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  PaletteOptions,
  alpha,
} from '@mui/material/styles';
import { blue, green, grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { themeSelectedAtom, Mode } from 'atoms/config';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'vms-h2': React.CSSProperties;
    'vms-body2': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'vms-h2'?: React.CSSProperties;
    'vms-body2'?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'vms-h2': true;
    'vms-body2': true;
  }
}

const commonPalette: PaletteOptions = {
  primary: blue,
  success: { ...green, contrastText: 'white' },
  secondary: {
    contrastText: '#fff',
    dark: '#c51162',
    light: '#ff4081',
    main: '#f50057',
  },
  error: {
    main: '#F03A48',
  },
  info: {
    contrastText: '#fff',
    dark: '#1976d2',
    light: '#64b5f6',
    main: '#2196f3',
  },
  grey: {
    main: grey[300],
    dark: grey[400],
  },
  warning: {
    main: '#F57C00',
    light: '#FFC891',
    dark: '#FFA726',
  },
};

const darkPalette: PaletteOptions = {
  mode: 'dark',
  ...commonPalette,
  background: {
    default: '#303030',
    paper: 'rgb(54, 54, 54)',
  },
  action: {
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
  },
  secondary: {
    main: '#404040',
    light: '#414141',
  },

  warning: {
    main: '#FFA726',
    dark: '#F57C00',
    light: '#F9C685',
    contrastText: '#FA6A3E',
  },
  common: {
    white: '#FFF',
    black: '#1F1F1F',
  },
};

const lightPalette: PaletteOptions = {
  ...commonPalette,
  background: {
    paper: '#f2f2f2',
  },
  action: {
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
  },
  secondary: {
    main: '#FFFFFF',
    light: '#DDD',
  },
  warning: {
    main: '#F2C16D',
    dark: '#FA6A3E',
    light: '#FA8B3E',
    contrastText: '#FA6A3E',
  },
  common: {
    white: '#FFF',
    black: '#CCC',
  },
};

function MaterialUiTheme({ children }: { children: React.ReactNode }) {
  const themeSelected = useRecoilValue(themeSelectedAtom);

  const themeMode = createTheme({
    palette: themeSelected === Mode.DARK ? darkPalette : lightPalette,
  });

  const theme: Theme = createTheme({
    palette: themeSelected === Mode.DARK ? darkPalette : lightPalette,
    components: {
      MuiTypography: {
        defaultProps: {
          color: 'textPrimary',
          variantMapping: {
            'vms-h2': 'h2',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          FormHelperTextProps: {
            disabled: true /* to put color with opacity 0.5 */,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999999s', // 9999999 / 60 / 60 = 2777,7775 hours to change the input color when autofill
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {

            '&.MuiTableCell-head': {
              backgroundColor: themeSelected === Mode.DARK ? '#1F1F1F' : '#CCC',
              color: themeSelected === Mode.DARK ? '#FFF' : '#000',
            }
          },
        },
      },
      MuiPaper: {
        styleOverrides: { root: { backgroundImage: 'unset' } },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiTab: {
        styleOverrides: { root: { minWidth: '160px' } },
      },
      MuiListItem: {
        defaultProps: {
          disablePadding: true,
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            transition: 'all 125ms ease-in-out',
            '&.Mui-selected': {
              backgroundColor: themeMode.palette.action.selected,
              borderLeft: `3.5px solid ${themeMode.palette.text.primary}`,
            },
            '&.Mui-selected:hover': {
              backgroundColor: themeMode.palette.action.hover,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'grey',
        },
        variants: [
          {
            props: { variant: 'contained', color: 'grey' },
            style: {
              color: themeMode.palette.getContrastText(themeMode.palette.grey[300]),
            },
          },
          {
            props: { variant: 'outlined', color: 'grey' },
            style: {
              color: themeMode.palette.text.primary,
              borderColor:
                themeMode.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              '&.Mui-disabled': {
                border: `1px solid ${themeMode.palette.action.disabledBackground}`,
              },
              '&:hover': {
                borderColor:
                  themeMode.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.23)'
                    : 'rgba(255, 255, 255, 0.23)',
                backgroundColor: alpha(
                  themeMode.palette.text.primary,
                  themeMode.palette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: { color: 'grey', variant: 'text' },
            style: {
              color: themeMode.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(
                  themeMode.palette.text.primary,
                  themeMode.palette.action.hoverOpacity
                ),
              },
            },
          },
        ],
      },
    },
    typography: {
      body2: {
        fontWeight: 300,
      },
      'vms-h2': {
        fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
        fontSize: '1.5rem',
        fontWeight: 500,
        letterSpacing: '0.18px',
        lineHeight: 1,
      },
      'vms-body2': {
        fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.25px',
      },
    },
  });
  // eslint-disable-next-line no-console
  process.env.REACT_APP_ENV !== 'test' && console.log(theme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MaterialUiTheme;
