import { Dialog, DialogContent } from '@mui/material';

import React from 'react';
import CalculatorForm from './CalculatorForm/CalculatorForm';
import { CalculatorHeaderTitle } from './CalculatorHeaderTitle';

function CalculatorModal({
  modalOpened,
  setModalOpened,
}: {
  modalOpened: boolean;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Dialog
      open={modalOpened}
      onClose={() => setModalOpened(false)}
      fullWidth
      maxWidth="md"
      aria-labelledby="calculator-modal-title"
    >
      <CalculatorHeaderTitle />
      <DialogContent>
        <CalculatorForm onCancel={() => setModalOpened(false)} showCancelButton={true} />
      </DialogContent>
    </Dialog>
  );
}

export default CalculatorModal;
