import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  CircularProgress,
  Select,
  Checkbox,
  Box,
  Typography,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  label: {
    paddingRight: theme.spacing(1),
  },
}));

function SelectFetchLoading({ label }: { label: string }) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth disabled>
      <InputLabel id="select-loading-label">
        <Box display="flex">
          <Typography color="textSecondary" className={classes.label}>
            {`${label}...`}
          </Typography>
          <CircularProgress size={20} />
        </Box>
      </InputLabel>
      <Select labelId="select-loading-label" id="select-loading" value={[]} label={`${label}...`}>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default SelectFetchLoading;
