import { Box, styled } from '@mui/material';
import React from 'react';
import { useFetch } from 'hooks';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { asyncComponent } from 'HOCs';
import { PermissionRole } from 'types/user.types';
import { Playlist } from 'types/playlist';
import CreatePlaylistModal from './CreatePlaylistModal';
import PlaylistsList from './PlaylistsList';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

function PlaylistPage() {
  const { t } = useTranslation('playlists');
  const { data: playlists } = useFetch<Playlist[]>('/v1/playlists');
  const [createModalOpened, setCreateModalOpened] = React.useState(false);

  return (
    <Container>
      {!playlists || playlists?.length === 0 ? (
        <EmptyState
          title={t('your_account_does_not_have_mosaic_playlists_yet')}
          description={t('create_a_playlist_and_select_the_mosaics_and_order_you_want_to_display')}
          captionButton={t('create_a_playlist')}
          onAddClick={() => setCreateModalOpened(true)}
          roleAdd={PermissionRole.INSERT_PLAYLIST}
        />
      ) : (
        <PlaylistsList {...{ playlists, setCreateModalOpened }} />
      )}
      <CreatePlaylistModal open={createModalOpened} setOpen={setCreateModalOpened} />
    </Container>
  );
}

export default asyncComponent(PlaylistPage);
