import { Box, IconButton, Paper, Theme, Tooltip, Typography } from '@mui/material';
import { AddCircle, ViewModuleOutlined } from '@mui/icons-material';
import { textDebouncedAtom } from 'atoms/selectMosaicPanel';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MosaicCard } from 'components/ItemsCard';
import { Mosaic } from 'types/mosaics.types';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useSelectMosaicPanel } from 'hooks/mosaic/useSelectMosaicPanel';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultContainer: {
      backgroundColor: theme.palette.background.default,
      padding: '16px',
      marginTop: '16px',
      overflow: 'auto',
      flex: '1 1 auto',
      pr: 0,
    },
  })
);
export function ResultsContainer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const text = useRecoilValue(textDebouncedAtom);
  const { data: mosaicResults } = useFetch<Mosaic[]>('/v1/mosaics', {
    params: {
      text,
    },
  });
  const { closeMosaicSelection } = useSelectMosaicPanel();
  const { openCreateModal } = useCreateMosaicModal();
  const [ordered, setOrdered] = React.useState(false);

  if (!mosaicResults && !text) {
    // Dont have any mosaic registered
    closeMosaicSelection();
    return null;
  }

  return (
    <Paper className={classes.resultContainer}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          mb: 1,
          pr: 2,
        }}
      >
        <ViewModuleOutlined titleAccess="mosaic" />
        <Box flex="1 1 auto" marginLeft="0.4rem">
          <Typography>{t('main-left-menu:mosaics')}</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {`${mosaicResults?.length} ${t('_common:results')}`}
        </Typography>
        <Permissioned role={PermissionRole.INSERT_CHANNEL}>
          <Tooltip title={t('mosaics:create_mosaic')}>
            <Box marginLeft="4px">
              <IconButton size="small" aria-label="create mosaic" onClick={() => openCreateModal()}>
                <AddCircle />
              </IconButton>
            </Box>
          </Tooltip>
        </Permissioned>
        <Box marginLeft="4px">
          <Tooltip title={!ordered ? t('_common:order_by_id') : t('_common:order_by_alpha')}>
            <IconButton
              size="small"
              aria-label="order"
              onClick={() => {
                setOrdered(!ordered);
              }}
            >
              {ordered ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {!mosaicResults || mosaicResults.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Typography color="textSecondary">{t('crud_actions:no_results')}</Typography>
        </Box>
      ) : (
        mosaicResults
          .sort((a, b) => {
            if (ordered) {
              return b.id - a.id;
            }

            return a.name.localeCompare(b.name);
          })
          .map((mosaic) => (
            <MosaicCard
              key={mosaic.id}
              {...{ mosaic }}
              onClick={() => closeMosaicSelection()}
              callStartLive={text.length >= 4}
            />
          ))
      )}
    </Paper>
  );
}
