import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import ConfirmModal from './ConfirmModal';

export const useFormStyles = makeStyles<Theme, { dialog?: boolean }>({
  form: {
    overflow: 'hidden',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    height: '100%',
  },
  recordingTypeHorizontallyAligned: {
    display: 'flex',
  },
  formInputsContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: ({ dialog }) => (dialog ? '0px 24px 16px' : '32px'),
  },
  formFooter: {
    flex: '0 0 auto',
    padding: ({ dialog }) => (dialog ? '0px' : '20px'),
    textAlign: 'end',
  },
  formIntelbrasFooter: {
    flex: '0 0 auto',
    padding: ({ dialog }) => (dialog ? '0px' : '20px'),
    textAlign: 'end',
    marginTop: '-2rem',
  },
  formInputsGroupLabel: {
    marginBottom: '0.6rem',
  },
  BoxWithMarginTop: {
    marginTop: '2rem',
  },
  formInput: {
    marginBottom: '1.2rem',
  },
  inputMarginLeft: {
    marginLeft: '16px',
  },
});

export const useButtonsStyles = makeStyles((theme) => ({
  formDeleteButton: {
    float: 'left',
    padding: '5px 15px',
  },
  formCancelButton: {
    padding: '5px 15px',
  },
  formSaveButton: {
    marginLeft: '1rem',
    padding: '5px 15px',
  },
  formSaveSuccessButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

type FormOnDelete = (setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>) => void;

/**
 * return states to control the delete option in forms, with confirm modal
 * This hook works together with ConfirmModal, and a button
 *
 * IN:
 *
 * `onConfirmDelete`: a callback function to be called when user click in ok in ConfirmModal
 *
 * OUT:
 *
 * `isDeleting` indicates after user click in confirm in ConfirmModal
 * `deleteConfirmModalOpen` indicates when the ConfirmModal is opened
 * `handleDelete` a function to be called in prop `onConfirm` of ConfirmModal
 * `setDeleteConfirmModalOpen` a function to change the state `deleteConfirmModalOpen`, uses in delete button
 * * */

function useFormDelete({ onConfirmDelete }: { onConfirmDelete?: FormOnDelete }) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false);

  const handleDelete = React.useCallback(async () => {
    setIsDeleting(true);
    setDeleteConfirmModalOpen(false);
    onConfirmDelete && onConfirmDelete(setIsDeleting);
  }, [onConfirmDelete]);

  return { isDeleting, deleteConfirmModalOpen, handleDelete, setDeleteConfirmModalOpen };
}

function DeleteButton({
  isSubmitting,
  confirmModalTitle,
  confirmModalDescription,
  onConfirmDelete,
  variant,
}: {
  isSubmitting: boolean;
  confirmModalTitle: string;
  confirmModalDescription: string;
  onConfirmDelete?: FormOnDelete;
  variant?: 'text' | 'outlined' | 'contained';
}) {
  const { t } = useTranslation('crud_actions');
  const classes = useButtonsStyles();
  const {
    isDeleting,
    deleteConfirmModalOpen,
    handleDelete,
    setDeleteConfirmModalOpen,
  } = useFormDelete({ onConfirmDelete });

  return (
    <>
      <Button
        className={classes.formDeleteButton}
        aria-label="delete"
        color="error"
        disabled={isSubmitting}
        onClick={() => setDeleteConfirmModalOpen(true)}
        variant={variant}
      >
        {t('crud_actions:delete')}
      </Button>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        confirmActionText={t('crud_actions:delete')}
        title={confirmModalTitle}
        variant="contained"
        description={confirmModalDescription}
      />
    </>
  );
}

function CancelButton({
  isSubmitting,
  onClick,
  disabled,
}: {
  isSubmitting: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) {
  const { t } = useTranslation('crud_actions');
  const classes = useButtonsStyles();

  return (
    <Button
      className={classes.formCancelButton}
      aria-label="cancel"
      disabled={disabled || isSubmitting}
      onClick={onClick}
    >
      {t('crud_actions:cancel')}
    </Button>
  );
}

export const isSaveButtonDisabled = <Values extends FormikValues = FormikValues>({
  isSubmitting,
  initialValues,
  values,
}: {
  isSubmitting: boolean;
  initialValues: Values;
  values: Values;
}) => isSubmitting || equal(initialValues, values);

function SaveButton<Values extends FormikValues = FormikValues>({
  isSubmitting,
  dialog,
  disabled,
  label,
  initialValues,
  values,
  ...props
}: {
  isSubmitting: boolean;
  dialog?: boolean;
  disabled?: boolean;
  label?: string;
  initialValues: Values;
  values: Values;
} & ButtonProps) {
  const { t } = useTranslation('crud_actions');
  const classes = useButtonsStyles();
  return (
    <Button
      className={`${classes.formSaveButton} ${!dialog && classes.formSaveSuccessButton}`}
      variant={dialog ? undefined : 'contained'}
      type="submit"
      aria-label="save"
      color="primary"
      disabled={
        disabled === undefined
          ? isSaveButtonDisabled({ isSubmitting, initialValues, values })
          : disabled
      }
      {...props}
    >
      {isSubmitting ? (
        <CircularProgress size="1.6rem" color="inherit" />
      ) : (
        label || t('crud_actions:save')
      )}
    </Button>
  );
}

const Form = {
  DeleteButton,
  CancelButton,
  SaveButton,
};

export default Form;
