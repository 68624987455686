import { PermissionRole } from 'types/user.types';
import EmptyState from 'components/EmptyState';
import React from 'react';
import { useTranslation } from 'react-i18next';

function EmptyCustomer({ route }: { route: string }) {
  const { t } = useTranslation('customers');

  return (
    <EmptyState
      title={t('register_first_customer')}
      description={t('provide_information_register_channels')}
      captionButton={t('register_customer')}
      roleAdd={PermissionRole.INSERT_CLIENT}
      {...{ route }}
    />
  );
}

export default EmptyCustomer;
