import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Customer } from 'types/customer.types';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React, { FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';

function SelectCustomer(props: {
  selectedCustomer: FilterSelectValue | null;
  onChangeCustomer: (value: FilterSelectValue) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  filterFn?: (value: Customer, index: number, array: Customer[]) => boolean;
  disabled?: boolean;
}): React.ReactElement;
function SelectCustomer(props: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  name: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  className?: string;
  filterFn?: (value: Customer, index: number, array: Customer[]) => boolean;
  disabled?: boolean;
}): React.ReactElement;
function SelectCustomer({
  selectedCustomer,
  onChangeCustomer,
  onBlur,
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
  className,
  filterFn,
  disabled,
}: {
  selectedCustomer?: FilterSelectValue | null;
  onChangeCustomer?: (value: FilterSelectValue) => void;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  name?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  className?: string;
  filterFn?: (value: Customer, index: number, array: Customer[]) => boolean;
  disabled?: boolean;
}) {
  const { data: customers } = useFetch<Customer[]>('/v1/clients');
  const { t } = useTranslation('customers');

  React.useEffect(
    function setLabels() {
      if (selectedCustomer && customers) {
        onChangeCustomer?.({
          id: selectedCustomer.id,
          label: customers.find((customer) => customer.id === selectedCustomer.id)?.tradeName || '',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]
  );

  const customersFiltered = React.useMemo(
    () => (filterFn ? customers?.filter(filterFn) : customers),
    [customers, filterFn]
  );

  const handleChange = React.useCallback(
    (e) => {
      onChange?.(e);
      onChangeCustomer?.({
        id: e.target.value as number,
        label:
          customers?.find((customer) => customer.id === (e.target.value as number))?.tradeName ||
          '',
      });
    },
    [customers, onChange, onChangeCustomer]
  );

  return (
    <FormControl variant="outlined" fullWidth {...{ className, error }}>
      <InputLabel id="select-customer-label">{t('customer')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-customer-label"
        id="select-customer"
        onChange={handleChange}
        onBlur={onBlur}
        value={selectedCustomer?.id || value || ''}
        label={t('customer')}
        disabled={disabled}
      >
        {customersFiltered?.map(({ tradeName, id }) => (
          <MenuItem key={id} value={id}>
            {tradeName}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectCustomer.Loading = function SelectCustomerLoading() {
  const { t } = useTranslation('customers');
  return <SelectFetchLoading label={t('customer')} />;
};

export default SelectCustomer;
