import routes from 'helpers/routes';
import { useCallback } from 'react';
import { TFunction } from 'react-i18next';
import millisecondsToHHMMSS from 'helpers/millisecondsToHHMMSS';
import { Column, TableData } from 'types/table.types';
import { formatToReal } from 'helpers/formatToReal';
import { formatBytes } from 'helpers/bytesOperations';

interface ColumnsProps {
  t: TFunction<('usage_summary' | '_common' | 'channels' | 'metrics')[]>;
}

export default function useColumns({
  t,
}: ColumnsProps): (customerId: number) => Column<TableData>[] {
  // @ts-ignore
  return useCallback(
    (customerId: number) => [
      {
        description: t('channels:channel_name'),
        dataKey: 'name',
        linkTo: (row) => routes.customer.channel.view(customerId, row.id),
      },
      {
        description: 'ID',
        dataKey: 'id',
      },
      {
        description: t('_common:bitrate'),
        dataKey: 'bitrate',
        renderValue: (value) => `${value} kbps`,
      },
      {
        description: 'FPS',
        dataKey: 'fps',
      },
      {
        description: t('_common:resolution'),
        dataKey: 'resolution',
      },
      {
        description: t('channels:recording_type'),
        dataKey: 'recordingType',
        renderValue: (value) => t(`channels:recording_type_${value}`),
      },
      {
        description: t('used_space'),
        dataKey: 'usedSize',
        renderValue: (value) => (typeof value === 'number' ? formatBytes(value, 3) : ''),
      },
      {
        description: t('metrics:transfer_for_playback_of_recorded_video'),
        dataKey: 'vodSize',
        renderValue: (value) => (typeof value === 'number' ? formatBytes(value, 3) : ''),
      },
      {
        description: t('metrics:transfer_for_live_video'),
        dataKey: 'liveSize',
        renderValue: (value) => (typeof value === 'number' ? formatBytes(value, 3) : ''),
      },
      {
        description: t('live_video_time'),
        dataKey: 'liveDuration',
        renderValue: (value) => (typeof value === 'number' ? millisecondsToHHMMSS(value) : ''),
      },
      {
        description: t('recorded_video_time'),
        dataKey: 'recordedVideoDuration',
        renderValue: (value) => (typeof value === 'number' ? millisecondsToHHMMSS(value) : ''),
      },
      {
        description: t('metrics:transfer_for_recording'),
        dataKey: 'recordedVideoSize',
        renderValue: (value) => (typeof value === 'number' ? formatBytes(value, 3) : ''),
      },
      {
        description: t('metrics:playback_of_recorded_video'),
        dataKey: 'vodDuration',
        renderValue: (value) => (typeof value === 'number' ? millisecondsToHHMMSS(value) : ''),
      },
      {
        description: t('metrics:channel_cost'),
        dataKey: 'channelValue',
        renderValue: (value) => (typeof value === 'number' ? formatToReal(value) : '---'),
      },
    ],
    [t]
  );
}
