import React from 'react';
import { PermissionGroup, UserInitialValues } from 'types/user.types';
import { useUserCRUD } from 'hooks';
import UserForm from './UserForm';

const DEFAULT_USER: UserInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  permission: PermissionGroup.CLIENT,
  status: true,
  enablePermissionCompany: false,
  companies: [],
};

function UserCreate() {
  const { createUser } = useUserCRUD();

  return <UserForm initialValues={DEFAULT_USER} doSubmit={createUser} />;
}

export default UserCreate;
