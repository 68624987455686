import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ceps from 'assets/CEPs.json';

function SelectState({
  selectedState,
  onChangeSelectState,
  onBlurSelectState,
  error,
  helperText,
}: {
  selectedState: string;
  onChangeSelectState: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlurSelectState?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation(['customers', '_common']);

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-customer-state-label">{t('state')}</InputLabel>
      <Select
        name="state"
        id="state"
        label={t('state')}
        labelId="select-customer-state-label"
        fullWidth
        // @ts-ignore
        onChange={onChangeSelectState}
        value={selectedState}
        onBlur={onBlurSelectState}
      >
        {ceps.map((cep) => (
          <MenuItem aria-label="item" key={cep.id} value={cep.sigla}>
            {`${cep.sigla} - ${cep.nome}`}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectState;
