import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import { TestBatchDeviceIcon } from 'components/TestingDeviceFeedback/TestingDeviceFeedback';
import InfoItem from 'components/InfoItem';
import { CustomizedResponseCodeEnum } from 'types/channels.types';
import { testedHikvisonChannelsAtom } from 'atoms/hikvision';
import { useRecoilValue } from 'recoil';
import { HikVisionChannel } from 'types/hikvision.types';
import HikvisionDialogForm from './HikvisionDialogForm';

interface Props {
  channel: HikVisionChannel;
  keyChannel: number;
  customerId: string;
}
export default function HikvisionChannelPreview({ channel, keyChannel, customerId }: Props) {
  const { t } = useTranslation();
  const [updateConfirmModalOpen, setUpdateConfirmModalOpen] = React.useState(false);
  const testedChannels = useRecoilValue(testedHikvisonChannelsAtom);
  const status = testedChannels.find((item) => item.p2pChannel === channel.p2pChannel)
    ?.success as CustomizedResponseCodeEnum;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        component="div"
        justifyContent="space-between"
        m={2}
        p={2}
      >
        <Box>
          <Box mb={4}>
            <InfoItem label={t('channels:channel_name')}>{channel.name}</InfoItem>
          </Box>
          <Box display="flex" gap={2}>
            <InfoItem label={t('channels:channel_group')}>{channel.channelGroup?.name}</InfoItem>
            <InfoItem label={t('channels:recording_type')}>
              {t(`channels:recording_type_${channel.recordingType}`)}
            </InfoItem>
          </Box>
        </Box>
        <Box>
          <Box mb={4}>
            <Box display="flex" alignItems="center" gap={4}>
              <InfoItem label="Serial">{channel.serialNumber}</InfoItem>

              <IconButton
                aria-label={`edit ${channel.p2pChannel}`}
                onClick={() => {
                  setUpdateConfirmModalOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TestBatchDeviceIcon customizedCode={status} />
              <Typography variant="body2">{t('_common:channel')}</Typography>
              <Typography variant="body1">{channel.p2pChannel}</Typography>
            </Box>
          </Box>
        </Box>

        <HikvisionDialogForm
          open={updateConfirmModalOpen}
          channel={channel}
          handleClose={() => {
            setUpdateConfirmModalOpen(false);
          }}
          keyChannel={keyChannel}
          customerId={customerId}
        />
      </Box>
      <Divider />
    </>
  );
}
