import { Box, Chip, IconButton, Menu, MenuItem, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MoreVert } from '@mui/icons-material';
import { DateRangeValue } from 'components/DateRange';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperationItems } from 'types/operationLogs.types';
import { FilterSelectValue } from 'types/select.types';
import { Operation } from './SelectOperation';
import useItemTranslation from './useItemTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    chipContainer: {
      flex: '1 1 auto',
    },
    chip: {
      margin: theme.spacing(1),
    },
    moreButton: {
      flex: '0 0 auto',
      margin: 'auto',
    },
  })
);

function OperationLogsAppliedFilters({
  range,
  setRange,
  user,
  setUser,
  item,
  setItem,
  operation,
  setOperation,
}: {
  range: DateRangeValue | null;
  setRange: (value: DateRangeValue | null) => void;
  user: FilterSelectValue | null;
  setUser: (value: React.SetStateAction<FilterSelectValue | null>) => void;
  item: OperationItems | null;
  setItem: (value: React.SetStateAction<OperationItems | null>) => void;
  operation: Operation | null;
  setOperation: (value: React.SetStateAction<Operation | null>) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation(['_common', 'operation_logs']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const translateItem = useItemTranslation();
  const haveSomeFilter = React.useMemo(() => !!range || !!user || !!item || !!operation, [
    item,
    operation,
    range,
    user,
  ]);

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearAll = () => {
    setAnchorEl(null);
    setRange(null);
    setUser(null);
    setItem(null);
    setOperation(null);
  };

  if (!haveSomeFilter) return null;

  return (
    <Paper className={classes.root} role="region" aria-label="Filtros aplicados">
      <Box className={classes.chipContainer}>
        {range && (
          <Chip
            aria-label="Chip range"
            size="small"
            label={`${range[0].toLocaleDateString(
              navigator.language
            )} - ${range[1].toLocaleDateString(navigator.language)}`}
            onDelete={() => setRange(null)}
            className={classes.chip}
          />
        )}
        {user && (
          <Chip
            aria-label={`Chip user ${user.label}`}
            size="small"
            label={user.label}
            onDelete={() => setUser(null)}
            className={classes.chip}
          />
        )}
        {item && (
          <Chip
            aria-label={`Chip item ${translateItem(item)}`}
            size="small"
            label={translateItem(item)}
            onDelete={() => setItem(null)}
            className={classes.chip}
          />
        )}
        {operation && (
          <Chip
            aria-label={`Chip operation ${t(`operation_logs:operation_${operation}`)}`}
            size="small"
            label={t(`operation_logs:operation_${operation}`)}
            onDelete={() => setOperation(null)}
            className={classes.chip}
          />
        )}
      </Box>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickMore}
        className={classes.moreButton}
        aria-label="Menu dos filtros"
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-label="Menu dos filtros"
      >
        <MenuItem onClick={handleClearAll} aria-label="Limpar filtros">
          {t('_common:clean_filters')}
        </MenuItem>
      </Menu>
    </Paper>
  );
}

export default OperationLogsAppliedFilters;
