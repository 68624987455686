import { atom } from 'recoil';
import { CustomerGroup } from 'types/customerGroup.types';

export const customerGroupSearchTextAtom = atom<string>({
  key: 'customerGroupSearchTextAtom',
  default: '',
});

export const customerGroupInEditionAtom = atom<CustomerGroup | null>({
  key: 'customerGroupInEditionAtom',
  default: null,
});

export const customerGroupcreateModalOpenedAtom = atom<boolean>({
  key: 'customerGroupcreateModalOpenedAtom',
  default: false,
});