import { useTranslation } from 'react-i18next';
import { OperationItems } from 'types/operationLogs.types';

const TRANSLATIONS: { [key in OperationItems]: string } = {
  ClientGroup: 'customer_group',
  Channel: 'channel',
  Playlist: 'Playlist',
  User: 'operation_logs:user',
  MosaicItem: 'mosaic_item',
  Mosaic: 'mosaic',
  Client: 'customers:customer',
  PlaylistItem: 'Playlist Item',
  ChannelGroup: 'settings:channel_group',
  Company: 'company:company',
  MasterCompanySetting: 'operation_logs:master_company_setting',
  HikvisionSettings: 'hikvision_settings:config',
  ScheduledRecording: '_common:recording_schedule',
};

function useItemTranslation() {
  const { t } = useTranslation(['_common', 'operation_logs', 'customers', 'settings']);

  return (key: OperationItems) => t(TRANSLATIONS[key]);
}

export default useItemTranslation;
