import React from 'react';
import { PermissionGroup, PermissionRole } from 'types/user.types';
import roles from 'constants/roles';
import { Chip } from '@mui/material';
import { Check, Remove } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RolesContainer } from './User.styles';

interface RolesProps {
  label: string;
  role: PermissionRole;
  groups: PermissionGroup[];
}
function UserRoles({ groupPermission }: { groupPermission: PermissionGroup }) {
  const { t } = useTranslation('roles');

  const [currentRoles, setCurrentRoles] = React.useState<RolesProps[]>(roles);
  React.useEffect(() => {
    const newRoles: RolesProps[] = [
      ...roles,
      {
        label: 'insert_schedule_recordings',
        role: PermissionRole.INSERT_SCHEDULE_RECORDING,
        groups: [PermissionGroup.ADMIN],
      },
      {
        label: 'remove_schedule_recordings',
        role: PermissionRole.REMOVE_SCHEDULE_RECORDING,
        groups: [PermissionGroup.ADMIN],
      },
      {
        label: 'edit_schedule_recordings',
        role: PermissionRole.EDIT_SCHEDULE_RECORDING,
        groups: [PermissionGroup.ADMIN],
      },
      {
        label: 'view_schedule_recordings',
        role: PermissionRole.VIEW_SCHEDULE_RECORDING,
        groups: [PermissionGroup.ADMIN],
      },
    ];
    setCurrentRoles(newRoles);
  }, []);

  return (
    <RolesContainer>
      {currentRoles.map(({ role, label, groups }) => (
        <Chip
          key={role}
          size="small"
          variant="outlined"
          color={groups.includes(groupPermission) ? 'primary' : undefined}
          icon={groups.includes(groupPermission) ? <Check /> : <Remove />}
          label={t(label)}
          disabled={!groups.includes(groupPermission)}
        />
      ))}
    </RolesContainer>
  );
}

export default UserRoles;
