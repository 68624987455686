import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import Layout from 'components/Layout';
import routes from 'services/routes';
import { GATracker } from 'components/GATracker';
import { SocketConnection } from 'components/SocketConnection';
import { PermissionRole } from 'types/user.types';

import routesHelper from 'helpers/routes';
import MosaicFullScreenView from 'pages/MosaicFullScreenViewPage';
import PlaylistPlayFullscreenPage from 'pages/PlaylistPlayFullscreenPage';
import ErrorBoundary from 'components/ErrorBoundary';
import { ErrorBoundaryDefault } from 'components/ErrorBoundary/ErrorBoundary';

function Routes() {
  return (
    <BrowserRouter>
      <ErrorBoundary
        fallback={
          <Layout.Container>
            <ErrorBoundaryDefault
              handlerTryAgainClick={() => window.location.reload()}
              isApiError={false}
            />
          </Layout.Container>
        }
      >
        <GATracker />
        <SocketConnection />
        <Switch>
          <PrivateRoute
            component={MosaicFullScreenView}
            permission={PermissionRole.VIEW_MOSAIC}
            exact
            path={routesHelper.mosaic.fullscreen(':mosaicId')}
          />
          <PrivateRoute
            component={PlaylistPlayFullscreenPage}
            permission={PermissionRole.VIEW_PLAYLIST}
            exact
            path={routesHelper.playlists.fullscreen(':playlistId')}
          />
          <Route>
            <Layout>
              <Switch>
                {routes.map(({ path, Component, exact, permission }, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PrivateRoute key={i} component={Component} {...{ permission, exact, path }} />
                ))}
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default Routes;
