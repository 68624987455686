import { useLeftPanel } from "./useLeftPanel";

export function useGlobalSearch() {
  const { setGlobalSearchActual, isGlobalSearchActual, setMenuActual } = useLeftPanel();
  const openSearchPanel = () => setGlobalSearchActual();
  const closeSearchPanel = () => setMenuActual();
  const toggleSearchPanel = () => {
    isGlobalSearchActual() ? closeSearchPanel() : openSearchPanel();
  };

  return {
    openSearchPanel,
    closeSearchPanel,
    toggleSearchPanel,
  };
}
