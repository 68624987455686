import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch, useQuery } from 'hooks';
import { Box } from '@mui/material';
import { ChannelHeader } from 'components/Channel/ChannelHeader';
import { IntelbrasForm } from 'components/forms/channels';
import routes from 'helpers/routes';
import { useIntelbrasApi } from 'hooks/channels/manufactures/useIntelbrasApi';
import { IntelbrasChannel, normalizeIntelbrasChannel } from 'types/intelbras.types';

function ChannelIntelbrasEditPage() {
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const { updateChannel, deleteChannel } = useIntelbrasApi(Number(customerId));
  const { data: channel } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    cleanCacheAfterMs: 1500,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    normalizeData: normalizeIntelbrasChannel,
  });
  const history = useHistory();
  const query = useQuery();
  return (
    <>
      <Box sx={{ padding: 4, paddingBottom: 0 }}>
        <ChannelHeader customerId={Number(customerId)} />
      </Box>
      <IntelbrasForm
        onClose={() => history.push(query.get('returnRoute') || routes.customer.root())}
        initialValues={channel as IntelbrasChannel}
        doSubmit={updateChannel}
        customerId={customerId}
        showDeleteButton
        doDelete={deleteChannel}
      />
    </>
  );
}

export default asyncComponent(ChannelIntelbrasEditPage);
