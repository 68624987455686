import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { CustomerGroup } from 'types/customerGroup.types';

function SelectClientGroup({
  selectedClientGroup,
  onChangeClientGroup,
  onBlurClientGroup,
  error,
  helperText,
}: {
  selectedClientGroup?: CustomerGroup | null;
  onChangeClientGroup: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlurClientGroup?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation(['customers', '_common']);
  const { data: customerGroups } = useFetch<CustomerGroup[]>('/v1/clientGroups');
  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-customer-group-label">{t('_common:customer_group')}</InputLabel>
      <Select
        name="clientGroup"
        id="clientGroup"
        labelId="select-customer-group-label"
        label={t('_common:customer_group')}
        fullWidth
        // @ts-ignore
        onChange={(e) =>
          onChangeClientGroup({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: {
                id: e.target.value as number,
                name:
                  customerGroups?.find(
                    (clientGroup) => clientGroup.id === (e.target.value as number)
                  )?.name || '',
              },
            },
          })
        }
        onBlur={onBlurClientGroup}
        value={selectedClientGroup ? selectedClientGroup.id : ''}
      >
        {customerGroups?.map((clientGroup) => (
          <MenuItem aria-label="item" key={clientGroup.id} value={clientGroup.id}>
            {clientGroup.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectClientGroup;
