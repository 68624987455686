import { UseMeasureRect } from 'react-use/lib/useMeasure';

const COMMON = {
  height: 0,
  width: 0,
  name: 'COMMON',
  upper: function upper(measure: UseMeasureRect) {
    return measure.height > this.height && measure.width > this.width;
  },
  lower: function lower(measure: UseMeasureRect) {
    return measure.height <= this.height || measure.width <= this.width;
  },
};
// pra baixo
export const EXTRA_BIG = {
  ...COMMON,
  height: 206.7,
  width: 457,
  name: 'EXTRA_BIG',
};
export const BIG = {
  ...COMMON,
  height: 164.94,
  width: 365.52,
  name: 'BIG',
};
export const MEDIUM = {
  ...COMMON,
  height: 137.1,
  width: 305,
  name: 'MEDIUM',
};
export const SMALL = {
  ...COMMON,
  height: 92,
  width: 216.2,
  name: 'SMALL',
};
export const EXTRA_SMALL = {
  ...COMMON,
  height: 75,
  width: 177.63,
  name: 'EXTRA_SMALL',
};
export const EXTRA_SMALL2 = {
  ...COMMON,
  height: 63,
  width: 177.63,
  name: 'EXTRA_SMALL2',
};

export const EXTRA_SMALL3 = {
  ...COMMON,
  height: 50,
  width: 177.63,
  name: 'EXTRA_SMALL3',
};
