import { tokenAtom } from 'atoms/auth';
import React, { Suspense, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { io, Socket } from 'socket.io-client';
import { loggedUserAtom } from 'atoms/users';
import { useLogout } from 'hooks/useLogout';
import { SESSION_STORAGE, URL_LOGIN } from 'services/auth';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { useMessages } from 'hooks';
import { useTranslation } from 'react-i18next';
import useSIGMAIntegrator from './useSigmaIntegrator';
import useConsumption from './useConsumption';
import useJoinRoom from './useJoinRoom';

function SocketConnection() {
  const token = useRecoilValue(tokenAtom);

  return token ? (
    <Suspense fallback={null}>
      <Authenticated {...{ token }} />
    </Suspense>
  ) : null;
}

function Authenticated({ token }: { token: string }) {
  const socket = useRef<Socket | null>(null); // Melhor uso de ref
  const logout = useLogout();
  const loggedUser = useRecoilValue(loggedUserAtom);
  const [logoutByCompanyFlag] = useDecision(
    FEATURE_FLAGS_KEYS.DESLOGAR_USUARIO_AO_PERDER_ACESSO_DA_MASTER_COMPANY
  );
  const { t } = useTranslation();
  const { enqueueInfo } = useMessages();

  useJoinRoom({ socket: socket.current, masterCompanyId: loggedUser?.masterCompany.id });
  useSIGMAIntegrator({ socket: socket.current, loggedUser });
  useConsumption({ socket: socket.current, loggedUser });

  const onConnectError = React.useCallback(
    (err: { message: string }) => {
      if (err.message === 'Authentication Error' && process.env.REACT_APP_ENV !== 'test') {
        logout();
      }
    },
    [logout]
  );

  const onHeartBeat = React.useCallback((data: string) => {
    if (data === 'ping') socket.current?.emit('heartbeat', 'pong');
  }, []);

  const onMasterDisabled = React.useCallback(() => {
    if (!logoutByCompanyFlag.enabled) return;

    enqueueInfo(t('_common:master_company_has_been_disabled'));

    sessionStorage.removeItem(SESSION_STORAGE.REFRESH_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE.TOKEN);
    window.location.assign(URL_LOGIN);
  }, [enqueueInfo, logoutByCompanyFlag.enabled, t]);

  useEffect(() => {
    if (loggedUser && !socket.current) {
      socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        auth: {
          token,
        },
        transports: ['websocket'],
      });
    }

    const MASTER_DISABLED_EVENT = `master_company_${loggedUser?.masterCompany.id}_disabled`;
    socket.current?.on('heartbeat', onHeartBeat);
    socket.current?.on('connect_error', onConnectError);
    socket.current?.on(MASTER_DISABLED_EVENT, onMasterDisabled);

    return () => {
      socket.current?.off('heartbeat', onHeartBeat);
      socket.current?.off('connect_error', onConnectError);
      socket.current?.off(MASTER_DISABLED_EVENT, onMasterDisabled);
      socket.current?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, token]);

  return null;
}

export default SocketConnection;
