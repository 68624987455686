import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useYupObject } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useFormStyles, Form } from 'components/core';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { DateRangeValue } from './DateRange.types';

const useStyles = makeStyles((theme) => ({
  startDateField: {
    marginRight: theme.spacing(2),
  },
}));

function DateRangeForm({
  value,
  setValue,
  handleClose,
}: {
  value: DateRangeValue | null;
  setValue: (d: DateRangeValue) => void;
  handleClose: () => void;
}) {
  const yup = useYupObject();
  const classes = useStyles();
  const classesForm = useFormStyles({ dialog: true });
  const { t } = useTranslation(['metrics', 'forms', '_common']);

  const validationSchema = yup.object({
    startDate: yup.date().required(),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), t('forms:date_the_end_date_cannot_be_less_than_the_start_date')),
  });
  const initialValues = {
    startDate: value && value[0],
    endDate: value && value[1],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        values.startDate && values.endDate && setValue([values.startDate, values.endDate]);
        setSubmitting(false);
        handleClose();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  return (
    <form aria-label="Dates range" className={classesForm.form} onSubmit={formik.handleSubmit}>
      <>
        <Box marginBottom={2}>
          <Typography>{t('enter_the_period')}</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classesForm.formInput}
            gap={2}
          >
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              label={t('_common:start_date')}
              renderInput={(props) => (
                <TextField
                  className={classes.startDateField}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  name="startDate"
                  id="startDate"
                  fullWidth
                  {...props}
                />
              )}
              value={formik.values.startDate}
              autoFocus
              onError={(error) => {
                if (error && error !== formik.errors.startDate) {
                  formik.setFieldError('startDate', String(error));
                }
              }}
              onChange={(_date: Dayjs | null) => {
                formik.setFieldValue(
                  'startDate',
                  _date?.toDate(),
                  _date?.toString() !== 'Invalid Date'
                );
              }}
              OpenPickerButtonProps={{
                'aria-label': 'select start date',
              }}
            />
            <DesktopDatePicker
              label={t('_common:final_date')}
              renderInput={(props) => (
                <TextField
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  name="endDate"
                  id="endDate"
                  fullWidth
                  {...props}
                />
              )}
              value={formik.values.endDate}
              inputFormat="DD/MM/YYYY"
              onError={(error) => {
                if (error && error !== formik.errors.endDate) {
                  formik.setFieldError('endDate', String(error));
                }
              }}
              onChange={(_date: Dayjs | null) => {
                formik.setFieldValue(
                  'endDate',
                  _date?.toDate(),
                  _date?.toString() !== 'Invalid Date'
                );
              }}
              OpenPickerButtonProps={{
                'aria-label': 'select end date',
              }}
            />
          </Box>
        </LocalizationProvider>
        <Box className={classesForm.formFooter}>
          <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={() => handleClose()} />
          <Form.SaveButton
            isSubmitting={formik.isSubmitting}
            initialValues={formik.initialValues}
            dialog
            values={formik.values}
          />
        </Box>
      </>
    </form>
  );
}
export default DateRangeForm;
