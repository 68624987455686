import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';

function TextSuccessTestingConnection() {
  const { t } = useTranslation('channels');

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} margin={1}>
      <CheckCircle color="success" />
      <Typography variant="body2">{t('channels:camera_tested_successfully')}</Typography>
    </Box>
  );
}

export default TextSuccessTestingConnection;
