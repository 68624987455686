import React from 'react';
import ApiBoundary from 'components/ApiBoundary';
import { useTranslation } from 'react-i18next';
import 'react-grid-layout/css/styles.css';
import NotFound from 'components/NotFound';
import { useHistory, useParams } from 'react-router-dom';
import { useKeyPressEvent } from 'react-use';
import { Mosaic } from 'types/mosaics.types';
import Layout from 'components/Layout';
import { Box, IconButton } from '@mui/material';
import { ArrowBack, Replay } from '@mui/icons-material';
import routes from 'helpers/routes';
import MosaicPagination from 'components/MosaicPagination';
import { useMosaicPagination } from 'components/MosaicPagination/useMosaicPagination';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { useFetchMosaic } from 'hooks/mosaic/useFetchMosaic';
import { metricsStartLoadingAtom } from 'atoms/mosaics';
import MosaicFullScreenLive from './MosaicFullScreenLive';

interface MosaicViewPageProps {
  mosaic: Mosaic;
  setMosaic: SetterOrUpdater<Mosaic | null>;
}

function MosaicViewPageInternal({ mosaic, setMosaic }: MosaicViewPageProps) {
  const history = useHistory();
  const propsPagination = useMosaicPagination({ mosaic, setMosaic });
  useKeyPressEvent('Escape', () => history.push(routes.mosaic.view(mosaic.id)));

  const setMetricsStartLoading = useSetRecoilState(metricsStartLoadingAtom);

  React.useEffect(() => {
    setMetricsStartLoading(new Date());

    return () => {
      setMetricsStartLoading(null);
    };
  }, [setMetricsStartLoading]);

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: '0 0 auto', p: 0.5 }}>
        <Box>
          <IconButton size="small" onClick={() => history.push(routes.mosaic.view(mosaic.id))}>
            <ArrowBack fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => window.location.reload()}>
            <Replay fontSize="small" />
          </IconButton>
        </Box>

        <MosaicPagination {...propsPagination} size="small" />
      </Box>

      <MosaicFullScreenLive {...{ mosaic }} currentPage={propsPagination.currentPage} />
    </Box>
  );
}

function MosaicFullScreenView() {
  const { mosaicId } = useParams<{ mosaicId: string }>();
  const [mosaic, setMosaic, mosaicApi] = useFetchMosaic(Number(mosaicId));
  const { t } = useTranslation('mosaics');

  /* have to use mosaicApi in here because if not use, the notfound state will be shown after load mosaicApi, until set the mosaicAtom */
  return (
    <>
      {mosaicApi ? (
        <ApiBoundary>{mosaic && <MosaicViewPageInternal {...{ mosaic, setMosaic }} />}</ApiBoundary>
      ) : (
        <NotFound
          title={t('non_exixstent_mosaic')}
          reason={t('mosaic_does_not_exist_or_may_have_been_deleted')}
          shouldDo={t('register_a_new_mosaic_or_select_another_mosaic_to_be_displayed')}
        />
      )}
    </>
  );
}

export default function MosaicFullScreenViewPage() {
  return (
    <Layout.Container>
      <ApiBoundary>
        <MosaicFullScreenView />
      </ApiBoundary>
    </Layout.Container>
  );
}
