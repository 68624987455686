import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ChannelStatusIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4444 3.55573V6.66684L14.48 4.62239C15.04 4.06239 16 4.46239 16 5.25351V10.738C16 11.5291 15.04 11.9291 14.48 11.3691L12.4444 9.33351V12.4446C12.4444 12.9335 12.0444 13.3335 11.5556 13.3335H0.888889C0.4 13.3335 0 12.9335 0 12.4446V3.55573C0 3.06684 0.4 2.66684 0.888889 2.66684H11.5556C12.0444 2.66684 12.4444 3.06684 12.4444 3.55573ZM0.888951 6.66819L1.82228 7.60204C2.39265 7.03135 3.05377 6.58481 3.80562 6.26241C4.55747 5.94001 5.36302 5.77881 6.22228 5.77881H6.40562C6.46117 5.77881 6.5334 5.78622 6.62228 5.80104L7.28895 4.51144C7.11117 4.48921 6.93339 4.47253 6.75562 4.46142C6.57784 4.4503 6.40006 4.44474 6.22228 4.44474C5.17043 4.44474 4.18895 4.64115 3.27784 5.03396C2.36673 5.42676 1.57043 5.97151 0.888951 6.66819ZM2.77784 8.53589L3.71117 9.49197C3.92599 9.27704 4.16302 9.0936 4.42228 8.94167C4.68154 8.78973 4.95562 8.66929 5.24451 8.58036L5.95562 7.13511C5.32599 7.17217 4.74265 7.31114 4.20562 7.55201C3.66858 7.79288 3.19265 8.12084 2.77784 8.53589ZM5.39451 11.0317C5.51673 11.2578 5.68895 11.4115 5.91117 11.4931C6.14821 11.582 6.38525 11.5765 6.62228 11.4764C6.85932 11.3763 7.00747 11.204 7.06673 10.9594L8.51117 5.11178C8.52599 5.04507 8.51858 4.98578 8.48895 4.9339C8.45932 4.88202 8.41488 4.84496 8.35562 4.82273C8.28895 4.80049 8.22784 4.79864 8.17228 4.81717C8.11673 4.8357 8.07414 4.87461 8.04451 4.9339L5.37784 10.3591C5.26673 10.5815 5.27228 10.8057 5.39451 11.0317ZM8.58895 9.35856C8.6408 9.40303 8.68895 9.4475 8.73339 9.49197L9.66673 8.53589C9.53339 8.40248 9.3908 8.27834 9.23895 8.16346C9.0871 8.04858 8.93339 7.93556 8.77784 7.82438L8.42228 9.22516C8.48154 9.26962 8.5371 9.31409 8.58895 9.35856ZM9.90562 6.99059C10.1612 7.17588 10.4001 7.37969 10.6223 7.60204L11.5556 6.66819C11.2445 6.35691 10.9075 6.07527 10.5445 5.82328C10.1815 5.57129 9.80006 5.34894 9.40006 5.15624L9.08895 6.49031C9.37784 6.63854 9.65006 6.8053 9.90562 6.99059Z" fill="#323232" />
    </SvgIcon>
  );
}

export default ChannelStatusIcon;
