import { Box, IconButton, Popover, styled, Typography } from '@mui/material';
import { Funnel } from 'components/core/Icons';
import { DateRangeForm, DateRangeValue } from 'components/DateRange';
import { timeFormat } from 'd3';
import { useQuery } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { INIT_RANGE } from './metrics';

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  alignItems: 'center',
}));

function HeaderTab({
  range,
  setRange,
  title,
  subtitle,
  routeFn,
}: {
  range: DateRangeValue;
  setRange: (v: DateRangeValue) => void;
  title: string;
  subtitle: string;
  routeFn: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => string;
}) {
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation('metrics');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');

  React.useEffect(
    function updateWhenChangeSearchParams() {
      const rangeS = startDateS && endDateS ? [startDateS, endDateS].join(',') : null;
      const isRangeEqual = rangeS === range.map((d) => d.toISOString()).join(',');
      if (!isRangeEqual) {
        setRange(startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : INIT_RANGE);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  React.useEffect(
    function updateSearchParamsWhenChangeFilters() {
      history.push(
        routeFn({
          startDate: range[0],
          endDate: range[1],
        })
      );
    },
    [history, range, routeFn]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <TitleContainer>
      <IconButton
        sx={{
          marginRight: 1,
        }}
        onClick={handleClick}
        size="large"
      >
        <Funnel />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            padding: 1,
          },
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <DateRangeForm value={range} setValue={setRange} {...{ handleClose }} />
      </Popover>
      <Box>
        <Typography
          sx={{
            textTransform: 'uppercase',
            marginRight: 1,
          }}
          variant="h5"
          display="inline"
        >
          {title}
        </Typography>
        <Typography variant="body2" display="inline">
          (
          {t('displaying_data_between_startDate_and_endDate', {
            startDate: timeFormat('%x')(range[0]),
            endDate: timeFormat('%x')(range[1]),
          })}
          )
        </Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
    </TitleContainer>
  );
}

export default HeaderTab;
