import React from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import InfoItem from 'components/InfoItem';
import Permissioned from 'components/Permissioned';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';

export type MosaicInChannel = {
  id: number;
  name: string;
};

function ChannelMosaicChips({ channelId }: { channelId: number }) {
  const theme = useTheme();
  const viewMosaicPermission = useHavePermission(PermissionRole.VIEW_MOSAIC);
  const { data: mosaicsInChannel } = useFetch<MosaicInChannel[]>(
    `v1/mosaics/channels/${channelId}`,
    {
      conditionFn: () => viewMosaicPermission,
    }
  );

  if (!mosaicsInChannel?.length) return null;

  return (
    <Permissioned role={PermissionRole.VIEW_MOSAIC}>
      <InfoItem label={t('mosaics:channel_vinculated')}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'start',
            gap: '8px',
            height: '100px',
            overflow: 'auto',
            border: mosaicsInChannel.length >= 5 ? `solid 1px ${theme.palette.grey[700]}` : 'none',
            borderRadius: '0.5rem',
            paddingTop: '0.75rem',
            paddingLeft: '0.75rem',
            paddingBottom: '0.75rem',
          }}
        >
          {mosaicsInChannel.map((_mosaic) => (
            <Box
              key={_mosaic.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Chip
                label={
                  <Link
                    style={{
                      textDecoration: 'none',
                    }}
                    to={routes.mosaic.view(_mosaic.id)}
                  >
                    <Typography>{_mosaic.name}</Typography>
                  </Link>
                }
                sx={{
                  height: '24px',
                  p: '0.5rem',
                }}
              />
            </Box>
          ))}
        </Box>
      </InfoItem>
    </Permissioned>
  );
}
export default ChannelMosaicChips;
