import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  padding: 0 16px;
`;

export const InfoContainer = styled.div`
  margin-top: 2.25rem;
  flex: 1 1 auto;
  padding-right: 10rem;
  position: relative;

  > a {
    position: absolute;
    right: 24px;
  }

  > h6:first-of-type {
    margin-bottom: 1.68rem;
  }
`;
