import { useRecoilValue } from 'recoil';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'hooks';
import { mutate } from 'swr';
import { customerGroupSearchTextAtom } from 'atoms/customerGroup';
import { CustomerGroup } from 'types/customerGroup.types';

export function useCustomerGroupApi() {
  const { t } = useTranslation(['customer_groups', 'crud_actions', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const searchText = useRecoilValue(customerGroupSearchTextAtom);
  const listFetchKey = ['/v1/clientGroups', searchText];

  const createCustomerGroup = async (customerGroupReceived: CustomerGroup) => {
    try {
      await Axios.post('/v1/clientGroups', customerGroupReceived);
      enqueueSuccess(t('customer_group_created'));
      mutate(listFetchKey, async (items: CustomerGroup[]) => [
        { ...customerGroupReceived, id: -1 },
        ...items,
      ]);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const editCustomerGroup = async (customerGroupReceived: CustomerGroup) => {
    try {
      await Axios.put(`/v1/clientGroups/${customerGroupReceived.id}`, customerGroupReceived);
      enqueueSuccess(t('customer_group_changed'));
      mutate(listFetchKey, async (items: CustomerGroup[]) =>
        items.map((_item: CustomerGroup) =>
          _item.id === customerGroupReceived.id ? customerGroupReceived : _item
        )
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const deleteCustomerGroup = async (id: number) => {
    try {
      await Axios.delete(`/v1/clientGroups/${id}`);
      enqueueSuccess(t('customer_group_deleted'));
      mutate(listFetchKey, async (items: CustomerGroup[]) =>
        items.filter((_item: CustomerGroup) => _item.id !== id)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
    }
  };

  return {
    deleteCustomerGroup,
    createCustomerGroup,
    editCustomerGroup,
  };
}
