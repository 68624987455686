export enum PlaybackType {
  record = 'record',
  event = 'event',
}

export interface Record {
  size: number;
  id: string;
  startDate: Date;
  endDate: Date;
  customerId: number;
  fragmentName: string;
  channelId: number;
  masterCompanyId: number;
}

export enum Requester {
  sigma = 'SIGMA',
}

export enum RequestType {
  start = 'START',
  stop = 'STOP',
}
export interface RecordRequest {
  date: Date;
  id: string;
  masterCompanyId: number;
  channelId: number;
  clientId: number;
  requester: Requester;
  requestType: RequestType;
}

export interface ApiRecord {
  channelId: number;
  data: Record[];
  recordingRequests: RecordRequest[];
  startDates: Date[];
}

export interface ChannelEvent {
  date: Date;
  id: string;
  channelId: number;
  description: string;
  internalCode: number;
  internalDesc: string;
  code: number;
  customerId: number;
  masterCompanyId: number;
}

export interface ApiChannelEvent {
  channelId: number;
  data: ChannelEvent[];
}
