import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, SvgIconProps, Theme, Slide, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useRouteMatch } from 'react-router-dom';

export const useStyles = makeStyles<Theme, { isActive: boolean }>((theme) => ({
  item: ({ isActive }) => ({
    borderLeft: isActive ? `2.5px solid ${theme.palette.text.primary}` : '0 solid transparent',
    boxSizing: 'border-box',
    padding: isActive ? theme.spacing(2, 2, 2, 2) : theme.spacing(1.5, 1.5),
    textAlign: 'center',
    transition: 'all 0.1s ease-in-out',
    cursor: 'pointer',
    fontSize: 'small',
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg path': {
      fill: theme.palette.text.primary,
    },
  }),
  text: {
    position: 'absolute',
    left: '100%',
    top: '0',
    bottom: '0',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.tooltip,
    minWidth: '180px',
  },
}));

function ItemContainer(props: {
  text: string;
  onClick: React.MouseEventHandler;
  children: React.ReactElement<SvgIconProps>;
  id?: string;
}): React.ReactElement | null;
function ItemContainer(props: {
  text: string;
  route: string;
  children: React.ReactElement<SvgIconProps>;
  id?: string;
}): React.ReactElement | null;
function ItemContainer({
  text,
  children,
  onClick,
  route,
  id,
  ...props
}: {
  text: string;
  route?: string;
  onClick?: React.MouseEventHandler;
  children: React.ReactElement<SvgIconProps>;
  id?: string;
}) {
  const [isActive, setIsActive] = useState(false);
  const match = useRouteMatch(route || []);
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles({ isActive });

  useEffect(
    function verifyRouteMatch() {
      setIsActive(!!match);
    },
    [match]
  );

  return (
    <Box
      position="relative"
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      id={id}
      sx={(theme) => ({
        '& .item': isActive
          ? { backgroundColor: `${theme.palette.action.selected}!important` }
          : {},
        '&:hover': {
          '& .item': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      })}
    >
      <Slide in={isHover} unmountOnExit direction="right">
        <Paper className={classes.text} elevation={0}>
          <Typography variant="h6" noWrap>
            {text}
          </Typography>
        </Paper>
      </Slide>
      {route ? (
        <Link aria-label={text} to={route} className={`${classes.item} item`}>
          {children}
        </Link>
      ) : (
        <Box
          aria-label={text}
          role="button"
          className={`${classes.item} item`}
          onClick={onClick}
          {...props}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default ItemContainer;
