import { useYupObject } from 'hooks';
import { t } from 'i18next';
import React from 'react';
import { RecordingType } from 'types/channels.types';
import { WeekDay } from 'types/weekDay.enum';
import { Box, TextField, Divider } from '@mui/material';
import { Formik } from 'formik';
import routes from 'helpers/routes';
import { Form, useFormStyles } from 'components/core';
import { useHistory } from 'react-router-dom';
import { IRecordingSchedulerForm, ITimeRange } from 'types/recordingSchedule.types';
import RecordingScheduleCustomers from './RecordingScheduleCustomers';
import RecordingTimeTable from './RecordingTimeTable';

type InitialValues = IRecordingSchedulerForm & {
  id?: number;
};
type OnSave = (values: IRecordingSchedulerForm & { id?: number }) => Promise<void>;
interface Props {
  initialValues: InitialValues;
  onSave: OnSave;
}
export function RecordingScheduleForm({ initialValues, onSave }: Props) {
  const yup = useYupObject();
  const classesForm = useFormStyles({});
  const history = useHistory();

  const validationSchema = yup.object({
    name: yup.string().required().max(150).noSpecialCharacters(),
    timeRanges: yup
      .array(
        yup.object({
          weekDay: yup.string().oneOf(Object.keys(WeekDay)).required(),
          startTime: yup.date().required(),
          endTime: yup.date().required(),
          recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
        })
      )
      .ensure()
      .min(1, t('recording_schedule:you_must_select_at_least_one_time_range'))
      .test(
        'is-time-conflict',
        t('recording_schedule:time_conflict'),
        // @ts-ignore
        (timeRanges: ITimeRange[]) => {
          // Se houver apenas um horário, não há conflito
          if (timeRanges.length < 2) return true;

          // Função para verificar se há conflito de horários
          const hasTimeConflict = () => {
            for (let i = 0; i < timeRanges.length - 1; i++) {
              for (let j = i + 1; j < timeRanges.length; j++) {
                if (haveOverlap(timeRanges[i], timeRanges[j])) {
                  // Há conflito de horários em determinado dia da semana
                  return false;
                }
              }
            }
            return true;
          };

          // Função para verificar se há sobreposição entre dois intervalos de tempo
          const haveOverlap = (rangeA: ITimeRange, rangeB: ITimeRange) => {
            const { weekDay: weekdayA, startTime: startA, endTime: endA } = rangeA;
            const { weekDay: weekdayB, startTime: startB, endTime: endB } = rangeB;

            // Verifica se os horários têm conflito apenas se forem no mesmo dia da semana
            if (weekdayA === weekdayB) {
              return (
                (startA >= startB && startA < endB) ||
                (endA > startB && endA <= endB) ||
                (startA <= startB && endA >= endB)
              );
            }
            return false;
          };

          return hasTimeConflict();
        }
      ),
    customers: yup
      .array(
        yup.object({
          customerId: yup.number().required().positive(),
          channelIds: yup
            .array(yup.number())
            .required()
            .ensure()
            .min(1, t('recording_schedule:you_must_select_at_least_one_channel')),
        })
      )
      .ensure()
      .required()
      .min(1, t('recording_schedule:you_must_add_at_least_one_customer')),
  });
  return (
    <Formik
      {...{ initialValues, validationSchema }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await onSave(values);
          setSubmitting(false);
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {(formik) => (
        <Box
          component="form"
          className={classesForm.form}
          onSubmit={formik.handleSubmit}
          aria-label="recording schedule form"
        >
          <Box className={classesForm.formInputsContainer}>
            <TextField
              label={t('recording_schedule:recording_schedule_name')}
              name="name"
              id="name"
              autoFocus
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classesForm.formInput}
              disabled={formik.isSubmitting}
            />
            <RecordingScheduleCustomers />
            <RecordingTimeTable />
          </Box>
          <Divider />
          <Box className={classesForm.formFooter}>
            <Form.CancelButton
              isSubmitting={formik.isSubmitting}
              onClick={() => history.push(routes.settings.recordingSchedule.root)}
            />
            <Form.SaveButton
              isSubmitting={formik.isSubmitting}
              initialValues={formik.initialValues}
              values={formik.values}
              disabled={!formik.dirty || formik.isSubmitting}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
