import { atom } from 'recoil';
import { HikTestConnectionResponse, HikVisionChannel } from 'types/hikvision.types';

export const hikvisionBatchFormAtom = atom<HikVisionChannel[]>({
  key: 'hikvisionBatchFormAtom',
  default: [],
});

export const testedHikvisonChannelsAtom = atom<HikTestConnectionResponse[]>({
  key: 'testedHikvisonChannelsAtom',
  default: []
})