import { atom, selector } from 'recoil';
import { getUserDataFromToken, UserAuth } from 'services/auth';

export const tokenAtom = atom<string | null>({
  key: 'token',
  default: null,
});

export const refreshToken = atom<string | null>({
  key: 'refreshToken',
  default: null,
});

export const userInfoSelector = selector<null | UserAuth>({
  key: 'userInfoSelector',
  get: ({ get }) => getUserDataFromToken(get(tokenAtom)),
});