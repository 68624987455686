import React from 'react';
import { Typography, Box } from '@mui/material';

function InfoItem({ label, children }: { label: string; children: React.ReactNode }) {
  if (!children) return null;
  if (!label) return null;

  return (
    <Box marginBottom="20px">
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      {typeof children === 'string' ? (
        <Typography variant="body1">{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
}

export default InfoItem;
