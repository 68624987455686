import arEG from './ar-EG.json';
import caES from './ca-ES.json';
import csCZ from './cs-CZ.json';
import daDK from './da-DK.json';
import deCH from './de-CH.json';
import deDE from './de-DE.json';
import enCA from './en-CA.json';
import enGB from './en-GB.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import esMX from './es-MX.json';
import faIR from './fa-IR.json';
import fiFI from './fi-FI.json';
import frCA from './fr-CA.json';
import frFR from './fr-FR.json';
import heIL from './he-IL.json';
import huHU from './hu-HU.json';
import itIT from './it-IT.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import mkMK from './mk-MK.json';
import nbNO from './nb-NO.json';
import nlNL from './nl-NL.json';
import plPL from './pl-PL.json';
import ptBR from './pt-BR.json';
import ruRU from './ru-RU.json';
import svSE from './sv-SE.json';
import trTR from './tr-TR.json';
import ukUA from './uk-UA.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

const timeFormat = {
  'ar-EG': arEG,
  'ca-ES': caES,
  'cs-CZ': csCZ,
  'da-DK': daDK,
  'de-CH': deCH,
  'de-DE': deDE,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-US': enUS,
  en: enUS,
  'es-ES': esES,
  es: esES,
  'es-MX': esMX,
  'fa-IR': faIR,
  'fi-FI': fiFI,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'he-IL': heIL,
  'hu-HU': huHU,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'mk-MK': mkMK,
  'nb-NO': nbNO,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  pt: ptBR,
  'ru-RU': ruRU,
  'sv-SE': svSE,
  'tr-TR': trTR,
  'uk-UA': ukUA,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

export default timeFormat;
