import { Box, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const data = [
  { channelId: 1, status: true, transmissionTime: 60 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 2, status: false, transmissionTime: 10 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
  { channelId: 3, status: true, transmissionTime: 120 },
];

function TransmissionCostsChart() {
  const { t } = useTranslation(['usage_summary', 'channels']);

  const totalChannels = useMemo(() => data.length, []);
  const totalChannelsActive = useMemo(() => data.filter((item) => item.status).length, []);
  const totalChannelsInactive = useMemo(() => data.filter((item) => !item.status).length, []);
  const totalTransmissionTime = useMemo(
    () => data.reduce((total, item) => total + item.transmissionTime, 0),
    []
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom="16px">
        <Typography variant="h5">{t('channels:cameras')}</Typography>
        <Typography variant="h4">{totalChannels}</Typography>
      </Box>
      <Typography variant="body2">{t('details_of_the_number_of_cameras')}</Typography>
      <Box display="flex" justifyContent="space-between" marginBottom="8px" marginTop="8px">
        <Typography>{t('actives')}</Typography>
        <Typography>{totalChannelsActive}</Typography>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" marginBottom="8px" marginTop="8px">
        <Typography>{t('inactives')}</Typography>
        <Typography>{totalChannelsInactive}</Typography>
      </Box>
      <Divider />
      <Box marginTop="24px">
        <Typography variant="body2">{t('amount_of_hours')}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" marginTop="8px" marginBottom="8px">
        <Typography>{t('total_hours_transmitted')}</Typography>
        <Typography>{totalTransmissionTime}</Typography>
      </Box>
      <Divider />
    </>
  );
}

export default TransmissionCostsChart;
