import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Metrics(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M19 3C18.4696 3 17.9609 3.21071 17.5858 3.58579C17.2107 3.96086 17 4.46957 17 5C17.0012 5.28453 17.0632 5.56553 17.1816 5.82422L14.1914 10.0098C14.1278 10.0035 14.0639 10.0002 14 10C13.8252 10.0001 13.6512 10.0231 13.4824 10.0684L10.9297 7.51562C10.9755 7.34755 10.9991 7.1742 11 7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21071 9.53043 5 9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7C7.00021 7.3069 7.07105 7.60963 7.20703 7.88477L5.12695 11.0059C5.08471 11.0026 5.04237 11.0006 5 11C4.46957 11 3.96086 11.2107 3.58579 11.5858C3.21071 11.9609 3 12.4696 3 13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15C5.53043 15 6.03914 14.7893 6.41421 14.4142C6.78929 14.0391 7 13.5304 7 13C6.99979 12.6931 6.92895 12.3904 6.79297 12.1152L8.87305 8.99414C8.91529 8.99744 8.95763 8.99939 9 9C9.17477 8.99993 9.34878 8.97694 9.51758 8.93164L12.0703 11.4844C12.0245 11.6525 12.0009 11.8258 12 12C12 12.5304 12.2107 13.0391 12.5858 13.4142C12.9609 13.7893 13.4696 14 14 14C14.5304 14 15.0391 13.7893 15.4142 13.4142C15.7893 13.0391 16 12.5304 16 12C15.9991 11.7148 15.9371 11.4331 15.8184 11.1738L18.8086 6.99023C18.8722 6.99655 18.9361 6.99981 19 7C19.5304 7 20.0391 6.78929 20.4142 6.41421C20.7893 6.03914 21 5.53043 21 5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3ZM3 19V21H21V19H3Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Metrics;
