import React from 'react';
import TextDeviceOfflineTestingConnection from 'components/TextDeviceOfflineTestingConnection/TextDeviceOfflineTestingConnection';
import TextErrorTestingConnection from 'components/TextErrorTestingConnection/TextErrorTestingConnection';
import TextSuccessTestingConnection from 'components/TextSuccessTestingConnection/TextSuccessTestingConnection';
import { CheckCircle, Error, Warning } from '@mui/icons-material';
import { CustomizedResponseCodeEnum } from 'types/channels.types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { CodecNotSupportedMessage } from './CodecNotSupportedMessage';

interface TestingDeviceFeedbackProps {
  customizedCode?: CustomizedResponseCodeEnum;
}

export function TestBatchDeviceIcon({ customizedCode }: TestingDeviceFeedbackProps) {
  const { t } = useTranslation();
  if (customizedCode === CustomizedResponseCodeEnum.SUCCESS)
    return (
      <Tooltip title={t('channels:success_connection')}>
        <CheckCircle
          sx={{
            color: 'success.main',
          }}
        />
      </Tooltip>
    );
  if (customizedCode === CustomizedResponseCodeEnum.DEVICE_IS_OFFLINE)
    return (
      <Tooltip title={t('channels:device_offline')}>
        <Warning color="warning" />
      </Tooltip>
    );

  return (
    <Tooltip title={t('channels:error_connection')}>
      <Error color="error" />
    </Tooltip>
  );
}

export function TestBatchDeviceText({ customizedCode }: TestingDeviceFeedbackProps): string {
  if (customizedCode === CustomizedResponseCodeEnum.SUCCESS) return '_common:success';
  if (customizedCode === CustomizedResponseCodeEnum.DEVICE_IS_OFFLINE)
    return '_common:unavailable_device';
  return '_common:error';
}

export default function TestingDeviceFeedback({ customizedCode }: TestingDeviceFeedbackProps) {
  if (customizedCode === CustomizedResponseCodeEnum.SUCCESS)
    return <TextSuccessTestingConnection />;
  if (customizedCode === CustomizedResponseCodeEnum.DEVICE_IS_OFFLINE)
    return <TextDeviceOfflineTestingConnection />;
  if (customizedCode === CustomizedResponseCodeEnum.CODEC_NOT_SUPPORTED)
    return <CodecNotSupportedMessage />;
  return <TextErrorTestingConnection />;
}
