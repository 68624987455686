import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import StylesProvider from '@mui/styles/StylesProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MaterialUiTheme from 'config/MaterialUiTheme';
import { timeFormatDefaultLocale, TimeLocaleDefinition } from 'd3';
import timeLocales from 'locales/timeFormat';
import languageDetector from 'locales/languageDetector';
import ptBR from 'locales/timeFormat/pt-BR.json';
import SnackbarConfigProvider from 'config/SnackbarConfigProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import OptimizelyProviderWithUser from 'config/OptimizelyProviderWithUser';
import useDayJsConfig from 'config/useDayJsConfig';
import SetLoggedUser from 'config/SetLoggedUser';
import Routes from './Routes';
import GlobalCssOverride from './GlobalCssOverride';

function App() {
  const languages = languageDetector.detect() || 'pt-BR';
  const language = Array.isArray(languages) ? languages[0] : languages;
  useEffect(
    function localeD3TimeFormat() {
      timeFormatDefaultLocale(
        // @ts-ignore
        (timeLocales[language] || ptBR) as TimeLocaleDefinition
      );
    },
    [language]
  );

  useDayJsConfig();

  return (
    <RecoilRoot>
      <StylesProvider injectFirst>
        <MaterialUiTheme>
          <GlobalCssOverride />
          <SetLoggedUser>
            <OptimizelyProviderWithUser>
              <SnackbarConfigProvider>
                <DndProvider backend={HTML5Backend}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
                    <Routes />
                  </LocalizationProvider>
                </DndProvider>
              </SnackbarConfigProvider>
            </OptimizelyProviderWithUser>
          </SetLoggedUser>
        </MaterialUiTheme>
      </StylesProvider>
    </RecoilRoot>
  );
}

export default App;
