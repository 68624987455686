import React from 'react';
import { Box, styled } from '@mui/material';
import ApiBoundary from 'components/ApiBoundary';
import { useTranslation } from 'react-i18next';
import { DateRangeValue } from 'components/DateRange';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import DetailsList from './DetailsList';
import HeaderTab from './HeaderTab';
import { INIT_RANGE } from './metrics';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 1),
  flexDirection: 'column',
  flex: '1 1 50%',
  overflow: 'hidden',
}));

function DetailsTab() {
  const { t } = useTranslation('usage_summary');
  const query = useQuery();

  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue>(
    startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : INIT_RANGE
  );

  return (
    <Container>
      <HeaderTab
        title={t('detail_of_transmission_and_storage_costs')}
        subtitle={t('by_customer')}
        routeFn={routes.metrics.details}
        {...{
          range,
          setRange,
        }}
      />
      <ApiBoundary>
        <DetailsList startDate={range[0]} endDate={range[1]} />
      </ApiBoundary>
    </Container>
  );
}

export default DetailsTab;
