import { Box, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalculatorModal from './CalculatorModal';
import CalculatorAddPriceModal from './CalculatorAddPriceModal';
import { CalculatorDashboard } from './CalculatorDashboard/CalculatorDashboard';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

function CalculatorPage() {
  const { t } = useTranslation('calculator');
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <Container>
      <Typography variant="h4">{t('simulate')}</Typography>
      <Typography variant="h6">{t('use_the_calculator_below_to_simulate')}</Typography>
      <Typography variant="body1">
        {t('values_are_estimates_and_will_follow_according_to_tool_storage_and_transmission_usage')}
      </Typography>
      <CalculatorDashboard {...{ setModalOpened }} />
      <CalculatorModal {...{ modalOpened, setModalOpened }} />
      <CalculatorAddPriceModal />
    </Container>
  );
}

export default CalculatorPage;
