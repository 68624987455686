import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch, useQuery } from 'hooks';
import routes from 'helpers/routes';
import { Box } from '@mui/material';
import { ChannelHeader } from 'components/Channel/ChannelHeader';
import { RTMPChannelForm } from 'components/forms/channels';
import { normalizeChannel, useChannelsApi } from 'hooks/channels/useChannelsApi';
import { Channel } from 'types/channels.types';

function ChannelRTMPEditPage() {
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const history = useHistory();
  const query = useQuery();
  const { updateChannel, deleteChannel } = useChannelsApi(Number(customerId));
  const { data: channel } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    cleanCacheAfterMs: 1500,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    normalizeData: normalizeChannel,
  });

  const handleUpdate = async (channelUpdated: Channel) => {
    await updateChannel(channelUpdated);
    history.push(routes.customer.root());
  };

  return (
    <>
      <Box sx={{ padding: 4, paddingBottom: 0 }}>
        <ChannelHeader customerId={Number(customerId)} />
      </Box>
      <Box
        sx={{
          px: 4,
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <RTMPChannelForm
          initialValues={channel as Channel}
          doSubmit={handleUpdate}
          showDeleteButton
          doDelete={deleteChannel}
          customerId={Number(customerId)}
          onClose={() => history.push(query.get('returnRoute') ?? routes.customer.root())}
        />
      </Box>
    </>
  );
}

export default asyncComponent(ChannelRTMPEditPage);
