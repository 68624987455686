import { Box, Paper, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RecordingsFilter from './RecordingsFilters';
import RecordingsTable from './RecordingsTable';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function RecordingsPage() {
  const query = useQuery();
  const classes = useStyles();
  const { t } = useTranslation('recordings');

  const customerId = query.get('customer');
  return (
    <Container>
      <RecordingsFilter />
      <Paper className={classes.root}>
        {customerId ? (
          <RecordingsTable customerId={Number(customerId)} />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h5">{t('recording_history')}</Typography>
            <Typography variant="body2">
              {t('select_a_client_to_view_recording_history')}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default RecordingsPage;
