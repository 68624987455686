import React from 'react';

import LeftPanel from 'panels';
import TopBar from 'topbar';
import { Box, Button, Collapse, Link, styled, Typography } from '@mui/material';
import { DownloadVMSPopUp } from 'components/DownloadVMSPopUp';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ConfirmModal } from 'components/core';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { downloadModalOpenedAtom } from 'atoms/download';
import { Download } from '@mui/icons-material';
import { useMessages } from 'hooks';
import { useIsWindows } from 'hooks/useIsWindows';
import { useVMSDownloaded } from 'hooks/useVMSDownloaded';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
}));

function Layout({ children }: { children?: React.ReactNode }) {
  const [popUpDownloadFlag] = useDecision(FEATURE_FLAGS_KEYS.DOWNLOAD_VMS_CLOUD_POPUP);
  const [proxyFlag] = useDecision(FEATURE_FLAGS_KEYS.DOWNLOAD_VMS_CLOUD_WITH_PROXY_TO_CANADA);
  const [stepByStepFlag] = useDecision(FEATURE_FLAGS_KEYS.STEP_BY_STEP_INSTALLATION);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useRecoilState(downloadModalOpenedAtom);
  const { enqueueSuccess, enqueueError } = useMessages();
  const [opened, setOpened] = React.useState(true);
  const isWindows = useIsWindows();
  const downloaded = useVMSDownloaded();

  const handleDownload = async () => {
    setLoading(true);
    try {
      const url = proxyFlag.enabled
        ? 'https://segwarevmscloud.s3.amazonaws.com/Segware%2BVMS%2BCloud%2Bv1.0.1%2BPROXY.zip'
        : 'https://segwarevmscloud.s3.amazonaws.com/Segware%2BVMS%2BCloud%2Bv1.0.1.zip';

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'VMS Cloud App');

      document.body.appendChild(link);
      link.click();

      setLoading(false);
      setOpen(false);
      enqueueSuccess(t('_common:download_will_start_soon'));
    } catch (err) {
      setLoading(false);
      console.error({ err });
      setOpen(false);
      enqueueError(t('_common:it_was_not_possible_to_download'));
    }
  };
  return (
    <Container role="region" aria-label="root">
      {!downloaded && isWindows && popUpDownloadFlag.enabled && (
        <DownloadVMSPopUp {...{ opened, setOpened }} />
      )}
      <Box
        sx={{
          width: '100%',
          height:
            !downloaded && isWindows && popUpDownloadFlag.enabled && opened
              ? 'calc(100vh - 56px)'
              : '100vh',
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <LeftPanel />
        <Box flex="1 1 auto" display="flex" flexDirection="column" overflow="hidden">
          <TopBar />
          {children}
        </Box>
        {!downloaded && isWindows && (
          <ConfirmModal
            description={t('welcome:download_description')}
            doConfirm={handleDownload}
            variant="contained"
            loading={loading}
            disabled={loading}
            confirmActionText={t('_common:download')}
            open={open}
            setOpen={setOpen}
            title={t('welcome:download_title')}
            confirmActionIcon={<Download fontSize="medium" />}
            stepByStep={stepByStepFlag.enabled ? <DownloadStepByStep /> : undefined}
          />
        )}
      </Box>
    </Container>
  );
}

Layout.Container = Container;

export default Layout;

function DownloadStepByStep() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={{ my: 2 }}>
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textTransform: 'none',
            width: '100%',
            mx: 'auto',
            mb: 1,
            py: 0,
            px: 2,
          }}
          onClick={() => setOpen((old) => !old)}
        >
          <Typography fontWeight="bold" sx={(theme) => ({ color: theme.palette.primary.main })}>
            {t('_common:installation_guide')}
          </Typography>
          {!open ? (
            <KeyboardArrowDownIcon
              fontSize="large"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            />
          ) : (
            <KeyboardArrowUpIcon
              fontSize="large"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            />
          )}
        </Button>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={(theme) => ({
            background: theme.palette.secondary.dark,
            borderRadius: 2,
            px: 2,
            py: 1,
          })}
        >
          <Typography mt={1}>{t('_common:guide_description')}</Typography>
          <Box
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:system_requirements')}</Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:required_step')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:required_step_2')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:download_2')}</Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:download_step_1')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:download_step_2')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:extract')}</Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:extract_step_1')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:extract_step_2')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:extract_step_3')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:installation_dotnet')}</Typography>
            <Typography variant="body2" ml={1} mb={1}>
              {t('common:dotnev_step_1')}
              <Link
                sx={(theme) => ({
                  color: theme.palette.action.active,
                })}
                href="https://dotnet.microsoft.com/pt-br/download/dotnet/8.0"
                target="_blank"
              >
                {t('common:click_here')}
              </Link>
            </Typography>

            <Typography variant="body2" ml={1}>
              {t('common:dotnev_step_2')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:execution_app')}</Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:application_execution_step_1')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:application_execution_step_2')}
            </Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:application_execution_step_3')}
            </Typography>
          </Box>

          <Typography variant="body1" ml={1} mb={2}>
            {t('_common:congratulations_installation')}
          </Typography>

          <Box
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">{t('_common:observation')}</Typography>
            <Typography variant="body2" ml={1}>
              {t('_common:contact_support_if_instalation_fails')}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
