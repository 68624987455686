import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';

function TextErrorTestingConnection() {
  const { t } = useTranslation('channels');

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} margin={1}>
      <Error color="error" />
      <Typography variant="body2">{t('channels:error_testing')}</Typography>
    </Box>
  );
}

export default TextErrorTestingConnection;
