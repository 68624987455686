import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';
import { Customer } from 'types/customer.types';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';

function SelectCustomers({
  selectedCustomers,
  onChangeCustomers,
}: {
  selectedCustomers: FilterSelectValue[];
  onChangeCustomers: (value: FilterSelectValue[]) => void;
}) {
  const { data: customers } = useFetch<Customer[]>('/v1/clients');
  const { t } = useTranslation('customers');

  useEffect(
    function setLabels() {
      if (selectedCustomers && customers) {
        onChangeCustomers(
          selectedCustomers.map((value) => ({
            id: value.id,
            label: customers.find((customer) => customer.id === value.id)?.tradeName || '',
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]
  );

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-customers-label">{t('customers')}</InputLabel>
      <Select
        labelId="select-customers-label"
        id="select-customers"
        onChange={(e) =>
          onChangeCustomers(
            (e.target.value as number[]).map((id) => ({
              id,
              label: customers?.find((customer) => customer.id === id)?.tradeName || '',
            }))
          )
        }
        value={selectedCustomers.map((value) => value.id)}
        multiple
        renderValue={(selected) =>
          (selected)
            .map((id) => customers?.find((customer) => customer.id === id)?.tradeName)
            .join(', ')
        }
        label={t('customers')}
      >
        {customers?.map(({ tradeName, id }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selectedCustomers.some((value) => value.id === id)} />
            <ListItemText primary={tradeName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectCustomers.Loading = function SelectCustomersLoading() {
  const { t } = useTranslation('customers');
  return <SelectFetchLoading label={t('customers')} />;
};

export default SelectCustomers;
