import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecordingType } from 'types/channels.types';
import { WeekDay } from 'types/weekDay.enum';
import { ITimeRangeWithId } from 'types/recordingSchedule.types';
import RecordingTimeIntervalForm from './RecordingTimeIntervalForm';

function RecordingNewTimeIntervalDialog({
  open,
  setOpen,
  dateStartSelected,
  dateEndSelected,
  weekDay,
  onConfirm,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dateStartSelected?: string;
  dateEndSelected?: string;
  weekDay?: WeekDay;
  onConfirm: (values: ITimeRangeWithId[]) => void;
}) {
  const { t } = useTranslation(['recording_schedule', 'crud_actions']);

  const initialValues = React.useMemo(
    () => ({
      weekDay: weekDay ? [weekDay] : [],
      startTime: dayjs(dateStartSelected),
      endTime: dayjs(dateEndSelected),
      recordingType: RecordingType.RECORD_24_7,
      id: '',
    }),
    [dateEndSelected, dateStartSelected, weekDay]
  );

  const handleConfirm = React.useCallback(
    (values: ITimeRangeWithId[]) => {
      onConfirm(values);
      setOpen(false);
    },
    [onConfirm, setOpen]
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{t('recording_schedule:create_time_range')}</DialogTitle>
      <DialogContent>
        {open && (
          <RecordingTimeIntervalForm
            onConfirm={handleConfirm}
            initialValues={initialValues}
            onCancel={() => setOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default RecordingNewTimeIntervalDialog;
