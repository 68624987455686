import React from 'react';
import { useRecoilCallback } from 'recoil';
import { Dialog, DialogContent } from '@mui/material';
import { Channel } from 'types/channels.types';
import { batchRTSPChannelsAtom } from 'atoms/batchChannel';
import { rtmpChannelsAtom } from 'atoms/rtmp';
import { RTMPChannelForm, RTSPChannelForm } from '..';

export default function ChannelFormDialog({
  open,
  handleClose,
  channel,
  sequential,
}: {
  open: boolean;
  handleClose: () => void;
  channel: Channel;
  sequential: number;
}) {
  const editRTSPChannelAtom = useRecoilCallback(({ set }) => async (newChannel: Channel) => {
    set(batchRTSPChannelsAtom, (channels) =>
      channels.map((item, index) => (index + 1 === sequential ? newChannel : item))
    );
  });

  const editRTMPChannelAtom = useRecoilCallback(({ set }) => async (newChannel: Channel) => {
    set(rtmpChannelsAtom, (channels) =>
      channels.map((item, index) => (index + 1 === sequential ? newChannel : item))
    );
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{
        width: '100%',
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        {channel.url ? (
          <RTSPChannelForm
            initialValues={channel}
            doSubmit={editRTSPChannelAtom}
            onClose={handleClose}
          />
        ) : (
          <RTMPChannelForm
            initialValues={channel}
            doSubmit={editRTMPChannelAtom}
            onClose={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
