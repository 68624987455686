import { Button, Stack, FormHelperText, FormLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { IRecordingSchedulerForm } from 'types/recordingSchedule.types';
import RecordingScheduleCustomerChannels from './RecordingScheduleCustomerChannels';

function RecordingScheduleCustomers() {
  const { t } = useTranslation('recording_schedule');
  const formik = useFormikContext<IRecordingSchedulerForm>();

  const handleAddCustomer = React.useCallback(() => {
    formik.setFieldValue('customers', [...formik.values.customers, { id: uuidv4() }]);
  }, [formik]);

  return (
    <Stack spacing={1} sx={{ mb: 3 }}>
      <FormLabel error={formik.touched.customers && typeof formik.errors.customers === 'string'}>
        {t('included_channels')}
      </FormLabel>
      <RecordingScheduleCustomerChannels />
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          variant="outlined"
          onClick={handleAddCustomer}
          color={
            formik.touched.customers && typeof formik.errors.customers === 'string'
              ? 'error'
              : 'inherit'
          }
          disabled={formik.isSubmitting || formik.values.customers.some((c) => !c.customerId)}
        >
          {t('add_customer')}
        </Button>
        {formik.touched.customers && typeof formik.errors.customers === 'string' && (
          <FormHelperText error>{formik.errors.customers}</FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default RecordingScheduleCustomers;
