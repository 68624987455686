import { RecordingType } from './channels.types';

export type Order = 'asc' | 'desc';
export type CellValue = Date | number | string;
export type KeyOf<RowType> = {
  [Property in keyof RowType]: RowType[Property];
};

export interface Column<RowType> {
  description: string;
  dataKey: keyof RowType;
  align?: 'left' | 'center' | 'right' | 'justify';
  renderValue?: (value: CellValue) => string;
  linkTo?: (row: RowType) => string;
}

export interface TableData {
  id: number;
  name: string;
  bitrate: number;
  fps: number;
  resolution: string;
  recordingType: RecordingType;
  recordedVideoSize: number;
  recordedVideoDuration: number;
  liveVideoMinutes: number;
  value: number;
  vodSize: number;
  vodDuration: number;
  liveDuration: number;
  liveAmount: number;
  recordedVideoDurationAmount: number;
  recordedVideoSizeAmount: number;
  vodDurationAmount: number;
  liveTransferAmount: number;
  liveSize: number;
  usedSize: number;
  channelValue: number;
}

export interface HeaderDetailsProps<RowType> {
  columns: Column<KeyOf<RowType>>[];
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
}

export interface CellProps<RowType> {
  row: KeyOf<RowType>;
  column: Column<KeyOf<RowType>>;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
