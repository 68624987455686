import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { addPricesModalOpenedAtom } from 'atoms/calculator';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import CalculatorAddPriceForm from '../../components/CalculatorAddPriceForm/CalculatorAddPriceForm';

function CalculatorAddPriceModal() {
  const { t } = useTranslation(['calculator', 'crud_actions']);
  const [addPricesModalOpened, setAddPricesModalOpened] = useRecoilState(addPricesModalOpenedAtom);
  return (
    <Dialog
      open={addPricesModalOpened}
      onClose={() => setAddPricesModalOpened(false)}
      fullWidth
      maxWidth="md"
      aria-labelledby="add-prices-title"
    >
      <DialogTitle id="add-prices-title">{t('add_prices')}</DialogTitle>
      <DialogContent>
        <CalculatorAddPriceForm />
      </DialogContent>
    </Dialog>
  );
}

export default CalculatorAddPriceModal;
