import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material';

export default function GlobalCssOverride() {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        body: {
          margin: 0,
          overflow: 'hidden',
        },
        iframe: {
          display: 'none',
        },
        /* SCROLLBAR */
        '*::-webkit-scrollbar-track': {
          border: `1px solid ${theme.palette.background.default}`,
          backgroundColor: theme.palette.background.default,
        },
        '*::-webkit-scrollbar': {
          width: '0.3125rem',
          backgroundColor: theme.palette.background.paper,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.text.primary,
        },

        /* smart-webcomponents-react/scheduler HIDE WATERMARK (even in ErrorBoundary) */
        '.smart-scheduler-event-button, .smart-button, .smart-repeat-button, #watermark': {
          display: 'none!important',
        },
      }}
    />
  );
}
