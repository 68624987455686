import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITimeRangeWithId } from 'types/recordingSchedule.types';
import RecordingTimeIntervalForm from './RecordingTimeIntervalForm';

function RecordingEditTimeIntervalDialog({
  open,
  setOpen,
  item,
  onConfirm,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: ITimeRangeWithId;
  onConfirm: (values: ITimeRangeWithId[]) => void;
}) {
  const { t } = useTranslation(['recording_schedule', 'crud_actions']);

  const initialValues = React.useMemo(
    () => ({
      weekDay: [item.weekDay],
      startTime: dayjs(item.startTime),
      endTime: dayjs(item.endTime),
      recordingType: item.recordingType,
      id: item.id,
    }),
    [item]
  );

  const handleConfirm = React.useCallback(
    (values: ITimeRangeWithId[]) => {
      onConfirm(values);
      setOpen(false);
    },
    [onConfirm, setOpen]
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{t('recording_schedule:edit_time_range')}</DialogTitle>
      <DialogContent>
        {open && (
          <RecordingTimeIntervalForm
            onConfirm={handleConfirm}
            initialValues={initialValues}
            onCancel={() => setOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default RecordingEditTimeIntervalDialog;
