import { Box, Typography } from '@mui/material';
import { mosaicItensInitialDataAtom, mosaicItensValuesAtom } from 'atoms/mosaicItems';
import { useFetch, useMosaicStartLive } from 'hooks';
import React from 'react';
import { useMeasure } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Mosaic, MosaicItem } from 'types/mosaics.types';
import GridLayout from 'react-grid-layout';
import { NUMBER_COL, NUMBER_ROWS } from 'helpers/mosaicTemplates';
import { LiveVideo } from 'pages/MosaicViewPage/V2BottomPanel/V2VideoWithPlayback';
import ApiBoundary from 'components/ApiBoundary';
import { VideocamOff } from '@mui/icons-material';
import { VideoLiveSource } from 'types/channels.types';
import VideoSlot from 'components/core/VideoSlot';
import routes from 'helpers/routes';

function MosaicFullScreenLive({ mosaic, currentPage }: { mosaic: Mosaic; currentPage: number }) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [setMeasureRef, { width, height }] = useMeasure();
  const videosLiveSource = useMosaicStartLive({ mosaicId: mosaic.id });
  const [mosaicItensValues, setMosaicItensValues] = useRecoilState(
    mosaicItensValuesAtom(mosaic.id)
  );
  const setMosaicItensInitialData = useSetRecoilState(mosaicItensInitialDataAtom(mosaic.id));

  const { data: mosaicItensApi } = useFetch(`/v1/mosaics/${mosaic.id}/items`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  React.useEffect(
    function setDataFromApi() {
      setMosaicItensInitialData(mosaicItensApi);
      setMosaicItensValues(mosaicItensApi);
    },
    [mosaicItensApi, setMosaicItensInitialData, setMosaicItensValues]
  );

  React.useEffect(
    function bridgeMeasureRef() {
      if (containerRef.current) setMeasureRef(containerRef.current);
    },
    [setMeasureRef]
  );

  const getItem = React.useCallback(
    (order: number) => mosaicItensValues.find((item) => item.order === order),
    [mosaicItensValues]
  );

  const payload = React.useMemo(
    () =>
      mosaic.mosaicData.payload
        ? mosaic.mosaicData.payload
        : mosaic.mosaicData.pages?.find((pagina) => pagina.offset === currentPage)?.payload,
    [currentPage, mosaic.mosaicData.pages, mosaic.mosaicData.payload]
  );

  return (
    <Box ref={containerRef} sx={{ flex: '1 1 auto', display: 'flex', height: '100vh' }}>
      <GridLayout
        className="layout"
        layout={payload}
        cols={NUMBER_COL}
        width={width}
        rowHeight={(height - (NUMBER_ROWS + 1) * 4) / NUMBER_ROWS}
        margin={[4, 4]}
        isDraggable={false}
        isResizable={false}
        isDroppable={false}
      >
        {payload?.map((itemLayout) => (
          <Box key={itemLayout.i} display="flex">
            <MosaicFullScreenLiveItem
              mosaicItem={getItem(Number(itemLayout.i))}
              mosaicId={mosaic.id}
              videosLiveSource={videosLiveSource}
            />
          </Box>
        ))}
      </GridLayout>
    </Box>
  );
}

function MosaicFullScreenLiveItem({
  mosaicItem,
  mosaicId,
  videosLiveSource,
}: {
  mosaicItem?: MosaicItem;
  mosaicId: number;
  videosLiveSource: VideoLiveSource[];
}) {
  const videoLiveSource = videosLiveSource.find((vl) => vl.channelId === mosaicItem?.channel?.id);
  if (!mosaicItem) {
    return null;
  }

  if (!mosaicItem.visible) return <VideoSlot.Hidden />;

  if (!mosaicItem.channel.clientStatus)
    return (
      <VideoSlot.CustomerInactive
        mosaicId={mosaicId}
        order={mosaicItem.order}
        returnRoute={routes.mosaic.fullscreen(mosaicId)}
        channel={mosaicItem.channel}
      />
    );

  if (!mosaicItem.channel.status)
    return (
      <VideoSlot.FullscreenInactive
        mosaicId={mosaicId}
        order={mosaicItem.order}
        returnRoute={routes.mosaic.fullscreen(mosaicId)}
        channel={mosaicItem.channel}
      />
    );

  return (
    <ApiBoundary
      fallbackError={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex="1 1 auto"
        >
          <VideocamOff color="error" sx={{ mb: 1 }} />
          <Typography>{`${mosaicItem.channel.id} - ${mosaicItem.channel.name}`}</Typography>
        </Box>
      }
    >
      <LiveVideo
        channelName={mosaicItem.channel.name}
        channelId={mosaicItem.channel.id}
        paused={false}
        muted
        widescreen={mosaicItem.widescreen}
        enableZoom={false}
        mosaicId={mosaicId}
        thumbnail={null}
        videoLiveSource={videoLiveSource}
      />
    </ApiBoundary>
  );
}

export default MosaicFullScreenLive;
