import { ZoomTransform } from 'd3';
import { atom, atomFamily, selectorFamily } from 'recoil';
import { ChannelEvent, Record, RecordRequest } from 'types/playback.types';

type PlaybackKey = { mosaicId: number | null; channelId: number };

export const currentTimeFullscreenAtom = atomFamily<number, number>({
  key: 'mosaic.playback.currentTimeFullscreen',
  default: 0,
});

export const recordsFullscreenAtom = atomFamily<Record[] | null, number>({
  key: 'mosaic.playback.fullscreen.records',
  default: null,
});

/**
 * Dates records by channel
 */

export const datesRecordsFullscreenAtom = atomFamily<Date[] | null, number>({
  key: 'mosaic.playback.fullscreen.datesRecordsAtom',
  default: null,
});

/**
 * Events by channel
 */

export const eventsFullscreenAtom = atomFamily<ChannelEvent[] | null, number>({
  key: 'mosaic.playback.fullscreen.events',
  default: null,
});

export const playbackRateFullscreenAtom = atomFamily<number, number>({
  key: 'mosaic.playback.playbackRateFullscreenAtom',
  default: 1,
});

export const actualFowardRewindSecondsFullscreenAtom = atom<number | null>({
  key: 'mosaic.playback.actualFowardRewindSecondsFullscreenAtom',
  default: null,
});

export const lastFowardRewindClickFullscreenAtom = atomFamily<Date | null, number>({
  key: 'mosaic.playback.lastFowardRewindClickFullscreenAtom',
  default: null,
});

export const startDateChannelBarFullscreenAtom = atomFamily<Date, number>({
  key: 'mosaic.playback.startDateChannelBarFullscreenAtom',
  default: new Date(),
});

export const endDateChannelBarFullscreenAtom = atomFamily<Date, number>({
  key: 'mosaic.playback.endDateChannelBarFullscreenAtom',
  default: new Date(),
});

export const currentZoomChannelFullscreenBarAtom = atomFamily<ZoomTransform | undefined, number>({
  key: 'mosaic.playback.currentZoomChannelFullscreenBarAtom',
  default: undefined,
});

export const selectedDateFullscreenAtom = atomFamily<Date | null, PlaybackKey>({
  key: 'mosaic.playback.selectedDateFullscreen',
  default: null,
});

export const selectedDateInPickerFullscreenAtom = atom<Date | null>({
  key: 'mosaic.playback.selectedDateInPickerFullscreenAtom',
  default: null,
});

export const recordingRequestsFullscreenAtom = atomFamily<RecordRequest[] | null, number>({
  key: 'mosaic.playback.playback.recordingRequests',
  default: null,
});

export const playbackSourceStartDateFullscreenAtom = atomFamily<string | null, number>({
  key: 'playbackSourceStartDateFullscreen',
  default: null,
});

export const playbackStartDateDataBarFullscreenSelector = selectorFamily<
  string | null,
  PlaybackKey
>({
  key: 'mosaic.playback.fullscreen.startDateDataBar',
  get: ({ channelId, mosaicId }) => ({ get }) => {
    const dateRecords = get(datesRecordsFullscreenAtom(channelId));
    const selectedDate = get(selectedDateFullscreenAtom({ channelId, mosaicId }));
    if (!selectedDate) {
      return null;
    }
    const sourceStartDate = get(playbackSourceStartDateFullscreenAtom(channelId));
    const date = sourceStartDate ? new Date(sourceStartDate) : selectedDate;
    if (!dateRecords) {
      return null;
    }
    const selectedDateRecord =
      [...dateRecords]
        .sort((a, b) => Number(a) - Number(b))
        .filter((dateRecord) => dateRecord <= date)
        .reverse()[0] || null;
    return selectedDateRecord && selectedDateRecord.toISOString();
  },
});

export const liveSelectorFullscreen = selectorFamily<boolean, PlaybackKey>({
  key: 'mosaic.playback.playback.live',
  get: (key) => ({ get }) => !get(selectedDateFullscreenAtom(key)),
  set: ({ mosaicId, channelId }) => ({ set }, newValue) => {
    if (!newValue) {
      console.error(
        'liveSelector não deve ser setado para false, para ativar o modo playback deve ser atualizado o selectedDateAtom'
      );
      return;
    }
    set(selectedDateFullscreenAtom({ channelId, mosaicId }), null);
    set(playbackSourceStartDateFullscreenAtom(channelId), null);
  },
});

export const pausedFullscreenAtom = atomFamily<boolean, number>({
  key: 'mosaic.playback.fullscreen.paused',
  default: false,
});

export const mutedFullscreenAtom = atomFamily<boolean, number>({
  key: 'mosaic.playback.fullscreen.muted',
  default: true,
});
