import React from 'react';

interface ImageData {
  src: string;
}

export const useBase64Image = (base64Image?: string): ImageData => {
  const src = React.useMemo(() => (base64Image ? `data:image/png;base64,${base64Image}` : ''), [
    base64Image,
  ]);

  return {
    src,
  };
};
