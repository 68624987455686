import {
  PartnerType,
  CustomizedResponseCodeEnum,
  RecordingType,
  ChannelInitialValues,
} from './channels.types';

export interface IntelbrasChannel extends ChannelInitialValues {
  qtyChannels: number;
  serialNumber: string;
  p2pChannel: number;
  user: string;
  password: string;
  p2pPartner: PartnerType;
  idMask: string;
  uuid: string;
  subtypeStream: boolean;
}

export interface IntelbrasTestConnectionResponse {
  name: string;
  success: CustomizedResponseCodeEnum;
  message: string;
  url?: string;
  channel: number;
  serial: string;
  uuid: string;
}

export interface IntelbrasBody {
  serial: string;
  channel: number;
  user: string;
  password: string;
  name: string;
  port: number;
}

export const DEFAULT_INTELBRAS: IntelbrasChannel = {
  status: true,
  name: '',
  p2pChannel: 1,
  p2pPartner: PartnerType.INTELBRAS,
  serialNumber: '',
  retentionTime: 0,
  recordingType: RecordingType.LIVE,
  channelPrivate: false,
  subtypeStream: false,
  qtyChannels: 2,

  user: '',
  password: '',
  url: '',
  urlToFormat: '',
  urlOnlyRecord: '',
  urlOnlyRecordToFormat: '',
  uuid: '',
  specificUrl: false,
  idMask: '',
  activeTransmissionCreditLimit: false,
  transmissionCreditLimit: 0,
  activeStorageLimit: false,
  storageLimit: 0,
  enablePreAlarm: false,
  port: 554,
};

export const normalizeIntelbrasChannel = (channel: IntelbrasChannel): IntelbrasChannel => ({
  ...channel,
  idMask: String(channel.id).padStart(6, '0'),
  port: channel.port ?? 554,
});
