import { actualPanel } from "atoms/leftpanel";
import { useRecoilState } from "recoil";
import { LeftPanelContent } from "types/leftpanel.types";

export function useLeftPanel() {
  const [actualPanelValue, setActualPanel] = useRecoilState(actualPanel);
  return {
    actualPanelValue,
    isMenuActual: () => actualPanelValue === LeftPanelContent.MENU,
    isGlobalSearchActual: () => actualPanelValue === LeftPanelContent.GLOBAL_SEARCH,
    isSelectChannelActual: () => actualPanelValue === LeftPanelContent.SELECT_CHANNEL,
    isSelectCustomerActual: () => actualPanelValue === LeftPanelContent.SELECT_CUSTOMER,
    isSelectMosaicActual: () => actualPanelValue === LeftPanelContent.SELECT_MOSAIC,
    isPlaylistDetailsActual: actualPanelValue === LeftPanelContent.PLAYLIST_DETAILS,
    setMenuActual: () => setActualPanel(LeftPanelContent.MENU),
    setGlobalSearchActual: () => setActualPanel(LeftPanelContent.GLOBAL_SEARCH),
    setSelectChannelActual: () => setActualPanel(LeftPanelContent.SELECT_CHANNEL),
    setSelectCustomerActual: () => setActualPanel(LeftPanelContent.SELECT_CUSTOMER),
    setSelectMosaicActual: () => setActualPanel(LeftPanelContent.SELECT_MOSAIC),
    setPlaylistDetailsActual: (value: boolean) =>
      value
        ? setActualPanel(LeftPanelContent.PLAYLIST_DETAILS)
        : setActualPanel(LeftPanelContent.MENU),
  };
}