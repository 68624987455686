import React from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { PartnerType } from 'types/channels.types';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import SelectManuFacturer from 'components/SelectManufacturer';

interface Props {
  customerId: string;
}
function BatchPageP2P({ customerId }: Props) {
  const history = useHistory();

  const handleManuFacturerChange = (event: SelectChangeEvent<PartnerType>) => {
    event.target.value === PartnerType.INTELBRAS &&
      history.push(routes.customer.channel.create.formBatchIntelbras(customerId));

    event.target.value === PartnerType.HIKVISION &&
      history.push(routes.customer.channel.create.formBatchHikvision(customerId));
  };

  return (
    <Box
      sx={{
        textTransform: 'uppercase',
        height: '100vh',
        ml: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          m: 2,
        }}
      >
        <SelectManuFacturer value={undefined} onChange={handleManuFacturerChange} />
      </Box>
    </Box>
  );
}

export default BatchPageP2P;
