import { Box, Typography, Paper, TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Table from 'components/Table';
import { timeFormat } from 'd3';
import React from 'react';
import { TFunction, useTranslation, Namespace } from 'react-i18next';
import exportAsPDF from 'services/exportAsPDF';
import { OperationItems, OperationLogNormalized } from 'types/operationLogs.types';
import { Column } from 'types/table.types';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import useItemTranslation from './useItemTranslation';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    cursor: 'pointer',
  },
}));

function useColumns({
  t,
  translateItem,
}: {
  t: TFunction<Namespace<'_common' | 'customers' | 'operation_logs'>>;
  translateItem: (key: OperationItems) => string;
}): Column<OperationLogNormalized>[] {
  return React.useMemo(
    () => [
      {
        description: t('_common:date'),
        dataKey: 'date',
        renderValue: (value) => (value instanceof Date ? timeFormat('%d %B %Y %H:%M')(value) : ''),
      },
      {
        description: t('_common:description'),
        dataKey: 'companyName',
      },
      {
        description: t('operation_logs:user'),
        dataKey: 'userName',
      },
      {
        description: t('operation_logs:changed_item'),
        dataKey: 'objectName',
        renderValue: (value) => translateItem(value as OperationItems),
      },
      {
        description: t('operation_logs:operation'),
        dataKey: 'operation',
        // renderValue: (value) => (typeof value === 'string' ? t(`operation_${value}`) : ''),
        renderValue: (value) => t(`operation_logs:operation_${value}`),
      },
    ],
    [t, translateItem]
  );
}

function OperationLogsTable({ logs }: { logs: OperationLogNormalized[] }) {
  const { t } = useTranslation();
  const translateItem = useItemTranslation();
  const columns = useColumns({ t, translateItem });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const classes = useStyles();
  const history = useHistory();

  const handleExportAsPDF = () => {
    exportAsPDF({ rows: logs, t, title: t('settings:operation_logs'), columns });
  };

  if (logs.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('_common:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table
        dataLength={logs.length}
        tableName={t('settings:operation_logs')}
        {...{ page, setPage, rowsPerPage, setRowsPerPage, handleExportAsPDF, columns }}
      >
        {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow
            key={row.id}
            className={classes.row}
            aria-label={`linha dos logs ${row.id}`}
            onClick={() => history.push(routes.settings.operationLogs.details(row.id))}
            hover
          >
            {columns.map((column) => (
              <TableCell key={column.dataKey} aria-label={column.description}>
                <Table.Cell {...{ row, column }} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Paper>
  );
}

export default OperationLogsTable;
