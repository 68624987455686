export type ChannelLimitSettings = {
  activeTransmissionCreditLimit: boolean;
  transmissionCreditLimit: number;
  activeStorageLimit: boolean;
  storageLimit: number;
  masterCompanyId?: number;
};

export const DEFAULT_CHANNEL_LIMIT_SETTINGS: ChannelLimitSettings = {
  activeTransmissionCreditLimit: false,
  transmissionCreditLimit: 0,
  activeStorageLimit: false,
  storageLimit: 0,
};