import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function CalculatorHeaderTitle(props: BoxProps) {
  const { t } = useTranslation('calculator');

  return (
    <Box padding="16px 24px" marginBottom="16px" bgcolor="background.default" {...props}>
      <Typography id="calculator-modal-title">{t('enter_the_data')}</Typography>
      <Typography variant="body2">
        {t('you_can_enter_different_settings_for_as_many_cameras_as_you_want')}
      </Typography>
    </Box>
  );
}
