import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import Permissioned from 'components/Permissioned';
import { loggedUserAtom } from 'atoms/users';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Search,
  Customers,
  SegwareLogo,
  Settings,
  Calculator,
  Calendar,
  Channel,
  Subscriptions,
  Metrics,
  Mosaics,
  PlaylistPlay,
} from 'components/core/Icons';
import { PermissionRole } from 'types/user.types';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { DownloadVMSButton } from 'components/DownloadVMSButton';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { downloadModalOpenedAtom } from 'atoms/download';
import { useIsWindows } from 'hooks/useIsWindows';
import { useVMSDownloaded } from 'hooks/useVMSDownloaded';
import ItemContainer from './ItemContainer';
import MainMenuAvatar from './MainMenuAvatar';
import ReleaseNoteItem from './ReleaseNoteItem';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '30px',
    width: '35px',
    color: theme.palette.text.primary,
  },
}));

function MainMenu() {
  const classes = useStyles();
  const { t } = useTranslation(['main-left-menu', '_common']);
  const { openSearchPanel } = useGlobalSearch();
  const loggedUser = useRecoilValue(loggedUserAtom);
  const [downloadFlag] = useDecision(FEATURE_FLAGS_KEYS.DOWNLOAD_VMS_CLOUD);
  const [open, setOpen] = useRecoilState(downloadModalOpenedAtom);
  const isWindows = useIsWindows();
  const downloaded = useVMSDownloaded();

  return (
    <Box
      role="region"
      aria-label="Main menu"
      flex="0 0 auto"
      display="flex"
      flexDirection="column"
      sx={{ py: 2 }}
    >
      <Box flex="0 0 auto" sx={{ pb: 2, m: 'auto' }}>
        <SegwareLogo className={classes.segwareLogo} />
      </Box>
      <Box flex="1 1 auto">
        <ItemContainer text={t('main-left-menu:search')} onClick={() => openSearchPanel()}>
          <Search aria-label="Search" fontSize="small" />
        </ItemContainer>
        <Permissioned role={PermissionRole.VIEW_MOSAIC}>
          <ItemContainer text={t('main-left-menu:mosaics')} route={routes.mosaic.root}>
            <Mosaics fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.VIEW_PLAYLIST}>
          <ItemContainer text={t('_common:playlists')} route={routes.playlists.root()}>
            <PlaylistPlay fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.VIEW_CLIENT}>
          <ItemContainer text={t('customers')} route={routes.customer.root()}>
            <Customers fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.VIEW_CHANNEL}>
          <ItemContainer text={t('events:channel_events')} route={routes.events.root()}>
            <Calendar fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.VIEW_CHANNEL}>
          <ItemContainer
            text={t('channels_monitoring:channels_monitoring')}
            route={routes.channels.monitoring()}
          >
            <Channel fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.VIEW_CHANNEL}>
          <ItemContainer text={t('recordings:recordings')} route={routes.recordings.root()}>
            <Subscriptions fontSize="small" />
          </ItemContainer>
        </Permissioned>
      </Box>
      <Box flex="0 0 auto">
        <ReleaseNoteItem />
        <Permissioned
          role={[
            PermissionRole.VIEW_USER,
            PermissionRole.ADMIN,
            PermissionRole.VIEW_CHANNEL_GROUP,
            PermissionRole.VIEW_CLIENT_GROUP,
          ]}
        >
          <ItemContainer text={t('main-left-menu:settings')} route={routes.settings.root}>
            <Settings fontSize="small" />
          </ItemContainer>
        </Permissioned>
        <Permissioned role={PermissionRole.ADMIN}>
          {loggedUser &&
            loggedUser.masterCompany.invoiceMargin !== null &&
            loggedUser.masterCompany.invoiceMargin !== undefined &&
            loggedUser.masterCompany.invoiceMargin > 0 &&
            loggedUser.masterCompany.id !== 377 && (
              <ItemContainer text={t('metrics')} route={routes.metrics.root()}>
                <Metrics fontSize="small" />
              </ItemContainer>
            )}
          {[100, 1851, 7442].includes(loggedUser?.masterCompany.id || 0) && (
            <ItemContainer text={t('main-left-menu:cost_simulator')} route={routes.calculator.root}>
              <Calculator fontSize="small" />
            </ItemContainer>
          )}
        </Permissioned>
        {!downloaded && isWindows && downloadFlag.enabled && (
          <Box
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ItemContainer route="" text={t('_common:download_vms')}>
              <DownloadVMSButton.ToMenu />
            </ItemContainer>
          </Box>
        )}
        <MainMenuAvatar />
      </Box>
    </Box>
  );
}

export default MainMenu;
