import React from 'react';
import { IconButton } from '@mui/material';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function FullScreenButton({
  size,
  onClick,
  opened,
}: {
  size?: 'small' | 'medium';
  onClick: () => void;
  opened: boolean;
}) {
  const { t } = useTranslation(['_common']);

  return (
    <IconButton
      aria-label={opened ? 'fullscreen exit' : 'fullscreen'}
      onClick={onClick}
      {...{ size }}
      size="small"
      title={opened ? t('_common:exit_fullscreen_mode') : t('_common:go_to_fullscreen_mode')}
    >
      {opened ? <FullscreenExit fontSize="small" /> : <Fullscreen fontSize="small" />}
    </IconButton>
  );
}

export default FullScreenButton;
