import React, { ChangeEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';

interface IProps {
  helperText?: string;
  value: number;
  onChangeBitrateInput: (e: ChangeEvent) => void;
  onBlurBitrateInput: (e: ChangeEvent) => void;
  error?: boolean;
}

export function BitrateInput(props: IProps) {
  return (
    <>
      <TextField
        sx={{
          marginLeft: '1rem',
        }}
        label="Bitrate"
        name="bitrate"
        id="bitrate"
        fullWidth
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">Kbps</InputAdornment>,
        }}
        helperText={props.helperText}
        value={props.value}
        onChange={props.onChangeBitrateInput}
        onBlur={props.onBlurBitrateInput}
        error={props.error}
      />
    </>
  );
}
