import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Box, IconButton, Typography, Button, Link, Paper } from '@mui/material';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import InfoItem from 'components/InfoItem';
import routes from 'helpers/routes';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { Edit } from '@mui/icons-material';
import { Customer } from 'types/customer.types';
import { Channel } from 'types/channels.types';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { InfoContainer, Container } from './TabCustomer.styles';
import ChannelsResume from './ChannelsResume';

function TabCustomer({ customer, channels }: { customer: Customer; channels: Channel[] }) {
  const { t } = useTranslation(['customers', 'events', '_common']);
  const location = useLocation();
  const [tempoDeToleranciaFlag] = useDecision(FEATURE_FLAGS_KEYS.TEMPO_DE_TOLERANCIA_PARA_FALHAS);

  return (
    <Container role="region" aria-label="Customer tab">
      <InfoContainer>
        <Permissioned role={PermissionRole.EDIT_CLIENT}>
          <Link
            component={RouteLink}
            to={routes.customer.edit(customer.id, location.pathname + location.search)}
          >
            <IconButton aria-label="edit" color="default" size="large">
              <Edit />
            </IconButton>
          </Link>
        </Permissioned>
        <InfoItem label={t('_common:customer_status')}>
          {customer.status ? t('_common:active') : t('_common:inactive')}
        </InfoItem>
        {tempoDeToleranciaFlag.enabled && (
          <>
            <InfoItem label={t('customer:enable_fail_tolerance')}>
              <Typography>
                {customer?.enableFailTolerance ? t('_common:active') : t('_common:disabled')}
              </Typography>
            </InfoItem>
            {!!customer?.failToleranceTime && (
              <InfoItem label={t('customer:failToleranceTime')}>
                <Typography>{customer.failToleranceTime}s</Typography>
              </InfoItem>
            )}
          </>
        )}
        <InfoItem label={t('_common:customer_group')}>{customer.clientGroup?.name}</InfoItem>
        <InfoItem label={t('address')}>
          <Typography variant="body1">
            {customer.address}, {customer.addressNumber}, {customer.addressComplement}
          </Typography>
          <br />
          <Typography variant="body1">{customer.addressReference}</Typography>
          <br />
          <Typography variant="body1">{`${t('zipCode')}: ${customer.zipcode}`}</Typography>
          <br />
          <Typography variant="body1">
            {customer.city} - {customer.state} - {customer.district}
          </Typography>
        </InfoItem>
        <InfoItem label={t('phone')}>
          <Typography variant="body1">{customer.phone}</Typography>
          <br />
          <Typography variant="body1">{customer.phone2}</Typography>
        </InfoItem>
        <InfoItem label={t('email')}>{customer.email}</InfoItem>
        {customer.company?.companyName && (
          <InfoItem label={t('company:company')}>{customer.company.companyName}</InfoItem>
        )}
        <InfoItem label={t('events:events')}>
          <Link component={RouteLink} to={routes.events.root({ selectedCustomers: [customer.id] })}>
            <Button color="primary" variant="contained">
              {t('view_events')}
            </Button>
          </Link>
        </InfoItem>
      </InfoContainer>
      <Suspense
        fallback={
          <Box
            display="flex"
            height={400}
            flex="1 1 70%"
            margin="2rem 0 1rem 1rem"
            component={Paper}
          >
            <Box alignItems="center" display="flex" flex="1 1 auto" justifyContent="center">
              <CircularProgress />
            </Box>
          </Box>
        }
      >
        <ChannelsResume customerId={customer.id} {...{ channels }} />
      </Suspense>
    </Container>
  );
}

export default TabCustomer;
