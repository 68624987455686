import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

interface IProps {
  error?: boolean;
  helper?: string | boolean;
  value: number;
  onChangeSelectFps: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}


const FPS = [
  1,
  2,
  4,
  6,
  8,
  10,
  13,
  15,
  18,
  20,
  22,
  25,
  30
]

export default function SelectFPS(props: IProps) {
  return (
    <FormControl variant="outlined" fullWidth error={props.error}>
      <InputLabel id="select-fps">FPS</InputLabel>
      <Select
        name="fps"
        id="fps"
        label="FPS"
        labelId="select-fps"
        fullWidth
        value={props.value}
        // @ts-ignore
        onChange={props.onChangeSelectFps}
      >
        {
          FPS.map(item => (
            <MenuItem
              key={item}
              aria-label="item"
              value={item}
            >
              {item}
            </MenuItem>
          )
          ).reverse()
        }
      </Select>
      {props.helper && <FormHelperText>{props.helper}</FormHelperText>}
    </FormControl>
  )
}