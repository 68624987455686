/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Record,
  ChannelEvent,
  PlaybackType,
  RecordRequest,
  RequestType,
} from 'types/playback.types';
import { easeSinOut, ScaleTime, select, Selection, timeFormat } from 'd3';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Fade, Paper, Popper, PopperProps, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RECORD_BAR_COLOR } from './V2constants';

export interface DataBar {
  id: string;
  startDate: Date;
  endDate: Date;
  type: PlaybackType;
  color: string;
}

function Bars({
  left,
  name,
  xScale,
  onSelectDate,
  records,
  events,
  recordingRequests,
  loading,
}: {
  left: number;
  name: string;
  xScale: ScaleTime<number, number, never>;
  onSelectDate: (dateClicked: Date) => void;
  records?: Record[];
  events?: ChannelEvent[] | null;
  recordingRequests?: RecordRequest[] | null;
  loading?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const [anchorElRequest, setAnchorElRequest] = useState<PopperProps['anchorEl']>(null);
  const [event, setEvent] = useState<ChannelEvent | null>(null);
  const [recordingRequest, setRecordingRequest] = useState<RecordRequest | null>(null);
  const theme = useTheme();

  useEffect(
    function mountEvents() {
      const group = select(`.events-${name}`);
      if (events && events.length > 0) {
        group
          .selectAll<SVGGElement, ChannelEvent>('g')
          .data(events, (d) => d.id)
          .join('g')
          .attr('class', `event-${name}`)
          .attr('x', (d) => xScale(d.date))
          // .attr('y', 4)
          .attr('transform', (d) => `translate(${xScale(d.date)}, 0)`)
          .on('mouseenter', function mouseEnterEvent(e, d) {
            setAnchorEl(this);
            setEvent(d);
          })
          .on('mouseleave', function mouseLeaveEvent() {
            setAnchorEl(null);
            setEvent(null);
          })
          .each(function createPaths() {
            const eventGroup = select(this);
            eventGroup.selectAll('path').remove();
            eventGroup.selectAll('rect').remove();

            eventGroup
              .append('rect')
              .attr('x', '10')
              .attr('y', '6')
              .attr('width', '4')
              .attr('height', '10')
              .attr('fill', 'white');
            eventGroup
              .append('path')
              .attr(
                'd',
                'M12 22C11.734 22 11.4805 21.9501 11.2394 21.8504C10.9983 21.7506 10.7781 21.6093 10.5786 21.4264L2.57357 13.4214C2.39069 13.2219 2.24938 13.0017 2.14963 12.7606C2.04988 12.5195 2 12.266 2 12C2 11.734 2.04988 11.4763 2.14963 11.2269C2.24938 10.9776 2.39069 10.7614 2.57357 10.5786L10.5786 2.57357C10.7781 2.37406 10.9983 2.2286 11.2394 2.13716C11.4805 2.04572 11.734 2 12 2C12.266 2 12.5237 2.04572 12.7731 2.13716C13.0224 2.2286 13.2386 2.37406 13.4214 2.57357L21.4264 10.5786C21.6259 10.7614 21.7714 10.9776 21.8628 11.2269C21.9543 11.4763 22 11.734 22 12C22 12.266 21.9543 12.5195 21.8628 12.7606C21.7714 13.0017 21.6259 13.2219 21.4264 13.4214L13.4214 21.4264C13.2386 21.6093 13.0224 21.7506 12.7731 21.8504C12.5237 21.9501 12.266 22 12 22ZM11.0025 12.9975H12.9975V7.01247H11.0025V12.9975ZM12 15.99C12.2826 15.99 12.5195 15.8944 12.7107 15.7032C12.9019 15.5121 12.9975 15.2751 12.9975 14.9925C12.9975 14.7099 12.9019 14.473 12.7107 14.2818C12.5195 14.0906 12.2826 13.995 12 13.995C11.7174 13.995 11.4805 14.0906 11.2893 14.2818C11.0981 14.473 11.0025 14.7099 11.0025 14.9925C11.0025 15.2751 11.0981 15.5121 11.2893 15.7032C11.4805 15.8944 11.7174 15.99 12 15.99Z'
              )
              .attr('fill', '#FFB300');
          });
      }
    },
    [events, name, xScale]
  );

  useEffect(
    function mountRecordRequests() {
      const group: Selection<SVGGElement, unknown, HTMLElement, any> = select(
        `.recordingRequests-${name}`
      );
      if (recordingRequests && recordingRequests.length > 0) {
        group
          .selectAll<SVGGElement, RecordRequest>('g')
          .data(recordingRequests, (d) => d.id)
          .join('g')
          .attr('class', `recordingRequest-${name}`)
          .attr('x', (d) => xScale(d.date))
          .attr('y', 2)
          .attr('transform', (d) => `translate(${xScale(d.date)}, 0)`)
          .on('mouseenter', function mouseEnterRecordingRequest(e, d) {
            setAnchorElRequest(this);
            setRecordingRequest(d);
          })
          .on('mouseleave', function mouseLeaveRecordingRequest() {
            setAnchorElRequest(null);
            setRecordingRequest(null);
          })
          .each(function createPaths(record) {
            const eventGroup = select(this);
            eventGroup.selectAll('path').remove();

            eventGroup
              .append('path')
              .attr(
                'd',
                record.requestType === RequestType.start
                  ? 'M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z'
                  : 'M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z'
              )
              .attr('fill', '#fff');
          });
      }
    },
    [recordingRequests, name, xScale]
  );

  useEffect(
    function mountBarLoading() {
      const g = select(`.barLoading-${name}`);
      g.selectAll('rect').remove();
      if (loading) {
        /* barra fundo */
        g.append('rect')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('fill', theme.palette.divider);
        /* barra efeito skeleton */
        const b = g
          .append('rect')
          .attr('width', '80px')
          .attr('height', '100%')
          .attr('fill', 'url(#bar-loading-gradient)');
        const repeat = () => {
          b.attr('x', '0')
            .transition()
            .duration(1500)
            .ease(easeSinOut)
            .attr('x', '100%')
            .on('end', repeat);
        };
        repeat();
      }
    },
    [loading, name, theme]
  );

  const recordsFiltered = useMemo(
    () => records?.filter((record) => xScale(record.endDate) - xScale(record.startDate) > 0),
    [records, xScale]
  );

  useEffect(
    function mountRecords() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const group = select(`.bars-${name}`);
      if (recordsFiltered && recordsFiltered.length > 0) {
        group
          .selectAll<SVGRectElement, Record>('rect')
          .data(recordsFiltered, (d: Record) => d.id)
          .join('rect', undefined, (exit) =>
            exit.attr('height', 0).attr('x', 0).attr('width', 0).remove()
          )
          .on('click', function changePointer(e: MouseEvent) {
            const dateClicked = xScale.invert(e.clientX - (left || 0));
            onSelectDate(dateClicked);
          })
          .attr('id', (d: Record) => d.id)
          .attr('fill', RECORD_BAR_COLOR)
          .attr('height', '100%')
          .attr('width', (d: Record) => xScale(d.endDate) - xScale(d.startDate))
          .attr('class', 'record')
          .attr('y', 0)
          .attr('x', (d: Record) => xScale(d.startDate));
      }
    },
    [name, onSelectDate, left, xScale, recordsFiltered]
  );

  return (
    <>
      <g className={`bars-${name}`} />
      <g className={`barLoading-${name}`}>
        <defs>
          <linearGradient id="bar-loading-gradient" gradientTransform="rotate(0)">
            <stop offset="0%" stopColor="white" stopOpacity="0" />
            <stop offset="50%" stopColor="white" stopOpacity=".3" />
            <stop offset="100%" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </g>
      <g className={`events-${name}`} transform="translate(0, 4)" />
      <g className={`recordingRequests-${name}`} />
      <TooltipEvent {...{ event, anchorEl }} />
      <TooltipRecordingRequest {...{ recordingRequest, anchorElRequest }} />
    </>
  );
}

function TooltipEvent({
  anchorEl,
  event,
}: {
  anchorEl: PopperProps['anchorEl'];
  event: ChannelEvent | null;
}) {
  const { t } = useTranslation();
  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250} exit>
          <Paper>
            <Box padding="4px">
              <Typography>{t(`channel_events:${event?.code}`)}</Typography>
              {event?.date && (
                <Typography align="center">{timeFormat('%H:%M:%S')(event?.date)}</Typography>
              )}
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

function TooltipRecordingRequest({
  anchorElRequest,
  recordingRequest,
}: {
  anchorElRequest: PopperProps['anchorEl'];
  recordingRequest: RecordRequest | null;
}) {
  const { t } = useTranslation('channel_recording_requests');
  return (
    <Popper open={Boolean(anchorElRequest)} anchorEl={anchorElRequest} placement="top" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250} exit>
          <Paper>
            <Box padding="4px">
              <Typography>
                {t(
                  'channel_recording_requests:the_requester_system_made_a_request_for_requestType_the_recording',
                  {
                    requester: t(
                      `_common:channel_recording_requests:${recordingRequest?.requester}`
                    ),
                    requestType: recordingRequest && t(recordingRequest.requestType.toLowerCase()),
                  }
                )}
              </Typography>
              {recordingRequest?.date && (
                <Typography align="center">
                  {timeFormat('%H:%M:%S')(recordingRequest?.date)}
                </Typography>
              )}
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default Bars;
