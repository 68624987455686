import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormControlProps,
  FormHelperText,
} from '@mui/material';
import { WeekDay } from 'types/weekDay.enum';
import dayjs from 'dayjs';

function SelectMultipleWeekDay({
  selected,
  onChange,
  error,
  helperText,
  placeholder,
  name,
  ...props
}: {
  selected: WeekDay[];
  onChange: (e: SelectChangeEvent<WeekDay[]>) => void;
  error?: boolean;
  helperText?: string | string[];
  placeholder?: string;
  name: string;
} & FormControlProps) {
  const { t } = useTranslation('_common');

  return (
    <FormControl variant="outlined" {...{ error }} {...props}>
      <InputLabel id="select-week-day-label">{t('week_day')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-week-day-label"
        id="select-week-day"
        label={t('week_day')}
        multiple
        value={selected}
        onChange={onChange}
        renderValue={(value) =>
          value
            .map((weekDay) =>
              dayjs()
                .day(Object.values(WeekDay).findIndex((w) => w === weekDay))
                .format('dddd')
            )
            .join(', ')
        }
      >
        {Object.values(WeekDay).map((weekDay, index) => (
          <MenuItem key={weekDay} value={weekDay}>
            <Checkbox checked={selected.includes(weekDay)} />
            <ListItemText primary={dayjs().day(index).format('dddd')} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
export default SelectMultipleWeekDay;
