import React from 'react';
import { Typography, CardContent, ListItem, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import { Card } from './ItemsCard.styles';
import useCallStartLiveBefore from './useCallStartLiveBefore';

interface Mosaic {
  id: number;
  name: string;
  qtyChannels: number;
}

function MosaicCard(
  props: React.PropsWithChildren<{
    mosaic: Mosaic;
    onClick?: React.MouseEventHandler;
    callStartLive?: boolean;
  }>
): React.ReactElement | null;
function MosaicCard(
  props: React.PropsWithChildren<{ loading: boolean }>
): React.ReactElement | null;
function MosaicCard({
  mosaic,
  loading,
  onClick,
  callStartLive,
}: {
  mosaic?: Mosaic;
  loading?: boolean;
  onClick?: React.MouseEventHandler;
  callStartLive?: boolean;
}) {
  const { t } = useTranslation('search_bar');
  const listItemProps = loading
    ? {}
    : { component: Link, to: `/mosaics/${(mosaic as Mosaic).id}/view`, onClick };

  useCallStartLiveBefore(mosaic?.id, !callStartLive);

  return (
    <ListItem {...listItemProps}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography>{loading ? <Skeleton /> : mosaic?.name}</Typography>
            <Typography color="textSecondary">
              {loading ? <Skeleton /> : `${t('number_channels')} ${mosaic?.qtyChannels}`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </ListItem>
  );
}

export default MosaicCard;
