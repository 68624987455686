import React from 'react';
import { useRecoilCallback } from 'recoil';
import { Dialog, DialogContent } from '@mui/material';
import { hikvisionBatchFormAtom } from 'atoms/hikvision';
import { HikVisionChannel } from 'types/hikvision.types';
import { HikvisionForm } from './HikvisionForm';

interface Props {
  open: boolean;
  handleClose: () => void;
  channel: HikVisionChannel;
  customerId: string;
  keyChannel: number;
}
export default function HikvisionDialogForm({
  open,
  handleClose,
  keyChannel,
  channel,
  customerId,
}: Props) {
  const editChannelAtom = useRecoilCallback(({ set }) => async (newChannel: HikVisionChannel) => {
    set(hikvisionBatchFormAtom, (channels) =>
      channels.map((item, index) => (index + 1 === keyChannel ? newChannel : item))
    );
  });
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{
        width: '100%',
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <HikvisionForm
          initialValues={channel}
          doSubmit={editChannelAtom}
          customerId={customerId}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
