import { atomFamily } from 'recoil';

export const textTimeTooltipAtom = atomFamily<string | null, string>({
  key: 'components.CronologicBar.textTimeTooltipAtom',
  default: null,
});

export const leftTimeTooltipAtom = atomFamily<number, string>({
  key: 'components.CronologicBar.leftTimeTooltipAtom',
  default: 0,
});

export const periodStartDateAtom = atomFamily<Date, string>({
  key: 'components.CronologicBar.periodStartDateAtom',
  default: new Date(),
});

export const periodEndDateAtom = atomFamily<Date, string>({
  key: 'components.CronologicBar.periodEndDateAtom',
  default: new Date(),
});
