import {
  CustomizedResponseCodeEnum,
  ChannelConnectionResponse,
  BatchChannels,
} from 'types/channels.types';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IntelbrasBody, IntelbrasTestConnectionResponse } from 'types/intelbras.types';
import { HikTestConnectionResponse, HikvisionBody } from 'types/hikvision.types';
import useMessages from '../../../hooks/useMessages';

interface SingleTestingChannel {
  url: string;
}

interface BatchTestingChannel {
  urls: BatchChannels[];
}

interface P2PTesting {
  data: IntelbrasBody | HikvisionBody;
}

export function useTestingChannel() {
  const { t } = useTranslation('channels');
  const { enqueueError } = useMessages();

  const testingSingleChannel = async ({
    url,
  }: SingleTestingChannel): Promise<ChannelConnectionResponse | undefined> => {
    try {
      const { data } = await Axios.post<ChannelConnectionResponse>('/v1/channels/testConnection', {
        url,
      });

      return data;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        error?.response?.status !== 408 &&
          enqueueError(
            `${t('error_testing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
      }
    }

    return undefined;
  };

  const testingBatchChannels = async ({
    urls,
  }: BatchTestingChannel): Promise<ChannelConnectionResponse[] | undefined> => {
    try {
      const { data } = await Axios.post<ChannelConnectionResponse[]>(
        '/v1/channels/testConnection/many',
        urls
      );
      return data;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        error?.response?.status !== 408 &&
          enqueueError(
            `${t('error_testing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
      }
    }
    return undefined;
  };

  const testingIntelbrasChannel = async ({
    values,
  }: {
    values: IntelbrasBody;
  }): Promise<IntelbrasTestConnectionResponse | undefined> => {
    try {
      const { data: response } = await Axios.post<IntelbrasTestConnectionResponse>(
        '/v1/channels/ib/testConnection',
        values
      );

      return response;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        error?.response?.status !== 408 &&
          enqueueError(
            `${t('error_testing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
      }
    }
    return undefined;
  };

  const testingHikvisionChannel = async ({
    data,
  }: P2PTesting): Promise<HikTestConnectionResponse | undefined> => {
    try {
      const { data: response } = await Axios.post<HikTestConnectionResponse>(
        '/v1/channels/hik/testConnection',
        data
      );
      return response;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        error?.response?.status !== 408 &&
          enqueueError(
            `${t('error_testing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
      }
    }
    return undefined;
  };

  const testingBatchIntelbras = async (
    channelList: IntelbrasBody[]
  ): Promise<IntelbrasTestConnectionResponse[] | undefined> => {
    try {
      const { data: response } = await Axios.post<IntelbrasTestConnectionResponse[]>(
        '/v1/channels/ib/testConnection/many',
        channelList
      );
      const transformedData = response.map((item, index) => ({
        ...item,
        name: channelList[index].name,
        serial: channelList[index].serial,
        channel: channelList[index].channel,
        port: channelList[index].port,
      }));

      return transformedData;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        error?.response?.status !== 408 &&
          enqueueError(
            `${t('error_testing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
      }
    }
    return undefined;
  };

  const testingBatchHikvision = async (
    channels: HikvisionBody[]
  ): Promise<HikTestConnectionResponse[] | undefined> => {
    try {
      const { data } = await Axios.post<
        {
          message: string;
          success: CustomizedResponseCodeEnum;
        }[]
      >('/v1/channels/hik/testConnection/many', channels);

      const transformedData = data.map((item, index) => ({
        ...item,
        name: channels[index].name,
        serialNumber: channels[index].serial,
        p2pChannel: channels[index].channel,
      }));
      return transformedData;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        !(error?.response?.status === 408) &&
          enqueueError(
            `${t('channels:error_testing')}\n${t(
              `api_errors_messages:${error?.response?.data?.message}`
            )}`
          );
      }
    }

    return undefined;
  };

  return {
    testingSingleChannel,
    testingBatchChannels,
    testingIntelbrasChannel,
    testingHikvisionChannel,
    testingBatchIntelbras,
    testingBatchHikvision,
  };
}
