import React, { useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { User } from 'types/user.types';
import { useMessages } from 'hooks';
import { useTranslation } from 'react-i18next';
import AlertConsumption from 'components/AlertConsumption';
import { Button } from '@mui/material';
import ContactUsModal from 'components/core/ContactUsModal';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { SnackbarKey } from 'notistack';

interface IAlertStorage {
  masterCompanyId: number;
  consumptionPercentageStorage: number;
}
interface IAlertTransmission {
  masterCompanyId: number;
  consumptionPercentageTransmission: number;
}
interface IConsumptionProps {
  socket: Socket | null;
  loggedUser?: User | null;
}

function useConsumption({ socket, loggedUser }: IConsumptionProps) {
  const { closeSnackbar, enqueueWarning, enqueueError } = useMessages();
  const { t } = useTranslation();
  const history = useHistory();
  const keyMessageRef = useRef<SnackbarKey | undefined>();
  const [open, setOpen] = React.useState(true);

  useEffect(
    function onTransmissionSocketMessage() {
      if (socket && loggedUser) {
        const SHOW_ALERT_TRANSMISSION = `show_alert_transmission_${loggedUser.masterCompany.id}`;

        socket.off(SHOW_ALERT_TRANSMISSION);
        socket.on(
          SHOW_ALERT_TRANSMISSION,
          ({ consumptionPercentageTransmission }: IAlertTransmission) => {
            const percentage = Math.trunc(consumptionPercentageTransmission);

            // if (keyMessageRef.current) closeSnackbar(keyMessageRef.current);

            if (percentage > 150) {
              keyMessageRef.current = enqueueError(
                <AlertConsumption
                  message={t('consumption:alert_consumption_transmission_out_limit', {
                    percentage,
                  })}
                  textColor="inherit"
                  contactUS="support2"
                  setOpen={setOpen}
                />,
                {
                  persist: true,
                  preventDuplicate: true,
                  autoHideDuration: null,
                  style: {
                    width: '93vw',
                  },
                  action: (key) => (
                    <>
                      <Button onClick={() => history.push(routes.metrics.consumption())}>
                        {t('consumption:view_status_consumption')}
                      </Button>
                      <Button
                        sx={{
                          color: 'white',
                        }}
                        onClick={() => {
                          closeSnackbar(key);
                        }}
                      >
                        {t('_common:dismiss')}
                      </Button>
                      <ContactUsModal
                        open={open}
                        onClose={() => {
                          closeSnackbar(key);
                          setOpen(false);
                        }}
                      />
                    </>
                  ),
                }
              );
            } else if (percentage < 150 && percentage > 99) {
              keyMessageRef.current = enqueueError(
                <AlertConsumption
                  message={t('consumption:alert_consumption_transmission', { percentage })}
                  textColor="inherit"
                  contactUS="support1"
                  setOpen={setOpen}
                />,
                {
                  persist: true,
                  preventDuplicate: true,
                  autoHideDuration: null,
                  style: {
                    width: '93vw',
                  },
                  action: (key) => (
                    <>
                      <Button onClick={() => history.push(routes.metrics.consumption())}>
                        {t('consumption:view_status_consumption')}
                      </Button>
                      <Button
                        sx={{
                          color: 'white',
                        }}
                        onClick={() => {
                          closeSnackbar(key);
                        }}
                      >
                        {t('_common:dismiss')}
                      </Button>
                      <ContactUsModal
                        open={open}
                        onClose={() => {
                          closeSnackbar(key);
                          setOpen(false);
                        }}
                      />
                    </>
                  ),
                }
              );
            } else {
              keyMessageRef.current = enqueueWarning(
                <AlertConsumption
                  message={t('consumption:alert_consumption_transmission', { percentage })}
                  textColor="initial"
                  contactUS="commercial"
                  setOpen={setOpen}
                />,
                {
                  style: { width: '93vw' },
                  action: (key) => (
                    <>
                      <Button
                        sx={{
                          color: '#585858',
                          marginRight: '1rem',
                        }}
                        onClick={() => history.push(routes.metrics.consumption())}
                      >
                        {t('consumption:view_status_consumption')}
                      </Button>
                      <Button
                        sx={{
                          color: '#585858',
                        }}
                        onClick={() => {
                          closeSnackbar(key);
                        }}
                      >
                        {t('_common:dismiss')}
                      </Button>
                      <ContactUsModal
                        open={open}
                        onClose={() => {
                          closeSnackbar(key);
                          setOpen(false);
                        }}
                      />
                    </>
                  ),
                }
              );
            }
          }
        );
      }
    },
    [closeSnackbar, enqueueError, enqueueWarning, history, loggedUser, open, setOpen, socket, t]
  );

  useEffect(
    function onStorageSocketMessage() {
      if (socket && loggedUser) {
        const SHOW_ALERT_STORAGE = `show_alert_storage_${loggedUser.masterCompany.id}`;

        socket.off(SHOW_ALERT_STORAGE);
        socket.on(SHOW_ALERT_STORAGE, ({ consumptionPercentageStorage }: IAlertStorage) => {
          const percentage = Math.trunc(consumptionPercentageStorage);
          // if (keyMessageRef.current) closeSnackbar(keyMessageRef.current);
          if (percentage > 99) {
            keyMessageRef.current = enqueueError(
              <AlertConsumption
                message={t('consumption:alert_consumption_storage', { percentage })}
                textColor="inherit"
                contactUS="support1"
                setOpen={setOpen}
              />,
              {
                persist: true,
                preventDuplicate: true,
                autoHideDuration: null,
                style: {
                  width: '93vw',
                },
                action: (key) => (
                  <>
                    <Button onClick={() => history.push(routes.metrics.consumption())}>
                      {t('consumption:view_status_consumption')}
                    </Button>
                    <Button
                      sx={{
                        color: 'white',
                      }}
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      {t('_common:dismiss')}
                    </Button>
                    <ContactUsModal
                      open={open}
                      onClose={() => {
                        closeSnackbar(key);
                        setOpen(false);
                      }}
                    />
                  </>
                ),
              }
            );
          } else {
            keyMessageRef.current = enqueueWarning(
              <AlertConsumption
                message={t('consumption:alert_consumption_storage', { percentage })}
                textColor="initial"
                contactUS="commercial"
                setOpen={setOpen}
              />,
              {
                style: { width: '93vw' },
                action: (key) => (
                  <>
                    <Button
                      sx={{
                        color: '#585858',
                        marginRight: '1rem',
                      }}
                      onClick={() => history.push(routes.metrics.consumption())}
                    >
                      {t('consumption:view_status_consumption')}
                    </Button>
                    <Button
                      sx={{
                        color: '#585858',
                      }}
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      {t('_common:dismiss')}
                    </Button>
                    <Button
                      sx={{
                        color: '#585858',
                      }}
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      {t('_common:dismiss')}
                    </Button>
                    <ContactUsModal
                      open={open}
                      onClose={() => {
                        closeSnackbar(key);
                        setOpen(false);
                      }}
                    />
                  </>
                ),
              }
            );
          }
        });
      }
    },
    [closeSnackbar, enqueueError, enqueueWarning, history, loggedUser, open, setOpen, socket, t]
  );
}
export default useConsumption;
