import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  scroll-behaviour: smooth;
  padding: 0 16px;
  padding-top: 1.5rem;
  padding-bottom: 4rem;
`;

export const Header = styled.div`
  margin-bottom: 1.8rem;
  position: relative;
  > a {
    position: absolute;
    right: 25px;
  }
  > button {
    position: absolute;
    right: 110px;
  }
`;
