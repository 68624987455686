import React from 'react';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { PermissionRole } from 'types/user.types';

function EmptyChannel(props: React.PropsWithChildren<{ route: string }>): React.ReactElement | null;
function EmptyChannel(
  props: React.PropsWithChildren<{ onAddClick: React.MouseEventHandler }>
): React.ReactElement | null;
function EmptyChannel({
  route,
  onAddClick,
}: {
  onAddClick?: React.MouseEventHandler;
  route?: string;
}) {
  const { t } = useTranslation('channels');

  if (!route && !onAddClick) {
    return null;
  }

  const props = {
    title: t('register_first_channel'),
    description: [
      t('channel_is_camera_linked_to_customer'),
      t('camera_image_will_be_displayed_mosaic'),
    ],
    captionButton: t('register_channel'),
    roleAdd: PermissionRole.INSERT_CHANNEL,
  };
  // TODO error typescript, refactor this
  if (route) {
    return <EmptyState {...props} {...{ route }} />;
  }

  if (onAddClick) {
    return <EmptyState {...props} {...{ onAddClick }} />;
  }
}

export default EmptyChannel;
