import React from 'react';
import { useRecoilCallback } from 'recoil';
import { Dialog, DialogContent } from '@mui/material';
import { batchIntelbrasFormAtom } from 'atoms/intelbras';
import { IntelbrasChannel } from 'types/intelbras.types';
import { IntelbrasForm } from './IntelbrasForm';

interface IntelbrasDialog {
  open: boolean;
  handleClose: () => void;
  channel: IntelbrasChannel;
  customerId: string;
  keyChannel: number;
}
export default function IntelbrasFormDialog({
  open,
  handleClose,
  keyChannel,
  channel,
  customerId,
}: IntelbrasDialog) {
  const editChannelAtom = useRecoilCallback(({ set }) => async (newChannel: IntelbrasChannel) => {
    set(batchIntelbrasFormAtom, (channels) =>
      channels.map((item, index) => (index + 1 === keyChannel ? newChannel : item))
    );
  });
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{
        width: '100%',
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <IntelbrasForm
          onClose={handleClose}
          initialValues={channel}
          doSubmit={editChannelAtom}
          customerId={customerId}
        />
      </DialogContent>
    </Dialog>
  );
}
