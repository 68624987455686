import React from 'react';
import useSpeedTestAPI from 'hooks/speedTest/useSpeedTestAPI';
import styled from 'styled-components';
import { Box } from '@mui/material';
import SpeedTestView from './SpeedTestView';

const Container = styled(Box)({
  paddingTop: '32px',
});

function SpeedTestTab() {
  const { saveResult } = useSpeedTestAPI();

  return (
    <Container>
      <SpeedTestView onSave={saveResult} />
    </Container>
  );
}

export default SpeedTestTab;
