import { PermissionRole, PermissionGroup } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { loggedUserAtom } from 'atoms/users';

function Permissioned({
  role,
  children,
}: {
  role: PermissionRole | PermissionRole[];
  children: React.ReactNode;
}) {
  const havePermissionFn = useHavePermission();
  const loggedUser = useRecoilValue(loggedUserAtom);

  const canRender = Array.isArray(role)
    ? role.some((permissionRole) => havePermissionFn(permissionRole))
    : havePermissionFn(role);

  if (loggedUser?.permission === PermissionGroup.CLIENT) {
    return null;
  }
  if (!canRender) {
    return null;
  }
  return <>{children}</>;
}

export default Permissioned;
