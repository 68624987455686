import { Box, CircularProgress } from '@mui/material';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import routes from 'helpers/routes';
import { useMessages } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function DownloadPage() {
  const history = useHistory();
  const [proxyFlag] = useDecision(FEATURE_FLAGS_KEYS.DOWNLOAD_VMS_CLOUD_WITH_PROXY_TO_CANADA);
  const { enqueueError, enqueueSuccess } = useMessages();
  const { t } = useTranslation();

  useEffect(() => {
    const handleDownload = async () => {
      try {
        const url = proxyFlag.enabled
          ? 'https://vmscloud.s3.amazonaws.com/Segware+VMS+Cloud+v1.0.0+PROXY.zip'
          : 'https://vmscloud.s3.amazonaws.com/Segware+VMS+Cloud+v1.0.0.zip';

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'VMS Cloud App');

        document.body.appendChild(link);
        link.click();
        enqueueSuccess(t('_common:download_will_start_soon'));
      } catch (err) {
        console.error({ err });
        enqueueError(t('_common:it_was_not_possible_to_download'));
      }
    };

    handleDownload();

    const timeoutId = setTimeout(() => {
      history.push(routes.welcome.root);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size="1.6rem" color="primary" />
    </Box>
  );
}
