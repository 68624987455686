import { VideoLiveSource } from 'types/channels.types';
import useFetch from '../useFetch';

function useChannelStartLive({
  channelId,
  conditionFn,
}: {
  channelId: number;
  conditionFn?: () => boolean;
}): VideoLiveSource | undefined {
  const { data } = useFetch<VideoLiveSource>(`/v1/channels/${channelId}/url`, {
    revalidateOnFocus: false,
    errorRetryInterval: 1000,
    conditionFn,
  });

  return data;
}

export default useChannelStartLive;
