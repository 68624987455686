import React from 'react';
import CustomerDetails from 'components/CustomerDetails';
import { useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch } from 'hooks';
import NotFound from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { Customer } from 'types/customer.types';
import { normalizeCustomer } from 'atoms/customers';

const useStyles = makeStyles({
  root: {
    padding: '43.2px 33.92px',
    overflow: 'hidden',
    height: '100%',
    '& > div': {
      marginLeft: '0',
      overflow: 'hidden',
      height: '100%',
    },
  },
});

function CustomerDetailsPage() {
  const classes = useStyles();
  const { t } = useTranslation('customers');
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = useFetch<Customer>(`/v1/clients/${customerId}`, {
    normalizeData: normalizeCustomer,
  });

  if (!customer) {
    return (
      <NotFound
        title={t('non_exixstent_customer')}
        reason={t('customer_does_not_exist_or_may_have_been_deleted')}
        shouldDo={t('register_a_new_customer_or_select_another_customer_to_be_displayed')}
      />
    );
  }

  return <Box className={classes.root}>{customer && <CustomerDetails customer={customer} />}</Box>;
}

export default asyncComponent(CustomerDetailsPage);
