import { useRecoilState } from "recoil";
import { mosaicItensValuesAtom } from "atoms/mosaicItems";
import { MosaicItem } from "types/mosaics.types";

export function useUpdateMosaicItem(
  mosaicId: number,
  slotId: number
): (newValues: Partial<MosaicItem>) => void {
  const [mosaicItens, setMosaicItems] = useRecoilState(mosaicItensValuesAtom(mosaicId));

  return (newValues: Partial<MosaicItem>) => {
    setMosaicItems([
      ...mosaicItens.map((mosaicItem) =>
        mosaicItem.order !== slotId ? mosaicItem : { ...mosaicItem, ...newValues }
      ),
    ]);
  };
}
