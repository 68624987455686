import React from 'react';
import { IconButton, Typography } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { useTranslation } from 'react-i18next';
import { useNetwork } from 'react-use';
import { useScreenDetector } from 'hooks/useScreenDetector';

export function NetworkStatus() {
  const { t } = useTranslation();
  const network = useNetwork();
  const { md } = useScreenDetector();

  return !network.online ? (
    <IconButton
      size="small"
      aria-label="offline"
      data-testid="CloudOffIconId"
      sx={(theme) => ({
        bgcolor: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        gap: theme.spacing(0.5),
        px: theme.spacing(0.5),
      })}
    >
      <CloudOffIcon />
      {!md && <Typography>{t('_common:offline')}</Typography>}
    </IconButton>
  ) : null;
}
