import React from 'react';
import { asyncComponent } from 'HOCs';
import { Company } from 'types/companies.types';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { normalizeCompany, useCompaniesApi } from 'hooks/companies/useCompaniesApi';
import CompanyForm from '../CompanyPage/CompanyForm';

function CompanyEditPage() {
  const { updateCompany, deleteCompany } = useCompaniesApi();
  const { id } = useParams<{ id: string }>();

  const { data: company } = useFetch(`/v1/companies/${id}`, {
    cleanCacheAfterMs: 1500,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    normalizeData: normalizeCompany,
  });

  const handleEdit = async (values: Company) => {
    await updateCompany(values);
  }

  return (
    <CompanyForm
      initialValues={company as Company}
      doSubmit={handleEdit}
      showDeleteButton
      doDelete={deleteCompany}
    />
  );
}

export default asyncComponent(CompanyEditPage);
