/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR, { SWRConfiguration, cache, Key } from 'swr';
import Axios, { AxiosResponse, ResponseType } from 'axios';
import { Fetcher } from 'swr/dist/types';

type DataFinal<Data, NormalizedData> = NormalizedData extends Data ? Data : NormalizedData;

type ConfigFetch<Data, NormalizedData> = SWRConfiguration<DataFinal<Data, NormalizedData>> & {
  params?: any;
  normalizeData?: (data: Data) => DataFinal<Data, NormalizedData>;
  conditionFn?: () => boolean;
  cleanCacheAfterMs?: number;
  key?: Key;
  baseURL?: string;
  responseType?: ResponseType;
};

function useFetch<Data = any, NormalizedData = Data>(
  url: string,
  {
    normalizeData = (data: Data) => data as DataFinal<Data, NormalizedData>,
    conditionFn = () => true,
    cleanCacheAfterMs,
    params,
    key,
    baseURL,
    responseType,
    ...config
  }: ConfigFetch<Data, NormalizedData> = {
    normalizeData: (data: Data) => data as DataFinal<Data, NormalizedData>,
    conditionFn: () => true,
  }
) {
  const calcKey = key || params ? [url, ...Object.values(params)] : url;
  const fetcherFnp: Fetcher<DataFinal<Data, NormalizedData>> | null = conditionFn()
    ? async (_url) => {
        const response = await Axios.get<any, AxiosResponse<Data>>(_url, {
          params,
          baseURL,
          responseType,
        });

        if (cleanCacheAfterMs) {
          setTimeout(() => {
            cache.delete(calcKey);
          }, cleanCacheAfterMs);
        }
        return response.data
          ? normalizeData(response.data)
          : (response.data as DataFinal<Data, NormalizedData>);
      }
    : null;
  const returned = useSWR<DataFinal<Data, NormalizedData>>(calcKey, fetcherFnp, {
    suspense: true,
    ...config,
  });

  return returned;
}

export default useFetch;
