import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { normalizeCustomer } from 'atoms/customers';
import CustomerForm from 'components/CustomerForm';
import { useFetch, useQuery } from 'hooks';
import ApiBoundary from 'components/ApiBoundary';
import routes from 'helpers/routes';
import { Customer } from 'types/customer.types';
import { useCustomerApi } from 'hooks/customers/useCustomersApi';

function CustomerScreenPage() {
  const history = useHistory();
  const query = useQuery();

  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = useFetch<Customer>(`/v1/clients/${customerId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    cleanCacheAfterMs: 1500,
    normalizeData: normalizeCustomer,
  });
  const { editCustomer, deleteCustomer } = useCustomerApi();

  return (
    <ApiBoundary>
      <CustomerForm
        initialValues={customer as Customer}
        doSubmit={editCustomer}
        showDeleteButton
        doDelete={deleteCustomer}
        onSubmitSuccess={(customerEdited) =>
          history.push(query.get('returnRoute') || routes.customer.root(customerEdited.id))
        }
      />
    </ApiBoundary>
  );
}

export default asyncComponent(CustomerScreenPage);
