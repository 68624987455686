import { atom } from 'recoil';

export interface ICurrentPlan {
  consumedSize: number;
  storageSize: number;
}
export const storageSize = atom<ICurrentPlan>({
  key: 'storageSize', // unique ID (with respect to other atoms/selectors)
  default: {
    consumedSize: 0,
    storageSize: 0,
  }, // default value (aka initial value)
});