import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PermissionGroup } from 'types/user.types';

function SelectPermission({
  selectedPermission,
  onChangeSelectPermission,
  error,
  helperText,
}: {
  selectedPermission: string | null;
  onChangeSelectPermission: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation('users');

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-customer-permission-label">{t('permission')}</InputLabel>
      <Select
        name="permission"
        id="state"
        label={t('permission')}
        labelId="select-customer-permission-label"
        fullWidth
        // @ts-ignore
        onChange={onChangeSelectPermission}
        value={selectedPermission}
      >
        <MenuItem aria-label="item" value={PermissionGroup.ADMIN}>
          {t('users:user_permission_ADMIN')}
        </MenuItem>
        <MenuItem aria-label="item" value={PermissionGroup.OPERATOR}>
          {t('users:user_permission_OPERATOR')}
        </MenuItem>
        <MenuItem aria-label="item" value={PermissionGroup.OPERATOR_LOW}>
          {t('users:user_permission_OPERATOR_LOW')}
        </MenuItem>
        <MenuItem aria-label="item" value={PermissionGroup.COMMERCIAL}>
          {t('users:user_permission_COMMERCIAL')}
        </MenuItem>
        <MenuItem aria-label="item" value={PermissionGroup.CLIENT}>
          {t('users:user_permission_CLIENT')}
        </MenuItem>
        <MenuItem aria-label="item" value={PermissionGroup.SUPERVISOR}>
          {t('users:user_permission_SUPERVISOR')}
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectPermission;
