import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { ReactComponent as NotFoundIcon } from 'assets/not-found.svg';
import styled from 'styled-components';

const Container = styled(Box)`
  > * {
    margin-bottom: 8px;
  }

  > svg {
    margin-bottom: 16px;
  }
`;

function NotFound({
  title,
  reason,
  shouldDo,
  actionButton,
}: {
  title: string;
  reason: string;
  shouldDo: string;
  actionButton?: ReactNode;
}) {
  return (
    <Container
      flex="1 1 auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <NotFoundIcon />
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body1">{reason}</Typography>
      <Typography variant="body2" color="textSecondary">
        {shouldDo}
      </Typography>

      {actionButton}
    </Container>
  );
}

export default NotFound;
