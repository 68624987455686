import { useCallback } from 'react';
import {
  actualFowardRewindSecondsAtom,
  currentTimeAtom,
  getRecordedDataInDate,
  lastFowardRewindClickAtom,
  mergedLiveSelector,
  mergedMutedSelector,
  mergedPausedSelector,
  mergedSelectedDateSelecor,
  mergedWidescreenSelector,
  playbackRateAtom,
  playbackStartDateDataBarSelector,
  recordsAtom,
  selectedDateInPickerAtom,
} from 'atoms/playback';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

export default function useMergedControlsBar({
  channelsId,
  mosaicId,
}: {
  channelsId: number[];
  mosaicId: number;
}) {
  const [paused, setPaused] = useRecoilState(mergedPausedSelector(channelsId));
  const [muted, setMuted] = useRecoilState(mergedMutedSelector(channelsId));
  const currentTime = useRecoilValue(currentTimeAtom(channelsId[0]));
  const playbackRate = useRecoilValue(playbackRateAtom(channelsId[0]));
  const [widescreen, setWidescreen] = useRecoilState(
    mergedWidescreenSelector({ mosaicId, channelsId })
  );
  const [live, setLive] = useRecoilState(mergedLiveSelector({ mosaicId, channelsId }));
  const [selectedDate, setSelectedDate] = useRecoilState(
    mergedSelectedDateSelecor({ mosaicId, channelsId })
  );
  const playbackStartDateDataBar = useRecoilValue(
    playbackStartDateDataBarSelector({ mosaicId, channelId: channelsId[0] })
  );

  const handleWatchLive = useCallback(() => {
    setLive(true);
  }, [setLive]);

  const handleChangeDateTimePicker = useRecoilCallback(
    ({ set }) => (v: Date) => {
      if (channelsId.length === 0) {
        return;
      }

      channelsId.forEach((channelId) => {
        (async function fetchRecords() {
          const dataRecords = await getRecordedDataInDate(channelId, v);
          set(recordsAtom(channelId), (records) =>
            records ? records.concat(dataRecords.data) : dataRecords.data
          );
        })();
        setSelectedDate(v);
        set(selectedDateInPickerAtom, v);
      });
    },
    [channelsId, setSelectedDate]
  );

  const setPlaybackRate = useRecoilCallback(
    ({ set }) => (newValue: number) => {
      channelsId.forEach((channelId) => {
        set(playbackRateAtom(channelId), newValue);
      });
    },
    [channelsId]
  );

  const handleFowardRewind = useRecoilCallback(
    ({ set }) => (time: number) => {
      set(actualFowardRewindSecondsAtom, time);
      channelsId.forEach((channelId) => {
        set(lastFowardRewindClickAtom(channelId), new Date());
      });
    },
    [channelsId]
  );

  return {
    paused,
    setPaused,
    muted,
    setMuted,
    currentTime,
    widescreen,
    setWidescreen,
    live,
    handleWatchLive,
    selectedDate,
    playbackStartDateDataBar,
    handleChangeDateTimePicker,
    playbackRate,
    setPlaybackRate,
    handleFowardRewind,
  };
}
