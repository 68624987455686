import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TitleTotalStorage } from './Header/TitleTotalStorage';
import { BodyTotalStorage } from './Body/BodyTotalStorage';
import { FooterTotalStorage } from './Footer/FooterTotalStorage';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    width: '100%',
    borderRadius: '5px',
  },
}));

export function TotalStorage({
  transmissionValue,
  storageValue,
}: {
  transmissionValue: number;
  storageValue: number;
}) {
  const classes = useStyles();

  const total = transmissionValue + storageValue;

  return (
    <>
      <Box>
        <Box className={classes.container}>
          <TitleTotalStorage />
          <BodyTotalStorage transmissionValue={transmissionValue} storageValue={storageValue} />
          <FooterTotalStorage totalValue={total} />
        </Box>
      </Box>
    </>
  );
}
