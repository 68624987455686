import React from 'react';
import { useRecoilValue } from 'recoil';
import { asyncComponent } from 'HOCs';
import { useFetch } from 'hooks';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import EmptyState from 'components/EmptyState';
import { useHistory } from 'react-router-dom';
import { PermissionRole } from 'types/user.types';
import { RecordingSchedule } from 'types/recordingSchedule.types';
import { RecordingScheduleTable } from './RecordingScheduleTable';
import { scheduleListSearchTextAtom } from './useRecordingScheduleCRUD';
import RecordingScheduleHeaderBar from './RecordingScheduleHeaderBar';

function RecordingSchedules({ schedules }: { schedules: RecordingSchedule[] }) {
  return (
    <>
      <RecordingScheduleHeaderBar />
      <RecordingScheduleTable {...{ schedules }} />
    </>
  );
}

function RecordingScheduleFetch() {
  const text = useRecoilValue(scheduleListSearchTextAtom);
  const { data: schedules } = useFetch<RecordingSchedule[]>('/v1/scheduledRecording', {
    params: { text },
  });

  const history = useHistory();
  const { t } = useTranslation();

  if (!schedules?.length && !text) {
    return (
      <EmptyState
        title={t('_common:register_first_schedule')}
        description={t('_common:description_of_recording_schedule_empty_state')}
        captionButton={t('_common:register_recording_schedule')}
        onAddClick={() => history.push(routes.settings.recordingSchedule.create)}
        roleAdd={PermissionRole.INSERT_CHANNEL}
      />
    );
  }
  return schedules ? <RecordingSchedules {...{ schedules }} /> : null;
}

export default asyncComponent(RecordingScheduleFetch);
