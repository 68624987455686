import React from 'react';
import 'date-fns';
import { Box, IconButton, BoxProps, Button } from '@mui/material';
import {
  PlayArrow,
  Pause,
  FullscreenExit,
  PictureInPictureAltOutlined,
  GetApp,
  VolumeOffRounded,
  VolumeUpRounded,
} from '@mui/icons-material';
import routes from 'helpers/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FowardRewindAction from './V2FowardRewindAction';
import DateTimePlayback from './V2DateTimePlayback';
import PlaybackSpeedSelect from './V2PlaybackSpeedSelect';

function VideoControlsBar({
  paused,
  setPaused,
  muted,
  setMuted,
  mosaicId = null,
  currentTime,
  widescreen,
  setWidescreen,
  nowLiveDate,
  live,
  handleWatchLive,
  handleFowardRewind,
  selectedDate,
  playbackStartDateDataBar,
  handleChangeDateTimePicker,
  setPlaybackRate,
  playbackRate,
  doVideoTrimming,
  ...props
}: {
  paused: boolean;
  setPaused: (paused: boolean) => void;
  muted: boolean;
  setMuted: (paused: boolean) => void;
  mosaicId?: number | null;
  currentTime: number;
  widescreen?: boolean;
  setWidescreen: (widescreen: boolean) => void;
  nowLiveDate: Date;
  live: boolean;
  handleWatchLive: () => void;
  handleFowardRewind: (time: number) => void;
  selectedDate: Date | null;
  playbackStartDateDataBar: string | null;
  handleChangeDateTimePicker: (v: Date) => void;
  setPlaybackRate: (newValue: number) => void;
  playbackRate: number;
  doVideoTrimming?: () => void;
} & BoxProps) {
  const history = useHistory();
  const matchSlotView = useRouteMatch(routes.mosaic.slotView(':mosaicId', ':slotId'));
  const matchPlaybackView = useRouteMatch(
    routes.customer.channel.playback(':customerId', ':channelId')
  );
  const { t } = useTranslation(['video', '_common']);

  return (
    <Box
      padding="12px"
      display="flex"
      alignItems="center"
      aria-label="control bar"
      role="region"
      flex="1 1 auto"
      {...props}
    >
      <VideoControlsBar.PlayPauseAction {...{ paused, setPaused }} />
      <VideoControlsBar.MuteUnmuteAction {...{ muted, setMuted }} />
      <VideoControlsBar.WidescreenAction {...{ widescreen, setWidescreen }} />
      <FowardRewindAction {...{ live, handleFowardRewind }} />
      {!live && (
        <PlaybackSpeedSelect
          {...{
            setPlaybackRate,
            playbackRate,
          }}
        />
      )}
      <VideoControlsBar.DateTimePlayback
        {...{
          handleChangeDateTimePicker,
          playbackStartDateDataBar,
          nowLiveDate,
          selectedDate,
          currentTime,
          live,
          handleWatchLive,
        }}
      />
      {matchSlotView?.isExact ? (
        <>
          {doVideoTrimming && <DownloadVideoAction {...{ doVideoTrimming }} />}
          <IconButton
            aria-label="exit fullscreen"
            onClick={() => mosaicId && history.push(routes.mosaic.view(mosaicId))}
            size="large"
            title={t('_common:exit_fullscreen_mode')}
          >
            <FullscreenExit />
          </IconButton>
          <Button onClick={handleWatchLive} size="small" sx={{ py: 0.1 }}>
            {t('video:watch_live')}
          </Button>
        </>
      ) : null}
      {matchPlaybackView?.isExact && doVideoTrimming ? (
        <DownloadVideoAction {...{ doVideoTrimming }} />
      ) : null}
    </Box>
  );
}

export function DownloadVideoAction({ doVideoTrimming }: { doVideoTrimming: () => void }) {
  const { t } = useTranslation('video');

  return (
    <IconButton
      aria-label="Download video"
      onClick={() => doVideoTrimming?.()}
      size="large"
      title={t('video:download') ?? ''}
    >
      <GetApp height="1em" width="1em" />
    </IconButton>
  );
}

VideoControlsBar.PlayPauseAction = function PlayPauseAction({
  paused,
  setPaused,
}: {
  paused: boolean;
  setPaused: (paused: boolean) => void;
}) {
  const { t } = useTranslation('video');

  return (
    <IconButton
      aria-label={paused ? 'play' : 'pause'}
      onClick={() => setPaused(!paused)}
      size="small"
      title={paused ? t('video:play_video') : t('video:pause_video')}
      sx={{ p: 0, mr: 1.5 }}
    >
      {paused ? <PlayArrow /> : <Pause />}
    </IconButton>
  );
};

VideoControlsBar.MuteUnmuteAction = function MuteUnmuteAction({
  muted,
  setMuted,
}: {
  muted: boolean;
  setMuted: (paused: boolean) => void;
}) {
  const { t } = useTranslation('video');

  return (
    <IconButton
      aria-label={muted ? 'unmute' : 'mute'}
      onClick={() => setMuted(!muted)}
      size="small"
      title={muted ? t('video:unmute_video') : t('video:mute_video')}
      sx={{ p: 0, mr: 1.5 }}
    >
      {muted ? <VolumeOffRounded /> : <VolumeUpRounded />}
    </IconButton>
  );
};

VideoControlsBar.WidescreenAction = function WidescreenAction({
  widescreen,
  setWidescreen,
}: {
  widescreen?: boolean;
  setWidescreen: (widescreen: boolean) => void;
}) {
  const { t } = useTranslation('video');

  return (
    <IconButton
      color={widescreen ? 'primary' : undefined}
      onClick={() => setWidescreen(!widescreen)}
      size="small"
      title={widescreen ? t('video:deactivate_widescreen') : t('video:activate_widescreen')}
      sx={{ p: 0, mr: 1.5 }}
    >
      <PictureInPictureAltOutlined />
    </IconButton>
  );
};

VideoControlsBar.DateTimePlayback = DateTimePlayback;
VideoControlsBar.WatchLiveButton = function WatchLiveButton({
  live,
  handleWatchLive,
}: {
  live: boolean;
  handleWatchLive: () => void;
}) {
  const { t } = useTranslation('video');

  if (live) {
    return null;
  }
  return (
    <Button onClick={handleWatchLive} size="small" sx={{ py: 0.1 }}>
      {t('video:watch_live')}
    </Button>
  );
};

VideoControlsBar.PlaybackSpeedSelect = PlaybackSpeedSelect;
VideoControlsBar.FowardRewindAction = FowardRewindAction;

export default VideoControlsBar;
