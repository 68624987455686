/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { mosaicItemWidescreenSelector } from 'atoms/mosaicItems';
import {
  IconButton,
  Box,
  alpha as muifade,
  Typography,
  useTheme,
  Divider,
  Card,
  Dialog,
  DialogContent,
  Breadcrumbs,
  Link,
  Button,
  DialogTitle,
} from '@mui/material';
import { ChannelVideo } from 'components/Channel';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import routes from 'helpers/routes';
import { useDebounce, useKeyPress, useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { VideoLiveSource } from 'types/channels.types';
import {
  DeleteRounded,
  FullscreenExit,
  GetApp,
  OpenInNew,
  PictureInPictureAltOutlined,
  VolumeOffRounded,
  VolumeUpRounded,
} from '@mui/icons-material';
import { liveSelector, mutedAtom, pausedAtom, selectedDateAtom } from 'atoms/playback';
import { viewVideoDetailsAtom } from 'atoms/mosaics';
import { Live } from 'components/core/Icons';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { ChannelSimple } from 'types/mosaics.types';
import { useUpdateMosaicItem } from 'hooks/mosaic/items/useUpdateMosaicItem';
import ChannelControlsBar, {
  useChannelControlsBar,
} from 'components/CronologicBarFullscreen/ChannelControlsBarFullscreen';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import ChannelCronologicBar from 'components/CronologicBarFullscreen/ChannelCronologicBar';
import ExportVideoForm from 'components/ExportVideoForm/ExportVideoForm';
import FullScreenButton from './FullScreenButton';
import VideoTechInfo from './VideoTechInfo';
import { MEDIUM, EXTRA_SMALL, EXTRA_BIG, SMALL, BIG } from './responsive.service';

function MosaicVideo({
  channel,
  doSelect,
  order,
  selected,
  mosaicId,
  mosaicName,
  videoLiveSource,
  limitTransmissionBlocked,
  setVideoLiveSource,
}: {
  channel: ChannelSimple;
  doSelect: Function;
  order: number;
  selected?: boolean;
  mosaicId: number;
  mosaicName: string;
  videoLiveSource?: VideoLiveSource;
  limitTransmissionBlocked: boolean;
  setVideoLiveSource?: (source: VideoLiveSource) => void;
}) {
  const theme = useTheme();
  const updateMosaicItem = useUpdateMosaicItem(mosaicId, order);
  const viewVideoDetails = useRecoilValue(viewVideoDetailsAtom);
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const paused = useRecoilValue(pausedAtom(channel.id));
  const [isHover, setIsHover] = React.useState(false);
  const [openFullscreen, setOpenFullscreen] = React.useState(false);
  const [openExportModal, setOpenExportModal] = React.useState(false);
  const showBottomPanel = isHover || selected;
  const [nowLiveDate, setNowLiveDate] = useState(new Date());
  const [isPressingAlt] = useKeyPress('Alt');
  const [tip, setTip] = useState('');
  const { handleWatchLive } = useChannelControlsBar({
    channelId: channel.id,
    mosaicId,
  });

  const { t } = useTranslation('mosaics');
  const widescreen = useRecoilValue(
    mosaicItemWidescreenSelector({
      mosaicId,
      channelId: channel.id,
    })
  );
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: channel.id }));

  useEffect(() => {
    setNowLiveDate(new Date());
  }, [openFullscreen]);

  const handleWheel = React.useCallback(() => {
    if (!selected && !openFullscreen) {
      setTip(t('mosaics:select_the_channel_to_zoom_in'));
    } else if (!isPressingAlt) {
      setTip(t('mosaics:press_the_alt_key_to_zoom'));
    }
  }, [isPressingAlt, selected, openFullscreen, t]);

  useDebounce(
    () => {
      if (tip) {
        setTip('');
      }
    },
    1000,
    [setTip, tip]
  );

  const setWidescreen = (value: boolean) => {
    updateMosaicItem({ widescreen: value });
  };

  if (limitTransmissionBlocked)
    throw new Error(t('api_errors_messages:restricted_consumption_channel'));

  if (!channel.status) throw new Error(t('api_errors_messages:channel_inative'));

  return (
    <>
      <Card
        ref={measureRef}
        sx={{
          height: '100%',
          width: '100%',
          textAlign: 'center',
          '& .live-caption': {
            display: MEDIUM.lower(measures) ? 'none' : 'block',
          },
        }}
        aria-label={`select slot ${order} with channel ${channel.name}`}
        onClick={() => doSelect()}
        onDoubleClick={() => setOpenFullscreen(!openFullscreen)}
        onMouseOver={() => setIsHover(true)}
        onWheel={handleWheel}
        onMouseLeave={() => setIsHover(false)}
      >
        <ChannelVideo
          {...{
            channel,
            widescreen,
            mosaicId,
            videoLiveSource,
            setVideoLiveSource,
          }}
          enableZoom={selected && isPressingAlt}
        >
          <ChannelVideo.BottomPanel
            margin={EXTRA_SMALL.upper(measures) ? 8 : 4}
            {...(showBottomPanel ? {} : { sx: { right: 'auto' } })}
          >
            {showBottomPanel ? (
              <>
                <Box
                  textAlign="left"
                  sx={{
                    pt: EXTRA_BIG.upper(measures) ? 2 : SMALL.upper(measures) ? 0.5 : 0,
                    pb: EXTRA_BIG.upper(measures) ? 3 : SMALL.upper(measures) ? 1 : 0,
                    px: BIG.upper(measures) ? 2 : SMALL.upper(measures) ? 0.8 : 0.5,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      display: EXTRA_SMALL.upper(measures) ? 'block' : 'none',
                      lineHeight: '1rem',
                    }}
                  >{`${channel.clientTradeName} (${channel.clientCompanyName})`}</Typography>
                  <Typography variant="subtitle2">{channel.name}</Typography>
                  {viewVideoDetails && (
                    <VideoTechInfo {...{ channel }} noGap={EXTRA_SMALL.lower(measures)} />
                  )}
                </Box>
                <Divider />
              </>
            ) : null}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...(showBottomPanel
                  ? {
                      gap: EXTRA_BIG.upper(measures)
                        ? 5
                        : SMALL.upper(measures)
                        ? 2
                        : EXTRA_SMALL.upper(measures)
                        ? 0.5
                        : 0,
                      py: EXTRA_BIG.upper(measures) ? 2 : SMALL.upper(measures) ? 0.5 : 0,
                    }
                  : {
                      py: SMALL.upper(measures) ? 0.5 : 0,
                      px: SMALL.upper(measures) ? 2 : EXTRA_SMALL.upper(measures) ? 0.5 : 0,
                      gap: SMALL.upper(measures) ? 2 : EXTRA_SMALL.upper(measures) ? 0.5 : 0,
                    }),

                boxSizing: 'border-box',

                '&:empty': {
                  display: 'none',
                },
              }}
            >
              {showBottomPanel ? <ChannelVideo.SeeMoreAction {...{ channel }} /> : null}
              {showBottomPanel || paused ? <ChannelVideo.PlayPauseAction {...{ channel }} /> : null}
              <MuteAction {...{ channel, showBottomPanel }} />
              {showBottomPanel || widescreen ? (
                <WidescreenAction {...{ widescreen, setWidescreen }} />
              ) : null}
              {showBottomPanel ? (
                <FullScreenButton
                  opened={openFullscreen}
                  onClick={() => {
                    setOpenFullscreen(!openFullscreen);
                  }}
                  size="small"
                />
              ) : null}
              {showBottomPanel && !live ? (
                <WatchLiveButton {...{ mosaicId, channelId: channel.id }} />
              ) : null}
              <ChannelVideo.ZoomOutAction {...{ channel, mosaicId }} />
              {showBottomPanel ? (
                <RemoveSlotInMosaicButton
                  {...{ mosaicId, order, channelName: channel.name, size: 'small' }}
                />
              ) : null}
            </Box>
          </ChannelVideo.BottomPanel>
        </ChannelVideo>
        {tip && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={theme.zIndex.appBar}
            id={`tip-${channel.id}`}
          >
            <Box bgcolor={muifade(theme.palette.background.default, 0.8)} padding="16px 48px">
              <Typography>{tip}</Typography>
            </Box>
          </Box>
        )}
      </Card>
      <Dialog
        open={openFullscreen}
        onClose={() => setOpenFullscreen(false)}
        fullScreen
        // fullWidth
        // maxWidth="xl"
      >
        <DialogContent
          sx={{
            maxHeight: '100vh',
          }}
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Box>
                <Box padding="5.5px 0">
                  <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link
                      color="textSecondary"
                      variant="h5"
                      to={routes.mosaic.view(mosaicId)}
                      component={RouterLink}
                    >
                      {mosaicName}
                    </Link>
                    <Typography variant="h5">{channel.name}</Typography>
                    {channel && (
                      <RouterLink
                        aria-label="OpenInNew"
                        to={routes.customer.channel.view(channel.clientId, channel.id)}
                      >
                        <OpenInNew
                          sx={(_theme) => ({
                            marginRight: _theme.spacing(1),
                            color: _theme.palette.text.primary,
                          })}
                        />
                      </RouterLink>
                    )}
                  </Breadcrumbs>
                </Box>
                <Typography variant="body2">{t('_common:ESC_to_exit')}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: '100%',
              }}
              onDoubleClick={() => setOpenFullscreen(!openFullscreen)}
            >
              <ChannelVideo
                {...{
                  channel,
                  widescreen,
                  mosaicId,
                  videoLiveSource,
                  setVideoLiveSource,
                }}
                enableZoom={isPressingAlt}
                fullscreen
              />
            </Box>
            <ChannelCronologicBar
              {...{
                mosaicId,
                nowLiveDate: new Date(),
                channelId: channel.id,
                order,
                fullscreen: true,
              }}
            />
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <ChannelControlsBar
                  channelId={channel.id}
                  {...{
                    mosaicId,
                    nowLiveDate,
                  }}
                  flex="0 0 auto"
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    aria-label="Download video"
                    onClick={() => {
                      setOpenExportModal(!openExportModal);
                    }}
                    size="large"
                    title={t('video:download') ?? ''}
                  >
                    <GetApp height="1em" width="1em" />
                  </IconButton>

                  <IconButton
                    aria-label="exit fullscreen"
                    onClick={() => setOpenFullscreen(false)}
                    size="large"
                    title={t('_common:exit_fullscreen_mode')}
                  >
                    <FullscreenExit />
                  </IconButton>

                  <Button onClick={handleWatchLive} size="small" sx={{ py: 0.1 }}>
                    {t('video:watch_live')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <ExportVideoModal
            channel={channel}
            nowLiveDate={nowLiveDate}
            open={openExportModal}
            setOpen={setOpenExportModal}
          />
          {tip && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={theme.zIndex.appBar}
              id={`tip-${channel.id}-fullscreeen`}
            >
              <Box bgcolor={muifade(theme.palette.background.default, 0.8)} padding="16px 48px">
                <Typography>{tip}</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export function RemoveSlotInMosaicButton({
  channelName,
  order,
  mosaicId,
  size,
}: {
  channelName: string;
  order: number;
  mosaicId: number;
  size: 'small' | 'large' | 'medium';
}) {
  const { removeChannel } = useMosaicItens(mosaicId);
  const { openChannelSelection } = useSelectChannelPanel();
  const matchSlotView = useRouteMatch(routes.mosaic.slotView(':mosaicId', ':slotId'));
  const { t } = useTranslation(['video']);

  const handleRemoveClick = useCallback(() => {
    removeChannel(order);
    openChannelSelection();
  }, [openChannelSelection, order, removeChannel]);

  if (matchSlotView) {
    return null;
  }
  return (
    <IconButton
      aria-label={`remove channel ${channelName} of slot ${order}`}
      onClick={() => handleRemoveClick()}
      size={size}
      title={t('video:remove_channel_from_mosaic')}
    >
      <DeleteRounded color="error" fontSize={size} />
    </IconButton>
  );
}

function MuteAction({
  channel,
  showBottomPanel,
}: {
  channel: ChannelSimple;
  showBottomPanel?: boolean;
}) {
  const [muted, setMuted] = useRecoilState(mutedAtom(channel.id));
  const { t } = useTranslation(['video']);

  if (!showBottomPanel && muted) {
    return null;
  }

  return (
    <IconButton
      aria-label={muted ? 'unmute' : 'mute'}
      onClick={() => setMuted(!muted)}
      size="small"
      title={muted ? t('video:unmute_video') : t('video:mute_video')}
    >
      {muted ? <VolumeOffRounded fontSize="small" /> : <VolumeUpRounded fontSize="small" />}
    </IconButton>
  );
}

function WidescreenAction({
  widescreen,
  setWidescreen,
}: {
  widescreen?: boolean;
  setWidescreen?: (value: boolean) => void;
}) {
  const { t } = useTranslation(['video']);
  return (
    <IconButton
      size="small"
      color={widescreen ? 'primary' : undefined}
      onClick={() => setWidescreen && setWidescreen(!widescreen)}
      title={widescreen ? t('video:deactivate_widescreen') : t('video:activate_widescreen')}
    >
      <PictureInPictureAltOutlined fontSize="small" />
    </IconButton>
  );
}

export default MosaicVideo;

interface WatchLiveProps {
  mosaicId: number;
  channelId: number;
}

const WatchLiveButton = ({ mosaicId, channelId }: WatchLiveProps) => {
  const handleWatchLive = useRecoilCallback(
    ({ set }) => () => {
      set(selectedDateAtom({ mosaicId, channelId }), null);
    },
    []
  );

  return (
    <IconButton aria-label="Watch live" size="small" onClick={handleWatchLive}>
      <Live />
    </IconButton>
  );
};

function ExportVideoModal({
  channel,
  mosaicId = null,
  nowLiveDate,
  open,
  setOpen,
}: {
  channel: ChannelSimple;
  mosaicId?: number | null;
  nowLiveDate: Date;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(['video_export', 'forms', '_common']);
  return (
    <Dialog
      {...{ open }}
      onClose={() => setOpen(false)}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{t('video_export')}</DialogTitle>

      <DialogContent>
        <ExportVideoForm {...{ open, setOpen, channel, mosaicId, nowLiveDate }} />
      </DialogContent>
    </Dialog>
  );
}
