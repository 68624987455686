import Axios from 'axios';
import {
  mosaicItensValuesAtom,
  mosaicItensInitialDataAtom,
  slotsHasChanges,
} from 'atoms/mosaicItems';
import { mosaicAtom, mosaicInitialDataAtom } from 'atoms/mosaics';
import deepEqual from 'deep-equal';
import useMessages from 'hooks/useMessages';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { mutate } from 'swr';
import { MosaicItem } from 'types/mosaics.types';

export function useMosaicItensApi(mosaicId: number) {
  const { t } = useTranslation(['mosaics', 'api_errors_messages']);
  const { enqueueError } = useMessages();
  const itensValues = useRecoilValue(mosaicItensValuesAtom(mosaicId));
  const itensApi = useRecoilValue(mosaicItensInitialDataAtom(mosaicId));
  const mosaic = useRecoilValue(mosaicAtom(mosaicId));
  const mosaicApi = useRecoilValue(mosaicInitialDataAtom(mosaicId));
  const hasChanges = useRecoilValue(slotsHasChanges(mosaicId));

  const saveItem = async (mosaicItemReceived: MosaicItem) => {
    try {
      await Axios.post(`/v1/mosaics/${mosaicId}/items`, mosaicItemReceived);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_creating_item')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const deleteItem = async (id: number) => {
    try {
      await Axios.delete(`/v1/mosaics/${mosaicId}/items/${id}`);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_deleting_item')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const updateItem = async (id: number, mosaicItemReceived: MosaicItem) => {
    try {
      await Axios.put(`/v1/mosaics/${mosaicId}/items/${id}`, mosaicItemReceived);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_creating_item')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const saveItemsOfMosaic = async () => {
    try {
      const templateChanged = mosaicApi?.qtyChannels !== mosaic?.qtyChannels;
      if (!hasChanges && !templateChanged) return;
      if (!mosaic) return;

      const slots = itensApi.length >= itensValues.length ? itensApi : itensValues;

      await Promise.all(
        slots.map(({ id, order }) => {
          const itemsFiltered = itensValues.filter((item) => item.order <= mosaic.qtyChannels);
          const newItem = itemsFiltered.find((_item) => {
            if (_item.id) return _item.id === id;

            return _item.order === order;
          });

          const apiItem = itensApi.find((_item) => _item.id === id);

          if (!apiItem && newItem) return saveItem(newItem);
          if (apiItem && !newItem) return deleteItem(apiItem.id as number);
          if (!deepEqual(apiItem, newItem))
            return updateItem((apiItem as MosaicItem).id as number, newItem as MosaicItem);
          return null;
        })
      );
      mutate(`/v1/mosaics/${mosaicId}/items`);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_saving')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  return {
    saveItemsOfMosaic,
  };
}
