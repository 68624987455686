import Axios from 'axios';
import routes from 'helpers/routes';
import useMessages from 'hooks/useMessages';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import { ChannelInMosaic, Mosaic } from 'types/mosaics.types';
import { useCreateMosaicModal } from './useCreateMosaicModal';

export function useMosaicApi() {
  const { t } = useTranslation(['mosaics', 'api_errors_messages']);
  const history = useHistory();
  const { enqueueSuccess, enqueueError } = useMessages();
  const { closeCreateModal } = useCreateMosaicModal();

  const createMosaic = async (mosaicReceived: Mosaic) => {
    try {
      const { data } = await Axios.post('/v1/mosaics', mosaicReceived);

      enqueueSuccess(t('mosaics:mosaic_created'));
      closeCreateModal();
      history.push(routes.mosaic.view(data.id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const createMosaicFromCustomer = async (mosaicReceived: Mosaic, customerId: number) => {
    try {
      const { data } = await Axios.post<{ id: number }>(
        `/v1/mosaics/clients/${customerId}`,
        mosaicReceived
      );
      enqueueSuccess(t('mosaics:mosaic_created'));
      closeCreateModal();
      history.push(routes.mosaic.view(data.id));
    } catch (error) {
      console.error(error);
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const deleteMosaic = async (id: number) => {
    try {
      await Axios.delete(`/v1/mosaics/${id}`);
      enqueueSuccess(t('mosaics:mosaic_deleted'));
      history.push(routes.mosaic.root);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_deleting')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  const editMosaic = async (mosaicReceived: Mosaic & { channelsInMosaic: ChannelInMosaic[] }) => {
    try {
      await Axios.put(`/v1/mosaics/${mosaicReceived.id}`, mosaicReceived);
      mutate(`/v1/mosaics/${mosaicReceived.id}`, mosaicReceived);
      enqueueSuccess(t('mosaics:mosaic_edited'));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('mosaics:error_saving')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
    }
  };

  return {
    createMosaic,
    createMosaicFromCustomer,
    editMosaic,
    deleteMosaic,
  };
}
