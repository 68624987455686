import { Box, Breadcrumbs, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OpenInNew } from '@mui/icons-material';
import { mosaicAtom } from 'atoms/mosaics';
import routes from 'helpers/routes';
import { asyncComponent } from 'HOCs';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMosaicSlotViewPage } from 'hooks/useMosaicSlotView';

const useStyles = makeStyles((theme) => ({
  openChannelIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

function MosaicSlotViewTopBar() {
  const { mosaicId, mosaicItem } = useMosaicSlotViewPage();
  const mosaic = useRecoilValue(mosaicAtom(mosaicId));
  const classes = useStyles();

  if (!mosaic) return null;

  return (
    <Box padding="5.5px 0">
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link
          color="textSecondary"
          variant="h5"
          to={routes.mosaic.view(mosaicId)}
          component={RouterLink}
        >
          {mosaic.name}
        </Link>
        <Typography variant="h5">{mosaicItem?.channel.name}</Typography>
        {mosaicItem && (
          <RouterLink
            aria-label="OpenInNew"
            to={routes.customer.channel.view(mosaicItem.channel.clientId, mosaicItem.channel.id)}
          >
            <OpenInNew className={classes.openChannelIcon} />
          </RouterLink>
        )}
      </Breadcrumbs>
    </Box>
  );
}

export default asyncComponent(MosaicSlotViewTopBar);
