import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PasswordFieldPROPS = {
  helperText: string;
  value: string | undefined;
  onBlur: (e: React.FocusEvent) => void;
  onChange: (e: React.ChangeEvent) => void;
  error: boolean | undefined;
  name: string;
  label: string;
  id: string;
};
function PasswordField(props: PasswordFieldPROPS) {
  const { error, onBlur, onChange, helperText, value, name, id, label } = props;
  const { t } = useTranslation('channels');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [capsLock, setCapsLock] = useState(false);

  return (
    <TextField
      sx={{
        marginLeft: '16px',
      }}
      fullWidth
      id={id}
      autoComplete="off"
      name={name}
      label={label}
      helperText={(capsLock && t('channels:capslock_warning')) || helperText}
      type={showPassword ? 'text' : 'password'}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={(e) => {
        setCapsLock(e.getModifierState('CapsLock'));
      }}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordField;
