import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { Form } from 'components/core';
import PasswordField from 'components/PasswordField/PasswordField';
import { HikvisionSettingsProps } from './useHikvisionSettings';

interface FormProps {
  initialValues: HikvisionSettingsProps;
  onSave: (config: HikvisionSettingsProps) => Promise<void>;
}
export function HikvisionSettingsForm({ initialValues, onSave }: FormProps) {
  const { t } = useTranslation();
  const yup = useYupObject();
  const validationSchema = yup.object({
    appKey: yup
      .string()
      .max(200)
      .trim()
      .noSpecialCharacters()
      .test('white-space', t('_common:input_white_spaces_validation'), (appKey) => {
        if (!appKey) return true;

        return !(appKey.split(' ').length > 1);
      }),
    secretKey: yup
      .string()
      .max(200)
      .trim()
      .noSpecialCharacters()
      .test('white-space', t('_common:input_white_spaces_validation'), (secretKey) => {
        if (!secretKey) return true;

        return !(secretKey.split(' ').length > 1);
      }),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (configValues, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await onSave(configValues);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box
      sx={{
        height: '100vh',
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          overflow: 'hidden',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flex: '1 1 auto',
            overflow: 'auto',
            px: 4,
            py: 1.5,
          }}
        >
          <Typography variant="h5">{t('hikvision_settings:config')}</Typography>
          <Typography variant="body1" fontWeight="light" mb={4}>
            {t('hikvision_settings:description')}
          </Typography>

          <Box
            sx={{
              marginBottom: '1.2rem',
            }}
          >
            <PasswordField
              helperText={(formik.touched.appKey && formik.errors.appKey) || ''}
              value={formik.values.appKey.trim()}
              name="appKey"
              id="appKey"
              label={t('hikvision_settings:app_key')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.appKey && Boolean(formik.errors.appKey)}
            />
          </Box>
          <Box
            sx={{
              marginBottom: '1.2rem',
            }}
          >
            <PasswordField
              helperText={(formik.touched.secretKey && formik.errors.secretKey) || ''}
              value={formik.values.secretKey.trim()}
              name="secretKey"
              id="secretKey"
              label={t('hikvision_settings:secret_key')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.secretKey && Boolean(formik.errors.secretKey)}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            flex: '0 0 auto',
            padding: '20px',
            textAlign: 'end',
          }}
        >
          <Form.CancelButton
            isSubmitting={!formik.dirty || formik.isSubmitting}
            onClick={() => {
              formik.resetForm();
            }}
          />
          <Permissioned role={PermissionRole.EDIT_HIKVISION_SETTINGS}>
            <Form.SaveButton
              isSubmitting={formik.isSubmitting}
              initialValues={formik.initialValues}
              values={formik.values}
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            />
          </Permissioned>
        </Box>
      </form>
    </Box>
  );
}
