import { PermissionRole } from 'types/user.types';
import jwt from 'jsonwebtoken';

// https://sim-auth.segware.com/auth/realms/simcloud/protocol/openid-connect/auth?client_id=react&response_type=token
export const URL_LOGIN = process.env.REACT_APP_LOGIN_URL
  ? `${process.env.REACT_APP_LOGIN_URL}&redirect_uri=${window.location.href}`
  : `http://localhost:8081/auth/realms/simcloud/protocol/openid-connect/auth?client_id=react&response_type=token&redirect_uri=${window.location.href}`;

export const SESSION_STORAGE = {
  TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
};

export const getTokenFromUrl = () => {
  const url = new URL(window.location.href.replace('#', '?'));
  return url.searchParams.get('access_token');
};

export const getTokenFromUrlIfValid = () => {
  const token = getTokenFromUrl();
  return isValidToken(token) ? token : null;
};

export const getTokenFromStorageIfValid = () => {
  const token = sessionStorage.getItem(SESSION_STORAGE.TOKEN);
  return isValidToken(token) ? token : null;
};

export const isValidToken = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  const decoded = getUserDataFromToken(token);

  return !!decoded?.email_verified;
};

export interface UserAuth {
  resource_access: {
    account: {
      roles: string[];
    };
  };
  realm_access: {
    roles: PermissionRole[];
  };
  scope: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

export const getUserDataFromToken = (token: string | null): null | UserAuth => {
  if (!token) return null;
  return jwt.decode(token, { json: true }) as UserAuth;
};
