import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'pre', // to show breaklines in messages
  },
  backgroundWarning: {
    backgroundColor: '#FFD733 !important',
    color: '#585858 !important',
  },
}));

function SnackbarConfigProvider({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={2}
      classes={{
        root: classes.root,
        variantWarning: classes.backgroundWarning,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default SnackbarConfigProvider;
