import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.375rem',
    marginRight: theme.spacing(1),
  },
}));

function RecordingsTopBar() {
  const { t } = useTranslation('recordings');
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h4">
        {t('recordings')?.toUpperCase()}
      </Typography>
    </Box>
  );
}

export default RecordingsTopBar;
