import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TagLabel } from '../../types/speedTest.types';

const LabelSpeedTest = ({ tag }: { tag: TagLabel }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h6" sx={{ mt: 2, textAlign: 'center', mb: 2 }}>
      {t(`speed_test:label_status_${tag}`)}
    </Typography>
  );
};

export default function ProgressSpeedTestView({
  isRunning,
  tagLabel,
}: {
  isRunning: boolean;
  tagLabel: TagLabel;
}) {
  return (
    <Box width="100%">
      <LabelSpeedTest tag={tagLabel} />
      <Box sx={{ width: '300px', margin: '0 auto', py: 2 }}>
        <LinearProgress
          variant={isRunning ? 'indeterminate' : 'determinate'}
          color={isRunning ? 'primary' : 'secondary'}
          sx={{
            width: '100%',
            height: 10,
            bgcolor: '#ddd',
          }}
        />
      </Box>
    </Box>
  );
}
