import { ChannelConnectionResponse } from "types/channels.types";

export function transformBatchChannelsInObject(response: ChannelConnectionResponse[]) {
  const newObj = {};
  for (const item of response) {
    // @ts-ignore: Unreachable code error
    newObj[item.url] = item.success;
    // @ts-ignore: Unreachable code error
    newObj.message = item.message;
  }
  return newObj;
}