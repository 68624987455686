import React from 'react';
import { OptimizelyProvider, createInstance, setLogLevel } from '@optimizely/react-sdk';
import { useRecoilValue } from 'recoil';
import { loggedUserAtom } from 'atoms/users';

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
});

function OptimizelyProviderWithUser({ children }: { children: React.ReactNode }) {
  // https://docs.developers.optimizely.com/feature-experimentation/docs/customize-logger-react
  // @ts-expect-error
  setLogLevel(null);

  const userInfo = useRecoilValue(loggedUserAtom);

  // https://docs.developers.optimizely.com/feature-experimentation/docs/customize-logger-react#
  // @ts-expect-error
  setLogLevel(null);

  if (!userInfo) {
    return <>{children}</>;
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: userInfo?.id ? String(userInfo.id) : null,
        attributes: {
          email: userInfo?.email || null,
          masterCompanyId: userInfo?.masterCompany.id,
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  );
}

export default OptimizelyProviderWithUser;
