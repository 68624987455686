import 'dayjs/locale/pt-br';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import React from 'react';
import languageDetector from 'locales/languageDetector';

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(relativeTime);

dayjs.updateLocale('pt-br', {
  calendar: {
    lastDay: '[Ontem as] LT',
    sameDay: '[Hoje as] LT',
    nextDay: '[Amanhã as] LT',
    lastWeek: '[Última] dddd [as] LT',
    nextWeek: 'dddd [as] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'em %s',
    past: 'há %s',
    s: '%d segundos', // 'poucos segundos',fast foward action
    m: '1 minuto',
    mm: '%d minutos',
    h: '1 hora',
    hh: '%d horas',
    d: '1 dia',
    dd: '%d dias',
    M: '1 mês',
    MM: '%d meses',
    y: '1 ano',
    yy: '%d anos',
  },
});

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'em %s',
    past: 'há %s',
    s: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

dayjs.updateLocale('es', {
  calendar: {
    lastDay: '[Ayer a las] LT',
    sameDay: '[Hoy a las] LT',
    nextDay: '[Mañana a las] LT',
    lastWeek: '[El] dddd [pasado a las] LT',
    nextWeek: 'dddd [a las] LT',
    sameElse: 'L',
  },
});

function useDayJsConfig() {
  React.useEffect(function setLocale() {
    const languages = languageDetector.detect() || 'pt-BR';
    const language = Array.isArray(languages) ? languages[0] : languages;
    dayjs.locale(language);
  }, []);
}

export default useDayJsConfig;
