import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { Mosaic, Template } from 'types/mosaics.types';
import templates from 'helpers/mosaicTemplates';
import { MosaicPaginationProps } from './MosaicPagination';

interface UsePaginationProps {
  mosaic: Mosaic;
  setMosaic: SetterOrUpdater<Mosaic | null>;
}

export function useMosaicPagination({
  mosaic,
  setMosaic,
}: UsePaginationProps): MosaicPaginationProps {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { pages, payload } = mosaic.mosaicData;

  const template = payload
    ? payload.length
    : pages?.find((page) => page.offset === currentPage)?.payload.length;

  const handleChangePage = (newCurrentPage: number) => {
    setCurrentPage(newCurrentPage);
  };

  const handleChangeTemplate = (newTemplate: Template) => {
    const totalPages = mosaic.qtyChannels / newTemplate;
    setMosaic({
      ...mosaic,
      mosaicData: templates(totalPages)[newTemplate],
    });
    setCurrentPage(1);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [mosaic.qtyChannels]);

  return {
    currentPage,
    totalPage: pages ? pages.length : 1,
    onChangePage: handleChangePage,
    template: template as Template,
    onChangeTemplate: handleChangeTemplate,
    size: 'medium',
    qtyChannelMax: mosaic.qtyChannels,
  };
}
