import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RecordingType } from 'types/channels.types';
import { BarChart, YAxis, XAxis, ResponsiveContainer, Legend, Bar } from 'recharts';
import { Box, Divider, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { timeFormat } from 'd3';
import { formatBytes } from 'helpers/bytesOperations';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import colorByRecordingType from 'helpers/colorByRecordingType';
import { useSetRecoilState } from 'recoil';
import { Consuption } from './metrics';
import { storageSize } from './storage';

interface ChartData {
  date: Date;
  recording_type_LIVE: number;
  recording_type_EVENT: number;
  recording_type_SCHEDULE: number;
  recording_type_RECORD_24_7: number;
}

interface ListData {
  recordingType: RecordingType;
  totalChannels: number;
  totalSize: number;
}

interface DetailStorageInterface {
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: '48px',
  },
  listContentsSpacing: {
    margin: theme.spacing(0, 1),
  },
  listContentRecordingType: {
    margin: theme.spacing(0, 1),
    width: '350px',
  },
  listContentTotalChannels: {
    margin: theme.spacing(0, 1),
    width: '180px',
    textAlign: 'center',
    flex: 'inherit',
  },
  listContentTotalSize: {
    margin: theme.spacing(0, 1),
    width: '200px',
    flex: 'inherit',
  },
}));

function DetailStorageCostsChart({ startDate, endDate }: DetailStorageInterface) {
  const theme = useTheme();
  const classes = useStyles();
  const setStorageSize = useSetRecoilState(storageSize);
  const { t } = useTranslation(['channels', '_common', 'metrics']);
  const [opacity, setOpacity] = useState({
    recording_type_LIVE: 1,
    recording_type_EVENT: 1,
    recording_type_SCHEDULE: 1,
    recording_type_RECORD_24_7: 1,
    recording_type_PRE_ALARM: 1,
  });

  const { data } = useFetch<Consuption>('/v1/consumption/storage', {
    baseURL: process.env.REACT_APP_BASE_URL_CONSUMPTION,
    params: { startDate, endDate },
    normalizeData: (consuption) => ({
      ...consuption,
      channels: consuption.channels.map((item) => ({
        ...item,
        date: new Date(`${item.date}T00:00:00.000`),
      })),
    }),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const chartData = useMemo<ChartData[]>(() => {
    if (!data || data.channels.length === 0) {
      return [];
    }
    // https://stackoverflow.com/a/9229821/9516375
    const uniqDates = [...new Set(data.channels.map((item) => item.date.toDateString()))];
    return uniqDates
      .sort((a, b) => Number(new Date(a)) - Number(new Date(b)))
      .map((date) => ({
        date: new Date(date),
        recording_type_LIVE: data.channels
          .filter(
            (item) => item.date.toDateString() === date && item.recordingType === RecordingType.LIVE
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_EVENT: data.channels
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.EVENT
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_SCHEDULE: data.channels
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.SCHEDULE
          )
          .reduce((sum, item) => sum + item.size, 0),
        recording_type_RECORD_24_7: data.channels
          .filter(
            (item) =>
              item.date.toDateString() === date && item.recordingType === RecordingType.RECORD_24_7
          )
          .reduce((sum, item) => sum + item.size, 0),
        // recording_type_preAlarm: data.channels
        //   .filter(
        //     (item) =>
        //       item.date.toDateString() === date && item.recordingType === RecordingType.PRE_ALARM
        //   )
        //   .reduce((sum, item) => sum + item.size, 0),
      }));
  }, [data]);

  const listDate = useMemo<ListData[]>(() => {
    if (!data || data.channels.length === 0) {
      return [];
    }

    const lastDay = [...data.channels].sort(
      (a, b) => Number(new Date(a.date)) - Number(new Date(b.date))
    )[data.channels.length - 1].date;

    const uniqChannelsByRecordingType = [
      ...new Set(
        data.channels
          .sort((a, b) => {
            if (a.channelId > b.channelId) {
              return 1;
            }
            if (a.channelId < b.channelId) {
              return -1;
            }
            // a must be equal to b
            if (a.recordingType > b.recordingType) {
              return 1;
            }
            if (a.recordingType < b.recordingType) {
              return -1;
            }
            return 0;
          })
          .map((item) => [item.channelId, item.recordingType].join(','))
      ),
    ].map((x) => x.split(','));

    const uniqRecordingTypes = [...new Set(data.channels.map((item) => item.recordingType))];
    return uniqRecordingTypes.map((recordingType) => ({
      recordingType,
      totalChannels: uniqChannelsByRecordingType.filter((item) => item[1] === recordingType).length,
      totalSize: data.channels
        .filter((item) => item.recordingType === recordingType)
        .filter((channel) => channel.date.toISOString() === lastDay.toISOString())
        .reduce((sum, channel) => sum + channel.size, 0),
    }));
  }, [data]);

  const handleMouseEnter = useCallback(
    ({ dataKey }) => {
      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity]
  );

  const handleMouseLeave = useCallback(
    ({ dataKey }) => {
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity]
  );

  const renderLegendText: Formatter = useCallback(
    (value: string) => (
      <Typography display="inline" color="textSecondary">
        {t(value)}
      </Typography>
    ),
    [t]
  );

  useEffect(() => {
    data &&
      setStorageSize({
        consumedSize: data.sizeConsumed,
        storageSize: data.storageSize,
      });
  }, [data, listDate, setStorageSize]);

  if (chartData.length === 0) {
    return (
      <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h5">{t('_common:there_is_no_storage')}</Typography>
      </Box>
    );
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart width={600} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Bar
            barSize={17}
            type="monotone"
            dataKey="recording_type_LIVE"
            strokeOpacity={opacity.recording_type_LIVE}
            fill={colorByRecordingType(RecordingType.LIVE, theme)}
            stroke={colorByRecordingType(RecordingType.LIVE, theme)}
            animationDuration={500}
          />
          <Bar
            barSize={17}
            type="monotone"
            dataKey="recording_type_EVENT"
            strokeOpacity={opacity.recording_type_EVENT}
            fill={colorByRecordingType(RecordingType.EVENT, theme)}
            stroke={colorByRecordingType(RecordingType.EVENT, theme)}
            animationDuration={500}
          />
          <Bar
            barSize={17}
            type="monotone"
            dataKey="recording_type_SCHEDULE"
            strokeOpacity={opacity.recording_type_SCHEDULE}
            fill={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            stroke={colorByRecordingType(RecordingType.SCHEDULE, theme)}
            animationDuration={500}
          />
          <Bar
            barSize={17}
            type="monotone"
            dataKey="recording_type_RECORD_24_7"
            strokeOpacity={opacity.recording_type_RECORD_24_7}
            fill={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            stroke={colorByRecordingType(RecordingType.RECORD_24_7, theme)}
            animationDuration={500}
          />
          {/* <Bar
            barSize={17}
            type="monotone"
            dataKey="recording_type_PRE_ALARM"
            strokeOpacity={opacity.recording_type_PRE_ALARM}
            fill={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            stroke={colorByRecordingType(RecordingType.PRE_ALARM, theme)}
            animationDuration={500}
          /> */}

          <XAxis
            dataKey="date"
            tickFormatter={timeFormat('%d %b')}
            // tickFormatter={new Intl.DateTimeFormat(languageDetector.detect()).format}
            padding={{ left: 20, right: 20 }}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tickFormatter={(value: number) => formatBytes(value, 0)}
            padding={{ top: 10, bottom: 10 }}
            axisLine={false}
            tickLine={false}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            verticalAlign="top"
            height={30}
            formatter={renderLegendText}
            wrapperStyle={{
              top: -14,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <List sx={{ mb: 2 }}>
        {listDate.map((item) => (
          <ListItem key={item.recordingType} className={classes.listItem}>
            <Box
              bgcolor={colorByRecordingType(item.recordingType, theme)}
              width="5px"
              height="100%"
              marginRight="8px"
            />
            <Typography className={classes.listContentRecordingType} variant="button">
              {t(`recording_type_${item.recordingType}`)}
            </Typography>
            <Divider className={classes.listContentsSpacing} orientation="vertical" />
            <ListItemText className={classes.listContentTotalChannels}>
              {`${item.totalChannels} ${t('_common:channel', {
                count: Math.abs(item.totalChannels),
              }).toLowerCase()}`}
            </ListItemText>
            <Divider className={classes.listContentsSpacing} orientation="vertical" />
            <ListItemText className={classes.listContentTotalSize}>
              {formatBytes(item.totalSize)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default DetailStorageCostsChart;
