import { useRecoilState, useSetRecoilState } from 'recoil';
import { ChannelSimple } from 'types/mosaics.types';
import { mosaicItensValuesAtom, mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';

export function useMosaicItens(mosaicId: number) {
  const [mosaicItens, setMosaicItems] = useRecoilState(mosaicItensValuesAtom(mosaicId));
  const setMosaicSlotOrderSelected = useSetRecoilState(mosaicSlotOrderSelectedAtom);

  const putChannelInSlot = (order: number, channelReceived: ChannelSimple) => {
    const mosaicItem = mosaicItens.find((slot) => slot.order === order);

    const channel: ChannelSimple = {
      id: channelReceived.id,
      name: channelReceived.name,
      clientCompanyName: channelReceived.clientCompanyName,
      clientTradeName: channelReceived.clientTradeName,
      recordingType: channelReceived.recordingType,
      clientId: channelReceived.clientId,
      clientStatus: channelReceived.clientStatus,
      channelPrivate: channelReceived.channelPrivate,
      url: channelReceived.url,
      connectionTypeEnum: channelReceived.connectionTypeEnum,
      status: channelReceived.status,
    };

    if (!mosaicItem) {
      setMosaicItems((slots) => [
        ...slots,
        {
          order,
          channel,
          visible: true,
          limitTransmissionBlocked: false,
        },
      ]);
      return;
    }

    setMosaicItems((slots) => [
      ...slots.filter((slot) => slot.order !== order),
      {
        ...mosaicItem,
        channel,
      },
    ]);
  };

  const removeChannel = (order: number) => {
    setMosaicItems((items) => items.filter((item) => item.order !== order));
  };

  return {
    putChannelInSlot,
    removeChannel,
    selectMosaicSlot: (order: number) => setMosaicSlotOrderSelected(order),
    mosaicItens,
  };
}
