import React from 'react';
import { Box, IconButton, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DateRange } from '@mui/icons-material';
import DateRangeForm from './DateRangeForm';
import { DateRangeValue } from './DateRange.types';

const useDateRangeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.text.disabled}`,
      paddingLeft: '8px',
      height: '56px',
      boxSizing: 'border-box',
    },
    datesText: {
      flex: '1 1 auto',
    },
    popover: {
      padding: theme.spacing(1),
    },
  })
);

function DateRangeField({
  value,
  setValue,
  label,
}: {
  value: DateRangeValue | null;
  setValue: (d: DateRangeValue) => void;
  label: string;
}) {
  const classes = useDateRangeStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box className={classes.root}>
      {!value ? (
        <Typography className={classes.datesText} variant="body2">
          {label}
        </Typography>
      ) : (
        <Typography className={classes.datesText}>{`${value[0].toLocaleDateString(
          navigator.language
        )} - ${value[1].toLocaleDateString(navigator.language)}`}</Typography>
      )}
      <IconButton onClick={handleClick} size="small" aria-label="change dates filter">
        <DateRange />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.popover,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <DateRangeForm {...{ value, setValue, handleClose }} />
      </Popover>
    </Box>
  );
}

export default DateRangeField;
