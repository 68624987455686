import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Calculator(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M18 2H6C4.895 2 4 2.895 4 4V20C4 21.105 4.895 22 6 22H18C19.105 22 20 21.105 20 20V4C20 2.895 19.105 2 18 2ZM18 8H6V4H18V8ZM9 12H7V10H9V12ZM9 16H7V14H9V16ZM9 20H7V18H9V20ZM17 20H11V18H17V20ZM13 16H11V14H13V16ZM17 16H15V14H17V16ZM17 12H15V10H17V12ZM13 12H11V10H13V12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Calculator;
