import React from 'react';
import { Box, styled } from '@mui/material';
import ChannelsMonitoringTable from './ChannelsMonitoringTable';
import ChannelsMonitoringFilters from './ChannelsMonitoringFilters';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

function ChannelsMonitoringPage() {
  return (
    <Container>
      <ChannelsMonitoringFilters />
      <ChannelsMonitoringTable />
    </Container>
  );
}

export default ChannelsMonitoringPage;
