import { atom, atomFamily } from 'recoil';
import {
  TestedUrlsKey,
  BatchTestResponse,
  Channel,
} from 'types/channels.types';

export const batchRTSPChannelsAtom = atom<Channel[]>({
  key: 'batchChannelFormAtom',
  default: [],
});

export const testedUrlsResultsAtom = atomFamily<BatchTestResponse, TestedUrlsKey>({
  key: 'testedUrlsResultsAtom',
  default: {},
});
