import { atom } from 'recoil';
import { IntelbrasChannel, IntelbrasTestConnectionResponse } from 'types/intelbras.types';


export const batchIntelbrasFormAtom = atom<IntelbrasChannel[]>({
  key: 'batchIntelbrasFormAtom',
  default: [],
});

export const intelbrasConnectionTestsResponse = atom<IntelbrasTestConnectionResponse[]>({
  key: 'intelbrasConnectionTestsResponse',
  default: [],
});
