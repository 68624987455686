import React from 'react';
import Axios from 'axios';

function useCallStartLiveBefore(mosaicId?: number, skip: boolean = false) {
  const callStartLive = React.useCallback(async () => {
    !skip && mosaicId && Axios.post(`/v1/mosaics/${mosaicId}/startLive`);
  }, [mosaicId, skip]);

  React.useEffect(
    function call() {
      callStartLive();
    },
    [callStartLive]
  );
}

export default useCallStartLiveBefore;
