import React from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorSVG } from 'components/core/Icons';

export interface Error {
  response?: { data?: { message?: string } };
  message?: string;
}

export interface ErrorBoundaryFallbackProps {
  isApiError: boolean;
  error?: Error;
  handlerTryAgainClick: () => void;
  resetState?: () => void;
}

export type Props = {
  fallback?: React.ReactNode | ((props: ErrorBoundaryFallbackProps) => React.ReactNode);
  tryAgainClick?: VoidFunction | (() => Promise<void>);
  reloadChildrenOnTryAgain?: boolean;
  onError?: (error: Error) => void;
};

interface State {
  hasError: boolean;
  error?: Error;
  isApiError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined, isApiError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      isApiError: !!error?.response?.data,
      error,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.props.onError && this.props.onError(error);
  }

  render() {
    const { hasError, error, isApiError } = this.state;
    const { fallback, children, tryAgainClick, reloadChildrenOnTryAgain } = this.props;

    const resetState = () => {
      this.setState({
        hasError: false,
        error: undefined,
        isApiError: false,
      });
    };

    const handlerTryAgainClick = () => {
      (async function executeTryAgain() {
        tryAgainClick ? await tryAgainClick() : window.location.reload();
        if (reloadChildrenOnTryAgain) {
          resetState();
        }
      })();
    };

    if (hasError) {
      if (!fallback) {
        return (
          <ErrorBoundaryDefault {...{ isApiError, error, handlerTryAgainClick, resetState }} />
        );
      }
      if (typeof fallback === 'function') {
        return fallback({ isApiError, error, handlerTryAgainClick, resetState });
      }
      return <>{fallback}</>;
    }
    return children;
  }
}

export function ErrorBoundaryDefault({
  isApiError,
  error,
  handlerTryAgainClick,
  resetState,
}: ErrorBoundaryFallbackProps) {
  const { t } = useTranslation(['error_boundary', 'api_errors_messages']);

  return (
    <Container
      sx={{
        alignItems: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: (theme) => theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1 1 auto',
        }}
      >
        <ErrorSVG
          sx={{
            marginBottom: '1.25rem',
            width: '162.802px',
            height: '139.544px',
            color: 'text.primary',
          }}
        />
        <Typography
          variant="h4"
          sx={{
            mb: 1,
          }}
        >
          Oops!
        </Typography>
        <Typography variant="body1">{t('error_boundary:something_went_wrong')}</Typography>
        <Typography variant="body1">{t('error_boundary:problem_executing_request')}</Typography>
        {isApiError && (
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              mt: 1,
            }}
          >
            {t(`api_errors_messages:${error?.response?.data?.message}`)}
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={handlerTryAgainClick}
          sx={{
            mt: 4.5,
            py: 0.5,
            px: 5,
          }}
        >
          {t('error_boundary:try_again')}
        </Button>
      </Box>
    </Container>
  );
}

export default ErrorBoundary;
