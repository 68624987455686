import { Box, Button, Typography } from '@mui/material';
import { sigmaIntegrationActivateAtom } from 'atoms/sigmaIntegration';
import { User } from 'types/user.types';
import routes from 'helpers/routes';
import { useMessages } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Socket } from 'socket.io-client';
import dayjs from 'dayjs';

interface EventOpenRecordedMosaicProps {
  userId: number;
  mosaicId: number;
  startDate: string;
  endDate: string;
}

function useSIGMAIntegrator({
  socket,
  loggedUser,
}: {
  socket: Socket | null;
  loggedUser?: User | null;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueInfo, closeSnackbar, enqueueSuccess } = useMessages();

  const sigmaIntegrationActivate = useRecoilValue(sigmaIntegrationActivateAtom);

  React.useEffect(
    function putMosaicEvent() {
      if (socket && loggedUser) {
        const EVENT_OPEN_MOSAIC = `open_mosaic_${loggedUser.id}`;
        const EVENT_OPEN_RECORDED_MOSAIC = `open_recorded_mosaic_${loggedUser.id}`;
        socket.off(EVENT_OPEN_MOSAIC);
        socket.on(
          EVENT_OPEN_MOSAIC,
          ({
            mosaicId,
            customer,
            account,
          }: {
            mosaicId: number | null;
            customer: string;
            account: string;
          }) => {
            // eslint-disable-next-line no-console
            console.log('Socket: event open mosaic', { mosaicId, customer, account });
            if (sigmaIntegrationActivate) {
              mosaicId
                ? history.push(routes.mosaic.view(mosaicId))
                : history.push(routes.welcome.root);

              enqueueSuccess(t('mosaics:mosaic_changed_by_sigma_cloud'));
            } else {
              const snackkey = enqueueInfo(
                t('mosaics:new_event_received_view_cameras', {
                  customer,
                  account,
                }),
                {
                  persist: false,
                  autoHideDuration: 10000,
                  onClick: () => {
                    closeSnackbar(snackkey);
                  },
                  action: (key) => (
                    <>
                      <Box marginRight="8px">
                        <Button
                          onClick={() => {
                            closeSnackbar(key);
                            mosaicId
                              ? history.push(routes.mosaic.view(mosaicId))
                              : history.push(routes.welcome.root);
                          }}
                          variant="contained"
                        >
                          <Typography color="primary">{t('mosaics:view_cameras')}</Typography>
                        </Button>
                      </Box>
                      <Button
                        onClick={() => {
                          closeSnackbar(key);
                        }}
                      >
                        {t('_common:dismiss')}
                      </Button>
                    </>
                  ),
                }
              );
            }
          }
        );
        socket.off(EVENT_OPEN_RECORDED_MOSAIC);
        socket.on(
          EVENT_OPEN_RECORDED_MOSAIC,
          ({ mosaicId, userId, startDate }: EventOpenRecordedMosaicProps) => {
            if (dayjs().isBefore(new Date(startDate))) {
              const snackkey = enqueueInfo(
                t('mosaics:new_event_received_view_cameras_but_date_is_future'),
                {
                  persist: sigmaIntegrationActivate,
                  autoHideDuration: 10000,
                  onClick: () => {
                    closeSnackbar(snackkey);
                  },
                  action: (key) => (
                    <Button
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      {t('_common:dismiss')}
                    </Button>
                  ),
                }
              );
              return;
            }

            if (sigmaIntegrationActivate) {
              mosaicId && userId && startDate
                ? history.push(routes.mosaic.recorded(mosaicId, new Date(startDate).toISOString()))
                : history.push(routes.welcome.root);
            } else {
              const snackkey = enqueueInfo(t('mosaics:new_event_received_view_cameras'), {
                persist: false,
                autoHideDuration: 5000,
                onClick: () => {
                  closeSnackbar(snackkey);
                },
                action: (key) => (
                  <>
                    <Box marginRight="8px">
                      <Button
                        onClick={() => {
                          mosaicId && startDate
                            ? history.push(
                                routes.mosaic.recorded(mosaicId, new Date(startDate).toISOString())
                              )
                            : history.push(routes.welcome.root);
                          closeSnackbar(key);
                        }}
                        variant="contained"
                      >
                        <Typography color="primary">{t('mosaics:view_cameras')}</Typography>
                      </Button>
                    </Box>
                    <Button
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      {t('_common:dismiss')}
                    </Button>
                  </>
                ),
              });
            }
          }
        );
      }
    },
    [
      closeSnackbar,
      enqueueInfo,
      enqueueSuccess,
      history,
      loggedUser,
      sigmaIntegrationActivate,
      socket,
      t,
    ]
  );
}

export default useSIGMAIntegrator;
