import React, { useCallback, useMemo, useState } from 'react';
import {
  deleteMosaicConfirmOpened,
  metricsStartLoadingAtom,
  unstableChannelsAtom,
} from 'atoms/mosaics';
import CreateMosaicModal from 'components/CreateMosaicModal';
import ApiBoundary from 'components/ApiBoundary';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CircularProgress, Box, Button } from '@mui/material';
import { mosaicItensValuesAtom, mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { Block, ConfirmModal } from 'components/core';
import { useTranslation } from 'react-i18next';
import { useKeyPressEvent } from 'react-use';
import GridLayout from 'react-grid-layout';
import NotFound from 'components/NotFound';
import MosaicView from 'components/MosaicView';
import { Mosaic } from 'types/mosaics.types';
import { DEFAULT_MOSAIC_VALUES } from 'helpers/mosaicTemplates';
import deepEqual from 'deep-equal';
import { useFetchMosaic } from 'hooks/mosaic/useFetchMosaic';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import { useMosaicApi } from 'hooks/mosaic/useMosaicApi';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import { useMosaicPagination } from '../../components/MosaicPagination/useMosaicPagination';
import V2BottomPanel from './V2BottomPanel';

interface MosaicViewPageProps {
  mosaicId: number;
}

function MosaicViewPage({ mosaicId }: MosaicViewPageProps) {
  const [mosaic, setMosaic, mosaicApi] = useFetchMosaic(mosaicId);
  const { t } = useTranslation('mosaics');
  const { openCreateModal } = useCreateMosaicModal();

  /* have to use mosaicApi in here because if not use, the notfound state will be shown after load mosaicApi, until set the mosaicAtom */
  return (
    <>
      <CreateMosaicModal title={t('mosaics:create_mosaic')} initialValues={DEFAULT_MOSAIC_VALUES} />
      {mosaicApi ? (
        <ApiBoundary>{mosaic && <MosaicViewPageInternal {...{ mosaic, setMosaic }} />}</ApiBoundary>
      ) : (
        <NotFound
          title={t('non_exixstent_mosaic')}
          reason={t('mosaic_does_not_exist_or_may_have_been_deleted')}
          shouldDo={t('register_a_new_mosaic_or_select_another_mosaic_to_be_displayed')}
          actionButton={
            <Permissioned role={PermissionRole.INSERT_CHANNEL}>
              <Button
                variant="contained"
                aria-label={t('register_mosaic')}
                color="primary"
                onClick={() => openCreateModal()}
                sx={{
                  mt: 2,
                }}
              >
                {t('register_mosaic')}
              </Button>
            </Permissioned>
          }
        />
      )}
    </>
  );
}

interface MosaicViewInternalProps {
  mosaic: Mosaic;
  setMosaic: SetterOrUpdater<Mosaic | null>;
}
function MosaicViewPageInternal({ mosaic, setMosaic }: MosaicViewInternalProps) {
  const { t } = useTranslation(['mosaics', 'crud_actions']);
  const [isDeleting, setIsDeleting] = useState(false);
  const propsPagination = useMosaicPagination({
    mosaic,
    setMosaic,
  });
  const [deleteConfirmOpened, setDeleteConfirmOpened] = useRecoilState(deleteMosaicConfirmOpened);
  const mosaicItensValues = useRecoilValue(mosaicItensValuesAtom(mosaic.id));
  const orderSelected = useRecoilValue(mosaicSlotOrderSelectedAtom);
  // const [newMosaicDesign] = useDecision(FEATURE_FLAGS_KEYS.NEW_MOSAIC_DESIGN);
  const { removeChannel } = useMosaicItens(mosaic.id);
  const { deleteMosaic } = useMosaicApi();

  const setMetricsStartLoading = useSetRecoilState(metricsStartLoadingAtom);

  React.useEffect(() => {
    setMetricsStartLoading(new Date());

    return () => {
      setMetricsStartLoading(null);
    };
  }, [setMetricsStartLoading]);

  const handleDeleteMosaic = () => {
    setDeleteConfirmOpened(false);
    setIsDeleting(true);
    deleteMosaic(mosaic.id);
    setIsDeleting(false);
  };

  const payload = React.useMemo(
    () =>
      mosaic.mosaicData.payload
        ? mosaic.mosaicData.payload
        : mosaic.mosaicData.pages?.find((pagina) => pagina.offset === propsPagination.currentPage)
            ?.payload,
    [propsPagination.currentPage, mosaic.mosaicData.pages, mosaic.mosaicData.payload]
  );

  const handleLayoutChange = useCallback(
    (layout: GridLayout.Layout[]) => {
      const newLayout = layout.map((item) => {
        const newItem = {};

        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            // @ts-ignore
            const element = item[key];
            // @ts-ignore
            if (element !== undefined) newItem[key] = element;
          }
        }
        return newItem as GridLayout.Layout;
      });
      if (!deepEqual(payload, newLayout)) {
        setMosaic({
          ...mosaic,
          mosaicData: {
            ...mosaic.mosaicData,
            payload: undefined, // TODO remover quando estiver o banco todo atualizado
            pages: mosaic.mosaicData.payload
              ? [
                  {
                    offset: 1,
                    payload: newLayout,
                  },
                ]
              : mosaic.mosaicData.pages?.map((pagina) =>
                  pagina.offset !== propsPagination.currentPage
                    ? pagina
                    : {
                        ...pagina,
                        payload: newLayout,
                      }
                ),
          },
        });
      }
    },
    [propsPagination.currentPage, mosaic, payload, setMosaic]
  );

  useKeyPressEvent('Delete', () => orderSelected && removeChannel(orderSelected));

  const channelsPlayback = useMemo(() => {
    const mosaicItensVisiveis = mosaicItensValues
      .filter((mosaicItem) => mosaicItem.visible)
      .filter((mosaicItem) =>
        payload?.some((itemLayout) => Number(itemLayout.i) === mosaicItem.order)
      );
    return mosaicItensVisiveis.map((item) => ({
      ...item.channel,
      order: item.order,
    }));
  }, [mosaicItensValues, payload]);

  const setUnstableChannels = useSetRecoilState(unstableChannelsAtom);

  React.useEffect(
    function cleanUnstableChannels() {
      setUnstableChannels([]);
    },
    [propsPagination.template, setUnstableChannels]
  );

  return (
    <>
      <Block open={isDeleting}>
        <CircularProgress color="error" />
      </Block>

      <Box
        position="relative"
        flex="1 1 auto"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          display="flex"
          overflow="auto"
          sx={{
            flex: '1 1 auto',
          }}
        >
          <MosaicView
            {...{
              mosaic,
              orderSelected,
              handleLayoutChange,
            }}
            currentPage={propsPagination.currentPage}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            flex: '0 0 auto',
          }}
        >
          {/* {newMosaicDesign?.enabled ? ( */}
          <V2BottomPanel
            propsPagination={propsPagination}
            channels={channelsPlayback}
            mosaicId={mosaic.id}
            {...{
              orderSelected,
            }}
          />
          {/* ) : (
            <BottomPanel
              propsPagination={propsPagination}
              channels={channelsPlayback}
              mosaicId={mosaic.id}
              {...{ orderSelected }}
            />
          )} */}
        </Box>
      </Box>
      <ConfirmModal
        open={deleteConfirmOpened}
        setOpen={setDeleteConfirmOpened}
        doConfirm={handleDeleteMosaic}
        disabled={isDeleting}
        loading={isDeleting}
        variant="contained"
        confirmActionColor="primary"
        confirmActionText={t('crud_actions:delete')}
        title={t('mosaics:want_delete_mosaic', {
          name: mosaic.name,
        })}
        description={t('mosaics:mosaic_will_no_longer_displayed')}
      />
    </>
  );
}

export default MosaicViewPage;
