import { drag, ScaleTime, select, timeFormat } from 'd3';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { ANIMATION_TIMEOUT } from 'components/V2CronologicBar/V2constants';
import {
  textTimeTooltipFullscreenAtom,
  leftTimeTooltipFullscreenAtom,
} from 'atoms/cronologicBar-fullscreen';

function Indicator({
  left,
  height,
  xScale,
  id,
  onSelectDate,
  dateTime,
}: {
  left?: number;
  height?: number;
  xScale: ScaleTime<number, number, never>;
  id: string;
  onSelectDate: (date: Date) => void;
  dateTime: Date;
}) {
  const [dragging, setDragging] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragIndicator = useRecoilCallback(
    ({ set }) => (e: DragEvent) => {
      set(
        textTimeTooltipFullscreenAtom(id),
        timeFormat('%H:%M:%S')(xScale.invert(e.x + xScale(dateTime)))
      );
      set(
        leftTimeTooltipFullscreenAtom(id),
        e.x +
          xScale(dateTime) +
          (document.querySelector(`#cronologic-bar-${id}-fullscreen`)?.getBoundingClientRect()
            .left ?? 0)
      );
    },
    [left, xScale]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEndIndicator = useCallback(
    (e: DragEvent) => {
      onSelectDate(xScale.invert(e.x + xScale(dateTime)));
    },
    [onSelectDate, xScale, dateTime]
  );

  useEffect(
    function mount() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const group = select(`#indicator-${id}-fullscreen`);
      group.attr('transform', `translate(${xScale(dateTime)}, 0)`).attr('fill', 'orange');
      group
        .selectAll('rect')
        .data([dateTime])
        .join('rect')
        .attr('width', 2)
        .attr('height', height || 40);
      const circlePointer = group
        .selectAll<SVGCircleElement, unknown>('circle')
        .data([dateTime])
        .join(
          (enter) => enter.append('circle').attr('cx', 1),
          (update) =>
            dragging
              ? update
              : update.call((x) => x.transition().duration(ANIMATION_TIMEOUT).attr('cx', 1))
        )
        .attr('cy', (height || 40) - 10)
        .attr('r', 10)
        .raise();
      const dragBehavior = drag<SVGCircleElement, Date>()
        .on('start', () => setDragging(true))
        .on('drag', function dr(e: DragEvent) {
          circlePointer.raise().attr('cx', e.x);
          handleDragIndicator(e);
        })
        .on('end', (e: DragEvent) => {
          setDragging(false);
          handleDragEndIndicator(e);
        });
      circlePointer.call(dragBehavior);

      return () => {
        group.selectChildren().remove();
      };
    },
    [xScale, height, dragging, id, handleDragIndicator, handleDragEndIndicator, dateTime]
  );

  return <g id={`indicator-${id}-fullscreen`} />;
}

export default Indicator;
