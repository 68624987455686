import React, { useState, useEffect } from 'react';
import {
  customerGroupSearchTextAtom,
  customerGroupcreateModalOpenedAtom,
} from 'atoms/customerGroup';
import { useRecoilValue, useRecoilState } from 'recoil';
import { asyncComponent } from 'HOCs';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { Box } from '@mui/material';
import { CustomerGroup as CustomerGroupInterface } from 'types/customerGroup.types';
import CustomerGroupList from './CustomerGroupList';

import CustomerGroupCreateModal from './CustomerGroupCreateModal';

function CustomerGroup() {
  const { t } = useTranslation('customer_groups');

  const [selected, setSelected] = useState(0);
  const [createModalOpened, setCreateModalOpened] = useRecoilState(
    customerGroupcreateModalOpenedAtom
  );

  const text = useRecoilValue(customerGroupSearchTextAtom);
  const { data: items } = useFetch<CustomerGroupInterface[]>('/v1/clientGroups', {
    params: { text },
  });

  useEffect(() => {
    if (selected > 0) {
      return;
    }

    setSelected(0);
    if (items && items.length > 0) {
      setSelected(items[0].id);
    }
  }, [items, selected]);

  return (
    <Box display="flex" paddingTop="12px" flex="1 1 auto" overflow="hidden">
      {items?.length === 0 && !text ? (
        <EmptyState
          title={t('register_first_customer_group')}
          description=""
          captionButton={t('register_customer_group')}
          onAddClick={() => setCreateModalOpened(true)}
          roleAdd={PermissionRole.INSERT_CLIENT_GROUP}
        />
      ) : (
        <CustomerGroupList
          {...{ selected, setSelected }}
          items={items as CustomerGroupInterface[]}
        />
      )}
      <CustomerGroupCreateModal open={createModalOpened} setOpen={setCreateModalOpened} />
    </Box>
  );
}

export default asyncComponent(
  CustomerGroup,
  <Box display="flex" paddingTop="12px" flex="1 1 auto" overflow="hidden">
    <CustomerGroupList loading />
  </Box>
);
