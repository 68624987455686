import { FastRewind, FastForward } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

function FowardRewindAction({
  handleFowardRewind,
  live,
}: {
  handleFowardRewind: (time: number) => void;
  live: boolean;
}) {
  const [timeToPass, setTimeToPass] = React.useState(3600);

  return (
    <>
      <BackButton
        {...{
          handleFowardRewind,
          timeToPass,
        }}
      />
      <Box sx={{ mr: 1.5 }}>
        <SelectTimeToPass
          {...{
            setTimeToPass,
            timeToPass,
          }}
        />
      </Box>
      <Box sx={{ mr: 1.5 }}>
        <FowardButton
          {...{
            handleFowardRewind,
            timeToPass,
            live,
          }}
        />
      </Box>
    </>
  );
}

function BackButton({
  handleFowardRewind,
  timeToPass,
}: {
  handleFowardRewind: (time: number) => void;
  timeToPass: number;
}) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label="rewind"
      onClick={() => handleFowardRewind(-timeToPass)}
      size="small"
      sx={{ p: 0, mr: 1.5 }}
      title={((): string =>
        `${t('video:back')} ${dayjs.duration(timeToPass, 'seconds').humanize()}`)()}
    >
      <FastRewind />
    </IconButton>
  );
}

function FowardButton({
  handleFowardRewind,
  timeToPass,
  live,
}: {
  handleFowardRewind: (time: number) => void;
  timeToPass: number;
  live: boolean;
}) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label="foward"
      onClick={() => handleFowardRewind(timeToPass)}
      disabled={live}
      size="small"
      sx={{ p: 0 }}
      title={`${t('video:foward')} ${dayjs.duration(timeToPass, 'seconds').humanize()}`}
    >
      <FastForward />
    </IconButton>
  );
}

function SelectTimeToPass({
  setTimeToPass,
  timeToPass,
}: {
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  return (
    <Select
      role="region"
      aria-label="time to move foward or to rewind"
      value={timeToPass}
      onChange={(e) => setTimeToPass(e.target.value as number)}
      variant="outlined"
      size="small"
    >
      {[10, 30, 60, 300, 3600, 18000, 36000].map((seconds) => (
        <MenuItem key={seconds} value={seconds}>
          {dayjs.duration(seconds, 'seconds').humanize()}
        </MenuItem>
      ))}
    </Select>
  );
}

FowardRewindAction.BackButton = BackButton;
FowardRewindAction.FowardButton = FowardButton;
FowardRewindAction.SelectTimeToPass = SelectTimeToPass;

export default FowardRewindAction;
