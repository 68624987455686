import { atom } from 'recoil';
import { Customer } from 'types/customer.types';

export const normalizeCustomer = (customer: Customer) => ({
  ...customer,
  idMask: String(customer.id).padStart(6, '0'),
});

export const customerSearchTextAtom = atom<string>({
  key: 'customerSearchText',
  default: '',
});
