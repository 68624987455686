import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import SelectTemplate from 'components/SelectTemplate';
import { useTranslation } from 'react-i18next';
import { Template } from 'types/mosaics.types';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import SelectPage from './SelectPage';

type SizeType = 'small' | 'medium';

export interface MosaicPaginationProps {
  currentPage: number;
  totalPage: number;
  onChangePage: (newPage: number) => void;
  template: Template;
  onChangeTemplate: (newTemplate: Template) => void;
  size: SizeType;
  qtyChannelMax: Template;
}
function MosaicPagination({
  currentPage,
  totalPage,
  template,
  onChangeTemplate,
  onChangePage,
  size = 'medium',
  qtyChannelMax,
}: MosaicPaginationProps) {
  return (
    <Box display="flex" gap="0.5rem" alignItems="center">
      <MosaicPagination.SelectPage
        {...{
          currentPage,
          totalPage,
          onChangePage,
          size,
        }}
      />
      <MosaicPagination.SelectTemplate
        {...{
          template,
          onChangeTemplate,
          size,
          qtyChannelMax,
        }}
      />
    </Box>
  );
}

MosaicPagination.SelectPage = function MPSelectPage({
  currentPage,
  totalPage,
  onChangePage,
  size = 'medium',
}: {
  currentPage: number;
  totalPage: number;
  onChangePage: (newPage: number) => void;
  size: SizeType;
}) {
  const { t } = useTranslation();

  const goBack = () => {
    if (currentPage <= 1) return;
    onChangePage(currentPage - 1);
  };

  const goForward = () => {
    if (currentPage >= totalPage) return;
    onChangePage(currentPage + 1);
  };

  return (
    <>
      <IconButton
        onClick={goBack}
        aria-label="Go back Icon"
        title={t('_common:previous_page')}
        size={size}
      >
        <NavigateBefore fontSize={size} color="action" />
      </IconButton>

      <SelectPage
        currentPage={currentPage}
        totalPages={totalPage}
        onChangePage={onChangePage}
        size={size}
      />

      <Typography fontSize={size} sx={{ textTransform: 'lowercase' }}>
        {t('_common:of')} {totalPage}
      </Typography>
      <IconButton
        onClick={goForward}
        aria-label="Go forward Icon"
        title={t('_common:next_page')}
        size={size}
      >
        <NavigateNext fontSize={size} color="action" />
      </IconButton>
    </>
  );
};

MosaicPagination.SelectTemplate = function MPSelectTemplate({
  template,
  onChangeTemplate,
  size = 'medium',
  qtyChannelMax,
}: {
  template: Template;
  onChangeTemplate: (newTemplate: Template) => void;
  size: SizeType;
  qtyChannelMax: Template;
}) {
  const { t } = useTranslation();

  return (
    <SelectTemplate
      {...{ qtyChannelMax, size, template, onChangeTemplate }}
      title={t('_common:select_template_by_page')}
    />
  );
};

export default MosaicPagination;
