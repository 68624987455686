import React, { useState, useEffect } from 'react';
import {
  channelGroupSearchTextAtom,
  channelGroupcreateModalOpenedAtom,
} from 'atoms/channelGroup';
import { useRecoilValue, useRecoilState } from 'recoil';
import { asyncComponent } from 'HOCs';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { Box } from '@mui/material';
import { ChannelGroup as ChannelGroupInterface } from 'types/channelGroup.types';
import ChannelGroupList from './ChannelGroupList';
import ChannelGroupCreateModal from './ChannelGroupCreateModal';

function ChannelGroup() {
  const { t } = useTranslation('channel_groups');

  const [selected, setSelected] = useState(0);
  const [createModalOpened, setCreateModalOpened] = useRecoilState(
    channelGroupcreateModalOpenedAtom
  );

  const text = useRecoilValue(channelGroupSearchTextAtom);
  const { data: items } = useFetch<ChannelGroupInterface[]>('/v1/channelGroups', {
    params: { text },
  });

  useEffect(() => {
    if (selected > 0) {
      return;
    }

    setSelected(0);
    if (items && items.length > 0) {
      setSelected(items[0].id);
    }
  }, [items, selected]);

  return (
    <Box display="flex" paddingTop="12px" flex="1 1 auto" overflow="hidden">
      {items?.length === 0 && !text ? (
        <EmptyState
          title={t('register_first_channel_group')}
          description=""
          captionButton={t('register_channel_group')}
          onAddClick={() => setCreateModalOpened(true)}
          roleAdd={PermissionRole.INSERT_CHANNEL_GROUP}
        />
      ) : (
        <ChannelGroupList {...{ selected, setSelected }} items={items as ChannelGroupInterface[]} />
      )}
      <ChannelGroupCreateModal open={createModalOpened} setOpen={setCreateModalOpened} />
    </Box>
  );
}

export default asyncComponent(
  ChannelGroup,
  <Box display="flex" paddingTop="12px" flex="1 1 auto" overflow="hidden">
    <ChannelGroupList loading />
  </Box>
);
