import { Box, Fab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { SegwareLogo } from 'components/core/Icons';
import { useTranslation } from 'react-i18next';
import { NavigateBefore } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom';
import routes from 'helpers/routes';
import DebounceInput from 'components/core/DebounceInput';
import { useLeftPanel } from 'hooks/useLeftPanel';
import { Container, Header, InputContainer } from '../LeftPanel.styles';
import PlaylistDetailsMosaics from './PlaylistDetailsMosaics';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function PlaylistDetailsPanel() {
  const match = useRouteMatch<{ playlistId: string }>(routes.playlists.play(':playlistId'));
  const { t } = useTranslation(['playlists', 'select_mosaic']);
  const classes = useStyles();
  const { setPlaylistDetailsActual } = useLeftPanel();
  const [inputText, setInputText] = React.useState('');
  const [inputTextDebounced, setInputTextDebounced] = React.useState('');

  if (!match) {
    setPlaylistDetailsActual(false);
    return null;
  }

  return (
    <Container role="region" aria-labelledby="playlist-details-panel-title">
      <Header>
        <SegwareLogo className={classes.segwareLogo} />
        <Box>
          <Typography id="playlist-details-panel-title" variant="h6">
            {t('playlist_details')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('organize_and_add_mosaics_to_display_in_playlist')}
          </Typography>
        </Box>
      </Header>
      <InputContainer>
        <Fab size="small" onClick={() => setPlaylistDetailsActual(false)} aria-label="close">
          <NavigateBefore />
        </Fab>
        <DebounceInput
          placeholder={t('select_mosaic:search_mosaics')}
          autoFocus
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          setValue={setInputText}
          onDebounce={() => setInputTextDebounced(inputText)}
        />
      </InputContainer>
      <PlaylistDetailsMosaics
        playlistId={Number(match.params.playlistId)}
        searchText={inputTextDebounced}
      />
    </Container>
  );
}

export default PlaylistDetailsPanel;
