import React from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { formatToReal } from 'helpers/formatToReal';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedPlanAtom, totalStorageCalculatedSelector } from 'atoms/calculator';
import { formatBytes, convertByte } from 'helpers/bytesOperations';
import { Mode, themeSelectedAtom } from 'atoms/config';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  value: {
    fontWeight: 400,
    fontSize: '1.25rem',
    alignItems: 'center',
  },
  storageSize: {
    fontWeight: 400,
    fontSize: '1.5rem',
  },
  perMounth: {
    fontWeight: 'lighter',
    fontSize: '1rem',
    color: theme.palette.grey[500],
  },
  recommended: {
    color: '#7AFF7F',
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

export function NewSelectStoragePlan() {
  const classes = useStyles();
  const { t } = useTranslation('metrics');
  const [selectedPlan, setSelectedPlan] = useRecoilState(selectedPlanAtom);
  const totalStorage = useRecoilValue(totalStorageCalculatedSelector);
  const themeSelected = useRecoilValue(themeSelectedAtom);

  const plans = React.useMemo(
    () => [
      {
        storageSizeBytes: convertByte(25, 'GB', 'Bytes'),
        value: 0,
      },
      {
        storageSizeBytes: convertByte(250, 'GB', 'Bytes'),
        value: 100,
      },
      {
        storageSizeBytes: convertByte(500, 'GB', 'Bytes'),
        value: 150,
      },
      {
        storageSizeBytes: convertByte(1, 'TB', 'Bytes'),
        value: 290,
      },
      {
        storageSizeBytes: convertByte(2, 'TB', 'Bytes'),
        value: 560,
      },
      {
        storageSizeBytes: convertByte(3, 'TB', 'Bytes'),
        value: 810,
      },
      {
        storageSizeBytes: convertByte(4, 'TB', 'Bytes'),
        value: 1040,
      },
      {
        storageSizeBytes: convertByte(5, 'TB', 'Bytes'),
        value: 1250,
      },
      {
        storageSizeBytes: convertByte(7, 'TB', 'Bytes'),
        value: 1680,
      },
      {
        storageSizeBytes: convertByte(10, 'TB', 'Bytes'),
        value: 2300,
      },
      {
        storageSizeBytes: convertByte(15, 'TB', 'Bytes'),
        value: 3300,
      },
      {
        storageSizeBytes: convertByte(25, 'TB', 'Bytes'),
        value: 5350,
      },
      {
        storageSizeBytes: convertByte(50, 'TB', 'Bytes'),
        value: 10500,
      },
      {
        storageSizeBytes: convertByte(75, 'TB', 'Bytes'),
        value: 15600,
      },
      {
        storageSizeBytes: convertByte(100, 'TB', 'Bytes'),
        value: 20600,
      },
      {
        storageSizeBytes: convertByte(150, 'TB', 'Bytes'),
        value: 30600,
      },
      {
        storageSizeBytes: convertByte(250, 'TB', 'Bytes'),
        value: 51000,
      },
      {
        storageSizeBytes: convertByte(500, 'TB', 'Bytes'),
        value: 102000,
      },
    ],
    []
  );

  const recommendedPlan = React.useMemo(
    () =>
      plans.find((e) => convertByte(totalStorage, 'GB', 'Bytes') < e.storageSizeBytes) ||
      plans[plans.length - 1],
    [plans, totalStorage]
  );

  React.useEffect(() => {
    setSelectedPlan(recommendedPlan);
  }, [recommendedPlan, setSelectedPlan]);

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select
        id="planList"
        labelId="select-plan"
        value={String(selectedPlan?.storageSizeBytes) || ''}
        onChange={(e) => {
          setSelectedPlan(
            plans.find((plan) => plan.storageSizeBytes === Number(e.target.value)) || plans[0]
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              width: 250,
            },
          },
        }}
      >
        {plans.map((storagePlan) => (
          <MenuItem
            key={storagePlan.storageSizeBytes}
            value={storagePlan.storageSizeBytes}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box className={classes.row}>
              <Typography className={classes.storageSize} component="span">
                {formatBytes(storagePlan.storageSizeBytes)}
              </Typography>
              <Typography display="flex" className={classes.value} component="span">
                {formatToReal(storagePlan.value)}&nbsp;&nbsp;
                <Typography component="span" className={classes.perMounth}>
                  /{t('mounth')}
                </Typography>
              </Typography>
            </Box>
            {storagePlan.storageSizeBytes === recommendedPlan?.storageSizeBytes && (
              <Typography component="span" sx={{
                color: themeSelected === Mode.DARK ? '#7AFF7F' : '#4CAF50',
                fontSize: '0.85rem',
                textTransform: 'uppercase',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
                {t('recommended')}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
