import { Theme } from '@mui/material';
import { RecordingType } from 'types/channels.types';

const RECORD_24_7_COLOR = '#B0ADB8';
const SCHEDULE_COLOR = '#FF825C';
const LIVE_COLOR = '#4caf50';
// const PRE_ALARM_COLOR = '#E0CD53';

function colorByRecordingType(recordingType: RecordingType, theme: Theme) {
  switch (recordingType) {
    case RecordingType.RECORD_24_7:
      return RECORD_24_7_COLOR;
    case RecordingType.SCHEDULE:
      return SCHEDULE_COLOR;
    case RecordingType.LIVE:
      return LIVE_COLOR;

    // case RecordingType.PRE_ALARM:
    //   return PRE_ALARM_COLOR;

    default:
      return theme.palette.primary.main;
  }
}

export default colorByRecordingType;
