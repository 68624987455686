import styled from 'styled-components';
import UserAvatar from 'components/core/Avatar';

export const Avatar = styled(UserAvatar)<{ selected?: boolean }>`
  flex: 0 0 auto;
`;

export const TextContainer = styled.div`
  flex: 1 1 auto;
  margin-left: 1.5rem;
`;
