import { Box, IconButton, Popover, Switch, Typography } from '@mui/material';
import { deleteMosaicConfirmOpened, viewVideoDetailsAtom } from 'atoms/mosaics';
import { sigmaIntegrationActivateAtom } from 'atoms/sigmaIntegration';
import { Delete, Settings, SettingsTimelapse, SlowMotionVideo } from 'components/core/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import VideoControlsBar from './V2VideoControlsBar';

function MosaicSettings({
  setPlaybackRate,
  playbackRate,
  setTimeToPass,
  timeToPass,
}: {
  setPlaybackRate: (newValue: number) => void;
  playbackRate: number;
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  return (
    <MosaicSettings.Container>
      <MosaicSettings.ViewVideoDetails />
      <MosaicSettings.SigmaIntegrationSwitch />
      <MosaicSettings.PlaybackSpeedSelect
        {...{
          setPlaybackRate,
          playbackRate,
        }}
      />
      <MosaicSettings.AdvanceSpeed
        {...{
          setTimeToPass,
          timeToPass,
        }}
      />
      <MosaicSettings.DeleteMosaic />
    </MosaicSettings.Container>
  );
}

MosaicSettings.Container = function Container({ children }: { children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteMosaicConfirmOpenedValue = useRecoilValue(deleteMosaicConfirmOpened);
  React.useEffect(
    function close() {
      if (deleteMosaicConfirmOpened) {
        handleClose();
      }
    },
    [deleteMosaicConfirmOpenedValue]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton aria-describedby={id} size="small" sx={{ p: 0 }} onClick={handleClick}>
        <Settings />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ py: 1 }}>{children}</Box>
      </Popover>
    </>
  );
};

MosaicSettings.ViewVideoDetails = function ViewVideoDetails() {
  const { t } = useTranslation();
  const [viewVideoDetails, setViewVideoDetails] = useRecoilState(viewVideoDetailsAtom);
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClick={() => setViewVideoDetails(!viewVideoDetails)}
    >
      <Typography>{t('_common:view_channel_technical_details')}</Typography>
      <Switch checked={viewVideoDetails} sx={{ ml: 2 }} />
    </Box>
  );
};

MosaicSettings.SigmaIntegrationSwitch = function SigmaIntegrationSwitch() {
  const { t } = useTranslation();
  const [sigmaIntegrationActivate, setSigmaIntegrationActivate] = useRecoilState(
    sigmaIntegrationActivateAtom
  );
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClick={() => setSigmaIntegrationActivate(!sigmaIntegrationActivate)}
    >
      <Typography>{t('mosaics:synchronized_view_with_SIGMA_on_this_tab')}</Typography>
      <Switch checked={sigmaIntegrationActivate} sx={{ ml: 2 }} />
    </Box>
  );
};

MosaicSettings.PlaybackSpeedSelect = function PlaybackSpeedSelectOption({
  setPlaybackRate,
  playbackRate,
}: {
  setPlaybackRate: (newValue: number) => void;
  playbackRate: number;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SlowMotionVideo sx={{ mr: 2 }} />
        <Typography>{t('video:playback_speed')}</Typography>
      </Box>
      <VideoControlsBar.PlaybackSpeedSelect {...{ setPlaybackRate, playbackRate }} />
    </Box>
  );
};

MosaicSettings.AdvanceSpeed = function AdvanceSpeed({
  setTimeToPass,
  timeToPass,
}: {
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SettingsTimelapse sx={{ mr: 2 }} />
        <Typography>{t('video:advance_speed')}</Typography>
      </Box>
      <VideoControlsBar.FowardRewindAction.SelectTimeToPass
        {...{
          setTimeToPass,
          timeToPass,
        }}
      />
    </Box>
  );
};

MosaicSettings.DeleteMosaic = function DeleteMosaic() {
  const { t } = useTranslation();
  const setDeleteMosaicConfirmOpened = useSetRecoilState(deleteMosaicConfirmOpened);

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: 'error.main',
      }}
      onClick={() => {
        setDeleteMosaicConfirmOpened(true);
      }}
    >
      <Delete sx={{ mr: 2 }} />
      <Typography sx={{ color: 'currentcolor' }}>{t('mosaic:delete_mosaic')}</Typography>
    </Box>
  );
};

export default MosaicSettings;
