/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton } from '@mui/material';
import { scaleTime, select, timeFormat, ScaleTime, zoom, zoomTransform, ZoomTransform } from 'd3';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useDebounce, useMeasure } from 'react-use';
import { Add, ArrowLeft, ArrowRight, Remove } from '@mui/icons-material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  textTimeTooltipAtom,
  leftTimeTooltipAtom,
  periodEndDateAtom,
  periodStartDateAtom,
} from 'atoms/cronologicBar';
import { Mode, themeSelectedAtom } from 'atoms/config';
import { MARGINSIZES, STEP_ZOOM_X, SCALE_EXTENT } from './V2constants';
import Indicator from './V2Indicator';
import XAxis from './V2XAxis';
import TimeTooltip from './V2TimeTooltip';

function CronologicBar({
  height = 40,
  id,
  startDate,
  endDate,
  children,
  currentZoomState,
  setCurrentZoomState,
}: {
  height?: number;
  id: string;
  startDate: Date;
  endDate: Date;
  currentZoomState?: ZoomTransform;
  setCurrentZoomState: (
    valOrUpdater:
      | ((currVal: ZoomTransform | undefined) => ZoomTransform | undefined)
      | ZoomTransform
      | undefined
  ) => void;
  children?: (args: {
    left?: number;
    height?: number;
    xScale: ScaleTime<number, number, never>;
  }) => ReactNode;
}) {
  const svgEl = useRef<SVGSVGElement>(null);
  const setTextTooltip = useSetRecoilState(textTimeTooltipAtom(id));
  const setLeftTooltip = useSetRecoilState(leftTimeTooltipAtom(id));
  const [internalStartDate, setStartDate] = useRecoilState(periodStartDateAtom(id));
  const [internalEndDate, setEndDate] = useRecoilState(periodEndDateAtom(id));
  const themeSelected = useRecoilValue(themeSelectedAtom);
  const [measureRef, { width }] = useMeasure<HTMLDivElement>();
  const { t } = useTranslation('_common');

  useEffect(
    function resetZoom() {
      setCurrentZoomState(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate]
  );

  const xScale = useMemo(() => {
    const calculatedWith = document.querySelector(`#cronologic-bar-${id}`)?.clientWidth ?? width;
    const scale = scaleTime()
      .domain([startDate, endDate])
      .range([MARGINSIZES, calculatedWith - MARGINSIZES]);

    return currentZoomState ? currentZoomState.rescaleX(scale) : scale;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoomState, endDate.toString(), startDate.toString(), width]);

  useDebounce(
    function updateInternalPeriod() {
      const scaleStartDate = xScale.domain()[0];
      const scaleEndDate = xScale.domain()[1];
      if (
        scaleStartDate.toISOString() !== internalStartDate.toISOString() ||
        scaleEndDate.toISOString() !== internalEndDate.toISOString()
      ) {
        setStartDate(scaleStartDate);
        setEndDate(scaleEndDate);
      }
    },
    200,
    [internalEndDate, internalStartDate, setEndDate, setStartDate, xScale]
  );

  useEffect(
    function addZoomBehavior() {
      if (svgEl.current) {
        const svg = select(svgEl.current);

        const zoomBehavior = zoom<SVGSVGElement, unknown>()
          .scaleExtent(SCALE_EXTENT)
          .on('zoom', () => {
            if (!svgEl.current) {
              return;
            }
            const zoomState = zoomTransform(svgEl.current);
            setCurrentZoomState(zoomState);
          });

        svg.call(zoomBehavior);
      }
    },
    [setCurrentZoomState]
  );

  const handlePutTooltip = (e: React.MouseEvent) => {
    setTextTooltip(
      timeFormat('%d/%m %H:%M')(
        xScale.invert(e.pageX - (svgEl.current?.getBoundingClientRect().left ?? 0))
      )
    );
    setLeftTooltip(e.pageX);
  };
  const handleRemoveTooltip = () => {
    setTextTooltip('');
    setLeftTooltip(0);
  };

  const handleChangeZoomX = (xStep: number) => {
    if (!svgEl.current) {
      return;
    }
    const svg = select(svgEl.current);

    const zoomT = zoomTransform(svgEl.current);
    const newZoom = zoomT.translate(xStep / zoomT.k, zoomT.y);
    svg.call(zoom<SVGSVGElement, unknown>().transform, newZoom);
    setCurrentZoomState(newZoom);
  };

  const handleChangeZoomK = (kStep: number) => {
    if (!svgEl.current) {
      return;
    }
    const svg = select(svgEl.current);

    zoom<SVGSVGElement, unknown>().scaleExtent(SCALE_EXTENT).scaleBy(svg, kStep);
    const zoomT = zoomTransform(svgEl.current);
    setCurrentZoomState(zoomT);
  };

  return (
    <Box role="region" aria-label="cronologic bar" display="flex" alignItems="center">
      <IconButton
        aria-label="go back"
        size="small"
        onClick={() => handleChangeZoomX(STEP_ZOOM_X)}
        title={t('_common:move_back')}
      >
        <ArrowLeft />
      </IconButton>
      <Box ref={measureRef} sx={{ flex: '1 1 auto', height: `${height}px` }}>
        <Box
          component="svg"
          ref={svgEl}
          id={`cronologic-bar-${id}`}
          className="svg-bar"
          height={height}
          onMouseMove={handlePutTooltip}
          onMouseLeave={handleRemoveTooltip}
          width="100%"
          sx={{
            bgcolor: themeSelected === Mode.DARK ? '#595959' : '#AAAA',
          }}
        >
          {children
            ? children({
                left: (() => svgEl.current?.getBoundingClientRect().left)(),
                height: svgEl.current?.clientHeight,
                xScale,
              })
            : null}
          <TimeTooltip svgEl={svgEl} id={id} />
        </Box>
      </Box>
      <IconButton
        aria-label="go foward"
        size="small"
        onClick={() => handleChangeZoomX(-STEP_ZOOM_X)}
        title={t('_common:move_foward')}
      >
        <ArrowRight />
      </IconButton>
      <IconButton
        aria-label="less zoom"
        size="small"
        onClick={() => handleChangeZoomK(0.5)}
        title={t('_common:less_zoom')}
      >
        <Remove />
      </IconButton>
      <IconButton
        aria-label="more zoom"
        size="small"
        onClick={() => handleChangeZoomK(1.5)}
        title={t('_common:more_zoom')}
      >
        <Add />
      </IconButton>
    </Box>
  );
}

CronologicBar.Indicator = Indicator;
CronologicBar.XAxis = XAxis;

export default CronologicBar;
