import React from 'react';
import { useTranslation } from 'react-i18next';
import { useYupObject, useQuery, useUserCRUD } from 'hooks';
import { User, PermissionGroup, UserInitialValues, PermissionRole } from 'types/user.types';
import { useFormik } from 'formik';
import routes from 'helpers/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  FormControlLabel,
  Box,
  Switch,
  TextField,
  Divider,
  Button,
  Typography,
  FormControl,
} from '@mui/material';
import { Form } from 'components/core';
import Permissioned from 'components/Permissioned';
import { SelectMultiCompanies } from 'components/SelectCompanies/SelectMultiCompanies';
import { useHavePermission } from 'hooks/useHavePermission';
import UserRoles from './UserRoles';
import { useFormStyles } from '../core/Form';
import SelectPermission from '../SelectPermission/SelectPermission';

function UserForm({
  initialValues,
  doSubmit,
  showDeleteButton,
  doDelete,
}: {
  initialValues: UserInitialValues | User;
  doSubmit(user: User): Promise<void>;
  showDeleteButton?: boolean;
  doDelete?(id: number): Promise<void>;
}) {
  const history = useHistory();
  const { t } = useTranslation(['users', 'crud_actions', 'channels']);
  const classesForm = useFormStyles({});
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const yup = useYupObject();
  const query = useQuery();
  const validationSchema = yup.object({
    status: yup.bool(),
    firstName: yup.string().trim().max(254).required().noSpecialCharacters(),
    lastName: yup.string().trim().max(254).required().noSpecialCharacters(),
    email: yup.string().email().max(254).required(),
    username: yup.string().trim().max(254).required(),
    permission: yup.string().oneOf(Object.values(PermissionGroup)).required(),
    enablePermissionCompany: yup.bool(),
    companies: yup.array().when('enablePermissionCompany', {
      is: (enablePermissionCompany) => enablePermissionCompany,
      then: yup.array(yup.number().required()).required().min(1),
      otherwise: yup.array(),
    }),
  });

  const transformedInitialValues = initialValues.id
    ? // @ts-ignore
      { ...initialValues, companies: initialValues.companies?.map((company) => company.id) }
    : initialValues;

  const formik = useFormik({
    initialValues: transformedInitialValues,
    validationSchema,
    // @ts-ignore
    onSubmit: async (values: UserInitialValues, { setSubmitting }) => {
      try {
        const transformedValues = {
          ...values,
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim(),
          username: values.username?.trim(),
          email: values.email?.trim(),
        };

        // @ts-ignore
        await doSubmit(transformedValues);

        setSubmitting(false);
        history.push(routes.settings.user.root);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  const matchUserEdit = useRouteMatch(routes.settings.user.edit(':customerId'));
  const { changePasswordUser } = useUserCRUD();

  const handleChangePassword = () => {
    const { id } = initialValues as User;
    changePasswordUser(id);
  };

  return (
    <form aria-label="User" className={classesForm.form} onSubmit={formik.handleSubmit}>
      <>
        <Box className={classesForm.formInputsContainer}>
          <FormControlLabel
            className={classesForm.formInput}
            control={
              <Switch
                color="primary"
                id="status"
                name="status"
                onChange={formik.handleChange}
                checked={formik.values.status}
              />
            }
            label={t(formik.values.status ? 'active_user' : 'inactive_user')}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <TextField
              className={classesForm.formInput}
              fullWidth
              name="firstName"
              id="firstName"
              label={t('first_name')}
              autoFocus
              helperText={formik.touched.firstName && formik.errors.firstName}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
            <TextField
              className={classesForm.formInput}
              fullWidth
              label={t('last_name')}
              name="lastName"
              id="lastName"
              helperText={formik.touched.lastName && formik.errors.lastName}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <TextField
              className={classesForm.formInput}
              fullWidth
              label={t('user_name')}
              name="username"
              id="username"
              helperText={
                (formik.touched.username && formik.errors.username) ||
                (matchUserEdit?.isExact ? null : t('block_username_modification'))
              }
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              disabled={matchUserEdit?.isExact}
            />
            <TextField
              className={classesForm.formInput}
              fullWidth
              label={t('email')}
              name="email"
              id="email"
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              disabled={matchUserEdit?.isExact}
            />
            <SelectPermission
              error={formik.touched.permission && Boolean(formik.errors.permission)}
              helperText={formik.touched.permission ? formik.errors.permission : ''}
              selectedPermission={formik.values.permission || null}
              onChangeSelectPermission={formik.handleChange}
            />
          </Box>
          <Permissioned role={PermissionRole.ADMIN}>
            <FormControlLabel
              className={classesForm.formInput}
              control={
                <Switch
                  color="primary"
                  id="enablePermissionCompany"
                  name="enablePermissionCompany"
                  onChange={formik.handleChange}
                  checked={formik.values.enablePermissionCompany}
                />
              }
              label={
                formik.values.enablePermissionCompany
                  ? t('company:disablePermissionByCompany')
                  : t('company:allowPermissionByCompany')
              }
            />
          </Permissioned>

          {formik.values.enablePermissionCompany && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box width="100%">
                <React.Suspense fallback={<SelectMultiCompanies.Loading />}>
                  <SelectMultiCompanies
                    selected={formik.values.companies}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.companies)}
                  />
                </React.Suspense>
              </Box>
              <Box width="100%" />
            </Box>
          )}
          <UserRoles groupPermission={formik.values.permission} />
          {initialValues.id ? (
            <Permissioned role={PermissionRole.ADMIN}>
              <FormControl>
                <Typography variant="caption" color="#c1c1c1" fontSize="0.8rem" mb={1}>
                  {t('channels:password')}
                </Typography>
                <Button color="primary" variant="contained" onClick={handleChangePassword}>
                  {t('main-left-menu:change_password')}
                </Button>
              </FormControl>
            </Permissioned>
          ) : null}
        </Box>
        <Divider />
        <Box className={classesForm.formFooter}>
          {showDeleteButton && excludePermission && (
            <Form.DeleteButton
              isSubmitting={formik.isSubmitting}
              confirmModalDescription={t(
                'channels:not_possible_retrieve_information_and_view_image_camera'
              )}
              confirmModalTitle={t('want_delete')}
              onConfirmDelete={async (setIsDeleting) => {
                try {
                  if (!doDelete) return;
                  if (!initialValues.id) return;
                  await doDelete(initialValues.id);
                  setIsDeleting(false);
                  history.push(routes.settings.user.root);
                } catch (error) {
                  console.error(error);
                  setIsDeleting(false);
                }
              }}
            />
          )}
          <Form.CancelButton
            isSubmitting={formik.isSubmitting}
            onClick={() => history.push(query.get('returnRoute') || routes.settings.user.root)}
          />
          <Form.SaveButton
            isSubmitting={formik.isSubmitting}
            initialValues={formik.initialValues}
            values={formik.values}
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          />
        </Box>
      </>
    </form>
  );
}

export default UserForm;
