import React from 'react';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { downloadModalOpenedAtom } from 'atoms/download';

interface IDownloadButton {
  text?: string;
}
function DownloadButton({ text }: IDownloadButton) {
  const [open, setOpen] = useRecoilState(downloadModalOpenedAtom);

  const onClick = () => {
    setOpen(!open);
  };

  return text?.length ? (
    <Button variant="contained" color="primary" onClick={onClick}>
      <Download fontSize="small" />
      {text}
    </Button>
  ) : (
    <Download
      sx={(theme) => ({
        color: theme.palette.action.active,
      })}
      fontSize="medium"
      onClick={onClick}
    />
  );
}
function ToPopUp() {
  const { t } = useTranslation('_common');
  return <DownloadButton text={t('_common:download')} />;
}
function ToPage() {
  const { t } = useTranslation('_common');
  return <DownloadButton text={t('_common:download_app')} />;
}
function ToMenu() {
  return <DownloadButton />;
}
const DownloadVMSButton = {
  ToPopUp,
  ToPage,
  ToMenu,
};

export default DownloadVMSButton;
