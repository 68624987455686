import React from 'react';
import { Box, Typography, TextField, Button, Divider, Avatar } from '@mui/material';
import routes from 'helpers/routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Company } from 'types/companies.types';
import { Form } from 'components/core';
import { useFormik } from 'formik';
import { useMessages, useQuery, useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';

interface CompanyFormProps {
  showDeleteButton?: boolean;
  doDelete: (id: number) => Promise<void>;
  initialValues: Company;
  doSubmit: (values: Company) => Promise<void>;
}
export default function CompanyForm({
  showDeleteButton,
  doDelete,
  initialValues,
  doSubmit,
}: CompanyFormProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const yup = useYupObject();
  const validationSchema = yup.object({
    fantasyName: yup.string().required().max(100).trim().noSpecialCharacters().min(3),
    companyName: yup.string().required().max(100).trim().noSpecialCharacters().min(3),
    federalRegistration: yup.string().trim().required().min(14).max(50),
    email: yup.string().email().nullable().required(),
    phone: yup
      .string()
      .trim()
      .required()
      .matches(/^\d+$/, t('forms:tel_only_numbers'))
      .min(
        10,
        t('forms:tel_min_digits', {
          max: 10,
        })
      )
      .max(11, t('forms:tel_max_digits', { max: 11 })),
    address: yup.string().trim().min(5),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const transformedData = {
          ...values,
        };

        await doSubmit(transformedData);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  const excludePermission = useHavePermission(PermissionRole.REMOVE_COMPANY);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const { enqueueError } = useMessages();
  const [logoHelperText, setLogoHelperText] = React.useState('forms:max_size');
  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  function handleLogoValidation(file: File) {
    if (file.type.startsWith('image/')) {
      if (file.size <= 100 * 1024) {
        return file;
      }
      enqueueError(
        t('forms:file_max', {
          max: '100Kb',
        })
      );
    } else {
      enqueueError(t('forms:image_only'));
    }

    return undefined;
  }

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files && event.target.files[0];
    if (newFile) {
      const validFile = handleLogoValidation(newFile);
      formik.setFieldValue('file', validFile || undefined);
      setLogoHelperText(validFile?.name || 'forms:max_size');
    }
  };

  const handleLoadSRC = (file: File) => {
    if (file) {
      return file && file.name && URL.createObjectURL(file);
    }

    return '';
  };
  React.useEffect(
    function handleLogoHelperTextEffect() {
      const { id, namePhoto } = initialValues;
      id && namePhoto && setLogoHelperText(namePhoto);
    },
    [initialValues]
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      aria-label="companiesForm"
      style={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100%',
      }}
      encType="multipart/form-data"
    >
      <Box
        sx={{
          flex: '1 1 auto',
          overflow: 'auto',
          marginLeft: '32px',
          marginTop: '32px',
        }}
      >
        <Typography
          sx={{
            mb: 2,
            fontWeight: '100',
            fontSize: '20px',
          }}
        >
          {initialValues.id ? t('_common:edit_company') : t('_common:new_company')}
        </Typography>
        <Box mb={2}>
          <TextField
            placeholder={t('customers:fantasyName')}
            label={t('customers:fantasyName')}
            fullWidth
            value={formik.values.fantasyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fantasyName && Boolean(formik.errors.fantasyName)}
            helperText={
              (formik.touched.fantasyName && formik.errors.fantasyName) ||
              t('forms:string_max', { max: 100 })
            }
            id="fantasyName"
            name="fantasyName"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mb: 2,
          }}
        >
          <TextField
            label={t('customers:company_name')}
            placeholder={t('customers:company_name')}
            fullWidth
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={
              (formik.touched.companyName && formik.errors.companyName) ||
              t('forms:string_max', { max: 100 })
            }
            id="companyName"
            name="companyName"
          />
          <TextField
            label="CNPJ"
            placeholder="CNPJ"
            fullWidth
            value={formik.values.federalRegistration}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.federalRegistration && Boolean(formik.errors.federalRegistration)}
            helperText={formik.touched.federalRegistration && formik.errors.federalRegistration}
            id="federalRegistration"
            name="federalRegistration"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
              mb: '40px',
            }}
          >
            <TextField
              label="E-mail"
              type="email"
              placeholder="E-mail"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              id="email"
              name="email"
            />
            <TextField
              label={t('customers:phone')}
              type="phone"
              placeholder={t('customers:phone')}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              id="phone"
              name="phone"
            />
          </Box>
          <TextField
            label={t('customers:address')}
            placeholder={t('customers:address')}
            fullWidth
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            id="address"
            name="address"
          />
        </Box>
        <Box>
          <Typography mb={1} color="inherit">
            Logo
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Button color="primary" onClick={handleButtonClick} variant="contained">
              {initialValues.id ? t('_common:update_image') : t('_common:upload_image')}
            </Button>
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: 'none' }}
              accept="image/png, image/jpeg"
              onChange={(e) => {
                formik.handleChange(e);
                handleFileSelect(e);
              }}
              name="file"
              id="file"
              data-testid="logo"
            />
            <Typography variant="body2" color="inherit">
              {t(logoHelperText, {
                max: '100Kb',
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          {formik.values.file && formik.values.file.name && (
            <Avatar
              sx={(theme) => ({
                flex: '0 0 auto',
                width: '125px',
                height: '80px',
              })}
              variant="rounded"
            >
              <img
                style={{
                  borderRadius: '2px',
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                }}
                src={handleLoadSRC(formik.values.file)}
                alt={formik.values.file.name}
              />
            </Avatar>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          flex: '0 0 auto',
          padding: '20px',
          textAlign: 'end',
        }}
      >
        {showDeleteButton && excludePermission && (
          <Form.DeleteButton
            isSubmitting={formik.isSubmitting}
            confirmModalDescription={t('company:company_will_no_longer_displayed')}
            confirmModalTitle={t('company:want_delete_company', {
              name: formik.values.companyName,
            })}
            onConfirmDelete={async (setIsDeleting) => {
              try {
                if (!doDelete) return;
                if (!initialValues.id) return;
                await doDelete(initialValues.id);
                setIsDeleting(false);
                history.push(query.get('returnRoute') || routes.settings.companies.root());
              } catch (error) {
                console.error(error);
                setIsDeleting(false);
              }
            }}
          />
        )}
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => {
            history.push(query.get('returnRoute') || routes.settings.companies.root());
          }}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          values={formik.values}
          disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          label={t('crud_actions:save')}
        />
      </Box>
    </form>
  );
}
