import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PlaybackSpeedSelect({
  setPlaybackRate,
  playbackRate,
}: {
  setPlaybackRate: (newValue: number) => void;
  playbackRate: number;
}) {
  const { t } = useTranslation('video');
  return (
    <Select
      value={playbackRate}
      onChange={(e) => setPlaybackRate(e.target.value as number)}
      variant="outlined"
      title={t('video:playback_speed')}
      size="small"
    >
      <MenuItem value={0.25}>0.25</MenuItem>
      <MenuItem value={0.5}>0.5</MenuItem>
      <MenuItem value={0.75}>0.75</MenuItem>
      <MenuItem value={1}>Normal</MenuItem>
      <MenuItem value={1.25}>1.25</MenuItem>
      <MenuItem value={1.5}>1.50</MenuItem>
      <MenuItem value={1.75}>1.75</MenuItem>
      <MenuItem value={2}>2</MenuItem>
    </Select>
  );
}

export default PlaybackSpeedSelect;
