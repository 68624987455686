import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function RestartButton({
  onClick,
  disabled,
  seconds,
}: {
  onClick: () => void;
  disabled: boolean;
  seconds: number;
}) {
  const { t } = useTranslation();

  return (
    <Button variant="text" color="primary" sx={{ mt: 2 }} onClick={onClick} disabled={disabled}>
      {seconds > 0
        ? t('speed_test:restart_test_in', {
            seconds,
          })
        : t('speed_test:restart_test')}
    </Button>
  );
}
