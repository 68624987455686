import React from 'react';
import { Socket } from 'socket.io-client';

interface Props {
  socket: Socket | null;
  masterCompanyId?: number;
}

function useJoinRoom({ socket, masterCompanyId }: Props) {
  React.useEffect(() => {
    const joinRoom = () => {
      if (socket && masterCompanyId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        socket.emit('joinRoom', masterCompanyId, (response: any) => {
          if (response?.status === 'success') {
            // eslint-disable-next-line no-console
            console.info(`Joined room ${masterCompanyId}`);
          } else {
            console.error(`Failed to join room ${masterCompanyId}`);
          }
        });
      }
    };

    joinRoom();

    // Cleanup function
    return () => {
      if (socket && masterCompanyId) {
        socket.off('joinRoom');
      }
    };
  }, [socket, masterCompanyId]);

  return null;
}

export default useJoinRoom;
