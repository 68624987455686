import { useEffect, useState } from 'react';

export const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // following this nomenclature: https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints
  const sm = width <= 767;
  const md = width <= 1023;
  const lg = width > 1024;
  const xl = width > 1492;
  const xl2 = width > 1843;

  return {
    sm,
    md,
    lg,
    xl,
    xl2,
  };
};
