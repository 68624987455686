import { atom } from 'recoil';

const PREFIX = 'selectCustomer-';

export const textDebouncedAtom = atom<string>({
  key: `${PREFIX}textDebouncedAtom`,
  default: '',
});

export const selectedAtom = atom<number | null>({
  key: `${PREFIX}selected`,
  default: null,
});