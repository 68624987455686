import React from 'react';
import Axios from 'axios';

function useCallStartLiveBefore(customerId: number) {
  const callStartLive = React.useCallback(async () => {
    Axios.post(`/v1/clients/${customerId}/startLive`);
  }, [customerId]);

  React.useEffect(
    function call() {
      callStartLive();
    },
    [callStartLive]
  );
}

export default useCallStartLiveBefore;
