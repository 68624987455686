import React, { Suspense } from 'react';
import { Block } from 'components/core';
import { CircularProgress } from '@mui/material';
import ErrorBoundary from 'components/ErrorBoundary';
import { ErrorBoundaryFallbackProps } from 'components/ErrorBoundary/ErrorBoundary';

function DefaultLoading() {
  return (
    <Block open>
      <CircularProgress />
    </Block>
  );
}

function ApiBoundary({
  children,
  fallbackLoading = <DefaultLoading />,
  fallbackError,
  onError,
}: {
  fallbackLoading?: NonNullable<React.ReactNode> | null;
  fallbackError?: React.ReactNode | ((props?: ErrorBoundaryFallbackProps) => React.ReactNode);
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;
}) {
  return (
    <ErrorBoundary fallback={fallbackError} {...{ onError }}>
      <Suspense fallback={fallbackLoading}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default ApiBoundary;
