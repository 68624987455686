import { Box, Slide } from '@mui/material';
import ApiBoundary from 'components/ApiBoundary';
import ErrorBoundary from 'components/ErrorBoundary';
import routes, { mosaicViewContext } from 'helpers/routes';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Axios from 'axios';
import MosaicCreateChannel from './MosaicCreateChannel';
import MosaicCreateCustomer from './MosaicCreateCustomer';
import MosaicViewPage from './MosaicViewPage';

function MainMosaicViewPage() {
  const { mosaicId } = useParams<{ mosaicId: string }>();

  const history = useHistory();
  const matchFullScreen = useRouteMatch<{ mosaicId: string }>('/mosaics/:mosaicId/fullscreen');

  const match = useRouteMatch<{
    mosaicId: string;
    slotId: string;
    customerId: string;
  }>(mosaicViewContext);

  React.useEffect(
    function callStartLive() {
      // @ts-ignore
      if (history.location.state?.startDate) return;
      Axios.post(`/v1/mosaics/${Number(mosaicId)}/startLive`);
    },
    [mosaicId, history.location.state]
  );

  if (matchFullScreen) {
    return null;
  }

  if (!match) {
    history.push(routes.mosaic.root);
  }
  
  return (
    <ErrorBoundary>
      <Box
        overflow="auto"
        flex="1 1 auto"
        display={match?.path === routes.mosaic.view(':mosaicId') ? 'flex' : 'none'}
      >
        <ApiBoundary>
          <MosaicViewPage mosaicId={Number(mosaicId)} />
        </ApiBoundary>
      </Box>
      <Slide
        direction="left"
        in={match?.path === routes.mosaic.createChannel(':mosaicId', ':slotId', ':customerId')}
        unmountOnExit
        exit={false}
      >
        <Box overflow="auto" flex="1 1 auto" display="flex">
          <ApiBoundary>
            <MosaicCreateChannel
              mosaicId={Number(mosaicId)}
              customerId={Number(match?.params.customerId)}
              slotId={Number(match?.params.slotId)}
            />
          </ApiBoundary>
        </Box>
      </Slide>
      <Slide
        direction="left"
        in={match?.path === routes.mosaic.createCustomer(':mosaicId', ':slotId')}
        unmountOnExit
        exit={false}
      >
        <Box overflow="auto" flex="1 1 auto" display="flex">
          <ApiBoundary>
            <MosaicCreateCustomer
              mosaicId={Number(mosaicId)}
              slotId={Number(match?.params.slotId)}
            />
          </ApiBoundary>
        </Box>
      </Slide>
    </ErrorBoundary>
  );
}

export default MainMosaicViewPage;
