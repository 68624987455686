import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ChannelSimple } from 'types/mosaics.types';
import { exportVideoModalOpenedAtom } from '../../atoms/exportVideo';
import ExportVideoForm from '../ExportVideoForm/ExportVideoForm';

function ExportVideoModal({
  channel,
  mosaicId = null,
  nowLiveDate,
}: {
  channel: ChannelSimple;
  mosaicId?: number | null;
  nowLiveDate: Date;
}) {
  const [open, setOpen] = useRecoilState(exportVideoModalOpenedAtom);
  const { t } = useTranslation(['video_export', 'forms', '_common']);
  return (
    <Dialog
      {...{ open }}
      onClose={() => setOpen(false)}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{t('video_export')}</DialogTitle>

      <DialogContent>
        <ExportVideoForm {...{ open, setOpen, channel, mosaicId, nowLiveDate }} />
      </DialogContent>
    </Dialog>
  );
}

export default ExportVideoModal;
