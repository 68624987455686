import { userInfoSelector } from 'atoms/auth';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ReactGA from 'react-ga';
import routes from 'services/routes';

function GATracker() {
  const routeMatch = useRouteMatch({
    path: routes.map((r) => r.path).flat(),
    exact: true,
  });

  const userInfo = useRecoilValue(userInfoSelector);

  useEffect(() => {
    if (process.env.REACT_APP_GA && userInfo) {
      ReactGA.initialize(process.env.REACT_APP_GA, {
        gaOptions: {
          userId: userInfo.email,
        },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!routeMatch) {
      return;
    }

    ReactGA.pageview(routeMatch.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMatch, routeMatch?.path]);
  return null;
}

export default GATracker;
