import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SelectCompanyTypeValue } from 'types/companies.types';
import { useRecoilValue } from 'recoil';
import { loggedUserAtom } from 'atoms/users';

interface SelectVinculatedCompaniesProps {
  selectedCompany?: SelectCompanyTypeValue | null;
  onChangeSelectCompany: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlurSelectCompany?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
}

export default function SelectCompaniesVinculatedByLoggedUser({
  selectedCompany,
  onChangeSelectCompany,
  onBlurSelectCompany,
  error,
}: SelectVinculatedCompaniesProps) {
  const loggedUser = useRecoilValue(loggedUserAtom);
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-companies-label">{t('company:company')}</InputLabel>
      <Select
        labelId="select-companies-label"
        id="company"
        name="company"
        error={error}
        onChange={(e) =>
          onChangeSelectCompany({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: {
                id: Number(e.target.value),
                companyName:
                  loggedUser?.companies?.find((company) => company.id === Number(e.target.value))
                    ?.companyName || '',
              },
            },
          })
        }
        onBlur={onBlurSelectCompany}
        value={selectedCompany ? selectedCompany.id : ''}
        label={t('company:company')}
      >
        {loggedUser?.companies?.map((company) => (
          <MenuItem aria-label="item" key={company.id} value={company.id}>
            {company.companyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
