import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  customerSearchTextAtom,
  normalizeCustomer,
} from 'atoms/customers';
import { useFetch } from 'hooks';
import { asyncComponent } from 'HOCs';
import routes from 'helpers/routes';
import {
  CustomerSquare,
  CustomerList,
  CustomerListLoading,
  EmptyCustomer,
} from 'components/Customer';
import {
  Customer as CustomerInterface
} from 'types/customer.types'
import { Container } from './CustomerPage.styles';

function Customer() {
  const text = useRecoilValue(customerSearchTextAtom);
  const { data: customers } = useFetch<CustomerInterface[]>('/v1/clients', {
    params: { text },
    normalizeData: (_customers) => _customers.map(normalizeCustomer),
  });

  return (
    <Container>
      {!text && !customers?.length ? (
        <EmptyCustomer route={routes.customer.create()} />
      ) : (
        <CustomerList {...{ customers }} />
      )}

      <CustomerSquare {...{ customers }} />
    </Container>
  );
}

export default asyncComponent(
  Customer,
  <Container>
    <CustomerListLoading />
  </Container>
);
