/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import useCloudflareSpeedTest from 'hooks/speedTest/useCloudflareSpeedTest';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'hooks';
import ProgressSpeedTestView from './components/ProgressSpeedTestView/ProgressSpeedTestView';
import FeedbackSpeedTestView from './components/FeedbackNetworkPerformanceView/FeedbackSpeedTestView';
import CancelButton from './components/CancelButton/CancelButton';
import StartButton from './components/StartButton/StartButton';
import RestartButton from './components/RestartButton/RestartButton';
import { SpeedTestDTO } from './types/speedTest.types';

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function SpeedTestView({
  onSave,
}: {
  onSave: (resultSpeedTest: SpeedTestDTO) => Promise<void>;
}) {
  const { t } = useTranslation();
  const { enqueueError } = useMessages();
  const [startedTest, setStartedTest] = React.useState(false);
  const [waitTime, setWaitTime] = React.useState(0);

  const {
    error,
    onPause,
    onRestart,
    onStart,
    result,
    isLoading,
    labelStatus,
    isFinished,
    isRunning,
  } = useCloudflareSpeedTest();

  useEffect(
    function saveResultInAPI() {
      if (isFinished && result) {
        onSave(result);
        // should wait 10 seconds to test again
        setWaitTime(10);
      }
    },
    [isFinished, onSave, result]
  );

  useEffect(
    function handleWaitTime() {
      const timeoutId = setTimeout(() => {
        if (!waitTime) return;
        setWaitTime((_waitTime) => _waitTime - 1);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [waitTime]
  );

  useEffect(
    function handleError() {
      if (error) enqueueError(t('speed_test:error_in_testing'));
    },
    [enqueueError, error, t]
  );

  return (
    <Container>
      <NetworkCheckIcon fontSize="large" color="disabled" />

      {!isFinished ? (
        <ProgressSpeedTestView isRunning={isRunning} tagLabel={labelStatus} />
      ) : (
        result && (
          <FeedbackSpeedTestView classificationIdx={result.classification.classificationIdx} />
        )
      )}

      {isLoading ? (
        <CancelButton
          onClick={() => {
            onPause();
            setWaitTime(5);
          }}
          disabled={labelStatus === 'upload'}
        />
      ) : startedTest ? (
        <RestartButton disabled={isLoading || !!waitTime} seconds={waitTime} onClick={onRestart} />
      ) : (
        <StartButton
          disabled={isLoading}
          onClick={() => {
            setStartedTest(true);
            onStart();
          }}
        />
      )}
    </Container>
  );
}
