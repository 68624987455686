import React from 'react';
import { User } from 'types/user.types';
import { useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch, useUserCRUD } from 'hooks';
import UserForm from './UserForm';

function UserEdit() {
  const { userId } = useParams<{ userId: string }>();

  const { data: userInEdition } = useFetch<User>(`/v1/users/${userId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    cleanCacheAfterMs: 1500,
  });

  const { editUser, deleteUser } = useUserCRUD();

  return (
    <UserForm
      initialValues={userInEdition as User}
      doSubmit={editUser}
      showDeleteButton
      doDelete={deleteUser}
    />
  );
}

export default asyncComponent(UserEdit);
