import React, { useCallback, useEffect, useRef } from 'react';
import { useKeyPress, useMeasure } from 'react-use';
import styled from 'styled-components';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { NUMBER_COL, NUMBER_ROWS } from 'helpers/mosaicTemplates';
import { Box } from '@mui/material';
import { useFetch, useMosaicStartLive } from 'hooks';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mosaicItensInitialDataAtom, mosaicItensValuesAtom } from 'atoms/mosaicItems';
import { ChannelSimple, Mosaic } from 'types/mosaics.types';
import { VideoLiveSource } from 'types/channels.types';
import VideoSlot from 'components/core/VideoSlot';
import routes from 'helpers/routes';

const MosaicViewContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  height: 100%;
`;

function MosaicView({
  mosaic,
  orderSelected,
  handleLayoutChange,
  currentPage,
  removeChannelOption = false,
}: {
  mosaic: Mosaic;
  orderSelected: number | null;
  currentPage: number;
  handleLayoutChange?: (layout: GridLayout.Layout[]) => void;
  removeChannelOption?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [setMeasureRef, { width, height }] = useMeasure();
  const videosLiveSource = useMosaicStartLive({ mosaicId: mosaic.id });
  const [mosaicItensValues, setMosaicItensValues] = useRecoilState(
    mosaicItensValuesAtom(mosaic.id)
  );
  const setMosaicItensInitialData = useSetRecoilState(mosaicItensInitialDataAtom(mosaic.id));

  const { data: mosaicItensApi } = useFetch(`/v1/mosaics/${mosaic.id}/items`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(
    function setDataFromApi() {
      setMosaicItensInitialData(mosaicItensApi);
      setMosaicItensValues(mosaicItensApi);
    },
    [mosaicItensApi, setMosaicItensInitialData, setMosaicItensValues]
  );

  useEffect(
    function bridgeMeasureRef() {
      if (containerRef.current) setMeasureRef(containerRef.current);
    },
    [setMeasureRef]
  );

  const [isPressingAlt] = useKeyPress('Alt');

  const getItem = useCallback(
    (order: number) => mosaicItensValues.find((item) => item.order === order),
    [mosaicItensValues]
  );
  const payload = React.useMemo(
    () =>
      mosaic.mosaicData.payload
        ? mosaic.mosaicData.payload
        : mosaic.mosaicData.pages?.find((pagina) => pagina.offset === currentPage)?.payload,
    [currentPage, mosaic.mosaicData.pages, mosaic.mosaicData.payload]
  );

  return (
    <MosaicViewContainer ref={containerRef}>
      <GridLayout
        className="layout"
        layout={payload}
        cols={NUMBER_COL}
        width={width}
        rowHeight={(height - (NUMBER_ROWS + 1) * 4) / NUMBER_ROWS}
        margin={[4, 4]}
        onLayoutChange={(layout) => handleLayoutChange && handleLayoutChange(layout)}
        isDraggable={!isPressingAlt}
        isResizable={!isPressingAlt}
      >
        {payload?.map((itemLayout) => (
          <Box key={itemLayout.i} display="flex">
            <MosaicViewItem
              removeChannelOption={removeChannelOption}
              key={itemLayout.i}
              channel={getItem(Number(itemLayout.i))?.channel}
              order={Number(itemLayout.i)}
              selected={orderSelected === Number(itemLayout.i)}
              mosaicId={mosaic.id}
              mosaicName={mosaic.name}
              videosLiveSource={videosLiveSource}
              visible={getItem(Number(itemLayout.i))?.visible ?? true}
              limitTransmissionBlocked={
                getItem(Number(itemLayout.i))?.limitTransmissionBlocked ?? false
              }
            />
          </Box>
        ))}
      </GridLayout>
    </MosaicViewContainer>
  );
}

function MosaicViewItem({
  channel,
  videosLiveSource,
  order,
  mosaicId,
  selected,
  mosaicName,
  visible,
  removeChannelOption = false,
  limitTransmissionBlocked,
}: {
  channel?: ChannelSimple;
  videosLiveSource: VideoLiveSource[];
  order: number;
  mosaicId: number;
  mosaicName: string;
  selected: boolean;
  visible: boolean;
  removeChannelOption?: boolean;
  limitTransmissionBlocked: boolean;
}) {
  const videoLiveSource = videosLiveSource.find((vl) => vl.channelId === channel?.id);
  const channelWithSource = channel ? { channel, videoLiveSource } : undefined;
  const isChannelInative = channelWithSource?.channel && !channelWithSource.channel.status;

  if (channelWithSource?.channel && !channelWithSource?.channel.clientStatus)
    return (
      <VideoSlot.CustomerInactive
        removeChannelOption={removeChannelOption}
        mosaicId={mosaicId}
        order={order}
        returnRoute={routes.mosaic.view(mosaicId)}
        channel={channel as ChannelSimple}
      />
    );
  if (channelWithSource && !visible) return <VideoSlot.Hidden />;
  if (isChannelInative)
    return (
      <VideoSlot.Inactive
        channel={channel as ChannelSimple}
        order={order}
        mosaicId={mosaicId}
        returnRoute={routes.mosaic.view(mosaicId)}
      />
    );

  return (
    <VideoSlot.View
      key={`${mosaicId} - ${channel?.id}`}
      {...{
        order,
        mosaicId,
        channelWithSource,
        selected,
        mosaicName,
        limitTransmissionBlocked,
      }}
    />
  );
}

export default MosaicView;
