import Axios from 'axios';
import { ZoomTransform } from 'd3';
import mergeRecordsChannelsService from 'services/mergeRecordsChannels.service';
import { atom, atomFamily, selectorFamily } from 'recoil';
import { nonNullish } from 'helpers/helper';
import {
  ApiChannelEvent,
  ApiRecord,
  Record,
  ChannelEvent,
  RecordRequest,
} from 'types/playback.types';
import { mosaicItemWidescreenSelector, mosaicItensValuesAtom } from './mosaicItems';

export const TIME_PLAYBACK_MINUTES = 40;

type PlaybackKey = { mosaicId: number | null; channelId: number };
type PlaybackMergedKey = { mosaicId: number | null; channelsId: number[] };

/**
 * Records by channel
 */

export const recordsAtom = atomFamily<Record[] | null, number>({
  key: 'mosaic.playback.records',
  default: null,
});

/**
 * Dates records by channel
 */

export const datesRecordsAtom = atomFamily<Date[] | null, number>({
  key: 'mosaic.playback.datesRecordsAtom',
  default: null,
});

/**
 * Events by channel
 */

export const eventsAtom = atomFamily<ChannelEvent[] | null, number>({
  key: 'mosaic.playback.events',
  default: null,
});

/**
 * Recording requests by channel
 */

export const recordingRequestsAtom = atomFamily<RecordRequest[] | null, number>({
  key: 'mosaic.playback.recordingRequests',
  default: null,
});

export const playbackSourceStartDateAtom = atomFamily<string | null, number>({
  key: 'playbackSourceStartDate',
  default: null,
});

/**
 * Item's start date that user clicked in cronologicBar
 * */
export const playbackStartDateDataBarSelector = selectorFamily<string | null, PlaybackKey>({
  key: 'mosaic.playback.startDateDataBar',
  get: ({ channelId, mosaicId }) => ({ get }) => {
    const dateRecords = get(datesRecordsAtom(channelId));
    const selectedDate = get(selectedDateAtom({ channelId, mosaicId }));
    if (!selectedDate) {
      return null;
    }
    const sourceStartDate = get(playbackSourceStartDateAtom(channelId));
    const date = sourceStartDate ? new Date(sourceStartDate) : selectedDate;
    if (!dateRecords) {
      return null;
    }
    const selectedDateRecord =
      [...dateRecords]
        .sort((a, b) => Number(a) - Number(b))
        .filter((dateRecord) => dateRecord <= date)
        .reverse()[0] || null;
    return selectedDateRecord && selectedDateRecord.toISOString();
  },
});

/**
 * Date which user select in cronologicBar or in DateTimePicker
 */
export const selectedDateAtom = atomFamily<Date | null, PlaybackKey>({
  key: 'mosaic.playback.selectedDate',
  default: null,
});

export const pausedAtom = atomFamily<boolean, number>({
  key: 'mosaic.playback.paused',
  default: false,
});

export const mutedAtom = atomFamily<boolean, number>({
  key: 'mosaic.playback.muted',
  default: true,
});

/**
 * If the channel's video is live or not
 */
export const liveSelector = selectorFamily<boolean, PlaybackKey>({
  key: 'mosaic.playback.live',
  get: (key) => ({ get }) => !get(selectedDateAtom(key)),
  set: ({ mosaicId, channelId }) => ({ set }, newValue) => {
    if (!newValue) {
      console.error(
        'liveSelector não deve ser setado para false, para ativar o modo playback deve ser atualizado o selectedDateAtom'
      );
      return;
    }
    set(selectedDateAtom({ channelId, mosaicId }), null);
    set(playbackSourceStartDateAtom(channelId), null);
  },
});

/**
 * Date which user select in DateTimePicker, only way to att the range in cronologicbar
 */
export const selectedDateInPickerAtom = atom<Date | null>({
  key: 'mosaic.playback.selectedDateInPickerAtom',
  default: null,
});

/**
 * Channel's video current time in seconds which is updated by player
 */
export const currentTimeAtom = atomFamily<number, number>({
  key: 'mosaic.playback.currentTime',
  default: 0,
});

export const playbackRateAtom = atomFamily<number, number>({
  key: 'mosaic.playback.playbackRateAtom',
  default: 1,
});

export const actualFowardRewindSecondsAtom = atom<number | null>({
  key: 'mosaic.playback.actualFowardRewindSecondsAtom',
  default: null,
});

export const lastFowardRewindClickAtom = atomFamily<Date | null, number>({
  key: 'mosaic.playback.lastFowardRewindClickAtom',
  default: null,
});

export const startDateChannelBarAtom = atomFamily<Date, number>({
  key: 'mosaic.playback.startDateChannelBarAtom',
  default: new Date(),
});

export const endDateChannelBarAtom = atomFamily<Date, number>({
  key: 'mosaic.playback.endDateChannelBarAtom',
  default: new Date(),
});

export const currentZoomChannelBarAtom = atomFamily<ZoomTransform | undefined, number>({
  key: 'mosaic.playback.currentZoomChannelBarAtom',
  default: undefined,
});

/**
 * Date which starts the range in cronologicBar's merged channels
 */
export const startDateMergedSelector = selectorFamily<Date, number[]>({
  key: 'mosaic.playback.startDateMerged',
  get: (channelsId) => ({ get }) => {
    // Deve pegar a menor data inicial dos canais
    const dates = channelsId
      .map((id) => Number(get(startDateChannelBarAtom(id))))
      .sort((a, b) => a - b);
    return dates && dates.length > 0 ? new Date(dates[0]) : new Date();
  },
});

/**
 * Date which end the range in cronologicBar's merged channel
 */
export const endDateMergedSelector = selectorFamily<Date, number[]>({
  key: 'mosaic.playback.endDateMerged',
  get: (channelsId) => ({ get }) => {
    // Deve pegar a maior data final dos canais
    const dates = channelsId
      .map((id) => Number(get(endDateChannelBarAtom(id))))
      .sort((a, b) => a - b);
    return dates && dates.length > 0 ? new Date(dates[dates.length - 1]) : new Date();
  },
});

/**
 *
 * @param channelsId
 * Merge the records of the channels to mount the merged bar
 */
export const mergedRecordsSelector = selectorFamily<Record[] | null, number[]>({
  key: 'mosaic.playback.mergedRecords',
  get: (channelsId) => ({ get }) => {
    const allRecords = channelsId
      .map((id) => get(recordsAtom(id)))
      .flat()
      .filter(nonNullish);

    return mergeRecordsChannelsService(allRecords);
  },
});

/**
 *
 * @param channelsId
 * Merge the events of the channels to mount the merged bar
 */
export const mergedEventsSelector = selectorFamily<ChannelEvent[] | null, number[]>({
  key: 'mosaic.playback.mergedEvents',
  get: (channelsId) => ({ get }) =>
    channelsId
      .map((id) => get(eventsAtom(id)))
      .flat()
      .filter(nonNullish),
});

/**
 *
 * @param channelsId
 * Merge the recording requests of the channels to mount the merged bar
 */
export const mergedRecordingRequestsSelector = selectorFamily<RecordRequest[] | null, number[]>({
  key: 'mosaic.playback.mergedRecordingRequests',
  get: (channelsId) => ({ get }) =>
    channelsId
      .map((id) => get(recordingRequestsAtom(id)))
      .flat()
      .filter(nonNullish),
});

export const mergedSelectedDateSelecor = selectorFamily<Date | null, PlaybackMergedKey>({
  key: 'mosaic.playback.mergedSelectedDate',
  get: ({ channelsId, mosaicId }) => ({ get }) =>
    // teoricamente neste caso sempre serão iguais...
    get(selectedDateAtom({ mosaicId, channelId: channelsId[0] })),
  set: ({ channelsId, mosaicId }) => ({ set }, newValue) => {
    channelsId.forEach((channelId) => set(selectedDateAtom({ mosaicId, channelId }), newValue));
  },
});

export const mergedPausedSelector = selectorFamily<boolean, number[]>({
  key: 'mosaic.playback.mergedPaused',
  get: (channelsId) => ({ get }) =>
    // Caso tenha algum canal pausado, deve mostrar o estado pausado para a merged control bar
    channelsId.map((channelId) => get(pausedAtom(channelId))).some(Boolean),
  set: (channelsId) => ({ set }, newValue) => {
    channelsId.forEach((channelId) => set(pausedAtom(channelId), newValue));
  },
});

export const mergedMutedSelector = selectorFamily<boolean, number[]>({
  key: 'mosaic.playback.mergedMuted',
  get: (channelsId) => ({ get }) =>
    // Caso tenha algum canal não mutado, deve mostrar o estado não mutado para a merged control bar
    !channelsId
      .map((channelId) => get(mutedAtom(channelId)))
      .map((muted) => !muted)
      .some(Boolean),
  set: (channelsId) => ({ set }, newValue) => {
    channelsId.forEach((channelId) => set(mutedAtom(channelId), newValue));
  },
});

export const mergedLiveSelector = selectorFamily<boolean, PlaybackMergedKey>({
  key: 'mosaic.playback.mergedLive',
  get: ({ channelsId, mosaicId }) => ({ get }) =>
    // Caso tenha algum canal em playback, deve mostrar o watch live
    channelsId
      .map((channelId) => get(liveSelector({ channelId, mosaicId: null })))
      .some((live) => Boolean(!live)),
  set: ({ channelsId, mosaicId }) => ({ set }, newValue) => {
    if (!newValue) {
      console.error(
        'mergedLiveSelector não deve ser setado para false, para ativar o modo playback deve ser atualizado o selectedDateAtom'
      );
      return;
    }
    channelsId.forEach((channelId) => set(selectedDateAtom({ channelId, mosaicId }), null));
    channelsId.forEach((channelId) => set(playbackSourceStartDateAtom(channelId), null));
  },
});

type WidescreenSelector = {
  mosaicId: number;
  channelsId: number[];
};

export const mergedWidescreenSelector = selectorFamily<boolean, WidescreenSelector>({
  key: 'mosaic.playback.mergedWidescreen',
  get: ({ mosaicId, channelsId }) => ({ get }) =>
    // Caso tenha algum canal com widescreen ativado, deve mostrar o estado com widescreen ativado para a merged control bar
    channelsId
      .map((channelId) => get(mosaicItemWidescreenSelector({ channelId, mosaicId })))
      .some(Boolean),
  set: ({ mosaicId, channelsId }) => ({ set }, newValue) => {
    set(mosaicItensValuesAtom(mosaicId), (mosaicItens) =>
      mosaicItens.map((item) =>
        channelsId.includes(item.channel.id) ? { ...item, widescreen: !!newValue } : item
      )
    );
  },
});

/**
 *
 * @param record
 * @returns
 */
export const normalizeRecord = (record: Record): Record => ({
  ...record,
  startDate: new Date(record.startDate),
  endDate: new Date(record.endDate),
});

export const normalizeRecordingRequest = (recordingRequest: RecordRequest): RecordRequest => ({
  ...recordingRequest,
  date: new Date(recordingRequest.date),
});

export const normalizeRecordApiData = (apiData: ApiRecord): ApiRecord => ({
  ...apiData,
  data: apiData.data.map(normalizeRecord),
  recordingRequests: apiData.recordingRequests.map(normalizeRecordingRequest),
  startDates: apiData.startDates.map((date) => new Date(date)),
});

export const normalizeEvent = (event: ChannelEvent) => ({
  ...event,
  date: new Date(event.date),
});

export const normalizeEventApiData = (apiData: ApiChannelEvent) => ({
  ...apiData,
  data: apiData.data.map(normalizeEvent),
});

export const getRecordedDataInDate = async (channelId: number, date: Date): Promise<ApiRecord> => {
  const record = await Axios.get<ApiRecord>(`/v1/channels/${channelId}/records/optimized`, {
    params: {
      startDate: date.toISOString(),
      endDate: date.toISOString(),
    },
  });

  return normalizeRecordApiData(record.data);
};
