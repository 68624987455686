import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { customerGroupInEditionAtom } from 'atoms/customerGroup';
import CustomerGroupForm from '../CustomerGroupForm/CustomerGroupForm';

function CustomerGroupCreateModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(['customer_groups', 'crud_actions']);

  const customerGroupInEdition = useRecoilValue(customerGroupInEditionAtom);

  const handleClose = useRecoilCallback(({ set }) => () => {
    setOpen(false);
    setTimeout(() => set(customerGroupInEditionAtom, null), 150);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{customerGroupInEdition ? t('customer_groups:edit_customer_group') : t('customer_groups:create_customer_group')}</DialogTitle>
      <DialogContent>
        <CustomerGroupForm {...{ setOpen, customerGroupInEdition }} />
      </DialogContent>
    </Dialog>
  );
}

export default CustomerGroupCreateModal;
