import React from 'react';
import { Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { NewSelectStoragePlan } from './SelectStoragePlan';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  title: {
    background: theme.palette.common.black,
    color: theme.palette.text.primary,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    textAlign: 'center',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function BoxStoragePlan() {
  const classes = useStyles();
  const { t } = useTranslation('metrics');

  return (
    <Paper className={classes.container}>
      <Typography className={classes.title}>{t('storage')}</Typography>
      <NewSelectStoragePlan />
    </Paper>
  );
}
