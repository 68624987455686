/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import {} from 'atoms/mosaicItems';
import ErrorBoundary from 'components/ErrorBoundary';
import { ExportVideoModal } from 'components/ExportVideo';
import {
  ChannelControlsBar as V2ChannelControlsBar,
  ChannelCronologicBar as V2ChannelCronologicBar,
  VideoWithPlayback as V2VideoWithPlayback,
} from 'pages/MosaicViewPage/V2BottomPanel';
import { asyncComponent } from 'HOCs';
import { useChannelStartLive, useFetch } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import { normalizeChannel } from 'hooks/channels/useChannelsApi';
import EditIcon from '@mui/icons-material/Edit';
import { Channel } from 'types/channels.types';
import { BIG, EXTRA_BIG, MEDIUM } from 'components/MosaicVideo/responsive.service';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useMeasure } from 'react-use';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import routes from 'helpers/routes';

function PlaybackViewPage() {
  const { t } = useTranslation('channels');
  const [nowLiveDate] = useState(new Date());
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const { data: channelUnformated } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    normalizeData: normalizeChannel,
  });
  const history = useHistory();
  const channel = useMemo(
    () =>
      channelUnformated && {
        ...channelUnformated,
        clientCompanyName: channelUnformated.client.companyName,
        clientTradeName: channelUnformated.client.tradeName,
        clientId: channelUnformated.client.id,
        clientStatus: channelUnformated.client.status,
      },
    [channelUnformated]
  );

  if (!channel) {
    return (
      <NotFound
        title={t('non_exixstent_channel')}
        reason={t('channel_does_not_exist_or_may_have_been_deleted')}
        shouldDo={t('register_a_new_channel_or_select_another_channel_to_be_displayed')}
      />
    );
  }

  if (!channel.client.status)
    return (
      <Box
        display="flex"
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        ref={measureRef}
      >
        <Stack
          alignItems="center"
          direction={BIG.lower(measures) ? 'row' : 'column'}
          spacing={BIG.lower(measures) ? 1 : 0}
          display="flex"
          flexDirection="column"
          mb={5}
        >
          <PersonOffIcon
            sx={{
              fontSize: 99,
            }}
            color="action"
          />
          <Typography
            align="center"
            variant={EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'}
          >
            {t('customers:inactive')}
          </Typography>
        </Stack>
        <Box mb={1}>
          <Typography
            variant={
              EXTRA_BIG.lower(measures)
                ? BIG.lower(measures)
                  ? MEDIUM.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle2'
                : 'subtitle1'
            }
            display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
            align="center"
          >
            {t('customers:inactive_description')}
          </Typography>
          <Typography align="center" variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {t('mosaic:inactive_description')}
          </Typography>
          <Box width="100%" mt={4}>
            <Box flex="1 1 auto" textAlign="center">
              <Permissioned role={PermissionRole.EDIT_CLIENT}>
                <IconButton
                  aria-label={`remove channel ${channel.name}`}
                  onClick={() => history.push(routes.customer.edit(channel.clientId))}
                  size="small"
                  title={t('video:active_customer')}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Permissioned>
            </Box>
          </Box>
        </Box>
      </Box>
    );

  if (!channel.status)
    return (
      <Box
        display="flex"
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        ref={measureRef}
      >
        <Stack
          alignItems="center"
          direction={BIG.lower(measures) ? 'row' : 'column'}
          spacing={BIG.lower(measures) ? 1 : 0}
          display="flex"
          flexDirection="column"
          mb={5}
        >
          <VisibilityOffIcon
            sx={{
              fontSize: 99,
            }}
            color="action"
          />
          <Typography
            align="center"
            variant={EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'}
          >
            {t('mosaic:slot_inative')}
          </Typography>
        </Stack>
        <Box mb={1}>
          <Typography
            variant={
              EXTRA_BIG.lower(measures)
                ? BIG.lower(measures)
                  ? MEDIUM.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle2'
                : 'subtitle1'
            }
            display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
            align="center"
          >
            {t('channels:inactive_view')}
          </Typography>
        </Box>
      </Box>
    );

  return <PlaybackView {...{ channel, nowLiveDate }} />;
}

// Componente para quando o canal existe
function PlaybackView({
  channel,
  nowLiveDate,
}: {
  channel: Channel & {
    clientCompanyName: string;
    clientTradeName: string;
    clientId: number;
    clientStatus: boolean;
  };
  nowLiveDate: Date;
}) {
  const channelId = channel.id;
  const videoLiveSource = useChannelStartLive({ channelId });
  // const [newMosaicDesign] = useDecision(FEATURE_FLAGS_KEYS.NEW_MOSAIC_DESIGN);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ErrorBoundary>
        <Box flex="1 1 auto" display="flex" height="100%">
          {/* {newMosaicDesign?.enabled ? ( */}
          <V2VideoWithPlayback {...{ channel, videoLiveSource }} />
          {/* ) : (
            <VideoWithPlayback {...{ channel, videoLiveSource }} />
          )} */}
        </Box>
      </ErrorBoundary>
      <Paper>
        {/* {newMosaicDesign?.enabled ? ( */}
        <V2ChannelCronologicBar {...{ nowLiveDate, channelId }} />
        {/* ) : (
          <ChannelCronologicBar {...{ nowLiveDate, channelId }} />
        )} */}
      </Paper>
      {/* {newMosaicDesign?.enabled ? ( */}
      <V2ChannelControlsBar
        {...{
          nowLiveDate,
          channelId,
        }}
      />
      {/* ) : (
        <ChannelControlsBar
          {...{
            nowLiveDate,
            channelId,
          }}
        />
      )} */}
      <ExportVideoModal {...{ nowLiveDate, channel }} />
    </Box>
  );
}

export default asyncComponent(PlaybackViewPage);
