import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Settings(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1231 12.936C19.1591 12.636 19.1831 12.324 19.1831 12C19.1831 11.676 19.1591 11.364 19.1111 11.064L21.1391 9.48002C21.3191 9.33602 21.3671 9.07202 21.2591 8.86802L19.3391 5.54402C19.2191 5.32802 18.9671 5.25602 18.7511 5.32802L16.3631 6.28802C15.8591 5.90402 15.3311 5.59202 14.7431 5.35202L14.3831 2.80802C14.3471 2.56802 14.1431 2.40002 13.9031 2.40002H10.0631C9.82314 2.40002 9.63114 2.56802 9.59514 2.80802L9.23514 5.35202C8.64714 5.59202 8.10714 5.91602 7.61514 6.28802L5.22714 5.32802C5.01114 5.24402 4.75914 5.32802 4.63914 5.54402L2.71914 8.86802C2.59914 9.08402 2.64714 9.33602 2.83914 9.48002L4.86714 11.064C4.81914 11.364 4.78314 11.688 4.78314 12C4.78314 12.312 4.80714 12.636 4.85514 12.936L2.82714 14.52C2.64714 14.664 2.59914 14.928 2.70714 15.132L4.62714 18.456C4.74714 18.672 4.99914 18.744 5.21514 18.672L7.60314 17.712C8.10714 18.096 8.63514 18.408 9.22314 18.648L9.58314 21.192C9.63114 21.432 9.82314 21.6 10.0631 21.6H13.9031C14.1431 21.6 14.3471 21.432 14.3711 21.192L14.7311 18.648C15.3191 18.408 15.8591 18.084 16.3511 17.712L18.7391 18.672C18.9551 18.756 19.2071 18.672 19.3271 18.456L21.2471 15.132C21.3671 14.916 21.3191 14.664 21.1271 14.52L19.1231 12.936ZM11.9831 15.6C10.0031 15.6 8.38314 13.98 8.38314 12C8.38314 10.02 10.0031 8.40002 11.9831 8.40002C13.9631 8.40002 15.5831 10.02 15.5831 12C15.5831 13.98 13.9631 15.6 11.9831 15.6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Settings;
