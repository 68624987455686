import React from 'react';
import { Box, styled } from '@mui/material';
import TableEvent from './TableEvent';
import EventFilters from './EventFilters';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

function EventPage() {
  return (
    <React.Suspense fallback>
      <Container role="region" aria-label="Página de eventos">
        <EventFilters />
        <TableEvent />
      </Container>
    </React.Suspense>
  );
}

export default EventPage;
