import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelGroup } from 'types/channelGroup.types';
import { FilterSelectValue } from 'types/select.types';

function SelectChannelGroupToFilters({
  selectedChannelGroups,
  onChangeChannelGroups,
}: {
  selectedChannelGroups: FilterSelectValue[];
  onChangeChannelGroups: (value: FilterSelectValue[]) => void;
}) {
  const { data: channelGroups } = useFetch<ChannelGroup[]>('/v1/channelGroups');
  const { t } = useTranslation('channel_groups');

  useEffect(
    function setLabels() {
      if (selectedChannelGroups && channelGroups) {
        onChangeChannelGroups(
          selectedChannelGroups.map((value) => ({
            id: value.id,
            label: channelGroups.find((channelGroup) => channelGroup.id === value.id)?.name || '',
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelGroups]
  );

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-channel-group-label">{t('channel_group')}</InputLabel>
      <Select
        labelId="select-channel-group-label"
        id="select-channel-group"
        label={t('channel_group')}
        onChange={(e) =>
          onChangeChannelGroups(
            (e.target.value as number[]).map((id) => ({
              id,
              label: channelGroups?.find((channelGroup) => channelGroup.id === id)?.name || '',
            }))
          )
        }
        value={selectedChannelGroups.map((value) => value.id)}
        multiple
        renderValue={(selected) =>
          (selected)
            .map((id) => channelGroups?.find((customer) => customer.id === id)?.name)
            .join(', ')
        }
      >
        {channelGroups?.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selectedChannelGroups.some((value) => value.id === id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectChannelGroupToFilters.Loading = function SelectChannelGroupFilterLoading() {
  const { t } = useTranslation('channel_groups');
  return <SelectFetchLoading label={t('channel_groups:channel_group')} />;
};

export default SelectChannelGroupToFilters;
