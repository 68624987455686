import React, { Suspense } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useFetch } from 'hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.375rem',
    marginRight: theme.spacing(1),
  },
}));

interface ChannelsRecordingTotal {
  totalChannel: number;
  onlineChannel: number;
  offlineChannel: number;
}

interface ChannelsRecordingTotalNormalized {
  name: string;
  total: number;
  color: string;
}

function ChannelsMonitoringTopBar() {
  const { t } = useTranslation('channels_monitoring');
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h4">
        {t('channels_monitoring')?.toUpperCase()}
      </Typography>
      <Suspense fallback={null}>
        <ChannelsMonitoringPie />
      </Suspense>
    </Box>
  );
}

function ChannelsMonitoringPie() {
  const { t } = useTranslation('channels_monitoring');
  const theme = useTheme();
  const { data: channelsTotal } = useFetch<
    ChannelsRecordingTotal,
    ChannelsRecordingTotalNormalized[]
  >('/v1/channels/recording/total', {
    normalizeData: (data) => [
      { name: t('recording'), total: data.onlineChannel, color: theme.palette.success.main },
      { name: t('not_recording'), total: data.offlineChannel, color: theme.palette.error.main },
    ],
  });
  return (
    <ResponsiveContainer width={240} height={50}>
      <PieChart height={50} width={50}>
        <Pie
          dataKey="total"
          data={channelsTotal}
          cx={25}
          innerRadius="50%"
          outerRadius="100%"
          paddingAngle={5}
        >
          {channelsTotal?.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={entry.color} stroke="0" />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align="right"
          iconType="circle"
          iconSize={8}
          formatter={(value, entry) => (
            <Typography variant="body2" display="inline">{`${value} (${
              channelsTotal?.find((ct) => ct.name === value)?.total || 0
            })`}</Typography>
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChannelsMonitoringTopBar;
