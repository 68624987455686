import React from 'react';
import { Typography, CardContent, ListItem, CardActionArea, Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useDrag, DragObjectWithType } from 'react-dnd';
import { ItemTypes, ChannelDropItem } from 'helpers/dragAndDrop';
import { ChannelSimple } from 'types/mosaics.types';
import { Lock } from '@mui/icons-material';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { useTranslation } from 'react-i18next';
import { Card } from './ItemsCard.styles';

const useStyles = makeStyles({
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export interface Props {
  channel: ChannelSimple & { channelPrivate: boolean };
  onClick: React.MouseEventHandler;
  disabled?: boolean;
  to?: string;
}

interface PropsLoading {
  loading: true;
}
function ChannelCard(props: React.PropsWithChildren<PropsLoading>): React.ReactElement | null;
function ChannelCard(props: React.PropsWithChildren<Props>): React.ReactElement | null;
function ChannelCard({
  channel,
  loading,
  onClick,
  disabled,
  to,
}: {
  loading?: boolean;
  disabled?: boolean;
  channel?: ChannelSimple & { channelPrivate: boolean };
  to?: string;
  onClick?: React.MouseEventHandler;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, dragRef] = useDrag<ChannelDropItem | DragObjectWithType, void, void>({
    item: { payload: channel, type: ItemTypes.CHANNEL },
    canDrag: channel && !loading && !disabled && !channel.channelPrivate,
  });
  const listItemProps = loading || disabled ? {} : { component: to && Link, to, onClick };

  return (
    <ListItem {...listItemProps} {...{ disabled }}>
      <Card>
        <CardActionArea {...{ disabled }} ref={!loading ? dragRef : undefined}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Typography variant="subtitle1">{loading ? <Skeleton /> : channel?.name}</Typography>
              <Typography variant="body2" color="textPrimary">
                {loading ? <Skeleton /> : `ID ${String(channel?.id).padStart(6, '0')}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {loading ? (
                  <Skeleton />
                ) : (
                  `${channel?.clientTradeName} (${channel?.clientCompanyName})`
                )}
              </Typography>
            </Box>
            {!loading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {!channel?.status && (
                  <Tooltip title={t('mosaic:slot_inative') || ''}>
                    <VideocamOffIcon />
                  </Tooltip>
                )}
                {channel?.channelPrivate && (
                  <Tooltip title={t('channels:private_channel') || ''}>
                    <Lock />
                  </Tooltip>
                )}
              </Box>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </ListItem>
  );
}

export default ChannelCard;
