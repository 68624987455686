import React from 'react';
import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReleaseIcon } from 'components/core/Icons';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import ItemContainer from './ItemContainer';

const useStyles = makeStyles({
  adjustBadge: {
    '& .rnw-count': {
      right: '17px !important',
      top: '7px !important',
    },
  },
});

const ReleaseNoteItem: React.FC = () => {
  const { t } = useTranslation('main-left-menu');
  const classes = useStyles();
  const [releaseNotesFlag] = useDecision(FEATURE_FLAGS_KEYS.RELEASE_NOTES_CONTROL);

  const renderChild = (
    <ItemContainer id="menu-release-notes-item" text={t('release_notes')} onClick={() => {}}>
      <ReleaseIcon fontSize="small" />
    </ItemContainer>
  );

  if (releaseNotesFlag.enabled)
    return (
      <Link
        href="https://segware-vms-cloud.releasenotes.io"
        target="_blanc"
        className={classes.adjustBadge}
      >
        {renderChild}
      </Link>
    );

  return <Box className={classes.adjustBadge}>{renderChild}</Box>;
};

export default ReleaseNoteItem;
