import React from 'react';
import { Paper, TableRow, TableCell, Box, Typography, IconButton, Collapse } from '@mui/material';
import routes from 'helpers/routes';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import Table from 'components/Table';
import { Column } from 'types/table.types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ConfirmModal } from 'components/core';
import {
  RecordingScheduleListProps,
  CustomerFromSchedule,
  RecordingScheduleWithOutScheduling,
  RecordingSchedule,
} from 'types/recordingSchedule.types';
import { useRecordingScheduleCRUD } from './useRecordingScheduleCRUD';

function useColumns({
  t,
}: {
  t: TFunction<Namespace>;
}): Column<RecordingScheduleWithOutScheduling>[] {
  const transformCustomerData = React.useCallback((value: CustomerFromSchedule[]) => {
    const customerNameLimitPerRow = 10;

    return value
      .slice(0, customerNameLimitPerRow)
      .map(({ client }) => client.tradeName)
      .sort()
      .join(', ');
  }, []);

  return React.useMemo(
    () =>
      [
        {
          description: t('_common:schedule_name'),
          dataKey: 'name',
        },
        {
          description: t('customers:customers'),
          dataKey: 'channelsWithClient',
          renderValue: transformCustomerData,
        },
        {
          description: '',
          dataKey: 'id',
        },
      ] as Column<RecordingScheduleWithOutScheduling>[],
    [t, transformCustomerData]
  );
}

export function RecordingScheduleTable({ schedules }: RecordingScheduleListProps) {
  const { t } = useTranslation();
  const [openRowIndex, setOpenRowIndex] = React.useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const columns = useColumns({ t });
  if (!schedules?.length) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('_common:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        flex: '1 1 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Table
        dataLength={schedules.length}
        tableName={t('_common:recording_schedules')}
        {...{ columns, page, setPage, rowsPerPage, setRowsPerPage }}
      >
        {schedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
            }}
            aria-label={`recording schedule row id:${row.id}`}
            hover
          >
            {columns.map((column) => (
              <TableCell
                key={column.dataKey}
                aria-label={column.description}
                sx={{
                  width: column.dataKey === 'name' ? '30%' : '100%',
                }}
              >
                <TableRecordingScheduleCell
                  {...{
                    row,
                    column,
                    openRowIndex,
                    setOpenRowIndex,
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Paper>
  );
}
interface CellProps {
  row: RecordingSchedule;
  column: Column<RecordingScheduleWithOutScheduling>;
  openRowIndex: number;
  setOpenRowIndex: React.Dispatch<React.SetStateAction<number>>;
}

function TableRecordingScheduleCell({ row, column, openRowIndex, setOpenRowIndex }: CellProps) {
  const history = useHistory();
  const { deleteSchedule } = useRecordingScheduleCRUD();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteConfirmOpened, setDeleteConfirmOpened] = React.useState(false);

  const handleDeleteClick = React.useCallback(() => {
    setDeleteConfirmOpened(true);
  }, []);
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        setIsDeleting(true);
        await deleteSchedule(id);
        setDeleteConfirmOpened(false);
        setIsDeleting(false);
      } catch (error) {
        console.error(error);
        setIsDeleting(false);
      }
    },
    [deleteSchedule]
  );

  const handleEdit = React.useCallback(
    (id: number) => {
      history.push(routes.settings.recordingSchedule.edit(id));
    },
    [history]
  );

  const renderCustomersColumn = () => {
    const customerNameLimitPerRow = 10;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box onClick={() => setOpenRowIndex(openRowIndex === row.id ? -1 : row.id)}>
          <Box>
            {
              // @ts-ignore
              column.renderValue ? column.renderValue(row[column.dataKey]) : row[column.dataKey]
            }
          </Box>
          <Collapse key={row.id} in={openRowIndex === row.id} timeout="auto">
            {row.channelsWithClient
              .slice(customerNameLimitPerRow)
              .map((customer) => customer.client.tradeName)
              .sort()
              .join(', ')}
          </Collapse>
        </Box>

        {row.channelsWithClient.length > customerNameLimitPerRow && (
          <IconButton
            aria-label="expand row"
            onClick={() => setOpenRowIndex(openRowIndex === row.id ? -1 : row.id)}
            sx={{
              marginLeft: 2,
            }}
          >
            {openRowIndex === row.id ? (
              <KeyboardArrowUpIcon fontSize="medium" />
            ) : (
              <KeyboardArrowDownIcon fontSize="medium" />
            )}
          </IconButton>
        )}
      </Box>
    );
  };

  const renderIdColumn = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '1rem',
      }}
    >
      <IconButton onClick={() => handleEdit(row.id)} aria-label="edit" size="small">
        <Edit />
      </IconButton>
      <IconButton onClick={() => handleDeleteClick()} aria-label="delete" size="small">
        <Delete />
      </IconButton>
    </Box>
  );

  const renderColumn = () => {
    // TODO: melhorar o render do componente Table para permitir componente customizado e uma actions para cada linha

    if (column.dataKey === 'channelsWithClient') {
      return renderCustomersColumn();
    }
    if (column.dataKey === 'id') {
      return renderIdColumn();
    }
    return column.renderValue ? column.renderValue(row[column.dataKey]) : row[column.dataKey];
  };

  return (
    <Box>
      {renderColumn()}
      <ConfirmModal
        open={deleteConfirmOpened}
        setOpen={setDeleteConfirmOpened}
        doConfirm={() => handleDelete(row.id)}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        title={t('recording_schedule:delete_confirm_title', {
          name: row.name,
        })}
        description={t('recording_schedule:schedule_will_no_longer_displayed')}
      />
    </Box>
  );
}
