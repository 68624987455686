import React from 'react';
import {
  Box,
  FormControlLabel,
  IconButton,
  Link,
  styled,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { AddCircle, Fullscreen, List, Delete } from '@mui/icons-material';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { addMosaicToPlaylistModalOpenedAtom, itemAtualAtom } from 'atoms/playlist';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { viewVideoDetailsAtom } from 'atoms/mosaics';
import routes from 'helpers/routes';
import { Link as RouteLink, useHistory, useParams } from 'react-router-dom';
import { sigmaIntegrationActivateAtom } from 'atoms/sigmaIntegration';
import { useFetch } from 'hooks';
import { Playlist } from 'types/playlist';
import { asyncComponent } from 'HOCs';
import { ConfirmModal } from 'components/core';
import decodeEntities from 'helpers/decodeEntities';
import { useLeftPanel } from 'hooks/useLeftPanel';
import { useHavePermission } from 'hooks/useHavePermission';
import { usePlaylistApi } from 'hooks/playlists/usePlaylistApi';

function PlaylistPlayTopBar() {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { setPlaylistDetailsActual, isPlaylistDetailsActual } = useLeftPanel();
  const setOpen = useSetRecoilState(addMosaicToPlaylistModalOpenedAtom);
  const { t } = useTranslation('_common');
  const [viewVideoDetails, setViewVideoDetails] = useRecoilState(viewVideoDetailsAtom);
  const [sigmaIntegrationActivate, setSigmaIntegrationActivate] = useRecoilState(
    sigmaIntegrationActivateAtom
  );
  const itemAtual = useRecoilValue(itemAtualAtom);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false);
  const [idInDeletion, setIdInDeletion] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { deletePlaylist } = usePlaylistApi();
  const history = useHistory();

  const { data: playlist } = useFetch<Playlist>(`/v1/playlists/${Number(playlistId)}`, {
    normalizeData: (_playlist) => {
      const newArr = [..._playlist.items];

      return {
        ..._playlist,
        items: newArr.sort((a, b) => a.order - b.order),
      };
    },
  });

  const handleDelete = async () => {
    setIsDeleting(true);
    await deletePlaylist(idInDeletion);
    setDeleteConfirmModalOpen(false);
    setIsDeleting(false);
    history.push(routes.playlists.root());
  };

  const handlePutOnDeletion = (id: number) => {
    setIdInDeletion(id);
    setDeleteConfirmModalOpen(true);
  };

  if (!playlist) {
    return null;
  }

  return (
    <>
      <Box role="region" aria-label="Playlist top bar" display="flex" alignItems="center">
        <IconButton
          size="small"
          aria-label="Playlist details"
          onClick={() => setPlaylistDetailsActual(!isPlaylistDetailsActual)}
        >
          <List />
        </IconButton>
        <Box marginRight="16px" marginLeft="16px">
          <PlaylistTitle {...{ playlist }} />
          <Typography>{itemAtual?.mosaic.name}</Typography>
        </Box>
        <Permissioned role={PermissionRole.EDIT_PLAYLIST}>
          <IconButton size="small" aria-label="add mosaic" onClick={() => setOpen(true)}>
            <AddCircle />
          </IconButton>
        </Permissioned>
        <Permissioned role={PermissionRole.REMOVE_PLAYLIST}>
          <Box marginLeft="8px" marginRight="8px">
            <IconButton
              size="small"
              aria-label="delete playlist"
              onClick={() => handlePutOnDeletion(playlist.id)}
            >
              <Delete />
            </IconButton>
          </Box>
        </Permissioned>
        <FormControlLabel
          control={<Switch />}
          labelPlacement="end"
          label={t('_common:view_channel_technical_details')}
          onChange={(e, checked) => setViewVideoDetails(checked)}
          checked={viewVideoDetails}
        />
        <Box marginLeft="auto" marginRight="8px">
          <FormControlLabel
            control={<Switch />}
            labelPlacement="start"
            label={t('mosaics:synchronized_view_with_SIGMA_on_this_tab')}
            onChange={(e, checked) => setSigmaIntegrationActivate(checked)}
            checked={sigmaIntegrationActivate}
          />
        </Box>
        <Link component={RouteLink} to={routes.playlists.fullscreen(playlistId)}>
          <IconButton aria-label="fullscreen" color="default" size="small">
            <Fullscreen />
          </IconButton>
        </Link>
      </Box>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        description=""
        title={t('playlists:want_delete')}
      />
    </>
  );
}

const Title = styled(Typography)({
  cursor: 'text',
  outline: 'none',
  marginRight: '8px',
});

function PlaylistTitle({ playlist }: { playlist: Playlist }) {
  const { t } = useTranslation('_common');
  const { updatePlaylist } = usePlaylistApi();
  const havePermissionFn = useHavePermission();
  const canEditPlaylist = havePermissionFn(PermissionRole.EDIT_PLAYLIST);

  const handleBlurTitle: React.FocusEventHandler = (e) => {
    const text = decodeEntities(e.currentTarget.innerHTML);
    text && text.trim()
      ? updatePlaylist(playlist.id, { ...playlist, name: text })
      : (e.target.innerHTML = playlist.name);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const text = decodeEntities(e.currentTarget.innerHTML);
      text && text.trim()
        ? updatePlaylist(playlist.id, { ...playlist, name: text })
        : (e.currentTarget.innerHTML = playlist.name);
    }
  };

  const handlePaste: React.ClipboardEventHandler = (e) => {
    e.preventDefault();
    let text = '';
    if (e.clipboardData) {
      text = e.clipboardData.getData('text/plain');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  };
  return (
    <Tooltip title={canEditPlaylist ? t('click_to_change') : ''} placement="bottom-start">
      <Title
        variant="h6"
        contentEditable={canEditPlaylist}
        spellCheck={false}
        suppressContentEditableWarning={true}
        onBlur={handleBlurTitle}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      >
        {playlist.name}
      </Title>
    </Tooltip>
  );
}

export default asyncComponent(PlaylistPlayTopBar, null);
