import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { channelGroupInEditionAtom } from 'atoms/channelGroup';
import ChannelGroupForm from '../ChannelGroupForm/ChannelGroupForm';

function ChannelGroupCreateModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const channelGroupInEdition = useRecoilValue(channelGroupInEditionAtom)
  const { t } = useTranslation(['channel_groups', 'crud_actions']);

  const handleClose = useRecoilCallback(({ set }) => () => {
    setOpen(false);
    setTimeout(() => set(channelGroupInEditionAtom, null), 150);
  });


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-modal-title">{channelGroupInEdition ? t('channel_groups:update_channel_group') : t('channel_groups:create_channel_group')}</DialogTitle>
      <DialogContent>
        <ChannelGroupForm {...{ setOpen, channelGroupInEdition }} />
      </DialogContent>
    </Dialog>
  );
}

export default ChannelGroupCreateModal;
