import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ErrorSVG(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 162.802 139.544" {...props}>
      <g transform="translate(-879 -300)">
        <path
          d="M130.577,66.45ZM24.257,26.58a13.3,13.3,0,0,0-13.29,13.29v73.095a13.127,13.127,0,0,0,1.849,6.645H4.322A3.323,3.323,0,0,0,1,122.932a16.663,16.663,0,0,0,16.612,16.613H143.867a16.663,16.663,0,0,0,16.612-16.612,3.323,3.323,0,0,0-3.322-3.322h-8.495a13.129,13.129,0,0,0,1.849-6.645V67.715a39.664,39.664,0,0,1-6.645,3.063v42.187a6.652,6.652,0,0,1-6.645,6.645H24.257a6.652,6.652,0,0,1-6.645-6.645V39.87a6.652,6.652,0,0,1,6.645-6.645h66.45a39.822,39.822,0,0,1,.6-6.645ZM9.014,126.254H152.465c-1.452,3.7-4.359,6.645-8.6,6.645H17.612C13.373,132.9,10.466,129.951,9.014,126.254Z"
          transform="translate(878 300)"
          fill="currentColor"
          opacity="0.5"
        />
        <path
          d="M130.577,0A33.225,33.225,0,1,0,163.8,33.225,33.323,33.323,0,0,0,130.577,0Zm0,6.645A26.58,26.58,0,1,1,104,33.225,26.658,26.658,0,0,1,130.577,6.645Zm-2.661,9.967a.71.71,0,0,0-.662.662V35.885a.71.71,0,0,0,.662.662h5.321a.71.71,0,0,0,.662-.662V17.274a.71.71,0,0,0-.662-.662Zm0,26.58a.71.71,0,0,0-.662.662v5.321a.71.71,0,0,0,.662.662h5.321a.71.71,0,0,0,.662-.662V43.854a.71.71,0,0,0-.662-.662Z"
          transform="translate(878 300)"
          fill="#d32f2f"
        />
      </g>
    </SvgIcon>
  );
}

export default ErrorSVG;
