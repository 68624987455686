import React, { Suspense, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { useYupObject } from 'hooks';
import HelpIcon from '@mui/icons-material/Help';
import { Form } from 'components/core';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { PermissionRole } from 'types/user.types';
import SelectClientType from 'components/SelectClientType/SelectClientType';
import { SelectCompanies, SelectCompaniesVinculatedByLoggedUser } from 'components/SelectCompanies';
import { loggedUserAtom } from 'atoms/users';
import { useRecoilValue } from 'recoil';
import { SelectCompanyTypeValue } from 'types/companies.types';
import { ClientType, Customer, CustomerInitialValues } from 'types/customer.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { useFormStyles } from '../core/Form';
import useQuery from '../../hooks/useQuery';
import SelectState from '../SelectState/SelectState';
import SelectClientGroup from '../SelectClientGroup/SelectClientGroup';

function CustomerForm({
  initialValues,
  doSubmit,
  showDeleteButton,
  doDelete,
  onSubmitSuccess,
}: {
  initialValues: CustomerInitialValues | Customer;
  doSubmit: (customer: Customer) => Promise<number>;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  onSubmitSuccess?: (customer: Customer) => void;
}) {
  const history = useHistory();
  const { t } = useTranslation(['customers', '_common', 'users']);
  const yup = useYupObject();
  const classesForm = useFormStyles({});
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const query = useQuery();
  const [tempoDeToleranciaFlag] = useDecision(FEATURE_FLAGS_KEYS.TEMPO_DE_TOLERANCIA_PARA_FALHAS);

  const validationSchema = yup.object({
    status: yup.boolean(),
    address: yup.string().trim().required(),
    addressNumber: yup.number().required().positive(),
    addressComplement: yup.string().nullable(),
    addressReference: yup.string().nullable(),
    companyName: yup.string().trim().required().noSpecialCharacters().min(3).max(150),
    tradeName: yup.string().trim().required().noSpecialCharacters().min(3).max(150),
    district: yup.string().trim().required(),
    city: yup.string().trim().required(),
    zipcode: yup
      .string()
      .required()
      .matches(/^\d+$/, t('forms:zipcode_only_numbers'))
      .min(
        7,
        t('forms:zipcode_min_digits', {
          min: 7,
        })
      )
      .max(8, t('forms:cep_max_digits', { max: 8 })),
    phone: yup
      .string()
      .required()
      .matches(/^\d+$/, t('forms:tel_only_numbers'))
      .min(
        10,
        t('forms:tel_min_digits', {
          max: 10,
        })
      )
      .max(11, t('forms:tel_max_digits', { max: 11 })),
    phone2: yup
      .string()
      .nullable()
      .matches(/^\d+$/, t('forms:tel_only_numbers'))
      .min(
        10,
        t('forms:tel_min_digits', {
          max: 10,
        })
      )
      .max(11, t('forms:tel_max_digits', { max: 11 })),
    state: yup.string().trim().required(),
    email: yup.string().email().nullable(),
    clientType: yup.string().oneOf(Object.values(ClientType)).required(),
    clientGroup: yup
      .object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
      .required(),
    companies: yup.object({
      id: yup.number(),
      companyName: yup.string(),
    }),
    enableFailTolerance: yup.bool(),
    failToleranceTime: yup.number().when('enableFailTolerance', {
      is: (enabledTolerance) => enabledTolerance && tempoDeToleranciaFlag.enabled,
      then: yup
        .number()
        .max(
          99999,
          t('forms:max_size', {
            max: 99999,
          })
        )
        .min(1)
        .integer()
        .positive(),
      otherwise: yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const transformedValues = {
          ...values,
          company: {
            id: values.company?.id,
            companyName: values.company?.companyName,
          },
        };

        const id = await doSubmit(transformedValues as Customer);
        setSubmitting(false);
        onSubmitSuccess && onSubmitSuccess({ ...transformedValues, id } as Customer);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.id) {
      Object.keys(formik.values).forEach((key) => {
        formik.setFieldTouched(key, true, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formik.values.enableFailTolerance && !formik.values.id) {
      formik.setFieldValue('failToleranceTime', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.enableFailTolerance]);

  return (
    <form aria-label="customer" className={classesForm.form} onSubmit={formik.handleSubmit}>
      <Box className={classesForm.formInputsContainer}>
        <Box>
          <Typography variant="h6" className={classesForm.formInputsGroupLabel}>
            {t('customer:information')}
          </Typography>
        </Box>

        <FormControlLabel
          className={classesForm.formInput}
          control={
            <Switch
              color="primary"
              id="status"
              name="status"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.status}
            />
          }
          label={t(formik.values.status ? '_common:active' : '_common:inactive')}
        />

        <TextField
          className={classesForm.formInput}
          fullWidth
          id="tradeName"
          name="tradeName"
          label={t('trade_name')}
          placeholder={t('trade_name')}
          autoFocus
          value={formik.values.tradeName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.tradeName && Boolean(formik.errors.tradeName)}
          helperText={formik.touched.tradeName && formik.errors.tradeName}
        />
        <TextField
          className={classesForm.formInput}
          fullWidth
          id="companyName"
          name="companyName"
          label={t('company_name')}
          placeholder={t('company_name')}
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.companyName && Boolean(formik.errors.companyName)}
          helperText={formik.touched.companyName && formik.errors.companyName}
        />
        <Box display="inline-flex" width="100%" mb={2}>
          <Box width="50%">
            <SelectClientGroup
              error={formik.touched.clientGroup && Boolean(formik.errors.clientGroup)}
              // @ts-ignore
              helperText={formik.touched.clientGroup ? formik.errors.clientGroup?.id : ''}
              selectedClientGroup={formik.values.clientGroup || null}
              onChangeClientGroup={formik.handleChange}
              onBlurClientGroup={formik.handleBlur}
            />
          </Box>
          <Box className={classesForm.inputMarginLeft} width="50%">
            <SelectClientType
              error={formik.touched.clientType && Boolean(formik.errors.clientType)}
              helperText={formik.touched.clientType ? formik.errors.clientType : ''}
              selectedClientType={formik.values.clientType || null}
              onChangeSelectClientType={formik.handleChange}
              onBlurSelectClientType={formik.handleBlur}
            />
          </Box>
        </Box>
        <Box display="inline-flex" width="100%" gap={2}>
          <Box width="50%">
            <RuleToRenderSelectCompanies
              selectedCompany={formik.values.company}
              onChangeSelectCompany={formik.handleChange}
              onBlurSelectCompany={formik.handleBlur}
              error={formik.touched.company && Boolean(formik.errors.company)}
            />
          </Box>
          <Box width="50%" />
        </Box>
        {tempoDeToleranciaFlag.enabled && (
          <>
            <Box mt={6}>
              <Typography variant="h6" className={classesForm.formInputsGroupLabel}>
                {t('customer:fail_tolerance')}
              </Typography>
            </Box>

            <Box>
              <FormControl
                className={classesForm.formInput}
                error={
                  formik.touched.enableFailTolerance && Boolean(formik.errors.enableFailTolerance)
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        id="enableFailTolerance"
                        name="enableFailTolerance"
                        checked={formik.values.enableFailTolerance}
                        onClick={formik.handleChange}
                        disabled={formik.isSubmitting}
                      />
                    }
                    label={t('channels:enable_fail_tolerance')}
                  />
                  <Tooltip title={t('customer:enable_fail_tolerance_checkbox')}>
                    <HelpIcon fontSize="small" color="action" />
                  </Tooltip>
                </Box>
                <FormHelperText>
                  {formik.touched.enableFailTolerance && formik.errors.enableFailTolerance}
                </FormHelperText>
              </FormControl>
              <Box display="flex" width="100%" gap={2}>
                <Box width="50%">
                  {formik.values.enableFailTolerance && (
                    <TextField
                      className={classesForm.formInput}
                      id="failToleranceTime"
                      name="failToleranceTime"
                      fullWidth
                      type="number"
                      label={t('customer:failToleranceTime')}
                      placeholder={t('customer:failToleranceTime')}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Se o valor exceder 5 dígitos, trunca-o
                        if (value.length <= 5) {
                          formik.handleChange(e);
                        } else {
                          // Mantém o valor atual, mas não o altera no estado do Formik
                          formik.setFieldValue('failToleranceTime', value.slice(0, 5));
                        }
                      }}
                      value={formik.values.failToleranceTime}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.failToleranceTime && Boolean(formik.errors.failToleranceTime)
                      }
                      helperText={
                        formik.touched.failToleranceTime && formik.errors.failToleranceTime
                      }
                      sx={{
                        pb: 4,
                      }}
                    />
                  )}
                </Box>
                <Box width="50%" />
              </Box>
            </Box>
          </>
        )}
        <Box mt={6}>
          <Typography className={classesForm.formInputsGroupLabel} variant="h6">
            {t('contacts')}
          </Typography>
        </Box>
        <Box display="inline-flex" width="100%">
          <Box width="50%">
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="phone"
              name="phone"
              label={t('phone')}
              placeholder={t('inform_phone')}
              helperText={
                (formik.touched.phone && formik.errors.phone) ||
                t('customers:enter_DDD_before_phone')
              }
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </Box>
          <Box className={classesForm.inputMarginLeft} width="50%">
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="phone2"
              name="phone2"
              label={`${t('phone')} 2 (${t('customers:optional')})`}
              placeholder={t('inform_phone2')}
              helperText={
                (formik.touched.phone2 && formik.errors.phone2) || t('enter_DDD_before_phone')
              }
              value={formik.values.phone2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
            />
          </Box>
        </Box>
        <TextField
          className={classesForm.formInput}
          fullWidth
          id="email"
          name="email"
          label={t('email')}
          placeholder={t('inform_email')}
          helperText={(formik.touched.email && formik.errors.email) || t('enter_valid_email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        <Box className={classesForm.BoxWithMarginTop}>
          <Typography className={classesForm.formInputsGroupLabel} variant="h6">
            {t('address')}
          </Typography>
        </Box>
        <TextField
          className={classesForm.formInput}
          fullWidth
          id="zipcode"
          name="zipcode"
          label={t('zipCode')}
          placeholder={t('inform_zipCode')}
          value={formik.values.zipcode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
          helperText={formik.touched.zipcode && formik.errors.zipcode}
        />
        <TextField
          className={classesForm.formInput}
          fullWidth
          id="address"
          name="address"
          label={t('publicPlace')}
          placeholder={t('inform_publicPlace')}
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />
        <Box display="inline-flex" width="100%">
          <Box width="8%">
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="addressNumber"
              name="addressNumber"
              label={t('number')}
              value={formik.values.addressNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressNumber && Boolean(formik.errors.addressNumber)}
              helperText={formik.touched.addressNumber && formik.errors.addressNumber}
            />
          </Box>
          <Box width="92%" className={classesForm.inputMarginLeft}>
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="addressComplement"
              name="addressComplement"
              label={`${t('complement')} (${t('customers:optional')})`}
              placeholder={t('inform_complement')}
              value={formik.values.addressComplement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressComplement && Boolean(formik.errors.addressComplement)}
            />
          </Box>
        </Box>
        <TextField
          className={classesForm.formInput}
          fullWidth
          id="addressReference"
          name="addressReference"
          label={`${t('reference')} (${t('customers:optional')})`}
          placeholder={t('proximity')}
          value={formik.values.addressReference}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressReference && Boolean(formik.errors.addressReference)}
          helperText={formik.touched.addressReference && formik.errors.addressReference}
        />
        <Box display="inline-flex" width="100%">
          <Box width="33.33%">
            <SelectState
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state ? formik.errors.state : ''}
              selectedState={formik.values.state}
              onChangeSelectState={formik.handleChange}
              onBlurSelectState={formik.handleBlur}
            />
          </Box>
          <Box width="33.33%" className={classesForm.inputMarginLeft}>
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="city"
              name="city"
              label={t('city')}
              placeholder={t('city')}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Box>
          <Box width="33.33%" className={classesForm.inputMarginLeft}>
            <TextField
              className={classesForm.formInput}
              fullWidth
              id="district"
              name="district"
              label={t('district')}
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.district && Boolean(formik.errors.district)}
              helperText={formik.touched.district && formik.errors.district}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={classesForm.formFooter}>
        {showDeleteButton && excludePermission && (
          <Form.DeleteButton
            isSubmitting={formik.isSubmitting}
            confirmModalDescription={t('not_possible_retrieve_information_and_channels_customer')}
            confirmModalTitle={t('want_delete')}
            onConfirmDelete={async (setIsDeleting) => {
              try {
                if (!doDelete) return;
                if (!initialValues.id) return;
                await doDelete(initialValues.id);
                setIsDeleting(false);
                history.push(query.get('returnRoute') || routes.customer.root());
              } catch (error) {
                console.error(error);
                setIsDeleting(false);
              }
            }}
          />
        )}
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => history.push(query.get('returnRoute') || routes.customer.root())}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          values={formik.values}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        />
      </Box>
    </form>
  );
}

interface CompaniesDataProps {
  selectedCompany?: SelectCompanyTypeValue | null;
  onChangeSelectCompany: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlurSelectCompany?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
}

function RuleToRenderSelectCompanies(props: CompaniesDataProps) {
  // TODO: E se tivessemos 1 componente para lista de companias com uma prop para filtrar as companias vinculadas ao usuário logado?
  const loggedUser = useRecoilValue(loggedUserAtom);

  const haveCompaniesVinculated = loggedUser?.companies?.length;

  if (!loggedUser?.enablePermissionCompany)
    return (
      <Suspense fallback={<SelectCompanies.Loading />}>
        <SelectCompanies {...props} />
      </Suspense>
    );

  if (loggedUser?.enablePermissionCompany && haveCompaniesVinculated)
    return <SelectCompaniesVinculatedByLoggedUser {...props} />;

  return null;
}

export default CustomerForm;
