import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function RTMPCopyModal({
  open,
  setOpen,
  url,
  onRedirect,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
  onRedirect: () => void;
}) {
  const { t } = useTranslation();
  const [helperText, setHelperText] = React.useState('');

  const handleClose = () => {
    setOpen(false);
    onRedirect();
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setHelperText('');
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="rtmp-copy-modal"
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
        },
      }}
    >
      <DialogTitle id="rtmp-copy-modal">{t('channels:link_was_created')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('channels:rtmp_modal_description')}</DialogContentText>
      </DialogContent>

      <DialogContent>
        <TextField
          fullWidth
          id="url"
          name="url"
          label="URL RTMP"
          disabled
          value={url}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  setHelperText(t('channels:copied'));
                }}
                position="end"
                sx={{
                  cursor: 'pointer',
                }}
              >
                <ContentCopyIcon />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ml: 3,
              mb: 1,
            }}
          >
            <DialogContentText align="left">{helperText}</DialogContentText>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(url);
                setHelperText(t('channels:copied'));
              }}
            >
              {t('_common:copy_url')}
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                onRedirect();
              }}
              color="error"
              aria-label="close"
            >
              {t('_common:close')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
