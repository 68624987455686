import { Box, Chip, IconButton, Menu, MenuItem, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MoreVert } from '@mui/icons-material';
import { DateRangeValue } from 'components/DateRange';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    chipContainer: {
      flex: '1 1 auto',
    },
    chip: {
      margin: theme.spacing(1),
    },
    moreButton: {
      flex: '0 0 auto',
      margin: 'auto',
    },
  })
);

function RecordingsAppliedFilters({
  customer,
  handleChangeCustomer,
  channels,
  setChannels,
  range,
  setRange,
}: {
  customer: FilterSelectValue | null;
  handleChangeCustomer: (newValue: FilterSelectValue | null) => void;
  channels: FilterSelectValue[];
  setChannels: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  range: DateRangeValue | null;
  setRange: (value: DateRangeValue | null) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation('_common');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearAll = () => {
    setAnchorEl(null);
    handleChangeCustomer(null);
    setRange(null);
    setChannels([]);
  };

  if (!customer) return null;

  return (
    <Paper className={classes.root} role="region" aria-label="Filtros aplicados">
      <Box className={classes.chipContainer}>
        <Chip
          aria-label={`Chip cliente ${customer.label}`}
          size="small"
          label={customer.label}
          onDelete={() => handleChangeCustomer(null)}
          className={classes.chip}
        />
        {channels.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip canal ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() => setChannels([...channels.filter((item) => item.id !== value.id)])}
            className={classes.chip}
          />
        ))}
        {range && (
          <Chip
            aria-label={`Chip range ${customer.label}`}
            size="small"
            label={`${range[0].toLocaleDateString(
              navigator.language
            )} - ${range[1].toLocaleDateString(navigator.language)}`}
            onDelete={() => setRange(null)}
            className={classes.chip}
          />
        )}
      </Box>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickMore}
        className={classes.moreButton}
        aria-label="Menu dos filtros"
        size="large">
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-label="Menu dos filtros"
      >
        <MenuItem onClick={handleClearAll} aria-label="Limpar filtros">
          {t('_common:clean_filters')}
        </MenuItem>
      </Menu>
    </Paper>
  );
}

export default RecordingsAppliedFilters;
