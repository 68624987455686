import panzoom, { PanZoom } from 'panzoom';
import { useEffect, RefObject, useRef } from 'react';

function useZoom({
  videoEl,
  zoom,
  enableZoom,
  onZoomChange,
}: {
  videoEl: RefObject<HTMLVideoElement>;
  zoom: number;
  enableZoom?: boolean;
  onZoomChange?: (zoom: number) => void;
}) {
  const zoomInstance = useRef<PanZoom>();

  useEffect(() => {
    if (videoEl.current) {
      zoomInstance.current = panzoom(videoEl.current, {
        bounds: true,
        boundsPadding: 1,
        maxZoom: 5,
        minZoom: 1,
        initialX: 0,
        initialY: 0,
        initialZoom: zoom,
      });

      zoomInstance.current.on('zoom', (e) => {
        // @ts-ignore
        onZoomChange && onZoomChange(e.getTransform().scale);
      });
    }
    return () => {
      zoomInstance.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zoomInstance.current) {
      enableZoom ? zoomInstance.current.resume() : zoomInstance.current.pause();
    }
  }, [enableZoom]);

  useEffect(() => {
    if (!zoomInstance.current) {
      return;
    }
    const transform = zoomInstance.current.getTransform();
    if (transform.scale === zoom) {
      return;
    }
    zoomInstance.current.smoothZoomAbs(transform.x, transform.y, zoom);
  }, [zoom]);
}

export default useZoom;
