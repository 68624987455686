import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { timeFormat } from 'd3';

export default function useYupObject(): typeof yup {
  const { t } = useTranslation('forms');
  yup.setLocale({
    // https://github.com/jquense/yup/blob/master/src/locale.ts
    mixed: {
      required: t('forms:mixed_required'),
      notType: t('forms:mixed_notType'),
    },
    string: {
      email: t('forms:string_email'),
      url: t('forms:string_url'),
      max: ({ ...args }) => t('forms:string_max', args),
      min: ({ ...args }) => t('forms:min_length', args),
    },
    number: {
      min: t('forms:number_min'),
      moreThan: t('forms:number_moreThan'),
      max: t('forms:number_max'),
      integer: t('forms:number_integer'),
      positive: t('forms:number_positive'),
    },
    date: {
      min: ({ min }) =>
        t('forms:date_min', { min: timeFormat('%d/%m/%Y %H:%M:%S')(new Date(min)) }),
      max: ({ max }) =>
        t('forms:date_max', { max: timeFormat('%d/%m/%Y %H:%M:%S')(new Date(max)) }),
    },
    array: {
      min: ({ min }) => t('forms:array_min', { count: min }),
    },
  });

  // eslint-disable-next-line
  const rRtsp = /^(?:rtsp:|rtmp:)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=@#]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/\?)*)?$/i;
  // https://regexr.com/6efdu

  yup.addMethod<yup.StringSchema>(yup.string, 'rtsp', function rtsp() {
    return this.matches(rRtsp, { message: t('forms:string_rtsp'), excludeEmptyString: true });
  });

  yup.addMethod<yup.StringSchema>(
    yup.string,
    'noSpecialCharacters',
    function noSpecialCharacters() {
      return this.matches(/^[a-zA-Z0-9\sçáàãâéèẽêíìĩîóòõôúùũûüÇÁÀÃÂÉÈẼÊÍÌĨÎÓÒÕÔÚÙŨÛÜ+-\\(\\)]+$/, {
        message: t('forms:string_noSpecialCharacters'),
        excludeEmptyString: true,
      });
    }
  );

  return yup;
}

export const unwantedChars = /[e+-]/;
