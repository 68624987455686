import React from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject } from 'hooks';
import { channelGroupInEditionAtom } from 'atoms/channelGroup';
import { useSetRecoilState } from 'recoil';
import { useFormik } from 'formik';
import { Form } from 'components/core';
import { ChannelGroup } from 'types/channelGroup.types';
import { useChannelGroupApi } from 'hooks/channelGroup/useChannelGroupApi';
import { useFormStyles } from '../core/Form';

function ChannelGroupForm({ setOpen, channelGroupInEdition }: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  channelGroupInEdition: ChannelGroup | null
}) {
  const { t } = useTranslation(['channel_groups', 'crud_actions']);
  const { createChannelGroup, editChannelGroup } = useChannelGroupApi();
  const setChannelGroupInEdition = useSetRecoilState(
    channelGroupInEditionAtom
  );
  const classesForm = useFormStyles({ dialog: true });
  const yup = useYupObject();
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
  });

  const formik = useFormik({
    initialValues: channelGroupInEdition || { name: '' },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const valuesTransformed = {
          ...values,
          name: values.name?.trim(),
        };
        if (channelGroupInEdition) {
          await editChannelGroup(valuesTransformed as ChannelGroup);
        } else {
          await createChannelGroup(valuesTransformed as ChannelGroup);
        }
        setSubmitting(false);
        setOpen(false);
        setTimeout(() => setChannelGroupInEdition(null), 150);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  return (
    <form
      key={channelGroupInEdition?.id}
      aria-label="ChannelGroupCreate"
      className={classesForm.form}
      onSubmit={formik.handleSubmit}
    >
      <Box className={classesForm.formInputsContainer}>
        <TextField
          className={classesForm.formInput}
          sx={{ marginTop: '5px' }}
          fullWidth
          label={t('name')}
          name="name"
          id="name"
          autoFocus
          placeholder={t('enter_channel_group_name')}
          helperText={
            (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
          }
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
      </Box>
      <Box className={classesForm.formFooter}>
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => {
            setOpen(false);
            setTimeout(() => setChannelGroupInEdition(null), 150);
          }}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          dialog
          label={channelGroupInEdition ? t('crud_actions:save') : t('crud_actions:create')}
          values={formik.values}
        />
      </Box>
    </form>
  );
}

export default ChannelGroupForm;
