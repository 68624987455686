import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { channelSelectedAtom } from 'atoms/channels';
import { useRecoilState } from 'recoil';
import { ChannelDetails, ChannelList } from 'components/Channel';
import { Channel } from 'types/channels.types';
import { Box, Divider } from '@mui/material';

function TabChannels({
  channels,
  customerId,
  setTab,
}: {
  channels: Channel[];
  customerId: number;
  setTab: Dispatch<SetStateAction<string>>;
}) {
  const [channelIdSelected, setChannelIdSelected] = useRecoilState(channelSelectedAtom);
  const [channelsChecked, setChannelsChecked] = React.useState<
    { id: number; checked: boolean; status: boolean }[]
  >([]);

  const channel = useMemo(() => {
    const channelSelected = channels.find(
      (_channel) => _channel.id === channelIdSelected || channelsChecked[_channel.id]
    );
    return channelSelected || channels[0];
  }, [channelIdSelected, channels, channelsChecked]);

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
      }}
      role="region"
      aria-label="Channel tab"
    >
      <ChannelList
        {...{
          customerId,
          channels,
          setChannelIdSelected,
          channelsChecked,
          setChannelsChecked,
          setTab,
        }}
        channelIdSelected={channel.id}
      />
      <Divider
        orientation="vertical"
        sx={{
          height: '100vh',
        }}
        flexItem
      />
      {!channelsChecked.length && (
        <ChannelDetails
          {...{
            customerId,
            channel,
          }}
        />
      )}
    </Box>
  );
}

export default TabChannels;
