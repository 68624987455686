/* eslint-disable arrow-body-style */
import React, { useState, useMemo } from 'react';
import { useFetch, useQuery } from 'hooks';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { Box, Paper, TableCell, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChannelNormalized } from 'types/channelsMonitoring.types';
import routes from 'helpers/routes';
import { Column } from 'types/table.types';
import exportAsPDF from 'services/exportAsPDF';
import Table from 'components/Table';
import { asyncComponent } from 'HOCs';
import { Channel } from 'types/channels.types';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function useColumns({
  t,
}: {
  t: TFunction<Namespace<'channels' | 'customers'>>;
}): Column<ChannelNormalized>[] {
  return useMemo(
    () => [
      {
        description: t('channels:name'),
        dataKey: 'name',
        linkTo: (row) => routes.customer.channel.view(row.clientId, row.id),
      },
      {
        description: t('customers:customer'),
        dataKey: 'clientTradeName',
        linkTo: (row) => routes.customer.view(row.clientId),
      },
      {
        description: t('channels:recording_type'),
        dataKey: 'recordingType',
        renderValue: (value) => t(`channels:recording_type_${value}`),
      },
      {
        description: t('channels_monitoring:recording'),
        dataKey: 'online',
        renderValue: (value) => (value ? t(`_common:yes`) : t(`_common:no`)),
      },
    ],
    [t]
  );
}

function ChannelsMonitoringTable() {
  const query = useQuery();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const columns = useColumns({ t });
  const classes = useStyles();

  const { data: channels } = useFetch<Channel[], ChannelNormalized[]>('/v1/channels/recording', {
    params: {
      channelGroupIds: query.get('channelGroups'),
      clientGroupIds: query.get('customerGroups'),
      clientIds: query.get('customers'),
      recordingType: query.get('recordingTypes'),
      online: query.get('recording')
        ? Boolean(Number(query.get('recording')))
        : query.get('recording'),
      name: query.get('channelName'),
    },
    normalizeData: (_channels: Channel[]) =>
      _channels.map(
        ({
          id,
          name: channelName,
          recordingType: channelRecordingType,
          client,
          online: channelOnline,
        }) => ({
          id,
          name: channelName,
          recordingType: channelRecordingType,
          online: channelOnline,
          clientTradeName: client.tradeName,
          clientId: client.id,
        })
      ),
  });

  const handleExportAsPDF = () => {
    channels &&
      exportAsPDF({
        rows: channels,
        t,
        title: t('channels_monitoring:channels_monitoring'),
        columns,
      });
  };

  if (channels && channels.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('_common:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table
        dataLength={channels ? channels.length : 0}
        tableName={t('channels_monitoring:channels_monitoring')}
        {...{ page, setPage, rowsPerPage, setRowsPerPage, handleExportAsPDF, columns }}
      >
        {channels?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <TableRow key={row.id} aria-label={`linha dos canais ${row.id}`}>
            {columns.map((column) => (
              <TableCell
                sx={{
                  width: column.dataKey === 'name' ? '30%' : '20%',
                }}
                key={column.dataKey}
                aria-label={column.description}
              >
                <Table.Cell {...{ row, column }} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Paper>
  );
}

function Loading() {
  const { t } = useTranslation();
  const columns = useColumns({ t });
  return <Table.Loading {...{ columns }} />;
}

export default asyncComponent(ChannelsMonitoringTable, <Loading />);
