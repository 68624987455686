import { useRecoilValue } from 'recoil';
import { useFetch } from 'hooks';
import { useMemo } from 'react';
import { loggedUserAtom } from 'atoms/users';
import { useCanPlayChannel } from './useCanPlayChannel';

interface Props {
  channelId: number;
  channelPrivate: boolean;
}
export function useGetChannelThumbnail({
  channelId,
  channelPrivate,
}: Props) {
  const loggedUser = useRecoilValue(loggedUserAtom);
  const canPlay = useCanPlayChannel({ channelPrivate });

  const { data } = useFetch(
    `/v1/masterCompanies/${loggedUser?.masterCompany.id}/channels/${channelId}/thumbnail.jpg`,
    {
      responseType: 'arraybuffer',
      conditionFn: () => canPlay,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return useMemo(
    function transformDataBufferInBase64() {
      return data ? Buffer.from(data).toString('base64') : null;
    },
    [data]
  );
}