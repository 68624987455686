import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function PlaylistPlay(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <mask
        id="mask0_129_10298"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_129_10298)">
        <path
          d="M3 16V14H11V16H3ZM3 12V10H15V12H3ZM3 8V6H15V8H3ZM16 21V13L22 17L16 21Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default PlaylistPlay;
