import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function SlowMotionVideo(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <mask id="mask0_111_3549" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_111_3549)">
        <path
          d="M4.25 18.3C3.61667 17.5667 3.10833 16.75 2.725 15.85C2.34167 14.95 2.1 14 2 13H4.05C4.15 13.7334 4.33333 14.4292 4.6 15.0875C4.86667 15.7459 5.21667 16.35 5.65 16.9L4.25 18.3ZM2 11C2.13333 10 2.38333 9.05005 2.75 8.15005C3.11667 7.25005 3.61667 6.43338 4.25 5.70005L5.65 7.10005C5.21667 7.65005 4.86667 8.25422 4.6 8.91255C4.33333 9.57088 4.15 10.2667 4.05 11H2ZM10.95 21.95C9.95 21.85 9.00417 21.6084 8.1125 21.225C7.22083 20.8417 6.4 20.35 5.65 19.75L7.05 18.3C7.63333 18.7334 8.24583 19.0917 8.8875 19.375C9.52917 19.6584 10.2167 19.85 10.95 19.95V21.95ZM7.1 5.70005L5.65 4.25005C6.4 3.65005 7.22083 3.15838 8.1125 2.77505C9.00417 2.39172 9.96667 2.15005 11 2.05005V4.05005C10.25 4.15005 9.55 4.34172 8.9 4.62505C8.25 4.90838 7.65 5.26672 7.1 5.70005ZM9.5 16.5V7.50005L16.5 12L9.5 16.5ZM13 21.95V19.95C15.0167 19.6667 16.6875 18.775 18.0125 17.275C19.3375 15.775 20 14.0167 20 12C20 9.98338 19.3375 8.22505 18.0125 6.72505C16.6875 5.22505 15.0167 4.33338 13 4.05005V2.05005C15.5667 2.33338 17.7083 3.41672 19.425 5.30005C21.1417 7.18338 22 9.41672 22 12C22 14.5834 21.1417 16.8167 19.425 18.7C17.7083 20.5834 15.5667 21.6667 13 21.95Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SlowMotionVideo;
