import Axios from 'axios';
import { useCallback } from 'react';
import { channelSelectedAtom } from "atoms/channels";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { mutate } from "swr";
import { HikVisionChannel } from "types/hikvision.types";
import { PermissionRole } from "types/user.types";
import useMessages from "hooks/useMessages";
import { useHavePermission } from 'hooks/useHavePermission';

export function useHikVisionApi(customerId: number) {
  const listFetchKey = `/v1/clients/${customerId}/channels`;
  const { t } = useTranslation(['channels', 'permission', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const insertPermission = useHavePermission(PermissionRole.INSERT_CHANNEL);
  const editPermission = useHavePermission(PermissionRole.EDIT_CHANNEL);
  const removePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const setChannelSelected = useSetRecoilState(channelSelectedAtom);

  const createChannel = useCallback(
    async (channel: HikVisionChannel): Promise<HikVisionChannel> => {
      if (!insertPermission) {
        enqueueError(`${t('error_creating')}\n${t('permission:you_dont_have_permission')}`);
        throw new Error(t('permission:you_dont_have_permission'));
      }
      try {
        const { data: response } = await Axios.post<HikVisionChannel>(`/v1/clients/${customerId}/channels/p2p`, channel);
        enqueueSuccess(t('channels:channel_created'));
        mutate(listFetchKey, async (items?: HikVisionChannel[]) => [
          items?.map((_item: HikVisionChannel) => (_item.id === response.id ? response : _item)),
        ]);

        return response;
      } catch (error) {
        if (Axios.isAxiosError(error)) {
          enqueueError(
            `${t('channels:error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
          );
        }
        throw error;
      }
    }, [customerId, enqueueError, enqueueSuccess, insertPermission, listFetchKey, t]);

  const createBatchChannels = async (channels: HikVisionChannel[]): Promise<HikVisionChannel[]> => {
    if (!insertPermission) {
      enqueueError(`${t('error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const { data } = await Axios.post(`/v1/clients/${customerId}/channels/hik/p2p`, channels);
      enqueueSuccess(t('channels:channels_created'));
      mutate(listFetchKey, undefined, true);
      return data;
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t('error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      }
      throw error;
    }
  };

  const updateChannel = async (channel: HikVisionChannel) => {
    if (!editPermission) {
      enqueueError(`${t('channels:error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.put(
        `/v1/clients/${customerId}/channels/p2p/${channel.id}`,
        channel
      );
      enqueueSuccess(t('channels:channel_changed'));
      mutate(listFetchKey, async (items?: HikVisionChannel[]) =>
        items?.map((_item: HikVisionChannel) =>
          _item.id === channel.id ? channel : _item
        )
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('channels:error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const deleteChannel = async (id: number) => {
    if (!removePermission) {
      enqueueError(`${t('error_deleting')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.delete(`/v1/clients/${customerId}/channels/${id}`);
      enqueueSuccess(t('channel_deleted'));
      setChannelSelected(null);
      mutate(listFetchKey, async (items?: HikVisionChannel[]) =>
        items?.filter((_item: HikVisionChannel) => _item.id !== id)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  return {
    createChannel,
    updateChannel,
    deleteChannel,
    createBatchChannels,
  };
}