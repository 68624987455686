import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActiveTransmissionCreditLimit } from 'components/ActiveTransmissionCreditLimit/ActiveTransmissionCreditLimit';
import { ActiveStorageLimit } from 'components/ActiveStorageLimit/ActiveStorageLimit';
import HelpIcon from '@mui/icons-material/Help';
import { useFormik } from 'formik';
import { useYupObject, useMessages } from 'hooks';
import { Form, useFormStyles } from 'components/core';
import { useHistory } from 'react-router-dom';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { ChannelLimitSettings, DEFAULT_CHANNEL_LIMIT_SETTINGS } from 'types/channelLimits.types';

interface Props {
  initialValues: ChannelLimitSettings;
  onSave: (config: ChannelLimitSettings) => Promise<void>;
}
function ChannelLimitForm({ initialValues, onSave }: Props) {
  const { t } = useTranslation();
  const yup = useYupObject();
  const classesForm = useFormStyles({});
  const havePermissionFn = useHavePermission();
  const canEditChannel = havePermissionFn(PermissionRole.EDIT_CHANNEL);
  const { enqueueError } = useMessages();

  const history = useHistory();

  const validationSchema = yup.object({
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      })
      .required(),
    activeStorageLimit: yup.boolean(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      })
      .required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const isDefaulValueTryingPut =
        JSON.stringify(values) === JSON.stringify(DEFAULT_CHANNEL_LIMIT_SETTINGS);
      try {
        if (isDefaulValueTryingPut) {
          enqueueError(t('channels:duplicated_global_limits'));
          return;
        }
        await onSave(values);
        setSubmitting(false);
        history.push('/settings');
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classesForm.form}>
      <Box
        sx={{
          flex: '1 1 auto',
          overflow: 'auto',
          px: 4,
          py: 1.5,
        }}
      >
        <Typography fontSize="1.4rem">{t('channels:global_limits')}</Typography>
        <Typography fontSize="1.1rem" fontWeight="light" mb={4}>
          {t('channels:global_limits_warning')}
        </Typography>

        <Box display="flex">
          <ActiveTransmissionCreditLimit
            touched={formik.touched.activeTransmissionCreditLimit}
            change={formik.handleChange}
            checked={formik.values.activeTransmissionCreditLimit}
            error={formik.errors.activeTransmissionCreditLimit}
            disabled={!canEditChannel}
          />

          <ActiveStorageLimit
            touched={formik.touched.activeStorageLimit}
            change={formik.handleChange}
            checked={formik.values.activeStorageLimit}
            error={formik.errors.activeStorageLimit}
            disabled={!canEditChannel}
          />
        </Box>
        <Box display="flex" width="100%" marginBottom={4}>
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <InputLabel
              htmlFor="transmissionCreditLimit"
              error={
                formik.touched.transmissionCreditLimit &&
                Boolean(formik.errors.transmissionCreditLimit)
              }
            >
              <Box display="flex" alignItems="center" gap={1}>
                {t('channels:limit_of_use_of_credits')}
                <Tooltip title={t('channels:tooltip_about_limit_use_of_credits')}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Box>
            </InputLabel>

            <OutlinedInput
              label={
                <Box display="flex" alignItems="center" m={0} justifyContent="center">
                  {t('channels:limit_of_use_of_credits')}
                  <Tooltip title={t('channels:tooltip_about_limit_use_of_credits')}>
                    <HelpIcon fontSize="small" />
                  </Tooltip>
                </Box>
              }
              disabled={!canEditChannel}
              fullWidth
              endAdornment={<InputAdornment position="end">R$</InputAdornment>}
              data-testid="transmissionCreditLimit"
              id="transmissionCreditLimit"
              name="transmissionCreditLimit"
              value={formik.values.transmissionCreditLimit}
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.transmissionCreditLimit &&
                Boolean(formik.errors.transmissionCreditLimit)
              }
            />
            <FormHelperText
              error={
                formik.touched.transmissionCreditLimit &&
                Boolean(formik.errors.transmissionCreditLimit)
              }
            >
              {formik.touched.transmissionCreditLimit && formik.errors.transmissionCreditLimit}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: '100%' }} className={classesForm.inputMarginLeft}>
            <InputLabel
              htmlFor="storageLimit"
              error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}
            >
              <Box display="flex" alignItems="center" gap={2}>
                {t('channels:limit_of_use_of_storage')}
                <Tooltip title={t('channels:tooltip_about_limit_of_use_of_storage')}>
                  <HelpIcon fontSize="small" color="inherit" />
                </Tooltip>
              </Box>
            </InputLabel>
            <OutlinedInput
              label={
                <Box display="flex" alignItems="center" gap={1}>
                  {t('channels:limit_of_use_of_storage')}
                  <Tooltip title={t('channels:tooltip_about_limit_of_use_of_storage')}>
                    <HelpIcon fontSize="small" />
                  </Tooltip>
                </Box>
              }
              disabled={!canEditChannel}
              data-testid="storageLimit"
              fullWidth
              endAdornment={<InputAdornment position="end">GB</InputAdornment>}
              id="storageLimit"
              name="storageLimit"
              value={formik.values.storageLimit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}
              type="number"
            />
            <FormHelperText
              error={formik.touched.storageLimit && Boolean(formik.errors.storageLimit)}
            >
              {formik.touched.storageLimit && formik.errors.storageLimit}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      <Box className={classesForm.formFooter}>
        <Permissioned role={[PermissionRole.EDIT_CHANNEL, PermissionRole.INSERT_CHANNEL]}>
          <Form.CancelButton
            isSubmitting={!formik.dirty || formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue(
                'activeTransmissionCreditLimit',
                initialValues.activeTransmissionCreditLimit
              );
              formik.setFieldValue('activeStorageLimit', initialValues.activeStorageLimit);
              formik.setFieldValue(
                'transmissionCreditLimit',
                initialValues.transmissionCreditLimit
              );
              formik.setFieldValue('storageLimit', initialValues.storageLimit);
            }}
          />
        </Permissioned>
        <Permissioned role={[PermissionRole.EDIT_CHANNEL, PermissionRole.INSERT_CHANNEL]}>
          <Form.SaveButton
            isSubmitting={formik.isSubmitting}
            initialValues={formik.initialValues}
            values={formik.values}
            disabled={!formik.isValid || !formik.dirty}
          />
        </Permissioned>
      </Box>
    </form>
  );
}

export default ChannelLimitForm;
