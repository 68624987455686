import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Calendar(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M6 2V4H5C3.90694 4 3 4.90694 3 6V20C3 21.0931 3.90694 22 5 22H19C20.0931 22 21 21.0931 21 20V6C21 4.90694 20.0931 4 19 4H18V2H16V4H8V2H6ZM5 9H19V20H5V9ZM12 13V18H17V13H12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Calendar;
