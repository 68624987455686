import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ReleaseIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M20 3L19 4.28516L6 9V15L8.45117 15.8887L8.16797 16.6895C7.95497 17.2905 8.26909 17.9491 8.87109 18.1621L11.0488 18.9336C11.6498 19.1466 12.3104 18.8305 12.5234 18.2285L12.793 17.4648L19 19.7148L20 21H22V3H20ZM2 9V15H4V9H2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default ReleaseIcon;
