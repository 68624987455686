import React from 'react';
import MosaicPage from 'pages/MosaicPage';
import MainMosaicView from 'pages/MosaicViewPage';
import CustomerPage from 'pages/CustomerPage';
import SettingsPage from 'pages/SettingsPage';
import CustomerNewPage from 'pages/CustomerNewPage';
import CustomerEditPage from 'pages/CustomerEditPage';
import { RouteComponentProps } from 'react-router-dom';
import routesHelper, { mosaicViewContext } from 'helpers/routes';
import CustomerDetailsPage from 'pages/CustomerDetailsPage';
import ChannelNewPage from 'pages/ChannelNewPage';
import ChannelEditPage from 'pages/ChannelEditPage';
import ChannelViewPage from 'pages/ChannelViewPage';
import { PermissionRole } from 'types/user.types';
import MosaicSlotViewPage from 'pages/MosaicSlotViewPage';
import WelcomePage from 'pages/WelcomePage';
import CalculatorPage from 'pages/CalculatorPage';
import MetricsPage from 'pages/MetricsPage';
import PlaybackViewPage from 'pages/PlaybackViewPage';
import MosaicFullScreenViewPage from 'pages/MosaicFullScreenViewPage';
import EventPage from 'pages/EventPage';
import ChannelsMonitoringPage from 'pages/ChannelsMonitoringPage';
import RecordingsPage from 'pages/RecordingsPage';
import PlaylistPage from 'pages/PlaylistPage';
import PlaylistPlayPage from 'pages/PlaylistPlayPage';
import PlaylistPlayFullscreenPage from 'pages/PlaylistPlayFullscreenPage';
import ChannelIntelbrasEditPage from 'pages/ChannelEditPage/ChannelIntelbrasEditPage';
import ChannelHikvisionEditPage from 'pages/ChannelEditPage/ChannelHikvisionEditPage';
import { IntelbrasBatchForm } from 'components/forms/channels/IntelbrasForm/IntelbrasBatchForm';
import { PreviewBatchIntelbras } from 'components/forms/channels/IntelbrasForm/PreviewBatchIntelbras';
import CompanyEditPage from 'pages/CompanyEditPage/CompanyEditPage';
import MosaicRecordedPage from 'pages/MosaicRecordedPage';
import { HikvisionNextView } from 'components/forms/channels/HikvisionForm/HikvisionNextView';
import BatchPage from 'pages/ChannelNewPage/BatchPage';
import BatchPageP2P from 'pages/ChannelNewPage/BatchPageP2P';
import ChannelRTMPEditPage from 'pages/ChannelEditPage/ChannelRTMPEditPage';
import { CompanyPage } from 'pages/CompanyPage';
import HikvisionSettingsPage from 'pages/HikvisionSettingsPage';
import RecordingSchedule from 'components/RecordingSchedule';
import DownloadPage from 'pages/DownloadPage/DownloadPage';

export type PermissionRoute = PermissionRole | PermissionRole[];

const routes: {
  path: string | string[];
  name?: string /* PUT THE KEY OF I18 IN NAMESPACE "routes"  */;
  ignoreInMiddle?: boolean /* Only show in breadcrumb when is the actual page */;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  permission?: PermissionRoute;
}[] = [
  {
    exact: true,
    path: routesHelper.mosaic.recorded(':mosaicId', ':startDate'),
    name: 'mosaics',
    Component: MosaicRecordedPage,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    exact: true,
    path: routesHelper.mosaic.root,
    name: 'mosaics',
    Component: MosaicPage,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    exact: true,
    path: routesHelper.mosaic.fullscreen(':mosaicId'),
    name: 'mosaics-fullscreen',
    Component: MosaicFullScreenViewPage,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    exact: true,
    path: routesHelper.playlists.fullscreen(':playlistId'),
    name: 'mosaics-fullscreen',
    Component: PlaylistPlayFullscreenPage,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    exact: true,
    path: routesHelper.mosaic.slotView(':mosaicId', ':slotId'),
    name: 'mosaics',
    Component: MosaicSlotViewPage,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    path: mosaicViewContext,
    name: 'mosaics',
    Component: MainMosaicView,
    permission: PermissionRole.VIEW_MOSAIC,
  },
  {
    exact: true,
    path: routesHelper.customer.root(),
    name: 'customers',
    Component: CustomerPage,
    permission: PermissionRole.VIEW_CLIENT,
  },
  {
    exact: true,
    path: routesHelper.customer.create(),
    name: 'new_customer',
    Component: CustomerNewPage,
    permission: PermissionRole.INSERT_CLIENT,
  },
  {
    exact: true,
    path: routesHelper.customer.edit(':customerId'),
    name: 'edit_customer',
    Component: CustomerEditPage,
    permission: PermissionRole.EDIT_CLIENT,
  },
  {
    exact: true,
    path: routesHelper.customer.view(':customerId'),
    name: 'view_customer',
    Component: CustomerDetailsPage,
    ignoreInMiddle: true,
    permission: PermissionRole.VIEW_CLIENT,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.edit(':customerId', ':channelId'),
    name: 'edit_channel',
    Component: ChannelEditPage,
    permission: PermissionRole.EDIT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.editRTMP(':customerId', ':channelId'),
    name: 'edit_channel',
    Component: ChannelRTMPEditPage,
    permission: PermissionRole.EDIT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.editHikvision(':customerId', ':channelId'),
    name: 'edit_channel',
    Component: ChannelHikvisionEditPage,
    permission: PermissionRole.EDIT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.editIntelbras(':customerId', ':channelId'),
    name: 'edit_channel',
    Component: ChannelIntelbrasEditPage,
    permission: PermissionRole.EDIT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.view(':customerId', ':channelId'),
    name: 'view_channel',
    Component: ChannelViewPage,
    permission: PermissionRole.VIEW_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.playback(':customerId', ':channelId'),
    name: 'playback_channel',
    Component: PlaybackViewPage,
    permission: PermissionRole.VIEW_CHANNEL,
  },
  {
    exact: false,
    path: routesHelper.customer.channel.create.root(':customerId'),
    name: '',
    Component: ChannelNewPage,
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.rtspChannelsPreview(':customerId'),
    name: 'channels:confirmation',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.rtmpChannelsPreview(':customerId'),
    name: 'channels:confirmation',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.rtspChannelsPreview(':customerId', ':sequential'),
    name: 'batch_channel',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.rtmpChannelsPreview(':customerId', ':sequential'),
    name: 'batch_channel',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.registerHikvision(':customerId'),
    name: 'HIKVISION',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.registerIntelbras(':customerId'),
    name: 'INTELBRAS',
    permission: PermissionRole.INSERT_CHANNEL,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.formBatchIntelbras(':customerId'),
    name: 'INTELBRAS',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: IntelbrasBatchForm,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.selectP2P(':customerId'),
    name: 'channels:register_p2p',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: BatchPage,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.selectBatchP2P(':customerId'),
    name: 'channels:channels_batch_p2p',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: BatchPageP2P,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.formBatchHikvision(':customerId'),
    name: 'HIKVISION',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: ChannelNewPage,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.previewBatchIntelbras(':customerId'),
    name: 'channels:confirmation',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: PreviewBatchIntelbras,
  },
  {
    exact: true,
    path: routesHelper.customer.channel.create.previewBatchHikvision(':customerId'),
    name: 'channels:confirmation',
    permission: PermissionRole.INSERT_CHANNEL,
    Component: HikvisionNextView,
  },
  {
    path: routesHelper.settings.root,
    name: 'settings',
    Component: SettingsPage,
    exact: false,
    permission: [
      PermissionRole.VIEW_USER,
      PermissionRole.ADMIN,
      PermissionRole.VIEW_CHANNEL_GROUP,
      PermissionRole.VIEW_CLIENT_GROUP,
    ],
  },
  {
    path: routesHelper.settings.speedTest.root,
    name: 'speed_test:speed_test',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.settings.user.root,
    name: 'settings_user',
    exact: true,
    permission: PermissionRole.VIEW_USER,
  },
  {
    path: routesHelper.settings.user.create,
    name: 'settings_user_create',
    exact: true,
    permission: PermissionRole.INSERT_USER,
  },
  {
    path: routesHelper.settings.user.edit(':userId'),
    name: 'settings_user_edit',
    exact: true,
    permission: PermissionRole.EDIT_USER,
  },
  {
    path: routesHelper.settings.channelGroup,
    name: 'settings_channel_group',
    exact: true,
    permission: PermissionRole.VIEW_CHANNEL_GROUP,
  },
  {
    path: routesHelper.settings.customerGroup,
    name: '_common:customer_group',
    exact: true,
    permission: PermissionRole.VIEW_CLIENT_GROUP,
  },
  {
    path: routesHelper.settings.operationLogs.root(),
    name: 'settings_operation_logs',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.settings.usageSummary.root,
    name: 'settings_usage_summary',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.welcome.root,
    exact: true,
    Component: WelcomePage,
  },
  {
    path: routesHelper.download.root,
    exact: true,
    Component: DownloadPage,
  },
  {
    path: routesHelper.calculator.root,
    exact: true,
    Component: CalculatorPage,
  },
  {
    path: routesHelper.settings.usageSummary.details,
    name: 'settings_usage_summary_details',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.settings.channels,
    name: '_common:channels',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.root(),
    name: 'metrics',
    Component: MetricsPage,
    exact: false,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.storage(),
    name: 'metrics_storage',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.recording(),
    name: 'metrics_recording',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.live(),
    name: 'metrics_live',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.reproduction(),
    name: 'metrics_reproduction',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.streaming(),
    name: 'metrics_streaming',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.metrics.streaming(),
    name: 'metrics_details',
    exact: true,
    permission: PermissionRole.ADMIN,
  },
  {
    path: routesHelper.events.root(),
    name: 'events',
    Component: EventPage,
    exact: true,
    permission: PermissionRole.VIEW_CHANNEL,
  },
  {
    path: routesHelper.channels.monitoring(),
    name: 'channels_monitoring:channels_monitoring',
    Component: ChannelsMonitoringPage,
    exact: true,
    permission: PermissionRole.VIEW_CHANNEL,
  },
  {
    path: routesHelper.recordings.root(),
    name: 'recordings:recordings',
    Component: RecordingsPage,
    exact: true,
    permission: PermissionRole.VIEW_CHANNEL,
  },
  {
    path: routesHelper.playlists.root(),
    name: '_common:playlists',
    Component: PlaylistPage,
    exact: true,
    permission: PermissionRole.VIEW_PLAYLIST,
  },
  {
    path: routesHelper.playlists.play(':playlistId'),
    name: '_common:playlists',
    exact: true,
    permission: PermissionRole.VIEW_PLAYLIST,
    Component: PlaylistPlayPage,
  },
  {
    exact: true,
    path: routesHelper.settings.companies.edit(':id'),
    Component: CompanyEditPage,
    permission: PermissionRole.EDIT_COMPANY,
  },
  {
    exact: true,
    path: routesHelper.settings.companies.root(),
    Component: CompanyPage,
    permission: PermissionRole.VIEW_COMPANY,
    name: 'settings:companies',
  },
  {
    exact: true,
    path: routesHelper.settings.hikvision,
    Component: HikvisionSettingsPage,
    permission: PermissionRole.VIEW_CHANNEL,
    name: 'settings:hikvision',
  },
  {
    exact: true,
    path: routesHelper.settings.recordingSchedule.root,
    Component: RecordingSchedule,
    name: '_common:recording_schedule',
  },
  { path: '/' },
];

export default routes;
