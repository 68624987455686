import { ClientPlataform } from 'pages/SettingsPage/tabs/SpeedTest/types/speedTest.types';

declare global {
  interface Window {
    isWebView?: boolean;
    isWebViewProxy?: boolean;
  }
}

function useClientPlataform(): ClientPlataform {
  const isWebView = typeof window !== 'undefined' && !!window.isWebView;
  const isWebViewProxy = typeof window !== 'undefined' && !!window.isWebViewProxy;

  if (isWebView) return 'WEB_VIEW';
  if (isWebViewProxy) return 'WEB_VIEW_PROXY';

  return 'WEB_BROWSER';
}

export { useClientPlataform };
