import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { textDebouncedAtom } from 'atoms/selectChannelPanel';
import { nextEmptySlotSelector, mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { useFetch } from 'hooks';
import { EmptyChannel } from 'components/Channel';
import { VideocamOutlined, AddCircle } from '@mui/icons-material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Typography, Box, IconButton, Paper, Theme, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { ChannelCard } from 'components/ItemsCard';
import { ChannelSimple } from 'types/mosaics.types';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { useSelectCustomerPanel } from 'hooks/customers/useSelectCustomerPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultContainer: {
      backgroundColor: theme.palette.background.default,
      padding: '16px',
      marginTop: '16px',
      overflow: 'auto',
      flex: '1 1 auto',
    },
  })
);

function ResultsContainer({ mosaicId }: { mosaicId: number }) {
  const classes = useStyles();
  const { t } = useTranslation('_common');
  const text = useRecoilValue(textDebouncedAtom);
  const { data: channelResults } = useFetch<ChannelSimple[]>('/v1/channels', {
    params: {
      text,
      limit: text ? undefined : 8,
    },
    normalizeData: (data) => data.filter((item) => !item.channelPrivate),
  });

  const nextEmptySlot = useRecoilValue(nextEmptySlotSelector(mosaicId));
  const [orderSelected, setMosaicSlotOrderSelected] = useRecoilState(mosaicSlotOrderSelectedAtom);
  const { putChannelInSlot, mosaicItens } = useMosaicItens(mosaicId);
  const { closeChannelSelection } = useSelectChannelPanel();
  const { openCustomerSelection } = useSelectCustomerPanel();
  const [ordered, setOrdered] = React.useState(false);

  const handleClick = (channel: ChannelSimple) => {
    orderSelected && putChannelInSlot(orderSelected, channel);
    setMosaicSlotOrderSelected(nextEmptySlot);
    if (!nextEmptySlot) {
      closeChannelSelection();
    }
  };

  const channelIsInMosaic = useCallback(
    (channelId: number) => mosaicItens.some((item) => item.channel.id === channelId),
    [mosaicItens]
  );

  if (!channelResults?.length && !text) return <EmptyChannel onAddClick={openCustomerSelection} />;

  return (
    <Paper className={classes.resultContainer}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          mb: 1,
          pr: 2,
        }}
      >
        <VideocamOutlined titleAccess="channel" />
        <Box flex="1 1 auto" marginLeft="0.4rem">
          <Typography>{t('channels')}</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {`${channelResults?.length} ${t('results')}`}
        </Typography>
        <Permissioned role={PermissionRole.INSERT_CHANNEL}>
          <Box marginLeft="4px">
            <IconButton size="small" aria-label="create channel" onClick={openCustomerSelection}>
              <AddCircle />
            </IconButton>
          </Box>
        </Permissioned>
        <Box marginLeft="4px">
          <Tooltip title={t('_common:ordered')}>
            <IconButton
              size="small"
              aria-label="order"
              onClick={() => {
                setOrdered(!ordered);
              }}
            >
              {ordered ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {channelResults?.map((channel) => (
        <ChannelCard
          key={channel.id}
          disabled={channelIsInMosaic(channel.id)}
          onClick={() => handleClick(channel)}
          channel={{ ...channel, channelPrivate: false }}
          {...{ mosaicId }}
        />
      ))}
    </Paper>
  );
}

export function Loading() {
  const classes = useStyles();

  return (
    <Paper className={classes.resultContainer}>
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ChannelCard key={i} loading />
      ))}
    </Paper>
  );
}

export default ResultsContainer;
