const decodeEntities = (function decode() {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str: string) {
    let newStr: string | null = null;
    if (str && typeof str === 'string') {
      // strip script/html tags
      newStr = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
      newStr = newStr.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
      element.innerHTML = newStr;
      newStr = element.textContent;
      element.textContent = '';
    }

    return newStr;
  }

  return decodeHTMLEntities;
})();

export default decodeEntities;
