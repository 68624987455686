
import axios from 'axios';
import { useMessages } from 'hooks';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { Company, CompanyResponse, LogoData } from 'types/companies.types';
import { PermissionRole } from 'types/user.types';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { companiesSearchTextAtom } from 'atoms/companies';
import { useHavePermission } from 'hooks/useHavePermission';

export const normalizeCompany = (company: Company) => {
  const normalizedCompany = {
    ...company,
    idMask: String(company.id).padStart(6, '0'),
  };

  return normalizedCompany;
};

export function useCompaniesApi() {
  const { enqueueSuccess, enqueueError } = useMessages();
  const { t } = useTranslation();
  const insertPermission = useHavePermission(PermissionRole.INSERT_COMPANY);
  const editPermission = useHavePermission(PermissionRole.EDIT_COMPANY);
  const removePermission = useHavePermission(PermissionRole.REMOVE_COMPANY);
  const history = useHistory();

  const companySearchText = useRecoilValue(companiesSearchTextAtom);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mutateList = (data?: any) => {
    const companiesListFetchKey = ['/v1/companies', companySearchText];

    mutate(companiesListFetchKey, data);
  };

  const addCompany = async (companyData: Company): Promise<void> => {
    if (!insertPermission) {
      enqueueError(`${t('company:error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const { data } = await axios.post<CompanyResponse>('/v1/companies', companyData);
      enqueueSuccess(t('company:company_created'));
      history.push('/settings/companies');
      companyData.file &&
        (await updateLogo({
          id: data.id,
          file: companyData.file,
          method: 'post',
          companyId: data.id,
        }));

      mutateList(async (items?: Company[]) => [
        items?.map((_item: Company) => (_item.id === companyData.id ? companyData : _item)),
      ]);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(
          `${t('company:error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  const updateCompany = async (values: Company): Promise<number> => {
    if (!editPermission) {
      enqueueError(`${t('company:error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const { data } = await axios.put(`/v1/companies/${values.id}`, values);
      enqueueSuccess(t('company:company_edited'));
      values.file &&
        values.id &&
        (await updateLogo({
          id: values.id,
          file: values.file,
          method: 'put',
          companyId: values.id,
        }));
      mutateList(async (items?: Company[]) =>
        items?.map((_item: Company) => (_item.id === values.id ? values : _item))
      );
      history.push('/settings/companies');
      return data;
    } catch (error) {
      if (axios.isAxiosError(error))
        enqueueError(
          `${t('company:error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const deleteCompany = async (id: number) => {
    if (!removePermission) {
      enqueueError(`${t('company:error_deleting')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await axios.delete(`/v1/companies/${id}`);
      enqueueSuccess(t('company:company_deleted'));
      mutateList(async (items?: Company[]) => items?.filter((_item: Company) => _item.id !== id));
    } catch (error) {
      if (axios.isAxiosError(error))
        enqueueError(
          `${t('company:error_deleting')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };

  const updateLogo = async ({
    id,
    file,
    method,
    companyId,
  }: LogoData & { method: 'put' | 'post'; companyId: number }) => {
    try {
      const formData = new FormData();

      formData.append('file', new Blob([file], { type: file.type }), file.name);

      await axios[method](`/v1/companies/${companyId}/photos/${id}`, formData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(
          `${t('company:error_register_logo')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  return {
    addCompany,
    updateCompany,
    deleteCompany,
  };
}
