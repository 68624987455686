import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SelectPageProps {
  currentPage: number;
  totalPages: number;
  onChangePage: (newPage: number) => void;
  size: 'small' | 'medium';
}

function SelectPage({ currentPage, totalPages, onChangePage, size }: SelectPageProps) {
  const { t } = useTranslation();
  const pages = Array.from({ length: totalPages }).map((_, index) => index + 1);

  const handlePageChange = (event: SelectChangeEvent<number>) => {
    const newPage = event.target.value as number;
    onChangePage(newPage);
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        width: 120,
      }}
      role="region"
      aria-label="Change page"
    >
      <Select
        name="mosaicPagination"
        id="mosaicPagination"
        value={currentPage}
        onChange={handlePageChange}
        size={size}
      >
        {pages.map((page) => (
          <MenuItem key={page} value={page}>
            {page}ª {t('_common:page').toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectPage;
