import { Box, Checkbox, FormControlLabel, styled } from '@mui/material';
import { DateRangeValue, DateRangeField } from 'components/DateRange';
import SelectUser from 'components/SelectUser';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OperationItems } from 'types/operationLogs.types';
import OperationLogsAppliedFilters from './OperationLogsAppliedFilters';
import SelectItem from './SelectItem';
import SelectOperation, { Operation } from './SelectOperation';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
}));

const ContainerFilter = styled(Box)({
  '--width': 'calc(100% / 4)',
  width: 'calc(var(--width) - 8px)',
});

function OperationLogsFilters() {
  const { t } = useTranslation(['_common']);
  const query = useQuery();
  const history = useHistory();

  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue | null>(
    startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : null
  );

  const customerS = query.get('customer');
  const [customer, setCustomer] = React.useState(
    customerS ? { id: Number(customerS), label: '' } : null
  );

  const userS = query.get('user');
  const [user, setUser] = React.useState(userS ? { id: Number(userS), label: '' } : null);

  const operationS = query.get('operation') as Operation | null;
  const [operation, setOperation] = React.useState(operationS);

  const itemS = query.get('item') as OperationItems | null;
  const [item, setItem] = React.useState(itemS);

  const [filterActiveUsers, setFilterActiveUsers] = React.useState(true);

  useEffect(
    function updateWhenChangeSearchParams() {
      const rangeS = startDateS && endDateS ? [startDateS, endDateS].join(',') : null;
      const isRangeEqual = rangeS === (range?.map((d) => d.toISOString()).join(',') || null);
      if (!isRangeEqual) {
        setRange(startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : null);
      }

      const isCustomerEqual = customerS === (customer && String(customer.id));
      if (!isCustomerEqual) {
        setCustomer(customerS ? { id: Number(customerS), label: '' } : null);
      }

      const isUserEqual = userS === (user && String(user.id));
      if (!isUserEqual) {
        setUser(userS ? { id: Number(userS), label: '' } : null);
      }

      if (operationS !== operation) {
        setOperation(operationS);
      }

      if (itemS !== item) {
        setItem(itemS);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  useEffect(
    function updateSearchParamsWhenChangeFilters() {
      history.push(
        routes.settings.operationLogs.root({
          startDate: range && range[0],
          endDate: range && range[1],
          selectedCustomer: customer && customer.id,
          selectedUser: user && user.id,
          selectedOperation: operation,
          selectedItem: item,
        })
      );
    },
    [customer, history, item, operation, range, user]
  );

  const handleChangeShowActiveUsers = () => {
    setFilterActiveUsers(!filterActiveUsers);
  };
  const handleChangeRange = (nRange: DateRangeValue | null) => {
    setRange(
      nRange
        ? [new Date(nRange[0].toDateString()), new Date(`${nRange[1].toDateString()} 23:59:59`)]
        : null
    );
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox onClick={() => handleChangeShowActiveUsers()} checked={filterActiveUsers} />
        }
        label={t('_common:show_only_active_users')}
      />
      <Container aria-label="Filtros dos logs de operação" role="region">
        <ContainerFilter>
          <DateRangeField label={t('_common:date')} value={range} setValue={handleChangeRange} />
        </ContainerFilter>
        <ContainerFilter>
          <React.Suspense fallback={<SelectUser.Loading />}>
            <SelectUser
              selected={user}
              onChange={(newUser) => setUser(newUser)}
              filterData={(data) => {
                if (filterActiveUsers) {
                  return data.filter((userItem) => userItem.status);
                }
                return data;
              }}
            />
          </React.Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <React.Suspense fallback={<SelectItem.Loading />}>
            <SelectItem selected={item} onChange={(newitem) => setItem(newitem)} />
          </React.Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <SelectOperation
            selected={operation}
            onChange={(newOperation) => setOperation(newOperation)}
          />
        </ContainerFilter>
      </Container>
      <OperationLogsAppliedFilters
        {...{ range, setRange, user, setUser, item, setItem, operation, setOperation }}
      />
    </>
  );
}

export default OperationLogsFilters;
