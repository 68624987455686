import React, { Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { Typography, Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useKeyPressEvent } from 'react-use';
import { SegwareLogo } from 'components/core/Icons';
import { searchPanelTextDebouncedAtom, searchPanelText } from 'atoms/search';
import ErrorBoundary from 'components/ErrorBoundary';
import DebounceInput from 'components/core/DebounceInput';
import { cache } from 'swr';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { Container, Header } from './SearchPanel.styles';
import ResultsContainer, { Loading } from './ResultsContainer';
import { InputContainer } from '../LeftPanel.styles';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function SearchPanel() {
  const { t } = useTranslation('search_bar');
  const [inputText, setInputText] = useRecoilState(searchPanelText);
  const [searchPanelTextDebounced, setSearchPanelTextDebounced] = useRecoilState(
    searchPanelTextDebouncedAtom
  );
  const { closeSearchPanel } = useGlobalSearch();
  const classes = useStyles();
  useKeyPressEvent('Escape', () => closeSearchPanel());

  return (
    <Container role="container" aria-label="searchPanel-container">
      <Header>
        <SegwareLogo className={classes.segwareLogo} />
        <Typography variant="h6">{t('search')}</Typography>
      </Header>
      <InputContainer>
        <Fab size="small" onClick={closeSearchPanel} aria-label="close-search">
          <NavigateBeforeIcon />
        </Fab>
        <DebounceInput
          placeholder={t('search_customers_channels_mosaics')}
          autoFocus
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          setValue={setInputText}
          onDebounce={() => setSearchPanelTextDebounced(inputText)}
        />
      </InputContainer>
      <ErrorBoundary
        tryAgainClick={() => {
          const [key, , keyErr] = cache.serializeKey(['/v1/search', searchPanelTextDebounced]);
          cache.delete(key);
          cache.delete(keyErr);
        }}
        reloadChildrenOnTryAgain
      >
        <Suspense fallback={<Loading />}>
          <ResultsContainer />
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
}

export default SearchPanel;
