import { useHavePermission } from 'hooks/useHavePermission';
import axios from 'axios';
import { useMessages } from 'hooks';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilValue } from 'recoil';
import { mutate } from 'swr';
import { PermissionRole } from 'types/user.types';
import { useHistory } from 'react-router-dom';
import {
  IRecordingSchedulerForm,
  IRecordingSchedulerFormWithId,
  RecordingSchedule,
} from 'types/recordingSchedule.types';

export const scheduleListSearchTextAtom = atom<string>({
  key: 'scheduleListSearchTextAtom',
  default: '',
});

export function useRecordingScheduleCRUD() {
  const { t } = useTranslation([
    'recording_schedule',
    'crud_actions',
    'permission',
    'api_errors_messages',
  ]);
  const updatePermission = useHavePermission(PermissionRole.EDIT_SCHEDULE_RECORDING);
  const insertPermission = useHavePermission(PermissionRole.INSERT_SCHEDULE_RECORDING);
  const deletePermission = useHavePermission(PermissionRole.REMOVE_SCHEDULE_RECORDING);
  const { enqueueSuccess, enqueueError } = useMessages();
  const searchText = useRecoilValue(scheduleListSearchTextAtom);
  const history = useHistory();
  const listFetchKey = ['/v1/scheduledRecording', searchText];

  const createRecordingSchedule = async (
    dataToBeProcessed: IRecordingSchedulerForm
  ): Promise<number> => {
    if (!insertPermission) {
      enqueueError(`${t('error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const { name, customers, timeRanges } = dataToBeProcessed;

      const channels = customers.flatMap((customer) => customer.channelIds);
      const timeRangesWithOutId = timeRanges.map(({ id, ...rest }) => ({
        ...rest,
      }));

      // Para melhorar a performance e otimização no back será feito essa transformação
      // mas no front não é necessário.
      const recordingSchedule = {
        name,
        timeRanges: timeRangesWithOutId,
        channels: channels.map((id) => ({ id })),
      };

      const { data } = await axios.post('/v1/scheduledRecording', recordingSchedule);
      enqueueSuccess(t('recording_schedule:recording_schedule_created'));

      history.push('/settings/recordingSchedules');
      const recordingScheduleCreated = {
        ...recordingSchedule,
        id: data.id,
      };
      mutate('/v1/scheduledRecording', (list: IRecordingSchedulerForm[]) =>
        Array.isArray(list) ? [...list, recordingScheduleCreated] : [recordingScheduleCreated]
      );
      return data.id;
    } catch (error) {
      if (axios.isAxiosError(error))
        enqueueError(
          `${t('recording_schedule:error_creating')}\n${error?.response?.data?.message
            ? t(`api_errors_messages:${error?.response?.data?.message}`)
            : ''
          }`
        );
      console.error(error);
      throw error;
    }
  };

  const editRecordingSchedule = async (
    dataToBeProcessed: IRecordingSchedulerFormWithId
  ): Promise<void> => {
    if (!updatePermission) {
      enqueueError(`${t('error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }

    const { id, name, customers, timeRanges } = dataToBeProcessed;

    const timeRangesWithOutId = timeRanges.map((item) => ({
      ...item,
      id: undefined,
    }));

    const channels = customers.flatMap((customer) =>
      customer.channelIds.map((channelId) => channelId)
    );

    // Para melhorar a performance e otimização no back será feito essa transformação
    // mas no front não é necessário.
    const recordingScheduleUpdateTransformed = {
      name,
      timeRanges: timeRangesWithOutId,
      channels: channels.map((val) => ({ id: val })),
    };

    try {
      await axios.put(`/v1/scheduledRecording/${id}`, recordingScheduleUpdateTransformed);
      history.push('/settings/recordingSchedules');
      enqueueSuccess(t('recording_schedule:recording_schedule_updated'));
      mutate(listFetchKey);
    } catch (error) {
      if (axios.isAxiosError(error))
        enqueueError(
          `${t('recording_schedule:error_creating')}\n${error?.response?.data?.message
            ? t(`api_errors_messages:${error?.response?.data?.message}`)
            : ''
          }`
        );
      console.error(error);
      throw error;
    }
  };

  const deleteSchedule = async (id: number) => {
    if (!deletePermission) {
      enqueueError(
        `${t('_common:error_deleting_schedule')}\n${t('permission:you_dont_have_permission')}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await axios.delete(`/v1/scheduledRecording/${id}`);
      enqueueSuccess(t('_common:schedule_deleted'));
      mutate(listFetchKey, async (items: RecordingSchedule[]) =>
        items.filter((_item: RecordingSchedule) => _item.id !== id)
      );
    } catch (error) {
      if (axios.isAxiosError(error))
        enqueueError(
          `${t('_common:error_deleting_schedule')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      console.error(error);
      throw error;
    }
  };

  return {
    createRecordingSchedule,
    editRecordingSchedule,
    deleteSchedule,
  };
}
