import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { customerSearchTextAtom, normalizeCustomer } from 'atoms/customers';
import { useTranslation } from 'react-i18next';
import CustomerDetails from 'components/CustomerDetails';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useFetch, useQuery } from 'hooks';
import { Customer } from 'types/customer.types';

function CustomerSquare({ customers }: { customers?: Customer[] }) {
  const searchText = useRecoilValue(customerSearchTextAtom);
  const { t } = useTranslation('customers');
  const query = useQuery();
  const selectedCustomerId = query.get('selectedCustomer');

  if (selectedCustomerId) {
    return (
      <Suspense
        fallback={
          <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
            <CircularProgress />
          </Box>
        }
      >
        <CustomerSelected selectedCustomerId={Number(selectedCustomerId)} />
      </Suspense>
    );
  }

  if (!customers?.length && searchText) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
        <Typography color="textSecondary">
          {t('to_display_customer_information_search_by_tradename_corporatename_customer_id')}
        </Typography>
      </Box>
    );
  }

  if (customers?.length) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
        <Typography color="textSecondary">{t('select_customer_to_view_information')}</Typography>
      </Box>
    );
  }

  return null;
}

function CustomerSelected({ selectedCustomerId }: { selectedCustomerId: number }) {
  const { data: customerSelected } = useFetch<Customer>(`/v1/clients/${selectedCustomerId}`, {
    normalizeData: normalizeCustomer,
  });
  if (customerSelected) return <CustomerDetails customer={customerSelected} />;
  return null;
}

export default CustomerSquare;
