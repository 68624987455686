import React from 'react';
import { asyncComponent } from 'HOCs';
import CustomerForm from 'components/CustomerForm';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import { DEFAULT_CUSTOMER } from 'types/customer.types';
import { useCustomerApi } from 'hooks/customers/useCustomersApi';

function CustomerScreenPage() {
  const history = useHistory();
  const query = useQuery();
  const { createCustomer } = useCustomerApi();

  return (
    <CustomerForm
      initialValues={DEFAULT_CUSTOMER}
      doSubmit={createCustomer}
      onSubmitSuccess={(customer) =>
        history.push(query.get('returnRoute') || routes.customer.root(customer.id))
      }
    />
  );
}

export default asyncComponent(CustomerScreenPage);
