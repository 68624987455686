import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { FilterSelectValue } from 'types/select.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    chipContainer: {
      flex: '1 1 auto',
    },
    chip: {
      margin: theme.spacing(1),
    },
    moreButton: {
      flex: '0 0 auto',
      margin: 'auto',
    },
  })
);

function ChannelsMonitoringAppliedFilters({
  selectedChannelGroups,
  setSelectedChannelGroups,
  selectedCustomerGroups,
  setSelectedCustomerGroups,
  selectedCustomers,
  setSelectedCustomers,
  selectedRecordingTypes,
  setSelectedRecordingTypes,
  recording,
  setRecording,
  channelName,
  setChannelName,
}: {
  selectedChannelGroups: FilterSelectValue[];
  setSelectedChannelGroups: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  selectedCustomerGroups: FilterSelectValue[];
  setSelectedCustomerGroups: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  selectedCustomers: FilterSelectValue[];
  setSelectedCustomers: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  selectedRecordingTypes: string[];
  setSelectedRecordingTypes: React.Dispatch<React.SetStateAction<string[]>>;
  recording: boolean | null;
  setRecording: React.Dispatch<React.SetStateAction<boolean | null>>;
  channelName: string;
  setChannelName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const classes = useStyles();
  const { t } = useTranslation(['channels', '_common']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const haveSomeFilter = React.useMemo(
    () =>
      selectedChannelGroups.length > 0 ||
      selectedCustomerGroups.length > 0 ||
      selectedCustomers.length > 0 ||
      selectedRecordingTypes.length > 0 ||
      recording !== null ||
      Boolean(channelName),
    [
      selectedChannelGroups,
      selectedCustomerGroups,
      selectedCustomers,
      selectedRecordingTypes,
      recording,
      channelName,
    ]
  );

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearAll = () => {
    setAnchorEl(null);
    setSelectedChannelGroups([]);
    setSelectedCustomerGroups([]);
    setSelectedCustomers([]);
    setSelectedRecordingTypes([]);
    setRecording(null);
    setChannelName('');
  };

  if (!haveSomeFilter) return null;

  return (
    <Paper className={classes.root} role="region" aria-label="Filtros aplicados">
      <Box className={classes.chipContainer}>
        {selectedChannelGroups.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip grupo de canal ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedChannelGroups([
                ...selectedChannelGroups.filter((item) => item.id !== value.id),
              ])
            }
            className={classes.chip}
          />
        ))}
        {selectedCustomerGroups.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip grupo de cliente ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedCustomerGroups([
                ...selectedCustomerGroups.filter((item) => item.id !== value.id),
              ])
            }
            className={classes.chip}
          />
        ))}
        {selectedCustomers.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip cliente ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedCustomers([...selectedCustomers.filter((item) => item.id !== value.id)])
            }
            className={classes.chip}
          />
        ))}
        {selectedRecordingTypes.map((recordingType) => (
          <Chip
            key={recordingType}
            aria-label={`Chip tipo de gravação ${recordingType}`}
            size="small"
            label={t(`channels:recording_type_${recordingType}`)}
            onDelete={() =>
              setSelectedRecordingTypes([
                ...selectedRecordingTypes.filter((id) => recordingType !== id),
              ])
            }
            className={classes.chip}
          />
        ))}
        {recording !== null && (
          <Chip
            aria-label={`Chip gravando ou não gravando ${recording}`}
            size="small"
            label={
              recording
                ? t(`channels_monitoring:recording`)
                : t(`channels_monitoring:not_recording`)
            }
            onDelete={() => setRecording(null)}
            className={classes.chip}
          />
        )}
        {channelName && (
          <Chip
            aria-label={`Chip nome do canal ${channelName}`}
            size="small"
            label={channelName}
            onDelete={() => setChannelName('')}
            className={classes.chip}
          />
        )}
      </Box>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickMore}
        className={classes.moreButton}
        aria-label="Menu dos filtros"
        size="large">
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-label="Menu dos filtros"
      >
        <MenuItem onClick={handleClearAll} aria-label="Limpar filtros">
          {t('_common:clean_filters')}
        </MenuItem>
      </Menu>
    </Paper>
  );
}

export default ChannelsMonitoringAppliedFilters;
