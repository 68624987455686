import { atom, selector } from 'recoil';
import { LeftPanelContent } from 'types/leftpanel.types';

export const actualPanel = atom<LeftPanelContent>({
  key: 'actualPanel',
  default: LeftPanelContent.MENU,
});

export const sizeWrapper = selector<string>({
  key: 'sizeWrapper',
  get: ({ get }) => {
    const actual = get(actualPanel);
    if (actual === LeftPanelContent.MENU) {
      return '61px';
    }
    return '34rem';
  },
});

