import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function SettingsTimelapse(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <mask id="mask0_111_3564" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_111_3564)">
        <path
          d="M9.24995 22L8.84995 18.8C8.63329 18.7167 8.42912 18.6167 8.23745 18.5C8.04578 18.3833 7.85828 18.2583 7.67495 18.125L4.69995 19.375L1.94995 14.625L4.52495 12.675C4.50828 12.5583 4.49995 12.4458 4.49995 12.3375V11.6625C4.49995 11.5542 4.50828 11.4417 4.52495 11.325L1.94995 9.375L4.69995 4.625L7.67495 5.875C7.85828 5.74167 8.04995 5.61667 8.24995 5.5C8.44995 5.38333 8.64995 5.28333 8.84995 5.2L9.24995 2H14.75L15.15 5.2C15.3666 5.28333 15.5708 5.38333 15.7625 5.5C15.9541 5.61667 16.1416 5.74167 16.325 5.875L19.3 4.625L22.05 9.375L19.475 11.325C19.4916 11.4417 19.5 11.5542 19.5 11.6625V12.5C19.5 12.6667 19.4833 12.8333 19.45 13H15.4C15.45 12.8333 15.4875 12.6708 15.5125 12.5125C15.5375 12.3542 15.55 12.1833 15.55 12C15.55 11.0333 15.2083 10.2083 14.525 9.525C13.8416 8.84167 13.0166 8.5 12.05 8.5C11.0666 8.5 10.2375 8.84167 9.56245 9.525C8.88745 10.2083 8.54995 11.0333 8.54995 12C8.54995 12.8 8.77912 13.5 9.23745 14.1C9.69579 14.7 10.2833 15.1167 11 15.35V22H9.24995ZM18 21V15L23 18L18 21ZM13 21V15L18 18L13 21Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default SettingsTimelapse;
