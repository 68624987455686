import {
  ChannelInitialValues,
  CustomizedResponseCodeEnum,
  PartnerType,
  RecordingType,
} from './channels.types';

export interface HikVisionChannel extends ChannelInitialValues {
  p2pChannel: number;
  p2pPartner: PartnerType;
  serialNumber: string;
  privateKey: string;
  idMask: string;
  subtypeStream: boolean;
}
export interface HikTestConnectionResponse {
  message?: string;
  success: CustomizedResponseCodeEnum;
  p2pChannel?: number;
  serialNumber?: string;
  name?: string;
}

export const DEFAULT_HIKVISION: HikVisionChannel = {
  status: true,
  name: '',
  p2pChannel: 1,
  p2pPartner: PartnerType.HIKVISION,
  serialNumber: '',
  retentionTime: 0,
  privateKey: '',
  recordingType: RecordingType.LIVE,
  channelPrivate: false,
  subtypeStream: false,
  user: '',
  password: '',
  url: '',
  urlToFormat: '',
  urlOnlyRecord: '',
  urlOnlyRecordToFormat: '',
  specificUrl: false,
  idMask: '',
  activeTransmissionCreditLimit: false,
  transmissionCreditLimit: 0,
  activeStorageLimit: false,
  storageLimit: 0,
  enablePreAlarm: false,
};

export const normalizeHikvisionChannel = (channel: HikVisionChannel): HikVisionChannel => ({
  ...channel,
  idMask: String(channel.id).padStart(6, '0'),
});

export interface HikvisionBody {
  channel: number;
  serial: string;
  key: string;
  name: string;
}
