import { Box, styled, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TransmissionCostsChart from './TransmissionCostsChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transmissionCostsTitle: {
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
  })
);

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 1),
  flexDirection: 'column',
  flex: '1 1 50%',
}));

const ChartBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  flex: '1 1 auto',
}));

function StreamingTab() {
  const classes = useStyles();
  const { t } = useTranslation(['usage_summary', 'channels']);

  return (
    <Container>
      <Typography className={classes.transmissionCostsTitle} variant="h5">
        {t('transmission_costs')}
      </Typography>
      <ChartBox>
        <TransmissionCostsChart />
      </ChartBox>
    </Container>
  );
}

export default StreamingTab;
