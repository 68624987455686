import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Warning } from '@mui/icons-material';

function TextDeviceOfflineTestingConnection() {
  const { t } = useTranslation('channels');

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} margin={1}>
      <Warning color="warning" />
      <Typography variant="body2">{t('channels:device_offline')}</Typography>
    </Box>
  );
}

export default TextDeviceOfflineTestingConnection;
