import React, { Suspense } from 'react';
import ErrorBoundary, { ErrorBoundaryFallbackProps } from 'components/ErrorBoundary';
import Block from 'components/core/Block';
import { CircularProgress } from '@mui/material';

function DefaultLoading() {
  return (
    <Block open>
      <CircularProgress />
    </Block>
  );
}

export default function asyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent: Function,
  fallbackLoading: NonNullable<React.ReactNode> | null = <DefaultLoading />,
  fallbackError?: React.ReactNode | ((props: ErrorBoundaryFallbackProps) => React.ReactNode)
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any) => (
    <ErrorBoundary fallback={fallbackError}>
      <Suspense fallback={fallbackLoading}>
        <WrappedComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
