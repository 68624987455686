import React from 'react';
import { Box } from '@mui/material';
import { useFetch } from 'hooks';
import { asyncComponent } from 'HOCs';
import { useChannelLimitSettingsApi } from 'hooks/settings/channels/useChannelLimitSettingsApi';
import { ChannelLimitSettings, DEFAULT_CHANNEL_LIMIT_SETTINGS } from 'types/channelLimits.types';
import ChannelLimitForm from '../ChannelLimitForm/ChannelLimitForm';

function ChannelLimitPage() {
  const { addSettings, updateSettings } = useChannelLimitSettingsApi();

  const { data, isValidating } = useFetch<ChannelLimitSettings>(
    '/v1/masterCompanies/settings/channels/limits',
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleCreate = async (limit: ChannelLimitSettings) => {
    await addSettings(limit);
  };

  const handleEdit = async (limit: ChannelLimitSettings) => {
    await updateSettings(limit);
  };

  const initialValues = {
    activeTransmissionCreditLimit: data?.activeTransmissionCreditLimit || false,
    transmissionCreditLimit: data?.transmissionCreditLimit || 0,
    activeStorageLimit: data?.activeStorageLimit || false,
    storageLimit: data?.storageLimit || 0,
  };

  const onSave =
    JSON.stringify(data)
      !== JSON.stringify(DEFAULT_CHANNEL_LIMIT_SETTINGS) ? handleEdit : handleCreate;
  return isValidating ? null : (
    <Box height="100vh">
      <ChannelLimitForm
        {...{
          initialValues,
          onSave,
        }}
      />
    </Box>
  );
}

export default asyncComponent(ChannelLimitPage);
