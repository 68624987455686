import React from 'react';
import { useFetch } from 'hooks';
import { useParams } from 'react-router-dom';

import { IRecordingSchedulerFormWithId, ITimeRangeWithId, RecordingSchedule } from 'types/recordingSchedule.types';
import { useRecordingScheduleCRUD } from './useRecordingScheduleCRUD';
import { RecordingScheduleForm } from './RecordingScheduleForm';

const normalizeScheduleDate = (date: Date) => {
  const today = new Date();

  today.setHours(date.getHours());
  today.setMinutes(date.getMinutes());
  today.setSeconds(date.getSeconds());

  return today;
};

export const normalizeRecordingSchedule = (value: RecordingSchedule) => {
  const { channelsWithClient, ...rest } = value;
  return {
    ...rest,
    customers: channelsWithClient.flatMap((customerWithChannels) => ({
      customerId: customerWithChannels.client.id,
      customerName: customerWithChannels.client.tradeName,
      channelIds: customerWithChannels.channels.map((channel: { id: number }) => channel.id),
      id: String(customerWithChannels.client.id),
    })),
    timeRanges: value.timeRanges.map((timeRange: ITimeRangeWithId) => ({
      ...timeRange,
      startTime: normalizeScheduleDate(new Date(timeRange.startTime)),
      endTime: normalizeScheduleDate(new Date(timeRange.endTime)),
    })),
  };
};

function RecordingScheduleEdit() {
  const { id } = useParams<{ id: string }>();
  const { editRecordingSchedule } = useRecordingScheduleCRUD();

  const handleSubmit = React.useCallback(
    async (values: IRecordingSchedulerFormWithId) => {
      await editRecordingSchedule(values);
    },
    [editRecordingSchedule]
  );

  const { data } = useFetch(`/v1/scheduledRecording/${id}`, {
    cleanCacheAfterMs: 1500,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    normalizeData: normalizeRecordingSchedule,
  });
  if (!data) return null;

  return <RecordingScheduleForm initialValues={data} onSave={handleSubmit} />;
}

export default RecordingScheduleEdit;
