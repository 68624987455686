import React from 'react';
import { TEMPLATE_STRUCTURE } from 'helpers/mosaicTemplates';
import { FormControl, MenuItem, SelectChangeEvent, Select, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Template } from 'types/mosaics.types';
import { AwesomeMosaics } from 'components/core/Icons';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

interface SelectTemplateProps {
  template: Template;
  onChangeTemplate(template: Template): void;
  size: 'small' | 'medium';
  qtyChannelMax: Template;
  title?: string;
}

function SelectTemplate({
  template,
  onChangeTemplate,
  size,
  qtyChannelMax,
  title,
}: SelectTemplateProps) {
  const handleSelectTemplate = (_template: Template) => {
    onChangeTemplate(_template);
  };
  const [maxQtyMosaicFlag] = useDecision(FEATURE_FLAGS_KEYS.MAX_QTY_MOSAIC);
  const { t } = useTranslation();

  const templateMemo = React.useMemo(() => TEMPLATE_STRUCTURE.map(Number)
    .filter((value) => (
      maxQtyMosaicFlag.enabled ? value < 160 : value < 48
    )), [maxQtyMosaicFlag.enabled]);

  const templatesFiltered = templateMemo.filter(
    (templateValue) => templateValue <= qtyChannelMax
  );

  return (
    <FormControl variant="outlined" role="region" aria-label="Change template">
      <Select
        id="template-menu"
        name="template-menu"
        value={template}
        onChange={(event: SelectChangeEvent<Template>) => {
          const newTemplate = event.target.value as Template;
          onChangeTemplate(newTemplate);
        }}
        size={size}
        renderValue={(templateSelected) => (
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <AwesomeMosaics />
            {templateSelected}
          </Box>
        )}
      >
        {templatesFiltered.map((_template) => (
          <MenuItem
            key={_template}
            value={_template}
            onClick={() => handleSelectTemplate(_template as Template)}
            aria-label={`select template with ${_template} slot`}
            hidden={template === _template}
          >
            {_template} {t('_common:channel', { count: _template })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectTemplate;
