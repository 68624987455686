import styled from 'styled-components';
import { Paper, List as MuiList } from '@mui/material';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 33.7625rem;
  max-width: 33.7625rem;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > svg {
    margin-right: 1.2rem;
    transform: translateX(-6px);
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  margin-top: 1rem;
  overflow: auto;
`;

export const NoResultsContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled(MuiList)`
  padding-top: 0;
  > a {
    padding-top: 0;
  }
`;
