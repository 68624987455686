import { asyncComponent } from 'HOCs';
import { useFetch } from 'hooks';
import React from 'react';
import { OperationLogDetail, OperationTags } from 'types/operationLogs.types';
import { Box, Fab, Typography, Paper, Divider, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import useItemTranslation from 'components/OperationLogs/useItemTranslation';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos, NavigateBefore } from '@mui/icons-material';
import routes from 'helpers/routes';
import ApiBoundary from 'components/ApiBoundary';
import NormalizeValue from './NormalizeValue';

const TRANSLATIONS: { [key in OperationTags]: string } = {
  address: 'customers:address',
  address_complement: 'customers:complement',
  city: 'customers:city',
  address_reference: 'customers:reference',
  phone2: 'customers:phone2',
  client_type: 'customers:client_type',
  address_number: 'customers:number',
  phone1: 'customers:phone',
  trade_name: 'customers:trade_name',
  zipcode: 'customers:zipCode',
  client_group: '_common:customer_group',
  company_name: 'customers:company_name',
  district: 'customers:district',
  state: 'customers:state',
  email: 'customers:email',
  name: '_common:name',
  last_name: 'users:last_name',
  permission: 'users:permission',
  first_name: 'users:first_name',
  // TODO tradução de status é igual para as 3 linguas
  status: '_common:status',
  rtsp_url: 'channels:rtsp_url_live',
  url_only_record: 'channels:rtsp_recording_url',
  use_specific_url: 'channels:use_a_specific_url_for_recording',
  recording_type: 'channels:recording_type',
  channel_group: 'channels:channel_group',
  retention: 'calculator:recording_retention',
  channels: '_common:channels',
  items: '_common:items',
  fantasy_name: 'customers:fantasyName',
  federal_registration: 'CNPJ',
  name_photo: 'company:name_photo',
  phone: 'customers:phone',
  storage_limit: 'channels:limit_of_use_of_storage',
  transmission_credit: 'channels:limit_of_use_of_credits',
  rtsp_url_to_format: 'channels:rtsp_url_live_to_format',
  url_only_record_to_format: 'channels:rtsp_recording_url_to_format',
  active_storage_limit: 'channels:use_limit_of_use_of_storage',
  transmission_credit_limit: 'channels:limit_of_use_of_credits',
  active_transmission_credit_limit: 'channels:use_limit_of_use_of_credits',
  company: 'company:company_vinculated',
  companies: 'company:companies_vinculated',
  enable_permission_company: 'company:use_permission_company',
  p2p_partner: 'channels:p2p_partner',
  password: 'channels:password',
  serial_number: 'channels:serial_number',
  username: 'channels:username',
  channel_private: 'channels:private_channel',
  p2p_channel: '_common:channel',
  private_key: 'channels:private_key',
  appkey: 'hikvision_settings:app_key',
  secretkey: 'hikvision_settings:secret_key',
  scheduledRecordingChannel: 'recording_schedule:scheduledRecordingChannel',
  scheduledRecordingTimeRanges: 'recording_schedule:scheduledRecordingTimeRanges',
  simpleScheduledRecording: '_common:recording_schedule',
  subtype_stream: 'channels:secondary_stream',
  enable_pre_alarm: 'channels:enable_pre_alarm',
  port: 'channels:port',
  client_status: 'customers:status',
  enable_fail_tolerance: 'customer:enable_fail_tolerance',
  fail_tolerance_time: 'customer:failToleranceTime',
};

function useTranslateField() {
  const { t } = useTranslation();
  return (tag: OperationTags) => t(TRANSLATIONS[tag]);
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowTitle: {
    flex: '0 0 25%',
    padding: theme.spacing(1),
  },
  rowValueContainer: {
    flex: '0 0 37%',
  },
  previousButton: {
    marginRight: theme.spacing(2),
  },
}));

function OperationLogDetails() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation(['operation_logs', '_common']);
  const translateTag = useTranslateField();
  const translateItem = useItemTranslation();
  const { operationLogId } = useParams<{ operationLogId: string }>();
  const { data: operationLog } = useFetch<OperationLogDetail>(
    `/v1/systemLogs/${operationLogId}/comparePrevious`,
    {
      normalizeData: (data) => ({ ...data, currentDate: new Date(data.currentDate) }),
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  if (!operationLog) return null;

  return (
    <Box
      role="region"
      aria-labelledby="operation_details_title"
      sx={{
        padding: '24px',
        height: 'auto',
        overflow: 'auto',
      }}
    >
      <Box display="flex" alignItems="center" marginBottom="28px">
        <Fab
          size="small"
          onClick={() => {
            history.goBack();
          }}
          aria-label="return"
        >
          <NavigateBefore />
        </Fab>
        <Box flex="1 1 auto">
          <Typography
            id="operation_details_title"
            variant="h6"
            className={classes.title}
            display="inline"
          >
            {t('operation_details_title', {
              objectName: translateItem(operationLog.objectName),
              operation: t(`operation_${operationLog.currentOperation}`),
            })}
          </Typography>
          <Typography variant="body2" display="inline">
            {t('made_by_user_in_date', {
              username: operationLog.currentUserName,
              date: operationLog.currentDate.toLocaleDateString(navigator.language),
            })}
          </Typography>
        </Box>

        <Button
          className={classes.previousButton}
          startIcon={<ArrowBackIos />}
          disabled={!operationLog.lastSystemLogId}
          onClick={() =>
            operationLog.lastSystemLogId &&
            history.push(routes.settings.operationLogs.details(operationLog.lastSystemLogId))
          }
        >
          {t('_common:previous')}
        </Button>
        <ApiBoundary
          key={operationLog.currentSystemLogId}
          fallbackError={<ButtonNextError />}
          fallbackLoading={<ButtonLoading />}
        >
          <ButtonNext currentSystemLogId={operationLog.currentSystemLogId} />
        </ApiBoundary>
      </Box>
      <Paper variant="outlined">
        {operationLog.objectsDiff.map((item) => (
          <>
            <Box className={classes.row} key={item.tag}>
              <Box className={classes.rowTitle}>
                <Typography>{translateTag(item.tag)}</Typography>
              </Box>
              <Divider orientation="vertical" flexItem />

              <Box
                role="region"
                aria-label={`old ${translateTag(item.tag)}`}
                sx={{
                  bgcolor: !item.equals ? '#703A36' : undefined,
                  width: '100%',
                  overflow: 'overlay',
                  overflowWrap: 'break-word',
                }}
              >
                {item.lastValue !== null && JSON.stringify(item.lastValue) !== '{}' && (
                  <NormalizeValue
                    tag={item.tag}
                    value={item.lastValue}
                    sx={(theme) => ({
                      height: '24px',
                      padding: theme.spacing(1),
                    })}
                  />
                )}
              </Box>

              <Divider orientation="vertical" flexItem />
              <Box
                role="region"
                aria-label={`new ${translateTag(item.tag)}`}
                sx={{
                  bgcolor: !item.equals ? '#3D5B3E' : undefined,
                  width: '100%',
                  overflow: 'overlay',
                  overflowWrap: 'break-word',
                }}
              >
                {item.currentValue !== null && JSON.stringify(item.currentValue) !== '{}' && (
                  <NormalizeValue
                    tag={item.tag}
                    value={item.currentValue}
                    sx={(theme) => ({
                      height: '24px',
                      padding: theme.spacing(1),
                    })}
                  />
                )}
              </Box>
            </Box>
            <Divider />
          </>
        ))}
      </Paper>
    </Box>
  );
}

function ButtonNext({ currentSystemLogId }: { currentSystemLogId: number }) {
  const history = useHistory();
  const { t } = useTranslation('_common');
  const { data: nextLog } = useFetch<OperationLogDetail>(
    `/v1/systemLogs/${currentSystemLogId}/compareNext`,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  return (
    <Button
      endIcon={<ArrowForwardIos />}
      disabled={!nextLog}
      onClick={() =>
        nextLog && history.push(routes.settings.operationLogs.details(nextLog.currentSystemLogId))
      }
    >
      {t('_common:next')}
    </Button>
  );
}
function ButtonNextError() {
  const { t } = useTranslation('_common');

  return (
    <Button endIcon={<ArrowForwardIos />} disabled={true}>
      {t('_common:next')}
    </Button>
  );
}

function ButtonLoading() {
  return (
    <Button>
      <CircularProgress size="1.6rem" />
    </Button>
  );
}

export default asyncComponent(OperationLogDetails);
