import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  calculatedItemsSelector,
  checkedItemsIdsAtom,
  editingItemAtom,
  listChannelsAtom,
} from 'atoms/calculator';
import { CalculatedItem } from '../../../types/calculator.types';
import CalculatorForm from '../CalculatorForm/CalculatorForm';
import CalculatorListHeader from '../CalculatorListHeader';
import { DetailsStoragePlan } from '../DetailsStoragePlan/DetailsStoragePlan';
import CalculatorTable from './CalculatorTable/CalculatorTable';
import { CalculatorHeaderTitle } from '../CalculatorHeaderTitle';

const useStyles = makeStyles((theme) => ({
  containerFlex: {
    display: 'flex',
    gap: '2rem',
    height: '100%',
  },
}));

export function CalculatorDashboard({
  setModalOpened,
}: {
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classes = useStyles();
  const listCalculated = useRecoilValue(calculatedItemsSelector);
  const [list, setList] = useRecoilState(listChannelsAtom);
  const checkedItems = useRecoilValue(checkedItemsIdsAtom);
  const setEditingItem = useSetRecoilState(editingItemAtom);

  const listMerged = useMemo(
    () =>
      list.map((item) => ({
        ...item,
        ...(listCalculated.find((calc) => calc.id === item.id) as CalculatedItem),
      })),
    [list, listCalculated]
  );

  if (!list || list.length === 0) {
    return (
      <Box display="flex" flexDirection="column" flex="1 1 auto" overflow="hidden" marginTop="48px">
        <CalculatorHeaderTitle width="100%" bgcolor="background.paper" />
        <Box width="100%" overflow="auto">
          <CalculatorForm
            onCancel={() => setModalOpened(false)}
            showCancelButton={false}
            showImportButton
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box marginTop="3rem" flex="1 1 auto" display="flex" flexDirection="column" overflow="visible">
      <CalculatorListHeader
        handleDelete={() => {
          setList([]);
          setEditingItem(undefined);
        }}
        calculatedItems={listMerged}
        setModalOpened={setModalOpened}
        checkedItems={checkedItems}
      />
      <Box className={classes.containerFlex}>
        <CalculatorTable list={listMerged} setModalOpened={setModalOpened} />

        <DetailsStoragePlan />
      </Box>
    </Box>
  );
}
