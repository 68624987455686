import React from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ApiBoundary from 'components/ApiBoundary';
import { DateRangeValue } from 'components/DateRange';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import DetailReproductionCostsChart from './DetailReproductionCostsChart';
import HeaderTab from './HeaderTab';
import { INIT_RANGE } from './metrics';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 1),
  flexDirection: 'column',
  flex: '1 1 50%',
  overflow: 'hidden',
}));

const ChartBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  flex: '1 1 auto',
  overflow: 'auto',
}));

function ReproductionTab() {
  const { t } = useTranslation(['metrics', 'channels']);
  const query = useQuery();

  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue>(
    startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : INIT_RANGE
  );

  return (
    <Container>
      <HeaderTab
        title={t('playback_of_recorded_video')}
        subtitle={t('by_recording_types')}
        routeFn={routes.metrics.reproduction}
        {...{
          range,
          setRange,
        }}
      />
      <ChartBox>
        <ApiBoundary>
          <DetailReproductionCostsChart startDate={range[0]} endDate={range[1]} />
        </ApiBoundary>
      </ChartBox>
    </Container>
  );
}

export default ReproductionTab;
