// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RadioButtonUnchecked, Edit, Delete } from '@mui/icons-material';
import {
  TableRow,
  TableCell,
  Box,
  Checkbox,
  Typography,
  IconButton,
  Collapse,
  Tooltip,
  useTheme,
} from '@mui/material';
import {
  selectedRows,
  expandAllCalculatorTable,
  listChannelsAtom,
  checkedItemsIdsAtom,
} from 'atoms/calculator';
import { RecordingType } from 'types/channels.types';
import colorByRecordingType from 'helpers/colorByRecordingType';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { formatToReal } from 'helpers/formatToReal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { EnhancedTableBodyProps, useStyles } from './CalculatorTable.types';

export function EnhancedTableBody({
  list,
  handleEditClick,
  isItemSelected,
  item,
}: EnhancedTableBodyProps) {
  const { t } = useTranslation(['_common', 'channels', 'calculator']);
  const theme = useTheme();
  const classes = useStyles();
  const setList = useSetRecoilState(listChannelsAtom);
  const isExpandAll = useRecoilValue(expandAllCalculatorTable);
  const [open, setOpen] = useState(isExpandAll);
  const [selected, setSelected] = useRecoilState(selectedRows);
  const setCheckedItemsIds = useSetRecoilState(checkedItemsIdsAtom);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(
    function activeExpandAll() {
      setOpen(isExpandAll);
    },
    [isExpandAll]
  );

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        aria-checked={isItemSelected}
        key={item.id}
        tabIndex={-1}
        role="checkbox"
        hover
        sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}
      >
        <TableCell padding="checkbox">
          <Box onClick={(e) => e.stopPropagation()} sx={{ marginRight: 1 }}>
            <Checkbox
              id={item.id}
              checked={isItemSelected}
              onClick={(event) => {
                handleClick(event, item.id);
                setCheckedItemsIds((old) => {
                  const aux = { ...old };
                  aux[item.id] = !isItemSelected;
                  return aux;
                });
              }}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="h5" classes={{ root: classes.summaryQtdChannels }} display="inline">
            {item.qtChannels}
          </Typography>
          <Typography variant="body2" display="inline">
            {t('_common:channel', {
              count: Math.abs(item.qtChannels),
            }).toLowerCase()}
          </Typography>
        </TableCell>
        <TableCell sx={{ alignItems: 'center' }}>
          <Box display="flex">
            <RadioButtonUnchecked
              classes={{ root: classes.summaryRecordingTypeSvg }}
              htmlColor={colorByRecordingType(item.recordingType, theme)}
            />
            <Typography>{t('channels:recording_type', { context: item.recordingType })}</Typography>
          </Box>
        </TableCell>
        {item.recordingType !== RecordingType.LIVE && (
          <Tooltip title={`${item.totalSpaceUsed} GB`} placement="bottom-start">
            <TableCell>
              <Typography
                variant="h5"
                classes={{ root: classes.summaryTotalStorage }}
                display="inline"
              >
                {`${item.totalSpaceUsed.toFixed(2)} GB`}
              </Typography>
              <Typography variant="body2" display="inline">
                {t('calculator:used')}
              </Typography>
            </TableCell>
          </Tooltip>
        )}
        {item.recordingType === RecordingType.LIVE && <TableCell />}
        <Tooltip
          title={new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 15,
          }).format(Number(item.totalPrice.toFixed(5)))}
          placement="bottom-end"
        >
          <TableCell align="left">
            <Typography variant="h5" display="inline">
              {formatToReal(item.totalPrice)}
            </Typography>
          </TableCell>
        </Tooltip>
        <TableCell>
          <Box display="flex" alignItems="end" justifyContent="end">
            <IconButton size="small" onClick={handleEditClick(item)}>
              <Edit fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => setList(list.filter((i) => i.id !== item.id))}>
              <Delete fontSize="small" />
            </IconButton>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.containerToggle}>
              <Box marginRight="32px" flex="0 0 auto">
                {/* <Typography>{t('video_properties')}</Typography> */}
                <Typography variant="body2">{item.resolution}</Typography>
                <Typography variant="body2">{item.fps} fps</Typography>
                <Typography variant="body2">{item.bitrate} Kbps</Typography>
              </Box>
              <Box
                flex="1 1 auto"
                textAlign="end"
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-end"
              >
                <Box marginRight="16px" role="region" aria-labelledby="live_video">
                  <Typography variant="body2" id="live_video">
                    {t('calculator:live_video')}
                  </Typography>
                  <Typography>
                    {`${new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      maximumFractionDigits: 15,
                    }).format(Number(item.totalLivePerMinutePrice.toFixed(5)))} (${t(
                      '_common:hours_count',
                      {
                        count: Math.abs(item.timeForecastLiveDisplay),
                      }
                    )})`}
                  </Typography>
                  <Tooltip
                    title={`${t('calculator:transfer_to')} ${t(
                      'calculator:live_video'
                    ).toLowerCase()} `}
                  >
                    <Typography>
                      {`${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        maximumFractionDigits: 15,
                      }).format(
                        Number(item.totalLiveStreamTransferPrice.toFixed(5))
                      )} (${item.liveStreamTransferGB.toFixed(2)} GB)`}
                    </Typography>
                  </Tooltip>
                </Box>
                {item.recordingType !== RecordingType.LIVE && (
                  <>
                    <Box marginRight="16px" role="region" aria-labelledby="recording_retention">
                      <Typography variant="body2" id="recording_retention">
                        {t('calculator:recording_retention')}
                      </Typography>
                      <Typography>{`${t('_common:hours_count', {
                        count: Math.abs(item.recordingRetentionTime),
                      })} `}</Typography>
                    </Box>
                    <Box
                      marginRight="16px"
                      role="region"
                      aria-labelledby="playback_of_recorded_video"
                    >
                      <Typography variant="body2" id="playback_of_recorded_video">
                        {t('calculator:playback_of_recorded_video')}
                      </Typography>
                      <Typography>
                        {`${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          maximumFractionDigits: 15,
                        }).format(
                          Number(item.totalRecordingDisplayPerMinutePrice.toFixed(5))
                        )} (${t('_common:hours_count', {
                          count: Math.abs(item.timeForecastRecordingDisplay),
                        })})`}
                      </Typography>
                      <Tooltip
                        title={`${t('calculator:transfer_to')} ${t(
                          'calculator:playback_of_recorded_video'
                        ).toLowerCase()} `}
                      >
                        <Typography>
                          {`${new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            maximumFractionDigits: 15,
                          }).format(
                            Number(item.totalTransferRecordedVideoPlaybackPrice.toFixed(5))
                          )} (${item.transferRecordedVideoPlaybackGB.toFixed(2)} GB)`}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box marginRight="16px" role="region" aria-labelledby="recording">
                      <Typography variant="body2" id="recording">
                        {t('calculator:recording')}
                      </Typography>
                      <Typography>
                        {`${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          maximumFractionDigits: 15,
                        }).format(Number(item.totalRecordingPerMinutePrice.toFixed(2)))} (${t(
                          '_common:hours_count',
                          {
                            count: Math.abs(item.forecastTimeRecordedByMonth),
                          }
                        )})`}
                      </Typography>
                      <Tooltip
                        title={`${t('calculator:transfer_to')} ${t(
                          'calculator:recording'
                        ).toLowerCase()} `}
                      >
                        <Typography>
                          {`${new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            maximumFractionDigits: 15,
                          }).format(
                            Number(item.totalRecordingTransferPrice.toFixed(2))
                          )} (${item.totalRecordingTransferGB.toFixed(2)} GB)`}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {Number(item.totalRecordedMinutesPrice) > 0 && (
                      <Box marginRight="16px" role="region" aria-labelledby="quantity records">
                        <Typography variant="body2" id="recording">
                          {t('calculator:quantity_of_recordings_by_month')}
                        </Typography>
                        <Typography>
                          {`${new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            maximumFractionDigits: 15,
                          }).format(Number(item.totalRecordedMinutesPrice.toFixed(2)))} (${t(
                            '_common:records',
                            {
                              count: item.quantityOfRecordingsByMonth,
                            }
                          )})`}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
