/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Suspense } from 'react';
import { Box, styled } from '@mui/material';
import { timeDay } from 'd3';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import SelectCustomers from 'components/SelectCustomers';
import SelectCustomerGroup from 'components/SelectCustomerGroup';
import SelectChannelGroupToFilters from 'components/SelectChannelGroupToFilters';
import SelectRecordingTypesToFilters from 'components/SelectRecordingTypeToFilters';
import { FilterSelectValue } from 'types/select.types';
import SelectChannels from 'components/SelectChannels';
import { useTranslation } from 'react-i18next';
import { DateRangeField, DateRangeValue } from 'components/DateRange';
import EventChip from './EventChip';
import FilterEventType from './EventTypeFilter';

const ContainerFilter = styled(Box)({
  '--width': 'calc(100% / 7)',
  width: 'calc(var(--width) - 8px)',
});

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
}));

function EventFilters() {
  const { t } = useTranslation('_common');
  const query = useQuery();
  const history = useHistory();
  const startDateDefault = React.useMemo(() => timeDay.offset(new Date(), -7), []);
  const endDateDefault = React.useMemo(() => new Date(), []);

  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue>(
    startDateS && endDateS
      ? [new Date(startDateS), new Date(endDateS)]
      : [startDateDefault, endDateDefault]
  );

  const selectedCustomersS = query.get('customers');
  const [selectedCustomers, setSelectedCustomers] = useState<FilterSelectValue[]>(
    selectedCustomersS
      ? selectedCustomersS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );
  const selectedRecordingTypesS = query.get('recordingTypes');
  const [selectedRecordingTypes, setSelectedRecordingType] = useState<string[]>(
    selectedRecordingTypesS ? selectedRecordingTypesS.split(',') : []
  );
  const selectedEventTypesS = query.get('eventTypes');
  const [selectedEventTypes, setSelectedEventType] = useState<number[]>(
    selectedEventTypesS ? selectedEventTypesS.split(',').map((c) => Number(c)) : []
  );
  const selectedCustomerGroupsS = query.get('customerGroups');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState<FilterSelectValue[]>(
    selectedCustomerGroupsS
      ? selectedCustomerGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );

  const selectedChannelS = query.get('channels');
  const [selectedChannel, setSelectedChannel] = useState<FilterSelectValue[]>(
    selectedChannelS ? selectedChannelS.split(',').map((id) => ({ id: Number(id), label: '' })) : []
  );
  const selectedChannelGroupsS = query.get('channelGroups');
  const [selectedChannelGroups, setSelectedChannelGroups] = useState<FilterSelectValue[]>(
    selectedChannelGroupsS
      ? selectedChannelGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );

  useEffect(
    function updateWhenChangeSearchParams() {
      const rangeS = startDateS && endDateS ? [startDateS, endDateS].join(',') : null;
      const isRangeEqual = rangeS === (range?.map((d) => d.toISOString()).join(',') || null);
      if (!isRangeEqual) {
        setRange(
          startDateS && endDateS
            ? [new Date(startDateS), new Date(endDateS)]
            : [startDateDefault, endDateDefault]
        );
      }

      const isCustomersEqual = selectedCustomers.map((v) => v.id).join(',') === selectedCustomersS;
      if (!isCustomersEqual) {
        setSelectedCustomers(
          selectedCustomersS
            ? selectedCustomersS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }
      const isRecordingTypesEqual = selectedRecordingTypes.join(',') === selectedRecordingTypesS;
      if (!isRecordingTypesEqual) {
        setSelectedRecordingType(selectedRecordingTypesS ? selectedRecordingTypesS.split(',') : []);
      }
      const isEventTypesEqual = selectedEventTypes.join(',') === selectedEventTypesS;
      if (!isEventTypesEqual) {
        setSelectedEventType(selectedEventTypesS ? selectedEventTypesS.split(',').map(Number) : []);
      }
      const isCustomerGroupsEqual =
        selectedCustomerGroups.map((v) => v.id).join(',') === selectedCustomerGroupsS;
      if (!isCustomerGroupsEqual) {
        setSelectedCustomerGroups(
          selectedCustomerGroupsS
            ? selectedCustomerGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }
      const isChannelsEqual = selectedChannel.map((v) => v.id).join(',') === selectedChannelS;
      if (!isChannelsEqual) {
        setSelectedChannel(
          selectedChannelS
            ? selectedChannelS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }
      const isChannelGroupsEqual =
        selectedChannelGroups.map((v) => v.id).join(',') === selectedChannelGroupsS;
      if (!isChannelGroupsEqual) {
        setSelectedChannelGroups(
          selectedChannelGroupsS
            ? selectedChannelGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  useEffect(
    function updateQueryParams() {
      history.push(
        routes.events.root({
          startDate: range[0],
          endDate: range[1],
          selectedCustomers: selectedCustomers.map((value) => value.id),
          selectedRecordingTypes,
          selectedCustomerGroup: selectedCustomerGroups.map((value) => value.id),
          selectedChannel: selectedChannel.map((value) => value.id),
          selectedChannelGroup: selectedChannelGroups.map((value) => value.id),
          selectedEventTypes,
        })
      );
    },
    [
      history,
      JSON.stringify(selectedCustomers),
      JSON.stringify(selectedRecordingTypes),
      JSON.stringify(selectedCustomerGroups),
      JSON.stringify(selectedChannel),
      JSON.stringify(selectedChannelGroups),
      JSON.stringify(selectedEventTypes),
      JSON.stringify(range[0]),
      JSON.stringify(range[1]),
    ]
  );

  return (
    <>
      <Container aria-label="Filtros do evento" role="region">
        <ContainerFilter>
          <DateRangeField label={t('_common:date')} value={range} setValue={setRange} />
        </ContainerFilter>
        <ContainerFilter>
          <FilterEventType {...{ selectedEventTypes, setSelectedEventType }} />
        </ContainerFilter>
        <ContainerFilter>
          <SelectRecordingTypesToFilters
            {...{ selectedRecordingTypes, onChangeRecordingTypes: setSelectedRecordingType }}
          />
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectCustomers.Loading />}>
            <SelectCustomers {...{ selectedCustomers, onChangeCustomers: setSelectedCustomers }} />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectCustomerGroup.Loading />}>
            <SelectCustomerGroup
              {...{ selectedCustomerGroups, onChangeCustomerGroups: setSelectedCustomerGroups }}
            />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectChannels.Loading />}>
            <SelectChannels
              {...{ selectedChannels: selectedChannel, setSelectedChannels: setSelectedChannel }}
            />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectChannelGroupToFilters.Loading />}>
            <SelectChannelGroupToFilters
              {...{ selectedChannelGroups, onChangeChannelGroups: setSelectedChannelGroups }}
            />
          </Suspense>
        </ContainerFilter>
      </Container>
      <EventChip
        {...{
          range,
          setRange,
          selectedCustomers,
          selectedRecordingTypes,
          selectedCustomerGroup: selectedCustomerGroups,
          selectedChannel,
          selectedChannelGroup: selectedChannelGroups,
          setSelectedCustomers,
          setSelectedRecordingType,
          setSelectedCustomerGroup: setSelectedCustomerGroups,
          setSelectedChannel,
          setSelectedChannelGroup: setSelectedChannelGroups,
          selectedEventTypes,
          setSelectedEventType,
        }}
      />
    </>
  );
}

export default EventFilters;
