import React from 'react';
import { asyncComponent } from 'HOCs';
import { Box, Paper, AppBar, Tabs, Tab } from '@mui/material';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch, useHistory, Switch, Route } from 'react-router-dom';
import { ChannelHeader } from 'components/Channel/ChannelHeader';
import { batchRTSPChannelsAtom } from 'atoms/batchChannel';
import { IntelbrasBatchForm } from 'components/forms/channels/IntelbrasForm/IntelbrasBatchForm';
import HikvisionBatchForm from 'components/HikvisionBatchForm';
import { HikvisionNextView } from 'components/forms/channels/HikvisionForm/HikvisionNextView';
import { useRecoilValue } from 'recoil';
import { rtmpChannelsAtom } from 'atoms/rtmp';
import { useHikVisionApi } from 'hooks/channels/manufactures/useHikvisionApi';
import { useIntelbrasApi } from 'hooks/channels/manufactures/useIntelbrasApi';
import { DEFAULT_HIKVISION, HikVisionChannel } from 'types/hikvision.types';
import { DEFAULT_INTELBRAS, IntelbrasChannel } from 'types/intelbras.types';
import { HikvisionForm, IntelbrasForm, PreviewPage } from 'components/forms/channels';
import { ChannelRoot } from './ChannelRoot';
import BatchPageP2P from './BatchPageP2P';
import BatchPage from './BatchPage';
import { PreviewBatchIntelbras } from '../../components/forms/channels/IntelbrasForm/PreviewBatchIntelbras';

function ChannelNewPage() {
  const { customerId } = useParams<{ customerId: string }>();
  const query = useQuery();
  const match = useRouteMatch(routes.customer.channel.create.root(customerId));
  const matchChildren = useRouteMatch([
    routes.customer.channel.create.selectP2P(customerId),
    routes.customer.channel.create.selectBatchP2P(customerId),
    routes.customer.channel.create.channelCreateBatch(customerId),
    routes.customer.channel.create.root(customerId),
  ]);
  const history = useHistory();
  const { t } = useTranslation();
  const { createChannel: createHikVision } = useHikVisionApi(Number(customerId));
  const { createChannel: createIntelbras } = useIntelbrasApi(Number(customerId));

  const batchRTSPChannels = useRecoilValue(batchRTSPChannelsAtom);
  const batchRTMPChannels = useRecoilValue(rtmpChannelsAtom);

  const handleSubmitHikVision = async (camera: HikVisionChannel) => {
    await createHikVision(camera);
  };
  const handleSubmitIntelbras = async (camera: IntelbrasChannel) => {
    await createIntelbras(camera);
  };

  if (!match) return null;

  return (
    <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <ChannelHeader customerId={Number(customerId)} />
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 1.5,
          overflow: 'hidden',
        }}
      >
        <AppBar position="static" color="transparent" elevation={0}>
          <Box
            component="div"
            sx={{
              borderColor: 'divider',
              borderBottom: '1px solid #464646',
            }}
          >
            <Tabs value={matchChildren?.path} indicatorColor="primary">
              <Tab
                label={t('channels:single_register')}
                value={routes.customer.channel.create.root(customerId)}
                onClick={() => history.push(routes.customer.channel.create.root(customerId))}
              />
              <Tab
                label={t('channels:batch_registration')}
                value={routes.customer.channel.create.channelCreateBatch(customerId)}
                onClick={() =>
                  history.push(routes.customer.channel.create.channelCreateBatch(customerId))
                }
              />
              <Tab
                label={t('channels:register_p2p')}
                value={routes.customer.channel.create.selectP2P(customerId)}
                onClick={() => history.push(routes.customer.channel.create.selectP2P(customerId))}
              />
              <Tab
                label={t('channels:channels_batch_p2p')}
                value={routes.customer.channel.create.selectBatchP2P(customerId)}
                onClick={() =>
                  history.push(routes.customer.channel.create.selectBatchP2P(customerId))
                }
              />
            </Tabs>
          </Box>
        </AppBar>
        <Box sx={{ overflow: 'hidden', paddingTop: 1.5 }}>
          <Switch>
            <Route
              path={routes.customer.channel.create.channelCreateBatch(customerId)}
              exact
              component={() => <ChannelRoot customerId={Number(customerId)} batch />}
            />

            <Route
              path={routes.customer.channel.create.root(customerId)}
              exact
              component={() => <ChannelRoot customerId={Number(customerId)} />}
            />
            <Route
              path={routes.customer.channel.create.rtspChannelsPreview(customerId)}
              exact
              component={() => (
                <PreviewPage
                  onClose={() => {
                    history.push(
                      query.get('returnRoute') ?? routes.customer.channel.create.root(customerId)
                    );
                  }}
                  customerId={customerId}
                  channels={batchRTSPChannels}
                />
              )}
            />

            <Route
              path={routes.customer.channel.create.rtmpChannelsPreview(customerId)}
              exact
              component={() => (
                <PreviewPage
                  onClose={() => {
                    history.push(
                      query.get('returnRoute') ?? routes.customer.channel.create.root(customerId)
                    );
                  }}
                  customerId={customerId}
                  channels={batchRTMPChannels}
                />
              )}
            />

            <Route
              path={routes.customer.channel.create.selectP2P(customerId)}
              exact
              component={() => <BatchPage customerId={customerId} />}
            />
            <Route
              path={routes.customer.channel.create.registerHikvision(customerId)}
              exact
              component={() => (
                <HikvisionForm
                  customerId={customerId}
                  initialValues={DEFAULT_HIKVISION}
                  doSubmit={handleSubmitHikVision}
                  onClose={() =>
                    history.push(
                      query.get('returnRoute') ?? routes.customer.root(Number(customerId))
                    )
                  }
                />
              )}
            />
            <Route
              path={routes.customer.channel.create.registerIntelbras(customerId)}
              exact
              component={() => (
                <IntelbrasForm
                  onClose={() =>
                    history.push(
                      query.get('returnRoute') ?? routes.customer.root(Number(customerId))
                    )
                  }
                  customerId={customerId}
                  initialValues={DEFAULT_INTELBRAS}
                  doSubmit={handleSubmitIntelbras}
                />
              )}
            />
            <Route
              path={routes.customer.channel.create.selectBatchP2P(customerId)}
              exact
              component={() => <BatchPageP2P customerId={customerId} />}
            />

            <Route
              path={routes.customer.channel.create.formBatchIntelbras(customerId)}
              exact
              component={() => (
                <IntelbrasBatchForm
                  customerId={customerId}
                  initialValues={DEFAULT_INTELBRAS}
                  doSubmit={handleSubmitIntelbras}
                />
              )}
            />
            <Route
              path={routes.customer.channel.create.formBatchHikvision(customerId)}
              exact
              component={() => <HikvisionBatchForm customerId={customerId} />}
            />
            <Route
              path={routes.customer.channel.create.previewBatchIntelbras(customerId)}
              exact
              component={() => <PreviewBatchIntelbras customerId={customerId} />}
            />
            <Route
              path={routes.customer.channel.create.previewBatchHikvision(customerId)}
              exact
              component={() => <HikvisionNextView customerId={customerId} />}
            />
          </Switch>
        </Box>
      </Paper>
    </Box>
  );
}

export default asyncComponent(ChannelNewPage);
