import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperationItems } from 'types/operationLogs.types';
import useItemTranslation from './useItemTranslation';

function SelectItem({
  selected,
  onChange,
}: {
  selected: OperationItems | null;
  onChange: (value: OperationItems) => void;
}) {
  const { data: items } = useFetch<OperationItems[]>('/v1/systemLogs/items');
  const { t } = useTranslation('operation_logs');
  const translateItem = useItemTranslation();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-item-label">{t('changed_item')}</InputLabel>
      <Select
        labelId="select-item-label"
        id="select-item"
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value as OperationItems);
        }}
        value={selected || ''}
        label={t('changed_item')}
      >
        {items?.map((item) => (
          <MenuItem key={item} value={item}>
            {translateItem(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectItem.Loading = function SelectItemLoading() {
  const { t } = useTranslation('operation_logs');
  return <SelectFetchLoading label={t('changed_item')} />;
};

export default SelectItem;
