import React from 'react';
import { timeSecond } from 'd3';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DateTimePlayback({
  handleChangeDateTimePicker,
  playbackStartDateDataBar,
  nowLiveDate,
  selectedDate,
  currentTime,
  live,
  handleWatchLive,
}: {
  handleChangeDateTimePicker: (v: Date) => void;
  playbackStartDateDataBar: string | null;
  nowLiveDate: Date;
  selectedDate: Date | null;
  currentTime: number;
  live: boolean;
  handleWatchLive: () => void;
}) {
  const { t } = useTranslation(['video', '_common']);
  const cInputRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [inputFocused, setInputFocused] = React.useState(false);
  const [inputCurrentTime, setInputCurrentTime] = React.useState(0);

  const onChangeDateTimePicker = React.useCallback(
    (date: Dayjs | null) => {
      if (date && date.toDate().toString() !== 'Invalid Date') {
        inputRef.current?.blur();
        cInputRef.current?.focus();
        handleChangeDateTimePicker(date.toDate());
      }
    },
    [handleChangeDateTimePicker]
  );

  const inputValue = React.useMemo(
    () =>
      timeSecond.offset(
        playbackStartDateDataBar ? new Date(playbackStartDateDataBar) : selectedDate || nowLiveDate,
        inputCurrentTime
      ),
    [inputCurrentTime, nowLiveDate, playbackStartDateDataBar, selectedDate]
  );

  React.useEffect(() => {
    if (!inputFocused) {
      setInputCurrentTime(currentTime);
    }
  }, [currentTime, inputFocused]);

  return (
    <Box
      role="region"
      aria-label="Date and time"
      ref={cInputRef}
      tabIndex={0}
      sx={{ outlineWidth: 0, ml: 2, width: '220px' }}
      title={t('video:select_a_day_and_time_to_view_recording')}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDateTimePicker
          renderInput={(props) => (
            <>
              <TextField
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                inputRef={inputRef}
                size="small"
                {...props}
              />
            </>
          )}
          value={inputValue}
          onChange={onChangeDateTimePicker}
          inputFormat="DD/MM/YYYY HH:mm:ss"
          mask="__/__/____ __:__:__"
          OpenPickerButtonProps={{
            'aria-label': 'change date time',
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default DateTimePlayback;
