/* eslint-disable no-nested-ternary */
import {
  Box,
  Paper,
  Typography,
  useTheme,
  alpha as muifade,
  Stack,
  IconButton,
} from '@mui/material';
import { mosaicItemWidescreenSelector } from 'atoms/mosaicItems';
import { liveSelector } from 'atoms/playback';
import { ExportVideoModal } from 'components/ExportVideo';
import ErrorBoundaryVideo from 'components/MosaicVideo/ErrorBoundaryVideo';
import V2ChannelCronologicBar from 'pages/MosaicViewPage/V2BottomPanel/V2ChannelCronologicBar';
import {
  ChannelControlsBar as V2ChannelControlsBar,
  VideoWithPlayback as V2VideoWithPlayback,
} from 'pages/MosaicViewPage/V2BottomPanel';
import routes from 'helpers/routes';
import { asyncComponent } from 'HOCs';
import { useChannelStartLive } from 'hooks';
import EditIcon from '@mui/icons-material/Edit';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useDebounce, useKeyPress, useMeasure } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { VideoLiveSource } from 'types/channels.types';
import { MosaicItem } from 'types/mosaics.types';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import { BIG, EXTRA_BIG, MEDIUM } from 'components/MosaicVideo/responsive.service';
import { metricsStartLoadingAtom } from 'atoms/mosaics';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import VideoSlot from 'components/core/VideoSlot';
import { useMosaicSlotViewPage } from 'hooks/useMosaicSlotView';

function MosaicSlotViewPage() {
  const { mosaicItem, mosaicId } = useMosaicSlotViewPage();

  /* TODO some message when don't have a channel in slot */
  return mosaicItem ? <MosaicChannelView {...{ mosaicItem, mosaicId }} /> : null;
}

function MosaicChannelView({ mosaicItem, mosaicId }: { mosaicItem: MosaicItem; mosaicId: number }) {
  const [nowLiveDate] = useState(new Date());
  const [videoLiveSourceTryAgain, setVideoLiveSource] = React.useState<VideoLiveSource>();
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const widescreen = useRecoilValue(
    mosaicItemWidescreenSelector({
      mosaicId,
      channelId: mosaicItem.channel.id,
    })
  );
  const history = useHistory();
  const { t } = useTranslation('mosaics');
  const [isPressingAlt] = useKeyPress('Alt');
  const [tip, setTip] = useState('');
  const theme = useTheme();
  const { removeChannel } = useMosaicItens(mosaicId);
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: mosaicItem.channel?.id || 0 }));
  const videoLiveSource = useChannelStartLive({ channelId: mosaicItem.channel.id });
  // const [newMosaicDesign] = useDecision(FEATURE_FLAGS_KEYS.NEW_MOSAIC_DESIGN);

  const handleWheel = () => {
    if (!isPressingAlt) setTip(t('press_the_alt_key_to_zoom'));
  };

  useDebounce(
    () => {
      if (tip) {
        setTip('');
      }
    },
    1000,
    [setTip, tip]
  );

  const setMetricsStartLoading = useSetRecoilState(metricsStartLoadingAtom);

  React.useEffect(() => {
    setMetricsStartLoading(new Date());

    return () => {
      setMetricsStartLoading(null);
    };
  }, [setMetricsStartLoading]);

  if (!mosaicItem.channel.clientStatus)
    return (
      <Box
        display="flex"
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        ref={measureRef}
      >
        <Box
          display="flex"
          flex="1 1 auto"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Stack
            alignItems="center"
            direction={BIG.lower(measures) ? 'row' : 'column'}
            spacing={BIG.lower(measures) ? 1 : 0}
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <PersonOffIcon
              sx={{
                fontSize: 99,
              }}
              color="action"
            />
            <Typography
              align="center"
              variant={
                EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
              }
            >
              {t('customers:inactive')}
            </Typography>
          </Stack>
          <Box mb={1}>
            <Typography
              variant={
                EXTRA_BIG.lower(measures)
                  ? BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? 'caption'
                      : 'body2'
                    : 'subtitle2'
                  : 'subtitle1'
              }
              display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
              align="center"
            >
              {t('customers:inactive_description')}
            </Typography>
          </Box>
          <Typography align="center" variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {t('mosaic:inactive_description')}
          </Typography>
          <Box width="100%" mt={4}>
            <Box flex="1 1 auto" textAlign="center">
              <Permissioned role={PermissionRole.EDIT_CLIENT}>
                <IconButton
                  aria-label={`remove channel ${mosaicItem.channel.name} of slot ${mosaicItem.order}`}
                  onClick={() => history.push(routes.customer.edit(mosaicItem.channel.clientId))}
                  size="small"
                  title={t('video:active_customer')}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Permissioned>
            </Box>
          </Box>
        </Box>
      </Box>
    );

  if (!mosaicItem.channel.status)
    return (
      <Box
        display="flex"
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        ref={measureRef}
      >
        <Stack
          alignItems="center"
          direction={BIG.lower(measures) ? 'row' : 'column'}
          spacing={BIG.lower(measures) ? 1 : 0}
          display="flex"
          flexDirection="column"
          mb={5}
        >
          <VisibilityOffIcon
            sx={{
              fontSize: 99,
            }}
            color="action"
          />
          <Typography
            align="center"
            variant={EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'}
          >
            {t('mosaic:slot_inative')}
          </Typography>
        </Stack>
        <Box mb={1}>
          <Typography
            variant={
              EXTRA_BIG.lower(measures)
                ? BIG.lower(measures)
                  ? MEDIUM.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle2'
                : 'subtitle1'
            }
            display={EXTRA_BIG.lower(measures) ? 'none' : 'block'}
            align="center"
          >
            {t('channels:inactive_view')}
          </Typography>
        </Box>
        <Box display="flex" flex="0 0 auto" pb={2} width="100%" position="relative">
          <Box flex="1 1 auto" textAlign="center">
            <Permissioned role={PermissionRole.EDIT_CLIENT}>
              <IconButton
                aria-label={`Edit channel ${mosaicItem.channel.id}`}
                onClick={() =>
                  history.push(
                    routes.customer.channel.edit(
                      mosaicItem.channel.clientId,
                      mosaicItem.channel.id,
                      routes.mosaic.slotView(mosaicId, mosaicItem.order)
                    )
                  )
                }
                size="medium"
                title={t('video:active_channel_to_watch_stream')}
              >
                <EditIcon fontSize="medium" />
              </IconButton>
            </Permissioned>
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box
      position="relative"
      flex="1 1 auto"
      display="flex"
      overflow="hidden"
      flexDirection="column"
    >
      <ErrorBoundaryVideo
        key={String(live)}
        channel={mosaicItem.channel}
        removeChannelClick={() => removeChannel(mosaicItem.order)}
        order={mosaicItem.order}
        errorRetryInterval={1500}
        {...{ mosaicId, setVideoLiveSource }}
      >
        <Box
          flex="1 1 auto"
          display="flex"
          position="relative"
          onWheel={handleWheel}
          onDoubleClick={() => history.push(routes.mosaic.view(mosaicId))}
        >
          <Suspense fallback={<VideoSlot.Loading />}>
            <Suspense
              fallback={
                <VideoSlot.LoadingWithImage
                  channelId={mosaicItem.channel.id}
                  channelPrivate={mosaicItem.channel.channelPrivate}
                />
              }
            >
              {/* {newMosaicDesign?.enabled ? ( */}
              <V2VideoWithPlayback
                channel={mosaicItem.channel}
                videoLiveSource={videoLiveSourceTryAgain || videoLiveSource}
                {...{
                  mosaicId,
                  widescreen,
                  setVideoLiveSource,
                }}
                enableZoom={isPressingAlt}
              />
              {/* ) : ( */}
              {/* <VideoWithPlayback
                  channel={mosaicItem.channel}
                  videoLiveSource={videoLiveSourceTryAgain || videoLiveSource}
                  {...{
                    mosaicId,
                    widescreen,
                  }}
                  enableZoom={isPressingAlt}
                />
              )} */}
            </Suspense>
          </Suspense>
        </Box>
      </ErrorBoundaryVideo>
      <Paper>
        {/* {newMosaicDesign?.enabled ? ( */}
        <V2ChannelCronologicBar
          channelId={mosaicItem.channel.id}
          order={mosaicItem.order}
          height={40}
          {...{ nowLiveDate, mosaicId }}
        />
        {/* ) : (
          <ChannelCronologicBar
            channelId={mosaicItem.channel.id}
            order={mosaicItem.order}
            {...{ nowLiveDate, mosaicId }}
          />
        )} */}
      </Paper>
      {/* {newMosaicDesign?.enabled ? ( */}
      <V2ChannelControlsBar
        channelId={mosaicItem.channel.id}
        {...{
          mosaicId,
          nowLiveDate,
        }}
        flex="0 0 auto"
      />
      {
        /* // ) : ( */
        /* //   <ChannelControlsBar */
        //     channelId={mosaicItem.channel.id}
        //     {...{
        //       mosaicId,
        //       nowLiveDate,
        //     }}
        //     flex="0 0 auto"
        //   />
        // )}
      }
      <ExportVideoModal channel={mosaicItem.channel} {...{ nowLiveDate, mosaicId }} />
      {tip && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={theme.zIndex.appBar}
        >
          <Box bgcolor={muifade(theme.palette.background.default, 0.8)} padding="16px 48px">
            <Typography>{tip}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default asyncComponent(MosaicSlotViewPage);
