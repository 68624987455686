import React, { Suspense } from 'react';
import { mosaicAtom, unstableChannelsAtom } from 'atoms/mosaics';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelectMosaicPanel } from 'hooks/mosaic/useSelectMosaicPanel';
import decodeEntities from 'helpers/decodeEntities';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { NavigationMenu } from 'components/core/Icons';
import { Container } from 'topbar/SettingsTopBar';
import Breadcrumb from 'components/Breadcrumb';
import MosaicActions from './V2MosaicActions';

export default function MosaicTopBarInternal() {
  const { mosaicId } = useParams<{ mosaicId: string }>();
  const [mosaic, setMosaic] = useRecoilState(mosaicAtom(Number(mosaicId)));
  const { t } = useTranslation(['mosaics', '_common']);
  const { toggleMosaicSelection } = useSelectMosaicPanel();
  const havePermissionFn = useHavePermission();
  const canEditMosaic = havePermissionFn(PermissionRole.EDIT_MOSAIC);
  const setUnstableChannels = useSetRecoilState(unstableChannelsAtom);

  React.useEffect(
    function cleanUnstableChannels() {
      setUnstableChannels([]);
    },
    [mosaic?.qtyChannels, setUnstableChannels]
  );

  if (!mosaic) return <MosaicTopBar />;

  const handleBlurTitle: React.FocusEventHandler = (e) => {
    const text = decodeEntities(e.currentTarget.innerHTML);
    text?.trim() ? setMosaic({ ...mosaic, name: text }) : (e.target.innerHTML = mosaic.name);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const text = decodeEntities(e.currentTarget.innerHTML);
      text?.trim()
        ? setMosaic({ ...mosaic, name: text })
        : (e.currentTarget.innerHTML = mosaic.name);
    }
  };

  const handlePaste: React.ClipboardEventHandler = (e) => {
    e.preventDefault();
    let text = '';
    if (e.clipboardData) {
      text = e.clipboardData.getData('text/plain');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  };

  return (
    <Suspense fallback={() => null}>
      <Box role="region" aria-label="mosaic top bar" display="flex" alignItems="center">
        <IconButton
          size="small"
          aria-label="mosaic's list"
          onClick={toggleMosaicSelection}
          sx={{ p: 0, m: 0, mr: 3 }}
        >
          <NavigationMenu />
        </IconButton>
        <Tooltip title={canEditMosaic ? t('_common:click_to_change') : ''} placement="bottom-start">
          <Typography
            variant="vms-h2"
            contentEditable={canEditMosaic}
            onBlur={handleBlurTitle}
            onKeyDown={handleKeyDown}
            spellCheck={false}
            onPaste={handlePaste}
            suppressContentEditableWarning={true}
            sx={{
              cursor: 'text',
              outline: 'none',
              position: 'relative',
              mr: 3,
            }}
          >
            {mosaic.name}
          </Typography>
        </Tooltip>
        {mosaic && <MosaicActions {...{ mosaic, setMosaic }} />}
      </Box>
    </Suspense>
  );
}

export function MosaicTopBar() {
  const { t } = useTranslation();
  const { toggleMosaicSelection } = useSelectMosaicPanel();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title={t('_common:mosaics_list')}>
          <IconButton
            size="small"
            aria-label="mosaic's list"
            onClick={toggleMosaicSelection}
            sx={{ p: 0, m: 0, mr: 3 }}
          >
            <NavigationMenu />
          </IconButton>
        </Tooltip>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.375rem',
            }}
          >
            {t('mosaics:welcome_to_mosaic_page')?.toUpperCase()}
          </Typography>
          <Breadcrumb />
        </Box>
      </Box>
    </Container>
  );
}
