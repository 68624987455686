import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { ChannelLimitSettings } from 'types/channelLimits.types';

export function useChannelLimitSettingsApi() {
  const { t } = useTranslation(['channels', 'permission', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const insertPermission = useHavePermission(PermissionRole.INSERT_CHANNEL);
  const editPermission = useHavePermission(PermissionRole.EDIT_CHANNEL);

  const addSettings = async (data: ChannelLimitSettings): Promise<void> => {
    if (!insertPermission) {
      enqueueError(`${t('limit_error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.post(`/v1/masterCompanies/settings/channels/limits`, data);
      enqueueSuccess(t('limit_created'));
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t('limit_error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  const updateSettings = async (limit: ChannelLimitSettings): Promise<void> => {
    if (!editPermission) {
      enqueueError(`${t('limit_error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.put('/v1/masterCompanies/settings/channels/limits', limit);
      enqueueSuccess(t('limit_changed'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('limit_error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      throw error;
    }
  };
  return {
    addSettings,
    updateSettings,
  };
}
