import React from 'react';
import { Typography, Divider, ListItemButton, ListItem, Badge } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'hooks';
import BadgeActiveContent from 'components/BadgeActiveContent';
import { Customer } from 'types/customer.types';
import { Avatar, TextContainer } from './CustomerListItem.styles';

function CustomerListItem({ customer, loading }: { customer?: Customer; loading?: boolean }) {
  const query = useQuery();
  const selectedCustomerId = query.get('selectedCustomer');
  const selected = customer && Number(selectedCustomerId) === customer.id;

  return (
    <>
      <Divider />
      <ListItem>
        <ListItemButton sx={{ padding: '16px' }} selected={!!selected}>
          {loading ? (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          ) : (
            <Badge
              overlap="circular"
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              badgeContent={<BadgeActiveContent active={!!customer?.status} />}
            >
              <Avatar selected={selected} title={customer?.tradeName} name={customer?.tradeName} />
            </Badge>
          )}

          <TextContainer>
            <Typography variant="body1">{loading ? <Skeleton /> : customer?.tradeName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {loading ? <Skeleton /> : customer?.companyName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {loading ? <Skeleton /> : `ID ${customer?.idMask}`}
            </Typography>
          </TextContainer>
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default CustomerListItem;
