import React, { useMemo, useState } from 'react';
import {
  currentTimeAtom,
  currentZoomChannelBarAtom,
  endDateMergedSelector,
  mergedEventsSelector,
  mergedRecordingRequestsSelector,
  mergedRecordsSelector,
  mergedSelectedDateSelecor,
  playbackStartDateDataBarSelector,
  selectedDateAtom,
  startDateMergedSelector,
} from 'atoms/playback';
import CronologicBar, { BarLoading, Bars } from 'components/V2CronologicBar';
import ApiBoundary from 'components/ApiBoundary';
import { ScaleTime, timeSecond, ZoomTransform } from 'd3';
import { useRecoilValue, useSetRecoilState, useRecoilCallback } from 'recoil';
import { useDebounce } from 'react-use';

function MergedCronologicBar({
  channelsId,
  nowLiveDate,
  height,
  mosaicId,
}: {
  channelsId: number[];
  height?: number;
  nowLiveDate: Date;
  mosaicId: number;
}) {
  const startDate = useRecoilValue(startDateMergedSelector(channelsId));
  const endDate = useRecoilValue(endDateMergedSelector(channelsId));
  const [currentZoomState, setCurrentZoomState] = useState<ZoomTransform>();

  const onSelectDate = useRecoilCallback(
    ({ set }) => (date: Date) => {
      set(mergedSelectedDateSelecor({ mosaicId, channelsId }), date);
    },
    [channelsId]
  );

  const id = useMemo(() => `merged-${channelsId.join('-')}`, [channelsId]);

  return (
    <CronologicBar
      {...{
        id,
        height,
        startDate,
        endDate,
        currentZoomState,
        setCurrentZoomState,
      }}
    >
      {({ left, height: svgHeight, xScale }) => (
        <>
          <ApiBoundary fallbackLoading={<BarLoading />}>
            <MergedBars
              {...{ channelsId, left, xScale, nowLiveDate, onSelectDate, currentZoomState }}
            />
          </ApiBoundary>
          <CronologicBar.XAxis
            height={svgHeight}
            left={left || 0}
            {...{ xScale, id, onSelectDate }}
          />
          {channelsId.map((channelId) => (
            <IndicatorChannel
              key={channelId}
              height={svgHeight}
              {...{ channelId, nowLiveDate, id, left, xScale, mosaicId }}
            />
          ))}
        </>
      )}
    </CronologicBar>
  );
}

function IndicatorChannel({
  channelId,
  nowLiveDate,
  id,
  height,
  left,
  xScale,
  mosaicId,
}: {
  channelId: number;
  nowLiveDate: Date;
  id: string;
  height?: number;
  left?: number;
  xScale: ScaleTime<number, number, never>;
  mosaicId: number;
}) {
  const playbackStartDateDataBar = useRecoilValue(
    playbackStartDateDataBarSelector({ mosaicId, channelId })
  );
  const setSelectedDate = useSetRecoilState(selectedDateAtom({ mosaicId, channelId }));
  const currentTime = useRecoilValue(currentTimeAtom(channelId));

  const dateTime = useMemo(
    () =>
      timeSecond.offset(
        playbackStartDateDataBar ? new Date(playbackStartDateDataBar) : nowLiveDate,
        currentTime
      ),
    [currentTime, nowLiveDate, playbackStartDateDataBar]
  );

  return (
    <CronologicBar.Indicator
      onSelectDate={setSelectedDate}
      id={`${id}-indicator-of-${channelId}`}
      {...{ height, left, xScale, dateTime }}
    />
  );
}

function MergedBars({
  channelsId,
  left,
  xScale,
  onSelectDate,
  currentZoomState,
}: {
  channelsId: number[];
  left?: number;
  xScale: ScaleTime<number, number, never>;
  onSelectDate: (date: Date) => void;
  currentZoomState?: ZoomTransform;
}) {
  const records = useRecoilValue(mergedRecordsSelector(channelsId));
  const events = useRecoilValue(mergedEventsSelector(channelsId));
  const recordingRequests = useRecoilValue(mergedRecordingRequestsSelector(channelsId));

  const changeCurrentZoomState = useRecoilCallback(
    ({ set }) => (newValue: ZoomTransform | undefined) => {
      channelsId.forEach((channelId) => set(currentZoomChannelBarAtom(channelId), newValue));
    },
    []
  );

  useDebounce(
    () => {
      changeCurrentZoomState(currentZoomState);
    },
    500,
    [currentZoomState, changeCurrentZoomState]
  );

  return (
    records && (
      <Bars
        left={left || 0}
        name={`merged-${channelsId.join('-')}`}
        {...{ xScale, onSelectDate, records, events, recordingRequests }}
      />
    )
  );
}

export default MergedCronologicBar;
