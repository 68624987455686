import {
  selectorFamily,
  atomFamily,
  atom,
  RecoilState,
} from 'recoil';
import deepEqual from 'deep-equal';
import { Mosaic, MosaicItem, WidescreenSelector } from 'types/mosaics.types';
import { mosaicAtom } from './mosaics';

// initial data
export const mosaicItensInitialDataAtom = atomFamily<MosaicItem[], number>({
  key: 'mosaicItensInitialDataAtom',
  default: [],
});

// slots alterados
export const mosaicItensValuesAtom = atomFamily<MosaicItem[], number | null>({
  key: 'mosaicItensValuesAtom',
  default: [],
});

export const slotsHasChanges = selectorFamily<boolean, number>({
  key: 'slotsHasChanges',
  get: (mosaicId: number) => ({ get }) => {
    const itemsApi = get(mosaicItensInitialDataAtom(mosaicId));
    const itemsValue = get(mosaicItensValuesAtom(mosaicId));
    return !deepEqual(itemsApi, itemsValue);
  },
});

export const mosaicSlotOrderSelectedAtom = atom<number | null>({
  key: 'mosaicSlotOrderSelectedAtom',
  default: null,
});

export const mosaicItemWidescreenSelector = selectorFamily<boolean, WidescreenSelector>({
  key: 'mosaicItemWidescreenSelector',
  get: ({ mosaicId, channelId }) => ({ get }) => {
    const mosaicItens = get(mosaicItensValuesAtom(mosaicId));
    const mosaicItem = mosaicItens.find((item) => item.channel.id === channelId);
    return mosaicItem?.widescreen || false;
  },
  set: ({ mosaicId, channelId }) => ({ set, get }, newValue) => {
    const mosaicItens = get(mosaicItensValuesAtom(mosaicId));
    set(mosaicItensValuesAtom(mosaicId), (_) =>
      mosaicItens.map((mosaicItem) =>
        mosaicItem.channel.id === channelId ? { ...mosaicItem, widescreen: !!newValue } : mosaicItem
      )
    );
  },
});

export const nextEmptySlotSelector = selectorFamily<number | null, number>({
  key: 'nextEmptySlotSelector',
  get: (id) => ({ get }) => {
    const orderSelected = get(mosaicSlotOrderSelectedAtom);
    const itens = get(mosaicItensValuesAtom(id));
    const { qtyChannels } = get(mosaicAtom(id) as RecoilState<Mosaic>);
    const emptySlots = Array.from({ length: qtyChannels }) // [0,1,2,3]
      .map((_, i) => i + 1) // [1,2,3,4]
      .filter((order) => order !== orderSelected) // remove the selected slot
      .filter((order) => !itens.some((item) => item.order === order)); // filter empty slots
    return emptySlots.length > 0 ? emptySlots[0] : null;
  },
});