import React, { Dispatch, SetStateAction } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DownloadVMSButton } from 'components/DownloadVMSButton';
import { Close } from '@mui/icons-material';

interface IDownloadPopUp {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}
function DownloadVMSPopUp({ opened, setOpened }: IDownloadPopUp) {
  const { t } = useTranslation();
  return !opened ? null : (
    <Box
      sx={(theme) => ({
        width: '100vw',
        bgcolor: theme.palette.common.black,
        height: '57px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'ease-in-out',
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography m={2}>{t('_common:download_vms_windows')}</Typography>
        <DownloadVMSButton.ToPopUp />
      </Box>
      <IconButton sx={{ mr: 2 }}>
        <Close
          sx={(theme) => ({
            color: theme.palette.action.active,
            cursor: 'pointer',
          })}
          onClick={() => {
            setOpened(false);
          }}
        />
      </IconButton>
    </Box>
  );
}
export default DownloadVMSPopUp;
