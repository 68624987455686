import React from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useKeyPressEvent } from 'react-use';
import PlaylistPlayFullscreenView from '../PlaylistPlayPage/PlaylistPlayFullscreenView';

function PlaylistPlayFullscreenPage() {
  const history = useHistory();
  useKeyPressEvent('Escape', () => history.goBack());

  return (
    <Layout.Container>
      <PlaylistPlayFullscreenView />
    </Layout.Container>
  );
}

export default PlaylistPlayFullscreenPage;
