import GridLayout from 'react-grid-layout';
import { IMosaicPage, MosaicData, Template } from 'types/mosaics.types';

export const NUMBER_ROWS = 16;
export const NUMBER_COL = 16;

const normalizeMosaicData = (pages: IMosaicPage[], currentPage: number = 1): MosaicData => ({
  type: 'react-grid-layout',
  pages,
});

const mountPages = (layout: GridLayout.Layout[], totalPage: number = 1): IMosaicPage[] =>
  Array.from({ length: totalPage }).map((page, currentPageIndex) => ({
    offset: currentPageIndex + 1,
    payload: layout.map((item, index) => ({
      ...item,
      i: String(currentPageIndex * layout.length + (index + 1)),
    })),
  }));

const mountTemplate = (qtyColumns: number, qtyRows: number, totalPage: number = 1) => {
  const widthSlot = NUMBER_COL / qtyColumns;
  const heightSlot = NUMBER_ROWS / qtyRows;
  const size = { w: widthSlot, h: heightSlot };

  const linhas = Array.from({ length: qtyRows }).map((_, index) => ({ y: index * heightSlot }));
  const colunas = Array.from({ length: qtyColumns }).map((_, index) => ({ x: index * widthSlot }));

  return normalizeMosaicData(
    mountPages(
      linhas
        .map((linha) => colunas.map((coluna) => ({ ...size, ...linha, ...coluna, i: '0', moved: false, static: false, })))
        .flat(),
      totalPage
    )
  );
};

type IMosaicTemplate = (totalPage?: number) => { [t in Template]: MosaicData };

const mosaicTemplates: IMosaicTemplate = (totalPage: number = 1) => ({
  1: mountTemplate(1, 1, totalPage),
  2: mountTemplate(2, 1, totalPage),
  4: mountTemplate(2, 2, totalPage),
  8: mountTemplate(4, 2, totalPage),
  16: mountTemplate(4, 4, totalPage),
  25: mountTemplate(5, 5, totalPage),
  32: mountTemplate(8, 4, totalPage),
  36: mountTemplate(6, 6, totalPage),
  48: mountTemplate(8, 6, totalPage),
  64: mountTemplate(8, 8, totalPage),
  80: mountTemplate(10, 8, totalPage),
  100: mountTemplate(10, 10, totalPage),
  160: mountTemplate(16, 10, totalPage),
});

export default mosaicTemplates;

export const TEMPLATE_STRUCTURE = Object.keys(mosaicTemplates())
  .map(Number)

export function getTemplate(qtChannels: number) {
  return (
    TEMPLATE_STRUCTURE.find((templateValue) => qtChannels <= templateValue) ||
    TEMPLATE_STRUCTURE[TEMPLATE_STRUCTURE.length - 1]
  );
}

export const DEFAULT_MOSAIC_VALUES = {
  name: '',
  qtyChannels: 4 as Template,
  mosaicData: mosaicTemplates()[4],
};
