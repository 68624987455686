import React from 'react';
import { useFetch } from 'hooks';
import { asyncComponent } from 'HOCs';
import { HikvisionSettingsForm } from './HikvisionSettingsForm';
import { HikvisionSettingsProps, useHikvisionSettings } from './useHikvisionSettings';

function HikvisionSettingsPage() {
  const { createConfig, updateConfig } = useHikvisionSettings();
  const { data: fetchSettings, isValidating } = useFetch<HikvisionSettingsProps>(
    '/v1/masterCompanies/settings/hikvision',
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  if (!fetchSettings) return null;

  const handleCreate = async (config: HikvisionSettingsProps) => {
    await createConfig({
      ...config,
      id: fetchSettings.id,
    });
  };

  const handleUpdate = async (config: HikvisionSettingsProps) => {
    await updateConfig({
      ...config,
      id: fetchSettings.id,
    });
  };

  const initialValues = {
    appKey: fetchSettings?.appKey?.length ? fetchSettings.appKey : '',
    secretKey: fetchSettings?.secretKey?.length ? fetchSettings.secretKey : '',
  };
  const onSave =
    fetchSettings?.appKey?.length || fetchSettings?.secretKey?.length ? handleUpdate : handleCreate;

  return isValidating ? null : (
    <HikvisionSettingsForm
      {...{
        initialValues,
        onSave,
      }}
    />
  );
}

export default asyncComponent(HikvisionSettingsPage);
