import React from 'react';
import {
  Box,
  Link,
  Paper,
  TableContainer,
  Table as TableMui,
  Theme,
  Typography,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import createStyles from '@mui/styles/createStyles';
import { useFetch } from 'hooks';
import { Link as RouteLink } from 'react-router-dom';
import routes from 'helpers/routes';
import Table from 'components/Table';
import { getComparator, Order, TableData } from 'types/table.types';
import { Channel, Customer } from 'types/detailList.types';
import useColumns from 'hooks/useColumns';
import { formatToReal } from 'helpers/formatToReal';

const channelListColumns = {
  marginLeft: '8px',
  overflow: 'hidden',
};

const channelListItem = {
  display: 'grid',
  grid: 'auto / 2fr 0.5fr 1fr 0.5fr 1fr 1.5fr 1.5fr 1.5fr 2fr 1.5fr 1.5fr 1.5fr',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '16px 8px',
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '99%',
    },
    channelList: {
      flex: '1 1 auto',
    },
    channelListColumns,
    channelListItem,
    channelAlignRight: {
      justifySelf: 'end',
      ...channelListColumns,
    },
    channelListHeader: {
      padding: '8px 4px',
      backgroundColor: theme.palette.background.default,
      ...channelListItem,
    },
    container: {
      flex: '1 1 auto',
      marginBottom: theme.spacing(3),
    },
    containerRounded: {
      flex: '1 1 auto',
      marginBottom: theme.spacing(3),
      borderRadius: '5px',
      width: '100%',
    },
    rowStriped: {
      border: 'none',
      backgroundColor: theme.palette.secondary.dark,
    },
    rowNormal: {
      backgroundColor: theme.palette.secondary.light,
    },
    spacing: {
      marginBottom: '24px',
      marginTop: '48px',
    },
  })
);

function DetailsList({ startDate, endDate }: { startDate: Date; endDate: Date }) {
  const { t } = useTranslation(['usage_summary', 'channels', 'metrics', '_common']);
  const classes = useStyles();
  const columns = useColumns({ t });
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TableData>('name');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { data } = useFetch<Customer[]>('/v1/consumption/details', {
    baseURL: process.env.REACT_APP_BASE_URL_CONSUMPTION,
    params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    normalizeData: (items) =>
      items.map((item) => ({
        ...item,
        // duration: item.duration * 1000,
        channels: item.channels.map((channel) => ({
          ...channel,
          recordedVideoDuration: channel.recordedVideoDuration * 1000,
          vodDuration: channel.vodDuration * 1000,
        })),
      })),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  // const { data: dataLive } = useFetch<Live[]>('/v1/consumption/details/live', {
  //   baseURL: process.env.REACT_APP_BASE_URL_CONSUMPTION,
  //   params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
  //   normalizeData: (items) => items.map((item) => ({ ...item, duration: item.duration * 1000 })),
  // });

  if (!data?.length) {
    return (
      <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h5">{t('_common:there_is_no_detail_cost')}</Typography>
      </Box>
    );
  }

  return (
    <Paper className={classes.root}>
      <Box overflow="auto">
        {data?.map((customer) => (
          <Box key={`container-${customer.clientId}`} mr={2}>
            <Link
              component={RouteLink}
              to={routes.customer.view(customer.clientId)}
              color="textPrimary"
              variant="body2"
            >
              <Typography variant="h5" className={classes.spacing}>
                {`${customer.tradeName} (${customer.companyName})`}
              </Typography>
            </Link>
            <TableContainer className={classes.containerRounded}>
              <TableMui aria-label="Detalhes" size="medium">
                <Table.HeaderDetails
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns(customer.clientId)}
                />

                <TableBody aria-label="Corpo da tabela">
                  {customer.channels // @ts-ignore --next-line
                    .map<TableData>((channel) => channel)
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((row) => (
                      <>
                        <TableRow
                          className={classes.rowNormal}
                          key={row.id}
                          aria-label={`linha dos recordings ${row.id}`}
                          sx={{
                            width: '100%',
                          }}
                        >
                          {columns(customer.clientId).map((column) => (
                            <TableCell
                              key={`${column.dataKey}-${row.id}-${column.description}`}
                              aria-label={column.description}
                              sx={{ border: 'none' }}
                            >
                              <Table.Cell {...{ row, column }} />
                            </TableCell>
                          ))}
                        </TableRow>
                        <ConsumptionValueRow
                          {...{
                            liveAmount: row.liveAmount,
                            recordedVideoDurationAmount: row.recordedVideoDurationAmount,
                            recordedVideoSizeAmount: row.recordedVideoSizeAmount,
                            vodDurationAmount: row.vodDurationAmount,
                            liveTransferAmount: row.liveTransferAmount,
                            id: row.id,
                          }}
                        />
                      </>
                    ))}
                </TableBody>
              </TableMui>
            </TableContainer>
            <TotalValueRow key={`total-${customer.clientId}`} channels={customer.channels} />
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default DetailsList;

interface ConsumptionValueRowProps {
  liveAmount: number;
  recordedVideoDurationAmount: number;
  recordedVideoSizeAmount: number;
  vodDurationAmount: number;
  liveTransferAmount: number;
  id: number;
}
function ConsumptionValueRow({
  liveAmount,
  recordedVideoDurationAmount,
  recordedVideoSizeAmount,
  vodDurationAmount,
  liveTransferAmount,
  id,
}: ConsumptionValueRowProps) {
  const { t } = useTranslation();
  const totalValue = React.useMemo(
    () =>
      [
        liveAmount,
        recordedVideoDurationAmount,
        recordedVideoSizeAmount,
        vodDurationAmount,
        liveTransferAmount,
      ].reduce((total, next) => total + next),
    [
      liveAmount,
      recordedVideoDurationAmount,
      recordedVideoSizeAmount,
      vodDurationAmount,
      liveTransferAmount,
    ]
  );

  return (
    <TableRow
      aria-label={`linha dos resultados ${id}`}
      sx={(theme) => ({
        border: 'none',
        backgroundColor: theme.palette.secondary.dark,
        width: '100%',
      })}
    >
      <TableCell
        sx={{
          fontWeight: 'bold',
          minWidth: '150px',
        }}
      >
        {t('metrics:amount_consumed')}
      </TableCell>
      {Array.from({ length: 7 }, (_, i) => (
        <TableCell key={i} />
      ))}
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(liveTransferAmount)}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(liveAmount)}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(recordedVideoDurationAmount)}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(recordedVideoSizeAmount)}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(vodDurationAmount)}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: 'bold',
        }}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(totalValue)}
      </TableCell>
    </TableRow>
  );
}
interface TotalValueRowProps {
  channels: Channel[];
}
function TotalValueRow({ channels }: TotalValueRowProps) {
  const { t } = useTranslation();
  const [total, setTotal] = React.useState<number[]>();

  React.useMemo(
    function sumTotalValues() {
      setTotal(
        channels
          ?.map((channel) => channel as TableData)
          ?.map((channel) =>
            [
              channel.liveAmount,
              channel.liveTransferAmount,
              channel.recordedVideoDurationAmount,
              channel.recordedVideoSizeAmount,
              channel.vodDurationAmount,
            ].reduce((totalValue, next) => totalValue + next)
          )
      );
    },
    [channels, setTotal]
  );

  return (
    <TableRow
      sx={(theme) => ({
        backgroundColor: theme.palette.common.black,
        borderRadius: '4px',
        borderBottom: 'none',
        marginTop: '1.2rem',
        width: '100%',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      })}
    >
      <TableCell
        sx={{
          border: 'none',
          minWidth: '150px',
        }}
      >
        <Typography fontWeight="bold">{t('metrics:total_cost')}</Typography>
      </TableCell>
      {Array.from({ length: 6 }, (_, i) => (
        <TableCell width="100%" sx={{ border: 'none' }} key={i} />
      ))}

      <TableCell
        sx={{
          border: 'none',
          minWidth: '120px',
          paddingRight: '2rem',
        }}
      >
        <Typography fontWeight="bold" textAlign="left">
          {formatToReal(total?.reduce((totalValue, next) => totalValue + next) || 0)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
