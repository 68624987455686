import React from 'react';
import { Paper, Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AttachMoney, DeleteSweep, AddCircle, GetApp } from '@mui/icons-material';
import { CalculatedItem, CalculatorEnteredData } from 'types/calculator.types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { useMessages } from 'hooks';
import { CellValue, Column } from 'types/table.types';
import { CollapseAll, ExpandAll, ExportPDF } from 'components/core/Icons';
import {
  addPricesModalOpenedAtom,
  editingItemAtom,
  expandAllCalculatorTable,
  listChannelsAtom,
  selectedPlanAtom,
  totalValueCalculatedSelector,
} from 'atoms/calculator';
import { formatBytes } from 'helpers/bytesOperations';
import exportCalculatorAsPDF from 'services/exportCalculatorAsPDF';
import GetFileNameModal from './GetFileNameModal';
import { CalculatorList } from './CalculatorDashboard/CalculatorTable/CalculatorTable.types';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(4),
    display: 'flex',
  },
}));

function transformedValue(value: CellValue): string {
  return typeof value === 'number' ? `${Number(value.toFixed(2))}` : String(value);
}

function useColumns({
  t,
}: {
  t: TFunction<Namespace<'_common' | 'channels' | 'calculator' | 'metrics'>>;
}): Column<CalculatedItem & CalculatorEnteredData>[] {
  return React.useMemo(
    () => [
      {
        description: t('_common:channels'),
        dataKey: 'qtChannels',
      },
      {
        description: t('_common:resolution'),
        dataKey: 'resolution',
      },
      {
        description: 'FPS',
        dataKey: 'fps',
      },
      {
        description: `${t('_common:bitrate')} (Kbps)`,
        dataKey: 'bitrate',
      },
      {
        description: t('channels:recording_type'),
        dataKey: 'recordingType',
        renderValue: (value) => t(`channels:recording_type_${value}`),
      },
      {
        description: t('_common:pre_alarm'),
        dataKey: 'enablePreAlarm',
        renderValue: (value) => (value ? t('_common:active') : t('_common:inactive')),
      },
      {
        description: t('calculator:live_video'),
        dataKey: 'timeForecastLiveDisplay',
        renderValue: (value) =>
          t('_common:hours_count', {
            count: Math.abs(value as number),
          }),
      },
      {
        description: t('calculator:recording_retention'),
        dataKey: 'recordingRetentionTime',
        renderValue: (value) =>
          t('_common:hours_count', {
            count: Math.abs(value as number),
          }),
      },
      {
        description: t('calculator:playback_of_recorded_video'),
        dataKey: 'timeForecastRecordingDisplay',
        renderValue: (value) =>
          t('_common:hours_count', {
            count: Math.abs(value as number),
          }),
      },
      {
        description: t('metrics:recording'),
        dataKey: 'forecastTimeRecordedByMonth',
        renderValue: (value) =>
          t('_common:hours_count', {
            count: Math.abs(value as number),
          }),
      },
      {
        description: t('metrics:storage'),
        dataKey: 'totalSpaceUsed',
        renderValue: (value) => `${Number(value).toFixed(2)} GB`,
        align: 'center',
      },
      {
        description: `${t('calculator:partial')} (R$)`,
        dataKey: 'totalPrice',
        renderValue: (value) => transformedValue(value),
        align: 'left',
      },
    ],
    [t]
  );
}

function CalculatorListHeader({
  setModalOpened,
  calculatedItems,
  handleDelete,
  checkedItems,
}: {
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  calculatedItems: CalculatorList[];
  handleDelete: () => void;
  checkedItems: { [key: string]: boolean | undefined };
}) {
  const classes = useStyles();
  const setAddPriceModalOpened = useSetRecoilState(addPricesModalOpenedAtom);
  const { t } = useTranslation();
  const { enqueueError } = useMessages();
  const columns = useColumns({ t });
  const [getFileNameModalOpened, setGetFileNameModalOpened] = React.useState(false);
  const [fileNameJSON, setFileNameJSON] = React.useState('');
  const data = useRecoilValue(listChannelsAtom);
  const setEditingItemAtom = useSetRecoilState(editingItemAtom);
  const [isExpandAll, setIsExpandAll] = useRecoilState(expandAllCalculatorTable);

  const transmission = useRecoilValue(totalValueCalculatedSelector);
  const selectedPlan = useRecoilValue(selectedPlanAtom);
  const checkedItemsList: CalculatorList[] = React.useMemo(
    () => calculatedItems.filter((item) => checkedItems[item.id]),
    [calculatedItems, checkedItems]
  );

  const validateCanDownload = (fn: () => void) => {
    if (checkedItemsList.length === 0) {
      enqueueError(t('calculator:you_need_select_some_item_to_download'));
      return;
    }
    fn();
  };
  const handleDownloadAsPDF = () => {
    exportCalculatorAsPDF({
      t,
      rows: checkedItemsList,
      columns,

      planStorage: {
        plan: formatBytes(selectedPlan?.storageSizeBytes),
        value: selectedPlan?.value,
      },
      total: transmission,
    });
  };

  const handleDownloadAsJSON = () => {
    setGetFileNameModalOpened(true);
  };

  const handleExpandAll = () => {
    setIsExpandAll(!isExpandAll);
  };

  const handleNew = () => {
    setEditingItemAtom(undefined);
    setModalOpened(true);
  };

  React.useEffect(
    function doDownloadAsJSON() {
      if (fileNameJSON) {
        const link = document.createElement('a');
        link.className = 'download-helper';
        link.download = `${fileNameJSON}.json`;
        link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify({
            timestamp: new Date().toISOString(),
            data: checkedItemsList,
          })
        )}`;
        link.click();
        setFileNameJSON('');
      }
    },
    [fileNameJSON, data, checkedItemsList]
  );

  return (
    <Paper className={classes.header} role="region" aria-label="Calculator's list header">
      <Box marginRight="16px" flex="1 1 auto">
        <Tooltip title={t('calculator:add_prices')}>
          <IconButton
            onClick={() => setAddPriceModalOpened(true)}
            size="small"
            aria-label={t('calculator:add_prices')}
          >
            <AttachMoney />
          </IconButton>
        </Tooltip>
      </Box>
      <Tooltip title={t('calculator:export_to_JSON')}>
        <IconButton
          onClick={() => validateCanDownload(handleDownloadAsJSON)}
          size="small"
          aria-label={t('calculator:export_to_JSON')}
        >
          <GetApp fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('calculator:export_to_pdf')}>
        <IconButton
          onClick={() => validateCanDownload(handleDownloadAsPDF)}
          size="small"
          aria-label={t('calculator:export_to_pdf')}
        >
          <ExportPDF fontSize="medium" />
        </IconButton>
      </Tooltip>
      {isExpandAll ? (
        <Tooltip title={t('_common:collapse_all') || ''}>
          <IconButton onClick={handleExpandAll} size="small">
            <CollapseAll fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('_common:expand_all') || ''}>
          <IconButton onClick={handleExpandAll} size="small">
            <ExpandAll fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('_common:clear_list')}>
        <IconButton onClick={handleDelete} size="small">
          <DeleteSweep fontSize="medium" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('calculator:add_a_new_item')}>
        <IconButton
          onClick={handleNew}
          size="small"
          color="primary"
          autoFocus
          aria-label={t('calculator:add_a_new_item')}
        >
          <AddCircle fontSize="medium" />
        </IconButton>
      </Tooltip>
      <GetFileNameModal
        open={getFileNameModalOpened}
        onClose={() => setGetFileNameModalOpened(false)}
        setFileName={setFileNameJSON}
      />
    </Paper>
  );
}

export default CalculatorListHeader;
