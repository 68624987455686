/* eslint-disable no-param-reassign */
import axios from 'axios';
import { URL_LOGIN, getTokenFromStorageIfValid, getTokenFromUrlIfValid } from 'services/auth';

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${
    getTokenFromUrlIfValid() || getTokenFromStorageIfValid()
  }`;
  config.baseURL = config.baseURL || BASE_URL;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (
      (error.response && error.response.status === 401) ||
      (error.request && error.request.status === 401)
    ) {
      window.location.assign(URL_LOGIN);
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  }
);
