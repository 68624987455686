import { atom } from 'recoil';
import { User } from 'types/user.types';

export const usersLastChangeAtom = atom<number>({
  key: 'usersLastChangeAtom',
  default: Date.now(),
});

export const userSearchTextAtom = atom<string>({
  key: 'userSearchTextAtom',
  default: '',
});

export const loggedUserAtom = atom<User | null>({
  key: 'loggedUserAtom',
  default: null,
});
