import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';

export function CodecNotSupportedMessage() {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} margin={1}>
      <Error color="error" />
      <Typography variant="body2">{t('api_errors_messages:codec_not_supported')}</Typography>
    </Box>
  );
}
