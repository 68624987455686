import React from 'react';
import SpeedTest, { Results } from '@cloudflare/speedtest';
import {
  ClientPlataform,
  SpeedTestDTO,
  TagLabel,
} from 'pages/SettingsPage/tabs/SpeedTest/types/speedTest.types';
import { useClientPlataform } from 'hooks/useClientPlataform';

type SummarySpeedTest = {
  download: number; // Velocidade de download em bytes
  upload: number; // Velocidade de upload em bytes
  latency: number; // Latência em milissegundos (ms)
  jitter: number; // Jitter em milissegundos (ms)
  downloadedJitter: number; // Jitter durante o download em milissegundos (ms)
  downloadedLatency: number; // Latência do download em milissegundos (ms)
  uploadedJitter: number; // Jitter durante o upload em milissegundos (ms)
  uploadedLatency: number; // Latência do upload em milissegundos (ms)
};

export default function useCloudflareSpeedTest() {
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFinished, setIsFinished] = React.useState(false);
  const [result, setResult] = React.useState<SpeedTestDTO | null>(null);
  const [labelStatus, setLabelStatus] = React.useState<TagLabel>('default');
  const clientPlataform = useClientPlataform();

  const speedTestRef = React.useRef<SpeedTest | null>(null);

  React.useEffect(function createSpeedTest() {
    speedTestRef.current = new SpeedTest({
      autoStart: false,
    });

    speedTestRef.current.onFinish = (_result) => {
      const resultDTO = createDTO(_result, clientPlataform);
      setResult(resultDTO);
      setIsLoading(false);
      setIsFinished(true);
    };

    speedTestRef.current.onResultsChange = ({ type }) => {
      if (!speedTestRef.current?.isRunning) {
        setLabelStatus('default');
        return;
      }

      if (type === 'download') {
        setLabelStatus('download');
      } else if (type === 'upload') {
        setLabelStatus('upload');
      } else {
        setLabelStatus('latencyJitterAndPackages');
      }
    };

    speedTestRef.current.onError = (_error) => {
      console.error({
        _error,
      });
      setError(error);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStart = React.useCallback(() => {
    setIsLoading(true);
    speedTestRef.current?.play();
    setLabelStatus('connecting');
  }, []);

  const onRestart = React.useCallback(() => {
    setIsLoading(true);
    setIsFinished(false);
    speedTestRef.current?.restart();
  }, []);

  const onPause = React.useCallback(() => {
    speedTestRef?.current?.pause();
    setLabelStatus('default');
    setIsLoading(false);
  }, []);

  return {
    result,
    error,
    isLoading,
    isFinished,
    isRunning: !!speedTestRef.current?.isRunning,
    labelStatus,
    onStart,
    onRestart,
    onPause,
  };
}

function createDTO(result: Results, clientPlataform: ClientPlataform): SpeedTestDTO {
  const summaryResults = result.getSummary() as SummarySpeedTest;
  const score = result.getScores();
  const packageLoss = Number(result.getPacketLoss());
  const arrPings: number[] =
    // @ts-ignore
    result.raw.latency.results.timings.map((_timing) => _timing.ping) || [];
  const latencyPings = arrPings.reduce((acc: number, ping: number) => acc + ping, 0);
  const ping = latencyPings / arrPings.length;

  return {
    jitter: summaryResults.jitter,
    download: summaryResults.download,
    upload: summaryResults.upload,
    latency: summaryResults.latency,
    ping,
    // API pode retornar um valor null para o packageLoss
    packageLoss: packageLoss ?? 0,
    clientPlataform,
    classification: {
      classificationIdx: score.streaming.classificationIdx,
      classificationName: score.streaming.classificationName,
    },
  };
}
