import { Clear } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecordingSchedule, SimpleScheduledRecording } from 'types/recordingSchedule.types';

interface Props {
  selectedRecord?: SimpleScheduledRecording | null;
  setSelectedRecord: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  error: boolean | undefined;
  helperText?: string | undefined;
}
function SchedulingRecordSelect({ selectedRecord, setSelectedRecord, error, helperText }: Props) {
  const { data: recordingSchedules } = useFetch<RecordingSchedule[]>('/v1/scheduledRecording');
  const { t } = useTranslation('_common');

  const handleClear = () => {
    setSelectedRecord({
      // @ts-ignore
      target: {
        name: 'simpleScheduledRecording',
        // @ts-ignore
        value: null,
      },
    });
  };

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="event-filter-recordingSchedule-label">
        {t('_common:recording_schedules')}
      </InputLabel>
      <Select
        labelId="event-filter-recordingSchedule-label"
        id="simpleScheduledRecording"
        name="simpleScheduledRecording"
        onChange={(e) =>
          setSelectedRecord({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: {
                id: Number(e.target.value),
                companyName:
                  recordingSchedules?.find((record) => record.id === Number(e.target.value))
                    ?.name || '',
              },
            },
          })
        }
        value={selectedRecord?.id ?? ''}
        label={t('_common:recording_schedules')}
        endAdornment={
          selectedRecord?.id ? (
            <Tooltip title={t('_common:clear')}>
              <IconButton
                size="small"
                onClick={handleClear}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  marginRight: 30,
                }}
              >
                <Clear />
              </IconButton>
            </Tooltip>
          ) : null
        }
      >
        {recordingSchedules?.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

SchedulingRecordSelect.Loading = function SelectLoading() {
  const { t } = useTranslation();
  return <SelectFetchLoading label={t('_common:loading_schedule')} />;
};

export default SchedulingRecordSelect;
