import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ZoomOut(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <mask
        id="mask0_212_10340"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_212_10340)">
        <path
          d="M3.5 21V15H5.5V17.6L8.6 14.5L10 15.9L6.9 19H9.5V21H3.5ZM15.5 21V19H18.1L15 15.9L16.4 14.5L19.5 17.6V15H21.5V21H15.5ZM8.6 9.5L5.5 6.4V9H3.5V3H9.5V5H6.9L10 8.1L8.6 9.5ZM16.4 9.5L15 8.1L18.1 5H15.5V3H21.5V9H19.5V6.4L16.4 9.5Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default ZoomOut;
