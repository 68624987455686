import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.grey[500],
    fontSize: '0.85rem',
    textAlign: 'left',
    padding: '0 1rem',
    margin: '1rem',
  },
}));

export function HelperTotalStorage() {
  const classes = useStyles();
  const { t } = useTranslation(['calculator', 'metrics']);

  return <Typography className={classes.helperText}>{t('total_costs_helper_text')}</Typography>;
}
