import { useRecoilValue } from 'recoil';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { userSearchTextAtom } from 'atoms/users';
import { User } from 'types/user.types';
import useMessages from '../useMessages';

export default function useUserApi() {
  const { t } = useTranslation(['users', 'crud_actions', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();

  const userSearchText = useRecoilValue(userSearchTextAtom);
  const listFetchKey = ['/v1/users', userSearchText];

  const createUser = async (userReceived: User) => {
    try {
      const transformedData = {
        ...userReceived,
        companies: userReceived.enablePermissionCompany
          ? userReceived.companies.map((e) => ({ id: e }))
          : [],
      };

      await Axios.post('/v1/users', transformedData);
      enqueueSuccess(t('users:user_created'));
      mutate(listFetchKey, async (users: User[] = []) => [{ ...userReceived, id: -1 }, ...users]);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('users:error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const editUser = async (userReceived: User) => {
    try {
      const transformedData = {
        ...userReceived,
        companies:
          userReceived.companies.length >= 1 ? userReceived.companies.map((e) => ({ id: e })) : [],
      };

      await Axios.put(`/v1/users/${userReceived.id}`, transformedData);
      enqueueSuccess(t('users:user_changed'));
      mutate(listFetchKey, async (users: User[]) =>
        users.map((_user: User) => (_user.id === userReceived.id ? userReceived : _user))
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('users:error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const deleteUser = async (id: number) => {
    try {
      await Axios.delete(`/v1/users/${id}`);
      enqueueSuccess(t('users:user_deleted'));
      mutate(listFetchKey, async (users: User[]) => users.filter((_user: User) => _user.id !== id));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('users:error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const changePasswordUser = async (id: number) => {
    try {
      await Axios.put(`/v1/users/${id}/password`);
      enqueueSuccess(t('main-left-menu:send_email_to_change_password_success'));
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t('users:error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      }
      throw error;
    }
  };
  return {
    deleteUser,
    createUser,
    editUser,
    changePasswordUser,
  };
}
