import { atom, DefaultValue } from 'recoil';

const sigmaIntegrationActivateKey = 'sigmaIntegrationActivate';

export const sigmaIntegrationActivateAtom = atom<boolean>({
  key: sigmaIntegrationActivateKey,
  default: true,
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const sessionValue = sessionStorage.getItem(sigmaIntegrationActivateKey);
      if (sessionValue) {
        setSelf(JSON.parse(sessionValue));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          sessionStorage.removeItem(sigmaIntegrationActivateKey);
        } else {
          sessionStorage.setItem(sigmaIntegrationActivateKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});
