import React from 'react';
import { RecordingType } from 'types/channels.types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControlProps,
} from '@mui/material';

function SelectRecordingType({
  selectedRecordingType,
  onChangeRecordingType,
  error,
  helperText,
  placeholder,
  name,
  className,
  filteredRecordingType,
  ...props
}: {
  selectedRecordingType: RecordingType;
  onChangeRecordingType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name: string;
  className?: string;
  filteredRecordingType?: RecordingType[];
} & FormControlProps) {
  const { t } = useTranslation(['channels']);

  // const [preAlarmFlag] = useDecision(FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE);

  const recordingTypes = React.useMemo(() => {
    const recordingTypeDefault = [
      RecordingType.RECORD_24_7,
      RecordingType.EVENT,
      RecordingType.LIVE,
      RecordingType.SCHEDULE,
      // ...(preAlarmFlag.enabled ? [RecordingType.PRE_ALARM] : []),
    ];

    return Number(filteredRecordingType?.length) > 1 ? filteredRecordingType : recordingTypeDefault;
  }, [filteredRecordingType]);

  return (
    <FormControl variant="outlined" {...{ className, error }} {...props}>
      <InputLabel id="select-recording-type-label">{t('channels:recording_type')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-recording-type-label"
        id="select-recording-type"
        label={t('channels:recording_type')}
        value={selectedRecordingType}
        // @ts-ignore
        onChange={onChangeRecordingType}
        renderValue={(selected) => t(`recording_type_${selected as string}`)}
      >
        {recordingTypes?.map((type) => (
          <MenuItem key={type} value={type}>
            {t(`recording_type_${type}`)}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
export default SelectRecordingType;
