import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  FormLabel,
  TextField,
  FormControl,
  RadioGroup,
  Checkbox,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { RecordingType } from 'types/channels.types';
import { FormikProps, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useYupObject } from 'hooks';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import {
  calculatorRowIsChecked,
  checkedItemsIdsAtom,
  editingItemAtom,
  listChannelsAtom,
} from 'atoms/calculator';
import { BitrateInput } from 'components/BitrateInput/BitrateInput';
import { CalculatorImportButton } from 'components/CalculatorImportButton/CalculatorImportButton';
import { recommendedBitrate } from 'helpers/recommendBitrate';
import { CalculatorEnteredData } from '../../../types/calculator.types';
import { Form, useFormStyles } from '../../../components/core';
import SelectResolution from '../../../components/SelectResolution/SelectResolution';
import SelectFPS from '../../../components/SelectFPS/SelectFPS';
import { ICalculatorFormProps } from '../types/calculatorTypes';

function CalculatorForm(props: ICalculatorFormProps) {
  const { onCancel, showCancelButton, showImportButton } = props;
  const yup = useYupObject();
  const { t } = useTranslation(['calculator', 'channels', '_common']);
  const setList = useSetRecoilState(listChannelsAtom);
  const setCheckedItemsIds = useSetRecoilState(checkedItemsIdsAtom);
  const [editingItem, setEditingItem] = useRecoilState(editingItemAtom);
  const rowCheck = useRecoilValue(calculatorRowIsChecked);
  const classesForm = useFormStyles({ dialog: true });
  const [bitrateValue, setBitrateValue] = useState(1727);
  const [editingBitrateValue, setEditingBitrateValue] = useState(0);
  const [fps, setFps] = useState<number>(1);
  const [resolution, setResolution] = useState<string>('640x480');
  // const [preAlarmCalculatorFlag] = useDecision(
  //   FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE_CALCULATOR
  // );

  const validationSchema = yup.object({
    qtChannels: yup.number().required().integer().moreThan(0),
    resolution: yup.string().required(),
    fps: yup.number().required().integer().moreThan(0),
    bitrate: yup.number().required().moreThan(0),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    forecastTimeRecordedByMonth: yup.number().required().min(0),
    recordingRetentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().required().moreThan(0).integer(),
      otherwise: yup.number(),
    }),
    timeForecastRecordingDisplay: yup.number().required().min(0),
    minutesExportedVideo: yup.number().required(),
    timeForecastLiveDisplay: yup.number().required().min(0),
    enablePreAlarm: yup.bool(),
    quantityOfRecordingsByMonth: yup.number().when('enablePreAlarm', {
      is: (enablePreAlarm) => enablePreAlarm,
      then: yup.number().required().min(0).moreThan(0).integer(),
      otherwise: yup.number(),
    }),
  });

  const initialValues: CalculatorEnteredData = editingItem || {
    id: uuidv4(),
    qtChannels: 0,
    resolution: '1280x720',
    fps: 30,
    bitrate: bitrateValue,
    recordingType: RecordingType.LIVE,
    forecastTimeRecordedByMonth: 0,
    recordingRetentionTime: 0,
    timeForecastRecordingDisplay: 0,
    minutesExportedVideo: 0,
    timeForecastLiveDisplay: 0,
    quantityOfRecordingsByMonth: 0,
    isChecked: rowCheck,
    enablePreAlarm: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      let calculatedValues = values;
      if (values.recordingType === RecordingType.RECORD_24_7) {
        calculatedValues = {
          ...values,
          forecastTimeRecordedByMonth: 30 * 24,
        };
      } else if (values.recordingType === RecordingType.LIVE) {
        calculatedValues = {
          ...values,
          forecastTimeRecordedByMonth: 0,
          recordingRetentionTime: 0,
          timeForecastRecordingDisplay: 0,
        };
      }
      setList((list) =>
        editingItem
          ? list.map((item) => (item.id === editingItem.id ? calculatedValues : item))
          : [...list, calculatedValues]
      );
      setCheckedItemsIds((old) => {
        const aux = { ...old };
        aux[values.id] = false;
        return aux;
      });
      setSubmitting(false);
      setEditingItem(undefined);
      onCancel();
    },
  });

  // @ts-ignore
  const formikRef = useRef<FormikProps<FormikValues>>(formik);

  useEffect(
    function getfpsAndResolutions() {
      setFps(formik.values.fps);
      setResolution(formik.values.resolution);
    },
    [formik.values.fps, formik.values.resolution]
  );

  useEffect(
    function changeBitrateValue() {
      for (let i = 0; i < recommendedBitrate.length; i++) {
        if (recommendedBitrate[i][resolution]) {
          editingItem && setEditingBitrateValue(recommendedBitrate[i][resolution][fps]);
          !editingItem && setBitrateValue(recommendedBitrate[i][resolution][fps]);
        }
      }
    },
    [fps, resolution, bitrateValue, editingItem]
  );

  useEffect(
    function setNewBitrateInInput() {
      formikRef.current.setFieldValue('bitrate', bitrateValue);
      editingItem && formikRef.current.setFieldValue('bitrate', editingItem.bitrate);
    },
    [bitrateValue, editingItem]
  );

  useEffect(() => {
    formik.touched.quantityOfRecordingsByMonth = true;
    formik.touched.recordingRetentionTime = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.touched.quantityOfRecordingsByMonth,
    formik.touched.recordingRetentionTime,
    formik.values.recordingType,
  ]);

  return (
    <form
      className={classesForm.form}
      onSubmit={formik.handleSubmit}
      aria-label="calculator's form"
    >
      <Box className={classesForm.formInputsContainer}>
        <TextField
          sx={{
            marginTop: '6px',
          }}
          className={classesForm.formInput}
          label={t('number_of_channels')}
          name="qtChannels"
          id="qtChannels"
          autoFocus
          type="number"
          helperText={formik.touched.qtChannels && formik.errors.qtChannels}
          value={formik.values.qtChannels}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.qtChannels && Boolean(formik.errors.qtChannels)}
        />
        <Box className={classesForm.formInput}>
          <SelectResolution
            error={formik.touched.resolution && Boolean(formik.errors.resolution)}
            helperText={formik.touched.resolution ? formik.errors.resolution : ''}
            onChangeSelectResolution={formik.handleChange}
            selectedResolution={formik.values.resolution}
          />
        </Box>
        <Box display="inline-flex" width="100%" className={classesForm.formInput}>
          <SelectFPS
            error={formik.touched.fps && Boolean(formik.errors.fps)}
            helper={formik.touched.fps && formik.errors.fps}
            value={formik.values.fps}
            onChangeSelectFps={formik.handleChange}
          />
          <BitrateInput
            helperText={
              formik.errors.bitrate
                ? formik.errors.bitrate
                : t('recommended_bitrate', {
                    value: editingItem ? editingBitrateValue : bitrateValue,
                  })
            }
            value={formik.values.bitrate}
            onChangeBitrateInput={formik.handleChange}
            onBlurBitrateInput={formik.handleBlur}
            error={formik.touched.bitrate && Boolean(formik.errors.bitrate)}
          />
        </Box>
        <FormControl className={classesForm.formInput}>
          <FormLabel id="recordingType-id" component="legend">
            {t('channels:recording_type')}
          </FormLabel>

          <RadioGroup
            aria-labelledby="recordingType-id"
            defaultValue={formik.values.recordingType}
            name="recordingType"
            onChange={formik.handleChange}
            className={classesForm.recordingTypeHorizontallyAligned}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <FormControlLabel
                value={RecordingType.LIVE}
                control={<Radio />}
                label={t('channels:recording_type_LIVE')}
              />
              <FormControlLabel
                value={RecordingType.RECORD_24_7}
                control={<Radio />}
                label={t('channels:recording_type_RECORD_24_7')}
              />
              <FormControlLabel
                value={RecordingType.EVENT}
                control={<Radio />}
                label={t('channels:recording_type_EVENT')}
              />
              <FormControlLabel
                value={RecordingType.SCHEDULE}
                control={<Radio />}
                label={t('channels:recording_type_SCHEDULE')}
              />
              {/* {preAlarmCalculatorFlag.enabled &&
                <FormControlLabel
                  value={RecordingType.PRE_ALARM}
                  control={<Radio />}
                  label={t('channels:recording_type_PRE_ALARM')}
                />
              } */}
            </Box>
          </RadioGroup>
        </FormControl>

        {(formik.values.recordingType === RecordingType.EVENT ||
          formik.values.recordingType === RecordingType.SCHEDULE) && (
          <Box>
            <FormControlLabel
              sx={{
                marginBottom: '1.2rem',
              }}
              control={
                <Checkbox
                  id="enablePreAlarm"
                  name="enablePreAlarm"
                  checked={formik.values.enablePreAlarm}
                  onChange={formik.handleChange}
                  value={formik.values.enablePreAlarm}
                />
              }
              label={t('channels:enable_pre_alarm')}
            />
          </Box>
        )}

        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          className={classesForm.formInput}
        >
          {(formik.values.recordingType === RecordingType.EVENT ||
            formik.values.recordingType === RecordingType.SCHEDULE) && (
            <TextField
              className={classesForm.formInput}
              label={t('calculator:video_recorded_in_hours_per_month_per_camera')}
              name="forecastTimeRecordedByMonth"
              id="forecastTimeRecordedByMonth"
              fullWidth
              type="number"
              sx={{
                width: 'calc(50% - 8px)',
              }}
              helperText={
                (formik.touched.forecastTimeRecordedByMonth &&
                  formik.errors.forecastTimeRecordedByMonth) ||
                ''
              }
              value={formik.values.forecastTimeRecordedByMonth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.forecastTimeRecordedByMonth &&
                Boolean(formik.errors.forecastTimeRecordedByMonth)
              }
            />
          )}
          {formik.values.recordingType !== RecordingType.LIVE && (
            <>
              <TextField
                className={classesForm.formInput}
                label={t('calculator:recording_retention_time_in_hours_per_camera')}
                name="recordingRetentionTime"
                id="recordingRetentionTime"
                fullWidth
                type="number"
                sx={{
                  width: 'calc(50% - 8px)',
                }}
                helperText={
                  (formik.touched.recordingRetentionTime && formik.errors.recordingRetentionTime) ||
                  ''
                }
                value={formik.values.recordingRetentionTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.recordingRetentionTime &&
                  Boolean(formik.errors.recordingRetentionTime)
                }
              />
              <TextField
                className={classesForm.formInput}
                label={t('calculator:playback_of_recorded_video_in_hours_per_month_per_camera')}
                name="timeForecastRecordingDisplay"
                id="timeForecastRecordingDisplay"
                fullWidth
                type="number"
                sx={{
                  width: 'calc(50% - 8px)',
                }}
                helperText={
                  (formik.touched.timeForecastRecordingDisplay &&
                    formik.errors.timeForecastRecordingDisplay) ||
                  ''
                }
                value={formik.values.timeForecastRecordingDisplay}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.timeForecastRecordingDisplay &&
                  Boolean(formik.errors.timeForecastRecordingDisplay)
                }
              />
            </>
          )}

          <TextField
            className={classesForm.formInput}
            label={t('calculator:live_video_in_hours_per_month_per_camera')}
            name="timeForecastLiveDisplay"
            id="timeForecastLiveDisplay"
            fullWidth
            type="number"
            sx={{
              width: 'calc(50% - 8px)',
            }}
            helperText={
              (formik.touched.timeForecastLiveDisplay && formik.errors.timeForecastLiveDisplay) ||
              ''
            }
            value={formik.values.timeForecastLiveDisplay}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.timeForecastLiveDisplay &&
              Boolean(formik.errors.timeForecastLiveDisplay)
            }
          />

          {formik.values.enablePreAlarm && (
            <TextField
              className={classesForm.formInput}
              label={t('calculator:quantity_of_recordings_by_month')}
              name="quantityOfRecordingsByMonth"
              id="quantityOfRecordingsByMonth"
              fullWidth
              type="number"
              sx={{
                width: 'calc(50% - 8px)',
              }}
              helperText={
                (formik.touched.quantityOfRecordingsByMonth &&
                  formik.errors.quantityOfRecordingsByMonth) ||
                ''
              }
              value={formik.values.quantityOfRecordingsByMonth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.quantityOfRecordingsByMonth &&
                Boolean(formik.errors.quantityOfRecordingsByMonth)
              }
            />
          )}
        </Box>
      </Box>
      <Box className={classesForm.formFooter}>
        {showCancelButton && (
          <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={() => onCancel()} />
        )}
        {showImportButton && <CalculatorImportButton />}

        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          disabled={!formik.isValid || !formik.dirty}
          initialValues={formik.initialValues}
          values={formik.values}
          dialog
          label={t('simulate')}
          variant={showImportButton ? 'contained' : 'text'}
        />
      </Box>
    </form>
  );
}

export default CalculatorForm;
