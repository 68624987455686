import { FormControl, InputLabel, MenuItem, ListItemText, Select, Checkbox } from '@mui/material';
import { Channel } from 'types/channels.types';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';

function SelectChannels({
  selectedChannels,
  setSelectedChannels,
  customerId,
}: {
  selectedChannels: FilterSelectValue[];
  setSelectedChannels: (value: FilterSelectValue[]) => void;
  customerId?: number;
}) {
  const { data: channels } = useFetch<Channel[]>(
    customerId ? `/v1/clients/${customerId}/channels` : '/v1/channels'
  );
  const { t } = useTranslation('_common');

  useEffect(
    function setLabels() {
      if (selectedChannels && channels) {
        setSelectedChannels(
          selectedChannels.map((value) => ({
            id: value.id,
            label: channels.find((channel) => channel.id === value.id)?.name || '',
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channels]
  );

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="event-filter-channel-label">{t('channels')}</InputLabel>
      <Select
        labelId="event-filter-channel-label"
        id="event-filter-channel"
        label={t('channels')}
        onChange={(e) =>
          setSelectedChannels(
            (e.target.value as number[]).map((id) => ({
              id,
              label: channels?.find((channel) => channel.id === id)?.name || '',
            }))
          )
        }
        value={selectedChannels.map((value) => value.id)}
        multiple
        renderValue={(selected) =>
          selected.map((id) => channels?.find((channel) => channel.id === id)?.name).join(', ')
        }
      >
        {channels?.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selectedChannels.some((value) => value.id === id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectChannels.Loading = function SelectCustomersLoading() {
  const { t } = useTranslation('_common');
  return <SelectFetchLoading label={t('channels')} />;
};

export default SelectChannels;
