import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PartnerType } from 'types/channels.types';

interface Props {
  value: PartnerType | undefined;
  onChange: (event: SelectChangeEvent<PartnerType>) => void;
}

function SelectManufacturer({ value, onChange }: Props) {
  const { t } = useTranslation('channels');
  const manuFacturers = ['INTELBRAS', 'HIKVISION'];

  return (
    <FormControl
      variant="outlined"
      sx={{ width: 300, textTransform: 'uppercase', display: 'flex', justifyContent: 'center' }}
    >
      <InputLabel id="select-manuFacturer">{t('channels:select_the_manufacturer')}</InputLabel>

      <Select
        name="manuFacturer"
        data-testid="manuFacturer"
        labelId="select-manuFacturer"
        label={t('channels:select_the_manufacturer')}
        value={value}
        onChange={onChange}
      >
        {manuFacturers.map((item) => (
          <MenuItem data-testid={item} key={item} aria-label={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default SelectManufacturer;
