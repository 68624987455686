import { Box, CircularProgress } from '@mui/material';
import { selectedDateAtom } from 'atoms/playback';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { MosaicItem } from 'types/mosaics.types';

export default function MosaicRecordedPage() {
  const { mosaicId, startDate } = useParams<{ mosaicId: string; startDate: string }>();

  const { data: mosaicItensApi } = useFetch<MosaicItem[]>(`/v1/mosaics/${mosaicId}/items`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const history = useHistory();

  const handleChangeSelectedDate = useRecoilCallback(
    ({ set }) => () => {
      if (startDate?.length && mosaicItensApi) {
        mosaicItensApi.forEach((item) =>
          set(
            selectedDateAtom({
              mosaicId: Number(mosaicId),
              channelId: Number(item.channel.id),
            }),
            new Date(startDate)
          )
        );
        history.push(routes.mosaic.view(mosaicId), {
          startDate,
        });
      }
    },
    [startDate, mosaicItensApi]
  );

  React.useEffect(() => {
    handleChangeSelectedDate();
  }, [handleChangeSelectedDate]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        alignContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
