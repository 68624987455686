import { Box, Paper, Typography } from '@mui/material';
import { useFetch } from 'hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { ConsumptionPrevision } from './metrics';

function ConsumptionTabData({ month, year }: { month: number; year: number }) {
  const { t } = useTranslation();
  const { data } = useFetch<ConsumptionPrevision>('/v1/consumption/monthlyInvoice/prevision', {
    baseURL: process.env.REACT_APP_BASE_URL_CONSUMPTION,
    params: { month, year },
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  // const data = {
  //   amountConsumed: 380.7,
  //   averageConsumptionDay: 189.0625,
  //   consumptionMonthPrevision: 5860.9375,
  //   contractedValue: 800,
  // };

  const {
    percentConsumptionDay,
    percentConsumptionMonthPrevision,
    percentConsumptionMonth,
  } = React.useMemo(
    () => ({
      percentConsumptionDay:
        data && (data.averageConsumptionDay / (data.contractedValue || 1)) * 100,
      percentConsumptionMonthPrevision:
        data && (data.consumptionMonthPrevision / (data.contractedValue || 1)) * 100,
      percentConsumptionMonth: data ? (data.amountConsumed / (data.contractedValue || 1)) * 100 : 0,
    }),
    [data]
  );
  const computedRemainingValue = useMemo(() => {
    /*
     * This code was implemented to ensure correct data presentation.
     * In case the value is negative, the green color appears inappropriately.
     * To solve this, a logic was developed to zero the value when it's negative.
     * Importantly, this solution has no impact on other system functionalities.
     */
    if (!data) return 0;
    const isNegativeValue = data.contractedValue - data.amountConsumed < 0;
    if (isNegativeValue) return 0;

    return data.contractedValue - data.amountConsumed;
  }, [data]);

  const color = React.useMemo(() => (percentConsumptionMonth >= 90 ? '#E84040' : '#2196F3'), [
    percentConsumptionMonth,
  ]);

  const pieData = React.useMemo<{ name: string; value: number; color: string }[]>(
    () => [
      { name: t('metrics:consumed'), value: data ? data.amountConsumed : 0, color },
      {
        name: t('metrics:remaining'),
        value: computedRemainingValue,
        color: '#449D44',
      },
    ],
    [color, computedRemainingValue, data, t]
  );

  const haveNoConsumption =
    data?.amountConsumed === 0 &&
    data?.averageConsumptionDay === 0 &&
    data?.consumptionMonthPrevision === 0 &&
    data?.contractedValue === 0;

  if (haveNoConsumption) {
    return (
      <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h5">{t('_common:there_is_no_consumption')}</Typography>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        flex: '1 1 auto',
        bgcolor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '1 1 auto' }}
      >
        <Box sx={{ width: '438px', height: '300px', position: 'relative' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="value"
                data={pieData}
                innerRadius="50%"
                activeIndex={0}
                activeShape={({ cx, cy, fill, innerRadius, outerRadius, startAngle, endAngle }) => (
                  <g>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill={fill}
                    />
                    <text
                      x={cx}
                      y={cy}
                      dy={8}
                      textAnchor="middle"
                      fill="white"
                      style={{
                        margin: '0',
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '2rem',
                        lineHeight: '1.167',
                        letterSpacing: '0em',
                      }}
                    >
                      {new Intl.NumberFormat('pt-BR', {
                        maximumFractionDigits: 0,
                      }).format(percentConsumptionMonth)}
                      %
                    </text>
                    <text
                      x={cx}
                      y={cy + 20}
                      dy={8}
                      textAnchor="middle"
                      fill="white"
                      style={{
                        margin: '0',
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: '1.66',
                        letterSpacing: '0.03333em',
                        color: '#fff',
                      }}
                    >
                      {t('metrics:consumed')}
                    </text>
                  </g>
                )}
              >
                {pieData?.map((entry) => (
                  <Cell key={`cell-${entry.name}`} fill={entry.color} stroke="0" />
                ))}
              </Pie>
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                // iconType="circle"
                // iconSize={8}
                formatter={(value, entry) => (
                  <Typography
                    fontSize="0.8rem"
                    variant="body2"
                    display="inline"
                  >{`${value}`}</Typography>
                )}
              />
              {/* <Legend
              verticalAlign="middle"
              align="center"
              content={() => <Typography>sdsadsad</Typography>}
            /> */}
              {/* <Text textAnchor="middle">{percentConsumptionMonth}</Text> */}
            </PieChart>
          </ResponsiveContainer>

          <Box sx={{ textAlign: 'center' }}>
            <Typography
              role="region"
              aria-label="consumption-amount-consumed"
              sx={{ display: 'inline-block' }}
              variant="h5"
            >
              {`${t('metrics:amount_consumed')}: `}
            </Typography>
            <Typography
              sx={{ display: 'inline-block', marginLeft: 0.5, fontWeight: 'bold' }}
              role="region"
              aria-label="consumption-amount-consumed"
              color={color}
              variant="h5"
            >
              {` ${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
              }).format(data?.amountConsumed || 0)}`}
            </Typography>
            <Typography
              role="region"
              aria-label="consumption-contracted-value"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {`${t('metrics:contracted_value')}: `}
              {/* <b> */}
              {` ${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
              }).format(data?.contractedValue || 0)}`}
              {/* </b> */}
            </Typography>
          </Box>
        </Box>
        <Typography
          role="region"
          aria-label="consumption-description"
          variant="h5"
          sx={{ maxWidth: '385px' }}
        >
          {t('metrics:your_average_daily_usage_is')}
          <b>
            {` ${new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              maximumFractionDigits: 2,
            }).format(data?.averageConsumptionDay || 0)} (${new Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(percentConsumptionDay || 0)}%) `}
          </b>
          {t('metrics:and_the_consumption_forecast_for_this_month_is')}
          <b>
            {` ${new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              maximumFractionDigits: 2,
            }).format(data?.consumptionMonthPrevision || 0)} (${new Intl.NumberFormat('pt-BR', {
              maximumFractionDigits: 2,
            }).format(percentConsumptionMonthPrevision || 0)}%)`}
          </b>
        </Typography>
      </Box>
      <Paper
        sx={{
          flex: '0 0 auto',
          margin: 4,
          padding: 3,
        }}
      >
        <Typography variant="body2">
          <b>{t('_common:attention')}!</b>{' '}
          {t(
            'metrics:when_reaching_150_of_the_contracted_transmission_for_your_convenience_your_consumption_will_be_blocked'
          )}
        </Typography>
      </Paper>
    </Paper>
  );
}

export default ConsumptionTabData;
