import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, List, ListItem, Paper, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScreenDetector } from 'hooks/useScreenDetector';
import ChannelStatusIcon from 'components/core/Icons/ChannelStatusIcon';
import { useRecoilValue } from 'recoil';
import { unstableChannelsAtom } from 'atoms/mosaics';
import { useNetwork } from 'react-use';

export function UnstableChannelsStatus() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { md, lg } = useScreenDetector();
  const unstableChannels = useRecoilValue(unstableChannelsAtom);
  const network = useNetwork();

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  if (unstableChannels.length <= 0) {
    return null;
  }

  if (!network.online) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: md ? 'center' : 'flex-end',
      }}
    >
      <IconButton
        aria-label="unstable connection"
        size="small"
        onClick={handleClick}
        disabled={openModal}
        sx={(theme) => ({
          bgcolor: theme.palette.warning.dark,
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          gap: theme.spacing(0.5),
          px: theme.spacing(0.5),
          ':hover': {
            bgcolor: theme.palette.warning.contrastText,
          },
          ':disabled': {
            bgcolor: theme.palette.warning.contrastText,
            color: 'black',
          },
        })}
      >
        <Box
          sx={{
            ml: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ChannelStatusIcon />
        </Box>
        {lg && (
          <>
            <Typography sx={{ color: 'inherit' }}>
              {t('mosaics:unstable_channel', { count: unstableChannels.length })}
            </Typography>
            {openModal ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </>
        )}
      </IconButton>
      <InformativeDetailsDialog isOpen={openModal} onClose={handleClose} />
    </Box>
  );
}

interface InformativeDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

function InformativeDetailsDialog({ isOpen, onClose }: InformativeDetailsDialogProps) {
  const { t } = useTranslation();
  const [isOpenSuggestions, setOpenSuggestions] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpenSuggestions(!isOpenSuggestions);
  };

  const handleCloseSuggestions = () => {
    setOpenSuggestions(false);
  };

  useEffect(
    function closeDialogWhenClickOutside() {
      const handleClickOutside = (event: MouseEvent) => {
        if (event.target instanceof Node && !dropDownRef.current?.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    },
    [onClose]
  );

  useEffect(
    function closeDialogWhenPressEscape() {
      const handlePressEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          isOpenSuggestions ? handleCloseSuggestions() : onClose();
        }
      };

      document.addEventListener('keydown', handlePressEscape);
      return () => document.removeEventListener('keydown', handlePressEscape);
    },
    [isOpenSuggestions, onClose]
  );

  return isOpen ? (
    <Paper
      ref={dropDownRef}
      sx={(theme) => ({
        p: '16px',
        width: '440px',
        maxHeight: '600px',
        height: 'auto',
        position: 'absolute',
        zIndex: theme.zIndex.modal,
        top: '62px',
        overflow: 'auto',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box>
          <Typography variant="h6">{t('_common:unstable_channel')}</Typography>
          <Typography>{t('mosaics:unstable_channel_description')}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">{t('mosaics:why_is_this_happening')}</Typography>

          <List sx={{ display: 'disc' }}>
            <ListItem sx={{ display: 'list-item', mb: 1 }}>
              <Typography sx={{ ml: 2 }}>
                &bull; {t('mosaics:unstable_chanel_reason_first')}
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography sx={{ ml: 2 }}>
                &bull; {t('mosaics:unstable_chanel_reason_second')}
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box>
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textTransform: 'none',
              width: '100%',
              mx: 'auto',
              mb: 1,
            }}
            onClick={handleClick}
          >
            <Typography fontWeight="bold" sx={(theme) => ({ color: theme.palette.primary.main })}>
              {t('mosaics:how_to_fix')}
            </Typography>
            {!isOpenSuggestions ? (
              <KeyboardArrowDownIcon
                fontSize="large"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              />
            ) : (
              <KeyboardArrowUpIcon
                fontSize="large"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              />
            )}
          </Button>
          <Box
            sx={(theme) => ({
              display: isOpenSuggestions ? 'block' : 'none',
              bgcolor: theme.palette.common.black,
              borderRadius: 1,
              p: '10px',
            })}
          >
            <Typography sx={(theme) => ({ color: 'inherit', ml: 2 })}>
              &bull; {t('mosaics:unstable_channels_suggestion_first')}
            </Typography>
            <Typography sx={(theme) => ({ color: 'inherit', ml: 2 })}>
              &bull; {t('mosaics:unstable_channels_suggestion_second')}
            </Typography>
            <Typography sx={(theme) => ({ color: 'inherit', ml: 2 })}>
              &bull; {t('mosaics:unstable_channels_suggestion_third')}
            </Typography>
            <Typography sx={(theme) => ({ color: 'inherit', ml: 2 })}>
              &bull; {t('mosaics:unstable_channels_suggestion_fourth')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  ) : null;
}
