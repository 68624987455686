import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { EventFilterType } from 'types/event.types';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { FilterSelectValue } from 'types/select.types';
import { DateRangeValue } from 'components/DateRange';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    chipContainer: {
      flex: '1 1 auto',
    },
    chip: {
      margin: theme.spacing(1),
    },
    moreButton: {
      flex: '0 0 auto',
      margin: 'auto',
    },
  })
);

function EventChip({
  range,
  selectedCustomers,
  selectedRecordingTypes,
  selectedCustomerGroup,
  selectedChannel,
  selectedChannelGroup,
  setSelectedCustomers,
  setSelectedRecordingType,
  setSelectedCustomerGroup,
  setSelectedChannel,
  setSelectedChannelGroup,
  selectedEventTypes,
  setSelectedEventType,
}: {
  range: DateRangeValue;
  selectedCustomers: FilterSelectValue[];
  selectedRecordingTypes: string[];
  selectedCustomerGroup: FilterSelectValue[];
  selectedChannel: FilterSelectValue[];
  selectedChannelGroup: FilterSelectValue[];
  setSelectedCustomers: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  setSelectedRecordingType: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCustomerGroup: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  setSelectedChannel: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  setSelectedChannelGroup: React.Dispatch<React.SetStateAction<FilterSelectValue[]>>;
  selectedEventTypes: number[];
  setSelectedEventType: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const classes = useStyles();
  const { t } = useTranslation([
    'channels',
    'channel_recording_requests',
    'channel_events',
    '_common',
  ]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearAll = () => {
    setAnchorEl(null);
    setSelectedCustomers([]);
    setSelectedRecordingType([]);
    setSelectedEventType([]);
    setSelectedCustomerGroup([]);
    setSelectedChannel([]);
    setSelectedChannelGroup([]);
  };

  return (
    <Paper className={classes.root} role="region" aria-label="Filtros aplicados">
      <Box className={classes.chipContainer}>
        {range && (
          <Chip
            aria-label="Chip range"
            size="small"
            label={`${range[0].toLocaleDateString(
              navigator.language
            )} - ${range[1].toLocaleDateString(navigator.language)}`}
            className={classes.chip}
          />
        )}
        {selectedRecordingTypes.map((recordingType) => (
          <Chip
            key={recordingType}
            aria-label={`Chip tipo de gravação ${recordingType}`}
            size="small"
            label={t(`channels:recording_type_${recordingType}`)}
            onDelete={() =>
              setSelectedRecordingType([
                ...selectedRecordingTypes.filter((id) => recordingType !== id),
              ])
            }
            className={classes.chip}
          />
        ))}
        {selectedEventTypes.map((eventType) => (
          <Chip
            key={eventType}
            aria-label={`Chip tipo de evento ${EventFilterType[Number(eventType)]}`}
            size="small"
            label={
              [EventFilterType.START, EventFilterType.STOP].includes(eventType)
                ? t(
                  `channel_recording_requests:${eventType === EventFilterType.START ? 'start' : 'stop'
                  }`
                )
                : t(`channel_events:${eventType}`)
            }
            onDelete={() =>
              setSelectedEventType([...selectedEventTypes.filter((id) => eventType !== id)])
            }
            className={classes.chip}
          />
        ))}
        {selectedCustomers.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip cliente ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedCustomers([...selectedCustomers.filter((item) => item.id !== value.id)])
            }
            className={classes.chip}
          />
        ))}
        {selectedCustomerGroup.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip grupo de cliente ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedCustomerGroup([
                ...selectedCustomerGroup.filter((item) => item.id !== value.id),
              ])
            }
            className={classes.chip}
          />
        ))}
        {selectedChannel.map((value) => {
          if (value.label !== "") {
            return (
              <Chip
                key={value.id}
                aria-label={`Chip canal ${value.label}`}
                size="small"
                onDelete={() =>
                  setSelectedChannel([...selectedChannel.filter((item) => item.id !== value.id)])
                }
                label={value.label}
                className={classes.chip}
              />
            )
          }

          return null
        })}

        {selectedChannelGroup.map((value) => (
          <Chip
            key={value.id}
            aria-label={`Chip grupo de canal ${value.label}`}
            size="small"
            label={value.label}
            onDelete={() =>
              setSelectedChannelGroup([
                ...selectedChannelGroup.filter((item) => item.id !== value.id),
              ])
            }
            className={classes.chip}
          />
        ))}
      </Box>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickMore}
        className={classes.moreButton}
        aria-label="Menu dos filtros"
        size="large">
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-label="Menu dos filtros"
      >
        <MenuItem onClick={handleClearAll} aria-label="Limpar filtros">
          {t('_common:clean_filters')}
        </MenuItem>
      </Menu>
    </Paper>
  );
}

export default EventChip;
