import React from 'react';
import { useRouteMatch, Redirect, useHistory, Switch, Route } from 'react-router-dom';
import routes from 'helpers/routes';
import { Tabs, Tab, AppBar, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { loggedUserAtom } from 'atoms/users';
import StorageTab from './StorageTab';
import RecordingTab from './RecordingTab';
import LiveTab from './LiveTab';
import ReproductionTab from './ReproductionTab';
import StreamingTab from './StreamingTab';
import DetailsTab from './DetailsTab';
import ConsumptionTab from './ConsumptionTab';

const useStyles = makeStyles({
  root: {
    padding: '8px',
    margin: '24px',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

function MetricsPage() {
  const classes = useStyles();
  const match = useRouteMatch(routes.metrics.root());
  const matchChildren = useRouteMatch([
    ...Object.values(routes.metrics)
      .map((fnRoute) => fnRoute())
      .filter((route) => route !== routes.metrics.root()),
  ]);
  const history = useHistory();
  const { t } = useTranslation('metrics');
  const loggedUser = useRecoilValue(loggedUserAtom);

  if (
    loggedUser?.masterCompany.invoiceMargin === null ||
    loggedUser?.masterCompany.invoiceMargin === undefined ||
    loggedUser?.masterCompany.invoiceMargin <= 0
  )
    return null;

  if (!match) return null;

  if (match.isExact) {
    return <Redirect to={routes.metrics.storage()} />;
  }

  return (
    <Paper className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs value={matchChildren?.path} indicatorColor="primary" textColor="inherit">
          <Tab
            label={t('storage')}
            value={routes.metrics.storage()}
            onClick={() => history.push(routes.metrics.storage())}
          />
          <Tab
            label={t('recording')}
            value={routes.metrics.recording()}
            onClick={() => history.push(routes.metrics.recording())}
          />
          <Tab
            label={t('live')}
            value={routes.metrics.live()}
            onClick={() => history.push(routes.metrics.live())}
          />
          <Tab
            label={t('reproduction')}
            value={routes.metrics.reproduction()}
            onClick={() => history.push(routes.metrics.reproduction())}
          />
          {/* <Tab
            label={t('streaming')}
            value={routes.metrics.streaming()}
            onClick={() => history.push(routes.metrics.streaming())}
          /> */}
          <Tab
            label={t('details')}
            value={routes.metrics.details()}
            onClick={() => history.push(routes.metrics.details())}
          />
          <Tab
            label={t('consumption')}
            value={routes.metrics.consumption()}
            onClick={() => history.push(routes.metrics.consumption())}
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={routes.metrics.storage()} exact component={StorageTab} />
        <Route path={routes.metrics.recording()} exact component={RecordingTab} />
        <Route path={routes.metrics.live()} exact component={LiveTab} />
        <Route path={routes.metrics.reproduction()} exact component={ReproductionTab} />
        <Route path={routes.metrics.streaming()} exact component={StreamingTab} />
        <Route path={routes.metrics.details()} exact component={DetailsTab} />
        <Route path={routes.metrics.consumption()} exact component={ConsumptionTab} />
      </Switch>
    </Paper>
  );
}

export default MetricsPage;
