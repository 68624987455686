import React, { useEffect } from 'react';
import { mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { selectedAtom } from 'atoms/selectCustomerPanel';
import CustomerForm from 'components/CustomerForm';
import routes from 'helpers/routes';
import { useCustomerApi } from 'hooks/customers/useCustomersApi';
import { useSelectCustomerPanel } from 'hooks/customers/useSelectCustomerPanel';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Customer, DEFAULT_CUSTOMER } from 'types/customer.types';

function MosaicCreateCustomer({ mosaicId, slotId }: { mosaicId: number; slotId: number }) {
  const { createCustomer } = useCustomerApi();
  const setCustomerSelected = useSetRecoilState(selectedAtom);
  const history = useHistory();
  const { openCustomerSelection } = useSelectCustomerPanel();
  const setSlotSelected = useSetRecoilState(mosaicSlotOrderSelectedAtom);

  useEffect(() => {
    openCustomerSelection();
  }, [openCustomerSelection]);

  useEffect(() => {
    slotId && setSlotSelected(slotId);
  }, [setSlotSelected, slotId]);

  const onSubmitSuccess = (customer: Customer) => {
    setCustomerSelected(customer.id);
    history.push(routes.mosaic.createChannel(mosaicId, slotId, customer.id));
  };

  return (
    <CustomerForm
      initialValues={DEFAULT_CUSTOMER}
      doSubmit={createCustomer}
      onSubmitSuccess={onSubmitSuccess}
    />
  );
}

export default MosaicCreateCustomer;
