import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddCircle, DragIndicator, TimerOutlined, ViewModuleOutlined } from '@mui/icons-material';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch, useYupObject } from 'hooks';
import { PlaylistItem } from 'types/playlist';
import ApiBoundary from 'components/ApiBoundary';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { ItemTypes, PlaylistItemDrop } from 'helpers/dragAndDrop';
import {
  addMosaicToPlaylistModalOpenedAtom,
  savingPlaylistItemAtom,
  savingPlaylistItemErrorAtom,
} from 'atoms/playlist';
import { useFormik } from 'formik';
import { useDebounce, useUpdateEffect } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mutate } from 'swr';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmModal } from 'components/core';
import { useHavePermission } from 'hooks/useHavePermission';
import { usePlaylistApi } from 'hooks/playlists/usePlaylistApi';

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    flex: '1 1 auto',
  },
  status: {
    marginTop: theme.spacing(2),
  },
  card: {
    width: '444px',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    flexDirection: 'column',
  },
  listItemName: {
    flex: '1 1 auto',
    marginLeft: theme.spacing(1),
  },
  emptyBoxDragging: {
    width: '444px',
    height: '64px',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
  },
  rotationTimeInput: {
    width: '110px',
    flex: '0 0 auto',
  },
}));

function PlaylistDetailsMosaics({
  playlistId,
  searchText,
}: {
  playlistId: number;
  searchText: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation('playlists');
  const setOpen = useSetRecoilState(addMosaicToPlaylistModalOpenedAtom);

  return (
    <>
      <Paper className={classes.resultContainer}>
        <Box display="flex" alignItems="center">
          <ViewModuleOutlined titleAccess="mosaic" />
          <Box flex="1 1 auto" marginLeft="0.4rem">
            <Typography>{t('mosaics_in_the_playlist')}</Typography>
          </Box>
          <Permissioned role={PermissionRole.EDIT_PLAYLIST}>
            <Box marginLeft="4px">
              <IconButton size="small" aria-label="add mosaic" onClick={() => setOpen(true)}>
                <AddCircle />
              </IconButton>
            </Box>
          </Permissioned>
        </Box>
        <ApiBoundary fallbackLoading={<ListItems.Loading />}>
          <ListItems {...{ playlistId, searchText }} />
        </ApiBoundary>
      </Paper>
      <Status />
    </>
  );
}

function Status() {
  const classes = useStyles();
  const { t } = useTranslation(['_common', 'playlists']);
  const saving = useRecoilValue(savingPlaylistItemAtom);
  const savingError = useRecoilValue(savingPlaylistItemErrorAtom);
  const [showFinalMessage, setShowFinalMessage] = React.useState(false);

  useUpdateEffect(() => {
    if (saving === false) {
      setShowFinalMessage(true);
    }
  }, [saving]);

  useDebounce(
    () => {
      if (saving === false) {
        setShowFinalMessage(false);
      }
    },
    1500,
    [saving, setShowFinalMessage]
  );

  if (!saving && !showFinalMessage) {
    return null;
  }

  if (saving) {
    return (
      <Typography className={classes.status} variant="body2" color="textSecondary">
        {t('saving')}
      </Typography>
    );
  }

  if (savingError) {
    return (
      <Typography className={classes.status} color="error" variant="body2">
        {t('playlists:error_changing_playlist_item')}
      </Typography>
    );
  }

  return (
    <Typography className={classes.status} variant="body1" color="textSecondary">
      {t('saved_changes')}
    </Typography>
  );
}

function ListItems({ playlistId, searchText }: { playlistId: number; searchText: string }) {
  const { t } = useTranslation();
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [objMosaicReceive, setObjMosaicReceive] = React.useState<PlaylistItem>();
  const { deletePlaylistItem } = usePlaylistApi();

  const handleSendObjectMosaic = (objMosaic: PlaylistItem) => {
    setObjMosaicReceive(objMosaic);
    setDeleteConfirmModalOpen(!deleteConfirmModalOpen);
  };

  const handleDelete = async () => {
    if (objMosaicReceive) {
      setIsDeleting(true);
      await deletePlaylistItem(playlistId, objMosaicReceive.id);
    }
    setIsDeleting(false);
    setDeleteConfirmModalOpen(false);
  };
  const { data: items } = useFetch<PlaylistItem[]>(`/v1/playlists/${playlistId}/items`, {
    normalizeData: (_items) => {
      const newArr = [..._items];
      return newArr.sort((a, b) => a.order - b.order);
    },
  });

  const itemsFiltered = React.useMemo(
    () => items?.filter((item) => item.mosaic.name.includes(searchText)),
    [items, searchText]
  );

  if (!items || items.length === 0) {
    return null;
  }

  if (!itemsFiltered || itemsFiltered.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Typography color="textSecondary">{t('crud_actions:no_results')}</Typography>
      </Box>
    );
  }

  return (
    <>
      <List aria-label="Items in playlist">
        {itemsFiltered.map((item) => (
          <ListItems.Item
            key={item.id}
            {...{
              item,
              playlistId,
              handleSendObjectMosaic,
              items,
            }}
          />
        ))}
      </List>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:remove')}
        description={t('playlists:the_mosaic_will_no_longer_appear_on_this_playlist')}
        title={t('playlists:want_remove_mosaic_from_playlist', {
          mosaicName: objMosaicReceive?.mosaic.name,
        })}
      />
    </>
  );
}

ListItems.Item = function Item({
  item,
  playlistId,
  handleSendObjectMosaic,
  items,
}: {
  item?: PlaylistItem;
  playlistId?: number;
  handleSendObjectMosaic?: (objMosaic: PlaylistItem) => void;
  items?: PlaylistItem[];
}) {
  const { t } = useTranslation('playlists');
  const classes = useStyles();
  const { updatePlaylistItem } = usePlaylistApi();
  const yup = useYupObject();
  const havePermissionFN = useHavePermission();
  const canEditPlaylist = havePermissionFN(PermissionRole.EDIT_PLAYLIST);

  const validationSchema = yup.object({
    rotationTime: yup.number().required().integer().moreThan(0).max(999),
  });
  const formik = useFormik({
    initialValues: {
      rotationTime: item?.rotationTime || 60,
    },
    onSubmit: (values) => {
      if (!playlistId || !item) {
        return;
      }

      updatePlaylistItem(playlistId, item.id, { ...item, rotationTime: values.rotationTime });

      mutate(
        `/v1/playlists/${playlistId}/items`,
        (_items: PlaylistItem[]) =>
          _items.map((_item) =>
            _item.id === item.id ? { ...item, rotationTime: values.rotationTime } : _item
          ),
        false
      );
    },
    validationSchema,
  });

  useDebounce(
    () => {
      formik.dirty && formik.handleSubmit();
    },
    600,
    [formik.values.rotationTime]
  );

  const [{ isDragging }, dragRef] = useDrag<
    PlaylistItemDrop | DragObjectWithType,
    void,
    { isDragging: boolean }
  >({
    item: { payload: item, type: ItemTypes.PLAYLIST_ITEM },
    canDrag: !!item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isDraggingOtherOver }, dropRef] = useDrop<
    PlaylistItemDrop,
    void,
    { isDraggingOtherOver: boolean }
  >({
    accept: ItemTypes.PLAYLIST_ITEM,
    collect: (monitor) => ({
      isDraggingOtherOver: monitor.isOver() && monitor.canDrop() && !isDragging,
    }),
    drop: (dropItem) => {
      if (!playlistId || !item || !items) {
        return;
      }

      if (dropItem.payload.id === item.id) {
        return;
      }

      const lowestOrder = Math.min(dropItem.payload.order, item.order);

      const newItems = items
        .map((_item) =>
          _item.id === dropItem.payload.id ? { ..._item, order: item.order - 0.5 } : _item
        )
        .sort((a, b) => a.order - b.order)
        .map((_item, index) => ({ ..._item, order: index + 1 }));

      Promise.allSettled(
        newItems.map(
          (_item) => _item.order >= lowestOrder && updatePlaylistItem(playlistId, _item.id, _item)
        )
      );

      mutate(`/v1/playlists/${playlistId}/items`, newItems, false);
    },
  });

  return (
    <ListItem
      id={item && `item ${item.id} droppable`}
      aria-labelledby={item && `item-mosaic-name-${item.id}`}
      className={classes.listItem}
      ref={canEditPlaylist ? dropRef : null}
    >
      <Zoom in={isDraggingOtherOver} unmountOnExit>
        <Paper className={classes.emptyBoxDragging} />
      </Zoom>

      <Card className={classes.card}>
        <CardContent
          role="region"
          aria-label="card-content"
          className={classes.cardContent}
          ref={dragRef}
        >
          <DragIndicator color="disabled" />
          <Tooltip title={item?.mosaic.name || ''}>
            <Typography
              id={item && `item-mosaic-name-${item.id}`}
              className={classes.listItemName}
              noWrap
            >
              {item ? item.mosaic.name : <Skeleton />}
            </Typography>
          </Tooltip>

          <TextField
            disabled={!canEditPlaylist}
            type="number"
            className={classes.rotationTimeInput}
            id={`rotationTime-${item?.id}`}
            name="rotationTime"
            label={t('screen_time')}
            value={formik.values.rotationTime}
            onChange={formik.handleChange}
            error={formik.touched.rotationTime && Boolean(formik.errors.rotationTime)}
            helperText={formik.touched.rotationTime && formik.errors.rotationTime}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TimerOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Box marginLeft={2} flex="0 0 auto">
            <Permissioned role={PermissionRole.REMOVE_PLAYLIST}>
              <IconButton
                size="small"
                aria-label="delete mosaic"
                onClick={() => {
                  item && handleSendObjectMosaic && handleSendObjectMosaic(item);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Permissioned>
          </Box>
        </CardContent>
      </Card>
    </ListItem>
  );
};

ListItems.Loading = function Loading() {
  return (
    <>
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItems.Item key={i} />
      ))}
    </>
  );
};

export default PlaylistDetailsMosaics;
