import { mosaicItensInitialDataAtom, mosaicItensValuesAtom } from 'atoms/mosaicItems';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { MosaicItem } from 'types/mosaics.types';
import { useFetchMosaic } from './mosaic/useFetchMosaic';
import useFetch from './useFetch';

export function useMosaicSlotViewPage() {
  const { mosaicId, slotId } = useParams<{ mosaicId: string; slotId: string }>();
  useFetchMosaic(Number(mosaicId));
  const { data: mosaicItensApi } = useFetch<MosaicItem[]>(`/v1/mosaics/${mosaicId}/items`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const setDataFromApi = useRecoilCallback(
    ({ set }) => (data: MosaicItem[]) => {
      set(mosaicItensInitialDataAtom(Number(mosaicId)), data);
      set(mosaicItensValuesAtom(Number(mosaicId)), data);
    },
    []
  );

  useEffect(
    function callSetDataFromApi() {
      setDataFromApi(mosaicItensApi || []);
    },
    [mosaicItensApi, setDataFromApi]
  );

  const mosaicItem = useMemo(
    () => mosaicItensApi?.find((item) => item.order === Number(slotId)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mosaicItensApi, slotId]
  );
  return { mosaicId: Number(mosaicId), slotId: Number(slotId), mosaicItem };
}
