/* eslint-disable @typescript-eslint/indent */
import React, { useMemo } from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps,
  Typography,
  Box,
  alpha as muifade,
  Theme,
  useTheme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<'avatar', { selected?: boolean; size?: string }>({
    avatar: ({ size, selected }) => ({
      margin: 'auto',
      width: size || '63px',
      height: size || '63px',
      backgroundColor: selected ? theme.palette.background.default : undefined,
    }),
  })
);

function Avatar({
  name,
  selected,
  size,
  fade,
  className,
  ...props
}: { name?: string; selected?: boolean; size?: string; fade?: boolean } & AvatarProps) {
  const classes = useStyles({ selected, size });
  const theme = useTheme();
  const letters = useMemo(() => {
    if (!name) return '';
    const names = name.split(' ').filter((word) => word.length > 2);
    if (!names?.length) {
      return '';
    }
    const first = names[0][0];

    if (names.length === 1) {
      return `${first}${names[0][1]}`;
    }
    return `${first}${names[1][0]}`;
  }, [name]);

  return (
    <MuiAvatar className={`${classes.avatar} ${className}`} {...props}>
      {fade && (
        <Box
          position="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          bgcolor={muifade(theme.palette.background.default, 0.5)}
        />
      )}
      <Typography variant="body1">{letters.toUpperCase()}</Typography>
    </MuiAvatar>
  );
}

export default Avatar;
