import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Funnel(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 22 2 H 2 L 10 12 V 19 l 4 3 V 12 Z" />
    </SvgIcon>
  );
}

export default Funnel;
