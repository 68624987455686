import { atom, DefaultValue } from 'recoil';

const themeSelectedKey = 'themeSelected';

export enum Mode {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export const themeSelectedAtom = atom<Mode>({
  key: themeSelectedKey,
  default: Mode.DARK,
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const localValue = localStorage.getItem(themeSelectedKey);
      if (localValue) {
        setSelf(JSON.parse(localValue));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(themeSelectedKey);
        } else {
          localStorage.setItem(themeSelectedKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});
