export default function millisecondsToHHMMSS(duration: number) {
  // milliseconds = parseInt((duration % 1000) / 100),
  const seconds = (duration / 1000) % 60;
  const minutes = (duration / (1000 * 60)) % 60;
  const hours = duration / (1000 * 60 * 60);

  return `${String(Math.trunc(hours).toFixed(0)).padStart(2, '0')}:${String(
    Math.trunc(minutes).toFixed(0)
  ).padStart(2, '0')}:${String(seconds.toFixed(0)).padStart(2, '0')}`;
}
