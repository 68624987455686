import React, { useEffect } from 'react';
import { Form } from 'components/core';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMessages, useYupObject } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { Mosaic, Template } from 'types/mosaics.types';
import templates from 'helpers/mosaicTemplates';
import { FormikProvider, useFormik } from 'formik';
import SelectQtyChannels from 'components/SelectQtyChannels/SelectQtyChannels';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import { useHavePermission } from 'hooks/useHavePermission';
import { useMosaicApi } from 'hooks/mosaic/useMosaicApi';
import { useFormStyles } from '../core/Form';

interface MosaicFormProps {
  initialValues: Partial<Mosaic>;
  handleSubmit?: (data: Mosaic) => Promise<void>;
}

function MosaicForm({ handleSubmit, initialValues }: MosaicFormProps) {
  const { createModalOpened, closeCreateModal } = useCreateMosaicModal();
  const { t } = useTranslation(['mosaics', 'crud_actions', '_common']);
  const yup = useYupObject();
  const createPermission = useHavePermission(PermissionRole.INSERT_MOSAIC);
  const { enqueueError } = useMessages();
  const classesForm = useFormStyles({ dialog: true });
  const { createMosaic } = useMosaicApi();

  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    qtyChannels: yup.number().max(999).required(),
  });

  const defaultSubmit = async (data: Mosaic) => {
    await createMosaic(data);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const valuesTransformed = {
        ...values,
        name: values.name?.trim(),
        qtyChannels: values.qtyChannels,
        // @ts-ignore
        mosaicData: templates()[values.qtyChannels],
      };

      handleSubmit
        ? await handleSubmit(valuesTransformed as Mosaic)
        : await defaultSubmit(valuesTransformed as Mosaic);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (createModalOpened && !createPermission) {
      enqueueError(`${t('permission:you_dont_have_permission')}`);
      closeCreateModal();
    }
  }, [closeCreateModal, createModalOpened, createPermission, enqueueError, t]);

  return (
    <FormikProvider value={formik}>
      <form
        aria-label="MosaicFormModal"
        onSubmit={formik.handleSubmit}
        className={classesForm.form}
      >
        <Box className={classesForm.formInputsContainer}>
          <TextField
            className={classesForm.formInput}
            sx={{ marginTop: '5px' }}
            fullWidth
            label={t('mosaics:name')}
            name="name"
            disabled={formik.isSubmitting}
            id="name"
            autoFocus
            placeholder={t('mosaics:enter_mosaic_name')}
            helperText={
              (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
            }
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          <Box className={classesForm.formInput}>
            <SelectQtyChannels
              qtyChannels={formik.values.qtyChannels as Template}
              onChangeQtyChannels={formik.handleChange}
              error={formik.touched.qtyChannels && Boolean(formik.errors.qtyChannels)}
              helperText={formik.touched.qtyChannels ? formik.errors.qtyChannels : ''}
              fullWidth
              disabled={formik.isSubmitting}
              label={t('mosaics:select_the_number_of_channels')}
            />
          </Box>
          <Box className={classesForm.formFooter}>
            <Form.CancelButton
              isSubmitting={formik.isSubmitting}
              onClick={() => closeCreateModal()}
            />
            <Form.SaveButton
              isSubmitting={formik.isSubmitting}
              initialValues={formik.initialValues}
              dialog
              label={t('crud_actions:create')}
              values={formik.values}
              color="primary"
              variant="contained"
            />
          </Box>
        </Box>
      </form>
    </FormikProvider>
  );
}

export default MosaicForm;
