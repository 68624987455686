import React from 'react';
import { useTimeoutFn } from 'react-use';
import Axios from 'axios';

const SECONDS_BEFORE = 15;

/**
 * start live for next mosaic before change. (performance)
 * @param rotationTime atual item's rotationTime
 * @param mosaicId next item id
 */
function useCallStartLiveBefore(rotationTime: number, mosaicId: number) {
  /*
    Calcula quanto tempo antes do final do item atual.
    Regra:
    - Tempo de transição 50 segundos, no caso maior que 15 segundos, o tempo antes fica como 15 segundos
    - Tempo de transição 5 segundos, menor que 15 segundos, assim que entrar nesse item atual já irá chamar
  */
  const timeBefore = React.useMemo(() => Math.min(rotationTime, SECONDS_BEFORE), [rotationTime]);

  const callStartLive = React.useCallback(async () => {
    Axios.post(`/v1/mosaics/${mosaicId}/startLive`);
  }, [mosaicId]);

  // timeout para chamar a função acima com o tempo calculado
  const [, , reset] = useTimeoutFn(callStartLive, (rotationTime - timeBefore) * 1000);

  React.useEffect(
    function resetTimeoutWhenChangeMosaic() {
      reset();
    },
    [mosaicId, reset]
  );
}

export default useCallStartLiveBefore;
