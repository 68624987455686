import React, { MouseEventHandler, useEffect, useState } from 'react';
import { TableHead, TableBody, Table, Box, TableContainer, Paper } from '@mui/material';
import { CalculatorEnteredData } from 'types/calculator.types';
import { useRecoilCallback, useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  checkedAllCalculatorTable,
  checkedItemsIdsAtom,
  editingItemAtom,
  selectedRows,
  totalChannelsCalculatedSelector,
  totalStorageCalculatedSelector,
  totalValueCalculatedSelector,
} from 'atoms/calculator';
import CalculatorFooter from './CalculatorFooter';
import { CalculatorTableProps, Order, Data } from './CalculatorTable.types';
import { EnhancedTableHead } from './CalculatorTableHeader';
import { EnhancedTableBody } from './CalculatorTableBody';
import { getComparator } from './CalculatorTable.sorting';

export default function CalculatorTable({ list, setModalOpened }: CalculatorTableProps) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('qtChannels');
  const [selected, setSelected] = useRecoilState(selectedRows);
  const totalChannels = useRecoilValue(totalChannelsCalculatedSelector);
  const totalStorage = useRecoilValue(totalStorageCalculatedSelector);
  const totalValue = useRecoilValue(totalValueCalculatedSelector);
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const setCheckedItemsIds = useSetRecoilState(checkedItemsIdsAtom);
  const checkDefault = useRecoilValue(checkedAllCalculatorTable);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleEditClick: (item: CalculatorEnteredData) => MouseEventHandler = useRecoilCallback(
    ({ set }) => (item) => async (e) => {
      set(editingItemAtom, item);
      setModalOpened(true);
      e.stopPropagation();
    },
    []
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = list.map((n) => n.id);
      setSelected(newSelected);
      setCheckedItemsIds((old) => {
        const aux = { ...old };
        for (let i = 0; i < newSelected.length; i++) {
          aux[newSelected[i]] = event.target.checked;
        }
        return aux;
      });
      return;
    }
    setSelected([]);
    setCheckedItemsIds((old) => {
      const aux = { ...old };
      for (let i = 0; i < list.length; i++) {
        aux[list[i].id] = false;
      }
      return aux;
    });
  };

  useEffect(() => {
    if (checkDefault) {
      const newSelected = list.map((n) => n.id);
      setSelected(newSelected);
      setCheckedItemsIds((old) => {
        const aux = { ...old };
        for (let i = 0; i < newSelected.length; i++) {
          aux[newSelected[i]] = checkDefault;
        }
        return aux;
      });
    }
  }, [checkDefault, list, setCheckedItemsIds, setSelected]);
  return (
    <Box display="flex" flexDirection="column" justifyContent="start" width="100%">
      <Paper
        sx={{
          width: '100%',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 500,
          }}
        >
          <Table
            aria-label="Calculator Table"
            stickyHeader
            sx={{
              borderTopLeftRadius: '5px',
              borderRadius: '5px',
            }}
          >
            <TableHead>
              <EnhancedTableHead
                createSortHandler={createSortHandler}
                onSelectAllClick={handleSelectAllClick}
                order={order}
                orderBy={orderBy}
                numSelected={selected.length}
                rowCount={list.length}
              />
            </TableHead>

            <TableBody>
              {list
                .slice()
                .sort(getComparator(order, orderBy))
                .map((item) => (
                  <EnhancedTableBody
                    key={item.id}
                    isItemSelected={isSelected(item.id)}
                    handleEditClick={handleEditClick}
                    list={list}
                    item={item}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CalculatorFooter
        totalChannels={totalChannels}
        totalStorage={totalStorage}
        totalValue={totalValue}
      />
    </Box>
  );
}
