import React from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { Company } from 'types/companies.types';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { Delete, Edit } from '@mui/icons-material';
import routes from 'helpers/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSetRecoilState } from 'recoil';
import { deleteConfirmOpenedAtom } from 'atoms/companies';
import InfoItem from 'components/InfoItem';
import CompanyLogo from './CompanyLogo';

interface CompanyDetailsProps {
  company: Company;
}
export default function CompanyDetails({ company }: CompanyDetailsProps) {
  const { t } = useTranslation();
  const setDeleteConfirmOpened = useSetRecoilState(deleteConfirmOpenedAtom);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        margin: '64px 42px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <InfoItem label="Logo">
            <CompanyLogo
              base64Image={company.base64Photo}
              altText={`Logo - ${company.companyName}`}
            />
          </InfoItem>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Permissioned role={PermissionRole.REMOVE_COMPANY}>
            <Tooltip title={t('roles:remove_company')}>
              <IconButton
                onClick={() => setDeleteConfirmOpened(true)}
                aria-label="delete"
                size="large"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Permissioned>
          <Permissioned role={PermissionRole.EDIT_COMPANY}>
            {company.id && (
              <Link to={routes.settings.companies.edit(company.id)}>
                <Tooltip title={t('roles:edit_company')}>
                  <IconButton aria-label="edit" color="default" size="large">
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
          </Permissioned>
        </Box>
      </Box>

      <Box>
        <Box sx={{ marginBottom: '50px', marginTop: '36px' }}>
          <InfoItem label={t('customers:fantasyName')}>{company.fantasyName}</InfoItem>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box>
              <InfoItem label={t('customers:company_name')}>{company.companyName}</InfoItem>
            </Box>
          </Grid>
          {company.federalRegistration && (
            <Grid item xs={6}>
              <Box>
                <InfoItem label="CNPJ">{company.federalRegistration}</InfoItem>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box sx={{ margin: '52px 0' }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box>
              <InfoItem label="E-mail">{company.email}</InfoItem>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <InfoItem label={t('customers:phone')}>{company.phone}</InfoItem>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ margin: '52px 0' }} />
        {company.address && (
          <Box>
            <InfoItem label={t('customers:address')}>{company.address}</InfoItem>
          </Box>
        )}
      </Box>
    </Box>
  );
}
