import { selectorFamily, atom, atomFamily } from 'recoil';
import deepEqual from 'deep-equal';
import { Mosaic } from 'types/mosaics.types';

export const mosaicInitialDataAtom = atomFamily<Mosaic | null, number>({
  key: 'mosaicInitialDataAtom',
  default: null,
});

export const metricsStartLoadingAtom = atom<Date | null>({
  key: 'metricsStartLoadingAtom',
  default: null,
});

export const mosaicAtom = atomFamily<Mosaic | null, number>({
  key: 'mosaic',
  default: null,
});

export const createMosaicModalOpened = atom({
  key: 'createMosaicModalOpened',
  default: false,
});

export const deleteMosaicConfirmOpened = atom({
  key: 'deleteMosaicConfirmOpened',
  default: false,
});

export const viewVideoDetailsAtom = atom({
  key: 'src/atoms/mosaics/viewVideoDetailsAtom',
  default: false,
});

export const unstableChannelsAtom = atom<number[]>({
  key: 'unstableChannelsAtom',
  default: [],
});

export const unstableConnectionsAtom = atom<number[]>({
  key: 'unstableConnectionsAtom',
  default: [],
});

export const mosaicHasChangesSelector = selectorFamily<boolean, number>({
  key: 'mosaicHasChangesSelector',
  get: (mosaicId: number) => ({ get }) => {
    const mosaicValue = get(mosaicAtom(mosaicId));
    const mosaicApi = get(mosaicInitialDataAtom(mosaicId));

    return !deepEqual(mosaicApi, mosaicValue);
  },
});
