import { TFunction, Namespace } from 'react-i18next';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Column } from 'types/table.types';

type KeyOf<RowType> = {
  [Property in keyof RowType]: RowType[Property];
};

function exportAsPDF<RowType extends KeyOf<RowType>>({
  rows,
  t,
  title,
  columns,
  printAfterTable,
}: {
  rows: KeyOf<RowType>[];
  t: TFunction<Namespace<'_common'>>;
  title: string;
  columns: Column<KeyOf<RowType>>[];
  printAfterTable?: (doc: jsPDF) => void;
}) {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.text(title, 15, 15);

  doc.setFontSize(9);
  doc.setFont('helvetica', 'light');
  doc.setTextColor('#707070');

  // const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const date = t('_common:exported_in', { date: new Date().toLocaleString(navigator.language) });

  doc.text(date, 15, 22);

  const transformsDates = (value: string | number | Date): string | number =>
    value instanceof Date ? value.toLocaleString(navigator.language) : value;

  const body = rows.map((row) =>
    Object.fromEntries(
      columns.map((column) => [
        column.dataKey,
        column.renderValue
          ? column.renderValue(row[column.dataKey])
          : transformsDates(row[column.dataKey]),
      ])
    )
  );

  doc.autoTable({
    startY: 40,
    headStyles: { fillColor: 'white', textColor: 'black' },
    styles: {
      minCellHeight: 9,
      fontSize: 9,
    },
    columnStyles: Object.fromEntries(
      columns
        .filter((column) => !!column.align)
        .map((column) => [column.dataKey, { halign: column.align }])
    ),
    body,
    columns: columns.map((column) => ({
      header: column.description,
      dataKey: column.dataKey as string,
      align: column.align,
    })),
  });

  printAfterTable && printAfterTable(doc);

  doc.save(`${title} - ${new Date().toLocaleString(navigator.language)}.pdf`);
}

export default exportAsPDF;
