import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function UnstableConnection({ sx, ...props }: SvgIconProps) {
  const sxCalculated = { width: '16px', height: '16px', ...sx };
  return (
    <SvgIcon viewBox="0 0 16 12" fill="none" sx={sxCalculated} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4444 1.55573V4.66684L14.48 2.62239C15.04 2.06239 16 2.46239 16 3.25351V8.73795C16 9.52906 15.04 9.92906 14.48 9.36906L12.4444 7.33351V10.4446C12.4444 10.9335 12.0444 11.3335 11.5556 11.3335H0.888889C0.4 11.3335 0 10.9335 0 10.4446V1.55573C0 1.06684 0.4 0.66684 0.888889 0.66684H11.5556C12.0444 0.66684 12.4444 1.06684 12.4444 1.55573ZM0.888951 4.66819L1.82228 5.60204C2.39265 5.03135 3.05377 4.58481 3.80562 4.26241C4.55747 3.94001 5.36302 3.77881 6.22228 3.77881H6.40562C6.46117 3.77881 6.5334 3.78622 6.62228 3.80104L7.28895 2.51144C7.11117 2.48921 6.93339 2.47253 6.75562 2.46142C6.57784 2.4503 6.40006 2.44474 6.22228 2.44474C5.17043 2.44474 4.18895 2.64115 3.27784 3.03396C2.36673 3.42676 1.57043 3.97151 0.888951 4.66819ZM2.77784 6.53589L3.71117 7.49197C3.92599 7.27704 4.16302 7.0936 4.42228 6.94167C4.68154 6.78973 4.95562 6.66929 5.24451 6.58036L5.95562 5.13511C5.32599 5.17217 4.74265 5.31114 4.20562 5.55201C3.66858 5.79288 3.19265 6.12084 2.77784 6.53589ZM5.39451 9.03171C5.51673 9.25776 5.68895 9.41155 5.91117 9.49307C6.14821 9.58201 6.38525 9.57645 6.62228 9.4764C6.85932 9.37634 7.00747 9.20403 7.06673 8.95945L8.51117 3.11178C8.52599 3.04507 8.51858 2.98578 8.48895 2.9339C8.45932 2.88202 8.41488 2.84496 8.35562 2.82273C8.28895 2.80049 8.22784 2.79864 8.17228 2.81717C8.11673 2.8357 8.07414 2.87461 8.04451 2.9339L5.37784 8.35911C5.26673 8.58146 5.27228 8.80566 5.39451 9.03171ZM8.58895 7.35856C8.6408 7.40303 8.68895 7.4475 8.73339 7.49197L9.66673 6.53589C9.53339 6.40248 9.3908 6.27834 9.23895 6.16346C9.0871 6.04858 8.93339 5.93556 8.77784 5.82438L8.42228 7.22516C8.48154 7.26962 8.5371 7.31409 8.58895 7.35856ZM9.90562 4.99059C10.1612 5.17588 10.4001 5.37969 10.6223 5.60204L11.5556 4.66819C11.2445 4.35691 10.9075 4.07527 10.5445 3.82328C10.1815 3.57129 9.80006 3.34894 9.40006 3.15624L9.08895 4.49031C9.37784 4.63854 9.65006 4.8053 9.90562 4.99059Z"
        fill="#323232"
      />
    </SvgIcon>
  );
}

export default UnstableConnection;
