import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
import { EventFilterType } from 'types/event.types';

function EventTypeFilter({
  selectedEventTypes,
  setSelectedEventType,
}: {
  selectedEventTypes: number[];
  setSelectedEventType: (r: number[]) => void;
}) {
  const { t } = useTranslation(['channel_events', 'events', 'channel_recording_requests']);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="event-filter-event-type-label">{t('events:event_type')}</InputLabel>
      <Select
        labelId="event-filter-event-type-label"
        id="event-filter-event-type"
        label={t('events:event_type')}
        multiple
        value={selectedEventTypes}
        onChange={({ target: { value } }: SelectChangeEvent<typeof selectedEventTypes>) => {
          setSelectedEventType(typeof value === 'string' ? value.split(',').map(Number) : value);
        }}
        renderValue={(selected) => selected.map((eventType) => t(`${eventType}`)).join(', ')}
      >
        <MenuItem value={EventFilterType.CONNECTION_ATTEMPT}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_ATTEMPT)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_ATTEMPT}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_FAILED}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_FAILED)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_FAILED}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_SHUTDOWN}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_SHUTDOWN)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_SHUTDOWN}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_SUCCESS}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_SUCCESS)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_SUCCESS}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_ERROR}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_ERROR)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_ERROR}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_SIZE_PROBLEM}>
          <Checkbox
            checked={selectedEventTypes.includes(EventFilterType.CONNECTION_SIZE_PROBLEM)}
          />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_SIZE_PROBLEM}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CONNECTION_MAXATTEMPTS}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CONNECTION_MAXATTEMPTS)} />
          <ListItemText primary={t(`${EventFilterType.CONNECTION_MAXATTEMPTS}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.CODEC_NOT_SUPPORTED}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.CODEC_NOT_SUPPORTED)} />
          <ListItemText primary={t(`${EventFilterType.CODEC_NOT_SUPPORTED}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.START_LIVE_STREAM}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.START_LIVE_STREAM)} />
          <ListItemText primary={t(`${EventFilterType.START_LIVE_STREAM}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.START_RECORDING}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.START_RECORDING)} />
          <ListItemText primary={t(`${EventFilterType.START_RECORDING}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.STOP_LIVE_STREAM}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.STOP_LIVE_STREAM)} />
          <ListItemText primary={t(`${EventFilterType.STOP_LIVE_STREAM}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.STOP_RECORDING}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.STOP_RECORDING)} />
          <ListItemText primary={t(`${EventFilterType.STOP_RECORDING}`)} />
        </MenuItem>
        <MenuItem value={EventFilterType.OFFLINE_DEVICE}>
          <Checkbox checked={selectedEventTypes.includes(EventFilterType.OFFLINE_DEVICE)} />
          <ListItemText primary={t(`${EventFilterType.OFFLINE_DEVICE}`)} />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
export default EventTypeFilter;
