import React from 'react';
import { RecordingType } from 'types/channels.types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
// import { useDecision } from '@optimizely/react-sdk';
// import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

function SelectRecordingTypeToFilters({
  selectedRecordingTypes,
  onChangeRecordingTypes,
}: {
  selectedRecordingTypes: string[];
  onChangeRecordingTypes: (r: string[]) => void;
}) {
  const { t } = useTranslation('channels');
  // const [preAlarmFlag] = useDecision(FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE)
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-recording-type-label">{t('recording_type')}</InputLabel>
      <Select
        labelId="select-recording-type-label"
        id="select-recording-type"
        label={t('recording_type')}
        multiple
        value={selectedRecordingTypes}
        onChange={({ target: { value } }: SelectChangeEvent<typeof selectedRecordingTypes>) => {
          onChangeRecordingTypes(typeof value === 'string' ? value.split(',') : value);
        }}
        renderValue={(selected) =>
          selected.map((recordingType) => t(`recording_type_${recordingType}`)).join(', ')
        }
      >
        <MenuItem value={RecordingType.RECORD_24_7}>
          <Checkbox checked={selectedRecordingTypes.includes(RecordingType.RECORD_24_7)} />
          <ListItemText primary={t('recording_type_RECORD_24_7')} />
        </MenuItem>
        <MenuItem value={RecordingType.EVENT}>
          <Checkbox checked={selectedRecordingTypes.includes(RecordingType.EVENT)} />
          <ListItemText primary={t('recording_type_EVENT')} />
        </MenuItem>
        <MenuItem value={RecordingType.SCHEDULE}>
          <Checkbox checked={selectedRecordingTypes.includes(RecordingType.SCHEDULE)} />
          <ListItemText primary={t('recording_type_SCHEDULE')} />
        </MenuItem>
        <MenuItem value={RecordingType.LIVE}>
          <Checkbox checked={selectedRecordingTypes.includes(RecordingType.LIVE)} />
          <ListItemText primary={t('recording_type_LIVE')} />
        </MenuItem>
        {/* {preAlarmFlag.enabled && <MenuItem value={RecordingType.PRE_ALARM}>
          <Checkbox checked={selectedRecordingTypes.includes(RecordingType.PRE_ALARM)} />
          <ListItemText primary={t('recording_type_PRE_ALARM')} />
        </MenuItem>} */}
      </Select>
    </FormControl>
  );
}
export default SelectRecordingTypeToFilters;
