import React, { Dispatch, SetStateAction } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CreateMosaicModal from 'components/CreateMosaicModal';
import { createMosaicModalOpened } from 'atoms/mosaics';
import { getTemplate } from 'helpers/mosaicTemplates';
import { Mosaic, Template } from 'types/mosaics.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { useMosaicApi } from 'hooks/mosaic/useMosaicApi';
import routes from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import RectangularChannel from './RectangularChannel';
import ChannelActionsInBatch from './ChannelActionsInBatch';

interface Channel {
  id: number;
  name: string;
  channelPrivate: boolean;
  status: boolean;
}

function ChannelList({
  customerId,
  channels,
  channelIdSelected,
  setChannelIdSelected,
  channelsChecked,
  setChannelsChecked,
  setTab,
}: {
  customerId: number;
  channels: Channel[];
  channelIdSelected: number | null;
  setChannelIdSelected: SetterOrUpdater<number | null>;
  channelsChecked: { id: number; checked: boolean; status: boolean }[];
  setChannelsChecked: React.Dispatch<
    React.SetStateAction<{ id: number; checked: boolean; status: boolean }[]>
  >;
  setTab: Dispatch<SetStateAction<string>>;
}) {
  const { t } = useTranslation();
  const [createModalOpened, setCreateModalOpened] = useRecoilState(createMosaicModalOpened);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const { createMosaicFromCustomer } = useMosaicApi();
  const havePermissionFn = useHavePermission();
  const history = useHistory();
  const [channelsInBatchFlag] = useDecision(
    FEATURE_FLAGS_KEYS.ACTIVATE__DEACTIVATE_CHANNELS_IN_BATCH
  );

  const handleOpenMosaicModal = () => {
    setCreateModalOpened(!createModalOpened);
  };

  const handleSubmit = async (data: Mosaic) => {
    await createMosaicFromCustomer(data, customerId);
  };

  const handleNewChannel = () => {
    history.push(routes.customer.channel.create.root(customerId));
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          maxWidth: '29rem',
          width: '29rem',
          paddingRight: 2,
          gap: 2,
          zIndex: '1',
          pt: '1.5rem',
        }}
      >
        <Permissioned role={PermissionRole.INSERT_CHANNEL}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleNewChannel}
            disabled={!!channelsChecked.length}
          >
            {t('channels:new')}
          </Button>
        </Permissioned>
        <Permissioned role={PermissionRole.INSERT_MOSAIC}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleOpenMosaicModal}
            disabled={!!channelsChecked.length}
          >
            {t('mosaics:create_mosaic')}
          </Button>
        </Permissioned>
      </Box>
      <Box
        sx={{
          // eslint-disable-next-line no-nested-ternary
          mt: havePermissionFn(PermissionRole.INSERT_MOSAIC)
            ? '4.5rem'
            : havePermissionFn(PermissionRole.INSERT_CHANNEL)
            ? '4.5rem'
            : '1rem',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingRight: 2,
        }}
      >
        {channelsInBatchFlag.enabled && channels.length > 1 && (
          <ChannelActionsInBatch
            {...{
              channels,
              channelsChecked,
              customerId,
              setChannelsChecked,
              setTab,
              checkedAll,
              setCheckedAll,
            }}
          />
        )}
        <Box
          component="ul"
          aria-label="channels"
          sx={{
            margin: 0,
            padding: 0,
            width: '28.1rem',
            height: '100%',
            overflow: 'auto',
            scrollBehavior: 'smooth',
            marginTop: '12px',
            pb: '3.5rem',
            pr: 2,
          }}
        >
          {channels.length > 1 ? (
            channels.map((channel, _, arr) => (
              <RectangularChannel
                key={channel.id}
                channel={channel}
                selected={(channelIdSelected || channels[0].id) === channel.id}
                setSelected={(channelId) => setChannelIdSelected(channelId)}
                onClick={() => setChannelIdSelected(channel.id)}
                channelsChecked={channelsChecked}
                setChannelsChecked={setChannelsChecked}
                checkedAll={checkedAll}
              />
            ))
          ) : (
            <RectangularChannel
              {...{
                channel: channels[0],
                channelsChecked,
                selected: !!(channelIdSelected || channels[0].id),
                setChannelsChecked,
                setSelected: (channelId) => setChannelIdSelected(channelId),
                onClick: () => setChannelIdSelected(channels[0].id),
                noCheckbox: true,
                checkedAll: false,
              }}
            />
          )}
        </Box>
      </Box>
      <CreateMosaicModal
        title={t('mosaics:create_mosaic_with_all_channels')}
        description={t('mosaics:double_check_before_create_mosaic')}
        initialValues={{ qtyChannels: getTemplate(channels.length) as Template }}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}

export default ChannelList;
