import React from 'react';
import { Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MosaicCard } from 'components/ItemsCard';
import { ResultsContainer } from './ResultsContainer.1';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultContainer: {
      backgroundColor: theme.palette.background.default,
      padding: '16px',
      marginTop: '16px',
      overflow: 'auto',
      flex: '1 1 auto',
    },
  })
);

export function Loading() {
  const classes = useStyles();

  return (
    <Paper className={classes.resultContainer}>
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MosaicCard key={i} loading />
      ))}
    </Paper>
  );
}

export default ResultsContainer;
