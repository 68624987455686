import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';

import './locales/i18n';
import 'config/axios';

Sentry.init({
  dsn: 'https://a269e4ee8a234b05a5f651f5cf743ab8@o98808.ingest.sentry.io/6159020',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV === 'production',
});

const rootElement = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
