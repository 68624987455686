import React from 'react';
import { IRecordingSchedulerForm } from 'types/recordingSchedule.types';
import { useRecordingScheduleCRUD } from './useRecordingScheduleCRUD';
import { RecordingScheduleForm } from './RecordingScheduleForm';

function RecordingScheduleCreate() {
  const { createRecordingSchedule } = useRecordingScheduleCRUD();

  const initialValues: IRecordingSchedulerForm = {
    name: '',
    timeRanges: [],
    customers: [],
  };

  const handleSubmit = React.useCallback(
    async (values: IRecordingSchedulerForm) => {
      try {
        await createRecordingSchedule(values);
      } catch (error) {
        console.error(error);
      }
    },
    [createRecordingSchedule]
  );

  return <RecordingScheduleForm initialValues={initialValues} onSave={handleSubmit} />;
}

export default RecordingScheduleCreate;
