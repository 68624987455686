import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formatToReal } from 'helpers/formatToReal';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    width: '23rem',
    borderRadius: '5px'
  },
  subContainer: {
    padding: '1rem 2rem'
  },
  title: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px'

  },
  label: {
    fontSize: '0.85rem',
    color: theme.palette.grey[400],
    margin: '0.2rem 0'
  },
  value: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  totalLabel: {
    fontSize: '1.25rem',
    color: theme.palette.grey[500]
  },
  totalValue: {
    fontSize: '1.50rem',
    fontWeight: 'bold'
  },
  helperText: {
    color: theme.palette.grey[500],
    fontSize: '0.85rem',
    textAlign: 'left',
    padding: '0 1rem',
    margin: '1rem',
    maxWidth: '23rem'
  }
}))

export function FooterTotalStorage(
  {
    totalValue
  }: {
    totalValue: number
  }
) {

  const { t } = useTranslation(['calculator', 'metrics']);

  const classes = useStyles();

  return (
    <>
      <Box className={classes.subContainer}>
        <Typography className={classes.totalLabel}>{t('expected_total')}</Typography>
        <Typography className={classes.totalValue}>{formatToReal(totalValue)}</Typography>
      </Box>
    </>
  );
}