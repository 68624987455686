import React from 'react';
import { TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { checkedAllCalculatorTable } from 'atoms/calculator';
import { EnhancedTableHeadProps, headCells } from "./CalculatorTable.types";

export function EnhancedTableHead(
  {
    order,
    orderBy,
    numSelected,
    rowCount,
    onSelectAllClick,
    createSortHandler
  }: EnhancedTableHeadProps
) {
  const { t } = useTranslation(['_common', 'channels']);
  const [checked, setChecked] = useRecoilState(checkedAllCalculatorTable);
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          inputProps={{
            'aria-label': 'select all channels',
          }}
          onChange={(event) => {
            setChecked(!checked);
            onSelectAllClick(event);
          }}
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={checked}
        />
      </TableCell>
      {
        headCells.map((header) => (
          <TableCell
            key={header.id}
            padding={header.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === header.id ? order : false}
            width={header.width}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
              sx={theme => ({
                color: theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.text.primary,
                }
              })}

            >
              {t(`${header.label}`)}
            </TableSortLabel>
          </TableCell>
        ))
      }
      <TableCell />
    </TableRow>
  )
}
;