import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import { ReactComponent as DefaultLogo } from 'assets/default-logo-for-companies.svg';
import { useBase64Image } from 'hooks/useBase64Image';

interface CompanyLogoProps {
  base64Image?: string;
  selected?: boolean;
  altText: string;
}

export default function CompanyLogo({ base64Image, selected, altText }: CompanyLogoProps) {
  const { src } = useBase64Image(base64Image);
  const [loading, setLoading] = useState(true);

  function handleImageLoad() {
    setLoading(false);
  }

  return (
    <Avatar
      sx={(theme) => ({
        flex: '0 0 auto',
        bgColor: selected ? theme.palette.background.default : undefined,
        width: '125px',
        height: '80px',
      })}
      variant="rounded"
    >
      {base64Image ? (
        <>
          <img
            onLoad={handleImageLoad}
            src={src}
            alt={altText}
            style={{
              borderRadius: '2px',
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              display: loading ? 'none' : 'block',
            }}
          />
        </>
      ) : (
        <DefaultLogo />
      )}
    </Avatar>
  );
}
