import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Mosaics(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M2 4C0.895 4 0 4.895 0 6V17C0 18.105 0.895 19 2 19H8V21H16V19H22C23.105 19 24 18.105 24 17V6C24 4.895 23.105 4 22 4H2ZM2 6H22V17H2V6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66211 10.781H11.3382V7.32538H3.66211V10.781ZM3.66211 15.6004H11.3382V12.1448H3.66211V15.6004ZM12.6641 10.781H20.3401V7.32538H12.6641V10.781ZM12.6641 15.6004H20.3401V12.1448H12.6641V15.6004Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Mosaics;
