import React from 'react';
import { Playlist } from 'types/playlist';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Paper,
  Typography,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Delete, PlayCircleFilled } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import routes from 'helpers/routes';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { ConfirmModal } from 'components/core';
import { usePlaylistApi } from 'hooks/playlists/usePlaylistApi';

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginBottom: theme.spacing(1),
  },
  listItemTitle: {
    minWidth: '35%',
    maxWidth: '80%',
  },
}));

function PlaylistsList({
  playlists,
  setCreateModalOpened,
}: {
  playlists: Playlist[];
  setCreateModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(['_common', 'playlists', 'crud_actions']);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false);
  const [idInDeletion, setIdInDeletion] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { deletePlaylist } = usePlaylistApi();

  const classes = useStyles();

  const handleDelete = async () => {
    setIsDeleting(true);
    await deletePlaylist(idInDeletion);
    setDeleteConfirmModalOpen(false);
    setIsDeleting(false);
  };

  const handlePutOnDeletion = (id: number) => {
    setIdInDeletion(id);
    setDeleteConfirmModalOpen(true);
  };

  return (
    <>
      <Box marginBottom="24px">
        <Permissioned role={PermissionRole.INSERT_PLAYLIST}>
          <Button
            onClick={() => setCreateModalOpened(true)}
            color="primary"
            variant="contained"
            size="small"
          >
            {t('playlists:create_a_playlist')}
          </Button>
        </Permissioned>
      </Box>
      <List disablePadding sx={{ overflow: 'auto' }}>
        {playlists.map((playlist) => (
          <ListItem
            role="listitem"
            component={Paper}
            key={playlist.id}
            classes={{
              root: classes.listItem,
            }}
          >
            <ListItemIcon>
              <Link aria-label={`Play ${playlist.id}`} to={routes.playlists.play(playlist.id)}>
                <IconButton size="small">
                  <PlayCircleFilled fontSize="large" />
                </IconButton>
              </Link>
            </ListItemIcon>
            <Typography className={classes.listItemTitle}>{playlist.name}</Typography>
            <Typography>{t('mosaics_count', { count: playlist.items.length })}</Typography>
            <Permissioned role={PermissionRole.REMOVE_PLAYLIST}>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => handlePutOnDeletion(playlist.id)}
                  aria-label={`delete playlist ${playlist.id}`}
                  edge="end"
                  size="small"
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </Permissioned>
          </ListItem>
        ))}
      </List>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        description=""
        title={t('playlists:want_delete')}
      />
    </>
  );
}

PlaylistsList.Loading = function PlaylistsListLoading() {
  return (
    <List>
      {Array.from({ length: 5 }).map((x, id) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={id}>
          <PlayCircleFilled />
          <Typography>
            <Skeleton />
          </Typography>
          <Typography>
            <Skeleton />
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default PlaylistsList;
