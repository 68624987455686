import { select, easeExp } from 'd3';
import React, { useEffect } from 'react';

function BarLoading() {
  useEffect(function mountBarLoading() {
    const g = select('.barLoading');
    /* barra fundo */
    g.append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', 'white')
      .attr('opacity', '.1');

    /* barra efeito skeleton */
    const b = g
      .append('rect')
      .attr('width', '80px')
      .attr('height', '100%')
      .attr('fill', 'url(#bar-loading-gradient)');
    repeat();
    function repeat() {
      b.attr('x', '0')
        .transition()
        .duration(1500)
        .ease(easeExp)
        .attr('x', '100%')
        .on('end', repeat);
    }
  }, []);
  return (
    <g className="barLoading">
      <defs>
        <linearGradient id="bar-loading-gradient" gradientTransform="rotate(0)">
          <stop offset="0%" stopColor="white" stopOpacity="0" />
          <stop offset="50%" stopColor="white" stopOpacity=".3" />
          <stop offset="100%" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </g>
  );
}

export default BarLoading;
