export interface Company {
  fantasyName: string;
  companyName: string;
  federalRegistration: string;
  email: string;
  phone: string;
  address: string;
  file?: File;
  base64Photo?: string;
  id?: number;
  idMask?: string;
  namePhoto?: string;
}
export interface CompanySimplifyListItem {
  id: number;
  companyName: string;
  base64Photo: string;
  namePhoto: string;
}

export interface LogoData {
  id: number;
  file: File;
}

export interface CompanyResponse {
  id: number;
}
export interface SelectCompanyTypeValue {
  id: number;
  companyName: string;
}

export const DEFAULT_COMPANY: Company = {
  fantasyName: '',
  companyName: '',
  federalRegistration: '',
  email: '',
  phone: '',
  address: '',
};
