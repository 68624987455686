import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import routes, { mosaicViewContext } from 'helpers/routes';
import { Box } from '@mui/material';
import CustomerTopBar from './CustomerTopBar';
import MosaicTopBarInternal from './V2MosaicTopBar';
import SettingsTopBar from './SettingsTopBar';
import MosaicSlotViewTopBar from './MosaicSlotViewTopBar';
import PlaybackTopBar from './PlaybackTopBar';
import EventTopBar from './EventTopBar';
import ChannelsMonitoringTopBar from './ChannelsMonitoringTopBar';
import RecordingsTopBar from './RecordingsTopBar';
import PlaylistTopBar from './PlaylistTopBar';
import PlaylistPlayTopBar from './PlaylistPlayTopbar';
import { MosaicTopBar } from './V2MosaicTopBar/V2MosaicTopBar';

function TopBar() {
  // const [newMosaicDesign] = useDecision(FEATURE_FLAGS_KEYS.NEW_MOSAIC_DESIGN);

  return (
    <>
      <Switch>
        <TopBarRoute path={routes.customer.root()} component={CustomerTopBar} />
        <TopBarRoute
          path={routes.mosaic.slotView(':mosaicId', ':slotId')}
          component={MosaicSlotViewTopBar}
        />
        <TopBarRoute path={routes.mosaic.root} exact component={MosaicTopBar} />
        <TopBarRoute path={mosaicViewContext} component={MosaicTopBarInternal} />
        <TopBarRoute path={routes.events.root()} component={EventTopBar} />
        <TopBarRoute path={routes.channels.monitoring()} component={ChannelsMonitoringTopBar} />
        <TopBarRoute path={routes.recordings.root()} component={RecordingsTopBar} />
        <TopBarRoute path={routes.settings.root} component={SettingsTopBar} exact={false} />
        <TopBarRoute
          path={routes.customer.channel.playback(':customerId', ':channelId')}
          component={PlaybackTopBar}
        />
        <TopBarRoute exact path={routes.playlists.root()} component={PlaylistTopBar} />
        <TopBarRoute
          exact
          path={routes.playlists.play(':playlistId')}
          component={PlaylistPlayTopBar}
        />
      </Switch>
      <Divider />
    </>
  );
}

function TopBarRoute(routeProps: RouteProps) {
  return (
    <Box
      bgcolor="background.default"
      role="region"
      aria-label="Top bar"
      sx={{
        px: 4,
        py: 2,
      }}
    >
      <Route {...routeProps} />
    </Box>
  );
}

export default TopBar;
