import { RecordingType } from 'types/channels.types';
import { CalculatedItem, CalculatorEnteredData } from 'types/calculator.types';
import { makeStyles } from '@mui/styles';

export type Order = 'asc' | 'desc';

export type CalculatorList = CalculatorEnteredData & CalculatedItem;

export interface CalculatorTableProps {
  list: CalculatorList[];
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Data {
  qtChannels: number;
  recordingType: RecordingType;
  totalSpaceUsedPrice: number;
  totalPrice: number;
}

export interface HeadCell {
  id: keyof Data;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  width?: number;
}

export interface EnhancedTableHeadProps {
  numSelected: number;
  rowCount: number;
  order: Order;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy: keyof Data;
  createSortHandler: (property: keyof Data) => (event: React.MouseEvent<unknown>) => void;
}

export interface EnhancedTableBodyProps {
  list: CalculatorList[];
  handleEditClick: (item: CalculatorEnteredData) => React.MouseEventHandler;
  isItemSelected: boolean;
  item: CalculatorList;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'qtChannels',
    numeric: true,
    disablePadding: true,
    label: 'search_bar:number_channels',
  },
  {
    id: 'recordingType',
    numeric: false,
    disablePadding: false,
    label: 'channels:recording_type',
  },
  {
    id: 'totalSpaceUsedPrice',
    numeric: true,
    disablePadding: false,
    label: 'metrics:necessary_storage',
  },
  {
    id: 'totalPrice',
    numeric: true,
    disablePadding: false,
    label: 'calculator:price',
    width: 50,
  },
];

export const useStyles = makeStyles((theme) => ({
  containerToggle: {
    display: 'flex',
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  },
  accordionSummary: {
    alignItems: 'center',
  },
  summaryTotalValue: {
    flex: '1 1 auto',
    marginRight: theme.spacing(2),
  },
  summaryQtdChannels: {
    marginRight: '4px',
  },
  summaryRecordingType: {
    margin: theme.spacing(0, 4),
    display: 'inline-flex',
    alignItems: 'baseline',
  },
  summaryRecordingTypeSvg: {
    marginTop: 'auto',
    marginRight: theme.spacing(1),
  },
  summaryTotalStorage: {
    marginRight: '4px',
  },
  headerInputPrice: {
    marginRight: '8px',
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
  },
}));
