import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Encrypted(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="encrypted">
        <mask
          id="mask0_111_3643"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_111_3643)">
          <path
            id="encrypted_2"
            d="M10.5 15H13.5L12.925 11.775C13.2583 11.6083 13.5208 11.3667 13.7125 11.05C13.9042 10.7333 14 10.3833 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.3833 10.0958 10.7333 10.2875 11.05C10.4792 11.3667 10.7417 11.6083 11.075 11.775L10.5 15ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22Z"
            fill="currentColor"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default Encrypted;
