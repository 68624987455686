import React from 'react';
import { Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface IResponseMessageTooltip {
  message: string;
}
export function ResponseMessageTooltip({ message }: IResponseMessageTooltip) {
  return (
    <Tooltip title={message}>
      <HelpIcon color="action" />
    </Tooltip>
  );
}
