import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/Breadcrumb';
import styled from 'styled-components';

export const Container = styled.div`
  > h4 {
    font-size: 1.375rem;
  }
`;

function SettingsTopBar() {
  const { t } = useTranslation('settings');
  return (
    <Container>
      <Typography variant="h4">{t('settings')?.toUpperCase()}</Typography>
      <Breadcrumb />
    </Container>
  );
}

export default SettingsTopBar;
