import React from 'react';

function useIsWindows() {
  const [isWindows, setIsWindows] = React.useState(false);

  React.useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isWindowsOS = /windows/.test(userAgent);
    setIsWindows(isWindowsOS);
  }, []);

  return isWindows;
}

export { useIsWindows };
