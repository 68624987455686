import styled from 'styled-components';
import { Paper } from '@mui/material';

export const ListContainer = styled(Paper)`
  width: 28.1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0 0 auto;
`;

export const BodyItems = styled.div`
  overflow: auto;
  height: 100%;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  padding: 2rem 1.3rem;
  display: flex;
  align-items: center;

  > h5 {
    flex: 1 1 auto;
  }

  > button {
    flex: 0 0 auto;
  }
`;
