import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { searchPanelOrderedById, searchPanelTextDebouncedAtom } from 'atoms/search';
import { useTranslation } from 'react-i18next';
import { PersonOutlineOutlined, VideocamOutlined, ViewModuleOutlined } from '@mui/icons-material';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import { CustomerCard, MosaicCard, ChannelCard } from 'components/ItemsCard';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import {
  ChannelSearched,
  CustomerSearched,
  MosaicSearched,
  SearchApi,
  SearchType,
} from 'types/search.types';
import { Content, List, NoResultsContent } from './SearchPanel.styles';
import SearchExpansionPanel from './SearchExpansionPanel';

function ResultsContainer() {
  const text = useRecoilValue(searchPanelTextDebouncedAtom);
  const clientsOrderedById = useRecoilValue(searchPanelOrderedById('search-clients'));
  const mosaicsOrderedById = useRecoilValue(searchPanelOrderedById('search-mosaics'));
  const channelsOrderedById = useRecoilValue(searchPanelOrderedById('search-channels'));

  const { data } = useFetch<SearchApi>('/v1/search', {
    params: {
      text,
    },
    initialData: text ? undefined : [],
    revalidateOnReconnect: Boolean(text),
    revalidateOnFocus: Boolean(text),
    conditionFn: () => text.length >= 3,
  });

  const results = useMemo(
    () => ({
      customers: (data?.filter((result) => result.type === SearchType.CLIENT) ||
        []) as CustomerSearched[],
      mosaics: (data?.filter((result) => result.type === SearchType.MOSAIC) ||
        []) as MosaicSearched[],
      channels: (data?.filter((result) => result.type === SearchType.CHANNEL) ||
        []) as ChannelSearched[],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, clientsOrderedById, channelsOrderedById, mosaicsOrderedById]
  );

  const hasResults = useMemo(() => {
    if (!results) {
      return false;
    }
    const hasCustomers = results.customers.length > 0;
    const hasMosaics = results.mosaics.length > 0;
    const hasChannels = results.channels.length > 0;
    return hasCustomers || hasMosaics || hasChannels;
  }, [results]);

  const { t } = useTranslation('search_bar');
  const { closeSearchPanel } = useGlobalSearch();

  if (text && !hasResults) {
    return <NoResultsContent>{t('no_results')}</NoResultsContent>;
  }

  if (!hasResults || !results) return null;

  return (
    <Content>
      {results.customers?.length > 0 && (
        <SearchExpansionPanel
          name="search-clients"
          label={t('category_customers')}
          length={results.customers?.length}
          Icon={PersonOutlineOutlined}
        >
          <List>
            {results.customers
              ?.sort((a, b) => {
                if (clientsOrderedById) {
                  return b.id - a.id;
                }
                return a.tradeName.localeCompare(b.tradeName);
              })
              .map((customer) => (
                <CustomerCard
                  key={customer.id}
                  customer={customer}
                  onClick={closeSearchPanel}
                  route={routes.customer.view(customer.id)}
                />
              ))}
          </List>
        </SearchExpansionPanel>
      )}
      {results.channels?.length > 0 && (
        <SearchExpansionPanel
          name="search-channels"
          label={t('category_channels')}
          length={results.channels?.length}
          Icon={VideocamOutlined}
        >
          <List>
            {results.channels
              ?.sort((a, b) => {
                if (channelsOrderedById) {
                  return b.id - a.id;
                }
                return 0;
              })
              .map((channel) => (
                <ChannelCard
                  key={channel.id}
                  channel={channel}
                  onClick={closeSearchPanel}
                  to={routes.customer.channel.view(
                    channel.clientId.toString(),
                    channel.id.toString()
                  )}
                />
              ))}
          </List>
        </SearchExpansionPanel>
      )}
      {results.mosaics?.length > 0 && (
        <SearchExpansionPanel
          name="search-mosaics"
          label={t('category_mosaics')}
          length={results.mosaics?.length}
          Icon={ViewModuleOutlined}
        >
          <List>
            {results.mosaics
              ?.sort((a, b) => {
                if (mosaicsOrderedById) {
                  return b.id - a.id;
                }
                return a.name.localeCompare(b.name);
              })
              .map((mosaic) => (
                <MosaicCard
                  key={mosaic.id}
                  mosaic={mosaic}
                  onClick={closeSearchPanel}
                  callStartLive={text.length >= 4}
                />
              ))}
          </List>
        </SearchExpansionPanel>
      )}
    </Content>
  );
}

export function Loading() {
  const { t } = useTranslation('search_bar');
  return (
    <Content>
      <SearchExpansionPanel
        name="search-clients"
        label={t('category_customers')}
        Icon={PersonOutlineOutlined}
        loading
      >
        <List>
          {Array.from({ length: 2 }).map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <CustomerCard key={i} loading />
          ))}
        </List>
      </SearchExpansionPanel>
      <SearchExpansionPanel
        name="search-channels"
        label={t('category_channels')}
        Icon={VideocamOutlined}
        loading
      >
        <List>
          {Array.from({ length: 2 }).map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ChannelCard key={i} loading />
          ))}
        </List>
      </SearchExpansionPanel>
      <SearchExpansionPanel
        name="search-mosaics"
        label={t('category_mosaics')}
        Icon={ViewModuleOutlined}
        loading
      >
        <List>
          {Array.from({ length: 2 }).map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <MosaicCard key={i} loading />
          ))}
        </List>
      </SearchExpansionPanel>
    </Content>
  );
}

export default ResultsContainer;
