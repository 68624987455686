import { Fade, Paper, Popper, PopperProps, Typography } from '@mui/material';
import {
  textTimeTooltipFullscreenAtom,
  leftTimeTooltipFullscreenAtom,
} from 'atoms/cronologicBar-fullscreen';
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

function TimeTooltip({ svgEl, id }: { svgEl: React.RefObject<SVGSVGElement>; id: string }) {
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const text = useRecoilValue(textTimeTooltipFullscreenAtom(id));
  const left = useRecoilValue(leftTimeTooltipFullscreenAtom(id));

  useEffect(() => {
    setAnchorEl({
      // clientWidth: 63,
      // clientHeight: 24,
      getBoundingClientRect: () => {
        const rect = svgEl.current?.getBoundingClientRect();
        return new DOMRect(left, rect?.y ?? 0, 1, rect?.height ?? 0);
      },
    });
  }, [text, left, svgEl]);

  return (
    <Popper
      open={!!text}
      anchorEl={anchorEl}
      transition
      style={{
        zIndex: 1500,
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250} exit>
          <Paper>
            <Typography>{text}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default TimeTooltip;
