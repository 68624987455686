import Axios from 'axios';
import { customerSearchTextAtom } from "atoms/customers";
import { textDebouncedAtom } from "atoms/selectChannelPanel";
import useMessages from "hooks/useMessages";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { mutate } from "swr";
import { PermissionRole } from "types/user.types";
import { useHavePermission } from 'hooks/useHavePermission';
import { Customer } from 'types/customer.types';

export function useCustomerApi() {
  const { t } = useTranslation(['customers', 'crud_actions', 'permission', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();
  const searchText = useRecoilValue(customerSearchTextAtom);
  const editPermission = useHavePermission(PermissionRole.EDIT_CLIENT);
  const insertPermission = useHavePermission(PermissionRole.INSERT_CLIENT);
  const removePermission = useHavePermission(PermissionRole.REMOVE_CLIENT);
  const searchSelectCustomerPanel = useRecoilValue(textDebouncedAtom);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mutateLists = (data?: any) => {
    // TODO create a global list controlled in useFecth with all keys with the URL
    const customerPageList = ['/v1/clients', searchText];
    const selectPanelList = [
      '/v1/clients',
      ...Object.values({
        text: searchSelectCustomerPanel,
        limit: searchSelectCustomerPanel ? undefined : 8,
      }),
    ];

    [customerPageList, selectPanelList].forEach((key) => {
      mutate(key, data);
    });
  };

  const editCustomer = async (customerReceived: Customer) => {
    if (!editPermission) {
      enqueueError(`${t('error_editing')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.put(`/v1/clients/${customerReceived.id}`, customerReceived);
      enqueueSuccess(t('customer_changed'));

      mutateLists(async (items?: Customer[]) =>
        items?.map((_item: Customer) =>
          _item.id === customerReceived.id ? customerReceived : _item
        )
      );
      return customerReceived.id;
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const deleteCustomer = async (id: number) => {
    if (!removePermission) {
      enqueueError(`${t('error_deleting')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.delete(`/v1/clients/${id}`);
      enqueueSuccess(t('customer_deleted'));

      mutateLists(async (items?: Customer[]) =>
        items?.filter((_item: Customer) => _item.id !== id)
      );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const createCustomer = async (customerReceived: Customer): Promise<number> => {
    if (!insertPermission) {
      enqueueError(`${t('error_creating')}\n${t('permission:you_dont_have_permission')}`);
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      const { data } = await Axios.post('/v1/clients', customerReceived);
      enqueueSuccess(t('customer_created'));

      mutateLists(async (items?: Customer[]) => [
        { ...customerReceived, id: data.id },
        ...(items || []),
      ]);
      return data.id;
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };
  return {
    editCustomer,
    deleteCustomer,
    createCustomer,
  };
}
