/* eslint-disable no-nested-ternary */
import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Fade,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { liveSelector } from 'atoms/playback';
import Permissioned from 'components/Permissioned';
import { ChannelDropItem, ItemTypes } from 'helpers/dragAndDrop';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import routes from 'helpers/routes';
import { useGetChannelThumbnail } from 'hooks/channels';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import React, { Suspense } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import { VideoLiveSource } from 'types/channels.types';
import { ChannelSimple } from 'types/mosaics.types';
import { PermissionRole } from 'types/user.types';
import { createStyles, makeStyles } from '@mui/styles';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import axios from 'axios';
import { useMessages } from 'hooks';
import ErrorBoundaryVideo from '../MosaicVideo/ErrorBoundaryVideo';
import MosaicVideo, { RemoveSlotInMosaicButton } from '../MosaicVideo/MosaicVideo';

import {
  BIG,
  MEDIUM,
  EXTRA_SMALL,
  EXTRA_BIG,
  SMALL,
  EXTRA_SMALL2,
} from '../MosaicVideo/responsive.service';
import Block from './Block';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<'root' | 'dropHereBox' | 'cardActionArea' | 'thumbnailImg', { selected?: boolean }>({
    root: {
      flex: '1 1 50%',
      display: 'flex',
      border: ({ selected }) => (selected ? `2px solid ${theme.palette.primary.main}` : ''),
      position: 'relative',
    },
    cardActionArea: {
      height: '100%',
      textAlign: 'center',
    },
    dropHereBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: theme.zIndex.drawer,
      backgroundColor: theme.palette.grey[700],
    },
    thumbnailImg: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      filter: 'blur(3px)',
    },
  })
);

function EnableChannelIcon({
  channel,
  returnRoute,
  onClick,
}: {
  returnRoute: string;
  channel: ChannelSimple;
  onClick?: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueError } = useMessages();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const channelResponse = await axios.get(
        `/v1/clients/${channel.clientId}/channels/${channel.id}`
      );

      let channelRoute = '';
      switch (channelResponse?.data?.connectionTypeEnum) {
        case 'RTMP':
          channelRoute = 'editRTMP';
          break;
        case 'RTSP':
          channelRoute = 'edit';
          break;
      }

      switch (channelResponse?.data?.p2pPartner) {
        case 'HIKVISION':
          channelRoute = 'editHikvision';
          break;
        case 'INTELBRAS':
          channelRoute = 'editIntelbras';
          break;
      }

      setLoading(false);

      history.push(
        // @ts-ignore
        routes.customer.channel[channelRoute](channel.clientId, channel.id, returnRoute)
      );
    } catch (err) {
      enqueueError('Não foi possível carregar os dados desta câmera');
      console.error('Erro ao editar canal', err);
      setLoading(false);
    }
  };
  return (
    <Tooltip
      title={
        channel.clientStatus ? t('mosaic:slot_inative_desc_2') : t('mosaic:inactive_description')
      }
    >
      <IconButton
        aria-label={
          channel.clientStatus
            ? `edit channel with id: ${channel.id}`
            : `edit customer with id ${channel.clientId}`
        }
        size="medium"
        onClick={onClick || handleClick}
        disabled={loading}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

function View({
  channelWithSource,
  selected,
  order,
  mosaicId,
  mosaicName,
  limitTransmissionBlocked,
}: {
  channelWithSource?: { channel: ChannelSimple; videoLiveSource?: VideoLiveSource };
  selected?: boolean;
  order: number;
  mosaicName: string;
  mosaicId: number;
  limitTransmissionBlocked: boolean;
}) {
  const [videoLiveSource, setVideoLiveSource] = React.useState<VideoLiveSource>();
  const channel = React.useMemo(() => channelWithSource?.channel, [channelWithSource]);
  const classes = useStyles({ selected });
  const { openChannelSelection } = useSelectChannelPanel();
  const { putChannelInSlot, selectMosaicSlot, removeChannel } = useMosaicItens(mosaicId);
  // live selector aqui apenas para mesmo com live fora do ar, conseguir mudar para playback, por isso que passo channelId 0 quando não tem channel
  const live = useRecoilValue(liveSelector({ mosaicId, channelId: channel?.id || 0 }));

  const [{ isActive }, dropRef] = useDrop<ChannelDropItem, void, { isActive: boolean }>({
    accept: ItemTypes.CHANNEL,
    drop: (item) => {
      putChannelInSlot(order, item.payload);
      selectMosaicSlot(order);
    },
    collect: (monitor) => ({
      isActive: monitor.isOver() && monitor.canDrop(),
    }),
  });

  const doSelect = useRecoilCallback(
    ({ set }) => () => {
      set(mosaicSlotOrderSelectedAtom, (oldVal) => (oldVal === order ? null : order));
    },
    [openChannelSelection]
  );

  const handleClickInAddChannel = useRecoilCallback(
    ({ set }) => () => {
      openChannelSelection();
      set(mosaicSlotOrderSelectedAtom, order);
    },
    [openChannelSelection, doSelect]
  );

  return (
    <Card
      ref={dropRef}
      className={classes.root}
      variant="outlined"
      role="region"
      aria-label={`slot ${order}`}
      sx={{
        '&:hover': {
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <Fade in={isActive} unmountOnExit>
        <Typography className={classes.dropHereBox} variant="h5">
          Drop here
        </Typography>
      </Fade>

      {channelWithSource ? (
        <ErrorBoundaryVideo
          key={String(live)}
          channel={channelWithSource.channel}
          removeChannelClick={() => removeChannel(order)}
          errorRetryInterval={1500}
          {...{ order, mosaicId, setVideoLiveSource }}
        >
          <Suspense fallback={<Loading />}>
            <Suspense
              fallback={
                <LoadingWithImage
                  channelId={channelWithSource.channel.id}
                  channelPrivate={channelWithSource.channel.channelPrivate}
                />
              }
            >
              <MosaicVideo
                videoLiveSource={videoLiveSource || channelWithSource?.videoLiveSource}
                channel={channelWithSource.channel}
                {...{
                  order,
                  selected,
                  mosaicId,
                  mosaicName,
                  doSelect,
                  limitTransmissionBlocked,
                  setVideoLiveSource,
                }}
              />
            </Suspense>
          </Suspense>
        </ErrorBoundaryVideo>
      ) : (
        <CardActionArea
          className={classes.cardActionArea}
          aria-label={`add channel in slot ${order}`}
          onClick={() => handleClickInAddChannel()}
        >
          <AddIcon fontSize="large" />
        </CardActionArea>
      )}
    </Card>
  );
}

function Loading() {
  const classes = useStyles({ selected: false });

  return (
    <Card variant="outlined" className={classes.root}>
      <Box display="flex" flex="1 1 auto" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </Card>
  );
}

function LoadingWithImage({
  channelId,
  channelPrivate,
}: {
  channelId: number;
  channelPrivate: boolean;
}) {
  const classes = useStyles({ selected: false });
  const img64 = useGetChannelThumbnail({ channelId, channelPrivate });

  return (
    <Card variant="outlined" className={classes.root}>
      {img64 ? (
        <CardMedia
          className={classes.thumbnailImg}
          component="img"
          image={`data:image/png;base64, ${img64}`}
        />
      ) : null}
      <Box display="flex" flex="1 1 auto" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </Card>
  );
}

function Hidden() {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  return (
    <Card
      className={classes.root}
      variant="outlined"
      role="region"
      sx={{
        '&:hover': {
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <Box
        display="flex"
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box mb={1}>
          <VisibilityOffIcon fontSize="large" />
        </Box>
        <Box mb={1}>
          <Typography variant="h5">{t('mosaic:slotHidden')}</Typography>
        </Box>
        <Box mb={1}>
          <Typography>{t('mosaic:slotHiddenDescription')}</Typography>
        </Box>
        <Typography color="GrayText" variant="body1">
          {t('mosaic:slotHiddenDescription2')}
        </Typography>
      </Box>
    </Card>
  );
}

function Inactive({
  channel,
  returnRoute,
  unHover = false,
  mosaicId,
  order,
}: {
  channel: ChannelSimple;
  unHover?: boolean;
  order: number;
  mosaicId: number;
  returnRoute: string;
}) {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const [redirectFlag] = useDecision(FEATURE_FLAGS_KEYS.REDIRECT_TO_ENABLE_CHANNEL_IN_MOSAIC);
  const [openFullscreen, setOpenFullscreen] = React.useState(false);

  return (
    <Box ref={measureRef} sx={{ width: '100%', height: '100%', display: 'flex', flex: '1 1 auto' }}>
      <Card
        className={classes.root}
        variant="outlined"
        role="region"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: '1 1 auto',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            p: BIG.lower(measures) ? (MEDIUM.lower(measures) ? 0.5 : 1) : 2,
            '&:hover': {
              border: (theme) => (!unHover ? `2px solid ${theme.palette.primary.main}` : ''),
            },
          }}
        >
          <Box display="flex" width="100%" alignItems="center" flex="0 0 auto">
            <Link
              aria-label="OpenInNew"
              to={routes.customer.channel.view(channel.clientId, channel.id)}
            >
              <OpenInNew
                sx={{ mr: 1, color: 'text.primary' }}
                fontSize={MEDIUM.lower(measures) ? 'small' : 'medium'}
              />
            </Link>
            <Tooltip title={`${channel.clientTradeName} (${channel.clientCompanyName})`}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? 'caption'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                sx={EXTRA_SMALL.lower(measures) ? { fontSize: '10px' } : {}}
              >
                {channel.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            flex="1 1 auto"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Stack
              alignItems="center"
              direction={BIG.lower(measures) ? 'row' : 'column'}
              spacing={BIG.lower(measures) ? 1 : 0}
              display="flex"
              flexDirection="column"
            >
              <VisibilityOffIcon
                fontSize={EXTRA_BIG.lower(measures) ? 'medium' : 'large'}
                sx={{
                  dispĺay: 'flex',
                }}
              />
              <Typography
                align="center"
                variant={
                  EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
                }
              >
                {t('mosaic:slot_inative')}
              </Typography>
            </Stack>
            <Box mb={1}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? SMALL.lower(measures)
                        ? 'caption'
                        : 'body2'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                display={BIG.lower(measures) ? 'none' : 'block'}
                align="center"
              >
                {t('mosaic:slot_inative_desc_1')}
              </Typography>
            </Box>
            <Typography
              align="center"
              variant={
                EXTRA_BIG.lower(measures)
                  ? BIG.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle1'
              }
              color={MEDIUM.lower(measures) ? 'white' : 'textSecondary'}
              display={EXTRA_SMALL2.lower(measures) ? 'none' : 'block'}
              sx={{ mt: EXTRA_BIG.lower(measures) ? (SMALL.lower(measures) ? 0 : 0.5) : 1 }}
            >
              {t('mosaic:slot_inative_desc_2')}
            </Typography>
          </Box>
          <Box display="flex" flex="0 0 auto" pb={2} width="100%" position="relative">
            <Box flex="1 1 auto" textAlign="center">
              {redirectFlag.enabled && (
                <Permissioned role={PermissionRole.EDIT_CLIENT}>
                  <EnableChannelIcon returnRoute={returnRoute} channel={channel} />
                </Permissioned>
              )}

              <RemoveSlotInMosaicButton
                {...{
                  mosaicId,
                  order,
                  channelName: channel.name,
                  size: MEDIUM.lower(measures) ? 'small' : 'medium',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
      <Block open={openFullscreen}>
        <Dialog open={openFullscreen} onClose={() => setOpenFullscreen(false)}>
          <DialogContent>
            <DialogContentText>Teste Inactive</DialogContentText>
          </DialogContent>
        </Dialog>
      </Block>
    </Box>
  );
}

function CustomerInactive({
  channel,
  returnRoute,
  unHover = false,
  mosaicId,
  order,
  removeChannelOption,
}: {
  channel: ChannelSimple;
  unHover?: boolean;
  order: number;
  mosaicId: number;
  returnRoute: string;
  removeChannelOption?: boolean;
}) {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const [redirectFlag] = useDecision(FEATURE_FLAGS_KEYS.REDIRECT_TO_ENABLE_CHANNEL_IN_MOSAIC);
  const history = useHistory();

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flex: '1 1 auto' }}>
      <Card
        className={classes.root}
        variant="outlined"
        role="region"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: '1 1 auto',
        }}
        ref={measureRef}
      >
        <Box
          sx={{
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            p: BIG.lower(measures) ? (MEDIUM.lower(measures) ? 0.5 : 1) : 2,
            '&:hover': {
              border: (theme) => (!unHover ? `2px solid ${theme.palette.primary.main}` : ''),
            },
          }}
        >
          <Box display="flex" width="100%" alignItems="center" flex="0 0 auto">
            <Link
              aria-label="OpenInNew"
              to={routes.customer.channel.view(channel.clientId, channel.id)}
            >
              <OpenInNew
                sx={{ mr: 1, color: 'text.primary' }}
                fontSize={MEDIUM.lower(measures) ? 'small' : 'medium'}
              />
            </Link>
            <Tooltip title={`${channel.clientTradeName} (${channel.clientCompanyName})`}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? 'caption'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                sx={EXTRA_SMALL.lower(measures) ? { fontSize: '10px' } : {}}
              >
                {channel.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            flex="1 1 auto"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Stack
              alignItems="center"
              direction={BIG.lower(measures) ? 'row' : 'column'}
              spacing={BIG.lower(measures) ? 1 : 0}
              display="flex"
              flexDirection="column"
            >
              <PersonOffIcon
                fontSize={!EXTRA_SMALL.lower(measures) ? 'large' : 'medium'}
                sx={{
                  display: 'flex',
                }}
              />
              <Typography
                align="center"
                variant={
                  EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
                }
              >
                {t('customers:inactive')}
              </Typography>
            </Stack>
            <Box mb={1}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? SMALL.lower(measures)
                        ? 'caption'
                        : 'body2'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                display={BIG.lower(measures) ? 'none' : 'block'}
                align="center"
              >
                {t('customers:inactive_description')}
              </Typography>
            </Box>
            <Typography
              align="center"
              variant={
                EXTRA_BIG.lower(measures)
                  ? BIG.lower(measures)
                    ? 'caption'
                    : 'body2'
                  : 'subtitle1'
              }
              color={MEDIUM.lower(measures) ? 'white' : 'textSecondary'}
              display={MEDIUM.lower(measures) ? 'none' : 'block'}
              sx={{ mt: EXTRA_BIG.lower(measures) ? (SMALL.lower(measures) ? 0 : 0.5) : 1 }}
            >
              {t('mosaic:inactive_description')}
            </Typography>
          </Box>
          <Box display="flex" flex="0 0 auto" pb={2} width="100%" position="relative">
            <Box flex="1 1 auto" textAlign="center">
              {redirectFlag.enabled && (
                <Permissioned role={PermissionRole.EDIT_CLIENT}>
                  <EnableChannelIcon
                    returnRoute={returnRoute}
                    channel={channel}
                    onClick={() =>
                      history.push(
                        routes.customer.edit(channel.clientId, routes.mosaic.view(mosaicId))
                      )
                    }
                  />
                </Permissioned>
              )}

              {!removeChannelOption && (
                <RemoveSlotInMosaicButton
                  channelName={channel.name}
                  order={order}
                  mosaicId={mosaicId}
                  size="small"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

function FullscreenInactive({
  channel,
  returnRoute,
  unHover = false,
  mosaicId,
  order,
}: {
  channel: ChannelSimple;
  unHover?: boolean;
  order: number;
  mosaicId: number;
  returnRoute: string;
}) {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  const [measureRef, measures] = useMeasure<HTMLDivElement>();
  const [redirectFlag] = useDecision(FEATURE_FLAGS_KEYS.REDIRECT_TO_ENABLE_CHANNEL_IN_MOSAIC);
  return (
    <Box ref={measureRef} sx={{ width: '100%', height: '100%', display: 'flex', flex: '1 1 auto' }}>
      <Card
        className={classes.root}
        variant="outlined"
        role="region"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: '1 1 auto',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            p: BIG.lower(measures) ? (MEDIUM.lower(measures) ? 0.5 : 1) : 2,
          }}
        >
          <Box display="flex" width="100%" alignItems="center" flex="0 0 auto">
            <Link
              aria-label="OpenInNew"
              to={routes.customer.channel.view(channel.clientId, channel.id)}
            >
              <OpenInNew
                sx={{ mr: 1, color: 'text.primary' }}
                fontSize={MEDIUM.lower(measures) ? 'small' : 'medium'}
              />
            </Link>
            <Tooltip title={`${channel.clientTradeName} (${channel.clientCompanyName})`}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? 'caption'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                sx={EXTRA_SMALL.lower(measures) ? { fontSize: '10px' } : {}}
              >
                {channel.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            flex="1 1 auto"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Stack
              alignItems="center"
              direction={BIG.lower(measures) ? 'row' : 'column'}
              spacing={BIG.lower(measures) ? 1 : 0}
              display="flex"
              flexDirection="column"
            >
              <VisibilityOffIcon
                fontSize={
                  EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'small' : 'medium') : 'large'
                }
                sx={{
                  display: BIG.lower(measures) ? 'none' : 'flex',
                }}
              />
              <Typography
                align="center"
                variant={
                  EXTRA_BIG.lower(measures) ? (BIG.lower(measures) ? 'subtitle2' : 'h6') : 'h5'
                }
              >
                {t('mosaic:slot_inative')}
              </Typography>
            </Stack>
            <Box mb={1}>
              <Typography
                variant={
                  BIG.lower(measures)
                    ? MEDIUM.lower(measures)
                      ? SMALL.lower(measures)
                        ? 'caption'
                        : 'body2'
                      : 'subtitle2'
                    : 'subtitle1'
                }
                display={BIG.lower(measures) ? 'none' : 'block'}
                align="center"
              >
                {t('mosaic:slot_inative_desc_1')}
              </Typography>
              <Typography
                align="center"
                variant={
                  EXTRA_BIG.lower(measures)
                    ? BIG.lower(measures)
                      ? 'caption'
                      : 'body2'
                    : 'subtitle1'
                }
                color={MEDIUM.lower(measures) ? 'white' : 'textSecondary'}
                display={EXTRA_SMALL2.lower(measures) ? 'none' : 'block'}
                sx={{ mt: EXTRA_BIG.lower(measures) ? (SMALL.lower(measures) ? 0 : 0.5) : 2 }}
              >
                {t('video:active_channel_to_watch_stream')}
              </Typography>
            </Box>
            <Box display="flex" flex="0 0 auto" pb={2} width="100%" position="relative" mt={4}>
              <Box flex="1 1 auto" textAlign="center">
                {redirectFlag.enabled && (
                  <Permissioned role={PermissionRole.EDIT_CLIENT}>
                    <EnableChannelIcon returnRoute={returnRoute} channel={channel} />
                  </Permissioned>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

const VideoSlot = {
  View,
  CustomerInactive,
  FullscreenInactive,
  Loading,
  LoadingWithImage,
  Hidden,
  Inactive,
};

export default VideoSlot;
