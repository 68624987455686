import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PlaylistForm from '../../components/PlaylistFormModal/PlaylistForm';

function CreatePlaylistModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation(['playlists', '_common']);
  const onClose = () => setOpen(false);

  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby="create-playlist-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-playlist-modal-title">{t('create_a_playlist')}</DialogTitle>
      <DialogContent>
        <PlaylistForm {...{ setOpen }} />
      </DialogContent>
    </Dialog>
  );
}

export default CreatePlaylistModal;
