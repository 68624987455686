import { Button, Input } from '@mui/material';
import { CheckedItemsIds, checkedItemsIdsAtom, listChannelsAtom } from 'atoms/calculator';
import { useMessages } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { CalculatorEnteredData } from 'types/calculator.types';

export function CalculatorImportButton() {
  const { t } = useTranslation();
  const setList = useSetRecoilState(listChannelsAtom);
  const setCheckedItens = useSetRecoilState(checkedItemsIdsAtom);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const { enqueueError } = useMessages();

  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    (async function importFile() {
      const content = e.target.files && (await e.target.files[0].text());
      const file = JSON.parse(content || '{}');
      if (!Object.prototype.hasOwnProperty.call(file, 'data')) {
        enqueueError(t('calculator:invalid_file'));
        return;
      }
      setList(file.data as CalculatorEnteredData[]);
      setCheckedItens(
        (file.data as CalculatorEnteredData[]).reduce<CheckedItemsIds>((acc, item) => {
          const aux = { ...acc };
          aux[item.id] = true;
          return aux;
        }, {})
      );
    })();
  };

  return (
    <>
      <Button onClick={handleClick} color="primary">
        {t('calculator:import_simulation')}
      </Button>
      <Input
        inputRef={hiddenFileInput}
        onChange={handleChange}
        type="file"
        inputProps={{
          accept: 'application/json',
        }}
        sx={{ display: 'none' }}
      />
    </>
  );
}
