import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useFetch, useQuery } from 'hooks';
import { CompanySimplifyListItem, Company } from 'types/companies.types';
import { companiesSearchTextAtom } from 'atoms/companies';
import { normalizeCompany } from 'hooks/companies/useCompaniesApi';
import CompanyDetails from './CompanyDetails';

function CompanySquare({ data }: { data?: CompanySimplifyListItem[] }) {
  const searchText = useRecoilValue(companiesSearchTextAtom);
  const { t } = useTranslation();
  const query = useQuery();
  const selectedCompanyId = query.get('selectedCompany');

  if (selectedCompanyId) {
    return (
      <Suspense
        fallback={
          <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
            <CircularProgress />
          </Box>
        }
      >
        <CompanySelected selectedCompanyID={selectedCompanyId} />
      </Suspense>
    );
  }

  if (!data?.length && searchText) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
        <Typography color="textSecondary">
          {t(
            'company:to_display_company_information_search_by_tradename_corporatename_customer_id'
          )}
        </Typography>
      </Box>
    );
  }

  if (data?.length) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex="1 1 auto">
        <Typography color="textSecondary">
          {t('company:select_company_to_view_information')}
        </Typography>
      </Box>
    );
  }

  return null;
}

function CompanySelected({ selectedCompanyID }: { selectedCompanyID: string }) {
  const { data: companySelected } = useFetch<Company>(`/v1/companies/${selectedCompanyID}`, {
    normalizeData: normalizeCompany,
  });

  if (companySelected) return <CompanyDetails company={companySelected} />;
  return null;
}

export default CompanySquare;
