import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SelectResolution({
  selectedResolution,
  onChangeSelectResolution,
  error,
  helperText,
}: {
  selectedResolution: string | null;
  onChangeSelectResolution: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation('_common');

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-customer-resolution-label">{t('_common:resolution')}</InputLabel>
      <Select
        name="resolution"
        id="state"
        label={t('_common:resolution')}
        labelId="select-customer-resolution-label"
        fullWidth
        // @ts-ignore
        onChange={onChangeSelectResolution}
        value={selectedResolution}
      >
        <MenuItem aria-label="item" value="640x480">
          640 x 480 (4:3) 480p
        </MenuItem>
        <MenuItem aria-label="item" value="1280x720">
          1280 x 720 (16:9) 720p
        </MenuItem>
        <MenuItem aria-label="item" value="1920x1080">
          1920 x 1080 (16:9) 1080p
        </MenuItem>
        <MenuItem aria-label="item" value="2048x1152">
          2048 x 1152 (1:1.77)
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectResolution;
