import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Mosaic } from 'types/mosaics.types';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import MosaicForm from '../MosaicForm/MosaicForm';

interface MosaicModal {
  initialValues: Partial<Mosaic>;
  onSubmit?: (data: Mosaic) => Promise<void>;
  title: string;
  description?: string;
}

function CreateMosaicModal({ title, description, initialValues, onSubmit }: MosaicModal) {
  const useStyles = makeStyles({
    dialogContent: {
      padding: '0px 24px 16px',
    },
  });
  const { createModalOpened, closeCreateModal } = useCreateMosaicModal();
  const { t } = useTranslation(['mosaics', 'crud_actions', '_common']);
  const classes = useStyles();

  return (
    <Dialog
      open={createModalOpened}
      onClose={() => closeCreateModal()}
      aria-labelledby="create-mosaic-modal-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="create-mosaic-modal-title">{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {description && (
          <Typography variant="body2" mb={1}>
            {description}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" mb={4}>
            {t('_common:next_one')}
          </Typography>
        )}
        <MosaicForm initialValues={initialValues} handleSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
}

export default CreateMosaicModal;
