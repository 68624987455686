import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import { EmptyChannel } from 'components/Channel';
import { Box, Divider, Paper, Typography, useTheme } from '@mui/material';
import { RadioButtonUnchecked } from '@mui/icons-material';
import { Channel, RecordingType } from 'types/channels.types';
import colorByRecordingType from 'helpers/colorByRecordingType';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { useScreenDetector } from 'hooks/useScreenDetector';
import { Container } from './ChannelsResume.styles';

interface TypeTotal {
  record247: number;
  event: number;
  live: number;
  schedule: number;
  preAlarm: number;
  total: number;
}

function ChannelsResume({ customerId, channels }: { customerId: number; channels: Channel[] }) {
  const { data: channelsData } = useFetch<TypeTotal>(
    `/v1/clients/${customerId}/channels/typeTotal`
  );

  if (!channelsData?.total)
    return (
      <>
        <Container>
          <EmptyChannel route={routes.customer.channel.create.root(customerId)} />
        </Container>
      </>
    );

  return (
    <Box flex="1 1 70%" margin="2rem 0 1rem 1rem">
      <Box component={Paper}>
        <RecordingTypeOptions {...{ channelsData }} />
        <ChannelStatusOptions {...{ customerId, channels }} />
      </Box>
    </Box>
  );
}

const RecordingTypeOptions = ({ channelsData }: { channelsData: TypeTotal }) => {
  const theme = useTheme();
  const { xl, xl2 } = useScreenDetector();

  const helperRecordingType = useMemo<{
    [type: string]: RecordingType;
  }>(
    () => ({
      record247: RecordingType.RECORD_24_7,
      event: RecordingType.EVENT,
      live: RecordingType.LIVE,
      schedule: RecordingType.SCHEDULE,
      // preAlarm: RecordingType.PRE_ALARM,
    }),
    []
  );

  const { t } = useTranslation(['channels', 'customers']);

  return (
    <Box sx={{ padding: '24px 24px 0px' }}>
      <Typography variant="body1">{t('channels:recording_type')}</Typography>
      <Box
        sx={{
          display: 'grid',
          // eslint-disable-next-line no-nested-ternary
          gridTemplateColumns: xl2 ? 'repeat(3, 1fr)' : xl ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
          gap: '24px',
          padding: '16px',
          overflowX: 'auto',
        }}
      >
        {Object.keys(channelsData)
          .filter((key) => (channelsData as never)[key] > 0 && key !== 'total')
          .map((key) => (
            <Box key={key} display="flex" width="200px">
              <RadioButtonUnchecked
                htmlColor={colorByRecordingType(helperRecordingType[key], theme)}
              />
              <Box marginLeft="12px" role="region" aria-labelledby={`channel-type-${key}`}>
                <Typography variant="body1">
                  {(channelsData as never)[key]}{' '}
                  {t('_common:channel', {
                    count: (channelsData as never)[key],
                  })}
                </Typography>
                <Typography variant="body2" color="textSecondary" id={`channel-type-${key}`}>
                  {key === 'record247'
                    ? t('recording_type_RECORD_24_7')
                    : t(`recording_type_${key.toUpperCase()}`)}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
const ChannelStatusOptions = ({ channels }: { channels: Channel[] }) => {
  const { t } = useTranslation();
  const { xl, xl2 } = useScreenDetector();

  const channelsEnable = React.useMemo(() => channels.filter((c) => c.status), [channels]);
  const channelsDisable = React.useMemo(() => channels.filter((c) => !c.status), [channels]);

  if (!channels.length) {
    return null;
  }
  return (
    <>
      <Box sx={{ margin: '0 auto', width: 'calc(100% - 48px)' }}>
        <Divider />
      </Box>
      <Box sx={{ padding: '24px 24px 12px' }}>
        <Typography variant="body1">{t('_common:channels_status')}</Typography>
        <Box
          sx={{
            display: 'grid',
            // eslint-disable-next-line no-nested-ternary
            gridTemplateColumns: xl2 ? 'repeat(3, 1fr)' : xl ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
            gap: '24px',
            padding: '16px',
            overflowX: 'auto',
          }}
        >
          {!!channelsEnable?.length && (
            <Box sx={{ display: 'flex', gap: '7px', width: '200px' }}>
              <VideocamIcon color="success" />
              <Box sx={{}}>
                <Typography>{`${channelsEnable.length} ${t('_common:channel', {
                  count: channelsEnable.length,
                })}`}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('_common:actived', {
                    count: channelsEnable.length,
                  })}
                </Typography>
              </Box>
            </Box>
          )}
          {!!channelsDisable?.length && (
            <Box sx={{ display: 'flex', gap: '7px', width: '200px' }}>
              <VideocamOffIcon color="disabled" />
              <Box sx={{}}>
                <Typography>
                  {`${channelsDisable.length} ${t('_common:channel', {
                    count: channelsDisable.length,
                  })}`}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('_common:disabled', {
                    count: channelsDisable.length,
                  })}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ChannelsResume;
