import React, { Suspense, useMemo } from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import routes from 'helpers/routes';
import { normalizeChannel } from 'hooks/channels/useChannelsApi';

function PlaybackTopBarInternal() {
  const { t } = useTranslation(['playback', 'customers']);
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const { data: channelUnformated } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    normalizeData: normalizeChannel,
  });

  const channel = useMemo(
    () =>
      channelUnformated && {
        ...channelUnformated,
        clientCompanyName: channelUnformated.client.companyName,
        clientTradeName: channelUnformated.client.tradeName,
        clientId: channelUnformated.client.id,
      },
    [channelUnformated]
  );

  if (!channel) {
    return null;
  }

  return (
    <Box padding="5.5px 0">
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link
          to={routes.customer.root(Number(customerId))}
          variant="h5"
          color="textSecondary"
          component={RouterLink}
        >
          {t('customers:customers')}
        </Link>
        <Link
          component={RouterLink}
          color="textSecondary"
          variant="h5"
          to={routes.customer.view(channel.clientId)}
        >
          {channel.clientTradeName}
        </Link>
        <Link
          color="textSecondary"
          variant="h5"
          to={routes.customer.channel.view(channel.clientId, channel.id)}
          component={RouterLink}
        >
          {channel.name}
        </Link>
        <Typography variant="h5">{t('playback')}</Typography>
      </Breadcrumbs>
    </Box>
  );
}

export default function PlaybackTopBar() {
  return (
    <Suspense fallback={() => null}>
      <PlaybackTopBarInternal />
    </Suspense>
  );
}
