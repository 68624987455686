import React from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useFetch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { CompanySimplifyListItem } from 'types/companies.types';
import SelectFetchLoading from 'components/SelectFetchLoading';

interface SelectCompaniesProps {
  selected: Number[];
  onChange: (event: SelectChangeEvent<Number[]>) => void;
  error: boolean;
}

export function SelectMultiCompanies({ selected, onChange, error }: SelectCompaniesProps) {
  const { data: companies } = useFetch<CompanySimplifyListItem[]>('/v1/companies');
  const { t } = useTranslation();

  return (
    <>
      <FormControl variant="outlined" fullWidth {...{ error }}>
        <InputLabel id="multi-select-companies-label">{t('company:companies')}</InputLabel>
        <Select
          labelId="multi-select-companies-label"
          id="companies"
          name="companies"
          onChange={onChange}
          multiple
          error={selected.length === 0}
          value={selected}
          label={t('company:companies')}
          renderValue={(values) =>
            // @ts-ignore
            values
              .map((id) => companies?.find((company) => company.id === id)?.companyName)
              .join(', ')
          }
        >
          {companies?.map(({ id, companyName }) => (
            <MenuItem key={id} value={id}>
              {id ? (
                <Checkbox checked={selected.includes(id)} value={selected.includes(id)} />
              ) : null}
              <ListItemText primary={companyName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText
        sx={(pallet) => ({
          color: pallet.palette.error.main,
          ml: 2,
        })}
      >
        {companies?.length === 0 && t('company:haveNoCompanies')}
        {!(companies?.length === 0) && selected.length === 0 && t('company:selectOneCompany')}
      </FormHelperText>
    </>
  );
}

SelectMultiCompanies.Loading = function SelectMultiCompaniesLoading() {
  const { t } = useTranslation();
  return <SelectFetchLoading label={t('company:companies')} />;
};
