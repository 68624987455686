import React from 'react';
import { Box } from '@mui/material';
import { TotalStorage } from 'pages/CalculatorPage/DetailsStoragePlan/TotalStorage/TotalStorage';
import { useRecoilValue } from 'recoil';
import { totalValueCalculatedSelector, selectedPlanAtom } from 'atoms/calculator';
import { HelperTotalStorage } from './TotalStorage/HelperTotalStorage/HelperTotalStorage';
import { BoxStoragePlan } from './SelectStoragePlan/BoxStoragePlan';

export function DetailsStoragePlan() {
  const transmission = useRecoilValue(totalValueCalculatedSelector);
  const newStorage = useRecoilValue(selectedPlanAtom);

  return (
    <Box
      sx={{
        maxWidth: '20rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <BoxStoragePlan />
      <TotalStorage transmissionValue={transmission} storageValue={newStorage.value} />
      <HelperTotalStorage />
    </Box>
  );
}
