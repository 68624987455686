import React, { Dispatch, SetStateAction } from 'react';
import { CircularProgress } from '@mui/material';
import { ConfirmModal } from 'components/core';
import { useChannelsApi } from 'hooks/channels/useChannelsApi';
import { useTranslation } from 'react-i18next';
import { TAB_CUSTOMER } from 'components/CustomerDetails/CustomerDetails';

interface Props {
  _channelsChecked: { id: number; checked: boolean; status: boolean }[];
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setCheckedAll: Dispatch<SetStateAction<boolean>>;
  customerId: number;
  setChannelsChecked: React.Dispatch<
    React.SetStateAction<{ id: number; checked: boolean; status: boolean }[]>
  >;
}

const ActivateInBatch = ({
  _channelsChecked,
  openModal,
  setOpenModal,
  customerId,
  setChannelsChecked,
  setCheckedAll,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const { enableInBatch } = useChannelsApi(customerId);
  const { t } = useTranslation();
  const channelsFiltered: number[] = _channelsChecked
    .filter((_ch) => !_ch.status)
    .map((_channel) => _channel.id);

  const handleEnableChannel = async () => {
    try {
      setLoading(true);
      await enableInBatch(channelsFiltered);
      setLoading(false);
      setOpenModal(false);
      setCheckedAll(false);

      setChannelsChecked([]);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setOpenModal(false);
    }
  };

  return (
    <ConfirmModal
      open={openModal}
      setOpen={setOpenModal}
      doConfirm={handleEnableChannel}
      disabled={loading || channelsFiltered.length === 0}
      loading={loading}
      confirmActionColor="primary"
      variant="contained"
      confirmActionText={
        loading ? <CircularProgress size="1.6rem" color="inherit" /> : t('_common:enable_all')
      }
      title={t('_common:enable_all_selected_channels')}
      description={
        channelsFiltered.length
          ? t('_common:double_check_enable_all_selected_channels', {
              count: channelsFiltered.length,
            })
          : t('_common:this_channel_is_already_activated')
      }
    />
  );
};

const DeactivateInBatch = ({
  _channelsChecked,
  openModal,
  setOpenModal,
  customerId,
  setChannelsChecked,
  setCheckedAll,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const { disableInBatch } = useChannelsApi(customerId);
  const { t } = useTranslation();

  const channelsChecked = _channelsChecked;
  const channelsFiltered: number[] = channelsChecked
    .filter((_ch) => _ch.status)
    .map((_channel) => _channel.id);

  const handleDisableChannel = async () => {
    try {
      setLoading(true);
      await disableInBatch(channelsFiltered);
      setLoading(false);
      setOpenModal(false);
      setCheckedAll(false);
      setChannelsChecked([]);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setOpenModal(false);
    }
  };

  return (
    <ConfirmModal
      open={openModal}
      setOpen={setOpenModal}
      doConfirm={handleDisableChannel}
      disabled={loading || channelsFiltered.length === 0}
      loading={loading}
      confirmActionColor="primary"
      variant="contained"
      confirmActionText={
        loading ? <CircularProgress size="1.6rem" color="inherit" /> : t('_common:disable_all')
      }
      title={t('_common:disable_all_selected_channels')}
      description={
        channelsFiltered.length
          ? t('_common:double_check_disable_all_selected_channels', {
              count: channelsFiltered.length,
            })
          : t('_common:this_channel_is_already_disabled')
      }
    />
  );
};

const DeleteInBatch = ({
  _channelsChecked,
  openModal,
  setOpenModal,
  customerId,
  setChannelsChecked,
  setTab,
  setCheckedAll,
}: Props & {
  setTab: Dispatch<SetStateAction<string>>;
}) => {
  const [loading, setLoading] = React.useState(false);
  const { deleteInBatch } = useChannelsApi(customerId);
  const { t } = useTranslation();
  const channelsMappedById: number[] = _channelsChecked.map((_channel) => _channel.id);

  const handleDeleteChannel = async () => {
    try {
      setLoading(true);
      await deleteInBatch(channelsMappedById);
      setLoading(false);
      setOpenModal(false);
      setCheckedAll(false);
      setChannelsChecked([]);
      setTab(TAB_CUSTOMER);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setOpenModal(false);
    }
  };

  return channelsMappedById.length > 1 ? (
    <ConfirmModal
      open={openModal}
      setOpen={setOpenModal}
      doConfirm={handleDeleteChannel}
      disabled={loading}
      loading={loading}
      confirmActionColor="error"
      variant="contained"
      confirmActionText={
        loading ? <CircularProgress size="1.6rem" color="inherit" /> : t('_common:delete_all')
      }
      title={t('_common:delete_all_selected_channels')}
      description={
        channelsMappedById.length
          ? t('_common:double_check_delete_all_selected_channels', {
              qty: channelsMappedById.length,
            })
          : t('_common:')
      }
    />
  ) : null;
};

const ChannelDialog = {
  ActivateInBatch,
  DeactivateInBatch,
  DeleteInBatch,
};

export default ChannelDialog;
