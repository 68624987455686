import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Live(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 27 22" {...props}>
      <path d="M3.02018 0.520508C1.36102 2.18051 0.333008 4.4725 0.333008 7C0.333008 9.5275 1.36102 11.8195 3.02018 13.4795L4.19857 12.3011C2.84023 10.9428 1.99967 9.0675 1.99967 7C1.99967 4.9325 2.84023 3.05723 4.19857 1.69889L3.02018 0.520508ZM15.9792 0.520508L14.8008 1.69889C16.1591 3.05723 16.9997 4.9325 16.9997 7C16.9997 9.0675 16.1591 10.9428 14.8008 12.3011L15.9792 13.4795C17.6383 11.8195 18.6663 9.5275 18.6663 7C18.6663 4.4725 17.6383 2.18051 15.9792 0.520508ZM5.37695 2.87728C4.32029 3.93311 3.66634 5.39167 3.66634 7C3.66634 8.60833 4.32029 10.0669 5.37695 11.1227L6.55534 9.94434C5.80034 9.19017 5.33301 8.14833 5.33301 7C5.33301 5.85167 5.80117 4.81066 6.55534 4.05566L5.37695 2.87728ZM13.6224 2.87728L12.444 4.05566C13.199 4.80983 13.6663 5.85167 13.6663 7C13.6663 8.14833 13.1982 9.18934 12.444 9.94434L13.6224 11.1227C14.6782 10.0661 15.333 8.60833 15.333 7C15.333 5.39167 14.6791 3.93311 13.6224 2.87728ZM9.49967 4.5C8.83663 4.5 8.20075 4.76339 7.73191 5.23223C7.26307 5.70107 6.99967 6.33696 6.99967 7C6.99967 7.66304 7.26307 8.29893 7.73191 8.76777C8.20075 9.23661 8.83663 9.5 9.49967 9.5C10.1627 9.5 10.7986 9.23661 11.2674 8.76777C11.7363 8.29893 11.9997 7.66304 11.9997 7C11.9997 6.33696 11.7363 5.70107 11.2674 5.23223C10.7986 4.76339 10.1627 4.5 9.49967 4.5Z" />
    </SvgIcon>
  );
}

export default Live;
