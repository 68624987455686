import Axios from 'axios';
import { SESSION_STORAGE, URL_LOGIN } from 'services/auth';

export function useLogout() {
  return async () => {
    await Axios.post('/v1/logout');
    sessionStorage.removeItem(SESSION_STORAGE.REFRESH_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE.TOKEN);
    window.location.assign(URL_LOGIN);
  };
}
