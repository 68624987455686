import { Box, useTheme } from '@mui/material';
import React from 'react';

function BadgeActiveContent({ active }: { active: boolean }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: active ? theme.palette.success.main : theme.palette.grey[700],
        height: '8px',
        minWidth: '8px',
        borderRadius: '4px',
      }}
    />
  );
}

export default BadgeActiveContent;
