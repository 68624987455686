import { TimePicker } from '@mui/x-date-pickers';
import { Box, TextField, Button } from '@mui/material';
import { RecordingType } from 'types/channels.types';
import { Form, useButtonsStyles, useFormStyles } from 'components/core';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useYupObject } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectRecordingType from 'components/SelectRecordingType';
import { WeekDay } from 'types/weekDay.enum';
import SelectMultipleWeekDay from 'components/SelectMultipleWeekDay';
import { v4 as uuidv4 } from 'uuid';
import { ITimeRangeWithId } from 'types/recordingSchedule.types';

export type TimeIntervalForm = {
  weekDay: WeekDay[];
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  recordingType: RecordingType;
  id: string;
};

function RecordingTimeIntervalForm({
  onConfirm,
  initialValues,
  onCancel,
}: {
  onConfirm: (values: ITimeRangeWithId[]) => void;
  onCancel: () => void;
  initialValues: TimeIntervalForm;
}) {
  const yup = useYupObject();
  const { t } = useTranslation(['recording_schedule', 'crud_actions', 'forms']);
  const classesForm = useFormStyles({ dialog: true });
  const classesButtonForm = useButtonsStyles();
  // const [preAlarmFlag] = useDecision(FEATURE_FLAGS_KEYS.PRE_ALARM_RECORDING_TYPE)

  const isEditing = React.useMemo(() => !!initialValues.id, [initialValues.id]);

  const validationSchema = yup.object({
    weekDay: yup
      .array(yup.string().oneOf(Object.keys(WeekDay)).required())
      .ensure()
      .min(1, t('recording_schedule:you_must_select_at_least_one_day_of_week')),
    startTime: yup.date().nullable().required(),
    endTime: yup
      .date()
      .nullable()
      .required()
      .min(yup.ref('startTime'), t('recording_schedule:end_time_cannot_be_less_than_start_time'))
      .test(
        'is-not-equal',
        t('forms:time_cannot_be_equal'),
        (value) => (value as Date)?.getTime() > initialValues.startTime.toDate().getTime()
      ),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onConfirm(
        values.weekDay.map((value) => ({
          weekDay: value,
          startTime: values.startTime.toDate(),
          endTime: values.endTime.toDate(),
          recordingType: values.recordingType,
          id: initialValues.weekDay[0] === value && values.id ? values.id : uuidv4(),
        }))
      );
      setSubmitting(false);
    },
  });

  return (
    <form className={classesForm.form} onSubmit={formik.handleSubmit}>
      <Box sx={{ py: 2 }}>
        <Box display="flex">
          <TimePicker
            label={t('recording_schedule:initial_time')}
            value={formik.values.startTime}
            onChange={async (newDate: dayjs.Dayjs | null) => {
              // @ts-ignore
              // if (newDate && !isNaN(newDate)) {
              formik.setErrors((await formik.setFieldValue('startTime', newDate, true)) || {});
              // }
              formik.setFieldTouched('startTime', true);
            }}
            onError={(error) => {
              if (error && error !== formik.errors.startTime) {
                formik.setFieldError('startTime', String(error));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="startTime"
                id="startTime"
                helperText={formik.touched.startTime && formik.errors.startTime}
                error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                sx={{ width: 135, flex: '0 0 auto' }}
              />
            )}
          />
          <TimePicker
            label={t('recording_schedule:end_time')}
            value={formik.values.endTime}
            onChange={async (newDate: dayjs.Dayjs | null) => {
              // @ts-ignore
              formik.setErrors((await formik.setFieldValue('endTime', newDate, true)) || {});
              formik.setFieldTouched('endTime', true);
            }}
            onError={(error) => {
              if (error && error !== formik.errors.endTime) {
                formik.setFieldError('endTime', String(error));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classesForm.inputMarginLeft}
                name="endTime"
                id="endTime"
                helperText={formik.touched.endTime && formik.errors.endTime}
                error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                sx={{ width: 135, flex: '0 0 auto' }}
              />
            )}
          />
          <SelectRecordingType
            className={classesForm.inputMarginLeft}
            name="recordingType"
            error={formik.touched.recordingType && Boolean(formik.errors.recordingType)}
            helperText={formik.touched.recordingType ? formik.errors.recordingType : ''}
            selectedRecordingType={formik.values.recordingType}
            onChangeRecordingType={formik.handleChange}
            filteredRecordingType={[
              RecordingType.RECORD_24_7,
              RecordingType.EVENT,
              // RecordingType.PRE_ALARM,
            ]}
            fullWidth
          />
        </Box>
        <SelectMultipleWeekDay
          selected={formik.values.weekDay}
          onChange={formik.handleChange}
          name="weekDay"
          error={formik.touched.weekDay && Boolean(formik.errors.weekDay)}
          helperText={formik.touched.weekDay ? formik.errors.weekDay : ''}
          fullWidth
          sx={{ mt: 2 }}
        />
      </Box>
      <Box className={classesForm.formFooter}>
        {isEditing && (
          <Button
            className={classesButtonForm.formDeleteButton}
            aria-label="delete"
            color="error"
            disabled={formik.isSubmitting}
            onClick={() => onConfirm([])}
          >
            {t('crud_actions:delete')}
          </Button>
        )}
        <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={() => onCancel()} />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          values={formik.values}
          disabled={false}
          label={isEditing ? t('crud_actions:edit') : t('crud_actions:create')}
          {...{ initialValues }}
        />
      </Box>
    </form>
  );
}

export default RecordingTimeIntervalForm;
