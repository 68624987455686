import React, { useState, useLayoutEffect } from 'react';

export default function useHaveScroll(
  ref: React.RefObject<HTMLElement>,
  deps: React.DependencyList = []
): { haveScroll: boolean } {
  const [haveScroll, setHaveScroll] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);

  return {
    haveScroll,
  };
}
