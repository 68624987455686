import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { savingPlaylistItemAtom } from 'atoms/playlist';
import SelectMosaics from 'components/SelectMosaics';
import { useFormik } from 'formik';
import { useYupObject } from 'hooks';
import { usePlaylistApi } from 'hooks/playlists/usePlaylistApi';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { PlaylistItem } from 'types/playlist';

const useStyles = makeStyles((theme) => ({
  footer: {
    flex: '0 0 auto',
    textAlign: 'end',
    '> button': {
      padding: '6px 44px',
    },
  },
  formContainer: {
    padding: '0px 24px 16px',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  buttonSave: {
    marginLeft: theme.spacing(2),
  },
}));

function AddMosaicToPlaylistModal({
  open,
  setOpen,
  items,
  playlistId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  items: PlaylistItem[];
  playlistId: number;
}) {
  const { t } = useTranslation(['playlists', 'crud_actions']);
  const yup = useYupObject();
  const classes = useStyles();
  const { createPlaylistItems } = usePlaylistApi();
  const setSavingPlaylistItem = useSetRecoilState(savingPlaylistItemAtom);

  const validationSchema = yup.object({
    mosaics: yup
      .array(
        yup.object({
          id: yup.number().required(),
          label: yup.string().required(),
        })
      )
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      mosaics: items.map((item) => ({
        id: item.mosaic.id,
        label: item.mosaic.name,
      })),
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSavingPlaylistItem(true);
      await createPlaylistItems(
        playlistId,
        values.mosaics
          .map((item, index) => ({ ...item, order: index + 1 }))
          .filter((newItem) => !items.some((itemExistent) => itemExistent.mosaic.id === newItem.id))
          .map((item, index) => ({
            order: item.order,
            mosaic: { id: item.id, name: item.label },
            type: 'MOSAIC',
            rotationTime: 60,
          }))
      );
      setSubmitting(false);
      setSavingPlaylistItem(false);
      onClose();
    },
  });

  const onClose = () => setOpen(false);

  return (
    <Dialog {...{ open, onClose }} aria-labelledby="add-mosaic-to-playlist" maxWidth="md" fullWidth>
      <DialogTitle id="add-mosaic-to-playlist">{t('add_mosaic_to_playlist')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.formContainer}>
            <Box padding="0.6rem 0">
              <Suspense fallback={<SelectMosaics.Loading />}>
                <SelectMosaics
                  selectedMosaics={formik.values.mosaics}
                  onChangeMosaics={formik.handleChange}
                  error={formik.touched.mosaics && Boolean(formik.errors.mosaics)}
                  helperText={
                    formik.touched.mosaics &&
                    formik.errors.mosaics &&
                    (Array.isArray(formik.errors.mosaics)
                      ? formik.errors.mosaics.join(',')
                      : formik.errors.mosaics)
                  }
                  label={t('playlists:playlist_mosaics')}
                  fnItemIsDisabled={(item) => items.some((_item) => _item.mosaic.id === item.id)}
                />
              </Suspense>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button aria-label="cancel" disabled={formik.isSubmitting} onClick={onClose}>
              {t('crud_actions:cancel')}
            </Button>
            <Button
              className={classes.buttonSave}
              type="submit"
              aria-label="save"
              color="primary"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {formik.isSubmitting ? <CircularProgress size="1.6rem" /> : t('crud_actions:save')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddMosaicToPlaylistModal;
