import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Routline(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <mask
        id="mask0_111_3658"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_111_3658)">
        <path
          d="M11.325 12.7C12.175 13.55 13.125 14.2792 14.175 14.8875C15.225 15.4958 16.35 15.9667 17.55 16.3C16.8833 17.15 16.0667 17.8125 15.1 18.2875C14.1333 18.7625 13.1083 19 12.025 19C10.075 19 8.42083 18.3208 7.0625 16.9625C5.70417 15.6042 5.025 13.95 5.025 12C5.025 10.9167 5.2625 9.89167 5.7375 8.925C6.2125 7.95833 6.875 7.14167 7.725 6.475C8.05833 7.675 8.52917 8.8 9.1375 9.85C9.74583 10.9 10.475 11.85 11.325 12.7ZM18.575 14.5C18.2417 14.4167 17.9125 14.325 17.5875 14.225C17.2625 14.125 16.9417 14.0083 16.625 13.875C16.7583 13.575 16.8542 13.2708 16.9125 12.9625C16.9708 12.6542 17 12.3333 17 12C17 10.6167 16.5125 9.4375 15.5375 8.4625C14.5625 7.4875 13.3833 7 12 7C11.6667 7 11.3458 7.02917 11.0375 7.0875C10.7292 7.14583 10.425 7.24167 10.125 7.375C9.99167 7.05833 9.87917 6.74167 9.7875 6.425C9.69583 6.10833 9.60833 5.78333 9.525 5.45C9.925 5.3 10.3333 5.1875 10.75 5.1125C11.1667 5.0375 11.5917 5 12.025 5C13.975 5 15.6292 5.67917 16.9875 7.0375C18.3458 8.39583 19.025 10.05 19.025 12C19.025 12.4333 18.9875 12.8583 18.9125 13.275C18.8375 13.6917 18.725 14.1 18.575 14.5ZM11 3V0H13V3H11ZM11 24V21H13V24H11ZM19.075 6.35L17.65 4.925L19.775 2.825L21.2 4.225L19.075 6.35ZM4.225 21.175L2.8 19.775L4.925 17.65L6.35 19.075L4.225 21.175ZM21 13V11H24V13H21ZM0 13V11H3V13H0ZM19.775 21.2L17.65 19.075L19.075 17.65L21.175 19.775L19.775 21.2ZM4.925 6.35L2.825 4.225L4.225 2.8L6.35 4.925L4.925 6.35Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default Routline;
