/**
 *
 * @param value
 * @returns a boolean indicating if value isn't null or undefined
 *
 * is used to change de inference of typescript when using a filter in an array
 *
 * https://github.com/microsoft/TypeScript/issues/16655#issuecomment-687141674
 */
export function nonNullish<V>(value: V): value is NonNullable<V> {
  return value !== undefined && value !== null;
}
