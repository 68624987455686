import React, { Suspense, useEffect, useMemo } from 'react';
import CreateMosaicModal from 'components/CreateMosaicModal';
import { useTranslation } from 'react-i18next';
import { PermissionRole } from 'types/user.types';
import { DEFAULT_MOSAIC_VALUES } from 'helpers/mosaicTemplates';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import { ReactComponent as MosaicSvg } from 'assets/mosaics.svg';
import { Box, Button, Typography } from '@mui/material';
import Permissioned from 'components/Permissioned';
import { NavigationMenu } from 'components/core/Icons';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { Redirect } from 'react-router-dom';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import { Mosaic } from 'types/mosaics.types';
import EmptyState from 'components/EmptyState';
import { asyncComponent } from 'HOCs';
import { useHavePermission } from 'hooks/useHavePermission';
import { useSelectMosaicPanel } from 'hooks/mosaic/useSelectMosaicPanel';
import { Container } from './MosaicPage.styles';

function MosaicPage() {
  const { t } = useTranslation('mosaics');
  const { openCreateModal } = useCreateMosaicModal();
  const [newMosaicPageFlag] = useDecision(FEATURE_FLAGS_KEYS.SHOW_NEW_MOSAIC_PAGE);
  const { data: mosaics } = useFetch<Mosaic[]>('/v1/mosaics');
  const havePermissionFn = useHavePermission();
  const { openMosaicSelection, closeMosaicSelection } = useSelectMosaicPanel();

  const firstMosaic = useMemo(() => {
    if (!mosaics?.length) {
      return null;
    }
    return mosaics[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosaics]);

  useEffect(() => {
    if (havePermissionFn(PermissionRole.INSERT_MOSAIC) && newMosaicPageFlag.enabled)
      openMosaicSelection();

    return () => {
      closeMosaicSelection();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!firstMosaic) {
    return (
      <Container>
        <CreateMosaicModal
          title={t('mosaics:create_mosaic')}
          initialValues={DEFAULT_MOSAIC_VALUES}
        />
        <EmptyState
          title={t('register_first_mosaic')}
          description={t('set_number_slots_select_channels_displayed_in_mosaic')}
          onAddClick={() => openCreateModal()}
          captionButton={t('register_mosaic')}
          roleAdd={PermissionRole.INSERT_MOSAIC}
        />
      </Container>
    );
  }

  if (!newMosaicPageFlag.enabled) {
    return <Redirect to={routes.mosaic.view(firstMosaic.id)} />;
  }

  return (
    <Suspense fallback={() => null}>
      <Container>
        <CreateMosaicModal
          title={t('mosaics:create_mosaic')}
          initialValues={DEFAULT_MOSAIC_VALUES}
        />
        <Box
          sx={{
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              alignItems: 'center',
              maxWidth: '640px',
            }}
          >
            <MosaicSvg
              style={{
                width: 130,
                height: 130,
              }}
            />
            <Typography variant="h5">{t('mosaics:select_the_mosaic_to_watch_channels')}</Typography>
            <Typography variant="subtitle1" color="textSecondary" my={1} maxWidth={550}>
              {t('mosaics:to_watch_able_channels_first_select_the_mosaic')}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                {t('mosaics:click_in_search')} (
              </Typography>
              <SearchIcon />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                ){t('mosaics:in_top_left_or_press')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {t('mosaics:or_pressionate')} (
              </Typography>
              <HamburguerShortCutIcon />
              <Typography variant="body2" color="textSecondary">
                {' '}
                ){t('mosaics:to_search_and_select_wanted_mosaic')}
              </Typography>
            </Box>
          </Box>
          <Permissioned role={PermissionRole.INSERT_MOSAIC}>
            <Typography
              variant="body2"
              fontWeight="bold"
              color="textSecondary"
              my={1}
              mt={2}
              maxWidth={600}
            >
              {t('mosaics:steps_to_create_mosaic')}
            </Typography>

            <Button
              variant="contained"
              aria-label={t('mosaics:new_mosaic')}
              color="primary"
              onClick={() => openCreateModal()}
              sx={{
                mt: 4,
              }}
            >
              {t('mosaics:new_mosaic')}
            </Button>
          </Permissioned>
        </Box>
      </Container>
    </Suspense>
  );
}

const SearchIcon = () => (
  <Typography
    sx={(theme) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
      width: '20px',
      backgroundColor: theme.palette.mode === 'light' ? '#aaa' : '#555',
      borderRadius: '3px',
      padding: '4px 8px',
      margin: '0 6px',
      boxShadow: '0 3px #222',
      transform: 'perspective(20px) rotateX(-2deg)',
      ':hover': {
        cursor: 'text',
      },
    })}
  >
    <NavigationMenu />
  </Typography>
);

const HamburguerShortCutIcon = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    }}
  >
    <Typography
      sx={(theme) => ({
        display: 'inline-block',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#aaa' : '#555',
        borderRadius: '3px',
        padding: '3px 7px',
        boxShadow: '0 3px #222',
        transform: 'perspective(20px) rotateX(-2deg)',
      })}
    >
      ctrl
    </Typography>
    <Typography>+</Typography>
    <Typography
      sx={(theme) => ({
        display: 'inline-block',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#aaa' : '#555',
        borderRadius: '3px',
        padding: '3px 7px',
        boxShadow: '0 3px #222',
        transform: 'perspective(20px) rotateX(-2deg)',
      })}
    >
      /
    </Typography>
  </Box>
);
export default asyncComponent(MosaicPage);
