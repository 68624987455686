import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatToReal } from 'helpers/formatToReal';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  subContainer: {
    padding: '1rem 2rem',
  },
  label: {
    fontSize: '0.85rem',
    color: theme.palette.grey[500],
    margin: '0.2rem 0',
  },
  value: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}));

export function BodyTotalStorage({
  transmissionValue,
  storageValue,
}: {
  transmissionValue: number;
  storageValue: number;
}) {
  const { t } = useTranslation(['calculator', 'metrics']);

  const classes = useStyles();

  return (
    <>
      <Divider />
      <Box className={classes.subContainer}>
        <Typography className={classes.label}>{t('metrics:streaming')}</Typography>
        <Typography className={classes.value}>{formatToReal(transmissionValue)}</Typography>
        <br />
        <Typography className={classes.label}>{t('metrics:storage')}</Typography>
        <Typography className={classes.value}>{formatToReal(storageValue)}</Typography>
      </Box>
      <Divider />
    </>
  );
}
