import React from 'react';
import { Box, Typography } from '@mui/material';
import { DownloadVMSButton } from 'components/DownloadVMSButton';
import { useTranslation } from 'react-i18next';
import { useClientPlataform } from 'hooks/useClientPlataform';

export default function BadFeedback() {
  const { t } = useTranslation();
  const clientPlataform = useClientPlataform();
  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 2, textAlign: 'center', lineHeight: 1.1 }}>
        {t('_common:ops')}
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ textAlign: 'center', lineHeight: 1.1 }}>
          {t('speed_test:bad_feedback')}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'center', lineHeight: 1.1 }}>
          {t('speed_test:bad_feedback_2')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
          {t('speed_test:bad_feedback_3')}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
          {t('speed_test:bad_feedback_4')}
        </Typography>
      </Box>
      {clientPlataform === 'WEB_BROWSER' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <DownloadVMSButton.ToPage />
        </Box>
      )}
    </Box>
  );
}
