import { Box, Fab } from '@mui/material';
import DebounceInput from 'components/core/DebounceInput';
import { t } from 'i18next';
import React from 'react';
import { useRecoilState } from 'recoil';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import routes from 'helpers/routes';
import { scheduleListSearchTextAtom } from './useRecordingScheduleCRUD';

export default function RecordingScheduleHeaderBar() {
  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(scheduleListSearchTextAtom);
  const [searchText, setSearchText] = React.useState(searchTextDebounced);
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    inputRef.current?.focus();
  });

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '4px 0',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box flex="0 0 28%" mb={3}>
        <DebounceInput
          {...{ inputRef }}
          placeholder={t('crud_actions:search')}
          autoFocus
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          setValue={setSearchText}
          onDebounce={() => setSearchTextDebounced(searchText)}
          fullWidth
        />
      </Box>
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        component={Link}
        to={routes.settings.recordingSchedule.create}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
