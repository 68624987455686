import Axios from 'axios';
import { savingPlaylistItemAtom, savingPlaylistItemErrorAtom } from "atoms/playlist";
import useMessages from "hooks/useMessages";
import { useTranslation } from "react-i18next";
import { useRecoilCallback } from "recoil";
import { cache, mutate } from "swr";
import { PlaylistCreation, Playlist, PlaylistItem, PlaylistItemCreation } from "types/playlist";

export function usePlaylistApi() {
  const { t } = useTranslation(['playlists', 'api_errors_messages']);
  const { enqueueSuccess, enqueueError } = useMessages();

  const createPlaylist = async (
    playlistReceived: PlaylistCreation,
    onSuccess: (id: number) => void
  ) => {
    try {
      const { data } = await Axios.post('/v1/playlists', playlistReceived);
      await createPlaylistItems(
        data.id,
        playlistReceived.mosaics.map((mosaic, index) => ({
          order: index + 1,
          mosaic: { id: mosaic.id, name: mosaic.label },
          type: 'MOSAIC',
          rotationTime: 60,
        }))
      );
      onSuccess(data.id);
      enqueueSuccess(t('playlist_created'));
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_creating')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const updatePlaylist = async (id: number, playlistReceived: Playlist) => {
    try {
      await Axios.put(`/v1/playlists/${id}`, { ...playlistReceived, items: undefined });
      enqueueSuccess(t('playlist_changed'));
      cache.has(`/v1/playlists/${id}`) && mutate(`/v1/playlists/${id}`, playlistReceived);
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_editing')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
      throw error;
    }
  };

  const deletePlaylist = async (id: number) => {
    try {
      await Axios.delete(`/v1/playlists/${id}`);
      enqueueSuccess(t('playlist_deleted'));

      cache.has('/v1/playlists') &&
        mutate('/v1/playlists', (items: Playlist[]) =>
          items.filter((_item: Playlist) => _item.id !== id)
        );
    } catch (error) {
      if (Axios.isAxiosError(error))
        enqueueError(
          `${t('error_deleting')}\n${t(`api_errors_messages:${error?.response?.data?.message}`)}`
        );
    }
  };

  const updatePlaylistItem = useRecoilCallback(
    ({ set }) => async (playlistId: number, id: number, playlistItemReceived: PlaylistItem) => {
      try {
        set(savingPlaylistItemAtom, true);
        set(savingPlaylistItemErrorAtom, false);
        await Axios.put(`/v1/playlists/${playlistId}/items/${id}`, playlistItemReceived);
        set(savingPlaylistItemAtom, false);

        cache.has(`/v1/playlists/${playlistId}`) &&
          mutate(
            `/v1/playlists/${playlistId}`,
            (playlist: Playlist) =>
              playlist && {
                ...playlist,
                items: playlist.items.map((_item) =>
                  _item.id === playlistItemReceived.id ? playlistItemReceived : _item
                ),
              }
          );
      } catch (error) {
        set(savingPlaylistItemErrorAtom, true);
        set(savingPlaylistItemAtom, false);
        if (Axios.isAxiosError(error)) {
          enqueueError(
            `${t(
              'error_changing_playlist_item'
            )}\n${`api_errors_messages:${error?.response?.data?.message}`}`
          );
        }
        throw error;
      }
    },
    [enqueueError, t]
  );

  const createPlaylistItems = useRecoilCallback(
    ({ set }) => async (playlistId: number, playlistItemsReceived: PlaylistItemCreation[]) => {
      try {
        await Axios.post(`/v1/playlists/${playlistId}/items`, playlistItemsReceived);

        set(savingPlaylistItemAtom, false);

        cache.has(`/v1/playlists/${playlistId}`) &&
          mutate(
            `/v1/playlists/${playlistId}`,
            (playlist: Playlist) =>
              playlist && {
                ...playlist,
                items: [...playlist.items, ...playlistItemsReceived],
              }
          );
        cache.has(`/v1/playlists/${playlistId}/items`) &&
          mutate(
            `/v1/playlists/${playlistId}/items`,
            (playlistItems: PlaylistItem[]) =>
              playlistItems &&
              Array.isArray(playlistItems) && [...playlistItems, ...playlistItemsReceived]
          );
      } catch (error) {
        if (Axios.isAxiosError(error)) {
          console.error(error);
          enqueueError(
            `${t(
              'error_inserting_mosaic_to_playlist'
            )}\n${`api_errors_messages:${error?.response?.data?.message}`}`
          );
        }
        throw error;
      }
    },
    [enqueueError, t]
  );

  const deletePlaylistItem = async (playlistId: number, id: number) => {
    try {
      await Axios.delete(`/v1/playlists/${playlistId}/items/${id}`);
      enqueueSuccess(t('mosaic_deleted_from_playlist'));

      cache.has(`/v1/playlists/${playlistId}/items`) &&
        mutate(`/v1/playlists/${playlistId}/items`, (playlistItems: PlaylistItem[]) =>
          playlistItems.filter((item) => item.id !== id)
        );
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueError(
          `${t(
            'error_mosaic_deleting_from_playlist'
          )}\n${`api_errors_messages:${error?.response?.data?.message}`}`
        );
      }
    }
  };

  return {
    createPlaylist,
    deletePlaylist,
    updatePlaylist,
    updatePlaylistItem,
    createPlaylistItems,
    deletePlaylistItem,
  };
}