interface StructureFps {
  [fps: number]: number
}

export interface StructureBitrate {
  [resolution: string]: StructureFps
}

export const recommendedBitrate: StructureBitrate[] = [
  {
    '640x480': // (4:3) 480p
    {
      1: 76,
      2: 112,
      4: 213,
      6: 244,
      8: 305,
      10: 305,
      13: 366,
      15: 427,
      18: 464,
      20: 580,
      22: 580,
      25: 696,
      30: 834,
    }
  },
  {
    '1280x720': // (16:9) 720p
    {
      1: 244,
      2: 366,
      4: 427,
      6: 464,
      8: 580,
      10: 696,
      13: 812,
      15: 832,
      18: 1040,
      20: 1248,
      22: 1456,
      25: 1440,
      30: 1727,
    }
  },
  {
    '1920x1080': // (16.9)1080p
    {
      1: 464,
      2: 580,
      4: 696,
      6: 812,
      8: 832,
      10: 1248,
      13: 1440,
      15: 1575,
      18: 1800,
      20: 1856,
      22: 2165,
      25: 2048,
      30: 2457,
    }
  },
  {
    '2048x1152': // (1: 1.77) Full HD
    {
      1: 696,
      2: 832,
      4: 1248,
      6: 1440,
      8: 1800,
      10: 1856,
      13: 1933,
      15: 2165,
      18: 2048,
      20: 2176,
      22: 2304,
      25: 2560,
      30: 2928,
    }
  },
]

