import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelGroup } from 'types/channelGroup.types';
import { FilterSelectValue } from 'types/select.types';

function SelectChannelGroup({
  selectedChannelGroup,
  onChangeChannelGroup,
  error,
  helperText,
  placeholder,
  name,
  handleBlur,
}: {
  selectedChannelGroup: FilterSelectValue | null;
  onChangeChannelGroup: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name: string;
  handleBlur: (e: React.FocusEvent) => void;
}) {
  const { data: channelGroups } = useFetch<ChannelGroup[]>('/v1/channelGroups');
  const { t } = useTranslation('channel_groups');

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-channel-group-label">{t('channel_group')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-channel-group-label"
        id="select-channel-group"
        label={t('channel_group')}
        onChange={(e) => {
          onChangeChannelGroup({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: {
                id: e.target.value as number,
                label:
                  channelGroups?.find(
                    (channelGroup) => channelGroup.id === (e.target.value as number)
                  )?.name || '',
              },
            },
          });
        }}
        onBlur={handleBlur}
        value={selectedChannelGroup ? selectedChannelGroup.id : ''}
      >
        {channelGroups?.map(({ name: channelName, id }) => (
          <MenuItem key={id} value={id}>
            {channelName}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectChannelGroup.Loading = function SelectChannelGroupLoading() {
  const { t } = useTranslation('channel_groups');
  return <SelectFetchLoading label={t('channel_groups:channel_group')} />;
};

export default SelectChannelGroup;
