export interface SpeedTestDTO {
  download: number;
  upload: number;
  latency: number;
  jitter: number;
  ping: number;
  packageLoss: number;
  classification: {
    classificationIdx: ClassificationIdx;
    classificationName: ClassificationName;
  };
  clientPlataform: ClientPlataform;
}

export type TagLabel =
  | 'default'
  | 'connecting'
  | 'latencyJitterAndPackages'
  | 'download'
  | 'upload';

export type ClassificationIdx = 0 | 1 | 2 | 3 | 4;
export type ClassificationName = 'bad' | 'poor' | 'average' | 'good' | 'great';
export type ClientPlataform = 'WEB_BROWSER' | 'WEB_VIEW' | 'WEB_VIEW_PROXY';

export enum ClientPlataformEnum {
  WEB_BROWSER = 'WEB_BROWSER',
  WEB_VIEW = 'WEB_VIEW',
  WEB_VIEW_PROXY = 'WEB_VIEW_PROXY',
}

export enum Classification {
  bad = 0,
  poor = 1,
  average = 2,
  good = 3,
  great = 4,
}
