import { DragObjectWithType } from 'react-dnd';
import { ChannelSimple } from 'types/mosaics.types';
import { PlaylistItem } from 'types/playlist';

export type ChannelDropItem = DragObjectWithType & {
  payload: ChannelSimple;
  name: string;
};

export type PlaylistItemDrop = DragObjectWithType & {
  payload: PlaylistItem;
};

export const ItemTypes = {
  CHANNEL: 'CHANNEL',
  PLAYLIST_ITEM: 'PLAYLIST_ITEM',
};
