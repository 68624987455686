import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { User } from 'types/user.types';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';

function SelectUser({
  selected,
  onChange,
  filterData,
}: {
  selected: FilterSelectValue | null;
  onChange: (value: FilterSelectValue) => void;
  filterData?: (data: User[]) => User[];
}) {
  const { t } = useTranslation('operation_logs');
  const { data } = useFetch<User[]>('/v1/users');

  const dataFiltered = React.useMemo(() => {
    if (data && filterData) {
      return filterData(data);
    }
    return data;
  }, [data, filterData]);

  React.useEffect(
    function setLabels() {
      if (selected && dataFiltered && !selected.label) {
        onChange({
          id: selected.id,
          label: dataFiltered.find((user) => user.id === selected.id)?.username || '',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, dataFiltered]
  );

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    const idValue = Number(e.target.value);
    onChange({
      id: idValue,
      label: dataFiltered?.find((user) => user.id === idValue)?.username || '',
    });
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-user-label">{t('user')}</InputLabel>
      <Select
        labelId="select-user-label"
        id="select-user"
        onChange={handleChange}
        value={selected ? selected.id : ''}
        label={t('user')}
      >
        {dataFiltered?.map(({ username, id }) => (
          <MenuItem key={id} value={id}>
            {username}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectUser.Loading = function SelectLoading() {
  const { t } = useTranslation('operation_logs');
  return <SelectFetchLoading label={t('user')} />;
};

export default SelectUser;
