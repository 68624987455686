import { atom } from 'recoil';
import { ChannelGroup } from 'types/channelGroup.types';

export const channelGroupSearchTextAtom = atom<string>({
  key: 'channelGroupSearchTextAtom',
  default: '',
});

export const channelGroupInEditionAtom = atom<ChannelGroup | null>({
  key: 'channelGroupInEditionAtom',
  default: null,
});

export const channelGroupcreateModalOpenedAtom = atom<boolean>({
  key: 'channelGroupcreateModalOpenedAtom',
  default: false,
});
