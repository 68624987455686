import {
  useRecoilState,
  SetterOrUpdater,
  useSetRecoilState,
} from 'recoil';
import { useFetch } from 'hooks';
import { useEffect } from 'react';
import { Mosaic } from 'types/mosaics.types';
import { mosaicAtom, mosaicInitialDataAtom } from 'atoms/mosaics';

export function useFetchMosaic(
  mosaicId: number
): [Mosaic | null, SetterOrUpdater<Mosaic | null>, Mosaic | undefined] {
  const { data: mosaicApi } = useFetch<Mosaic>(`/v1/mosaics/${mosaicId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [mosaic, setMosaic] = useRecoilState(mosaicAtom(mosaicId));
  const setMosaicInitialData = useSetRecoilState(mosaicInitialDataAtom(mosaicId));

  useEffect(() => {
    setMosaic(mosaicApi || null);
    setMosaicInitialData(mosaicApi || null);
  }, [mosaicApi, setMosaic, setMosaicInitialData]);

  return [mosaic, setMosaic, mosaicApi];
}
