import { loggedUserAtom } from 'atoms/users';
import axios from 'axios';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

const baseURL = process.env.REACT_APP_BASE_URL_LOGGER || 'http://localhost:8091/';
export type Level = 'info' | 'warn' | 'error';
function useLogger() {
  const loggedUser = useRecoilValue(loggedUserAtom);

  const doLog = useCallback(
    async (message: string | Object, level: Level = 'info') => {
      // eslint-disable-next-line no-console
      console.log('useLogger - logging...', level, message);
      try {
          const messageTreated =
          typeof message === 'object' ? { ...message, userEmail: loggedUser?.email, masterCompanyId: loggedUser?.masterCompany.id } : { error: message, userEmail: loggedUser?.email, masterCompanyId: loggedUser?.masterCompany.id };

        await axios.post(`/api/logger/${level}`, { message: messageTreated }, { baseURL });
      } catch (error) {
        console.error('useLogger - error logging', error);
      }
    },
    [loggedUser]
  );

  return doLog;
}

export default useLogger;
