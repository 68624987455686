/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import Block from './Block';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmModal({
  title,
  description,
  open,
  setOpen,
  doConfirm,
  disabled = false,
  variant,
  confirmActionText,
  confirmActionColor = 'primary',
  confirmActionIcon,
  loading,
  stepByStep,
}: {
  title: string;
  description: string | string[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  doConfirm: Function;
  disabled?: boolean;
  confirmActionText?: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained';
  confirmActionIcon?: JSX.Element;
  stepByStep?: JSX.Element;
  confirmActionColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  loading: boolean;
}) {
  const { t } = useTranslation('confirm_modal');
  return (
    <Block open={open}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {open && (
          <>
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  gap: 2,
                }}
              >
                {Array.isArray(description)
                  ? description.map((_desc, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Typography mb={2} key={index} variant="body2">
                        {_desc}
                      </Typography>
                    ))
                  : description}

                {stepByStep}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
              <Button
                variant={variant}
                onClick={() => doConfirm()}
                color={confirmActionColor}
                disabled={disabled}
                aria-label="confirm"
              >
                {loading ? (
                  <CircularProgress size="1.6rem" color="inherit" />
                ) : confirmActionIcon ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    {confirmActionIcon}
                    {confirmActionText || t('confirm')}
                  </Box>
                ) : (
                  confirmActionText || t('confirm')
                )}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Block>
  );
}

export default ConfirmModal;
