import React, { useCallback, useMemo } from 'react';
import { Alert, Box, SelectChangeEvent, Typography } from '@mui/material';
import SelectProtocol from 'components/forms/channels/SelectProtocol';
import { Channel, DEFAULT_BATCH_CHANNEL, DEFAULT_CHANNEL, ProtocolType } from 'types/channels.types';
import { BatchChannelForm, RTMPChannelForm, RTSPChannelForm } from 'components/forms/channels';
import { BatchRTMPForm } from 'components/forms/channels/BatchRTMPForm/BatchRTMPForm';
import { useTranslation } from 'react-i18next';
import { useChannelsApi } from 'hooks/channels/useChannelsApi';

interface Props {
  customerId: number;
  batch?: boolean;
}
export function ChannelRoot({ customerId, batch }: Props) {
  const [protocol, setProtocol] = React.useState<ProtocolType | null>(null);
  const { createChannel } = useChannelsApi(customerId);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (channel: Channel): Promise<Channel> => {
      const data = await createChannel(channel);
      return data;
    },
    [createChannel]
  );

  const onChange = React.useCallback((event: SelectChangeEvent<ProtocolType>) => {
    setProtocol(event.target.value as ProtocolType);
  }, []);

  const RTMPComponent = useMemo(
    () =>
      batch ? (
        <BatchRTMPForm
          onClose={() => setProtocol(null)}
          initialValues={DEFAULT_BATCH_CHANNEL}
          customerId={customerId}
        />
      ) : (
        <RTMPChannelForm
          onClose={() => setProtocol(null)}
          doSubmit={handleSubmit}
          customerId={customerId}
          initialValues={DEFAULT_CHANNEL}
        />
      ),
    [batch, customerId, handleSubmit]
  );

  const RTSPComponent = useMemo(
    () =>
      batch ? (
        <BatchChannelForm
          onClose={() => setProtocol(null)}
          initialValues={DEFAULT_BATCH_CHANNEL}
          customerId={customerId}
        />
      ) : (
        <RTSPChannelForm
          onClose={() => setProtocol(null)}
          doSubmit={handleSubmit}
          customerId={customerId}
          initialValues={DEFAULT_CHANNEL}
        />
      ),
    [batch, customerId, handleSubmit]
  );

  return (
    <Box
      sx={{
        maxHeight: '100%',
        height: '100vh',
        ml: 2,
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          m: 2,
          flexDirection: 'column',
          overflow: 'auto',
          flex: '1 1 auto',
        }}
      >
        <SelectProtocol {...{ onChange }} value={protocol} />
        {protocol === ProtocolType.RTMP && (
          <Alert
            variant="filled"
            severity="info"
            sx={(theme) => ({
              bgcolor: theme.palette.info.main,
              width: 'fit-content',
            })}
          >
            <Typography
              sx={{
                color: 'inherit',
              }}
              variant="vms-body2"
            >
              {t('_common:the_rtmp_protocol_link_is_generated_after_the_channel_is_created')}
            </Typography>
          </Alert>
        )}

        {protocol === ProtocolType.RTMP && RTMPComponent}
        {protocol === ProtocolType.RTSP && RTSPComponent}
      </Box>
    </Box>
  );
}
