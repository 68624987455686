import { selectedDateInPickerAtom } from 'atoms/playback';
import { timeHour } from 'd3';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export const getScaleDate = (selectedDate: Date | null, nowLiveDate: Date) => ({
  START: selectedDate ? timeHour.offset(selectedDate, -2) : timeHour.offset(nowLiveDate, -2),
  END: selectedDate ? timeHour.offset(selectedDate, 2) : nowLiveDate,
});

/**
 *
 * @param options {live, selectedDate, nowLiveDate}
 * @returns an array with two values, first one is the startDate, and the second one is endDate
 */
export default function useScaleDates({
  live,
  selectedDate,
  nowLiveDate,
}: {
  live?: boolean;
  selectedDate: Date | null;
  nowLiveDate: Date;
}): [Date, Date] {
  const selectedDateInPicker = useRecoilValue(selectedDateInPickerAtom);

  return useMemo(() => {
    const scaleDate = getScaleDate(selectedDate, nowLiveDate);
    /* Se for live deve mostrar as barras de duas horas antes, se for data selecionada deve mostrar a barra de duas horas antes e depois */
    return [scaleDate.START, scaleDate.END];
    // Só deve atualizar o xScale caso tenha selecionado a data pelo picker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateInPicker, live]);
}
