import React, { useCallback } from 'react';
import {
  useSnackbar,
  ProviderContext,
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
} from 'notistack';
import { Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MessageButton = styled(Button)({
  color: 'white',
});

interface Messages {
  enqueueSuccess: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  enqueueError: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  enqueueInfo: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  enqueueWarning: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

export default function useMessages(): ProviderContext & Messages {
  const { t } = useTranslation('_common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const enqueueSuccess = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      action: (key) => (
        <MessageButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t('dismiss')}
        </MessageButton>
      ),
      ...options,
      variant: 'success',
    }
    );
  const enqueueError = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      persist: true,
      preventDuplicate: true,
      action: (key) => (
        <MessageButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t('dismiss')}
        </MessageButton>
      ),
      ...options,
      variant: 'error',
    }
    );
  const enqueueInfo = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        action: (key) => (
          <MessageButton
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            {t('dismiss')}
          </MessageButton>
        ),
        ...options,
        variant: 'info',
      }),
    [closeSnackbar, enqueueSnackbar, t]
  );
  const enqueueWarning = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        action: (key) => (
          <MessageButton
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            {t('dismiss')}
          </MessageButton>
        ),
        ...options,
        variant: 'warning',
      }),
    [closeSnackbar, enqueueSnackbar, t]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
    enqueueSuccess,
    enqueueError,
    enqueueInfo,
    enqueueWarning,
  };
}
