import { Box, Button, ButtonGroup, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectChannelGroupToFilters from 'components/SelectChannelGroupToFilters';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks';
import routes from 'helpers/routes';
import SelectCustomerGroup from 'components/SelectCustomerGroup';
import SelectCustomers from 'components/SelectCustomers';
import SelectRecordingTypesToFilters from 'components/SelectRecordingTypeToFilters';
import { useTranslation } from 'react-i18next';
import DebounceInput from 'components/core/DebounceInput';
import ChannelsMonitoringAppliedFilters from './ChannelsMonitoringAppliedFilters';

const ContainerFilter = styled(Box)({
  '--width': 'calc(100% / 6)',
  width: 'calc(var(--width) - 8px)',
});

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
}));

const useStyles = makeStyles((theme) => ({
  recordingLabel: {
    marginRight: theme.spacing(1),
  },
  recordingButton: {
    height: '100%',
  },
}));

function ChannelsMonitoringFilters() {
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const { t } = useTranslation(['channels_monitoring', '_common', 'channels']);
  const selectedChannelGroupsS = query.get('channelGroups');
  const [selectedChannelGroups, setSelectedChannelGroups] = useState(
    selectedChannelGroupsS
      ? selectedChannelGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );
  const selectedCustomerGroupsS = query.get('customerGroups');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState(
    selectedCustomerGroupsS
      ? selectedCustomerGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );

  const selectedCustomerS = query.get('customers');
  const [selectedCustomers, setSelectedCustomers] = useState(
    selectedCustomerS
      ? selectedCustomerS.split(',').map((id) => ({ id: Number(id), label: '' }))
      : []
  );

  const selectedRecordingTypesS = query.get('recordingTypes');
  const [selectedRecordingTypes, setSelectedRecordingTypes] = useState(
    selectedRecordingTypesS ? selectedRecordingTypesS.split(',') : []
  );

  const recordingS = query.get('recording');
  const [recording, setRecording] = useState(recordingS ? Boolean(Number(recordingS)) : null);

  const channelNameS = query.get('channelName');
  const [channelName, setChannelName] = useState(channelNameS || '');
  const [channelNameDebounced, setChannelNameDebounced] = useState(channelNameS || '');

  useEffect(
    function updateWhenChangeSearchParams() {
      const isChannelGroupsEqual =
        selectedChannelGroups.map((v) => v.id).join(',') === selectedChannelGroupsS;
      if (!isChannelGroupsEqual) {
        setSelectedChannelGroups(
          selectedChannelGroupsS
            ? selectedChannelGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }

      const isCustomerGroupsEqual =
        selectedCustomerGroups.map((v) => v.id).join(',') === selectedCustomerGroupsS;
      if (!isCustomerGroupsEqual) {
        setSelectedCustomerGroups(
          selectedCustomerGroupsS
            ? selectedCustomerGroupsS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }

      const isCustomersEqual = selectedCustomers.map((v) => v.id).join(',') === selectedCustomerS;
      if (!isCustomersEqual) {
        setSelectedCustomers(
          selectedCustomerS
            ? selectedCustomerS.split(',').map((id) => ({ id: Number(id), label: '' }))
            : []
        );
      }

      const isRecordingTypesEqual = selectedRecordingTypes.join(',') === selectedRecordingTypesS;
      if (!isRecordingTypesEqual) {
        setSelectedRecordingTypes(
          selectedRecordingTypesS ? selectedRecordingTypesS.split(',') : []
        );
      }

      const isRecordingEqual = recordingS
        ? recording === Boolean(Number(recordingS))
        : recording === null;
      if (!isRecordingEqual) {
        setRecording(recordingS ? Boolean(Number(recordingS)) : null);
      }

      const isChannelNameEqual = channelName === channelNameS;
      if (!isChannelNameEqual) {
        setChannelName(channelNameS || '');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  useEffect(
    function updateSearchParamsWhenChangeFilters() {
      history.push(
        routes.channels.monitoring({
          selectedChannelGroups: selectedChannelGroups.map((v) => v.id),
          selectedCustomerGroups: selectedCustomerGroups.map((v) => v.id),
          selectedCustomers: selectedCustomers.map((v) => v.id),
          selectedRecordingTypes,
          recording,
          channelName: channelNameDebounced,
        })
      );
    },
    [
      history,
      recording,
      selectedChannelGroups,
      selectedCustomerGroups,
      selectedCustomers,
      selectedRecordingTypes,
      channelName,
      channelNameDebounced,
    ]
  );

  return (
    <>
      <Container aria-label="Filtros do monitoramento de canais" role="region">
        <ContainerFilter display="flex" alignItems="center">
          <Typography className={classes.recordingLabel} variant="body2">
            {t('recording')}
          </Typography>
          <ButtonGroup
            className={classes.recordingButton}
            variant="outlined"
            color="grey"
            size="small"
            fullWidth
          >
            <Button
              onClick={() => setRecording(recording ? null : true)}
              color={recording ? 'primary' : undefined}
            >
              {t('_common:yes')}
            </Button>
            <Button
              onClick={() => setRecording(recording === false ? null : false)}
              color={recording === false ? 'primary' : undefined}
            >
              {t('_common:no')}
            </Button>
          </ButtonGroup>
        </ContainerFilter>
        <ContainerFilter>
          <DebounceInput
            placeholder={t('channels:channel_name')}
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
            setValue={(value) => setChannelName(value)}
            onDebounce={() => {
              setChannelNameDebounced(channelName);
            }}
            fullWidth
          />
        </ContainerFilter>

        <ContainerFilter>
          <Suspense fallback={<SelectChannelGroupToFilters.Loading />}>
            <SelectChannelGroupToFilters
              {...{ selectedChannelGroups, onChangeChannelGroups: setSelectedChannelGroups }}
            />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectCustomerGroup.Loading />}>
            <SelectCustomerGroup
              {...{ selectedCustomerGroups, onChangeCustomerGroups: setSelectedCustomerGroups }}
            />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <Suspense fallback={<SelectCustomers.Loading />}>
            <SelectCustomers {...{ selectedCustomers, onChangeCustomers: setSelectedCustomers }} />
          </Suspense>
        </ContainerFilter>
        <ContainerFilter>
          <SelectRecordingTypesToFilters
            {...{ selectedRecordingTypes, onChangeRecordingTypes: setSelectedRecordingTypes }}
          />
        </ContainerFilter>
      </Container>
      <ChannelsMonitoringAppliedFilters
        {...{
          selectedChannelGroups,
          setSelectedChannelGroups,
          selectedCustomerGroups,
          setSelectedCustomerGroups,
          selectedCustomers,
          setSelectedCustomers,
          selectedRecordingTypes,
          setSelectedRecordingTypes,
          recording,
          setRecording,
          channelName,
          setChannelName: setChannelNameDebounced,
        }}
      />
    </>
  );
}

export default ChannelsMonitoringFilters;
