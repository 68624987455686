import { atom, atomFamily } from 'recoil';

export const searchPanelOpened = atom({
  key: 'searchPanelOpened',
  default: false,
});

export const searchPanelOrderedById = atomFamily<boolean, string>({
  key: 'searchPanelOrderedById',
  default: false,
});

export const searchPanelText = atom({
  key: 'searchPanelText',
  default: '',
});

export const searchPanelTextDebouncedAtom = atom({
  key: 'searchPanelTextDebouncedAtom',
  default: '',
});

/* To not get cache */
export const lastChangeInTextDebounced = atom({
  key: 'lastChangeInTextDebounced',
  default: Date.now(),
});
