/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form, useFormStyles } from 'components/core';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Box, TextField } from '@mui/material';
import { useYupObject } from 'hooks';
import {
  addPricesModalOpenedAtom,
  invoiceTaxesAtom,
  marginMultiplierForUseAtom,
  marginMultiplierForUsedSpaceAtom,
  priceLivePerMinuteAtom,
  priceliveStreamTransferPerGBAtom,
  priceRecordingDisplayPerMinuteAtom,
  priceRecordingPerMinuteAtom,
  priceRecordingTransferPerGBAtom,
  priceTransferRecordedVideoPlaybackPerGBAtom,
  priceUsedSpacePerGBAtom,
} from 'atoms/calculator';
import { Prices } from 'types/calculator.types';

function CalculatorAddPriceForm() {
  const { t } = useTranslation(['calculator', 'crud_actions']);

  const setAddPricesModalOpened = useSetRecoilState(addPricesModalOpenedAtom);
  const [priceRecordingPerMinute, setPriceRecordingPerMinute] = useRecoilState(
    priceRecordingPerMinuteAtom
  );
  const [priceLivePerMinute, setPriceLivePerMinute] = useRecoilState(priceLivePerMinuteAtom);
  const [priceRecordingDisplayPerMinute, setPriceRecordingDisplayPerMinute] = useRecoilState(
    priceRecordingDisplayPerMinuteAtom
  );
  const [priceUsedSpacePerGB, setPriceUsedSpacePerGB] = useRecoilState(priceUsedSpacePerGBAtom);
  const [priceRecordingTransferPerGB, setPriceRecordingTransferPerGB] = useRecoilState(
    priceRecordingTransferPerGBAtom
  );
  const [priceliveStreamTransferPerGB, setPriceliveStreamTransferPerGB] = useRecoilState(
    priceliveStreamTransferPerGBAtom
  );
  const [
    priceTransferRecordedVideoPlaybackPerGB,
    setPriceTransferRecordedVideoPlaybackPerGB,
  ] = useRecoilState(priceTransferRecordedVideoPlaybackPerGBAtom);
  const [marginMultiplierForUsedSpace, setMarginMultiplierForUsedSpace] = useRecoilState(
    marginMultiplierForUsedSpaceAtom
  );
  const [marginMultiplierForUse, setMarginMultiplierForUse] = useRecoilState(
    marginMultiplierForUseAtom
  );
  const [invoiceTaxes, setInvoiceTaxes] = useRecoilState(invoiceTaxesAtom);

  const yup = useYupObject();

  const validationSchema = yup.object({
    priceRecordingPerMinute: yup.number().required().moreThan(0),
    priceLivePerMinute: yup.number().required().moreThan(0),
    priceRecordingDisplayPerMinute: yup.number().required().moreThan(0),
    priceUsedSpacePerGB: yup.number().required().min(0),
    priceRecordingTransferPerGB: yup.number().required().moreThan(0),
    priceliveStreamTransferPerGB: yup.number().required().moreThan(0),
    priceTransferRecordedVideoPlaybackPerGB: yup.number().required().moreThan(0),
    marginMultiplierForUsedSpace: yup.number().required().moreThan(0),
    marginMultiplierForUse: yup.number().required().moreThan(0),
    invoiceTaxes: yup.number().required().moreThan(0),
  });

  const initialValues: Prices = {
    priceRecordingPerMinute,
    priceLivePerMinute,
    priceRecordingDisplayPerMinute,
    priceUsedSpacePerGB,
    priceRecordingTransferPerGB,
    priceliveStreamTransferPerGB,
    priceTransferRecordedVideoPlaybackPerGB,
    marginMultiplierForUsedSpace,
    marginMultiplierForUse,
    invoiceTaxes,
  };

  const classesForm = useFormStyles({ dialog: true });
  const submit = useCallback(
    (values: Prices, { setSubmitting }) => {
      setPriceRecordingPerMinute(values.priceRecordingPerMinute);
      setPriceLivePerMinute(values.priceLivePerMinute);
      setPriceRecordingDisplayPerMinute(values.priceRecordingDisplayPerMinute);
      setPriceUsedSpacePerGB(values.priceUsedSpacePerGB);
      setPriceRecordingTransferPerGB(values.priceRecordingTransferPerGB);
      setPriceliveStreamTransferPerGB(values.priceliveStreamTransferPerGB);
      setPriceTransferRecordedVideoPlaybackPerGB(values.priceTransferRecordedVideoPlaybackPerGB);
      setMarginMultiplierForUsedSpace(values.marginMultiplierForUsedSpace);
      setMarginMultiplierForUse(values.marginMultiplierForUse);
      setInvoiceTaxes(values.invoiceTaxes);
      setSubmitting(false);
      setAddPricesModalOpened(false);
    },
    [
      setAddPricesModalOpened,
      setPriceLivePerMinute,
      setPriceRecordingDisplayPerMinute,
      setPriceRecordingPerMinute,
      setPriceRecordingTransferPerGB,
      setPriceTransferRecordedVideoPlaybackPerGB,
      setPriceUsedSpacePerGB,
      setPriceliveStreamTransferPerGB,
      setMarginMultiplierForUsedSpace,
      setMarginMultiplierForUse,
      setInvoiceTaxes,
    ]
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submit,
  });

  return (
    <form aria-label="channel" className={classesForm.form} onSubmit={formik.handleSubmit}>
      <Box className={classesForm.formInputsContainer}>
        <TextField
          sx={{
            marginTop: '6px',
          }}
          className={classesForm.formInput}
          label={t('recording_per_minute')}
          name="priceRecordingPerMinute"
          id="priceRecordingPerMinute"
          fullWidth
          autoFocus
          placeholder={t('recording_per_minute')}
          helperText={
            formik.touched.priceRecordingPerMinute && formik.errors.priceRecordingPerMinute
          }
          value={formik.values.priceRecordingPerMinute}
          onChange={formik.handleChange}
          error={
            formik.touched.priceRecordingPerMinute && Boolean(formik.errors.priceRecordingPerMinute)
          }
        />
        <TextField
          className={classesForm.formInput}
          label={t('live_per_minute')}
          name="priceLivePerMinute"
          id="priceLivePerMinute"
          fullWidth
          placeholder={t('live_per_minute')}
          helperText={formik.touched.priceLivePerMinute && formik.errors.priceLivePerMinute}
          value={formik.values.priceLivePerMinute}
          onChange={formik.handleChange}
          error={formik.touched.priceLivePerMinute && Boolean(formik.errors.priceLivePerMinute)}
        />

        <TextField
          className={classesForm.formInput}
          label={t('playback_of_recorded_video_per_minute')}
          name="priceRecordingDisplayPerMinute"
          id="priceRecordingDisplayPerMinute"
          fullWidth
          placeholder={t('playback_of_recorded_video_per_minute')}
          helperText={
            formik.touched.priceRecordingDisplayPerMinute &&
            formik.errors.priceRecordingDisplayPerMinute
          }
          value={formik.values.priceRecordingDisplayPerMinute}
          onChange={formik.handleChange}
          error={
            formik.touched.priceRecordingDisplayPerMinute &&
            Boolean(formik.errors.priceRecordingDisplayPerMinute)
          }
        />

        <TextField
          className={classesForm.formInput}
          label={t('transfer_for_recording_by_GB')}
          name="priceRecordingTransferPerGB"
          id="priceRecordingTransferPerGB"
          fullWidth
          placeholder={t('transfer_for_recording_by_GB')}
          helperText={
            formik.touched.priceRecordingTransferPerGB && formik.errors.priceRecordingTransferPerGB
          }
          value={formik.values.priceRecordingTransferPerGB}
          onChange={formik.handleChange}
          error={
            formik.touched.priceRecordingTransferPerGB &&
            Boolean(formik.errors.priceRecordingTransferPerGB)
          }
        />

        <TextField
          className={classesForm.formInput}
          label={t('live_stream_transfer_by_GB')}
          name="priceliveStreamTransferPerGB"
          id="priceliveStreamTransferPerGB"
          fullWidth
          placeholder={t('live_stream_transfer_by_GB')}
          helperText={
            formik.touched.priceliveStreamTransferPerGB &&
            formik.errors.priceliveStreamTransferPerGB
          }
          value={formik.values.priceliveStreamTransferPerGB}
          onChange={formik.handleChange}
          error={
            formik.touched.priceliveStreamTransferPerGB &&
            Boolean(formik.errors.priceliveStreamTransferPerGB)
          }
        />

        <TextField
          className={classesForm.formInput}
          label={t('transfer_for_playback_of_recorded_video_by_GB')}
          name="priceTransferRecordedVideoPlaybackPerGB"
          id="priceTransferRecordedVideoPlaybackPerGB"
          fullWidth
          placeholder={t('priceTransferRecordedVideoPlaybackPerGB')}
          helperText={
            formik.touched.priceTransferRecordedVideoPlaybackPerGB &&
            formik.errors.priceTransferRecordedVideoPlaybackPerGB
          }
          value={formik.values.priceTransferRecordedVideoPlaybackPerGB}
          onChange={formik.handleChange}
          error={
            formik.touched.priceTransferRecordedVideoPlaybackPerGB &&
            Boolean(formik.errors.priceTransferRecordedVideoPlaybackPerGB)
          }
        />

        <TextField
          className={classesForm.formInput}
          label={t('margin_multiplier_for_use')}
          fullWidth
          placeholder={t('marginMultiplierForUse')}
          onChange={formik.handleChange}
          name="marginMultiplierForUse"
          id="marginMultiplierForUse"
          helperText={formik.touched.marginMultiplierForUse && formik.errors.marginMultiplierForUse}
          value={formik.values.marginMultiplierForUse}
          error={
            formik.touched.marginMultiplierForUse && Boolean(formik.errors.marginMultiplierForUse)
          }
        />

        <TextField
          className={classesForm.formInput}
          label={t('invoice_taxes')}
          name="invoiceTaxes"
          id="invoiceTaxes"
          fullWidth
          placeholder={t('invoice_taxes')}
          helperText={formik.touched.invoiceTaxes && formik.errors.invoiceTaxes}
          value={formik.values.invoiceTaxes}
          onChange={formik.handleChange}
          error={formik.touched.invoiceTaxes && Boolean(formik.errors.invoiceTaxes)}
        />

        <Box display="flex">
          <TextField
            sx={{
              marginRight: '8px',
            }}
            className={classesForm.formInput}
            label={t('used_space_per_GB')}
            fullWidth
            placeholder={t('used_space_per_GB')}
            name="priceUsedSpacePerGB"
            id="priceUsedSpacePerGB"
            helperText={formik.touched.priceUsedSpacePerGB && formik.errors.priceUsedSpacePerGB}
            error={formik.touched.priceUsedSpacePerGB && Boolean(formik.errors.priceUsedSpacePerGB)}
            value={formik.values.priceUsedSpacePerGB}
            onChange={formik.handleChange}
          />
          <TextField
            sx={{
              marginRight: '8px',
            }}
            className={classesForm.formInput}
            label={t('margin_multiplier_for_used_space')}
            fullWidth
            placeholder={t('margin_multiplier_for_used_space')}
            name="marginMultiplierForUsedSpace"
            id="marginMultiplierForUsedSpace"
            helperText={
              formik.touched.marginMultiplierForUsedSpace &&
              formik.errors.marginMultiplierForUsedSpace
            }
            value={formik.values.marginMultiplierForUsedSpace}
            error={
              formik.touched.marginMultiplierForUsedSpace &&
              Boolean(formik.errors.marginMultiplierForUsedSpace)
            }
            onChange={formik.handleChange}
          />
        </Box>
      </Box>
      <Box className={classesForm.formFooter}>
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => setAddPricesModalOpened(false)}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          values={formik.values}
          dialog
        />
      </Box>
    </form>
  );
}

export default CalculatorAddPriceForm;
