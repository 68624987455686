import { Box, styled, TextField } from '@mui/material';
import { DateRangeField, DateRangeValue } from 'components/DateRange';
import SelectChannels from 'components/SelectChannels';
import routes from 'helpers/routes';
import { useQuery } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FilterSelectValue } from 'types/select.types';
import SelectCustomer from 'components/SelectCustomer';
import RecordingsAppliedFilters from './RecordingsAppliedFilters';

const ContainerFilter = styled(Box)({
  '--width': 'calc(100% / 3)',
  width: 'calc(var(--width) - 8px)',
});

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
}));

function RecordingsFilters() {
  const { t } = useTranslation('_common');
  const history = useHistory();
  const query = useQuery();
  const customerS = query.get('customer');
  const [customer, setCustomer] = React.useState(
    customerS ? { id: Number(customerS), label: '' } : null
  );

  const channelsS = query.get('channels');
  const [channels, setChannels] = React.useState(
    channelsS ? channelsS.split(',').map((channelId) => ({ id: Number(channelId), label: '' })) : []
  );

  const startDateS = query.get('startDate');
  const endDateS = query.get('endDate');
  const [range, setRange] = React.useState<DateRangeValue | null>(
    startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : null
  );

  React.useEffect(
    function updateWhenChangeSearchParams() {
      const isCustomerEqual = customerS === (customer && String(customer.id));
      if (!isCustomerEqual) {
        setCustomer(customerS ? { id: Number(customerS), label: '' } : null);
      }

      const isChannelsEqual = (channels.map((v) => v.id).join(',') || null) === channelsS;
      if (!isChannelsEqual) {
        setChannels(
          channelsS ? channelsS.split(',').map((id) => ({ id: Number(id), label: '' })) : []
        );
      }

      const rangeS = startDateS && endDateS ? [startDateS, endDateS].join(',') : null;
      const isRangeEqual = rangeS === (range?.map((d) => d.toISOString()).join(',') || null);
      if (!isRangeEqual) {
        setRange(startDateS && endDateS ? [new Date(startDateS), new Date(endDateS)] : null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  React.useEffect(
    function updateSearchParamsWhenChangeFilters() {
      history.push(
        routes.recordings.root({
          selectedCustomer: customer && customer.id,
          selectedChannels: channels.map((c) => c.id),
          startDate: range && range[0],
          endDate: range && range[1],
        })
      );
    },
    [channels, customer, history, range]
  );

  const handleChangeCustomer = (newCustomer: FilterSelectValue | null) => {
    setCustomer(newCustomer);
    setChannels([]);
  };

  return (
    <>
      <Container aria-label="Filtros das gravações" role="region">
        <ContainerFilter>
          <React.Suspense fallback={<SelectCustomer.Loading />}>
            <SelectCustomer selectedCustomer={customer} onChangeCustomer={handleChangeCustomer} />
          </React.Suspense>
        </ContainerFilter>
        <ContainerFilter>
          {customer ? (
            <React.Suspense fallback={<SelectChannels.Loading />}>
              <SelectChannels
                selectedChannels={channels}
                setSelectedChannels={setChannels}
                customerId={customer.id}
              />
            </React.Suspense>
          ) : (
            <TextField label={t('channels')} disabled fullWidth />
          )}
        </ContainerFilter>
        <ContainerFilter>
          <DateRangeField label={t('_common:date')} value={range} setValue={setRange} />
        </ContainerFilter>
      </Container>
      <RecordingsAppliedFilters
        {...{ customer, handleChangeCustomer, channels, setChannels, range, setRange }}
      />
    </>
  );
}

export default RecordingsFilters;
