import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding-top: 12px;
  flex: 1 1 auto;
  overflow: hidden;
  box-sizing: border-box;
`;

export const ContainerDetails = styled.div`
  flex: 0 0 50%;
  padding: 8px;
  margin-left: 16px;
  box-sizing: border-box;
`;

export const HeaderDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 8px;

  > p {
    flex: 1 1 auto;
  }
`;

export const RolesContainer = styled.div`
  margin: 0.6rem 0px;
  > div {
    margin: 4px;
  }
`;
