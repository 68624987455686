import React, { useCallback, useEffect, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteMosaicConfirmOpened,
  mosaicHasChangesSelector,
} from 'atoms/mosaics';
import {
  slotsHasChanges as slotsHasChangesSelector,
  mosaicItensValuesAtom,
} from 'atoms/mosaicItems';
import { useSetRecoilState, useRecoilValue, SetterOrUpdater, useRecoilState } from 'recoil';
import templates from 'helpers/mosaicTemplates';
import { useTranslation } from 'react-i18next';
import { Prompt, useRouteMatch, matchPath } from 'react-router-dom';
import { mosaicViewContext } from 'helpers/routes';
import { Mosaic, Template } from 'types/mosaics.types';
import ConnectionStatus from 'components/ConnectionStatus';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import SelectQtyChannels from 'components/SelectQtyChannels/SelectQtyChannels';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { useMosaicItensApi } from 'hooks/mosaic/items/useMosaicItemsApi';
import { useCreateMosaicModal } from 'hooks/mosaic/useCreateMosaicModal';
import { useMosaicApi } from 'hooks/mosaic/useMosaicApi';

function MosaicActions({
  mosaic,
  setMosaic,
}: {
  mosaic: Mosaic;
  setMosaic: SetterOrUpdater<Mosaic | null>;
}) {
  const documentkeyDown = useRef(document.onkeydown);
  const slotsHasChanges = useRecoilValue(slotsHasChangesSelector(mosaic.id));
  const mosaicHasChanges = useRecoilValue(mosaicHasChangesSelector(mosaic.id));
  const { saveItemsOfMosaic } = useMosaicItensApi(mosaic.id);
  const { openCreateModal } = useCreateMosaicModal();
  const { editMosaic } = useMosaicApi();
  const setDeleteMosaicConfirmOpened = useSetRecoilState(deleteMosaicConfirmOpened);
  const { t } = useTranslation(['crud_actions', 'mosaics', '_common']);
  const match = useRouteMatch(mosaicViewContext);
  const [mosaicItensValues, setMosaicItensValues] = useRecoilState(
    mosaicItensValuesAtom(mosaic.id)
  );
  const [hiddenPopForDevTeamFlag] = useDecision(
    FEATURE_FLAGS_KEYS.POPUP_CONFIRM_EXIT_WITH_UNSAVED_CHANGES
  );

  useEffect(
    function handleConfirmExitWithUnsavedChanges() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const beforeUnload = (e: any) => {
        if (!match) {
          return '';
        }
        if (slotsHasChanges || mosaicHasChanges) {
          (e || window.event).returnValue = t(
            'crud_actions:there_are_unsaved_changes_are_you_sure_you_want_to_exit'
          );
          return t('crud_actions:there_are_unsaved_changes_are_you_sure_you_want_to_exit');
        }
        return '';
      };
      window.addEventListener('beforeunload', beforeUnload);
      return () => window.removeEventListener('beforeunload', beforeUnload);
    },
    [mosaicHasChanges, slotsHasChanges, t, match]
  );

  const handleSave = useCallback(() => {
    saveItemsOfMosaic();

    editMosaic({
      ...mosaic,
      channelsInMosaic: mosaicItensValues.map((item) => ({
        id: item.channel.id,
        name: item.channel.name,
      })),
    });
  }, [editMosaic, mosaic, mosaicItensValues, saveItemsOfMosaic]);

  useEffect(
    function handleSaveOnCtrlS() {
      documentkeyDown.current = document.onkeydown;
      // eslint-disable-next-line func-names
      document.onkeydown = function (e) {
        // eslint-disable-next-line no-param-reassign
        e = e || window.event; // Get event
        if (e.ctrlKey) {
          const c = e.which || e.keyCode; // Get key code
          if (c === 83) {
            // Block Ctrl+S
            e.preventDefault();
            e.stopPropagation();
            if (!slotsHasChanges && !mosaicHasChanges) {
              return;
            }
            handleSave();
          }
        }
      };

      return () => {
        document.onkeydown = documentkeyDown.current;
      };
    },
    [handleSave, mosaicHasChanges, slotsHasChanges]
  );

  const onChangeTemplate = (template: Template) => {
    setMosaic({
      ...mosaic,
      qtyChannels: template,
      mosaicData: templates()[template],
    });

    if (template > mosaic.qtyChannels) {
      return;
    }

    if (mosaicItensValues.filter((item) => item.order <= template).length === template) {
      return;
    }
    setMosaicItensValues(
      [...mosaicItensValues]
        .sort((a, b) => a.order - b.order)
        .map((item, index) => ({
          ...item,
          order: index + 1,
        }))
    );
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      {hiddenPopForDevTeamFlag.enabled ? null : (
        <Prompt
          when={slotsHasChanges || mosaicHasChanges}
          message={(location) => {
            const matchI = matchPath(location.pathname, {
              path: mosaicViewContext,
            });
            return (
              !!matchI || t('crud_actions:there_are_unsaved_changes_are_you_sure_you_want_to_exit')
            );
          }}
        />
      )}
      <Box
        aria-label="mosaic actions"
        display="flex"
        alignItems="center"
      >
        <Permissioned role={PermissionRole.EDIT_MOSAIC}>
          <IconButton
            size="small"
            disabled={!slotsHasChanges && !mosaicHasChanges}
            onClick={() => handleSave()}
            aria-label="save"
            sx={{ p: 0 }}
          >
            <SaveIcon />
          </IconButton>
        </Permissioned>
        <Permissioned role={PermissionRole.INSERT_MOSAIC}>
          <IconButton
            size="small"
            aria-label="add"
            onClick={() => openCreateModal()}
            sx={{ p: 0, mx: 1.5 }}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        </Permissioned>
        <Permissioned role={PermissionRole.REMOVE_MOSAIC}>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => setDeleteMosaicConfirmOpened(true)}
            sx={{ p: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Permissioned>
      </Box>

      <Box
        aria-label="mosaic status"
        sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ConnectionStatus />
        <SelectQtyChannels
          qtyChannels={mosaic.qtyChannels}
          onChangeQtyChannels={(e) => onChangeTemplate(Number(e.target.value) as Template)}
          label={t('mosaics:channels_in_the_mosaic')}
          size="small"
          sx={{ minWidth: '150px', ml: 2 }}
        />
      </Box>

    </Box>
  );
}

export default MosaicActions;
