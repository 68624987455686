import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function GoodFeedback() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mb: 2,
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
        {t('speed_test:wonder')}
      </Typography>
      <Typography variant="h6">{t('speed_test:good_feedback')}</Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {t('speed_test:good_feedback_2')}
      </Typography>
      <Typography variant="body2">{t('speed_test:good_feedback_3')}</Typography>
    </Box>
  );
}
