import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function ExportPDF(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6,2A2.013,2.013,0,0,0,4,4V20a2.013,2.013,0,0,0,2,2H18a2.013,2.013,0,0,0,2-2V8L14,2ZM6,4h7V9h5V20H6Zm5.832,5a1.044,1.044,0,0,0-1.043,1.023,5.7,5.7,0,0,0,.8,2.838,15.226,15.226,0,0,1-.8,2.027,5.546,5.546,0,0,0-2.406,1.3,1.046,1.046,0,0,0-.16,1.459A.8.8,0,0,0,8.945,18a1.052,1.052,0,0,0,.482-.113,5.4,5.4,0,0,0,1.924-2.351,12.7,12.7,0,0,1,1.684-.4,3.985,3.985,0,0,0,1.605,1.217,1.016,1.016,0,0,0,1.283-.65.878.878,0,0,0-.322-1.135,3.755,3.755,0,0,0-2.246-.244,14.04,14.04,0,0,1-.881-1.459,5.976,5.976,0,0,0,.4-2.918C12.714,9.373,12.382,9,11.832,9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default ExportPDF;
