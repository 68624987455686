import React from 'react';
import { Box, Paper, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { calculatedItemsSelector } from 'atoms/calculator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const useStyles = makeStyles((theme) => ({
  summaryTotalStorage: {
    marginRight: '4px',
  },
  footer: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 4rem',
  },
}));

interface CalculatorFooterProps {
  totalChannels: number;
  totalStorage: number;
  totalValue: number;
}

function CalculatorFooter({ totalChannels, totalStorage, totalValue }: CalculatorFooterProps) {
  const { t } = useTranslation('calculator');
  const list = useRecoilValue(calculatedItemsSelector);
  const classes = useStyles();

  if (list.length === 0) {
    return null;
  }

  return (
    <>
      <Container role="region" aria-label="Calculator table footer" className={classes.footer}>
        <Typography variant="h5" display="inline">
          {totalChannels}&nbsp;
          {t('_common:channel', {
            count: Math.abs(totalChannels),
          }).toLowerCase()}
        </Typography>
        <Box>
          <Typography variant="h5" display="inline" classes={{ root: classes.summaryTotalStorage }}>
            {`${totalStorage.toFixed(2)} GB`}
          </Typography>
          <Typography variant="body2" display="inline">
            {t('used')}
          </Typography>
        </Box>
        <Typography variant="h4" display="inline">
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalValue)}
        </Typography>
      </Container>
    </>
  );
}

export default CalculatorFooter;
