import React from 'react';
import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TEMPLATE_STRUCTURE } from 'helpers/mosaicTemplates';
import { Template } from 'types/mosaics.types';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

function SelectQtyChannels({
  qtyChannels,
  onChangeQtyChannels,
  error,
  helperText,
  label,
  ...props
}: {
  qtyChannels: Template;
  onChangeQtyChannels: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string;
  label: string;
} & FormControlProps) {
  const { t } = useTranslation(['mosaics', 'crud_actions', '_common']);
  const [maxQtyMosaicFlag] = useDecision(FEATURE_FLAGS_KEYS.MAX_QTY_MOSAIC);

  const template = React.useMemo(
    () =>
      TEMPLATE_STRUCTURE.map(Number).filter((value) =>
        maxQtyMosaicFlag.enabled ? value < 160 : value < 48
      ),
    [maxQtyMosaicFlag.enabled]
  );

  return (
    <FormControl variant="outlined" {...props}>
      <InputLabel id="qtyChannelsLabel">
        {label || t('mosaics:select_the_number_of_channels')}
      </InputLabel>
      <Select
        name="qtyChannels"
        aria-label="qtyChannels"
        labelId="qtyChannelsLabel"
        id="qtyChannels"
        // @ts-ignore
        onChange={onChangeQtyChannels}
        placeholder={label || t('mosaics:select_the_number_of_channels')}
        value={qtyChannels}
        label={label || t('mosaics:select_the_number_of_channels')}
        error={error}
      >
        {(template as Template[]).map((value) => (
          <MenuItem key={value} value={value}>
            {`${value} ${t('_common:channel', { count: value })}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectQtyChannels;
