import React, { useState } from 'react';
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  SvgIconTypeMap,
  Box,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Skeleton from '@mui/material/Skeleton';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { useRecoilState } from 'recoil';
import { searchPanelOrderedById } from 'atoms/search';

function SearchExpansionPanel({
  name,
  label,
  length,
  Icon,
  children,
  loading,
}: {
  name: string;
  label: string;
  length?: number;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  loading?: boolean;
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation('_common');
  const theme = useTheme();
  const [ordered, setOrdered] = useRecoilState(searchPanelOrderedById(name));

  return (
    <Accordion
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
      expanded={expanded}
      onChange={(e: React.SyntheticEvent, isExpanded: boolean) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}-results`}
        id={`${name}-accordion`}
        title={label}
      >
        {Icon && <Icon titleAccess={name} />}
        <Box flex="1 1 auto" marginLeft="0.4rem">
          <Typography>{label}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            {loading ? <Skeleton width="5.3rem" /> : `${length} ${t('results')}`}
          </Typography>
          {expanded && (
            <Box
              sx={{
                mx: 2,
              }}
            >
              <Tooltip title={!ordered ? t('_common:order_by_id') : t('_common:order_by_alpha')}>
                <IconButton
                  size="small"
                  aria-label="order"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrdered(!ordered);
                  }}
                >
                  {ordered ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails title={label}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default SearchExpansionPanel;
