import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  selectedDateFullscreenAtom,
  currentTimeFullscreenAtom,
  currentZoomChannelFullscreenBarAtom,
  selectedDateInPickerFullscreenAtom,
  startDateChannelBarFullscreenAtom,
  endDateChannelBarFullscreenAtom,
  playbackStartDateDataBarFullscreenSelector,
  eventsFullscreenAtom,
  recordingRequestsFullscreenAtom,
  recordsFullscreenAtom,
  datesRecordsFullscreenAtom,
} from 'atoms/playback.fullscreen';
import {
  ApiChannelEvent,
  ApiRecord,
  ChannelEvent,
  Record,
  RecordRequest,
} from 'types/playback.types';
import { ScaleTime, timeSecond } from 'd3';
import { useFetch } from 'hooks';

import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { useDebounce } from 'react-use';
import ApiBoundary from 'components/ApiBoundary';
import { getScaleDate } from 'components/V2CronologicBar';
import CronologicBarFullscreen from 'components/CronologicBarFullscreen/CronologicBarFullscreen';
import { Paper } from '@mui/material';
import { normalizeEventApiData, normalizeRecordApiData } from 'atoms/playback';
import Bars from './Bars';

interface CronologicBarProps {
  channelId: number;
  order?: number;
  height?: number;
  live?: boolean;
  nowLiveDate: Date;
  mosaicId?: number | null;
}

function ChannelCronologicBar({
  channelId,
  order,
  height,
  live,
  nowLiveDate,
  mosaicId = null,
}: CronologicBarProps) {
  const [selectedDate, setSelectedDate] = useRecoilState(
    selectedDateFullscreenAtom({ mosaicId, channelId })
  );
  const playbackStartDateDataBar = useRecoilValue(
    playbackStartDateDataBarFullscreenSelector({ mosaicId, channelId })
  );
  const currentTime = useRecoilValue(currentTimeFullscreenAtom(channelId));
  const [startDateFinal, setStartDateFinal] = useRecoilState(
    startDateChannelBarFullscreenAtom(channelId)
  );
  const [endDateFinal, setEndDateFinal] = useRecoilState(
    endDateChannelBarFullscreenAtom(channelId)
  );
  const selectedDateInPicker = useRecoilValue(selectedDateInPickerFullscreenAtom);
  const setMosaicSlotOrderSelected = useSetRecoilState(mosaicSlotOrderSelectedAtom);
  const [currentZoomChannelBar, setCurrentZoomChannelBar] = useRecoilState(
    currentZoomChannelFullscreenBarAtom(channelId)
  );

  useEffect(
    function changeDatesByDateSelection() {
      const scaleDate = getScaleDate(selectedDate, nowLiveDate);
      setStartDateFinal(scaleDate.START);
      setEndDateFinal(scaleDate.END);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateInPicker, live]
  );

  const onSelectDate = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      order && setMosaicSlotOrderSelected(order);
    },
    [order, setMosaicSlotOrderSelected, setSelectedDate]
  );

  const dateTime = useMemo(
    () =>
      timeSecond.offset(
        playbackStartDateDataBar ? new Date(playbackStartDateDataBar) : nowLiveDate,
        currentTime
      ),
    [currentTime, nowLiveDate, playbackStartDateDataBar]
  );

  return (
    <Paper>
      <CronologicBarFullscreen
        startDate={startDateFinal}
        endDate={endDateFinal}
        id={`${channelId}`}
        currentZoomState={currentZoomChannelBar}
        setCurrentZoomState={setCurrentZoomChannelBar}
        {...{ height }}
      >
        {({ left, height: svgHeight, xScale }) => (
          <>
            <ApiBoundary
              fallbackLoading={
                <ChannelBarsLoaging {...{ channelId, left, xScale, onSelectDate }} />
              }
            >
              <ChannelBars {...{ channelId, left, xScale, onSelectDate, nowLiveDate }} />
            </ApiBoundary>
            <CronologicBarFullscreen.XAxis
              height={svgHeight || 60}
              id={String(channelId)}
              left={left ?? 0}
              {...{ xScale, onSelectDate }}
            />
            <CronologicBarFullscreen.Indicator
              onSelectDate={setSelectedDate}
              id={String(channelId)}
              {...{ height, left, xScale, dateTime }}
            />
          </>
        )}
      </CronologicBarFullscreen>
    </Paper>
  );
}

function ChannelBars({
  channelId,
  left,
  xScale,
  onSelectDate,
  nowLiveDate,
}: {
  channelId: number;
  left?: number;
  xScale: ScaleTime<number, number, never>;
  onSelectDate: (date: Date) => void;
  nowLiveDate: Date;
}) {
  const [dates, setDates] = useState({
    startDate: nowLiveDate.toISOString(),
    endDate: nowLiveDate.toISOString(),
  });

  useDebounce(
    () => {
      const scaleStartDate = xScale.domain()[0];
      const scaleEndDate = xScale.domain()[1];
      setDates({
        startDate: scaleStartDate.toISOString(),
        endDate: scaleEndDate.toISOString(),
      });
    },
    500,
    [xScale]
  );

  const { data: records } = useFetch<ApiRecord>(`/v1/channels/${channelId}/records/optimized`, {
    params: dates,
    normalizeData: normalizeRecordApiData,
    conditionFn: () => dates.startDate !== dates.endDate,
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  const { data: events } = useFetch<ApiChannelEvent>(`/v1/channels/${channelId}/timeline`, {
    params: dates,
    normalizeData: normalizeEventApiData,
    conditionFn: () => dates.startDate !== dates.endDate,
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  const setNewEvents = useRecoilCallback(
    ({ set }) => (data: ChannelEvent[] | null) => {
      set(eventsFullscreenAtom(channelId), data);
    },
    []
  );

  useEffect(
    function loadedNewEvents() {
      setNewEvents(events?.data ?? null);
    },
    [events, setNewEvents]
  );

  const setNewRecordingRequests = useRecoilCallback(
    ({ set }) => (data: RecordRequest[] | null) => {
      set(recordingRequestsFullscreenAtom(channelId), data);
    },
    []
  );

  useEffect(
    function loadedNewRecordingRequests() {
      setNewRecordingRequests(records?.recordingRequests ?? null);
    },
    [events, records, setNewEvents, setNewRecordingRequests]
  );

  const setNewRecords = useRecoilCallback(
    ({ set }) => (data: ((currVal: Record[] | null) => Record[] | null) | Record[] | null) => {
      set(recordsFullscreenAtom(channelId), data);
    },
    []
  );

  useEffect(
    function loadedNewRecords() {
      setNewRecords(records ? records.data : null);
    },
    [records, setNewRecords, dates]
  );

  const setNewDateRecords = useRecoilCallback(
    ({ set }) => (data: ((currVal: Date[] | null) => Date[] | null) | Date[] | null) => {
      set(datesRecordsFullscreenAtom(channelId), data);
    },
    []
  );

  useEffect(
    function loadedNewDateRecords() {
      setNewDateRecords(records ? records.startDates : null);
    },
    [records, dates, setNewDateRecords]
  );

  // const mockEvents = Array.from({ length: 5 }).map((_, index) => ({
  //   date: dayjs()
  //     .subtract(index * 20, 'minutes')
  //     .toDate(),
  //   id: index.toString(),
  //   channelId,
  //   description: `Evento ${index}`,
  //   internalCode: 500,
  //   internalDesc: `Descrição interna do evento ${index}`,
  //   code: 400,
  //   customerId: 10,
  //   masterCompanyId: 100,
  // }));

  return (
    <Bars
      left={left ?? 0}
      records={records?.data}
      // events={mockEvents}
      events={events?.data}
      recordingRequests={records?.recordingRequests}
      name={`${channelId}`}
      {...{ xScale, onSelectDate }}
    />
  );
}

function ChannelBarsLoaging({
  left,
  channelId,
  xScale,
  onSelectDate,
}: {
  left?: number;
  channelId: number;
  xScale: ScaleTime<number, number, never>;
  onSelectDate: (date: Date) => void;
}) {
  const records = useRecoilValue(recordsFullscreenAtom(channelId));
  const events = useRecoilValue(eventsFullscreenAtom(channelId));
  const recordingRequests = useRecoilValue(recordingRequestsFullscreenAtom(channelId));

  return (
    <Bars
      left={left ?? 0}
      records={records ?? undefined}
      events={events ?? undefined}
      // events={mockEvents}
      recordingRequests={recordingRequests}
      name={`loading-${String(channelId)}-fullscreen`}
      loading
      {...{ xScale, onSelectDate }}
    />
  );
}

export default ChannelCronologicBar;
