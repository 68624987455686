import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function StartButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();

  const handleStartSpeedTest = () => {
    onClick();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      sx={{ mt: 2 }}
      onClick={handleStartSpeedTest}
      disabled={disabled}
    >
      {t('speed_test:start_test')}
    </Button>
  );
}
