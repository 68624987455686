import { createMosaicModalOpened } from "atoms/mosaics";
import { useRecoilState } from "recoil";

export function useCreateMosaicModal() {
  const [createModalOpened, setCreateModalOpened] = useRecoilState(createMosaicModalOpened);

  return {
    createModalOpened,
    openCreateModal: () => setCreateModalOpened(true),
    closeCreateModal: () => setCreateModalOpened(false),
  };
}