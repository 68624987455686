import { Box, FormControlLabel, Checkbox, IconButton, Tooltip } from '@mui/material';
import Permissioned from 'components/Permissioned';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionRole } from 'types/user.types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ChannelDialog from './ChannelDialog';

interface Channel {
  id: number;
  name: string;
  channelPrivate: boolean;
  status: boolean;
}

function ChannelActionsInBatch({
  channels,
  channelsChecked,
  setChannelsChecked,
  customerId,
  setTab,
  checkedAll,
  setCheckedAll,
}: {
  channels: Channel[];
  channelsChecked: { id: number; checked: boolean; status: boolean }[];
  setChannelsChecked: React.Dispatch<
    React.SetStateAction<{ id: number; checked: boolean; status: boolean }[]>
  >;
  customerId: number;
  setTab: Dispatch<SetStateAction<string>>;
  checkedAll: boolean;
  setCheckedAll: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [label, setLabel] = React.useState('channels:list');

  useEffect(
    function updateLabel() {
      const noChannelChacked = !channelsChecked.length && !checkedAll;
      const allChannelsChecked = channelsChecked.length === channels.length && checkedAll;
      const someChannelsChecked =
        !!channelsChecked.length && channelsChecked.length < channels.length;

      if (noChannelChacked) setLabel('channels:list');
      if (allChannelsChecked) setLabel('channels:all_channels');
      if (someChannelsChecked)
        setLabel(
          t('channels:selected_channels', {
            qty: channelsChecked.length,
          })
        );

      if (channelsChecked.length === 1) setLabel(t('channels:one_channel_selected'));
    },
    [checkedAll, t, channels.length, channelsChecked.length]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1,
      }}
    >
      <Permissioned role={PermissionRole.EDIT_CHANNEL}>
        <FormControlLabel
          control={
            <Checkbox
              id="checkAll"
              name="checkAll"
              checked={checkedAll}
              indeterminate={!!channelsChecked.length}
              onChange={() => setCheckedAll((_old) => !_old)}
              aria-label="check all channels"
            />
          }
          label={t(label)}
          sx={{
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        />
      </Permissioned>

      {!!channelsChecked.length && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.action.active,
            gap: 1,
            cursor: 'pointer',
          })}
        >
          <Permissioned role={PermissionRole.EDIT_CHANNEL}>
            <Tooltip title={t('_common:enable')}>
              <IconButton
                onClick={() => {
                  setOpenActivateModal((value) => !value);
                }}
                size="medium"
                aria-label="enable channels"
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          </Permissioned>
          <Permissioned role={PermissionRole.EDIT_CHANNEL}>
            <Tooltip title={t('_common:deactivate')}>
              <IconButton
                onClick={() => {
                  setOpenDeactivateModal((value) => !value);
                }}
              >
                <BlockIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Permissioned>
          {channelsChecked.length > 1 && (
            <Permissioned role={PermissionRole.REMOVE_CHANNEL}>
              <Tooltip title={t('_common:delete')}>
                <IconButton
                  onClick={() => {
                    setOpenDeleteModal((value) => !value);
                  }}
                >
                  <DeleteOutlineIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Permissioned>
          )}
        </Box>
      )}

      <ChannelDialog.ActivateInBatch
        openModal={openActivateModal}
        setOpenModal={setOpenActivateModal}
        _channelsChecked={channelsChecked}
        setChannelsChecked={setChannelsChecked}
        customerId={customerId}
        setCheckedAll={setCheckedAll}
      />
      <ChannelDialog.DeactivateInBatch
        openModal={openDeactivateModal}
        setChannelsChecked={setChannelsChecked}
        setOpenModal={setOpenDeactivateModal}
        _channelsChecked={channelsChecked}
        customerId={customerId}
        setCheckedAll={setCheckedAll}
      />
      <ChannelDialog.DeleteInBatch
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        setChannelsChecked={setChannelsChecked}
        _channelsChecked={channelsChecked}
        customerId={customerId}
        setTab={setTab}
        setCheckedAll={setCheckedAll}
      />
    </Box>
  );
}

export default ChannelActionsInBatch;
