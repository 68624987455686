import React, { useState } from 'react';
import { SegwareLogo } from 'components/core/Icons';
import { Box, Fab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ApiBoundary from 'components/ApiBoundary';
import { textDebouncedAtom } from 'atoms/selectCustomerPanel';
import { NavigateBefore } from '@mui/icons-material';
import DebounceInput from 'components/core/DebounceInput';
import { useSetRecoilState } from 'recoil';
import { useRouteMatch } from 'react-router-dom';
import routes, { mosaicViewContext } from 'helpers/routes';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { useSelectCustomerPanel } from 'hooks/customers/useSelectCustomerPanel';
import { InputContainer, Container, Header } from '../LeftPanel.styles';
import ResultsContainer, { Loading } from './ResultsContainer';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function SelectCustomerPanel() {
  const match = useRouteMatch<{ mosaicId: string }>(mosaicViewContext);
  const matchCreate = useRouteMatch([
    routes.mosaic.createChannel(':mosaicId', ':slotId', ':customerId'),
    routes.mosaic.createCustomer(':mosaicId', ':slotId'),
  ]);
  const classes = useStyles();
  const { t } = useTranslation('select_customer');
  const { openChannelSelection } = useSelectChannelPanel();
  const { closeCustomerSelection } = useSelectCustomerPanel();
  const [inputText, setInputText] = useState('');
  const setTextDebounced = useSetRecoilState(textDebouncedAtom);

  if (!match) {
    closeCustomerSelection();
    return null;
  }

  return (
    <Container role="region" aria-labelledby="select-customer-panel-title">
      <Header id="select-customer-panel-title">
        <SegwareLogo className={classes.segwareLogo} />
        <Box>
          <Typography variant="h6">{t('select_customer')}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t('you_need_select_customer_create_new_channel')}
          </Typography>
        </Box>
      </Header>
      <InputContainer>
        <Fab
          size="small"
          onClick={openChannelSelection}
          aria-label="close"
          disabled={matchCreate?.isExact}
        >
          <NavigateBefore />
        </Fab>
        <DebounceInput
          placeholder={t('search_customers')}
          autoFocus
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          setValue={setInputText}
          onDebounce={() => setTextDebounced(inputText)}
        />
      </InputContainer>
      <ApiBoundary fallbackLoading={<Loading />}>
        <ResultsContainer mosaicId={Number(match.params.mosaicId)} />
      </ApiBoundary>
    </Container>
  );
}

export default SelectCustomerPanel;
