import React from 'react';
import { useFetch } from 'hooks';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { selectedAtom, textDebouncedAtom } from 'atoms/selectCustomerPanel';
import routes from 'helpers/routes';
import { EmptyCustomer } from 'components/Customer';
import { AddCircle, PersonOutlineOutlined } from '@mui/icons-material';
import { Box, IconButton, Link as MuiLink, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { useTranslation } from 'react-i18next';
import { mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { Link } from 'react-router-dom';
import { CustomerCard } from 'components/ItemsCard';
import { Customer } from 'types/customer.types';
import { useSelectCustomerPanel } from 'hooks/customers/useSelectCustomerPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultContainer: {
      backgroundColor: theme.palette.background.default,
      padding: '16px',
      marginTop: '16px',
      overflow: 'auto',
      flex: '1 1 auto',
    },
  })
);

function Header({
  selected,
  length,
  setSelected,
  mosaicId,
  orderSelected,
}: {
  selected: number | null;
  length?: number;
  setSelected: SetterOrUpdater<number | null>;
  mosaicId: number;
  orderSelected: number;
}) {
  const { t } = useTranslation(['customers', 'search_bar', 'select_customer']);

  if (selected) {
    return (
      <Box display="flex" alignItems="center">
        <PersonOutlineOutlined titleAccess="channel" />
        <Box marginLeft="0.4rem">
          <Typography>{t('select_customer:selected_customer')}</Typography>
        </Box>
        <Box marginLeft="0.4rem">
          (
          <MuiLink
            color="textPrimary"
            underline="always"
            onClick={() => setSelected(null)}
            sx={{ cursor: 'pointer' }}
            component={Link}
            to={routes.mosaic.view(mosaicId)}
          >
            {t('select_customer:change')}
          </MuiLink>
          )
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" alignItems="center">
      <PersonOutlineOutlined titleAccess="channel" />
      <Box flex="1 1 auto" marginLeft="0.4rem">
        <Typography>{t('customers')}</Typography>
      </Box>

      <Typography variant="body2" color="textSecondary">
        {`${length} ${t('search_bar:results')}`}
      </Typography>
      <Permissioned role={PermissionRole.INSERT_CLIENT}>
        <Box marginLeft="4px">
          <IconButton
            size="small"
            aria-label="create customer"
            component={Link}
            to={routes.mosaic.createCustomer(mosaicId, orderSelected)}
          >
            <AddCircle />
          </IconButton>
        </Box>
      </Permissioned>
    </Box>
  );
}

function ResultsContainer({ mosaicId }: { mosaicId: number }) {
  const classes = useStyles();
  const orderSelected = useRecoilValue(mosaicSlotOrderSelectedAtom);
  const text = useRecoilValue(textDebouncedAtom);
  const [selected, setSelected] = useRecoilState(selectedAtom);
  const { data: customerResults } = useFetch<Customer[]>('/v1/clients', {
    params: {
      text,
      limit: text ? undefined : 8,
    },
  });
  const { closeCustomerSelection } = useSelectCustomerPanel();

  if (!orderSelected) {
    closeCustomerSelection();
    return null;
  }

  if (!customerResults?.length && !text)
    return <EmptyCustomer route={routes.mosaic.createCustomer(mosaicId, orderSelected)} />;

  return (
    <Paper className={classes.resultContainer}>
      <Header
        {...{ selected, setSelected, mosaicId, orderSelected }}
        length={customerResults?.length}
      />
      {customerResults
        ?.filter((customer) => (selected ? customer.id === selected : true))
        .map((customer) => (
          <CustomerCard
            key={customer.id}
            route={routes.mosaic.createChannel(mosaicId, orderSelected, customer.id)}
            onClick={() => setSelected(customer.id)}
            selected={selected === customer.id}
            {...{ customer }}
          />
        ))}
    </Paper>
  );
}

export function Loading() {
  const classes = useStyles();

  return (
    <Paper className={classes.resultContainer}>
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CustomerCard key={i} loading />
      ))}
    </Paper>
  );
}

export default ResultsContainer;
