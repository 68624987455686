import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { intelbrasConnectionTestsResponse } from 'atoms/intelbras';
import { TestBatchDeviceIcon } from 'components/TestingDeviceFeedback/TestingDeviceFeedback';
import InfoItem from 'components/InfoItem';
import { CustomizedResponseCodeEnum } from 'types/channels.types';
import { IntelbrasChannel } from 'types/intelbras.types';
import IntelbrasFormDialog from './IntelbrasFormDialog';

interface PreviewChannelInterface {
  channel: IntelbrasChannel;
  keyChannel: number;
  customerId: string;
}
export default function PreviewIntelbrasChannel({
  channel,
  keyChannel,
  customerId,
}: PreviewChannelInterface) {
  const { t } = useTranslation();
  const [updateConfirmModalOpen, setUpdateConfirmModalOpen] = React.useState(false);
  const testedChannels = useRecoilValue(intelbrasConnectionTestsResponse);
  const [status, setStatus] = React.useState<CustomizedResponseCodeEnum>();

  React.useEffect(
    function handleWithDeviceStatus() {
      const getByStatus = testedChannels.find((value) => value.uuid === channel.uuid);
      setStatus(getByStatus?.success || 0);
    },
    [channel.uuid, testedChannels]
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        component="div"
        justifyContent="space-between"
        m={2}
        p={2}
      >
        <Box>
          <Box mb={4}>
            <InfoItem label={t('channels:channel_name')}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 6,
                }}
              >
                <Typography>{channel.name}</Typography>
                <TestBatchDeviceIcon customizedCode={status} />
              </Box>
            </InfoItem>
          </Box>
          <Box display="flex" gap={2}>
            <InfoItem label={t('channels:channel_group')}>{channel.channelGroup?.name}</InfoItem>
            <InfoItem label={t('channels:recording_type')}>
              {t(`channels:recording_type_${channel.recordingType}`)}
            </InfoItem>
          </Box>
        </Box>
        <Box>
          <Box mb={4}>
            <Box display="flex" alignItems="start" gap={6}>
              <InfoItem label="Serial">{channel.serialNumber}</InfoItem>
              <InfoItem label={t('channels:port')}>{t(String(channel.port))}</InfoItem>

              <IconButton
                aria-label="edit"
                onClick={() => {
                  setUpdateConfirmModalOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoItem label={t('_common:channel')}>{channel.p2pChannel}</InfoItem>
            </Box>
          </Box>
        </Box>

        <IntelbrasFormDialog
          open={updateConfirmModalOpen}
          channel={channel}
          handleClose={() => {
            setUpdateConfirmModalOpen(false);
          }}
          keyChannel={keyChannel}
          customerId={customerId}
        />
      </Box>
      <Divider />
    </>
  );
}
