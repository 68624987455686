import { Box, Menu, MenuItem, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { userInfoSelector } from 'atoms/auth';
import { Mode, themeSelectedAtom } from 'atoms/config';
import { Avatar } from 'components/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import { loggedUserAtom } from 'atoms/users';
import { useUserCRUD } from 'hooks';
import { Encrypted, MoveItem, Routline } from 'components/core/Icons';
import { useLogout } from 'hooks/useLogout';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.action.hover : 'rgba(255, 255, 255, 0.38)',
    '& .MuiTypography-root': {
      color: 'rgba(0, 0, 0, 0.38)!important',
      fontWeight: 500,
    },
    cursor: 'pointer',
  },
}));

function MainMenuAvatar() {
  const classes = useStyles();
  const userInfo = useRecoilValue(userInfoSelector);
  const logout = useLogout();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [themeSelected, setThemeSelected] = useRecoilState(themeSelectedAtom);
  const { t } = useTranslation('main-left-menu');
  const userLogged = useRecoilValue(loggedUserAtom);
  const { changePasswordUser } = useUserCRUD();
  const openUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeThemeSelected = () => {
    const colorSwitch = themeSelected === Mode.DARK ? Mode.LIGHT : Mode.DARK;
    setThemeSelected(colorSwitch);
  };

  const handleChangePassword = () => {
    userLogged && changePasswordUser(userLogged.id);
  };

  return (
    <>
      <Box
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={openUserMenuClick}
        textAlign="center"
        paddingTop="16px"
      >
        <Avatar
          className={classes.avatar}
          title={userInfo?.name}
          name={userInfo?.name}
          size="33px"
        />
      </Box>
      <Menu
        id="user-menu"
        {...{ anchorEl }}
        onClose={handleCloseUserMenu}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleChangePassword}>
          <Encrypted sx={{ mr: 2 }} />
          <Typography variant="body1">{t('main-left-menu:change_password')}</Typography>
        </MenuItem>

        <MenuItem onClick={handleChangeThemeSelected}>
          <Routline sx={{ mr: 2 }} />
          <Typography variant="body1">{t('main-left-menu:switch_to_light_mode')}</Typography>
          <Switch checked={themeSelected === Mode.LIGHT} sx={{ ml: 2 }} />
        </MenuItem>
        <MenuItem onClick={() => logout()}>
          <MoveItem sx={{ mr: 2 }} />
          <Typography variant="body1">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default MainMenuAvatar;
