import React from 'react';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BatchTestResponse,
  CustomizedResponseCodeEnum,
  ChannelConnectionResponse,
  TestedUrlsKey,
  BatchChannels,
} from 'types/channels.types';
import TestingDeviceFeedback, {
  TestBatchDeviceIcon,
  TestBatchDeviceText,
} from 'components/TestingDeviceFeedback/TestingDeviceFeedback';
import { ResponseMessageTooltip } from 'components/ResponseMessageTooltip/ResponseMessageTooltip';
import { useTestingChannel } from 'components/forms/hooks/useTestingChannels';
import { testedUrlsResultsAtom } from 'atoms/batchChannel';
import { useRecoilState, useSetRecoilState, useRecoilValue, useRecoilCallback } from 'recoil';
import { intelbrasConnectionTestsResponse } from 'atoms/intelbras';
import { IntelbrasBody, IntelbrasTestConnectionResponse } from 'types/intelbras.types';
import { transformBatchChannelsInObject } from 'helpers/transformBatchChannelsInObject';
import { useTestChannelConnection } from 'hooks/channels/useTestChannelConnection';
import { HikTestConnectionResponse, HikvisionBody } from 'types/hikvision.types';
import { testedHikvisonChannelsAtom } from 'atoms/hikvision';

interface SingleProps {
  url: string;
  ariaLabel: string;
  isDisabled: boolean;
}

interface BatchProps extends SingleProps {
  qtyChannels: number;
  name: string;
  atomKey: TestedUrlsKey;
}

interface P2PChannelProps {
  data: HikvisionBody | IntelbrasBody;
  isDisabled: boolean;
  batchKey: string;
}

interface P2PBatchInterface {
  data: IntelbrasBody;
  isDisabled: boolean;
}

// TODO: this component will be deleted in future
// now we are using ChannelForm composition...
function Single({ url, ariaLabel, isDisabled }: SingleProps) {
  const [requestResponse, setRequestResponse] = React.useState<ChannelConnectionResponse>();
  const [testing, setTesting] = React.useState(false);
  const { testingSingleChannel } = useTestingChannel();
  const { t } = useTranslation('channels');

  const handleClick = React.useCallback(async () => {
    setRequestResponse(undefined);
    setTesting(true);
    const data = await testingSingleChannel({ url });
    data && setRequestResponse(data);
    setTesting(false);
  }, [testingSingleChannel, url]);

  React.useEffect(() => setRequestResponse(undefined), [url]);

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={ariaLabel}
        disabled={isDisabled || testing}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>
      {requestResponse && <TestingDeviceFeedback customizedCode={requestResponse.success} />}
      {requestResponse?.message && <ResponseMessageTooltip message={requestResponse.message} />}
    </Box>
  );
}

function Batch({ url, ariaLabel, qtyChannels, name, atomKey, isDisabled }: BatchProps) {
  const [response, setResponse] = useRecoilState<BatchTestResponse>(testedUrlsResultsAtom(atomKey));
  const [testing, setTesting] = React.useState(false);
  const { testingBatchChannels } = useTestingChannel();
  const [messageResponse, setMessageResponse] = React.useState<string | undefined>();
  const { t } = useTranslation('channels');

  const channels = React.useMemo<BatchChannels[]>(
    () =>
      Array.from({ length: qtyChannels }).map((_, index) => ({
        url: url.replace('[amount]', String(index + 1)),
        name: `${name} ${index + 1}`,
      })),
    [name, qtyChannels, url]
  );

  const handleClick = React.useCallback(async () => {
    setResponse({});
    setTesting(true);
    const data = await testingBatchChannels({ urls: channels });
    data && setResponse(transformBatchChannelsInObject(data));
    setTesting(false);
  }, [channels, setResponse, testingBatchChannels]);

  React.useEffect(() => {
    if (response.message) setMessageResponse(response.message);
  }, [response.message]);

  React.useEffect(() => setResponse({}), [setResponse, url, qtyChannels]);

  const existTests = Object.keys(response).length > 0 && url;

  return (
    <Box sx={{ marginTop: 2 }}>
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={ariaLabel}
        disabled={isDisabled || testing}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>

      {existTests
        ? channels.map((channel, index) => (
            <Grid
              key={channel.name}
              component={Paper}
              container
              spacing={1}
              alignItems="center"
              sx={{
                backgroundColor: 'background.default',
                padding: 1,
                my: 1,
              }}
            >
              <Grid item xs={1}>
                <Box gap={1} pr={1} display="flex">
                  <TestBatchDeviceIcon customizedCode={response[channel.url]} />
                  {messageResponse && response[channel.url] && (
                    <ResponseMessageTooltip message={messageResponse} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body1">
                  {t(
                    `${TestBatchDeviceText({
                      customizedCode: response[channel.url],
                    })}`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3.5} title={channel.name}>
                <Typography variant="body2">{t('channels:channel_name')}</Typography>
                <Typography variant="body1" noWrap>
                  {channel.name}
                </Typography>
              </Grid>
              <Grid item xs={6} title={channel.url}>
                <Typography variant="body2">URL</Typography>
                <Typography variant="body1" noWrap>
                  {channel.url}
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
    </Box>
  );
}

function Intelbras({ data, isDisabled, batchKey }: P2PChannelProps) {
  const [testing, setTesting] = React.useState<boolean>();
  const [response, setResponse] = React.useState<IntelbrasTestConnectionResponse>();
  const testedChannels = useRecoilValue(intelbrasConnectionTestsResponse);
  const { t } = useTranslation('channels');
  const { testingIntelbrasChannel } = useTestingChannel();
  const editChannelTestedAtom = useRecoilCallback(
    ({ set }) => async (
      channelTested: IntelbrasTestConnectionResponse,
      success: CustomizedResponseCodeEnum
    ) => {
      set(intelbrasConnectionTestsResponse, (channels) =>
        channels.map((item) => {
          if (item.uuid === channelTested.uuid) {
            return {
              ...channelTested,
              success,
            };
          }
          return item;
        })
      );
    }
  );

  const values = React.useMemo(() => data as IntelbrasBody, [data]);

  const handleClick = React.useCallback(async () => {
    setTesting(true);
    setResponse(await testingIntelbrasChannel({ values }));
    setTesting(false);
  }, [values, testingIntelbrasChannel]);

  React.useEffect(
    function cleanupResponse() {
      setResponse(undefined);
    },
    [values.serial, values.user, values.channel, values.password]
  );
  React.useEffect(
    function updateBatchPreviewStatus() {
      const channelTested = testedChannels.find((e) => e.uuid === batchKey);
      if (!channelTested || !response) return;
      if (channelTested.success === response.success) return;
      editChannelTestedAtom(channelTested, response.success);
    },
    [batchKey, editChannelTestedAtom, response, testedChannels]
  );

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={t('test')}
        disabled={isDisabled || testing}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>
      {response && <TestingDeviceFeedback customizedCode={response.success} />}
      {response?.message && <ResponseMessageTooltip message={response.message} />}
    </Box>
  );
}

function Hikvision({ data, isDisabled, batchKey }: P2PChannelProps) {
  const [testing, setTesting] = React.useState(false);
  const { testingHikvisionChannel } = useTestingChannel();
  const [response, setResponse] = React.useState<HikTestConnectionResponse>();
  const { t } = useTranslation('channels');

  const handleClick = React.useCallback(async () => {
    setTesting(true);
    const responseTesting = await testingHikvisionChannel({
      data,
    });
    responseTesting && setResponse(responseTesting);
    setTesting(false);
  }, [testingHikvisionChannel, data]);

  React.useEffect(() => setResponse(undefined), [data.serial]);

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label="test p2p hikvision"
        disabled={isDisabled || testing}
      >
        {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>

      {response && <TestingDeviceFeedback customizedCode={response.success} />}
      {response?.message && <ResponseMessageTooltip message={response.message} />}
    </Box>
  );
}

function BatchIntelbras({ data, isDisabled }: P2PBatchInterface) {
  const [testing, setTesting] = React.useState<boolean>();
  const [response, setResponse] = React.useState<IntelbrasTestConnectionResponse[]>();
  const setResultOfTestConnection = useSetRecoilState(intelbrasConnectionTestsResponse);

  const { testingBatchIntelbras } = useTestingChannel();
  const { t } = useTranslation('channels');

  const handleTest = React.useCallback(async () => {
    setTesting(true);

    setResponse(
      await testingBatchIntelbras(
        Array.from({ length: data.channel }).map((_, index) => ({
          name: `${data.name} ${index + 1}`,
          serial: data.serial,
          channel: index + 1,
          user: data.user,
          password: data.password,
          port: data.port,
        }))
      )
    );

    setTesting(false);
  }, [data, testingBatchIntelbras]);

  React.useEffect(() => {
    setResponse(undefined);
  }, [data.channel, data.password, data.serial, data.user]);

  React.useEffect(() => {
    if (response) {
      setResultOfTestConnection(response);
    }
  }, [response, setResultOfTestConnection]);

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%">
        <Button
          onClick={handleTest}
          variant="contained"
          aria-label={t('channels:test')}
          disabled={isDisabled || testing}
        >
          {testing ? <CircularProgress size="1.6rem" /> : t('channels:test')}
        </Button>

        {response
          ? response.map((intelbrasChannel) => (
              <Grid
                key={`${intelbrasChannel.serial}/${intelbrasChannel.channel}`}
                aria-label={`${intelbrasChannel.name}`}
                component={Paper}
                container
                spacing={1}
                data-testid="gridtests"
                alignItems="center"
                sx={{
                  backgroundColor: 'background.default',
                  padding: 1,
                  my: 1,
                }}
              >
                <Grid item xs={1}>
                  <Box gap={1} pr={1} display="flex">
                    <TestBatchDeviceIcon
                      customizedCode={
                        response.find((e, channel) => channel + 1 === intelbrasChannel.channel)
                          ?.success
                      }
                    />
                    <ResponseMessageTooltip
                      message={t(
                        `${
                          response.find((e, channel) => channel + 1 === intelbrasChannel.channel)
                            ?.message
                        }`
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography variant="body2">Status</Typography>
                  <Typography variant="body1">
                    {t(
                      `${TestBatchDeviceText({
                        customizedCode: response.find((e) => e.channel === intelbrasChannel.channel)
                          ?.success,
                      })}`
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2.5} title={intelbrasChannel.name}>
                  <Typography variant="body2">{t('channels:channel_name')}</Typography>
                  <Typography variant="body1" noWrap>
                    {intelbrasChannel.name}
                  </Typography>
                </Grid>
                <Grid item xs={2.5} title={intelbrasChannel.serial}>
                  <Typography variant="body2">Serial</Typography>
                  <Typography variant="body1" noWrap>
                    {intelbrasChannel.serial}
                  </Typography>
                </Grid>
                <Grid item xs={2.5} title={String(intelbrasChannel.channel)}>
                  <Typography variant="body2">{t('_common:channel')}</Typography>
                  <Typography variant="body1" noWrap>
                    {intelbrasChannel.channel}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : null}
      </Box>
    </Box>
  );
}

interface BatchHikvisionProps {
  name: string;
  serialNumber: string;
  qtyChannels: number;
  privateKey: string;
  disabled: boolean;
}

function BatchHikvision({
  name,
  disabled,
  privateKey,
  qtyChannels,
  serialNumber,
}: BatchHikvisionProps) {
  const { t } = useTranslation();
  const { testingBatchHikvision } = useTestChannelConnection();
  const [submitting, setSubmitting] = React.useState<boolean>();
  const [testedChannels, setTestedChannels] = React.useState<HikTestConnectionResponse[]>([]);
  const setResultOfTestConnection = useSetRecoilState(testedHikvisonChannelsAtom);

  const handleClick = React.useCallback(
    async function onTestChannels() {
      setSubmitting(true);

      setTestedChannels(
        await testingBatchHikvision(
          Array.from({ length: qtyChannels }).map((_, index) => ({
            channel: index + 1,
            serial: serialNumber,
            key: privateKey,
            name: `${name} ${index + 1}`,
          }))
        )
      );

      setSubmitting(false);
    },
    [name, privateKey, qtyChannels, serialNumber, testingBatchHikvision]
  );

  React.useEffect(() => {
    if (testedChannels) {
      setResultOfTestConnection(testedChannels);
    }
  }, [testedChannels, setResultOfTestConnection]);

  const existData = React.useMemo(() => {
    if (name.length && serialNumber.length && privateKey.length) {
      return true;
    }

    return false;
  }, [name, privateKey, serialNumber]);

  React.useEffect(() => {
    setTestedChannels([]);
  }, [name, privateKey, serialNumber, qtyChannels]);
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        aria-label={t('channels:test')}
        disabled={!existData || disabled || submitting}
        onClick={handleClick}
        sx={{
          marginBottom: 1,
        }}
      >
        {submitting ? <CircularProgress size="1.6rem" /> : t('channels:test')}
      </Button>

      {testedChannels?.length
        ? testedChannels.map((channel) => (
            <Grid
              key={`${channel.serialNumber}/${channel.p2pChannel}`}
              aria-label={`${channel.name}`}
              component={Paper}
              container
              data-testid="gridtests"
              alignItems="center"
              sx={{
                backgroundColor: 'background.default',
                padding: 1,
                my: 1,
                width: '100%',
              }}
            >
              <Grid item xs={1}>
                <Box gap={1} pr={1} display="flex">
                  <TestBatchDeviceIcon customizedCode={channel.success} />
                  <ResponseMessageTooltip message={t(`${channel.message}`)} />
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body1">
                  {t(
                    `${TestBatchDeviceText({
                      customizedCode: channel.success,
                    })}`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2.5} title={channel.name}>
                <Typography variant="body2">{t('channels:channel_name')}</Typography>
                <Typography variant="body1" noWrap>
                  {channel.name}
                </Typography>
              </Grid>
              <Grid item xs={2.5} title={channel.serialNumber}>
                <Typography variant="body2">Serial</Typography>
                <Typography variant="body1" noWrap>
                  {channel.serialNumber}
                </Typography>
              </Grid>
              <Grid item xs={2.5} title={String(channel.p2pChannel)}>
                <Typography variant="body2">{t('_common:channel')}</Typography>
                <Typography variant="body1" noWrap>
                  {channel.p2pChannel}
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
    </Box>
  );
}

const ChannelTestButton = {
  Single,
  Batch,
  Intelbras,
  Hikvision,
  BatchIntelbras,
  BatchHikvision,
};

export default ChannelTestButton;
