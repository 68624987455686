/* eslint-disable @typescript-eslint/indent */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  List,
  Divider,
  Typography,
  Fab,
  Box,
  Badge,
  Theme,
  ListItemButton,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { userSearchTextAtom } from 'atoms/users';
import { Add } from '@mui/icons-material';
import { Avatar } from 'components/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routes from 'helpers/routes';
import DebounceInput from 'components/core/DebounceInput';
import { useRecoilState } from 'recoil';
import Skeleton from '@mui/material/Skeleton';
import { useHaveScroll } from 'hooks';
import { User } from 'types/user.types';
import BadgeActiveContent from 'components/BadgeActiveContent';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    padding: '4px 0',
    flexDirection: 'initial',
    alignItems: 'center',
  },
  containerList: {
    backgroundColor: theme.palette.background.default,
    flex: '0 0 50%',
    padding: '8px',
    overflow: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    overflow: 'auto',
    height: '100%',
    marginTop: '8px',
    padding: '0',
  },
}));

function UserList(
  props: React.PropsWithChildren<{
    loading: true;
  }>
): React.ReactElement | null;
function UserList(
  props: React.PropsWithChildren<{
    items: User[];
    selected: number;
    setSelected: React.Dispatch<React.SetStateAction<number>>;
  }>
): React.ReactElement | null;
function UserList({
  items,
  selected,
  setSelected,
  loading,
}: {
  items?: User[];
  selected?: number;
  setSelected?: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation(['users', 'confirm_modal']);

  const listaRef = useRef(null);
  const { haveScroll } = useHaveScroll(listaRef, [items]);

  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(userSearchTextAtom);
  const [searchText, setSearchText] = useState(searchTextDebounced);
  const inputRef = useRef();

  // itemsComputed to mount skeleton loading
  const itemsComputed:
    | User[]
    | {
        id: number;
        name?: string;
        permission?: string;
        status?: boolean;
      }[] = useMemo(
    () =>
      items ||
      Array.from({ length: 5 }).map((x, id) => ({
        id,
      })),
    [items]
  );

  /* O foco no campo do search deve continuar mesmo após o loading aparecer na tela */
  useEffect(() => {
    // @ts-ignore
    inputRef.current?.focus();
  }, [items]);

  return (
    <Box className={classes.containerList}>
      <Box className={classes.header}>
        {(haveScroll || searchTextDebounced) && (
          <Box flex="0 0 60%">
            <DebounceInput
              {...{ inputRef }}
              placeholder={t('crud_actions:search')}
              autoFocus
              value={searchText}
              disabled={loading}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              setValue={setSearchText}
              onDebounce={() => {
                setSearchTextDebounced(searchText);
              }}
              fullWidth
            />
          </Box>
        )}
        <Box flex="1 1 auto" marginLeft="8px">
          <Typography color="textSecondary">{t('all_users')}</Typography>
        </Box>
        <Box flex="0 0 auto">
          <Fab
            color="primary"
            size="small"
            component={Link}
            to={routes.settings.user.create}
            aria-label="add"
          >
            <Add />
          </Fab>
        </Box>
      </Box>
      <List ref={listaRef} className={classes.list} aria-label={loading ? 'loading' : 'User list'}>
        {itemsComputed && itemsComputed.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography color="textSecondary">{t('crud_actions:no_results')}</Typography>
          </Box>
        ) : (
          itemsComputed.map((item) => (
            <span key={item.id}>
              <Divider />
              <ListItem onClick={() => setSelected && setSelected(item.id)}>
                <ListItemButton
                  sx={{ padding: '16px' }}
                  selected={selected === item.id}
                  aria-labelledby={`customer-group-list-item-${item.id}`}
                >
                  {item.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      badgeContent={<BadgeActiveContent active={!!item.status} />}
                    >
                      <Avatar
                        selected={selected === item.id}
                        title={item.name}
                        name={item.name}
                        fade={!item.status}
                      />
                    </Badge>
                  ) : (
                    <Skeleton variant="circular">
                      <Avatar />
                    </Skeleton>
                  )}

                  <Box marginLeft="16px" flex="1 1 auto">
                    <Typography id={`customer-group-list-item-${item.id}`}>
                      {item.name || <Skeleton />}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.permission ? t(`user_permission_${item.permission}`) : <Skeleton />}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            </span>
          ))
        )}
      </List>
    </Box>
  );
}

export default UserList;
