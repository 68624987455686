import React from 'react';
import {
  FormControl,
  SelectChangeEvent,
  Radio,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProtocolType } from 'types/channels.types';
// import { useDecision } from '@optimizely/react-sdk';
// import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

function SelectProtocol({
  onChange,
  value,
}: {
  onChange: (event: SelectChangeEvent<ProtocolType>) => void;
  value: ProtocolType | null;
}) {
  // const [RTMPProtocol] = useDecision(FEATURE_FLAGS_KEYS.RTMP_PROTOCOL_INTEGRATION);
  const { t } = useTranslation('channels');
  const protocols = ['RTSP', 'RTMP'];

  return (
    <FormControl
      sx={{
        width: 400,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          textTransform: 'none',
        }}
      >
        {t('channels:select_the_protocol')}
      </Typography>
      <RadioGroup
        row
        aria-labelledby="select-protocol"
        name="select-protocol"
        value={value}
        onChange={onChange}
      >
        {protocols.map((protocol) => (
          <FormControlLabel
            key={protocol}
            value={protocol}
            control={<Radio />}
            label={`${t('_common:protocol')} ${protocol}`}
            sx={{
              textTransform: 'none',
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
export default SelectProtocol;
