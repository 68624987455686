import React from 'react';
import { Typography, CardContent, ListItem, CardActionArea, Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { Card } from './ItemsCard.styles';

const useStyles = makeStyles((theme) =>
  createStyles<'card', { selected?: boolean }>({
    card: ({ selected }) => ({
      border: selected ? `1px solid ${theme.palette.primary.dark}` : '1px solid transparent',
    }),
  })
);

interface Customer {
  id: number;
  companyName: string;
  tradeName: string;
  status: boolean;
}

function CustomerCard(
  props: React.PropsWithChildren<{
    customer: Customer;
    onClick?: React.MouseEventHandler;
    route?: string;
    selected?: boolean;
  }>
): React.ReactElement | null;
function CustomerCard(
  props: React.PropsWithChildren<{ loading: boolean }>
): React.ReactElement | null;
function CustomerCard({
  loading,
  customer,
  onClick,
  route,
  selected,
}: {
  loading?: boolean;
  customer?: Customer;
  route?: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler;
}) {
  const classes = useStyles({ selected });
  const listItemProps = loading ? {} : { component: Link, to: route, onClick };
  const { t } = useTranslation();
  return (
    <ListItem {...listItemProps}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {loading ? <Skeleton /> : customer?.tradeName}
                </Typography>
                <Typography color="textSecondary">
                  {loading ? <Skeleton /> : customer?.companyName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {loading ? <Skeleton /> : `ID ${String(customer?.id).padStart(6, '0')}`}
                </Typography>
              </Box>
              {!loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {!customer?.status && (
                    <Tooltip title={t('customers:inactive') || ''}>
                      <PersonOffIcon />
                    </Tooltip>
                  )}
                </Box>
              ) : null}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </ListItem>
  );
}

export default CustomerCard;
