import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  List,
  Divider,
  IconButton,
  Typography,
  Fab,
  Box,
  Theme,
  ListItemButton,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  customerGroupInEditionAtom,
  customerGroupSearchTextAtom,
  customerGroupcreateModalOpenedAtom,
} from 'atoms/customerGroup';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Delete, Add, Edit } from '@mui/icons-material';
import { ConfirmModal } from 'components/core';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilState } from 'recoil';
import DebounceInput from 'components/core/DebounceInput';
import Skeleton from '@mui/material/Skeleton';
import { useHaveScroll } from 'hooks';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { useCustomerGroupApi } from 'hooks/customerGroup/useCustomersApi';
import { CustomerGroup } from 'types/customerGroup.types';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    padding: '4px 0',
    flexDirection: 'initial',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      width: '30%',
    },
  },
  addButton: {
    marginLeft: 'auto',
  },
  containerList: {
    backgroundColor: theme.palette.background.default,
    flex: '0 0 100%',
    padding: '8px',
    overflow: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    overflow: 'auto',
    height: '100%',
    marginTop: '8px',
    padding: '0',
  },
}));

function CustomerGroupList(
  props: React.PropsWithChildren<{
    loading: true;
  }>
): React.ReactElement | null;
function CustomerGroupList(
  props: React.PropsWithChildren<{
    items: CustomerGroup[];
    selected: number;
    setSelected: React.Dispatch<React.SetStateAction<number>>;
  }>
): React.ReactElement | null;
function CustomerGroupList({
  items,
  selected,
  setSelected,
  loading,
}: {
  items?: CustomerGroup[];
  selected?: number;
  setSelected?: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation(['customer_groups', 'confirm_modal']);

  const listaRef = useRef(null);
  const { haveScroll } = useHaveScroll(listaRef, [items]);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [idInDeletion, setIdInDeletion] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(customerGroupSearchTextAtom);
  const [searchText, setSearchText] = useState(searchTextDebounced);

  const { deleteCustomerGroup } = useCustomerGroupApi();
  const setCustomerGroupInEdition = useSetRecoilState(customerGroupInEditionAtom);
  const setCreateModalOpened = useSetRecoilState(customerGroupcreateModalOpenedAtom);
  const inputRef = useRef<HTMLInputElement>();

  // itemsComputed to mount skeleton loading
  const itemsComputed: CustomerGroup[] | { id: number; name?: string }[] = useMemo(() => {
    if (!items) {
      return Array.from({ length: 5 }).map((x, id) => ({
        id,
      }));
    }
    return items;
  }, [items]);

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteCustomerGroup(idInDeletion);
    setDeleteConfirmModalOpen(false);
    setIsDeleting(false);
  };

  const handlePutOnDeletion = (id: number) => {
    setIdInDeletion(id);
    setDeleteConfirmModalOpen(true);
  };

  const handleEdit = (customerGroup: CustomerGroup) => {
    setCustomerGroupInEdition(customerGroup);
    setCreateModalOpened(true);
  };

  /* O foco no campo do search deve continuar mesmo após o loading aparecer na tela */
  useEffect(() => {
    inputRef.current?.focus();
  }, [items]);

  return (
    <Box className={classes.containerList}>
      <Box className={classes.header}>
        {(haveScroll || !!searchTextDebounced) && (
          <DebounceInput
            {...{ inputRef }}
            placeholder={t('crud_actions:search')}
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            setValue={setSearchText}
            onDebounce={() => setSearchTextDebounced(searchText)}
          />
        )}
        <Permissioned role={PermissionRole.INSERT_CLIENT_GROUP}>
          <Fab
            className={classes.addButton}
            color="primary"
            size="small"
            onClick={() => setCreateModalOpened(true)}
            aria-label="add"
          >
            <Add />
          </Fab>
        </Permissioned>
      </Box>
      <List
        ref={listaRef}
        className={classes.list}
        aria-label={loading ? 'loading' : 'Customer group list'}
      >
        {itemsComputed && itemsComputed.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography color="textSecondary">{t('crud_actions:no_results')}</Typography>
          </Box>
        ) : (
          itemsComputed.map((item) => (
            <span key={item.id}>
              <Divider />
              <ListItem
                onClick={() => setSelected && setSelected(item.id)}
                aria-labelledby={`customer-group-list-item-${item.id}`}
              >
                <ListItemButton sx={{ padding: '16px' }} selected={selected === item.id}>
                  <Box flex="1 1 auto">
                    <Typography id={`customer-group-list-item-${item.id}`}>
                      {item.name || <Skeleton />}
                    </Typography>
                  </Box>
                  <Permissioned role={PermissionRole.EDIT_CLIENT_GROUP}>
                    <IconButton
                      disabled={loading}
                      onClick={() => handleEdit(item as CustomerGroup)}
                      aria-label="edit"
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  </Permissioned>
                  <Permissioned role={PermissionRole.REMOVE_CLIENT_GROUP}>
                    <IconButton
                      disabled={loading}
                      onClick={() => handlePutOnDeletion(item.id)}
                      aria-label="delete"
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </Permissioned>
                </ListItemButton>
              </ListItem>
            </span>
          ))
        )}
      </List>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        description=""
        title={t('want_delete')}
      />
    </Box>
  );
}

export default CustomerGroupList;
