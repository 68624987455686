import styled from 'styled-components';
import { Paper } from '@mui/material';

export const Container = styled(Paper)`
  padding: 8px;
  margin: 24px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
