import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import { useFetch, useQuery } from 'hooks';
import { Box } from '@mui/material';
import { ChannelHeader } from 'components/Channel/ChannelHeader';
import { HikvisionForm } from 'components/forms/channels';
import routes from 'helpers/routes';
import { useHikVisionApi } from 'hooks/channels/manufactures/useHikvisionApi';
import { HikVisionChannel, normalizeHikvisionChannel } from 'types/hikvision.types';

function ChannelHikvisionEditPage() {
  const { customerId, channelId } = useParams<{ customerId: string; channelId: string }>();
  const history = useHistory();
  const query = useQuery();
  const { updateChannel, deleteChannel } = useHikVisionApi(Number(customerId));
  const { data: channel } = useFetch(`/v1/clients/${customerId}/channels/${channelId}`, {
    cleanCacheAfterMs: 1500,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    normalizeData: normalizeHikvisionChannel,
  });
  return (
    <>
      <Box sx={{ padding: 4, paddingBottom: 0 }}>
        <ChannelHeader customerId={Number(customerId)} />
      </Box>
      <HikvisionForm
        initialValues={channel as HikVisionChannel}
        doSubmit={updateChannel}
        customerId={customerId}
        showDeleteButton
        doDelete={deleteChannel}
        onClose={() => history.push(query.get('returnRoute') || routes.customer.root())}
      />
    </>
  );
}

export default asyncComponent(ChannelHikvisionEditPage);
