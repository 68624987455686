import 'smart-webcomponents-react/source/styles/smart.default.css';
import React from 'react';
import { Scheduler, SchedulerEvent } from 'smart-webcomponents-react/scheduler';
import {
  GlobalStyles,
  useTheme,
  alpha,
  FormLabel,
  Stack,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { WeekDay } from 'types/weekDay.enum';
import colorByRecordingType from 'helpers/colorByRecordingType';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// @ts-ignore
import agendaGravacao from 'assets/agenda-de-gravacao-tutorial-v2.mp4';
import { Close, Help } from '@mui/icons-material';
import { RecordingType } from 'types/channels.types';
import { IRecordingSchedulerForm, ITimeRangeWithId } from 'types/recordingSchedule.types';
import RecordingNewTimeIntervalDialog from './RecordingNewTimeIntervalDialog';
import RecordingEditTimeIntervalDialog from './RecordingEditTimeIntervalDialog';

dayjs.extend(minMax);

function RecordingTimeTable() {
  const theme = useTheme();
  const { t } = useTranslation(['channels', 'recording_schedule', '_common']);
  const schedulerRef = React.useRef<Scheduler>(null);
  /* CREATE STATES */
  const [createOpened, setCreateOpened] = React.useState(false);
  const [dateStartSelected, setDateStartSelected] = React.useState<string>();
  const [dateEndSelected, setDateEndSelected] = React.useState<string>();
  const [groupSelected, setGroupSelected] = React.useState<{ weekDay: WeekDay }>();
  /* EDIT STATES */
  const [itemSelected, setItemSelected] = React.useState<ITimeRangeWithId>();
  const [editOpened, setEditOpened] = React.useState(false);
  /* */
  const [helpOpen, setHelpOpen] = React.useState(false);

  const selectedRange = React.useRef<{
    dateStart: string;
    dateEnd: string;
    group: { weekDay: WeekDay };
  }>();
  const formik = useFormikContext<IRecordingSchedulerForm>();

  React.useEffect(
    function cleanSelectedStateWhenCloseModal() {
      if (!createOpened) {
        setDateStartSelected(undefined);
        setDateEndSelected(undefined);
        setGroupSelected(undefined);
        selectedRange.current = undefined;
      }
    },
    [createOpened]
  );

  const handleChange = React.useCallback((e) => {
    // @ts-ignore
    if (!e?.detail?.dateStart && !e?.detail?.dateEnd) {
      return;
    }
    // @ts-ignore
    const dateStart = dayjs(e.detail.dateStart);
    // @ts-ignore
    const dateEnd = dayjs(e.detail.dateEnd).add(1, 'hour');
    // @ts-ignore
    const group = e?.detail?.group;
    if (Math.abs(dateStart.diff(dateEnd, 'hour')) >= 1) {
      selectedRange.current = {
        dateStart: dayjs.min(dayjs(dateStart), dayjs(dateEnd)).toISOString(),
        dateEnd: dayjs.max(dayjs(dateStart), dayjs(dateEnd)).toISOString(),
        group,
      };
    }
  }, []);

  const handleMouseUp = React.useCallback(function openModal() {
    if (selectedRange.current) {
      setDateStartSelected(selectedRange.current.dateStart);
      setDateEndSelected(selectedRange.current.dateEnd);
      setGroupSelected(selectedRange.current.group);
      setCreateOpened(true);
    }
  }, []);

  const handleItemClick = React.useCallback((e) => {
    const item = e?.detail?.itemObj;
    if (!item) {
      return;
    }
    setItemSelected({ ...item, endTime: item.dateEnd, startTime: item.dateStart });
    setEditOpened(true);
  }, []);

  const resourceDS = React.useMemo(
    () => [
      {
        label: 'Semana',
        value: 'weekDay',
        dataSource: Object.values(WeekDay).map((weekDay, index) => ({
          id: weekDay,
          label: dayjs().day(index).format('dddd'),
        })),
      },
    ],
    []
  );

  const dataComputed: SchedulerEvent[] = React.useMemo(
    () => [
      ...formik.values.timeRanges.map((item) => ({
        weekDay: item.weekDay,
        dateStart: new Date(item.startTime),
        dateEnd: new Date(item.endTime),
        id: String(item.id),
        backgroundColor: colorByRecordingType(item.recordingType, theme),
        label: t(`recording_type_${item.recordingType}`),
        recordingType: item.recordingType,
      })),
      {
        weekDay: groupSelected?.weekDay,
        dateStart: dateStartSelected && new Date(dateStartSelected),
        dateEnd: dateEndSelected && new Date(dateEndSelected),
        backgroundColor: colorByRecordingType(RecordingType.RECORD_24_7, theme),
        id: '',
      },
    ],
    [dateEndSelected, dateStartSelected, formik.values.timeRanges, groupSelected?.weekDay, t, theme]
  );

  const handleCreateTimeRange = React.useCallback(
    (newValues: ITimeRangeWithId[]) => {
      formik.setFieldTouched('timeRanges', true);
      formik.setFieldValue('timeRanges', [...newValues, ...formik.values.timeRanges]);
    },
    [formik]
  );

  const handleEditTimeRange = React.useCallback(
    (newValues: ITimeRangeWithId[]) => {
      formik.setFieldTouched('timeRanges', true);
      formik.setFieldValue('timeRanges', [
        ...newValues,
        ...formik.values.timeRanges.filter((v) => String(v.id) !== itemSelected?.id),
      ]);
      setItemSelected(undefined);
    },
    [formik, itemSelected?.id]
  );

  const handleItemChange = React.useCallback(
    (e) => {
      const item:
        | {
          allDay: boolean;
          backgroundColor: string;
          dateEnd: Date;
          dateStart: Date;
          id: string;
          label: string;
          weekDay: WeekDay;
          recordingType: RecordingType;
        }
        | undefined = e?.detail?.item;
      item &&
        formik.setFieldValue('timeRanges', [
          ...formik.values.timeRanges.filter((v) => v.id !== item.id),
          {
            weekDay: item.weekDay,
            startTime: item.dateStart,
            endTime: item.dateEnd,
            recordingType: item.recordingType,
            id: item.id,
          },
        ]);
    },
    [formik]
  );

  return (
    <Stack spacing={1} overflow="auto">
      <Box
        sx={{
          maxWidth: 1,
        }}
        onBlur={formik.handleBlur}
      >
        <FormLabel
          error={formik.touched.timeRanges && typeof formik.errors.timeRanges === 'string'}
          sx={{
            width: '100%',
          }}
        >
          {t('_common:recording_schedule')}
          <Tooltip title={t('recording_schedule:how_to_use_the_calendar')}>
            <IconButton onClick={() => setHelpOpen(true)} size="small" sx={{ ml: 1 }}>
              <Help
                fontSize="small"
                color={
                  formik.touched.timeRanges && typeof formik.errors.timeRanges === 'string'
                    ? 'error'
                    : 'inherit'
                }
              />
            </IconButton>
          </Tooltip>
        </FormLabel>
      </Box>
      {formik.touched.timeRanges && typeof formik.errors.timeRanges === 'string' && (
        <FormHelperText error>{formik.errors.timeRanges}</FormHelperText>
      )}
      <GlobalStyles
        styles={{
          '.smart-scheduler-header': {
            border: 'none!important',
          },
          body: {
            // '--smart-scheduler-default-height': '500px',
            '--smart-scheduler-timeline-cell-height': '20px',
            '--smart-scheduler-header-height': '0px',
            '--smart-scheduler-header-padding': '0px',
            '--smart-scheduler-timeline-header-all-day-cells-size': '0px',
            '--smart-background': theme.palette.background.default,
            '--smart-primary': theme.palette.primary.main,
            '--smart-primary-color': theme.palette.primary.contrastText,
            '--smart-secondary': theme.palette.secondary.main,
            '--smart-secondary-color': theme.palette.secondary.contrastText,
            '--smart-error': theme.palette.error.main,
            '--smart-error-color': theme.palette.error.contrastText,
            '--smart-success': theme.palette.success.main,
            '--smart-success-color': theme.palette.success.contrastText,
            '--smart-info': theme.palette.info.main,
            '--smart-info-color': theme.palette.info.contrastText,
            '--smart-warning': theme.palette.warning.main,
            '--smart-warning-color': theme.palette.warning.contrastText,
            '--smart-light': theme.palette.common.white,
            '--smart-surface': theme.palette.background.default,
            '--smart-surface-color': theme.palette.text.primary,
            '--smart-outline': theme.palette.divider,
            '--smart-scheduler-timeline-group-separator-background': theme.palette.divider,
            '--smart-border': theme.palette.divider,
            '--smart-scheduler-day-week-view-hour-color': theme.palette.divider,
            '--smart-ui-state-hover': theme.palette.action.hover,
            '--smart-ui-state-selected': alpha(theme.palette.primary.light, 0.2),
            '--smart-ui-state-active': alpha(theme.palette.primary.dark, 0.6),
          },
        }}
      />
      <RecordingNewTimeIntervalDialog
        open={createOpened}
        setOpen={setCreateOpened}
        {...{ dateStartSelected, dateEndSelected }}
        weekDay={groupSelected?.weekDay}
        onConfirm={handleCreateTimeRange}
      />
      {itemSelected && (
        <RecordingEditTimeIntervalDialog
          open={editOpened}
          setOpen={setEditOpened}
          item={itemSelected}
          onConfirm={handleEditTimeRange}
        />
      )}
      <TimeTableHelp open={helpOpen} setOpen={setHelpOpen} />

      <Scheduler
        ref={schedulerRef}
        id="scheduler"
        key="scheduler"
        view="day"
        views={[{ type: 'day' }]}
        groups={['weekDay']}
        resources={resourceDS}
        dataSource={dataComputed}
        hourStart={0}
        hourEnd={24}
        dateCurrent={new Date()}
        groupOrientation="horizontal"
        locale="pt"
        disableContextMenu
        disableEventMenu
        onChange={handleChange}
        onItemClick={handleItemClick}
        onItemChange={handleItemChange}
        onMouseUp={handleMouseUp}
        disabled={formik.isSubmitting}
      />
    </Stack>
  );
}

function TimeTableHelp({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) {
  const { t } = useTranslation('recording_schedule');

  return (
    <Dialog {...{ open }} onClose={() => setOpen(false)} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('how_to_use_the_calendar')}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <video src={agendaGravacao} width={800} height={400} muted autoPlay>
          <Typography>Your browser does not support the video tag.</Typography>
        </video>
      </DialogContent>
    </Dialog>
  );
}

export default RecordingTimeTable;
