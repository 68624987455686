import React, { useState, useEffect, useMemo } from 'react';
import { userSearchTextAtom } from 'atoms/users';
import { User as UserInterface, PermissionRole } from 'types/user.types';
import { useRecoilValue } from 'recoil';
import { asyncComponent } from 'HOCs';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { useFetch } from 'hooks';
import { Container } from './User.styles';
import UserList from './UserList';
import UserDetails from './UserDetails';

function User() {
  const { t } = useTranslation('users');
  const history = useHistory();

  const [selected, setSelected] = useState(0);

  const text = useRecoilValue(userSearchTextAtom);
  const { data: items } = useFetch<UserInterface[]>('/v1/users', { params: { text } });

  useEffect(() => {
    if (selected > 0) {
      return;
    }

    setSelected(0);
    if (items && items.length > 0) {
      setSelected(items[0].id);
    }
  }, [items, selected]);

  const user = useMemo(
    () => items?.find((_user) => _user.id === selected),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, selected]
  );

  return (
    <Container>
      {items?.length === 0 && !text ? (
        <EmptyState
          title={t('register_first_user')}
          description={t('provide_operator_permissions_and_customer_access')}
          captionButton={t('register_user')}
          onAddClick={() => history.push(routes.settings.user.create)}
          roleAdd={PermissionRole.INSERT_USER}
        />
      ) : (
        <>
          <UserList {...{ selected, setSelected }} items={items as UserInterface[]} />
          {user && <UserDetails {...{ user }} />}
        </>
      )}
    </Container>
  );
}

export default asyncComponent(
  User,
  <Container>
    <UserList loading />
  </Container>
);
