import { useLeftPanel } from "hooks/useLeftPanel";

export function useSelectMosaicPanel() {
  const { setSelectMosaicActual, setMenuActual, isSelectMosaicActual } = useLeftPanel();
  return {
    openMosaicSelection: setSelectMosaicActual,
    closeMosaicSelection: setMenuActual,
    toggleMosaicSelection: isSelectMosaicActual() ? setMenuActual : setSelectMosaicActual,
  };
}
