import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'components/core';
import routes from 'helpers/routes';
import {
  BatchChannelInitialValues,
  ProtocolType,
  RecordingType,
  Channel,
} from 'types/channels.types';
import { useYupObject, useQuery } from 'hooks';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { useSetRecoilState } from 'recoil';
import { Box, Divider, Typography } from '@mui/material';
import { batchRTSPChannelsAtom, testedUrlsResultsAtom } from 'atoms/batchChannel';

import ChannelForm from 'components/Channel/ChannelForm';

interface Props {
  initialValues: BatchChannelInitialValues | Channel;
  showDeleteButton?: boolean;
  doDelete?: (id: number) => Promise<void>;
  customerId: number;
  onClose: () => void;
}
export function BatchChannelForm({
  initialValues,
  showDeleteButton,
  doDelete,
  customerId,
  onClose,
}: Props) {
  const query = useQuery();
  const yup = useYupObject();
  const history = useHistory();
  const { t } = useTranslation();
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);

  const setBatchRTSPChannels = useSetRecoilState(batchRTSPChannelsAtom);
  const setReturnedTestUrlOnlyRecord = useSetRecoilState(testedUrlsResultsAtom('urlOnlyRecord'));
  const setReturnedTestUrl = useSetRecoilState(testedUrlsResultsAtom('url'));

  React.useEffect(
    function resetStatesWhenDependencies() {
      setReturnedTestUrl({});
      setReturnedTestUrlOnlyRecord({});
    },
    [setReturnedTestUrl, setReturnedTestUrlOnlyRecord]
  );

  const validationSchema = yup.object({
    qtyChannels: yup.number().required().min(2).max(999),
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    user: yup
      .string()
      .trim()
      .max(100)
      .nullable()
      .test('white-space', t('_common:input_white_spaces_validation'), (username) => {
        if (!username) return true;

        return !(username.split(' ').length > 1);
      }),
    password: yup
      .string()
      .trim()
      .max(100)
      .nullable()
      .test('white-space', t('_common:input_white_spaces_validation'), (password) => {
        if (!password) return true;

        return !(password.split(' ').length > 1);
      }),
    specificUrl: yup.bool(),
    urlToFormat: yup.string().trim().max(500).required(),
    urlOnlyRecordToFormat: yup.string().trim().max(500).nullable().when('specificUrl', {
      is: true,
      then: yup.string().required(),
    }),
    url: yup.string().rtsp().trim().max(500).required(),
    urlOnlyRecord: yup.string().rtsp().trim().max(500).nullable().when('specificUrl', {
      is: true,
      then: yup.string().required(),
    }),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
    // type: yup.string().oneOf(Object.values(ChannelType)).required(),
    // qtyChannels: yup.number().max(999).min(1).required(),
    // brand: yup.string().oneOf(Object.values(Brand)).required(),
    // model: yup.string().oneOf(Object.values(Model)).required(),
    // stream: yup.number().max(99).min(1).required(),
    // keepAliveTime: yup.number().required().max(999).min(1),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

        setBatchRTSPChannels(
          Array.from({ length: values.qtyChannels }).map((_, index) => {
            const data = {
              ...values,
              name: `${values.name} ${index + 1}`,
              url: values.url.replace('[amount]', String(index + 1)),
              urlToFormat: values.urlToFormat.replace('[amount]', String(index + 1)),
              urlOnlyRecord: values.urlOnlyRecord.replace('[amount]', String(index + 1)),
              urlOnlyRecordToFormat: values.urlOnlyRecordToFormat.replace(
                '[amount]',
                String(index + 1)
              ),
              channelGroup: values.channelGroup
                ? {
                    id: values.channelGroup.id,
                    name: values.channelGroup.label,
                  }
                : undefined,
              connectionTypeEnum: ProtocolType.RTSP,
              simpleScheduledRecording: hasUnlinkedSchedule
                ? null
                : values.simpleScheduledRecording,
            };
            return data as Channel;
          })
        );
        history.push(routes.customer.channel.create.rtspChannelsPreview(customerId));
      }}
    >
      {(formik) => (
        <form
          aria-label="batchChannelForm"
          style={{
            overflow: 'hidden',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              pr: 4,
              py: 1.5,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>

            <ChannelForm.StatusInput />

            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
                gap: '1.2rem',
              }}
            >
              <ChannelForm.QtyChannelsInput protocolValue={ProtocolType.RTSP} />
              <ChannelForm.NameInput />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.UsernameInput
                helperText={t('channels:in_the_url_field_replace_the_user_with_username')}
              />
              <ChannelForm.PasswordInput
                helperText={t('channels:in_the_url_field_replace_the_password_with_password')}
              />
            </Box>
            <ChannelForm.SpecificUrlCheckbox />
            {formik.values.specificUrl ? (
              <Box sx={{ marginBottom: 4, display: 'flex', width: '100%', gap: 2 }}>
                <Box sx={{ width: '100%' }}>
                  <ChannelForm.RTSPUrlToFormatInput />
                  <ChannelForm.TestBatchChannelButton ariaLabel="url live test" atomKey="url" />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <ChannelForm.RTSPUrlOnlyRecordToFormatInput />
                  <ChannelForm.TestBatchChannelButton
                    ariaLabel="url test record"
                    atomKey="urlOnlyRecord"
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={{ marginBottom: 4 }}>
                <ChannelForm.RTSPUrlInput />
                <ChannelForm.TestBatchChannelButton ariaLabel="url live test" atomKey="url" />
              </Box>
            )}
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mb: 4,
              }}
            >
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              textAlign: 'end',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'channels:not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('channels:want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    history.push(query.get('returnRoute') || routes.customer.root());
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
            <Form.SaveButton
              initialValues={formik.initialValues}
              values={formik.values}
              label={t('_common:next')}
              isSubmitting={formik.isSubmitting}
              disabled={!formik.isValid || !formik.dirty}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
}
