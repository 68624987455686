import React from 'react';
import { Typography, Divider, ListItemButton, ListItem, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'hooks';
import { CompanySimplifyListItem } from 'types/companies.types';
import CompanyLogo from './CompanyLogo';

interface CompanyListProps {
  company?: CompanySimplifyListItem;
  loading?: boolean;
}

function CompanyListItem({ company, loading }: CompanyListProps) {
  const query = useQuery();
  const selectedCompanyId = query.get('selectedCompany');
  const selected = company && Number(selectedCompanyId) === company.id;

  return (
    <>
      <Divider />
      <ListItem>
        <ListItemButton sx={{ padding: '16px' }} selected={!!selected}>
          {loading ? (
            <Skeleton variant="rectangular">
              <CompanyLogo altText={`Logo - ${company?.companyName}`} />
            </Skeleton>
          ) : (
            <CompanyLogo
              altText={`Logo - ${company?.namePhoto}`}
              selected={selected}
              base64Image={company?.base64Photo}
            />
          )}

          <Box
            sx={{
              flex: '1 1 auto',
              marginLeft: '1.5rem',
            }}
          >
            <Typography variant="body1">{loading ? <Skeleton /> : company?.companyName}</Typography>
          </Box>
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default CompanyListItem;
