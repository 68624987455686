export type EventCellValue = Date | number | string;

export interface Event {
  date: Date;
  event: string;
  description: string;
  channelId: number;
  channelName: string;
  clientId: number;
  clientCompanyName: string;
  clientTradeName: string;
  id: string;
}

export enum EventFilterType {
  START,
  STOP,
  CONNECTION_ATTEMPT = 101,
  START_LIVE_STREAM = 102,
  START_RECORDING = 103,
  CONNECTION_SUCCESS = 200,
  CONNECTION_FAILED = 400,
  CONNECTION_SHUTDOWN = 424,
  CONNECTION_SIZE_PROBLEM = 425,
  CONNECTION_MAXATTEMPTS = 426,
  CODEC_NOT_SUPPORTED = 427,
  STOP_LIVE_STREAM = 429,
  STOP_RECORDING = 430,
  OFFLINE_DEVICE = 431,
  CONNECTION_ERROR = 500,
}