import { atom } from 'recoil';

export const companiesSearchTextAtom = atom<string>({
  key: 'companiesSearchTextAtom',
  default: '',
});

export const deleteConfirmOpenedAtom = atom<boolean>({
  key: 'deleteConfirmOpenedAtom',
  default: false,
});