import { userInfoSelector } from "atoms/auth";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { PermissionRole } from "types/user.types";

export function useHavePermission(): { (role: PermissionRole): boolean };
export function useHavePermission(role: PermissionRole): boolean;
export function useHavePermission(
  role?: PermissionRole
): boolean | { (role: PermissionRole): boolean } {
  const userInfo = useRecoilValue(userInfoSelector);

  const havePermissionFn = useCallback(
    (_role: PermissionRole) => {
      if (!userInfo) {
        return false;
      }

      return userInfo.realm_access.roles.includes(_role);
    },
    [userInfo]
  );

  const havePermission = useMemo(() => (role ? havePermissionFn(role) : false), [
    havePermissionFn,
    role,
  ]);

  return role ? havePermission : havePermissionFn;
}
