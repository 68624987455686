import React, { useEffect } from 'react';
import DefaultImg from 'assets/default-image-v2.png';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Checkbox,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ApiBoundary from 'components/ApiBoundary';
import { useGetChannelThumbnail } from 'hooks/channels';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

const SIZE_CARD_WIDTH = '10rem';
const SIZE_CARD_HEIGHT = '6.25rem';

export const useStyles = makeStyles((theme) =>
  createStyles<'card' | 'cardActionArea' | 'infoContainer', { selected?: boolean }>({
    card: {
      marginRight: '12.8px',
      marginBottom: '6.4px',
      borderRadius: '0',
      width: SIZE_CARD_WIDTH,
      height: SIZE_CARD_HEIGHT,
      border: ({ selected }) =>
        selected ? `1px solid ${theme.palette.primary.dark}` : '1px solid transparent',
      opacity: ({ selected }) => (selected ? '' : 0.3),
    },
    cardActionArea: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      height: '100%',
      '& > img': {
        width: SIZE_CARD_WIDTH,
        height: SIZE_CARD_HEIGHT,
      },
    },
    infoContainer: {
      position: 'absolute',
      right: '0',
      left: '0',
      bottom: '0',
      opacity: '0.8',
      padding: '5px 16px',
      backgroundColor: theme.palette.grey['900'],
    },
  })
);

export function ChannelImg({
  channelId,
  channelPrivate,
}: {
  channelId: number;
  channelPrivate: boolean;
}) {
  const img64 = useGetChannelThumbnail({ channelId, channelPrivate });

  return img64 ? (
    <CardMedia component="img" image={`data:image/png;base64, ${img64}`} />
  ) : (
    <img alt="screenshot-channel" src={DefaultImg} />
  );
}

function RectangularChannel({
  channel,
  selected,
  onClick,
  setSelected,
  channelsChecked,
  setChannelsChecked,
  noCheckbox,
  checkedAll,
}: {
  channel: {
    name: string;
    id: number;
    channelPrivate: boolean;
    status: boolean;
  };
  selected: boolean;
  setSelected: (channelId: number) => void;
  onClick: React.MouseEventHandler;
  channelsChecked: { id: number; checked: boolean }[];
  setChannelsChecked: React.Dispatch<
    React.SetStateAction<{ id: number; checked: boolean; status: boolean }[]>
  >;
  noCheckbox?: boolean;
  checkedAll: boolean;
}) {
  const classes = useStyles({ selected });
  const [checked, setChecked] = React.useState(false);
  const [channelsInBatchFlag] = useDecision(
    FEATURE_FLAGS_KEYS.ACTIVATE__DEACTIVATE_CHANNELS_IN_BATCH
  );

  useEffect(
    function listenChannelsChecked() {
      channelsChecked.some((_channelChecked) => _channelChecked.id === channel.id)
        ? setChecked(true)
        : setChecked(false);
    },
    [channel.id, channelsChecked]
  );

  const handleClick = React.useCallback(
    (event) => {
      setSelected(channel.id);
      channelsChecked.length ? setChecked(!checked) : onClick(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checked, onClick]
  );

  useEffect(() => {
    setChecked(checkedAll);
  }, [checkedAll, setChecked]);

  useEffect(
    function updateChannelsCheckedAtom() {
      checked
        ? setChannelsChecked((_channelsChecked) => {
            if (_channelsChecked.includes({ id: channel.id, checked, status: channel.status }))
              return [..._channelsChecked];
            return [..._channelsChecked, { id: channel.id, checked, status: channel.status }];
          })
        : setChannelsChecked((_channels) =>
            _channels.filter((_channelChecked) => _channelChecked.id !== channel.id)
          );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channel.id, checked, setChannelsChecked]
  );

  return (
    <Card
      component="li"
      aria-label={channel.name}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '85px',
        mb: 1,
        borderRadius: '12px',
        border: '1px solid transparent',
        opacity: selected ? '' : 0.7,
        bgcolor: selected ? theme.palette.action.disabled : theme.palette.action.disabledBackground,
      })}
    >
      <CardActionArea
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignContent: 'center',
        }}
        className={classes.cardActionArea}
      >
        {channelsInBatchFlag.enabled && !noCheckbox && (
          <Permissioned role={PermissionRole.EDIT_CHANNEL}>
            <Checkbox
              id={String(channel.id)}
              name={String(channel.id)}
              checked={checked}
              onChange={(_event) => {
                setSelected(channel.id);
                setChecked(!checked);
              }}
            />
          </Permissioned>
        )}

        {/* <img alt="screenshot-channel" src={DefaultImg} /> */}
        <ApiBoundary
          fallbackLoading={<img alt="screenshot-channel" src={DefaultImg} />}
          fallbackError={<img alt="screenshot-channel" src={DefaultImg} />}
        >
          <ChannelImg channelId={channel.id} channelPrivate={channel.channelPrivate} />
        </ApiBoundary>
        <CardContent
          sx={{
            marginLeft: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              mb: 1,
            }}
          >
            <Box
              sx={(theme) => ({
                bgcolor: channel.status ? theme.palette.success.main : theme.palette.error.main,
              })}
              height="8px"
              width="8px"
              borderRadius="4px"
              display="inline-block"
              marginRight="8px"
              marginTop={1}
            />
            <Tooltip title={channel?.name || 'Channel Loading'}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                  noWrap
                >
                  {channel.name}
                </Typography>
                <Typography variant="body2" noWrap>
                  ID: 00000{channel.id}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default RectangularChannel;
