import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs, AppBar, Divider, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { useHavePermission } from 'hooks/useHavePermission';
import { Customer } from 'types/customer.types';
import { normalizeChannel } from 'hooks/channels/useChannelsApi';
import { Channel } from 'types/channels.types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VideocamIcon from '@mui/icons-material/Videocam';
import TabCustomer from './TabCustomer';
import TabChannels from './TabChannels';
import useCallStartLiveBefore from './useCallStartLiveBefore';

export const TAB_CUSTOMER = 'TAB_CUSTOMER';
export const TAB_CHANNEL = 'TAB_CHANNEL';

const useStyles = makeStyles((theme) => ({
  headerClientName: {
    fontSize: '1.375rem',
  },
  tabs: {
    color: theme.palette.text.primary,
  },
}));

function CustomerDetails({ customer }: { customer: Customer }) {
  const classes = useStyles();
  const { t } = useTranslation(['customers', '_common']);
  const [tab, setTab] = useState(TAB_CUSTOMER);
  const { data: channels } = useFetch(`/v1/clients/${customer.id}/channels`, {
    normalizeData: (_channels: Channel[]) => _channels.map(normalizeChannel),
  });
  const viewChannelPermission = useHavePermission(PermissionRole.VIEW_CHANNEL);
  useCallStartLiveBefore(customer.id);

  useEffect(() => {
    /* 
    Simulação: Ter um customer com channel e outro sem channel na lista, 
    clica no com channel, e seleciona a aba channels, ao selecionar o customer sem channel dá problema. */
    setTab(TAB_CUSTOMER);
  }, [customer.id]);

  return (
    <Box marginLeft="24px" display="flex" flexDirection="column" flex="1 1 auto">
      <Box padding="0 0 16px 0" display="flex" alignItems="center">
        <Box flex="1 1 auto">
          <Typography className={classes.headerClientName} variant="h6">
            {customer.tradeName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {customer.companyName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            ID {customer.idMask}
          </Typography>
        </Box>
      </Box>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="Tab List"
        >
          <Tab
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '10px',
              height: '40px',
              minHeight: '40px',
            }}
            label={t('customers_data')}
            value={TAB_CUSTOMER}
            icon={<AccountCircleIcon />}
          />
          {Boolean(channels?.length) && viewChannelPermission && (
            <Tab
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '10px',
                height: '40px',
                minHeight: '40px',
              }}
              label={`${t('_common:channels')} (${channels?.length})`}
              value={TAB_CHANNEL}
              aria-label="channel"
              icon={<VideocamIcon />}
            />
          )}
        </Tabs>
      </AppBar>
      <Divider />
      {tab === TAB_CUSTOMER && <TabCustomer {...{ customer, channels: channels || [] }} />}
      {tab === TAB_CHANNEL && !!channels?.length && (
        <Permissioned role={PermissionRole.VIEW_CHANNEL}>
          <TabChannels customerId={customer.id} channels={channels as Channel[]} setTab={setTab} />
        </Permissioned>
      )}
    </Box>
  );
}

export default CustomerDetails;
