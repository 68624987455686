import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export enum Operation {
  PERSIST = 'PERSIST',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

function SelectOperation({
  selected,
  onChange,
}: {
  selected: Operation | null;
  onChange: (r: Operation) => void;
}) {
  const { t } = useTranslation('operation_logs');

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-operation-label">{t('operation')}</InputLabel>
      <Select
        labelId="select-operation-label"
        id="select-operation"
        label={t('operation')}
        value={selected || ''}
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value as Operation);
        }}
        renderValue={(value) => t(`operation_${value}`)}
      >
        <MenuItem value={Operation.PERSIST}>{t('operation_PERSIST')}</MenuItem>
        <MenuItem value={Operation.UPDATE}>{t('operation_UPDATE')}</MenuItem>
        <MenuItem value={Operation.REMOVE}>{t('operation_REMOVE')}</MenuItem>
      </Select>
    </FormControl>
  );
}
export default SelectOperation;
