import React from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Company, SelectCompanyTypeValue } from 'types/companies.types';
import { useFetch } from 'hooks';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { Clear } from '@mui/icons-material';

interface SelectCompaniesProps {
  selectedCompany?: SelectCompanyTypeValue | null;
  onChangeSelectCompany: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function SelectCompanies({ selectedCompany, onChangeSelectCompany }: SelectCompaniesProps) {
  const { t } = useTranslation();

  const { data: companies } = useFetch<Company[]>('/v1/companies');

  const [selectedValue, setSelectedValue] = React.useState<number | ''>(
    selectedCompany ? selectedCompany.id : ''
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event.target.value);
    setSelectedValue(value);
    onChangeSelectCompany({
      ...event,
      target: {
        ...event.target,
        // @ts-ignore
        value: {
          id: value,
          companyName: companies?.find((company) => company.id === value)?.companyName || '',
        },
      },
    });
  };

  const handleClear = () => {
    setSelectedValue('');
    onChangeSelectCompany({
      target: {
        name: 'company',
        // @ts-ignore
        value: {
          id: '',
          companyName: '',
        },
      },
    });
  };
  if (!companies?.length) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-companies-label">{t('company:company')}</InputLabel>
      <Select
        labelId="select-companies-label"
        id="company"
        name="company"
        value={selectedValue}
        // @ts-ignore
        onChange={handleChange}
        label={t('company:company')}
        endAdornment={
          selectedValue ? (
            <IconButton
              size="small"
              onClick={handleClear}
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                marginRight: 30,
              }}
            >
              <Clear />
            </IconButton>
          ) : null
        }
        style={{ position: 'relative' }}
      >
        <MenuItem aria-label="" value="" />
        {companies?.map((company) => (
          <MenuItem aria-label="item" key={company.id} value={company.id}>
            {company.companyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectCompanies.Loading = function SelectCompaniesLoading() {
  const { t } = useTranslation();
  return <SelectFetchLoading label={t('company:company')} />;
};

export default SelectCompanies;
