import React, { useEffect } from 'react';
import { useFetch } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { asyncComponent } from 'HOCs';
import NotFound from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import { MosaicInPlaylist, Playlist, PlaylistItem } from 'types/playlist';
import { Mosaic } from 'types/mosaics.types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { mosaicSlotOrderSelectedAtom } from 'atoms/mosaicItems';
import { useInterval } from 'react-use';
import { addMosaicToPlaylistModalOpenedAtom, itemAtualAtom } from 'atoms/playlist';
import AddMosaicToPlaylistModal from 'pages/PlaylistPage/AddMosaicToPlaylistModal';
import { Box, IconButton } from '@mui/material';
import useCallStartLiveBefore from 'components/ItemsCard/useCallStartLiveBefore';
import MosaicFullScreenLive from 'pages/MosaicFullScreenViewPage/MosaicFullScreenLive';
import { ArrowBack, Replay } from '@mui/icons-material';
import routes from 'helpers/routes';

function PlaylistPlayFullscreenView() {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { data: playlist } = useFetch<Playlist>(`/v1/playlists/${Number(playlistId)}`, {
    normalizeData: (_playlist) => {
      const newArr = [..._playlist.items];

      return {
        ..._playlist,
        items: newArr.sort((a, b) => a.order - b.order),
      };
    },
  });
  const { t } = useTranslation('playlists');

  if (!playlist) {
    return (
      <NotFound
        title={t('non_exixstent_playlist')}
        reason={t('playlist_does_not_exist_or_may_have_been_deleted')}
        shouldDo={t('register_a_new_playlist_or_select_another_playlist_to_be_displayed')}
      />
    );
  }

  if (playlist.items) return <PlaylistView {...{ playlist }} />;
}

function PlaylistView({ playlist }: { playlist: Playlist }) {
  const [index, setIndex] = React.useState(0);
  const itemAtual = React.useMemo<PlaylistItem>(() => playlist.items[index], [playlist, index]);
  const [open, setOpen] = useRecoilState(addMosaicToPlaylistModalOpenedAtom);
  const setItemAtual = useSetRecoilState(itemAtualAtom);

  const nextIndex = React.useMemo(() => {
    const newIndex = index + 1;
    return playlist.items.length - 1 >= newIndex ? newIndex : 0;
  }, [index, playlist]);

  useCallStartLiveBefore(itemAtual.rotationTime, !!playlist.items[nextIndex].mosaic.id);

  useInterval(() => {
    setIndex(nextIndex);
  }, itemAtual.rotationTime * 1000);

  useEffect(() => {
    if (itemAtual) {
      setItemAtual(itemAtual);
    }
  }, [itemAtual, setItemAtual]);

  return (
    <>
      <PlaylistMosaicView mosaicInPlaylist={itemAtual.mosaic} playListId={playlist.id} />
      <AddMosaicToPlaylistModal
        {...{ open, setOpen }}
        items={playlist.items}
        playlistId={playlist.id}
      />
    </>
  );
}

function PlaylistMosaicView({
  mosaicInPlaylist,
  playListId,
}: {
  mosaicInPlaylist: MosaicInPlaylist;
  playListId: number;
}) {
  const orderSelected = useRecoilValue(mosaicSlotOrderSelectedAtom);
  const { data: mosaic } = useFetch<Mosaic>(`/v1/mosaics/${mosaicInPlaylist.id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const history = useHistory();
  if (!mosaic) {
    return null;
  }

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <IconButton size="small" onClick={() => history.push(routes.playlists.play(playListId))}>
          <ArrowBack fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => window.location.reload()}>
          <Replay fontSize="small" />
        </IconButton>
      </Box>
      <MosaicFullScreenLive {...{ mosaic, orderSelected }} currentPage={1} />
    </Box>
  );
}

export function PlaylistMosaicViewPage({ mosaicId }: { mosaicId: number }) {
  const orderSelected = useRecoilValue(mosaicSlotOrderSelectedAtom);
  const { data: mosaic } = useFetch<Mosaic>(`/v1/mosaics/${mosaicId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (!mosaic) {
    return null;
  }

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
      <MosaicFullScreenLive {...{ mosaic, orderSelected }} currentPage={1} />
    </Box>
  );
}

export default asyncComponent(PlaylistPlayFullscreenView);
