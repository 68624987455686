import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function SegwareLogo(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="125.009"
      height="106.625"
      viewBox="0 0 125.009 106.625"
    >
      <g transform="translate(-7342 -1953.688)">
        <rect
          width="10.4"
          height="10.4"
          transform="matrix(0.707, -0.707, 0.707, 0.707, 7433.918, 2034.571)"
          fill="#dc2a39"
        />
        <path
          d="M2116.835,64.613l-62.5-62.5-44.119,44.119L2046.978,83l-11.03,11.03-22.073-22.074-7.358,7.355,29.431,29.427,18.381-18.384,18.387,18.381,3.7-3.695h0l7.34-7.341-7.34-7.34-3.683,3.684-47.81-47.812,29.414-29.414,47.809,47.812-3.681,3.682,7.34,7.34,7.34-7.34h0Z"
          transform="translate(5350.174 1951.574)"
          fill={theme.palette.text.primary}
        />
        <rect
          width="10.38"
          height="10.38"
          transform="translate(7342 2016.216) rotate(-45)"
          fill={theme.palette.text.primary}
        />
      </g>
    </SvgIcon>
  );
}

export default SegwareLogo;
