import React from 'react';

import BadFeedback from './BadFeedback';
import GoodFeedback from './GoodFeedback';
import { Classification, ClassificationIdx } from '../../types/speedTest.types';

export default function FeedbackSpeedTestView({
  classificationIdx,
}: {
  classificationIdx: ClassificationIdx;
}) {
  return classificationIdx < Classification.average ? <BadFeedback /> : <GoodFeedback />;
}
