import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { FilterSelectValue } from 'types/select.types';
import { CustomerGroup } from 'types/customerGroup.types';

function SelectCustomerGroup({
  selectedCustomerGroups,
  onChangeCustomerGroups,
  error,
  helperText,
}: {
  selectedCustomerGroups: FilterSelectValue[];
  onChangeCustomerGroups: (value: FilterSelectValue[]) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { data: customerGroups } = useFetch<CustomerGroup[]>('/v1/clientGroups');
  const { t } = useTranslation('_common');

  React.useEffect(
    function setLabels() {
      if (selectedCustomerGroups && customerGroups) {
        onChangeCustomerGroups(
          selectedCustomerGroups.map((value) => ({
            id: value.id,
            label:
              customerGroups.find((customerGroup) => customerGroup.id === value.id)?.name || '',
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerGroups]
  );

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-customer-group-label">{t('customer_group')}</InputLabel>
      <Select
        labelId="select-customer-group-label"
        id="select-customer-group"
        label={t('customer_group')}
        onChange={(e) =>
          onChangeCustomerGroups(
            (e.target.value as number[]).map((id) => ({
              id,
              label: customerGroups?.find((customerGroup) => customerGroup.id === id)?.name || '',
            }))
          )
        }
        value={selectedCustomerGroups.map((value) => value.id)}
        multiple
        renderValue={(selected) =>
          (selected)
            .map((id) => customerGroups?.find((customerGroup) => customerGroup.id === id)?.name)
            .join(', ')
        }
      >
        {customerGroups?.map(({ name, id }) => (
          <MenuItem aria-label="item" key={id} value={id}>
            <Checkbox checked={selectedCustomerGroups.some((value) => value.id === id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectCustomerGroup.Loading = function SelectCustomerGroupLoading() {
  const { t } = useTranslation('_common');
  return <SelectFetchLoading label={t('customer_group')} />;
};

export default SelectCustomerGroup;
