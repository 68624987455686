import { useHavePermission } from 'hooks/useHavePermission';
import { useMessages } from 'hooks';
import { useTranslation } from 'react-i18next';
import { PermissionRole } from 'types/user.types';
import axios from 'axios';
import { mutate } from 'swr';

export interface HikvisionSettingsProps {
  id?: number;
  appKey: string;
  secretKey: string;
}

export function useHikvisionSettings() {
  const { t } = useTranslation();
  const { enqueueSuccess, enqueueError } = useMessages();
  const editPermission = useHavePermission(PermissionRole.EDIT_HIKVISION_SETTINGS);

  const createConfig = async (config: HikvisionSettingsProps): Promise<void> => {
    if (!editPermission) {
      enqueueError(
        `${t('hikvision_settings:hikvision_settings_error_creating')}\n${t(
          'permission:you_dont_have_permission'
        )}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }

    try {
      await axios.post(`/v1/masterCompanies/settings/hikvision`, config);
      enqueueSuccess(t('hikvision_settings:created_successfully'));
      mutate(`/v1/masterCompanies/settings/hikvision`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(
          `${t('hikvision_settings:hikvision_settings_error_creating')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  const updateConfig = async (config: HikvisionSettingsProps): Promise<void> => {
    if (!editPermission) {
      enqueueError(
        `${t('hikvision_settings:hikvision_settings_error_creating')}\n${t(
          'permission:you_dont_have_permission'
        )}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }

    try {
      await axios.post(`/v1/masterCompanies/settings/hikvision`, config);
      enqueueSuccess(t('hikvision_settings:updated_successfully'));
      mutate(`/v1/masterCompanies/settings/hikvision`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(
          `${t('hikvision_settings:hikvision_settings_error_editing')}\n${t(
            `api_errors_messages:${error?.response?.data?.message}`
          )}`
        );
      }
      throw error;
    }
  };

  return {
    createConfig,
    updateConfig,
  };
}
