import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Channel(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M3 5C1.897 5 1 5.897 1 7V17C1 18.103 1.897 19 3 19H16C17.103 19 18 18.103 18 17V14.0801L23 18.0801V5.91992L18 9.91992V7C18 5.897 17.103 5 16 5H3Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Channel;
