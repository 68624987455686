import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mosaic } from 'types/mosaics.types';
import { FilterSelectValue } from 'types/select.types';

function SelectMosaics({
  selectedMosaics,
  onChangeMosaics,
  label,
  error,
  helperText,
  fnItemIsDisabled,
}: {
  selectedMosaics: FilterSelectValue[];
  onChangeMosaics: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  fnItemIsDisabled?: (item: FilterSelectValue) => boolean;
}) {
  const { data: mosaics } = useFetch<Mosaic[]>('/v1/mosaics');
  const { t } = useTranslation('_common');

  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id="select-mosaics-label">{label || t('mosaics')}</InputLabel>
      <Select
        labelId="select-mosaics-label"
        id="mosaics"
        name="mosaics"
        onChange={(e) =>
          onChangeMosaics({
            ...e,
            target: {
              ...e.target,
              // @ts-ignore
              value: (e.target.value as number[]).map((id) => ({
                id,
                label: mosaics?.find((item) => item.id === id)?.name || '',
              })),
            },
          })
        }
        value={selectedMosaics.map((value) => value.id)}
        multiple
        renderValue={(selected) =>
          (selected)
            .map((id) => mosaics?.find((item) => item.id === id)?.name)
            .join(', ')
        }
        label={label || t('mosaics')}
      >
        {mosaics?.map(({ name, id }) => (
          <MenuItem
            key={id}
            value={id}
            disabled={fnItemIsDisabled && fnItemIsDisabled({ id, label: name })}
          >
            <Checkbox checked={selectedMosaics.some((value) => value.id === id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectMosaics.Loading = function SelectmosaicsLoading() {
  const { t } = useTranslation('_common');
  return <SelectFetchLoading label={t('mosaics')} />;
};

export default SelectMosaics;
