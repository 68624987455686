import React from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject } from 'hooks';
import {
  customerGroupInEditionAtom,
} from 'atoms/customerGroup';
import { useSetRecoilState } from 'recoil';
import { useFormik } from 'formik';
import { Form } from 'components/core';
import { CustomerGroup } from 'types/customerGroup.types';
import { useCustomerGroupApi } from 'hooks/customerGroup/useCustomersApi';
import { useFormStyles } from '../core/Form';

function CustomerGroupForm({
  setOpen,
  customerGroupInEdition
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customerGroupInEdition: CustomerGroup | null;
}) {
  const { t } = useTranslation(['customer_groups', 'crud_actions']);
  const { createCustomerGroup, editCustomerGroup } = useCustomerGroupApi();
  const setCustomerGroupInEdition = useSetRecoilState(
    customerGroupInEditionAtom
  );

  const classesForm = useFormStyles({ dialog: true });
  const yup = useYupObject();
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
  });

  const formik = useFormik({
    initialValues: customerGroupInEdition || { name: '' },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const valuesTransformed = {
          ...values,
          name: values.name?.trim(),
        };
        if (customerGroupInEdition) {
          await editCustomerGroup(valuesTransformed as CustomerGroup);
        } else {
          await createCustomerGroup(valuesTransformed as CustomerGroup);
        }
        setSubmitting(false);
        setOpen(false);
        setTimeout(() => setCustomerGroupInEdition(null), 150);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  return (
    <form
      key={customerGroupInEdition?.id}
      aria-label="CustomerGroupCreate"
      className={classesForm.form}
      onSubmit={formik.handleSubmit}
    >
      <Box className={classesForm.formInputsContainer}>
        <TextField
          className={classesForm.formInput}
          sx={{ marginTop: '5px' }}
          fullWidth
          label={t('name')}
          name="name"
          id="name"
          autoFocus
          placeholder={t('enter_customer_group_name')}
          helperText={
            (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
          }
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
      </Box>
      <Box className={classesForm.formFooter}>
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => {
            setOpen(false);
            setTimeout(() => setCustomerGroupInEdition(null), 150);
          }}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          dialog
          label={customerGroupInEdition ? t('crud_actions:save') : t('crud_actions:create')}
          values={formik.values}
        />
      </Box>
    </form>
  );
}

export default CustomerGroupForm;
