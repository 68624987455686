import { Box, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function AuxContact(key_contact: string): string {
  if (key_contact === 'commercial') return 'contact_commercial_representant_or_send_email';
  if (key_contact === 'support1') return 'contact_commercial_team';
  if (key_contact === 'support2') return 'contact_support_team';

  return '';
}

function AlertConsumption({
  message,
  textColor,
  contactUS,
  setOpen,
}: {
  message: string;
  textColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  contactUS: 'commercial' | 'support1' | 'support2';
  setOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [messageSecondary, setMessageSecondary] = useState<string>('');

  useEffect(() => {
    setMessageSecondary(AuxContact(contactUS));
  }, [contactUS]);
  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Typography color={textColor}>{message}</Typography>
      <Link
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
          marginLeft: '4px',
        }}
        component={Typography}
        color={textColor}
        onClick={() => setOpen(true)}
      >
        {t(`consumption:${messageSecondary}`)}
      </Link>
    </Box>
  );
}

export default AlertConsumption;
