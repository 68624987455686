import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { DownloadVMSButton } from 'components/DownloadVMSButton';
import { SegwareLogo } from 'components/core/Icons';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { useIsWindows } from 'hooks/useIsWindows';
import { useVMSDownloaded } from 'hooks/useVMSDownloaded';

const SystemNameTypography = styled(Typography)({
  fontWeight: 400,
});

const Container = styled(Box)({
  flex: '1 1 auto',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

function WelcomePage() {
  const { t } = useTranslation();
  const [downloadFlag] = useDecision(FEATURE_FLAGS_KEYS.DOWNLOAD_VMS_CLOUD);
  const isWindows = useIsWindows();
  const downloaded = useVMSDownloaded();

  return (
    <Container>
      <Box width="50%" margin="auto">
        <SegwareLogo
          sx={(theme) => ({
            height: '60px',
            width: '65px',
            color: theme.palette.text.primary,
            mb: 2,
          })}
        />
        <SystemNameTypography variant="h4">{t('welcome:hello_user')}</SystemNameTypography>
        <Typography
          sx={{
            mt: 2,
            fontSize: 18,
            width: '100%',
          }}
          variant="h6"
        >
          {t('welcome:description_1')}
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 4,
            fontSize: 18,
            width: '100%',
          }}
          variant="body2"
        >
          {t('welcome:description_2')}
        </Typography>

        {!downloaded && isWindows && downloadFlag.enabled && <DownloadVMSButton.ToPage />}
      </Box>
    </Container>
  );
}

export default WelcomePage;
