import React from 'react';
import { Box, styled, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { timeFormat } from 'd3';
import { useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import ApiBoundary from 'components/ApiBoundary';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { TitleContainer } from './HeaderTab';
import ConsumptionTabData from './ConsumptionTabData';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 1),
  flexDirection: 'column',
  flex: '1 1 50%',
  overflow: 'hidden',
}));

function ConsumptionTab() {
  const { t } = useTranslation('metrics');
  const query = useQuery();
  const history = useHistory();

  const monthS = query.get('month');
  const yearS = query.get('year');

  const [dateFilter, setDateFilter] = React.useState(
    monthS && yearS ? new Date(Number(yearS), Number(monthS) - 1) : new Date()
  );

  const month = React.useMemo(() => dateFilter.getMonth() + 1, [dateFilter]);

  React.useEffect(
    function updateWhenChangeSearchParams() {
      const rangeS = monthS && yearS ? `${monthS}, ${yearS}` : null;
      const isRangeEqual = rangeS === `${month.toString()}, ${dateFilter.getFullYear().toString()}`;
      if (!isRangeEqual) {
        setDateFilter(monthS && yearS ? new Date(Number(yearS), Number(monthS) - 1) : new Date());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.toString()]
  );

  React.useEffect(
    function updateSearchParamsWhenChangeFilters() {
      history.push(
        routes.metrics.consumption({
          month: month.toString(),
          year: dateFilter.getFullYear().toString(),
        })
      );
    },
    [history, dateFilter, month]
  );

  return (
    <Container>
      <TitleContainer sx={{ padding: '6px' }}>
        <Typography
          sx={{ textTransform: 'uppercase', marginRight: 1 }}
          variant="h5"
          display="inline"
        >
          {t('credit_consumption')}
        </Typography>
        <Typography variant="body2" display="inline">
          {t('displaying_data_for_from', {
            descriptionDate: timeFormat('%B %Y')(dateFilter),
          })}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year', 'month']}
            openTo="month"
            label={t('select_month_and_year')}
            inputFormat="MM/YYYY"
            value={dateFilter}
            onChange={(newValue: Dayjs | null) => newValue && setDateFilter(newValue.toDate())}
            renderInput={(params) => (
              <TextField {...params} sx={{ marginLeft: 'auto' }} helperText={null} />
            )}
          />
        </LocalizationProvider>
      </TitleContainer>
      <ApiBoundary>
        <ConsumptionTabData month={month} year={dateFilter.getFullYear()} />
      </ApiBoundary>
    </Container>
  );
}

export default ConsumptionTab;
