const FEATURE_FLAGS_KEYS = {
  POPUP_CONFIRM_EXIT_WITH_UNSAVED_CHANGES: 'popup_confirm_exit_with_unsaved_changes',
  CHANGE_INITIAL_REDIRECT: 'change_initial_redirect', // TODO: remover quando implementar tela de bem vindo ao acessar o vms.
  RELEASE_NOTES_CONTROL: 'release_notes_control',
  MAX_QTY_MOSAIC: 'max_qty_mosaic',
  H265_MESSAGE: 'h265_message',
  DISABLED_PROGRESSIVE_HLS_CONFIG: 'disabled_progressive_hls_config',
  SHOW_NEW_MOSAIC_PAGE: 'show_new_mosaic_page',
  FEATURE_INTELBRAS_INPUT_PORT: 'feature_intelbras_input_port',
  ENABLE_PLAY_FROM_SECOND_FRAGMENT: 'enable_play_from_second_fragment',
  ACTIVATE__DEACTIVATE_CHANNELS_IN_BATCH: 'activate___deactivate_channels_in_batch',
  REDIRECT_TO_ENABLE_CHANNEL_IN_MOSAIC: 'redirect_to_enable_channel_in_mosaic',
  TEMPO_DE_TOLERANCIA_PARA_FALHAS: 'configuracao_de_tempo_de_tolerancia_para_falhas',
  DESLOGAR_USUARIO_AO_PERDER_ACESSO_DA_MASTER_COMPANY:
    'deslogar_usuario_ao_perder_acesso_da_master_company',
  DOWNLOAD_VMS_CLOUD: 'download_vms_cloud',
  DOWNLOAD_VMS_CLOUD_WITH_PROXY_TO_CANADA: 'download_vms_cloud_with_proxy_to_canada',
  DOWNLOAD_VMS_CLOUD_POPUP: 'download_vms_cloud_popup',
  STEP_BY_STEP_INSTALLATION: 'step_by_step_installation',
  DIAGNOSTICO_DE_CONEXAO: 'diagnostico_de_conexao',
  DIAGNOSTICO_DE_CONEXAO_ONLY_WINDOWS: 'diagnostico_de_conexao_only_windows',
  MOSAICOS_VINCULADOS_AO_CANAL: 'mosaicos_vinculados_ao_canal',
};

export default FEATURE_FLAGS_KEYS;
