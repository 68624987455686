import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    background: theme.palette.common.black,
    color: theme.palette.text.primary,
    textAlign: 'center',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
}));

export function TitleTotalStorage() {
  const { t } = useTranslation(['calculator', 'metrics']);
  const classes = useStyles();
  return <Typography className={classes.title}>{t('total_costs')}</Typography>;
}
