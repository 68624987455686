import React from 'react';
import routes from 'services/routes';
import { Route, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*
  Following these articles:
  -> https://material-ui.com/components/breadcrumbs/#integration-with-react-router
  -> https://medium.com/@mattywilliams/generating-an-automatic-breadcrumb-in-react-router-fed01af1fc3
*/

function Breadcrumb() {
  const { t } = useTranslation(['routes', '_common']);
  return (
    <>
      {routes.map(({ path }, i) => (
        <Route
          exact
          path={path}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          render={({ match }) => {
            const { customerId } = match.params || {};
            const pathnames = match.path.split('/').filter((x) => x);

            return (
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                {pathnames
                  .map((_, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const finalUrl =
                      typeof customerId === 'string' ? to.replace(':customerId', customerId) : to;
                    const routeItem = routes.find(({ path: _path }) => _path === to);
                    // eslint-disable-next-line no-shadow
                    const name = routeItem?.name;
                    const ignoreInMiddle = routeItem?.ignoreInMiddle ?? false;

                    return name ? { name, to, last, ignoreInMiddle, finalUrl } : null;
                  })
                  .filter((x) => x)
                  .map((value) => {
                    const { name, last, to, ignoreInMiddle, finalUrl } = value as {
                      name: string;
                      to: string;
                      last: boolean;
                      ignoreInMiddle: boolean;
                      finalUrl: string;
                    };
                    if (last)
                      return (
                        <Typography variant="body2" key={to}>
                          {t(name)}
                        </Typography>
                      );

                    if (ignoreInMiddle) return null;

                    return (
                      <Link
                        color="textSecondary"
                        variant="body2"
                        to={finalUrl}
                        key={to}
                        component={RouterLink}
                      >
                        {t(name)}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            );
          }}
        />
      ))}
    </>
  );
}

export default Breadcrumb;
