import { useHavePermission } from 'hooks/useHavePermission';
import { useMemo } from 'react';
import { PermissionRole } from 'types/user.types';

export function useCanPlayChannel({ channelPrivate }: { channelPrivate: boolean }) {
  const haveAdminPermission = useHavePermission(PermissionRole.ADMIN);

  const canPlay = useMemo(() => !channelPrivate || haveAdminPermission, [
    haveAdminPermission,
    channelPrivate,
  ]);

  return canPlay;
}