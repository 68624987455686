import {
  Box,
  Button,
  Link,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Skeleton,
  TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouteLink } from 'react-router-dom';
import React from 'react';
import { CellProps, Column, HeaderDetailsProps, KeyOf, TableData } from 'types/table.types';
import { useTranslation } from 'react-i18next';

function Cell<RowType extends KeyOf<RowType>>({ row, column }: CellProps<RowType>) {
  const textValue = React.useMemo(
    () => (column.renderValue ? column.renderValue(row[column.dataKey]) : row[column.dataKey]),
    [row, column]
  );
  return column.linkTo ? (
    <Link component={RouteLink} to={column.linkTo(row)} color="textPrimary" variant="body2">
      {textValue}
    </Link>
  ) : (
    <>{textValue}</>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    overflow: 'auto',
  },
  container: {
    flex: '1 1 auto',
  },
  tablePaginationContainer: {
    position: 'relative',
  },
  tablePagination: {
    flex: '0 0 auto',
  },
  exportPDFButton: {
    position: 'absolute',
    '--halfHeight': 'calc(100% / 2)', // halfHeight do container pai
    top: 'calc(var(--halfHeight) - 18px)', // 18px; height button = 36px; 36px / 2 = 18px
    left: '8px',
  },
  tableCell: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.text.primary} !important`,
  },
  tableHeaderDetails: {
    backgroundColor: `${theme.palette.common.black} !important`,
    fontWeight: '500',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  rowDetails: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: 'inherit',
    display: 'table-row',
    verticalAlign: 'middle',
    outline: '0px',
  },
}));

function Table<RowType extends KeyOf<RowType>>({
  children,
  dataLength,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleExportAsPDF,
  tableName,
  columns,
}: {
  children: React.ReactNode;
  dataLength: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleExportAsPDF?: React.MouseEventHandler;
  tableName: string;
  columns: Column<KeyOf<RowType>>[];
}) {
  // TODO: melhorar o render para permitir componente customizado e uma actions para cada linha
  const classes = useStyles();
  const { t } = useTranslation('_common');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <TableMui aria-label={tableName} size="medium" stickyHeader>
          <Header {...{ columns }} />
          <TableBody aria-label="Corpo da tabela">{children}</TableBody>
        </TableMui>
      </TableContainer>
      <Box className={classes.tablePaginationContainer}>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 15, 30]}
          component="div"
          count={dataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {handleExportAsPDF && (
          <Button
            className={classes.exportPDFButton}
            color="primary"
            variant="contained"
            onClick={handleExportAsPDF}
          >
            {t('_common:export_as_PDF')}
          </Button>
        )}
      </Box>
    </>
  );
}

function Loading<RowType extends KeyOf<RowType>>({
  columns,
}: {
  columns: Column<KeyOf<RowType>>[];
}) {
  const { t } = useTranslation('events');
  return (
    <Table
      dataLength={10}
      page={0}
      setPage={(n) => {}}
      rowsPerPage={15}
      setRowsPerPage={(n) => {}}
      tableName={t('loading_events')}
      {...{ columns }}
    >
      {Array.from({ length: 5 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={i}>
          {columns.map(({ description }) => (
            <TableCell key={description} aria-label={description}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
}

function Header<RowType extends KeyOf<RowType>>({
  columns,
}: {
  columns: Column<KeyOf<RowType>>[];
}) {
  const classes = useStyles();

  return (
    <TableHead aria-label="Cabeçalho da tabela">
      <TableRow>
        {columns.map(({ description }) => (
          <TableCell key={description} className={classes.tableCell}>
            {description}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function HeaderDetails<RowType extends KeyOf<RowType>>({
  columns,
  orderBy,
  order,
  onRequestSort,
}: HeaderDetailsProps<TableData>) {
  const classes = useStyles();

  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead aria-label="Cabeçalho da tabela">
      <TableRow className={classes.rowDetails}>
        {columns.map((props: Column<TableData>) => (
          <TableCell
            className={classes.tableCell}
            key={props.dataKey}
            sortDirection={orderBy === props.dataKey ? order : false}
          >
            <TableSortLabel
              key={props.dataKey}
              active={orderBy === props.dataKey}
              direction={orderBy === props.dataKey ? order : 'asc'}
              onClick={createSortHandler(props.dataKey)}
            >
              {props.description}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Table.Loading = Loading;
Table.Cell = Cell;
Table.Header = Header;
Table.HeaderDetails = HeaderDetails;

export default Table;
