import { Box, Fab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { SegwareLogo } from 'components/core/Icons';
import { useTranslation } from 'react-i18next';
import ApiBoundary from 'components/ApiBoundary';
import { textDebouncedAtom } from 'atoms/selectMosaicPanel';
import { NavigateBefore } from '@mui/icons-material';
import DebounceInput from 'components/core/DebounceInput';
import { useSetRecoilState } from 'recoil';
import { useSelectMosaicPanel } from 'hooks/mosaic/useSelectMosaicPanel';
import ResultsContainer, { Loading } from './ResultsContainer';
import { InputContainer, Header, Container } from '../LeftPanel.styles';

const useStyles = makeStyles((theme) => ({
  segwareLogo: {
    height: '34px',
    width: '40px',
    color: theme.palette.text.primary,
  },
}));

function SelectMosaicPanel() {
  const { t } = useTranslation('select_mosaic');
  const classes = useStyles();
  const [inputText, setInputText] = useState('');
  const setTextDebounced = useSetRecoilState(textDebouncedAtom);
  const { closeMosaicSelection } = useSelectMosaicPanel();
  return (
    <Container role="region" aria-labelledby="select-mosaic-panel-title">
      <Header>
        <SegwareLogo className={classes.segwareLogo} />
        <Box>
          <Typography variant="h6" id="select-mosaic-panel-title">
            {t('select_mosaic')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('select_a_mosaic_to_view')}
          </Typography>
        </Box>
      </Header>
      <InputContainer>
        <Fab size="small" onClick={closeMosaicSelection} aria-label="close-select-mosaic">
          <NavigateBefore />
        </Fab>
        <DebounceInput
          placeholder={t('search_mosaics')}
          autoFocus
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          setValue={setInputText}
          onDebounce={() => setTextDebounced(inputText)}
        />
      </InputContainer>
      <ApiBoundary fallbackLoading={<Loading />}>
        <ResultsContainer />
      </ApiBoundary>
    </Container>
  );
}

export default SelectMosaicPanel;
