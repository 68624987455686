import { Box, Typography, Skeleton } from '@mui/material';
import { ChannelSimple, VideoProperties } from 'types/mosaics.types';
import ApiBoundary from 'components/ApiBoundary';
import { useFetch } from 'hooks';
import React from 'react';

function VideoTechInfoInner({ channel, noGap }: { channel: ChannelSimple; noGap?: boolean }) {
  const { data: videoProperties } = useFetch<VideoProperties>(
    `/v1/channels/${channel.id}/properties`
  );
  return (
    <VideoTechInfoView
      bitrate={videoProperties?.bitrate}
      resolution={videoProperties?.resolution}
      fps={videoProperties?.fps}
      {...{ noGap }}
    />
  );
}

function VideoTechInfoView({
  bitrate,
  resolution,
  fps,
  loading,
  noGap,
}: {
  bitrate?: number;
  resolution?: string;
  fps?: number;
  loading?: boolean;
  noGap?: boolean;
}) {
  return (
    <Box display="flex" gap={noGap ? 0 : 2} alignItems="flex-start">
      <Typography variant="caption">
        {loading ? <Skeleton sx={{ minWidth: '100px' }} /> : `${bitrate} kbps`}
      </Typography>
      <Typography variant="caption">
        {loading ? <Skeleton sx={{ minWidth: '100px' }} /> : resolution}
      </Typography>
      <Typography variant="caption">
        {loading ? <Skeleton sx={{ minWidth: '100px' }} /> : `${fps} fps`}
      </Typography>
    </Box>
  );
}

VideoTechInfo.Loading = function VideoTechInfoLoading() {
  return <VideoTechInfoView loading />;
};
VideoTechInfo.Error = function VideoTechInfoError() {
  return null;
};

function VideoTechInfo({ channel, noGap }: { channel: ChannelSimple; noGap?: boolean }) {
  return (
    <ApiBoundary
      fallbackLoading={<VideoTechInfo.Loading />}
      fallbackError={<VideoTechInfo.Error />}
    >
      <VideoTechInfoInner {...{ channel, noGap }} />
    </ApiBoundary>
  );
}

export default VideoTechInfo;
