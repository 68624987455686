import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import routes from 'helpers/routes';
import { selectedAtom } from 'atoms/selectCustomerPanel';
import { useSetRecoilState } from 'recoil';
import { useQuery } from 'hooks';
import { RTSPChannelForm } from 'components/forms/channels';
import { useChannelsApi } from 'hooks/channels/useChannelsApi';
import { useMosaicItens } from 'hooks/mosaic/items/useMosaicItems';
import { useSelectCustomerPanel } from 'hooks/customers/useSelectCustomerPanel';
import { useSelectChannelPanel } from 'hooks/channels/useSelectChannelPanel';
import { Channel, DEFAULT_CHANNEL } from 'types/channels.types';
import { Box } from '@mui/material';

function MosaicCreateChannel({
  mosaicId,
  slotId,
  customerId,
}: {
  mosaicId: number;
  slotId: number;
  customerId: number;
}) {
  const history = useHistory();
  const match = useRouteMatch<{ mosaicId: string; slotId: string; customerId: string }>(
    routes.mosaic.createChannel(':mosaicId', ':slotId', ':customerId')
  );
  const { createChannel } = useChannelsApi(customerId);
  const { putChannelInSlot, selectMosaicSlot } = useMosaicItens(mosaicId);
  const { openCustomerSelection } = useSelectCustomerPanel();
  const { openChannelSelection } = useSelectChannelPanel();
  const setCustomerSelected = useSetRecoilState(selectedAtom);
  const query = useQuery();

  useEffect(() => {
    openCustomerSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!match?.isExact) {
      return;
    }

    selectMosaicSlot(Number(match.params.slotId));
    setCustomerSelected(Number(match.params.customerId));

    // eslint-disable-next-line consistent-return
    return () => {
      setCustomerSelected(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, selectMosaicSlot]);

  const handleSubmit = async (channel: Channel) => {
    const channelCreated = await createChannel(channel);
    putChannelInSlot(slotId, {
      ...channel,
      id: channelCreated.id,
      clientCompanyName: '',
      clientTradeName: '',
      clientStatus: true,
      clientId: 0,
    });
    openChannelSelection();
    history.push(routes.mosaic.view(mosaicId));
  };

  return (
    <Box
      sx={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <RTSPChannelForm
        initialValues={DEFAULT_CHANNEL}
        doSubmit={handleSubmit}
        onClose={() => history.push(query.get('returnRoute') || routes.customer.root())}
      />
    </Box>
  );
}

export default MosaicCreateChannel;
