import { RecordingType } from 'types/channels.types';
import { DateRangeValue } from 'components/DateRange';

export interface ConsuptionDetail {
  date: Date;
  recordingDuration: number;
  size: number;
  channelId: number;
  channelName: string;
  recordingType: RecordingType;
}

export interface Consuption {
  allTheTime: number;
  event: number;
  scheduled: number;
  channels: ConsuptionDetail[];
  sizeConsumed: number;
  storageSize: number;
}

export interface ConsuptionPlayback {
  date: Date;
  size: number;
  duration: number;
  channelId: number;
  channelName: string;
  recordingType: RecordingType;
}

export interface ConsumptionPrevision {
  amountConsumed: number;
  contractedValue: number;
  averageConsumptionDay: number;
  consumptionMonthPrevision: number;
}

export const recordingTypeToConsuptionKey = (rt: RecordingType) => {
  switch (rt) {
    case RecordingType.EVENT:
      return 'event';
    case RecordingType.SCHEDULE:
      return 'scheduled';
    default:
      return 'allTheTime';
  }
};

const INIT_DATE = new Date(new Date(new Date().setDate(1)).toDateString());

const END_DATE = (() => {
  const endDate = new Date();

  // if (INIT_DATE.getDay() === endDate.getDay()) {
  //   endDate.setUTCHours(2, 59, 59, 0);
  //   return endDate;
  // }

  endDate.setDate(endDate.getDate() + 1);
  endDate.setUTCHours(2, 59, 59, 0);
  return endDate;
})();

export const INIT_RANGE: DateRangeValue = [INIT_DATE, END_DATE];
