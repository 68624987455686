import styled from 'styled-components';
import Box from '@mui/material/Box';

export const Container = styled(Box)`
  margin: auto;
  text-align: center;

  > h6 {
    margin-bottom: 0.6rem;
  }

  > p:last-of-type {
    margin-bottom: 1.68rem;
  }
`;
