import React, { Suspense } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import { useYupObject } from 'hooks';
import { Form } from 'components/core';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { usePlaylistApi } from 'hooks/playlists/usePlaylistApi';
import { useFormStyles } from '../core/Form';
import SelectMosaics from '../SelectMosaics/SelectMosaics';

function PlaylistForm({ setOpen }: { setOpen: (open: boolean) => void }) {
  const { t } = useTranslation(['playlists', '_common', 'crud_actions']);
  const yup = useYupObject();
  const { createPlaylist } = usePlaylistApi();
  const classesForm = useFormStyles({ dialog: true });
  const history = useHistory();
  const initialValues = {
    name: '',
    mosaics: [],
  };
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    mosaics: yup
      .array(
        yup.object({
          id: yup.number().required(),
          label: yup.string().required(),
        })
      )
      .required(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      createPlaylist(values, (id: number) => history.push(routes.playlists.play(id)));
      setSubmitting(false);
    },
  });
  return (
    <form
      aria-label="PlaylistFormModal"
      className={classesForm.form}
      onSubmit={formik.handleSubmit}
    >
      <Box className={classesForm.formInputsContainer}>
        <TextField
          className={classesForm.formInput}
          sx={{ marginTop: '4px' }}
          fullWidth
          id="name"
          name="name"
          label={t('_common:name')}
          autoFocus
          placeholder={t('enter_playlist_name')}
          helperText={
            (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
          }
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
        <Suspense fallback={<SelectMosaics.Loading />}>
          <SelectMosaics
            selectedMosaics={formik.values.mosaics}
            onChangeMosaics={formik.handleChange}
            error={formik.touched.mosaics && Boolean(formik.errors.mosaics)}
            helperText={
              formik.touched.mosaics &&
              formik.errors.mosaics &&
              (Array.isArray(formik.errors.mosaics)
                ? formik.errors.mosaics.join(',')
                : formik.errors.mosaics)
            }
            label={t('playlist_mosaics')}
          />
        </Suspense>
      </Box>
      <Box className={classesForm.formFooter}>
        <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={() => setOpen(false)} />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          values={formik.values}
          label={t('crud_actions:create')}
          dialog
        />
      </Box>
    </form>
  );
}
export default PlaylistForm;
