import { Record } from 'types/playback.types';

export default function mergeRecordsChannels(channelsRecords: Record[]): Record[] {
  let mergedData: Record[] = [];

  let actualIndex = 0;
  [...channelsRecords]
    .sort((a, b) => Number(a.startDate) - Number(b.startDate))
    .forEach((record) => {
      const mergingRecord = mergedData[actualIndex];
      if (!mergingRecord) {
        mergedData = [record];
      } else if (
        record.startDate <= mergingRecord.endDate &&
        record.endDate > mergingRecord.endDate
      ) {
        mergedData[actualIndex] = { ...mergingRecord, endDate: record.endDate };
      } else {
        actualIndex += 1;
        mergedData[actualIndex] = record;
      }
    });

  return mergedData;
}
