import React from 'react';
import { asyncComponent } from 'HOCs';
import { Company, DEFAULT_COMPANY } from 'types/companies.types';
import CompanyForm from 'pages/CompanyPage/CompanyForm';
import { useCompaniesApi } from 'hooks/companies/useCompaniesApi';

function CompanyNewPage() {
  const { addCompany, deleteCompany } = useCompaniesApi();

  const handleSubmit = async (company: Company) => {
    await addCompany(company);
  }

  const handleDeleteCompany = async (id: number) => {
    await deleteCompany(id);
  };

  return (
    <CompanyForm
      doSubmit={handleSubmit}
      initialValues={DEFAULT_COMPANY}
      doDelete={handleDeleteCompany}
    />
  );
}

export default asyncComponent(CompanyNewPage);
