import { RecordingType } from './channels.types';

export enum SearchType {
  CLIENT = 'CLIENT',
  MOSAIC = 'MOSAIC',
  CHANNEL = 'CHANNEL',
}

export interface CustomerSearched {
  id: number;
  type: SearchType.CLIENT;
  tradeName: string;
  companyName: string;
  qtyChannels: number;
  status: boolean;
}
export interface MosaicSearched {
  id: number;
  type: SearchType.MOSAIC;
  name: string;
  qtyChannels: number;
}
export interface ChannelSearched {
  id: number;
  type: SearchType.CHANNEL;
  name: string;
  clientTradeName: string;
  clientCompanyName: string;
  clientId: number;
  qtyChannels: number;
  recordingType: RecordingType;
  channelPrivate: boolean;
  status: boolean;
}

export interface Search {
  customers: CustomerSearched[];
  mosaics: MosaicSearched[];
  channels: ChannelSearched[];
}

export type SearchApi = (CustomerSearched | MosaicSearched | ChannelSearched)[];
