import { Box, Fab, Link, Typography } from '@mui/material';
import React from 'react';
import { CompanySimplifyListItem } from 'types/companies.types';
import { useTranslation } from 'react-i18next';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import routes from 'helpers/routes';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DebounceInput from 'components/core/DebounceInput';
import { useRecoilState } from 'recoil';
import { companiesSearchTextAtom } from 'atoms/companies';
import CompanyListItem from './CompanyListItem';

function LayoutContainer({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const listaRef = React.useRef<HTMLDivElement>(null);
  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(companiesSearchTextAtom);
  const [searchText, setSearchText] = React.useState(searchTextDebounced);
  const inputRef = React.useRef<HTMLInputElement>();
  const history = useHistory();

  const handleDebounceText = () => {
    setSearchTextDebounced(searchText);
    if (searchText !== searchTextDebounced) {
      history.push(routes.settings.companies.root());
    }
  };

  /* O foco no campo do search deve continuar mesmo após o loading aparecer na tela */
  React.useEffect(() => {
    inputRef.current?.focus();
  }, [children]);

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        borderRadius: '5px',
        paddingLeft: '20px',
        minHeight: '100%',
        width: '50rem',
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
      }}
    >
      <Typography
        sx={{
          paddingTop: '24px',
          paddingBottom: 1,
        }}
      >
        {t('company:all_companies')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
          marginRight: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <DebounceInput
            {...{ inputRef }}
            placeholder={t('crud_actions:search')}
            fullWidth
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            setValue={setSearchText}
            onDebounce={handleDebounceText}
          />
        </Box>
        <Box
          sx={{
            margin: '0 1rem',
          }}
        >
          <Permissioned role={PermissionRole.INSERT_CLIENT}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              component={RouterLink}
              to={routes.settings.companies.create}
            >
              <AddIcon />
            </Fab>
          </Permissioned>
        </Box>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          height: '100%',
        }}
        ref={listaRef}
      >
        {children}
      </Box>
    </Box>
  );
}
function CompanyList({ data }: { data?: CompanySimplifyListItem[] }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        height: '100%',
      }}
    >
      <LayoutContainer>
        {data && data.length > 0 ? (
          data.map((company) => (
            <Link
              key={company.id}
              to={routes.settings.companies.root(Number(company.id))}
              component={RouterLink}
              underline="none"
            >
              <CompanyListItem company={company} key={company.id} />
            </Link>
          ))
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography color="textSecondary">{t('customers:no_results')}</Typography>
          </Box>
        )}
      </LayoutContainer>
    </Box>
  );
}

export function CompanyListLoading() {
  return (
    <LayoutContainer>
      {Array.from({ length: 10 }).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CompanyListItem key={i} loading />
      ))}
    </LayoutContainer>
  );
}

export default CompanyList;
