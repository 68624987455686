import Axios from 'axios';
import { useHavePermission } from 'hooks/useHavePermission';
import useMessages from 'hooks/useMessages';
import { SpeedTestDTO } from 'pages/SettingsPage/tabs/SpeedTest/types/speedTest.types';
import { useTranslation } from 'react-i18next';
import { PermissionRole } from 'types/user.types';

const ENDPOINT = `/v1/networkConnectionPerformances`;
export default function useSpeedTestAPI() {
  const { t } = useTranslation();
  const insertPermission = useHavePermission(PermissionRole.INSERT_NETWORK_CONNECTION_PERFORMANCE);
  const { enqueueSuccess, enqueueError } = useMessages();

  const saveResult = async (result: SpeedTestDTO) => {
    if (!insertPermission) {
      enqueueError(
        `${t('speed_test:error_creating')}\n${t('permission:you_dont_have_permission')}`
      );
      throw new Error(t('permission:you_dont_have_permission'));
    }
    try {
      await Axios.post<SpeedTestDTO>(ENDPOINT, result);

      enqueueSuccess(t('speed_test:result_saved_successfuly'));
    } catch (err) {
      console.error({ err });

      if (Axios.isAxiosError(err)) {
        enqueueError(
          `${t('speed_test:error_saving')}\n${t(
            `api_errors_messages:${err?.response?.data?.message}`
          )}`
        );
      }
    }
  };

  return {
    saveResult,
  };
}
